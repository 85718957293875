import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import IoTFlowsLogo from '../IoTFlowsLogoBlackFont.png';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
  } from "react-router-dom";
import Amplify, { Auth, Hub } from 'aws-amplify';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import GoogleButton from 'react-google-button'
import GoogleLogo from './googleLogo.svg'
import MicrosoftLogo from './MicrosoftLogo.png'
import Google_Logo from './Google_Logo.svg'


Amplify.configure({
    Auth: {            
        identityPoolId: 'us-east-1:5684b65a-8bf4-4697-b9a4-87eadbf03ef5',
        region: 'us-east-1',
        identityPoolRegion: 'us-east-1',      
        userPoolId: 'us-east-1_3EP1MRzAy',  
        userPoolWebClientId: '18230ih88kfb3ouj67mrid5er3',
        oauth: {
            domain: 'auth.iotflows.com',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            // redirectSignIn: 'https://console.iotflows.com/', 
            // redirectSignOut: 'https://console.iotflows.com/', 
            redirectSignIn: 'http://localhost:3000/', 
            redirectSignOut: 'http://localhost:3000/', 
            responseType: 'token', // DO NOT USE 'code'!!! it goes through a forever loop //or 'token', note that REFRESH token will only be generated when the responseType is code
            // responseType: 'code', 
            options: {
                // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
                AdvancedSecurityDataCollectionFlag : true
            },
        }
    }
});


const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100vh',
        overflow:'scroll',
    },
    loginContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '540px',
        paddingTop: '56px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '382px',
            paddingTop: '28px',
        },
    },
    logoContainer: {
        marginLeft:'12px',
        paddingLeft: '16px',
        paddingBottom: '40px',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '20px',
            paddingBottom: '24px',
        },
    },
    loginSection: {
        width: '540px',
        color: '#424770',
        backgroundColor: '#fff',
        borderRadius: '16px',
        padding: '56px 48px',
        webkitBoxShadow:  '0 30px 60px -12px rgb(50 50 93 / 25%), 0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%)',
        boxShadow: '0 30px 60px -12px rgb(50 50 93 / 25%), 0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '32px 20px',
            margin:'0px 16px'
        },
    },
    loginTitleContainer: {
        marginLeft: '12px',
        textAlign: 'left',
    },
    loginTitle: {
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '32px',
        fontFamily: 'sohne-var,sohne-woff,Helvetica Neue,Arial,sans-serif',
        letterSpacing: '-.2px',
        color: '#3c4257',
    },
    inputSection: {
        marginBottom: '12px',
    },
    inputContainers: {
        textAlign: 'left',
        marginLeft: '12px',
        marginTop:'12px',
    },
    labelCSS: {
        marginBottom: '0px',
        textAlign: 'left',
    },
    loginHeader: {
        lineHeight: '20px',
        fontWeight: 500,
        fontSize: '14px',
        color: '#3c4257',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
    },
    inputCSS: {
        // backgroundColor: '#f6f9fc',
        backgroundColor: '#fff',
        color: 'rgb(60, 66, 87)',

        display: 'block',
        width: '100%',
        height: 'calc(1.5em + .75rem + 2px)',
        padding: '.375rem .75rem',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
        webkitAppearance: 'none',
        mozAppearance: 'none',
        appearance: 'none',
        borderRadius: '.25rem',
        webkitFontSmoothing: 'antialiased',
        transition: 'background-color .24s,box-shadow .24s',
        '&:focus': {
            transition: 'background-color .24s,box-shadow .24s',
            borderColor: 'rgba(142, 183, 248, 1)',
            outline: 0,
            webkitBoxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px',
            mozBoxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px',
            boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px',
        },
    },
    forgotCSS: {
        textAlign: 'left',
        textDecoration: 'none',
        lineHeight: '20px',
        fontWeight: 500,
        fontSize: '14px',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
        color: '#2563EB',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'none',
            color: '#3c4257',
        },
    },
    loginButtonContainer: {
        marginLeft: '12px',
        marginTop: '24px',
    },
    loginButton: {
        backgroundColor: '#2563EB',
        color: '#fff',
        border: 'none',
        fontWeight: 500,
        padding: '4px 14px',
        fontSize: '16px',
        letterSpacing: '.025em',
        lineHeight: '28px',
        borderRadius: '4px',
        width: '100%',
        '&:hover': {
            backgroundColor: '#2563EB', 
            opacity: 0.9,
        },
        '&:disabled': {
            opacity: 0.8,
        },
    },
    googleLoginButton: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#fff',
        color: '#3c4257',
        border: 'none',
        fontWeight: 500,
        padding: '4px 4px',
        fontSize: '14px',
        letterSpacing: '.025em',
        lineHeight: '28px',
        borderRadius: '4px',
        width: '100%',
        opacity: 0.8,
        outline: 'none',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            backgroundColor: '#F3F4F6', 
            opacity: 1,
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        '&:disabled': {
            opacity: 0.8,
        },
    },
    signupContainer: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    signupMessage: {
        display: 'flex',
        width:'fit-content',
        margin:'0px auto',
        lineHeight: '20px',
        color: '#3c4257',
        fontWeight: 400,
        fontSize: '14px',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
    },
    footerPositioner: {
        position: 'relative',
        // bottom: '10px',
        // display: 'flex',
        // flexDirection: 'column',
    },
    spacer: {
        flexGrow: 1,
        flexShrink: 1,
    },
    legal_footer: {
        padding: '12px',
    },
    legal_item: {
        textDecoration: 'none',
        color: '#606972',
        padding: '8px',
        textAlign: 'center',
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'none',
            color: 'rgba(96, 105, 114, 0.8)',
            cursor: 'pointer',
        },
    },
    angledDiv: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: '-250px',
        bottom: 0,
        zIndex: -1,
        transform: 'skewY(-12deg)',
    },
    largeGreyBackground: {
        position: 'absolute',
        top: '-1000px',
        left: 0,
        right: 0,
        transformOrigin: '0 50%',
        height: '1630px',
        backgroundColor: '#F3F4F6',
    },
    leftStripeBackground: {
        top: '630px',
        left: '-10px',
        right: 'calc(50% + 505px)',
        height: '50px',
        backgroundColor:'#60A5FA',
        position: 'absolute',
        [theme.breakpoints.up('xl')]: {
            right: 'calc(50% + 605px)',
        },
        [theme.breakpoints.down('md')]: {
            right: 'calc(50% + 135px)',
        },
    },
    rightStripeBackground: {
        top: '580px',
        right: '-10px',
        left: 'calc(100% - 230px)',
        height: '50px',
        backgroundColor:'#60A5FA',
        position: 'absolute',
        [theme.breakpoints.down('md')]: {
            left: 'calc(100% - 110px)',
        },
    },
    messageContainer: {
        padding: '1px',
        marginLeft: '12px',
        marginTop: '12px',
    },
    notificationMessage: {
        lineHeight: '16px',
        fontSize: '12px',
        // color: '#cd3d64',
        fontWeight: 400,
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',

    },
    // MFA CSS
    textCSS: {
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
        lineHeight: '20px',
        fontWeight: 400,
        fontSize: '14px',
        color: '#3c4257',
    },
    boxDisplay: {
        borderRight: '1px solid rgba(0, 0, 0, 0.2)',
        width: '40px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '32px',
        position: 'relative',
        appearance: 'none',
        margin: 0,
        '&:last-child': {
            borderRight: 'none',
        },
    },
    wrap: {
        border: '1px solid rgba(0, 0, 0, 0.2)',
        display: 'inline-block',
        position: 'relative',
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '24px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
    },
    input: {
        position: 'absolute',
        border: 'none',
        fontSize: '32px',
        textAlign: 'center',
        backgroundColor: 'transparent',
        outline: 'none',
        appearance: 'none',
        margin: 0,
        // -webkitAppearance: 'none',
        // -mozAppearance: 'textfield',
    },
    shadows: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        boxShadow: '0 0 0 4px rgba(58, 151, 212, 0.28)',
        borderRadius: '0px',
    },
    shadowsLeft: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        boxShadow: '0 0 0 4px rgba(58, 151, 212, 0.28)',
        borderRadius: '0px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '0px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '0px',
    },
    shadowsRight: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        boxShadow: '0 0 0 4px rgba(58, 151, 212, 0.28)',
        borderRadius: '0px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '8px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '8px',
    },
   
  });

const CODE_LENGTH = new Array(6).fill(0);

class Login extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: '',
            redirect: false,
            toPath:'',
            wrongPassword: false,
            emptyUsername: false,
            incorrectEmailPassword: false,
            issueMessage: '',
            displayMFA: false,
            value: "",
            focused: false,
            verifyingUser: {},
            user: {},
            // Local Host Link
            googleLink: '',
            microsoftLink: '',
            sbdLink:'',
            issueMFAMessage: '',
            errorMFA: false,
            isMFAlogin: false,
        }

    }
    input = React.createRef();

    componentDidMount() {
        Hub.listen("auth", ({ payload: { event, data } }) => {
          switch (event) {
            case "signIn":
                if(data){
                    this.props.setUser(data)
                    this.setState({ user: data });
                }
              break;
          }
        });
        
        const isLocalhost = Boolean(
            window.location.hostname === "localhost" ||
              // [::1] is the IPv6 localhost address.
              window.location.hostname === "[::1]" ||
              // 127.0.0.1/8 is considered localhost for IPv4.
              window.location.hostname.match(
                /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
              )
          );
          if(isLocalhost) {
              this.setState({
                googleLink: 'https://auth.iotflows.com/oauth2/authorize?client_id=18230ih88kfb3ouj67mrid5er3&response_type=token&redirect_uri=http://localhost:3000/&identity_provider=Google',
                microsoftLink: 'https://auth.iotflows.com/oauth2/authorize?client_id=18230ih88kfb3ouj67mrid5er3&response_type=token&scope=email+openid+phone+profile&redirect_uri=http://localhost:3000/&identity_provider=Microsoft',
                sbdLink: 'https://auth.iotflows.com/oauth2/authorize?client_id=18230ih88kfb3ouj67mrid5er3&response_type=token&scope=email+openid+phone+profile&redirect_uri=http://localhost:3000/&identity_provider=SBDInc'
            })
          }else {
            this.setState({
                googleLink: 'https://auth.iotflows.com/oauth2/authorize?client_id=18230ih88kfb3ouj67mrid5er3&response_type=token&redirect_uri=https://www.console.iotflows.com/&identity_provider=Google',
                microsoftLink: 'https://auth.iotflows.com/oauth2/authorize?client_id=18230ih88kfb3ouj67mrid5er3&response_type=token&scope=email+openid+phone+profile&redirect_uri=https://www.console.iotflows.com/&identity_provider=Microsoft',
                sbdLink: 'https://auth.iotflows.com/oauth2/authorize?client_id=18230ih88kfb3ouj67mrid5er3&response_type=token&scope=email+openid+phone+profile&redirect_uri=https://www.console.iotflows.com/&identity_provider=SBDInc'
            })
          }
    
      }


    handleSignupRouting = () => {
        this.setState(
            {
                redirect: true,
                toPath: '/signup',
            }, () => {
                this.setState({redirect: false})
            })
    }

    handleResetRouting = () => {
        this.setState(
            {
                redirect: true,
                toPath: '/reset',
            }, () => {
                this.setState({redirect: false})
            })
    }

    async getCodeFromUserInput () {
        this.setState({displayMFA: true})
    }

    handleMFALogin = () => {
        // set state to true so it doesn't continously try to log in 
        this.setState({isMFAlogin: true})
        
        // If MFA is enabled, sign-in should be confirmed with the confirmation code
        Auth.confirmSignIn(
            this.state.verifyingUser,   // Return object from Auth.signIn()
            this.state.value,   // Confirmation code  
            this.state.verifyingUser.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        ).then(loggedUser =>{
            this.props.setUser(loggedUser)
            this.setState({
                errorMFA: false,
                issueMFAMessage: '',
                isMFAlogin: false
            })
        })
        .catch(err => {
            // console.log('err', err)
            this.setState({
                errorMFA: true,
                issueMFAMessage: err.message,
                isMFAlogin: false
            })
        });
    }

    handledLogin = () => {
        try {
            Auth.signIn(this.state.username, this.state.password)
            .then(verifyingUser => {
                if (verifyingUser.challengeName === 'SMS_MFA' || verifyingUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    this.setState({
                        verifyingUser: verifyingUser,
                        displayMFA: true,
                    })
                    
                } else {
                    // The user directly signs in
                    this.props.setUser(verifyingUser)
                    this.setState({
                        incorrectEmailPassword: false,
                        issueMessage: '',
                    })
                }
            })
            .catch(err => {
                // console.log('err', err)
                this.setState({
                    incorrectEmailPassword: true,
                    issueMessage: err.message,
                })
            });
            
            
        } catch (error) {
            console.log('error signing in', error);
        }
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter' && this.state.username != '' && this.state.password != ''){
            // console.log('enter press here! ')
            this.handledLogin();
        }
        if(event.key === 'Enter' && this.state.username == ''){
            this.setState({emptyUsername: true})
        }

    }

    handleUsernameChange = (e) => {
        this.setState({username: e.target.value.replace(/[^A-Za-z0-9_.@]/g, "")});
    }

    // MFA Functions
    handleClick = () => {
        this.input.current.focus();
    };

    handleFocus = () => {
        this.setState({ focused: true });
    };

    handleBlur = () => {
        this.setState({focused: false});
    };

    handleChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
      
        this.setState((state) => {
            console.log('state', state)
          if (state.value.length >= CODE_LENGTH.length) return null;
          return {
            value: (state.value + value).slice(0, CODE_LENGTH.length),
          };
          
        });
      };

    handleKeyUp = (e) => {
    if (e.key === "Backspace") {
        this.setState((state) => {
        return {
            value: state.value.slice(0, state.value.length - 1),
        };
        });
    }
    };

    render() {
        const { classes} = this.props
        const { username, 
                password, 
                toPath,
                redirect,
                emptyUsername, 
                incorrectEmailPassword,
                issueMessage,
                displayMFA,
                value,
                focused,
                user,
                googleLink,
                microsoftLink,
                sbdLink,
                errorMFA,
                issueMFAMessage,
                isMFAlogin } = this.state

        const values = value.split("");
        const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;
        const hideInput = !(values.length < CODE_LENGTH.length);
                


        if (redirect) {
            return <Redirect push to={toPath} />
          }
        if(Object.keys(value).length >= 6 && !isMFAlogin){
            this.handleMFALogin()
        }

        return (
            <div className={classes.root}>
                <div className={classes.angledDiv}>
                    <div className={classes.largeGreyBackground}>
                    </div>
                    <div className={classes.leftStripeBackground}>
                    </div>
                    <div className={classes.rightStripeBackground}>
                    </div>
                </div>
                
                <div className={classes.loginContainer}>
                    <div className={classes.logoContainer}>
                        <img src={IoTFlowsLogo} height="40px" alt="IoTFlows Inc" loading="lazy" />
                    </div>
                    <div className={classes.loginSection}>
                        {!displayMFA &&
                        <>
                        <form id="login"  onSubmit={e => e.preventDefault()} >
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div className={classes.loginTitleContainer} >
                                    <span className={classes.loginTitle} >Sign in to your account</span>
                                </div>
                                <div className={classes.inputSection} >
                                    <div style={{marginBottom: '0px'}}>
                                        <div className={classes.inputContainers} >
                                            <label className={classes.labelCSS} htmlFor="username"><span className={classes.loginHeader} >Username</span></label> 
                                        </div>
                                        <div className={classes.inputContainers}>
                                            <input onChange={this.handleUsernameChange} value={username} type="text" autoComplete="username" id="user_username" placeholder="" className={classes.inputCSS} required />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.inputSection}>
                                    <div style={{marginBottom: '0px'}}>
                                        <div className={classes.inputContainers}>
                                            <label className={classes.labelCSS} htmlFor="password"><span className={classes.loginHeader}>Password</span></label>
                                        </div>
                                        <div className={classes.inputContainers}>
                                            <input onChange={(e) => this.setState({password: e.target.value})} onKeyPress={this.handleKeyPress} type="password" autoComplete="current-password" id="user_password" placeholder="" className={classes.inputCSS} required />
                                        </div>
                                        {emptyUsername && username == '' &&
                                            <div className={classes.messageContainer}>
                                                <svg fill="#ed5f74" height="12" width="12" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0 1 13.634 16H2.365A2.365 2.365 0 0 1 .25 12.577L5.884 1.308a2.365 2.365 0 0 1 4.231 0zM8 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z" fillRule="evenodd"></path>
                                                </svg>
                                                <span className={classes.notificationMessage} style={{color: '#cd3d64'}}>&nbsp;&nbsp;Please enter your username.</span>
                                            </div>
                                        }
                                        {incorrectEmailPassword &&
                                            <div className={classes.messageContainer}>
                                                <svg fill="#ed5f74" height="12" width="12" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0 1 13.634 16H2.365A2.365 2.365 0 0 1 .25 12.577L5.884 1.308a2.365 2.365 0 0 1 4.231 0zM8 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z" fillRule="evenodd"></path>
                                                </svg>
                                                <span className={classes.notificationMessage} style={{color: '#cd3d64'}}>&nbsp;&nbsp;{issueMessage}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={classes.inputContainers}>
                                    <div onClick={this.handleResetRouting} className={classes.forgotCSS}>Forgot your password?</div>
                                </div>
                            </div>
                        </form>
                        <div >
                            <div className={classes.inputContainers} >
                                <button 
                                    // type="submit"
                                    onClick={this.handledLogin} 
                                    className={classes.loginButton} 
                                    disabled={password == '' || username == '' }
                                >
                                    Sign in
                                </button>
                            </div>
                        </div>
                        <div className={classes.signupContainer} >
                            <span className={classes.signupMessage} >Don't have an account? &nbsp; <div onClick={this.handleSignupRouting} className={classes.forgotCSS}>Sign up</div></span>
                        </div>
                        <div className={classes.inputSection} >
                            <div style={{marginBottom: '0px'}}>
                                <div className={classes.inputContainers} >
                                    <div className={classes.labelCSS}><span className={classes.loginHeader} >Sign in with Google</span></div> 
                                </div>
                                <div className={classes.inputContainers}>
                                    <button 
                                    className={classes.googleLoginButton} 
                                    onClick={() => window.location.href = googleLink}
                                    >
                                        <div style={{height: '28px', width: '28px', borderRadius: '4px',backgroundColor: 'transparent', textAlign:'center', marginRight: '8px'}}>
                                            <img src={GoogleLogo} atl='Google Logo' width="18" height="18" />
                                        </div>
                                        <div style={{fontSize:'16px'}}>
                                            Sign in with Google
                                        </div>
                                    </button>                              
                                </div>
                            </div>
                        </div>
                        <div className={classes.inputSection} >
                            <div style={{marginBottom: '0px'}}>
                                <div className={classes.inputContainers} >
                                    <div className={classes.labelCSS}><span className={classes.loginHeader} >Sign in with Microsoft</span></div> 
                                </div>
                                <div className={classes.inputContainers}>
                                    <button 
                                    className={classes.googleLoginButton} 
                                    onClick={() => window.location.href = microsoftLink}
                                    >
                                        <div style={{height: '28px', width: '28px', borderRadius: '4px',backgroundColor: 'transparent', textAlign:'center', marginRight: '8px'}}>
                                            <img src={require('./MicrosoftLogo.png')} atl='Microsoft Logo' width="18" height="18" />
                                        </div>
                                        <div style={{fontSize:'16px'}}>
                                            Sign in with Microsoft
                                        </div>
                                    </button>                              
                                </div>
                            </div>
                        </div>
                        <div className={classes.inputSection} >
                            <div style={{marginBottom: '0px'}}>
                                <div className={classes.inputContainers} >
                                    <div className={classes.labelCSS}><span className={classes.loginHeader} >{'Sing in with Stanley Black & Decker'}</span></div> 
                                </div>
                                <div className={classes.inputContainers}>
                                    <button 
                                    className={classes.googleLoginButton} 
                                    onClick={() => window.location.href = sbdLink}
                                    >
                                        <div style={{height: '28px', width: '28px', borderRadius: '4px',backgroundColor: 'transparent', textAlign:'center', marginRight: '8px'}}>
                                        <img src={require('./sbd_logo.png')} atl='SBD Logo' width="28" height="28" />
                                        </div>
                                        <div style={{fontSize:'16px'}}>
                                            {'Sign in with Stanley Black & Decker'}
                                        </div>
                                    </button>                              
                                </div>
                            </div>
                        </div>
                        </>
                        }
                         {/* Show when MFA authentication is required */}
                         {displayMFA &&
                         <>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div className={classes.loginTitleContainer} >
                                    <span className={classes.loginTitle} >Two-step authentication</span>
                                </div>

                                <div className={classes.inputContainers} >
                                    <span className={classes.textCSS} >To continue, please enter the 6-digit verification code sent to your authentication application</span>
                                </div>

                                {/* 6 code digits  */}
                                <div className={classes.wrap} onClick={this.handleClick}>
                                    <input
                                            value=""
                                            ref={this.input}
                                            onChange={this.handleChange}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            onKeyUp={this.handleKeyUp}
                                            className={classes.input}
                                            type="text"
                                            style={{
                                                width: "40px",
                                                top: "0px",
                                                bottom: "0px",
                                                left: "0px",
                                                left: `${selectedIndex * 40}px`,
                                                opacity: hideInput ? 0 : 1,
                                            }}
                                        />
                                    {
                                        CODE_LENGTH.map((v, index) => {
                                            const selected = values.length === index;
                                            const filled = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

                                            return (
                                            <div key={index} className={classes.boxDisplay}>
                                                {values[index]}
                                                {(selected || filled) && focused && (index == 0) && <div className={classes.shadowsLeft} />}
                                                {(selected || filled) && focused && (index > 0 && index < 5) && <div className={classes.shadows} />}
                                                {(selected || filled) && focused && (index == 5) && <div className={classes.shadowsRight} />}
                                            </div>
                                            );
                                        })
                                    }
                                </div>
                                {errorMFA &&
                                    <div className={classes.messageContainer}>
                                        <svg fill="#ed5f74" height="12" width="12" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0 1 13.634 16H2.365A2.365 2.365 0 0 1 .25 12.577L5.884 1.308a2.365 2.365 0 0 1 4.231 0zM8 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z" fillRule="evenodd"></path>
                                        </svg>
                                        <span className={classes.notificationMessage} style={{color: '#cd3d64'}}>&nbsp;&nbsp;{issueMFAMessage}</span>
                                    </div>
                                }
                            </div>
                            <div >
                                <div className={classes.loginButtonContainer} >
                                    <button 
                                        className={classes.loginButton} 
                                        onClick={this.handleMFALogin} 
                                        disabled={Object.keys(value).length < 6}
                                    >
                                        Continue
                                    </button>
                                </div>
                                {/* <div className={classes.inputContainers} style={{marginTop: '24px'}}>
                                    <div onClick={this.handleLoginRouting} className={classes.forgotCSS} style={{textAlign: 'center'}}>Login another way</div>
                                </div> */}
                            </div>
                        </>
                        }
                    </div>

                    {/* <div className={classes.signupContainer} >
                        <span className={classes.signupMessage} >Don't have an account? &nbsp; <div onClick={this.handleSignupRouting} className={classes.forgotCSS}>Sign up</div></span>
                    </div> */}
                    <div className={classes.spacer}/>
                    <section className={classes.footerPositioner} >
                        <div className={classes.legal_footer} >
                            <a className={classes.legal_item} href="https://www.iotflows.com/" >©IoTFlows Inc 2021</a>
                            <a className={classes.legal_item} href="https://www.iotflows.com/policies/terms-of-use/" >Terms of Use</a>
                            <a className={classes.legal_item} href="https://www.iotflows.com/policies/private-policy/" >Private Policy</a>
                        </div>
                    </section>
                    </div>
                
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
    showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  
  })
  
  const mapStateToProps = state => {
    return {
      searchbarUsername: state.searchbarUser.searchbarUsername
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Login));

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import  {putData, getData } from '../ApiCalls/DataApis';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

function SelectMachineFlowDataStream({flow, deviceDataStreams}) {
  const [machineFlow, setMachineFlow] = useState(flow.flow_name);
  const [dataStream, setDataStream] = useState([]);
  const [loading, setLoading] = useState(false);

  


  useEffect(() => {
    if(flow && flow.data_stream_uuid && flow.data_stream_name){
      deviceDataStreams.map(currentDataStream => {
        if(currentDataStream.data_stream_uuid == flow.data_stream_uuid){
          setDataStream(currentDataStream)
        }
      })
    }
  },[]);

  const handleChangeMachineFlow = (event) => {
    setMachineFlow(event.target.value);
  };
  const handleChangeDataStream = (event) => {
    if(!loading){
      setLoading(true)
      putData('https://api.iotflows.com/v1/assets/' + flow.asset_uuid + '/data_stream_flows' , 
      {
        "flow_uuid": flow.flow_uuid,
        "data_stream_uuid": event.target.value.data_stream_uuid,
      })
      .then(async response => {
        if(response && response.ok){
          // const data = await response.json();
          setDataStream(event.target.value);
          setLoading(false)
        }
        else{
          try{
            const data = await response.json();
            setLoading(false)
          }catch (e){
            console.log(e)
          }
        }
      })
    }
    
    
  };
  

  return (
    <div style={{display:'flex'}}>
      <FormControl variant="standard" sx={{width:300}}>
        <InputLabel id="demo-simple-select-standard-label">Machine Flow</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={machineFlow}
          sx={{width:'100%'}}
          onChange={handleChangeMachineFlow}
          label="Machine Flow"
          disabled={true}
        >
          <MenuItem value={flow.flow_name}>
            {flow.flow_name}
          </MenuItem>
        </Select>
      </FormControl>
      <div style={{margin:'auto 16px'}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#6b7280" viewBox="0 0 16 16">
          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
        </svg>
      </div>
      <FormControl variant="filled"  sx={{width:300}}>
        <InputLabel id="demo-simple-select-filled-label">Data Stream</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={dataStream}
          label="Data Stream"
          sx={{width:'100%'}}
          onChange={handleChangeDataStream}
        >
          {deviceDataStreams.map(dataStream => (
            <MenuItem value={dataStream}>{dataStream.data_stream_name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{margin:'auto 8px'}}>
        {dataStream && Object.keys(dataStream).length ?
        //  Check mark
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#22c55e" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
        </svg>
        :
        //  X mark
        
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fca5a5"  viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
        </svg>
        }
        {loading && <CircularProgress size={26} style={{color: '#22c55e',position: 'absolute',marginTop: 0,marginLeft: -24 }} />}
      </div>
    </div>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AssignMachineFlows({openAssignMachineFlowsModal, handleOpenAssignMachineFlowsModal, asset}) {
  const [open, setOpen] = useState(openAssignMachineFlowsModal);
  const [machineFlowList, setMachineFlowList] = useState([]);
  const [deviceDataStreams, setDeviceDataStreams] = useState([]);
  const [finishedGetMachineFlows, setFinishedGetMachineFlows] = useState(false)
  const [finishedGetDataStreams, setFinishedGetDataStreams] = useState(false)

  useEffect(() => {
    // Get list of assets with datastreams matching 
    getData('https://api.iotflows.com/v1/assets/' + asset.asset_uuid + '/data_stream_flows')
    .then(async response => {
      if(response && response.ok ){
        const data = await response.json();
        if(data && data.data){
          console.log('Assign machine flow data.data')
          console.log(data.data)
          setMachineFlowList(data.data)
          setFinishedGetMachineFlows(true)
        }
      }
    });

    getData('https://api.iotflows.com/v1/devices/' + asset.device_uuid + '/data_streams' )
    .then(async response => {
      if(response && response.ok ){
        const data = await response.json();
        if(data && data.data){
          setDeviceDataStreams(data.data)
          setFinishedGetDataStreams(true)
        }
      }
    });

  },[]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleOpenAssignMachineFlowsModal()
  };

  return (
    <div style={{marginBottom:'80px'}}>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'xl'}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Assign Machine Flows to Data Stream</DialogTitle>
        <DialogContent>
          <div style={{width:'100%', display:'flex',flexDirection:'column', margin:'16px auto'}}>
            {finishedGetMachineFlows && finishedGetDataStreams &&
            machineFlowList.map(flow => (
              <div key={flow.flow_uuid} style={{margin:'8px auto'}}>
                <SelectMachineFlowDataStream flow={flow} deviceDataStreams={deviceDataStreams}/>
              </div>
            ))
            }
          

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleClose}>Agree</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}


import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
// import  {deleteData } from '../ApiCalls/DataApis';
// import Alert from '@material-ui/lab/Alert';
// import { Typography } from '@material-ui/core';
// import Row from 'react-bootstrap/Row'
import '../styles/emailAddressesList.css'
import  {postData, getData } from '../ApiCalls/DataApis';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(70),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    width: '135px',
    // font-weight:bold;
    padding:'5px 14px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  // sendInviteButton: {
  //   backgroundColor: 'rgba(79, 134, 206,0.8)',
  //   marginRight:'auto',
  //   color: 'rgba(255,255,255)',
  //   '&:hover': {
  //     backgroundColor:'rgba(79, 134, 206,1)',
  //   },
  //   '&:active': {
  //     position:'relative',
  //     top:'1px',
  //     outline: 'none',
  //   },
  //   '&:focus': {
  //     outline: 'none',
  //   },
  // },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
  
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class SendInviteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      users: [],
      value: "",
      error: null,
      organizationMemberRole:'',
      projectMemberRole:'',
      organizationRoles: [],
      projectRoles: [],
      projects_list: [],
      projectSelected: [],
    };
  }

  componentDidMount() {

      getData('https://api.iotflows.com/v1/organizations/roles')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            organizationRoles: data.data 
          })
        }
      });
      getData('https://api.iotflows.com/v1/projects/roles')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            projectRoles: data.data 
          })
        }
      });
      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            projects_list: data.data ,
          })
        }
      });
  } 

  handleOpen = () => {
    this.setState({ 
      open: true,
      users: [],
      value: "",
      error: null,
     });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      users: [],
      value: "",
      error: null,
      projectSelected: [],
      organizationMemberRole:'',
      projectMemberRole:'',
     });
  };

  submitButtonClick = () => {
    let users_temp = []
    this.state.users.map(user => {
      users_temp.push({"email":user})
    })
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        postData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/invite', 
        {'invite_data': {
            'version': '1.1.2',
            'users': users_temp,
            'organization_invite': {
              'version': '1.1.2',
              'organization_uuid': this.props.selectedOrganization.organization_uuid,
              'organization_role_name': this.state.organizationMemberRole,
              'projects':[{
                'project_uuid': this.state.projectSelected.project_uuid,
                'project_role_name': this.state.projectMemberRole,        
              }],
            }
          }
        })
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              // const data = await response.json();
              this.props.showSuccessSnackbar("Invite have been sent!");
              this.setState({
                loading: false,
                success: true,
                open: false,
                users: [],
                value: "",
                error: null,
              }, ()=> {
                  this.props.refreshMemberList();
                  this.setState({
                    success: false,
                  })
                }
              );
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false,
                  users: [],
                  value: "",
                  error: null,
                });
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  handleBlur = async event => {
    if (this.state.value) {
      event.preventDefault();

      var value = this.state.value.trim();

      if (value && await this.isValid(value)) {
        this.setState({
          users: [...this.state.users, this.state.value],
          value: ""
        });
      }
    }

  }

  handleKeyDown = async evt => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.value.trim();

      if (value && await this.isValid(value)) {
        this.setState({
          users: [...this.state.users, this.state.value],
          value: ""
        });
      }
    }
  };

  handleChange = evt => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleDelete = user => {
    this.setState({
      users: this.state.users.filter(i => i !== user)
    });
  };

  handlePaste = async evt => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails && await this.isValid(emails)) {
      var toBeAdded = emails.filter(email => !this.isInList(email));

      this.setState({
        users: [...this.state.users, ...toBeAdded]
      });
    }
  };

  async isValid(email) {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }
    // Checking for email in database to ensure theres no duplicate
    await getData('https://api.iotflows.com/v1/users/exists?email=' + email)
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data[0].exists)
        {
          error = `${email} already has an account with IoTFlows.`;
        }        
      }
    });

    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      this.setState({ error });
      return false;
    }

    return true;
  };

  isInList(email) {
    return this.state.users.includes(email);
  };

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  // Handle to select organization role
  handleOrganizationRoleChange = (event) => {
    if(Object.keys(event.target.value).length === 0){
      this.setState({
        organizationMemberRole: '',
      });
    }else {
      this.setState({organizationMemberRole: event.target.value});
    }
  };
  
  // Handle to select project user will be assinged to
  handleProjectSelected = (event) => {
    if(Object.keys(event.target.value).length === 0){
      this.setState({
        projectSelected: [],
        projectMemberRole: ''
      });
    }else {
      this.setState({
        projectSelected: JSON.parse(event.target.value)
      });
    }
  };

  // Handle to select project role
  handleProjectRoleChange = (event) => {
    if(Object.keys(event.target.value).length === 0){
      this.setState({projectMemberRole: ''});
    }else {
      this.setState({projectMemberRole: event.target.value});
    }
  };

  render() {
    const { classes, selectedOrganization } = this.props;
    const { open, 
            loading, 
            success, 
            users, 
            value,
            organizationMemberRole,
            projectMemberRole,
            organizationRoles,
            projectRoles,
            projects_list,
            projectSelected} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        style={{marginRight:'16px'}}
        
      >
        <Button className={classes.button} onClick={this.handleOpen} variant="contained" disabled={selectedOrganization.organization_is_personal}>
          <MailOutlineOutlinedIcon fontSize="small" />&nbsp;Send Invite        
        </Button>
       
        
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title"> 
                  Add people
                </DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '20px'}}>
                
                  <div style={{margin: '0px'}} >
                  {users.map(item => (
                    <div className="tag-item" key={item}>
                      {item}
                      <button
                        type="button"
                        className="button"
                        onClick={() => this.handleDelete(item)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}

                  <input
                    className={"inputs " + (this.state.error && " has-error")}
                    value={this.state.value}
                    placeholder="Type or paste email addresses"
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                    onPaste={this.handlePaste}
                    onBlur={this.handleBlur}
                  />

                  {this.state.error && <p className="error">{this.state.error}</p>}

                    {/* Creating a list of all the organization roles */}
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Organization Role</InputLabel>
                    <Select
                      native
                      value={organizationMemberRole}
                      onChange={this.handleOrganizationRoleChange}
                    >
                      <option aria-label="None" value="" />
                      {organizationRoles.map( (role,index) => (
                      <LightTooltip  key={index.toString()} className={classes.hoverText} title={role.role_description} placement="right-start" arrow  >
                        <option key={index.toString()}  value={role.role_name} >{role.role_name}</option>
                      </LightTooltip>
                    ))}
                    </Select>
                  </FormControl>


                    {/* Creating a list of all the projects in the organizaiton */}
                  <FormControl className={classes.formControl} >
                    <InputLabel id="demo-simple-select-label">Assign to a Project</InputLabel>
                    <Select
                      native
                      onChange={this.handleProjectSelected}
                    >
                      <option aria-label="None" value="" />
                      {projects_list.map( (project,index) => (
                        // <option key={index.toString()}  value={project.project_name} >{project.project_name}</option>
                        <option key={index.toString()}  value={`{"project_uuid": "${project.project_uuid}","project_name":"${project.project_name}"}`} >{project.project_name}</option>
                    ))}
                    </Select>
                  </FormControl>

                    {/* Creating a list of all the project roles */}
                  <FormControl className={classes.formControl} disabled={Object.keys(projectSelected).length === 0} >
                    <InputLabel id="demo-simple-select-label">Project Role</InputLabel>
                    <Select
                      native
                      value={projectMemberRole}
                      onChange={this.handleProjectRoleChange}
                    >
                      <option aria-label="None" value="" />
                      {projectRoles.map( (role,index) => (
                      <LightTooltip  key={index.toString()} className={classes.hoverText} title={role.role_description} placement="right-start" arrow  >
                        <option key={index.toString()}  value={role.role_name} >{role.role_name}</option>
                      </LightTooltip>
                    ))}
                    </Select>
                  </FormControl>


                  </div>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  
                    color="primary" 
                    type='submit' 
                    className={buttonClassname} 
                    disabled={loading || !organizationMemberRole || Object.keys(users).length === 0 || (Object.keys(projectSelected).length === 0? false : !projectMemberRole) || (projectMemberRole? (Object.keys(projectSelected).length === 0): false)} 
                    onClick={this.submitButtonClick}
                  >
                    Invite { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

SendInviteModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(SendInviteModal));




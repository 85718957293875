export const network_data_api = 
[
  { id: 1, network_type: 10, country: 1, network_name: 'Mahmoud Home', network_ssid: 'SSID', network_password: 'password',account_username: 'acctUsername', account_password: 'password'},
  { id: 2, network_type: 10, country: 1, network_name: 'Ulises Home', network_ssid: 'SSID', network_password: 'password',account_username: 'acctUsername', account_password: 'password'},
  { id: 3, network_type: 10, country: 1, network_name: 'Pauls Home', network_ssid: 'SSID', network_password: 'password',account_username: 'acctUsername', account_password: 'password'},
  { id: 4, network_type: 11, country: 1, network_name: 'Georgia Tech', network_ssid: 'SSID', network_password: 'password',account_username: 'acctUsername', account_password: 'password'},
  { id: 5, network_type: 11, country: 1, network_name: 'B&D', network_ssid: 'SSID', network_password: 'password',account_username: 'acctUsername', account_password: 'password'},
  { id: 6, network_type: 11, country: 1, network_name: 'Starbucks 4', network_ssid: 'SSID', network_password: 'password',account_username: 'acctUsername', account_password: 'password'},
  { id: 7, network_type: 10, country: 1, network_name: 'Starbucks 2', network_ssid: 'SSID', network_password: 'password',account_username: 'acctUsername', account_password: 'password'},

];

export const network_names = [];
for (let i = 0; i < Object.keys(network_data_api).length; i++) {
  network_names[i] = network_data_api[i].network_name
  }
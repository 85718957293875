import React from 'react'
import { Grid, Typography, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Row from 'react-bootstrap/Row';
import ResetDeviceModal from "./ResetDeviceModal.js"
import DownloadDataStreamDataModal from '../components/DownloadDataStreamDataModal';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import "../styles/HeartbeatLED.scss";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  root: {
    marginBottom: '15px',
  },
  headerCSS2: {
    borderRadius: '5px',
    height:'auto',
    // paddingLeft: '10px',
    // paddingBottom: '8px',
    // paddingTop: '8px',
    backgroundColor: '#eeeeee',
    // [theme.breakpoints.down('xs')]: {
    //   paddingLeft: 10, 
    // },
  },
  deviceFontSize: {
    fontSize: '14px'
  },
  tourIconCSS: {
    padding: '0px', 
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '20px',
    // marginLeft: '0px', 
    // marginBottom: '0px',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
});


class HeaderDevice extends React.Component{
  constructor(props){
    super(props);
    this.state = {
    }
  }

  
  render() {
    const { classes, deviceInfo} = this.props

    return (
      <div className={classes.root}>
        <div className={classes.headerCSS2} >
          <Grid container spacing={1}  >
            <Grid item sm={4}  direction="column"  container >
              <Row style={{margin: '0px', paddingLeft: '10px'}}> 
                <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                    Name:
                </Typography>
                <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {fontFamily:'Trebuchet MS'}}>
                  {this.props.isDeviceOnline ? <span className="heartbeatOnline"> </span>: <span className="heartbeatOffline"> </span>}
                </Typography>
                <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                &nbsp; {this.props.selected_device.device_name}
                </Typography>
              </Row>
              <Row style={{margin: '0px', paddingLeft: '10px'}}> 
                <Typography  className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                    Description:&nbsp; 
                </Typography>
                <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                  {this.props.selected_device.device_description}
                </Typography>
              </Row>
              {/* <Row style={{margin: '0px', paddingLeft: '10px'}}> 
                <Typography  className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                    MAC Address:&nbsp; 
                </Typography>
                <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                  {deviceInfo.default_network_mac}
                </Typography>
              </Row>
              <Row style={{margin: '0px', paddingLeft: '10px'}}> 
                <Typography  className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                    IPv4 Address:&nbsp; 
                </Typography>
                <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                  {deviceInfo.default_network_ip4}
                </Typography>
              </Row>
              <Row style={{margin: '0px', paddingLeft: '10px'}}> 
                <Typography  className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                    IPv4 Subnet Mask:&nbsp; 
                </Typography>
                <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                  {deviceInfo.default_network_ip4subnet}
                </Typography>
              </Row> */}
            </Grid>

            <Grid item sm={4} direction="column"  container >
                {/* <Row style={{margin: '0px', paddingLeft: '10px'}}> 
                <FormControlLabel
                  style={{margin: '0px 0px 0.35em 0px'}}
                  control={<IOSSwitch checked={isDeviceEnabled} onChange={this.deviceStatusChange} name="isDeviceEnabled" />}
                  label={<span style={{ fontSize: '1rem', fontFamily: 'Trebuchet MS', color: '#757575'}}>Device Status</span>}
                  labelPlacement="start"
                />
                </Row> */}
                <Row style={{margin: '0px', paddingLeft: '10px'}}> 
                  <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                    Location:&nbsp; 
                  </Typography>
                  <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                    {/* {this.props.selected_device.device_last_heard} */}
                    {this.props.deviceGeolocation.region_code ? 
                      this.props.deviceGeolocation.city +', '+ this.props.deviceGeolocation.region_code +', '+ this.props.deviceGeolocation.country_code 
                    :''}
                    {/* {this.props.selected_device.device_peer_host ? this.props.selected_device.device_peer_host : ''} */}
                  </Typography>
                </Row>
                <Row style={{margin: '0px', paddingLeft: '10px'}}> 
                  <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                    Last Connection Established:&nbsp; 
                  </Typography>
                  <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                    {this.props.lastHeard}
                  </Typography>
                </Row>
                {/* <Row style={{margin: '0px', paddingLeft: '10px'}}> 
                  <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                    OS Architecture:&nbsp; 
                  </Typography>
                  <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                    {deviceInfo.os_architecture}
                  </Typography>
                </Row>
                <Row style={{margin: '0px', paddingLeft: '10px'}}> 
                  <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                    OS Distribution:&nbsp; 
                  </Typography>
                  <Typography className={classes.deviceFontSize} variant="body1" gutterBottom style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                    {deviceInfo.os_distribution}
                  </Typography>
                </Row> */}
            </Grid>
            <Grid container item sm={4}  style={{justifyContent: 'center'}} >
              <Grid item sm={6} style={{textAlign: 'right', marginTop: 'auto', marginBottom: 'auto', padding: '4px'}}>
                  <ResetDeviceModal selectedDevice={this.props.selectedDevice} isDeviceOnline={this.props.isDeviceOnline} />
              </Grid>
              <Grid item sm={3} style={{textAlign: 'right', marginTop: 'auto', marginBottom: 'auto', padding: '4px'}}>
                  <DownloadDataStreamDataModal device_uuid={this.props.device_uuid} /> 
              </Grid>
              <Grid item sm={3} style={{textAlign: 'center', marginTop: 'auto', marginBottom: 'auto', padding: '4px 8px 4px 0px'}}>
                <LightTooltip title="How to publish data">
                  <IconButton className={classes.tourIconCSS} onClick={this.props.handleClickStartDemo} >
                    <InfoOutlinedIcon style={{color: '#9e9e9e', fontSize: '24px' }} />
                  </IconButton>
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
        </div>
        
      </div>
    )

  }
}



export default withStyles(styles,{ withTheme: true })(HeaderDevice)

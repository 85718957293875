const uiReducer = (state = {}, action) => {
  switch (action.type) {
    case "SNACKBAR_SUCCESS":
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.message,
        snackbarAlertSeverity : "success"
      };
    case "SNACKBAR_CLEAR":
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false
      };
      case "SNACKBAR_ERROR":
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.message,
        snackbarAlertSeverity : "error"
      };
    default:
      return state;
  }
};

export default uiReducer;
const initialState = {
  allAlertChannels: [],
  currentAlertChannel: [{}],
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'FETCH_ALERT_CHANNELS':
      return {
        ...state,
        allAlertChannels: action.payload
      }
    case 'SELECTED_ALERT_CHANNEL':
      return {
        ...state,
        selectedAlertChannel: action.currentAlertChannel
      }
    // case 'SELECTED_USER':
    //   return {
    //     ...state,
    //     selectedUser: action.payload
    //   }
   
    default:
      return state;
  }
}

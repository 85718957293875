import React from 'react';
// import {  withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import {  withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import {getData} from '../ApiCalls/DataApis';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableFooter from '@material-ui/core/TableFooter';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
// import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
// import Button from '@material-ui/core/Button';
// import MqttService from '../core/services/MqttService';
import Grid from '@material-ui/core/Grid';
// import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';
// import SyntaxHighlighter from 'react-syntax-highlighter';
// import { tomorrowNightBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import DropdownRow from './DropdownRow'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import AddAssetInOrganizationModal from '../components/AddAssetInOrganizationModal';
import "../styles/HeartbeatLED.scss";
import Avatar from '@mui/material/Avatar';
import {Redirect} from "react-router-dom";
import { styled } from '@mui/material/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {withRouter} from 'react-router-dom';
import TagSelectionButton  from "./TagSelectionButton.js";
import MenuAssetButton  from "./MenuAssetButton.js";
import AvatarGroup from '@mui/material/AvatarGroup';
import Badge from '@mui/material/Badge';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    bottom:2,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));


const CardContainer = styled('div')(({ theme }) => ({
  position:'relative',
  display:'flex', 
  marginLeft:'auto', 
  marginRight:'auto',
  cursor:'pointer',
  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  color: 'rgba(0, 0, 0, 0.87)',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  backgroundColor: '#fff',
  borderRadius:'10px',
  [theme.breakpoints.down('sm')]:{
    minWidth:'350px',
    maxWidth:'450px',
    height:'200px', 
  },
  [theme.breakpoints.up('sm')]:{
    minWidth:'300px',
    maxWidth:'450px',
    height:'150px', 
  },
  [theme.breakpoints.up('md')]:{
    minWidth:'350px',
    maxWidth:'450px',
    height:'200px', 
  },
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  },
  '&:focus': {
    outline: 'none',
  },
}))

const CardShadow = styled('div')(({ theme }) => ({
  position:'absolute',
  top:'0px',
  left:'0px',
  borderRadius:'10px',
  zIndex:1,
  backgroundColor:'rgb(0,0,0,0)',
  width:'100%',
  [theme.breakpoints.down('sm')]:{
    minWidth:'350px',
    height:'200px', 
  },
  [theme.breakpoints.up('sm')]:{
    minWidth:'300px',
    height:'150px', 
  },
  [theme.breakpoints.up('md')]:{
    minWidth:'350px',
    height:'200px', 
  },
  '&:hover': {
    backgroundColor:'rgb(0,0,0,0.1)',
  }
}))


const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        // style={globalStyles.LightDarkTheme}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton 
        // style={globalStyles.LightDarkTheme} 
        onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        // style={globalStyles.LightDarkTheme}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        // style={globalStyles.LightDarkTheme}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '3px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
  },
  pageContainer:{
    maxWidth: '1536px',
    marginLeft:'auto',
    marginRight:'auto'
  },
  tableContainer: {
    borderRadius: '10px',
  },
  table: {
    minWidth: 450,
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    minWidth:'70px',
    maxHeight: '23px',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'10px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  cardInfo:{
    padding:'24px', 
    position:'absolute', 
    top:'0px', 
    left:'0px',
    zIndex:10, 
    height:'200px',
    width:'300px', 
    justifyContent:'center', 
    alignItems:'center', 
    backgroundColor:'rgba(0,0,0,0.5)',
    borderRadius:'10px 10px 0px 0px'
  },
  inputContainer:{
    display: 'inline-flex',
    flexGrow: 1,
    width: '100%',
    border: '1px solid #E5E7EB',
    padding: '0px',
    margin: '4px',
    textAlign: 'left',
    backgroundColor: "#fff",
    borderRadius:'6px',
    height: '2.25rem',
    position: 'relative',
  },
  searchIconContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
    padding: '0.125rem 0.5rem 0.125rem 0.25rem'
  },
  searchIcon: {
    color: '#6B7280',
    height: '1rem'
  },
  inputCSS: {
    backgroundColor: 'transparent',
    border:'0px solid transparent',
    textAlign: 'left',
    outline: 'none',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    padding: '0.5rem 0.25rem',
    minWidth: '100%',
    color: '#4B5563'
  },
  statusBar: {
    borderRadius:'10px 0px 0px 10px',
    backgroundColor:'#2ccc39',
    background:'#2ccc39',
    height:'100%',
    width:'7px'
  },
  avatar: {
    backgroundColor:'transparent', 
    borderRadius:'10px', 
    color:'#334155'
  }
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);

const dummyAssetData = [
  {
    id:'1',
    image:'/broken_linl.png',
    name: 'Tucker Machine V1.4werv',
    description: 'Tucker Machines V12.4werv in JLR. Located in UK',
    tags: ['JLR', 'Tucker', 'UK', 'SWS'],
    data_stream_matching: ''
  },
  {
    id:'2',
    image:'/broken_linl.png',
    name: 'Tucker Machine V2.4werv',
    description: 'Tucker Machines V12.4werv in JLR. Located in UK',
    tags: ['JLR', 'Tucker', 'UK', 'SWS'],
    data_stream_matching: ''
  },
];



class OrganizationAssetsPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      organization_assets_list: [],
      filtered_organization_assets_list:[],
      organization_assets_tags: [],
      organizationTagKeysDic:[],
      allTags:[],
      assertSearchText:'',
      toPath:'',
      redirect: false,
      tagStates:[],
    }
    this.refreshAssetList = this.refreshAssetList.bind(this);
    this.updateURLTagSearch = this.updateURLTagSearch.bind(this);
    
  }

  componentDidMount(){
    
    var query = this.props.location.search
    // var query = '?q=Ulises&tag_Machine+Model=TE&tag_Machine+Type=Lathe%2CGrinder'
    query = decodeURIComponent(query)

    query = query.substring(1)
    var vars = query.split('&');
    var currentStates = [];
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] == 'q') {
            this.setState({assertSearchText:pair[1]})
        }else {
          var strFirstThree = pair[0].substring(0,4);
          if(strFirstThree == "tag_"){
            let tagKey = pair[0].substring(4)
            // tagKey = tagKey.split('+').join(' ')

            let tagArray = pair[1].split(',');
            let states = {
              'tag_key':tagKey,
              "tag_array": tagArray
            }
            currentStates.push(states)


          }
        }
        
    }
    this.setState({tagStates: currentStates})
    // console.log('Query variable %s not found', variable);

    

    // Get list of assets for organization
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/assets' + this.props.location.search )
    .then(async response => {
      if(response && response.ok ){
        const data = await response.json();
        if(data && data.data){
          // this.setState({
          //   filtered_organization_assets_list: data.data,
          // })
          const OrgAssets = data.data;

          // check to see if any datastreams are missing
          // Get list of assets with datastreams maching 
          OrgAssets.map((asset, index) =>{
            getData('https://api.iotflows.com/v1/assets/' + asset.asset_uuid + '/data_stream_flows')
            .then(async response => {
              if(response && response.ok ){
                const data = await response.json();
                if(data && data.data){
                  var counter = 0;
                  data.data.map(machineFlow =>{
                    if(!machineFlow.data_stream_uuid){
                      counter = counter +1;
                    }
                  })
                  OrgAssets[index].missingDSConnections = counter
                  
                  if(Object.keys(OrgAssets).length -1 == index){
                    this.setState({
                      filtered_organization_assets_list: OrgAssets,
                    })
                  }
                }
              }
            });
          })
          
          
        }
      }
    });

    // Get list of tags assets for organization
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/assets/tags')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            organization_assets_tags: data.data,
          },()=>{
            let keys = [];
            this.state.organization_assets_tags.map(tagKey => {
              keys[tagKey.tag_key] = [];
            })
            this.setState({organizationTagKeysDic: keys})
            
          })
        }
      }
    });
  }


  componentDidUpdate(prevProps, prevState){
    const { assertSearchText} = this.state
    
    if(assertSearchText != prevState.assertSearchText){
      this.updateURLTagSearch([],"")
    }

    if(this.props.location.search != prevProps.location.search){
      // Get list of assets for organization
      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/assets' + this.props.location.search )
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(data.data){
            this.setState({
              filtered_organization_assets_list: data.data,
            })
          }
        }
      });
    }

  }

  updateURLTagSearch = (tagArray, tagQuery) => {
    const params = new URLSearchParams()

    
    if (this.state.assertSearchText) {
      params.append("q", this.state.assertSearchText)
    } else {
      params.delete("q")
    }

    let removeSpaceTagQuery = ""
    let dic = this.state.organizationTagKeysDic;
    if(tagQuery){
      removeSpaceTagQuery = tagQuery
      // removeSpaceTagQuery = tagQuery.split(' ').join('_');
      dic[removeSpaceTagQuery] = tagArray
    }
    Object.keys(dic).map(key =>{
      if(dic[key] && dic[key].length > 0){
        params.append(`tag_${key}`, dic[key])
      }else{
          params.delete(`tag_${key}`)
        }
    })

    this.setState({
      organizationTagKeysDic: dic
    })

    this.props.history.push({search: params.toString()})
  }


  refreshAssetList = () => {
     // Refresh list of assets for organization
     getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/assets' + this.props.location.search)
     .then(async response => {
       if(response.ok ){
         const data = await response.json();
         if(data.data){
           this.setState({
            filtered_organization_assets_list: data.data,
           })
         }
       }
     });
  }

  handleChangePage = (event, newPage) => {
    this.setState({page: newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  handleChangeAssertSearchText = (e) => {
    this.setState({assertSearchText: e.target.value});
  }

  handleClickedAsset = (asset) => {
    if(asset == "Piston-SAT-Assets"){
      this.setState({
        toPath: `assets/sat/piston-group-assets`
      }, ()=>{
        this.setState({redirect: true})
      })
    }else if(asset ==='bechtel-group-assets'){
      this.setState({
        toPath: `assets/sat/bechtel-group-assets`
      }, ()=>{
        this.setState({redirect: true})
      })
    }else{
      this.setState({
        toPath: `assets/${asset.asset_uuid}`
      }, ()=>{
        this.setState({redirect: true})
      })
    }
    
  }


  render(){
    const { classes} = this.props
    const { rowsPerPage,
            page,
            organization_assets_list,
            assertSearchText,
            redirect,
            filtered_organization_assets_list,
            organization_assets_tags,
            tagStates} = this.state

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, dummyAssetData.length - page * rowsPerPage);
    

    if (redirect) {
      return <Redirect push to={{pathname: this.state.toPath }} />
    }


    return (
      <div className={classes.root} style={{marginBottom: '60px'}}>
        <div className={classes.breadcrumbCSS}>
          <Breadcrumbs  separator="›" aria-label="breadcrumb">
            <Typography className={classes.fontStyles} style={{color:'#757575'}}>{this.props.selectedOrganization.organization_name}</Typography>
            <Typography className={classes.fontStyles} color="textPrimary">Assets</Typography>
          </Breadcrumbs>
        </div>
        <div className={classes.pageContainer}>
          {/* Title and add asset button */}
          <div style={{display:'flex', padding:'0px 16px'}}>
            <div style={{marginRight:'8px'}}>
              <h3 style={{color:'#374151', fontSize:'24px', fontWeight:700, fontFamily:'Trebuchet MS',}}>Assets</h3>
            </div>
            <div style={{marginLeft:'auto', minWidth:'115px', marginTop:'auto', marginBottom:'auto'}}>
              <AddAssetInOrganizationModal  refreshAssetList={this.refreshAssetList} />
            </div>
          </div>
          <div style={{padding:'0px 16px'}}>
            <div className={classes.inputContainer} >
              <div className={classes.searchIconContainer} >
                  <svg xmlns="http://www.w3.org/2000/svg" className={classes.searchIcon}   fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
              </div>
              <input className={classes.inputCSS} value={assertSearchText} onChange={this.handleChangeAssertSearchText}  placeholder="Search assets..." />
            </div>
          </div>
          {Object.keys(organization_assets_tags).length > 0 &&
          <div style={{display:'flex', padding:'0px 16px'}}>
            {organization_assets_tags.map(currentTagKey => (
              <div key={currentTagKey.tag_key}>
                <TagSelectionButton currentTagKey={currentTagKey} tagStates={tagStates} assertSearchText={assertSearchText} updateURLTagSearch={this.updateURLTagSearch} />   
              </div>
              
            ))}
            </div>
          }

          {/* List of assets */}
          <div style={{margin: '24px'}}>
          <Grid container spacing={3} >

            {/* Testing for multiple assets in one dashboard */}
            {this.props.selectedOrganization.organization_uuid == 'org_1570ab30abddcfec3d2bf2115dbe7ca2' &&
            <Grid  item xs={12} sm={6} md={6} lg={4}>
              <CardContainer >
                <div style={{display:'flex', flexDirection:'column',justifyContent:'space-between', overflow:'hidden', width:'100%',padding:'8px'}} >
                  <div style={{display:'flex'}}>
                    {/* Empty space to separate content and have a onClick */}
                    <div style={{width:'100%'}} onClick={() => this.handleClickedAsset('Piston-SAT-Assets')}>
                      &nbsp;
                    </div>
                    {/* Vertical dot button for dropdown of options for asset */}
                    <div >
                      {/* <MoreVertIcon /> */}
                        {/* <MenuAssetButton asset={asset} refreshAssetList={this.refreshAssetList} /> */}
                    </div>
                  </div>
                  <div 
                    onClick={() => this.handleClickedAsset('Piston-SAT-Assets')}
                    style={{display:'flex', flexDirection:'column',overflow:'hidden', justifyContent:'center', width:'100%', height:'100%'}} 
                    >
                      <div style={{padding:'8px'}}>
                        <h4 style={{color:'#374151', fontSize:'22px', fontWeight:600, fontFamily:'Trebuchet MS',overflow: 'hidden', textOverflow: 'ellipsis'}}>SAT Piston Dashboard</h4>
                      </div>
                      {/* <div style={{padding:'12px', textAlign:'right'}}>
                        <h5 style={{color:'#374151', fontSize:'14px',lineHeight:'20px', fontWeight:400, fontFamily:'Trebuchet MS',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>RA16NM, RA17NM, RA13NM</h5>
                      </div> */}
                  </div>  
                  <div style={{display:'flex', justifyContent: 'center'}} onClick={() => this.handleClickedAsset('Piston-SAT-Assets')}>
                    <div style={{padding:'4px 4px 48px 4px'}}>
                        <AvatarGroup max={4} >
                            <Avatar alt="Piston SAT"  src="https://www.pistonautomotive.com/wp-content/uploads/2021/03/footer-logoAsset-4.png" variant="rounded"
                              sx={{ 
                                width: { xs: 206, sm: 206, md:206 }, 
                                height:  { xs: 40, sm: 40, md:40 }, 
                                borderRadius:'10px',
                              }}
                              style={{border: '2px solid transparent'}}
                            />
                            {/* <Avatar alt="Piston SAT"  src="https://iotflows-manufacturer-machine-pictures.s3.amazonaws.com/mch_f7aedcdba86d9aab7b1c0c38fb00f847.png" variant="rounded"
                              sx={{ 
                                width: { xs: 75, sm: 75, md:75 }, 
                                height:  { xs: 75, sm: 75, md:75 }, 
                                borderRadius:'10px',
                              }}
                              style={{border: '2px solid transparent'}}
                            /> */}
                        </AvatarGroup>
                    </div>
                  </div>
                </div>
                
              </CardContainer>
              </Grid>
              }
            {/* Testing for multiple assets in one dashboard */}
            {this.props.selectedOrganization.organization_uuid == 'org_1570ab30abddcfec3d2bf2115dbe7ca2' &&
            <Grid  item xs={12} sm={6} md={6} lg={4}>
              <CardContainer >
                <div style={{display:'flex', flexDirection:'column',justifyContent:'space-between', overflow:'hidden', width:'100%',padding:'8px'}} >
                  <div style={{display:'flex'}}>
                    {/* Empty space to separate content and have a onClick */}
                    <div style={{width:'100%'}} onClick={() => this.handleClickedAsset('bechtel-group-assets')}>
                      &nbsp;
                    </div>
                    {/* Vertical dot button for dropdown of options for asset */}
                    <div >
                    </div>
                  </div>
                  <div 
                    onClick={() => this.handleClickedAsset('bechtel-group-assets')}
                    style={{display:'flex', flexDirection:'column',overflow:'hidden', justifyContent:'center', width:'100%', height:'100%'}} 
                    >
                      <div style={{padding:'8px'}}>
                        <h4 style={{color:'#374151', fontSize:'22px', fontWeight:600, fontFamily:'Trebuchet MS',overflow: 'hidden', textOverflow: 'ellipsis'}}>SAT Bechtel Dashboard</h4>
                      </div>
                  </div>  
                  <div style={{display:'flex', justifyContent: 'center'}} onClick={() => this.handleClickedAsset('bechtel-group-assets')}>
                    <div style={{padding:'4px 4px 16px 4px'}}>
                        <AvatarGroup max={4} >
                            <Avatar alt="Bechtel SAT"  src="https://upload.wikimedia.org/wikipedia/en/thumb/8/88/Bechtel_logo.svg/1200px-Bechtel_logo.svg.png" variant="rounded"
                              sx={{ 
                                width: { xs: 75, sm: 75, md:75 }, 
                                height:  { xs: 63, sm: 63, md:63 }, 
                                borderRadius:'10px',
                              }}
                              style={{border: '2px solid transparent'}}
                            />
                            <Avatar alt="Bechtel SAT"  src="https://iotflows-manufacturer-machine-pictures.s3.amazonaws.com/mch_f7aedcdba86d9aab7b1c0c38fb00f847.png" variant="rounded"
                              sx={{ 
                                width: { xs: 75, sm: 75, md:75 }, 
                                height:  { xs: 75, sm: 75, md:75 }, 
                                borderRadius:'10px',
                              }}
                              style={{border: '2px solid transparent'}}
                            />
                        </AvatarGroup>
                    </div>
                  </div>
                </div>
                
              </CardContainer>
              </Grid>
              }
            {/* Testing for multiple assets in one dashboard */}
            {Object.keys(filtered_organization_assets_list).length > 0 &&
              filtered_organization_assets_list.map(asset => (
              <Grid key={asset.asset_id} item xs={12} sm={6} md={6} lg={4}>
              <CardContainer key={asset.asset_id} >
                {/* <CardShadow /> */}
                <div style={{display:'flex'}} onClick={() => this.handleClickedAsset(asset)}>
                  <div className={classes.statusBar}/>
                  <div style={{padding:'4px'}}>
                    {asset.asset_custom_picture_url &&
                      <Avatar
                        alt={asset.asset_custom_name}
                        src={asset.asset_custom_picture_url}
                        sx={{ 
                          width: { xs: 192, sm: 142, md:192 }, 
                          height:  { xs: 192, sm: 142, md:192 }, 
                          borderRadius:'10px' 
                        }}
                        variant="rounded"
                      />
                    }
                    {!asset.asset_custom_picture_url && asset.machine_picture_url &&
                    <Avatar
                      alt={asset.machine_name}
                      src={asset.machine_picture_url}
                      sx={{ 
                        width: { xs: 192, sm: 142, md:192 }, 
                        height:  { xs: 192, sm: 142, md:192 }, 
                        borderRadius:'10px' 
                      }}
                      variant="rounded"
                    />
                    }
                  </div>
                  {/* <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div style={{padding:'8px'}}>
                      <span className="heartbeatOnline"></span>
                    </div>
                    <div style={{padding:'8px'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="#22c55e"viewBox="0 0 16 16">
                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                        <path width="1em" height="1em" fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"/>
                      </svg>
                    </div>
                    <div style={{padding:'8px'}}>
                      <svg width="1.5em" height="1.5em" viewBox="0 0 16 16"  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3 12.44 12.44 0 0 0 .663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404l.785-.785c.63.24 1.228.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.934-.933a6.454 6.454 0 0 1 2.012.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905l.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .048.737.518.518 0 0 1-.668.05 11.496 11.496 0 0 0-1.812-1.07zM9.02 11.78c.238.14.236.464.04.66l-.706.706a.5.5 0 0 1-.708 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.373 0 .722.102 1.02.28zm4.355-9.905a.53.53 0 1 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"></path>
                      </svg>
                    </div>
                    <div style={{padding:'8px'}}>
                      <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="#b91c1c" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1L1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"></path>
                          <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"></path>
                      </svg>
                    </div>
                  </div> */}
                </div>
                <div style={{display:'flex', flexDirection:'column',overflow:'hidden', width:'100%',padding:'8px'}} >
                  <div style={{display:'flex'}}>
                    <div style={{position:'relative',}} onClick={() => this.handleClickedAsset(asset)}>
                        <Avatar
                          alt={asset.manufacturer_name}
                          src={asset.manufacturer_logo_url}
                          sx={{ width: 35, height: 35 }}
                          variant="rounded"
                        />
                      {asset.manufacturer_is_verified &&
                        <div style={{position:'absolute', top:'-9px', left:'24px'}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" fill="#2563eb" viewBox="0 0 16 16">
                            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
                          </svg>
                        </div>
                      }
                    </div>
                    {/* Empty space to separate content and have a onClick */}
                    <div style={{width:'100%'}} onClick={() => this.handleClickedAsset(asset)}>
                      &nbsp;
                    </div>
                    {/* Vertical dot button for dropdown of options for asset */}
                    <div >
                        <MenuAssetButton asset={asset} refreshAssetList={this.refreshAssetList} />
                    </div>

                  </div>
                  <div 
                    onClick={() => this.handleClickedAsset(asset)}
                    style={{display:'flex', flexDirection:'column',overflow:'hidden', justifyContent:'space-between', width:'100%', height:'100%'}} 
                    >
                    {asset.asset_custom_name &&
                      <div style={{padding:'8px'}}>
                        <h5 style={{color:'#374151', fontSize:'20px', fontWeight:600, fontFamily:'Trebuchet MS',overflow: 'hidden', textOverflow: 'ellipsis'}}>{asset.asset_custom_name}</h5>
                      </div>
                    }
                    {!asset.asset_custom_name && asset.machine_name &&
                      <div style={{padding:'8px'}}>
                        <h5 style={{color:'#374151', fontSize:'20px', fontWeight:600, fontFamily:'Trebuchet MS',overflow: 'hidden', textOverflow: 'ellipsis'}}>{asset.machine_name}</h5>
                      </div>
                    }
                    {asset.asset_custom_identifier &&
                      <div style={{padding:'12px', textAlign:'right'}}>
                        <h5 style={{color:'#374151', fontSize:'14px',lineHeight:'20px', fontWeight:400, fontFamily:'Trebuchet MS',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{asset.asset_custom_identifier}</h5>
                      </div>
                    }
                    {!asset.asset_custom_identifier && asset.machine_identifier &&
                      <div style={{padding:'12px', textAlign:'right'}}>
                        <h5 style={{color:'#374151', fontSize:'14px',lineHeight:'20px', fontWeight:400, fontFamily:'Trebuchet MS',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{asset.machine_identifier}</h5>
                      </div>
                    } 
                  </div>  
                </div>
                
              </CardContainer>
              </Grid>
            ))}
            </Grid>
          </div>
         </div>
       
        
      </div>
      )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    currentUserInfo: state.users.currentUserInfo,
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(withRouter(OrganizationAssetsPage)));






{/* <TableContainer component={Paper} >
<Table className={classes.table} aria-label="spanning table">
  <colgroup>
    
    <col style={{width:'10%'}}/>
    <col style={{width:'25%'}}/>
    <col style={{width:'25%'}}/>
    <col style={{width:'10%'}}/>
    <col style={{width:'20%'}}/>
    <col style={{width:'10%'}}/>
  </colgroup>
  <TableHead>
    <TableRow>
      <StyledTableCell style={{fontSize: '24px', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={4}>
        <b>Assets</b>
      </StyledTableCell>
      <StyledTableCell  style={{paddingRight: '12px'}} align="right" colSpan={2}>
        <AddAssetModal device_uuid={this.props.device_uuid} refreshAssetList={this.refreshAssetList} />
      </StyledTableCell>
    </TableRow>
    <TableRow>
      <StyledTableCell ></StyledTableCell>
      <StyledTableCell >Name</StyledTableCell>
      <StyledTableCell >Description</StyledTableCell>
      <StyledTableCell >Tags</StyledTableCell>
      <StyledTableCell >Data Streams Matching</StyledTableCell> 
      <StyledTableCell ></StyledTableCell> 
    </TableRow>
  </TableHead>
  <TableBody>
    {(rowsPerPage > 0
            ? dummyAssetData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : dummyAssetData
          ).map((row, id) => (
      <DropdownRow key={row.name} row={row} refreshAssetList={this.refreshAssetList} />
    ))}
    {emptyRows > 0 && (
          <TableRow style={{ height: 40 * emptyRows }}>
            <TableCell colSpan={7} />
          </TableRow>
        )}
  </TableBody>
</Table>
<TableFooter>
    <TableRow >
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, { label: 'All', value: -1 }]}
        colSpan={3}
        count={dummyAssetData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableRow>
    </TableFooter>
</TableContainer> */}
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, rgbToHex } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import Tooltip from '@material-ui/core/Tooltip';
import '../styles/emailAddressesList.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Divider from '@mui/material/Divider';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    maxWidth: theme.spacing(200),
    minWidth: theme.spacing(90),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('md')]:{
      maxWidth: theme.spacing(110),
      minWidth: theme.spacing(80),
    },
    [theme.breakpoints.down('sm')]:{
      maxWidth: theme.spacing(70),
      minWidth: theme.spacing(60),
    },
    [theme.breakpoints.down('xs')]:{
      maxWidth: theme.spacing(43),
      minWidth: theme.spacing(43),
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'5px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    minWidth:'70px',
    maxHeight: '40px',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    borderRadius: 10, 
    maxWidth: theme.spacing(200),
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class CreateAssetFlowModalNoButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      loading: false,
      success: false,
      machineFlowName: '',
      machineFlowDescription: '',
      dataTypeSelection:'',
      dateTimeFormat: '',
      mergeRequired: false,
      parsingExpression: '',
      parsingExpressionCondition: '',
      parsingExpressionConditionValue: '',
      splitDataType: '',
      dateTimeType:'',
      parsingExpressionDateType: '',
      parsingExpressionDateTypeCondition: '',
      parsingExpressionDateTypeConditionValue: '',
      startingDateTimeRatio: '',
      parsingExpressionSamplingInterval: '',
      parsingExpressionSamplingIntervalCondition: '',
      parsingExpressionSamplingIntervalConditionValue: '',
      topicSelected:'',
      assetInformation:[],
    }
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {


    // Get list of data streams
    getData('https://api.iotflows.com/v1/assets/' + this.props.asset_uuid )
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          console.log('data.data')
          console.log(data.data)
          this.setState({
            assetInformation: data.data[0],
          })
        }
      }
    });


  }


  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
      machineFlowName: '',
      machineFlowDescription: '',
      dataTypeSelection:'',
      dateTimeFormat: '',
      mergeRequired: false,
      parsingExpression: '',
      parsingExpressionCondition: '',
      parsingExpressionConditionValue: '',
      parsingExpressionDateType: '',
      splitDataType: '',
      dateTimeType:'',
      parsingExpressionDateTypeCondition: '',
      parsingExpressionDateTypeConditionValue: '',
      startingDateTimeRatio: '',
      parsingExpressionSamplingInterval: '',
      parsingExpressionSamplingIntervalCondition: '',
      parsingExpressionSamplingIntervalConditionValue: '',
      topicSelected:'',
    });
  };

  handleClose = () => {
    this.props.handleCloseModal()
    this.setState({ 
      open: false,
      loading: false,
      success: false,
      machineFlowName: '',
      machineFlowDescription: '',
      dataTypeSelection:'',
      dateTimeFormat: '',
      mergeRequired: false,
      parsingExpression: '',
      parsingExpressionCondition: '',
      parsingExpressionConditionValue: '',
      parsingExpressionDateType: '',
      splitDataType: '',
      dateTimeType:'',
      parsingExpressionDateTypeCondition: '',
      parsingExpressionDateTypeConditionValue: '',
      startingDateTimeRatio: '',
      parsingExpressionSamplingInterval: '',
      parsingExpressionSamplingIntervalCondition: '',
      parsingExpressionSamplingIntervalConditionValue: '',
      topicSelected:'',
    });
  };

  handleMachineFlowNameChange = (event) => {
    this.setState({machineFlowName: event.target.value});
  }

  handleMachineFlowDescriptionChange = (event) => {
    this.setState({machineFlowDescription: event.target.value});
  }

  handleDataTypeSelectionChange = (event) => {
    this.setState({dataTypeSelection: event.target.value})
  }
  handleDateTimeFormatChange = (event) => {
    this.setState({dateTimeFormat: event.target.value})
  }
  handleDateTimeTypeSelectionChange = (event) => {
    this.setState({dateTimeType: event.target.value})
  }

  handleTopicSelectionChange = (e) => {
    this.setState({
      topicSelected: e.target.value
    })
    
  }


  submitButtonClick = () => {
    const { loading, 
            machineFlowName,
            machineFlowDescription,
            topicSelected,
            dataTypeSelection,
            dateTimeFormat,
            mergeRequired,
            parsingExpression,
            parsingExpressionCondition,
            parsingExpressionConditionValue,
            splitDataType,
            dateTimeType,
            parsingExpressionDateType,
            parsingExpressionDateTypeCondition,
            parsingExpressionDateTypeConditionValue,
            startingDateTimeRatio,
            parsingExpressionSamplingInterval,
            parsingExpressionSamplingIntervalCondition,
            parsingExpressionSamplingIntervalConditionValue,
            assetInformation,
           } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          let payloadParsingExpression = {};
          if(dataTypeSelection == 'timeseries_array' && mergeRequired){
            payloadParsingExpression = {
                "version": "1.1",
                "data_type": dataTypeSelection,
                "merge_require": mergeRequired,
                "data": {                  
                  "expression": parsingExpression,
                  "condition": {
                    "expression": parsingExpressionCondition,
                    "value": parsingExpressionConditionValue
                  },      
                  "split_with": splitDataType
                },
                "datetime": {
                  "type": dateTimeType,
                  "starting_datetime": {
                    "expression": parsingExpressionDateType,
                    "condition": {
                      "expression": parsingExpressionDateTypeCondition,
                      "value": parsingExpressionDateTypeConditionValue
                    }        
                  },
                  "starting_datetime_ratio": startingDateTimeRatio,
                  "sampling_interval":{
                    "expression": parsingExpressionSamplingInterval,
                    "condition": {
                      "expression": parsingExpressionSamplingIntervalCondition,
                      "value": parsingExpressionSamplingIntervalConditionValue
                    }          
                  }
                }
              }
          }else {
            payloadParsingExpression = {
                "version": "1.1",
                "data_type": dataTypeSelection,
                "datetime_format": dateTimeFormat,
                "merge_require": mergeRequired,
                "data": {                  
                  "expression": parsingExpression,
                  "condition": {
                    "expression": parsingExpressionCondition,
                    "value": parsingExpressionConditionValue,
                  }      
                }
              }
          }
          // console.log('payloadParsingExpression')
          // console.log(payloadParsingExpression)

          // console.log("API CALL IN NO BUTTON")
          // console.log('https://api.iotflows.com/v1/machines/' + assetInformation.machine_uuid + '/flows')
          // console.log(machineFlowName)
          // console.log(machineFlowDescription)
          // console.log(topicSelected)
          // console.log(payloadParsingExpression)
        postData('https://api.iotflows.com/v1/machines/' + assetInformation.machine_uuid + '/flows', 
        {'flow_name': machineFlowName,
          'flow_description': machineFlowDescription,
          'flow_mqtt_subtopic': topicSelected,
          'flow_mqtt_payload_parsing_expression': payloadParsingExpression,
        })
          .then(async response => {
            if(response && response.ok){
              const data = await response.json();
              
              this.props.showSuccessSnackbar("Machine Flow has been successfully added!");
              // this.props.refreshAssetList();
              this.handleClose();
              this.props.handleCloseModal()
              this.props.refreshMachineFlowList()
              this.setState({
                loading: false,
                success: true,
                open:false,
              });
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false});
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  




  


  render() {
    const { classes, selectedOrganization } = this.props;
    const { open, 
            loading, 
            success, 
            machineFlowName,
            machineFlowDescription,
            dataTypeSelection,
            dateTimeFormat,
            topicSelected,
            mergeRequired,
            parsingExpression,
            parsingExpressionCondition,
            parsingExpressionConditionValue,
            splitDataType,
            dateTimeType,
            parsingExpressionDateType,
            parsingExpressionDateTypeCondition,
            parsingExpressionDateTypeConditionValue,
            startingDateTimeRatio,
            parsingExpressionSamplingInterval,
            parsingExpressionSamplingIntervalCondition,
            parsingExpressionSamplingIntervalConditionValue} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });


    return (
      <Grid
        container
        alignItems="flex-start"
        justify="flex-start"
        direction="column"
      >
        
        {/* <Button className={classes.button} variant="contained"   onClick={this.handleOpen}><AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Create Machine Flow</Button> */}

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >

                
                    <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Create Machine Flow</DialogTitle>
                    <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px'}}>

                    <TextField
                      style={{marginTop: '0px'}}
                      autoFocus
                      margin="normal"
                      id="machineFlowName"
                      label="Name"
                      type="text"
                      value={machineFlowName} 
                      onChange={this.handleMachineFlowNameChange}
                      fullWidth
                      required
                    />

                    <TextField
                      margin="normal"
                      id="machineFlowDescription"
                      label="Description"
                      type="text"
                      value={machineFlowDescription} 
                      onChange={this.handleMachineFlowDescriptionChange}
                      fullWidth
                    />

                      <div>
                        <TextField 
                          label="Data Stream SubTopic" 
                          placeholder="Assets/{asset_custom_identifier}/Temperature"
                          variant="standard" 
                          fullWidth 
                          value={topicSelected} 
                          onChange={this.handleTopicSelectionChange}
                        />
                      </div>
                         
                        <div>
                          <FormControl sx={{ mt: 3, mb:2, minWidth: 145,width: '100%' }}>
                            <InputLabel id="dataTypeSelectionLabel">Data Type</InputLabel>
                            <Select
                              labelId="dataTypeSelectionLabel"
                              id="dataTypeSelectionId"
                              value={dataTypeSelection}
                              onChange={this.handleDataTypeSelectionChange}
                              label="Data Type"
                              sx={{ width: '100%' }}
                            >
                              {/* <MenuItem value="">
                                <em>None</em>
                              </MenuItem> */}
                              <MenuItem value={'datetime'}>Date time</MenuItem>
                              <MenuItem value={'string'}>String</MenuItem>
                              <MenuItem value={'number'}>Number</MenuItem>
                              <MenuItem value={'timeseries_single_point'}>Time Series Single Point</MenuItem>
                              <MenuItem value={'timeseries_array'}>Time Series Array</MenuItem>
                              <MenuItem value={'timeseries_single_coordinate'}>Time Series Single Coordinate</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      
                      {dataTypeSelection == 'timeseries_array' &&
                        <FormControl sx={{ mb: 2}} component="fieldset">
                          <FormLabel  component="legend">Merge Required</FormLabel>
                          <RadioGroup row aria-label="arc" name="row-radio-merge-required">
                            <FormControlLabel 
                                onChange={(e) => this.setState({mergeRequired: false})}
                                value="false" 
                                control={<Radio />} 
                                label="No" 
                              />
                            <FormControlLabel 
                              onChange={(e) => this.setState({mergeRequired: true})}
                              value="true" 
                              control={<Radio />} 
                              label="Yes" 
                              />
                          </RadioGroup>
                        </FormControl>
                      }
                      {!mergeRequired && dataTypeSelection == 'datetime' &&
                        <div>
                          <FormControl sx={{ mb: 2, minWidth: 145,width: '100%' }}>
                            <InputLabel id="dateTimeFormatLabel">Date Time Format</InputLabel>
                            <Select
                              labelId="dateTimeFormatLabel"
                              id="dateTimeFormatid"
                              value={dateTimeFormat}
                              onChange={this.handleDateTimeFormatChange}
                              label="Date Time Format"
                              sx={{ width: '100%' }}
                            >
                              {/* <MenuItem value="">
                                <em>None</em>
                              </MenuItem> */}
                              <MenuItem value={'time'}>Time</MenuItem>
                              <MenuItem value={'date'}>Date</MenuItem>
                              <MenuItem value={'datetime'}>Date Time</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      }

                      <Divider light fullWidth />
                     
                      <div style={{margin:'16px 0px 16px 8px'}}>
                        <div style={{display: 'flex', flexDirection:'column', marginTop:'8px'}}>
                          <h5 style={{fontSize:'16px', fontWeight:700, fontFamily:'Trebuchet MS',}}>
                            Data
                          </h5>
                        </div>
                        <TextField 
                          label="Parsing Expression" 
                          placeholder=".Data.Parameter[X].value[0]"
                          variant="standard" 
                          fullWidth 
                          value={parsingExpression} 
                          onChange={(e) => this.setState({parsingExpression: e.target.value})}
                        />
                        <div style={{marginTop:'24px', marginLeft:'16px'}}>
                          <h5 style={{fontSize:'16px', fontWeight:500, fontFamily:'Trebuchet MS',}}>
                            Condition:
                          </h5>
                          <div style={{display:'flex'}}>
                            <TextField  
                              label="Expression" 
                              placeholder=".Data.Parameter[X].key[0]"
                              variant="standard" 
                              fullWidth 
                              value={parsingExpressionCondition} 
                              onChange={(e) => this.setState({parsingExpressionCondition: e.target.value})}
                            />
                            <div style={{fontSize:'24px',marginTop:'auto', marginBottom:'auto', marginLeft:'16px', marginRight:'16px'}}>
                              {'='}
                            </div>
                            <TextField 
                              label="Value" 
                              placeholder="Temperature"
                              variant="standard" 
                              fullWidth 
                              value={parsingExpressionConditionValue} 
                              onChange={(e) => this.setState({parsingExpressionConditionValue: e.target.value})}
                            />
                          </div>
                          <TextField 
                              label="Split Data Type" 
                              placeholder=";"
                              variant="standard" 
                              style={{maxWidth: 120, marginTop:'16px'}}
                              value={splitDataType} 
                              onChange={(e) => this.setState({splitDataType: e.target.value})}
                            />
                        </div>
                        

                      </div>
                      {mergeRequired &&
                      <>
                      <Divider light fullWidth />

                      <div style={{margin:'16px 0px 16px 8px'}}>
                        <div style={{display: 'flex', flexDirection:'column', marginTop:'24px'}}>
                          <h5 style={{fontSize:'16px', fontWeight:500, fontFamily:'Trebuchet MS',}}>
                            Date Time
                          </h5>
                        </div>
                        <FormControl sx={{ mt: 1, mb:2, minWidth: 145,width: '100%' }}>
                          <InputLabel id="dateTimeLabel">Date Type</InputLabel>
                          <Select
                            value={dateTimeType}
                            onChange={this.handleDateTimeTypeSelectionChange}
                            label="Data Type"
                            sx={{ width: '100%' }}
                          >
                            {/* <MenuItem value="">
                              <em>None</em>
                            </MenuItem> */}
                            <MenuItem value={'starting_datetime_sampling_interval'}>Starting Datetime Sampling Interval</MenuItem>
                          </Select>
                        </FormControl>
                        <div style={{marginTop:'24px', marginLeft:'16px'}}>
                          <h5 style={{fontSize:'16px', fontWeight:500, fontFamily:'Trebuchet MS',}}>
                            Starting Date Time
                          </h5>
                          <TextField 
                              label="Parsing Expression" 
                              placeholder=".Data.Parameter[X].value[0]"
                              variant="standard" 
                              fullWidth 
                              value={parsingExpressionDateType} 
                              onChange={(e) => this.setState({parsingExpressionDateType: e.target.value})}
                            />

                          <div style={{marginTop:'24px', marginLeft:'16px'}}>
                            <h5 style={{fontSize:'16px', fontWeight:500, fontFamily:'Trebuchet MS',}}>
                              Condition
                            </h5>
                            <div style={{display:'flex'}}>
                              <TextField  
                                label="Expression" 
                                placeholder=".Data.Parameter[X].key[0]"
                                variant="standard" 
                                fullWidth 
                                value={parsingExpressionDateTypeCondition} 
                                onChange={(e) => this.setState({parsingExpressionDateTypeCondition: e.target.value})}
                              />
                              <div style={{fontSize:'24px',marginTop:'auto', marginBottom:'auto', marginLeft:'16px', marginRight:'16px'}}>
                                {'='}
                              </div>
                              <TextField 
                                label="Value" 
                                placeholder="DateTime"
                                variant="standard" 
                                fullWidth 
                                value={parsingExpressionDateTypeConditionValue} 
                                onChange={(e) => this.setState({parsingExpressionDateTypeConditionValue: e.target.value})}
                              />
                            </div>
                            <TextField 
                                label="Starting Date Time Ratio" 
                                placeholder="1"
                                variant="standard" 
                                style={{maxWidth: 190, marginTop:'16px', width:'100%'}}
                                value={startingDateTimeRatio} 
                                onChange={(e) => this.setState({startingDateTimeRatio: e.target.value})}
                              />
                          </div>
                        </div>
                        <div style={{marginTop:'24px', marginLeft:'16px'}}>
                          <h5 style={{fontSize:'16px', fontWeight:500, fontFamily:'Trebuchet MS',}}>
                            Sampling Interval
                          </h5>
                          <TextField 
                              label="Parsing Expression" 
                              placeholder=".Data.Parameter[X].key[0]"
                              variant="standard" 
                              fullWidth 
                              value={parsingExpressionSamplingInterval} 
                              onChange={(e) => this.setState({parsingExpressionSamplingInterval: e.target.value})}
                            />

                          <div style={{marginTop:'24px', marginLeft:'16px'}}>
                            <h5 style={{fontSize:'16px', fontWeight:500, fontFamily:'Trebuchet MS',}}>
                              Condition
                            </h5>
                            <div style={{display:'flex'}}>
                              <TextField  
                                label="Expression" 
                                placeholder=".Data.Parameter[X].key[0]"
                                variant="standard" 
                                fullWidth 
                                value={parsingExpressionSamplingIntervalCondition} 
                                onChange={(e) => this.setState({parsingExpressionSamplingIntervalCondition: e.target.value})}
                              />
                              <div style={{fontSize:'24px',marginTop:'auto', marginBottom:'auto', marginLeft:'16px', marginRight:'16px'}}>
                                {'='}
                              </div>
                              <TextField 
                                label="Value" 
                                placeholder="DateTime"
                                variant="standard" 
                                fullWidth 
                                value={parsingExpressionSamplingIntervalConditionValue} 
                                onChange={(e) => this.setState({parsingExpressionSamplingIntervalConditionValue: e.target.value})}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      </>
                      }

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading || (machineFlowName == '') || (topicSelected=='')} onClick={this.submitButtonClick}>
                    {'Create'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(CreateAssetFlowModalNoButton));




import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import  {deleteData } from '../ApiCalls/DataApis';
import {fetchOrganizations} from '../store/actions/organizationActions';
import LastMemberAdminModal from './LastMemberAdminModal';



const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class LeaveOrganizationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      loading: false,
      success: false,
      lastMemberAdminModalOpen: false,
    };

    this.handleCloseModalLastMember = this.handleCloseModalLastMember.bind(this);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      success: false,
      loading: false });
  };

  componentWillReceiveProps({open}) {
    this.setState({...this.state,open})
  }

  submitButtonClick = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {

        deleteData('https://api.iotflows.com/v1/organizations/' + this.props.organizationInfo.organization_uuid + '/members', 
        {'member_username': this.props.currentUserInfo[0].user_username,
        'delete_mode': 'delete_safe'})
          .then(async response => {
            if(response.status == 202){ 
              const data = await response.json();
              // console.log(data)
              this.setState({lastMemberAdminModalOpen: true}, () => {
                this.handleClose();
                this.props.handleCloseModal();
              });
              this.setState({ 
                open: false,
                success: false,
                loading: false 
              });
              
            }else if(response.ok){ 
              this.props.showSuccessSnackbar("Member has been successfully removed!"); 
              this.setState({
                loading: false,
                success: true,
                open:false,
              },() => { 
                this.handleClose();
                this.props.fetchOrganizations();
                this.props.handleCloseModal();
              });
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false,
                },() => { 
                  this.props.handleCloseModal();
                });
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  handleCloseModalLastMember(){
    this.setState({ 
      lastMemberAdminModalOpen: false,
    });
  }

  

  render() {
    const { classes } = this.props;
    const { open, loading, success, lastMemberAdminModalOpen } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
        {/* <Button className={classes.button} variant="outlined"  onClick={this.handleOpen}>Leave Org</Button> */}
       
        
        
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title"> Leave Organization {this.props.organizationInfo.organization_name} </DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '20px'}}>
                
                  <DialogContentText style={{margin: '0px'}} id="alert-dialog-description">
                    Are you sure you want to leave organization {this.props.organizationInfo.organization_name}?
                  </DialogContentText>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Leave { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>

        <LastMemberAdminModal 
          open={lastMemberAdminModalOpen} 
          lastMember={"You are "} 
          user_username={this.props.currentUserInfo[0].user_username} 
          refreshMemberList={this.props.fetchOrganizations}
          handleCloseModalLastMember={this.handleCloseModalLastMember} />
        
      </Grid>
    );
  }
}

LeaveOrganizationModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  fetchOrganizations: () => dispatch(fetchOrganizations()),

})

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(LeaveOrganizationModal));

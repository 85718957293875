import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import DeleteModal from '../components/DeleteModal';
import ChangeMemberRoleModal from '../components/ChangeMemberRoleModal';
import AddMemberModal from '../components/AddMemberModal';
import SendInviteModal from '../components/SendInviteModal';
import Row from 'react-bootstrap/Row';
import {getData, postData} from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import {fetchOrganizations, fetchSelectedOrganization} from '../store/actions/organizationActions';


const styles = theme => ({
  root: {
    flexGrow: 1,
    
  },
  container: {
    justifyContent:'center',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.spacing(1),  
    marginLeft: 'auto',
    marginRight: 'auto',
    alignContent: 'center',
    alignItems: 'center',
    maxWidth: '850px',
    // maxHeight: '100vh',
    // overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      gridGap: theme.spacing(1),
    },

  },
  paper: {
    paddingRight: theme.spacing(2),
    margin: '0px',
    alignItems: 'center',
    alignContent: 'center', 
    justifyContent: 'center',
    backgroundColor: '#eeeeee',
  },
  image: {
    width: 85,
    height: 85,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    border: '2px solid #fafafa',
  },
  centerItems:{
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',

  },
  btnContainer: {
    paddingTop:theme.spacing(0),
    padding: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '850px',
    justifyContent:'flex-end',
    // alignContent: 'flex-end',
    // alignItems: 'flex-end',
  },
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '0px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
    
  },
  dropdownBtn: {
    color:'#757575', 
    outline: 'none',
    borderRadius: '20px',
    padding: '6px',
    cursor: 'pointer',
    '&:hover': {
      color:'#fafafa',
      backgroundColor:'#d1d1d1',
    },
    '&:active': {
      color:'#fafafa',
      backgroundColor:'#bdbdbd',
    },
  },
});


class MembersListPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      members_list: []
    }
    this.refreshMemberList = this.refreshMemberList.bind(this);
  }

  componentDidMount() {
    
    getData(this.props.memberApi)
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        this.setState({
          members_list: data.data,
        })
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.allOrganizations == ''){
      if(this.props.selectedOrganization == undefined){
        this.props.fetchSelectedOrganization(this.props.allOrganizations[0])
      }
    }
  }


  refreshMemberList() {
    getData(this.props.memberApi)
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        this.setState({
          members_list: data.data,
        })
      }
    });
    this.props.fetchOrganizations();

  }

  render(){
    const {classes, selectedOrganization} = this.props

    return(

      <div className={classes.root} style={{marginBottom: '60px'}}>
        {this.props.listType == 'Project' ? null : 
          <div className={classes.breadcrumbCSS}>
            <Breadcrumbs  separator="›" aria-label="breadcrumb">
              {/* <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/" >
                {this.props.selectedOrganization.organization_name}
              </Link> */}
              {/* <Link color="inherit" href="/projects" >
                Projects
              </Link> */}
              <Typography className={classes.fontStyles} style={{color:'#757575'}}>{this.props.selectedOrganization.organization_name}</Typography>
              <Typography className={classes.fontStyles} color="textPrimary">Members</Typography>
            </Breadcrumbs>
          </div>
        }
      
        {/* <Grid container spacing={3} className={classes.btnContainer}>
          <Grid item xs={6} md={9} style={{ justifyContent:'flex-end'}}>
            <SendInviteModal />
          </Grid>
          <Grid item xs={12} >
            <AddMemberModal style={{ justifyContent:'flex-end'}} 
              memberApi = {this.props.memberApi} 
              roleApi = {this.props.roleApi} 
              searchMemberApi={this.props.searchMemberApi}  
              refreshMemberList={this.refreshMemberList}
              disableBtn = {selectedOrganization.organization_is_personal}
            />
          </Grid>
        </Grid> */}
          <Row className={classes.btnContainer}>
            <SendInviteModal style={{ justifyContent:'flex-end'}}
              refreshMemberList={this.refreshMemberList}
             />
            <AddMemberModal style={{ justifyContent:'flex-end'}} 
              memberApi = {this.props.memberApi} 
              roleApi = {this.props.roleApi} 
              searchMemberApi={this.props.searchMemberApi}  
              refreshMemberList={this.refreshMemberList}
              disableBtn = {selectedOrganization.organization_is_personal}
            />
          </Row>
        
        
        <Container className={classes.container}>

        {this.state.members_list.map((member, index) => (
          <Paper key={index.toString()} className={classes.paper}>
            <Grid container style={{alignItems:'center', alignContent: 'center', justifyContent: 'center', margin: '0px' }} spacing={2}>
              
              <Grid item>
                <ButtonBase className={classes.image}>
                  <Avatar className={classes.img, classes.large} alt={member.user_first_name} src={member.user_image_url}  />
                </ButtonBase>
              </Grid>

              <Grid item xs={12} sm container className={classes.centerItems} >
                <Grid item xs container direction="column" spacing={2}  >
                  <Grid item xs style={ {textAlign: 'left', width:'235px'}} >
                    {member.user_invite_pending?
                      <Typography gutterBottom variant="h6" style={ {color: 'rgba(130, 113, 201,1)', fontSize: 14}}>
                        Invitation sent
                      </Typography>
                      :
                      <Typography gutterBottom variant="h6" style={ {color: '#0d47a1', fontSize: 14}}>
                        {member.user_first_name}&nbsp;{member.user_last_name}
                      </Typography>
                    }
                    <Typography variant="body1" gutterBottom style={ {fontSize: 12}}>
                      {this.props.listType == 'Project' ? member.project_member_role_name: member.organization_member_role_name } 
                    </Typography>
                    <Typography variant="body1" gutterBottom style={ {color: 'rgba(0,0,0,0.7)',fontSize: 12}}>
                      {member.user_email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <div edge="end" className={classes.dropdownBtn} >
                    <Dropdown >
                        <Dropdown.Toggle as={EditOutlinedIcon}  >
                        </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="1" style={{padding:'0px', cursor: 'pointer'}} disabled={(selectedOrganization != undefined && !selectedOrganization.organization_is_personal) ? false : true} > <ChangeMemberRoleModal refreshMemberList={this.refreshMemberList} roleApi = {this.props.roleApi} memberApi = {this.props.memberApi} user_username = {member.user_username} /> </Dropdown.Item>
                        <Dropdown.Item eventKey="2" style={{padding:'0px', cursor: 'pointer'}} disabled={(selectedOrganization != undefined && !selectedOrganization.organization_is_personal) ? false : true} > <DeleteModal refreshMemberList={this.refreshMemberList} modalButton = {'Delete Member'} dialogTitle={'Remove Member'} removingItem={'Member'} memberApi = {this.props.memberApi} user_username = {member.user_username}/> </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Grid>

              </Grid>
            </Grid>
            
          </Paper>
      ))}

      
    </Container>
    </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchSelectedOrganization: currentOrganization => dispatch(fetchSelectedOrganization(currentOrganization)),
  // fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  fetchOrganizations: () => dispatch(fetchOrganizations()),
})

const mapStateToProps = state => {
  return {
    // currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MembersListPage))
const DarkTheme = {
  backgroundColor: "rgba(16, 18, 22, 1)",
  // backgroundColor: "fff",
  color: "#fff",
}

const LightDarkTheme = {
  backgroundColor: "rgba(16, 18, 22, 1)",
  color: "rgba(255,255,255,0.8)",
}
const ExtraLightDarkTheme = {
  backgroundColor: "rgba(16, 18, 22, 1)",
  color: "rgba(255,255,255,0.5)",
}

const IoTFlowsColorDark = {
  backgroundColor: "rgb(26,29,33)",
  color: "#fff",
}


export const globalStyles = {
  DarkTheme: DarkTheme,
  IoTFlowsColorDark: IoTFlowsColorDark,
  LightDarkTheme: LightDarkTheme,
  ExtraLightDarkTheme: ExtraLightDarkTheme,
}
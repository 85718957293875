import React from "react";
import _ from "lodash";
import { WidthProvider, Responsive } from "react-grid-layout";
import {withRouter} from 'react-router-dom';
import "./resizeStyles.css"
import "./reactGridStyles.css"
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@mui/material/Grid';
import  { getData, putData } from '../ApiCalls/DataApis';
import Checkbox from '@mui/material/Checkbox';
import 'rsuite/dist/styles/rsuite-default.css';
import { DateRangePicker } from 'rsuite';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import {IoTFlowsHeatmap} from './IoTFlowsHeatmap'
import TableSATWidget from './TableSATWidget'
import PartIdSelection from './PartIdSelection'
import LinearProgress from '@mui/material/LinearProgress';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import CircularProgress from '@mui/material/CircularProgress';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);


const {
  allowedMaxDays,
  allowedDays,
  allowedRange,
  beforeToday,
  afterToday,
  combine
} = DateRangePicker;

const controllerList = [
  {
      ip_address: '10.20.14.145',
      controller: "MHT-2",
      port: '80',
      device_connection_is_enabled: true,
  },
  {
      ip_address: '10.20.14.142',
      controller: "MHT-1",
      port: '80',
      device_connection_is_enabled: true,
  },
  {
      ip_address: "10.20.14.69",
      controller: "P702-CMA-S023-D1",
      port: '80',
      device_connection_is_enabled: true,
  },
  {
      ip_address: "10.20.14.70",
      controller: "P702-CMA-S075-D1",
      port: '80',
      device_connection_is_enabled: true,
  },
  {
      ip_address: "10.20.14.71",
      controller: "P702-CMA-S130-D1",
      port: '80',
      device_connection_is_enabled: true,
  },
  {
      ip_address: "10.20.14.78",
      controller: "P702-CMA-S150-D1",
      port: '80',
      device_connection_is_enabled: true,
  },
  {
      ip_address: "10.20.14.34",
      controller: "P702-CMA-S100-D1",
      port: '80',
      device_connection_is_enabled: true,
  },
  {
      ip_address: "10.20.14.35",
      controller: "P702-CMA-S100-D2",
      port: '80',
      device_connection_is_enabled: true,
  },
  {
      ip_address: "10.20.14.36",
      controller: "P702-CMA-S100-D3",
      port: '80',
      device_connection_is_enabled: true,
  },
  {
      ip_address: "10.20.14.58",
      controller: "P702-PDB-S030-D1",
      port: '80',
      device_connection_is_enabled: true,
  },
  {
      ip_address: "10.20.14.62",
      controller: "P702-PDB-S060-D1",
      port: '80',
      device_connection_is_enabled: true,
  },
  
]


const styles = theme => ({
    button: {
        backgroundColor:'transparent',
        borderRadius:'5px',
        border:'1px solid #4f86ce',
        display:'inline-block',
        minWidth:'70px',
        maxHeight: '40px',
        cursor:'pointer',
        color:'#4f87cc',
        fontFamily:'Trebuchet MS',
        fontSize:'14px',
        // font-weight:bold;
        padding:'4px 10px',
        textDecoration:'none',
        textShadow:'0px 0px 0px #9eb9ff',
        outline: 'none',
        '&:hover': {
            backgroundColor:'transparent',
        },
        '&:active': {
            position:'relative',
            top:'1px',
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    cardRoot: {
        margin: '8px',
        borderRadius:'20px', 
        // boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1), 0px -4px 15px -2px rgba(0,0,0,0.1)',
        backgroundColor:'transparent', 
        border:'0px solid transparent'
      },
    cardRootMini: {
        margin: '8px',
        borderRadius:'20px', 
        boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1), 0px -4px 15px -2px rgba(0,0,0,0.1)',
      },
    tableContainer: {
      borderRadius: '10px',
    },
    table: {
      minWidth: 450,
    },
    colorPrimary: {
      backgroundColor: '#4f86ce',
    },
    barColorPrimary: {
      backgroundColor: '#bfdaff',
    },
    buttonProgress: {
      height: '2px'
    },
    breadcrumbCSS: {
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      paddingBottom: '3px',
    },
    fontStyles: {
      fontFamily:'Trebuchet MS',
      fontSize: '14px',
    },
    downloadButton: {
      backgroundColor:'transparent',
      borderRadius:'5px',
      border:'1px solid #757575',
      display:'inline-block',
      cursor:'pointer',
      color:'#757575',
      fontFamily:'Trebuchet MS',
      fontSize:'12px',
      // font-weight:bold;
      padding:'2px 14px',
      textDecoration:'none',
      textShadow:'0px 0px 0px #9eb9ff',
      outline: 'none',
      '&:hover': {
        backgroundColor:'rgba(117, 117, 117,0.1)',
      },
      '&:active': {
        position:'relative',
        top:'1px',
        outline: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    filtersSection:{
      padding:'0px 8px',
      display:'flex', 
      justifyContent:'flex-end',
      alignItems:'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding:'8px',
      }

    } ,
    backBtn:{
      fontSize:'18px',
      padding:'6px',
      width:'31px',
      height:'32px',
      borderRadius:'25px',
      cursor:'pointer',
      '&:hover': {
        backgroundColor:'rgba(117, 117, 117,0.2)',
      },
    },
    refreshBtn:{
      border:'0px solid #fff',
      marginBottom:'4px',
      padding:'4px',
      cursor:'pointer',
      backgroundColor:'transparent',
      borderRadius:'8px',
      '&:hover': {
        backgroundColor:'rgba(117, 117, 117,0.2)',
      },
    },
    inputContainer:{
      width: 'fit-content',
      border: '0px solid #E5E7EB',
      padding: '0px',
      textAlign: 'left',
      backgroundColor: "transparent",
      position: 'relative',
    },
    searchResults: {
      minWidth: 'max-content',
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      zIndex: 300,
      backgroundColor: '#fff',
      padding: '0.5rem',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      inset: '40px auto auto 0px',
      borderRadius: '5px',
      maxHeight: '500px',
      overflow: 'auto',
    },
    resultContainer: {
      width: '100%',
      display: 'flex',
      padding: '8px 8px 8px 12px',
      cursor: 'pointer',
      "&:hover": {
        backgroundColor: '#F3F4F6',
      }
    },
    searchText: {
      fontSize: '14px',
      color: '#6B7280',
      fontWeight: 700,
      fontFamily:'Trebuchet MS',
    },
})


/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */
class DashboardSATPiston extends React.PureComponent {
      constructor(props) {
        super(props);
        this.state = {
            testData: [],
            testDataTable: [],
            checked1: true,
            controllerCheck1: true,
            controllerCheck2: true,
            controller1Dic:{},
            controller2Dic:{},
            loading:true,
            startDate: "",
            endDate: "",
            selectedSpindleTopics:'',
            selectedSpindles:[],
            sat_status_per_day: {},
            sat_status_per_day_oks: {},
            partIds:[],
            selectedPartIds:[],
            // new controller states
            controllerArray:[],
            controllerArrayCheckedStates:[],
            showAlphatoolboxList: false,
            selectedControllerIFrame:controllerList[0],
            loadingToggle:false,
            loadingController: false
        };
      }

      componentDidMount() {  
              
  
        // hack: read sat_status_per_day
        var self = this
        this.fetchSatStatusPerDay(null,null,null)                        
        
        // let start = (new Date( (new Date()).getTime() - (3 * 24 * 60 * 60 * 1000))).toLocaleDateString('en-CA')
        let start = (new Date( (new Date()).getTime() - (3 * 24 * 60 * 60 * 1000))).toISOString()

        // let end = (new Date()).toLocaleDateString('en-CA') 
        let end = (new Date()).toISOString() 
        
        this.setState({
          startDate: start,
          endDate: end,
        })  
        getData(`https://api.iotflows.com/v1/data_streams/ds_fd37b109318595ffa73d2ef2622d3815/sat_data?starting_date=${start}&ending_date=${end}`)
        .then(async response => {
          if(response && response.ok ){
              const data = await response.json();
              if(data && data.data ){
                  this.setState({
                    testData: data,
                    testDataTable: data.data,
                  })  
                  if(data.counts){
                    // // console.log('data for controllers')
                    // console.log(data)
                    // Create Array of controllers
                    let controllerArray = []
                    Object.keys(data.counts).map(controllers =>{
                      if(!controllers.includes('total')){
                        controllerArray.push(controllers)
                      }
                    })
                    // console.log('List COnt', controllerArray)
                    let controllerStates = new Array(controllerArray.length).fill(true)
                    // console.log('States COnt', controllerStates)

                    this.setState({
                      controllerArray:controllerArray,
                      controllerArrayCheckedStates:controllerStates,
                      loading:false
                    })

                  }
                  if(data.part_ids){
                    this.setState({
                      partIds: data.part_ids
                    })  
                  }
                              
              }
          }
        });
        this.fetchSatStatusPerDay(start, end, null)
      }   


      getPercentage = (ratio) => {
        if(ratio){
          let percent
          percent = (ratio)*100
          percent = percent.toFixed(1)+'%'
          return percent
        }
      }

      formatDate = (date) => {
        if(date){
          let modifyDate = new Date(date);
          modifyDate = modifyDate.toLocaleTimeString()+' '+modifyDate.toLocaleDateString();
          return modifyDate
        }
      }

      handleSelectedDate = (value) => {
        var start = new Date(value[0]);
        // get date to be lie 1/1/2022

        start = start.toISOString();
        // start = start.toLocaleDateString();
        

        // start = start.getTime();
        // start = parseInt(start);
        var end = new Date(value[1]);

        end = end.toISOString();
        // end = end.toLocaleDateString();

        // end = end.getTime();
        // end = parseInt(end);
        //  convert it to the end of the day
        // end = end + 86399000; 
        
        const { loading, selectedPartIds} = this.state;
        if(!loading){
          this.setState({
              loading: true,
              startDate: start,
              endDate: end,
            }, () => {
              if(start && end){
                var endLink = 'starting_date=' + start + '&ending_date=' + end;
                var topicContaining = ''
                getData('https://api.iotflows.com/v1/data_streams/ds_fd37b109318595ffa73d2ef2622d3815/sat_data?'+ endLink )
                .then(async response => {
                  if(response && response.ok ){
                      const data = await response.json();
                      if(data && data.data){

                        this.setState({
                          testData: data,
                          testDataTable: data.data,
                          controllerArray:[],
                          controllerArrayCheckedStates:[],
                        }, () => {
                          // Callback after all data is populated
                          // console.log('data for controllers Inside Seleceted Dates')
                          // console.log(data)
                          // Create Array of controllers
                          let controllerArray = []
                          Object.keys(data.counts).map(controllers =>{
                            if(!controllers.includes('total')){
                              controllerArray.push(controllers)
                            }
                          })
                          // console.log('List COnt Inside Seleceted Dates', controllerArray)
                          let controllerStates = new Array(controllerArray.length).fill(true)
                          // console.log('States COnt Inside Seleceted Dates', controllerStates)

                          

                          this.setState({
                            controllerArray:controllerArray,
                            controllerArrayCheckedStates:controllerStates,
                            loading: false
                          })
                        })

                        if(data.part_ids){
                          this.setState({
                            partIds: data.part_ids,
                            selectedPartIds:[]
                          })  
                        }
                      }
                  }
                });                
                                          
                this.fetchSatStatusPerDay(null, null, topicContaining, endLink)
              }
          })
        }
      }
      
      // hack: read sat_status_per_day
      fetchSatStatusPerDay(starting_time, ending_time, topic_containing, endLink) {        
        var self = this  

        // let start = starting_time ? (new Date(starting_time)).toLocaleDateString('en-CA') : (new Date( (new Date()).getTime() - (3 * 24 * 60 * 60 * 1000))).toLocaleDateString('en-CA')
        let start = starting_time ? (new Date(starting_time)).toISOString() : (new Date( (new Date()).getTime() - (3 * 24 * 60 * 60 * 1000))).toISOString()

        // let end = ending_time ? (new Date(ending_time)).toLocaleDateString('en-CA')     : (new Date()).toLocaleDateString('en-CA')            
        let end = ending_time ? (new Date(ending_time)).toISOString()     : (new Date()).toISOString()            
        let topicContaining = topic_containing || ''        
        var url = ''
        var url_oks = ''
        if(endLink)
        {
          url = `https://api.iotflows.com/v1/data_streams/ds_fd37b109318595ffa73d2ef2622d3815/sat_status_per_day?${endLink}`
          url_oks = `https://api.iotflows.com/v1/data_streams/ds_fd37b109318595ffa73d2ef2622d3815/sat_status_per_day_oks?${endLink}`
          if(!url.includes('starting_date') || !url.includes('ending_date'))        
            url += `&starting_date=${start}&ending_date=${end}`
        }          
        else
        {
          url = `https://api.iotflows.com/v1/data_streams/ds_fd37b109318595ffa73d2ef2622d3815/sat_status_per_day?starting_date=${start}&ending_date=${end}`
          url_oks = `https://api.iotflows.com/v1/data_streams/ds_fd37b109318595ffa73d2ef2622d3815/sat_status_per_day_oks?starting_date=${start}&ending_date=${end}`
        }                   
        if(topicContaining && !url.includes('topicContaining'))        
          url += `&` + topicContaining             
          url_oks += `&` + topicContaining             
        getData(url)
        .then(async response => {
          if(response && response.ok ){
            const data = await response.json();
            if(data && data.data){
              self.setState({sat_status_per_day: data.data})                      
            }
          }
        })
        getData(url_oks)
        .then(async response => {
          if(response && response.ok ){
            const data = await response.json();
            if(data && data.data){
              self.setState({sat_status_per_day_oks: data.data})                      
            }
          }
        })
      }

      handleClearDate = (e) => {
        try{
          const { loading, selectedPartIds} = this.state;

          if(!loading){

            // setting date to always be at 7 days 
            // let start = (new Date( (new Date()).getTime() - (3 * 24 * 60 * 60 * 1000))).toLocaleDateString('en-CA')
            let start = (new Date( (new Date()).getTime() - (3 * 24 * 60 * 60 * 1000))).toISOString()
            // let end = (new Date()).toLocaleDateString('en-CA')  
            let end = (new Date()).toISOString()  

            this.setState({
                loading: true,
                startDate: start,
                endDate: end,
              })
            var endLink = `starting_date=${start}&ending_date=${end}`;
            getData('https://api.iotflows.com/v1/data_streams/ds_fd37b109318595ffa73d2ef2622d3815/sat_data?'+ endLink )
            .then(async response => {
              if(response && response.ok ){
                  const data = await response.json();
                  if(data && data.data){

                    this.setState({
                      testData: data,
                      testDataTable: data.data,
                      controllerArray:[],
                      controllerArrayCheckedStates:[],
                    }, () => {
                      // Callback after all data is populated
                      // console.log('data for controllers CLEAR Dates')
                      // console.log(data)
                      // Create Array of controllers
                      let controllerArray = []
                      Object.keys(data.counts).map(controllers =>{
                        if(!controllers.includes('total')){
                          controllerArray.push(controllers)
                        }
                      })
                      // console.log('List Contr CLEAR Dates', controllerArray)
                      let controllerStates = new Array(controllerArray.length).fill(true)
                      // console.log('States Contr CLEAR Dates', controllerStates)

                      

                      this.setState({
                        controllerArray:controllerArray,
                        controllerArrayCheckedStates:controllerStates,
                        loading: false
                      })
                    })
                      

                      if(data.part_ids){
                        this.setState({
                          partIds: data.part_ids,
                          selectedPartIds:[]
                        })  
                      }
                  }
              }
            });
            this.fetchSatStatusPerDay(null, null, null, endLink)
          }
        }catch(e){
          console.log(e)
        }
      }


      handlePartIdCallback = (ids) => {
        this.setState({selectedPartIds:ids})
        if(this.state.startDate && this.state.endDate){
          try{
            if(!this.state.loading){
              this.setState({
                  loading: true,
                })
                var endLink = 'starting_date=' + this.state.startDate + '&ending_date=' + this.state.endDate;
                if(Object.keys(ids).length >0){
                  let stringIds = ids.toString();
                  endLink += `&part_id=${stringIds}`
                } 
                getData('https://api.iotflows.com/v1/data_streams/ds_fd37b109318595ffa73d2ef2622d3815/sat_data?'+ endLink )
                .then(async response => {
                  if(response && response.ok ){
                      const data = await response.json();
                      if(data && data.data){

                        this.setState({
                          testData: data,
                          testDataTable: data.data,
                          controllerArray:[],
                          controllerArrayCheckedStates:[],
                        }, () => {
                          // Callback after all data is populated
                          // console.log('data for controllers parts ID')
                          // console.log(data)
                          // Create Array of controllers
                          let controllerArray = []
                          Object.keys(data.counts).map(controllers =>{
                            if(!controllers.includes('total')){
                              controllerArray.push(controllers)
                            }
                          })
                          // console.log('List contr parts ID', controllerArray)
                          let controllerStates = new Array(controllerArray.length).fill(true)
                          // console.log('States contr parts ID', controllerStates)

                          

                          this.setState({
                            controllerArray:controllerArray,
                            controllerArrayCheckedStates:controllerStates,
                            loading: false
                          })
                        })
    
                        
                      }
                  }
                });                              
                this.fetchSatStatusPerDay(null, null,null, endLink)
              }
            
          }catch (e){
            console.log(e)
          }
          
          
        }else{
          console.log('no date')
        }
        
      }


      handleChangeControllerState = (position) => {
        const { loading, startDate, endDate, selectedPartIds, controllerArray} = this.state;
        if(!loading){
          this.setState({
            loading: true,
          })
          let updatedCheckedState = this.state.controllerArrayCheckedStates.map((item, index) =>
            index === position ? !item : item
          );
          this.setState({controllerArrayCheckedStates: updatedCheckedState})
        
          // Creating array of controller states that are true
          let updatedControllerTopics = []
          controllerArray.map((controller,index) =>{
            if(updatedCheckedState[index]){
              updatedControllerTopics.push(controller)
            }
          })

          var endLink = '';
          if(Object.keys(updatedControllerTopics).length > 0  && startDate && endDate){
            endLink = 'topic_containing=' + updatedControllerTopics.toString() + '&starting_date=' + startDate + '&ending_date=' + endDate;
          }else if(Object.keys(updatedControllerTopics).length > 0) {
            endLink = 'topic_containing=' + updatedControllerTopics.toString() 
          }else if(startDate && endDate){
            endLink = 'starting_date=' + startDate + '&ending_date=' + endDate;
          }
          let stringIds = selectedPartIds.toString();
          if(endLink && Object.keys(selectedPartIds).length >0){
            endLink += `&part_id=${stringIds}`
          }else if(!endLink && Object.keys(selectedPartIds).length >0){
            endLink = `part_id=${stringIds}`
          }
          getData('https://api.iotflows.com/v1/data_streams/ds_fd37b109318595ffa73d2ef2622d3815/sat_data?'+ endLink )
          .then(async response => {
            if(response && response.ok ){
                const data = await response.json();
                if(data && data.data){
                  this.setState({
                    testData: data,
                    testDataTable: data.data,
                    controllerArray:[],
                    controllerArrayCheckedStates:[],
                  }, () => {
                    // Callback after all data is populated
                    // console.log('data for controllers Checked controller')
                    // console.log(data)
                    // Create Array of controllers
                    let controllerArray = []
                    Object.keys(data.counts).map(controllers =>{
                      if(!controllers.includes('total')){
                        controllerArray.push(controllers)
                      }
                    })
                    // console.log('List contr checked controller', controllerArray)
                    let controllerStates = new Array(controllerArray.length).fill(true)
                    // console.log('States contr checked controller', controllerStates)

                    

                    this.setState({
                      controllerArray:controllerArray,
                      controllerArrayCheckedStates:controllerStates,
                      loading: false
                    })
                  })

                }
            }
          });
          this.fetchSatStatusPerDay(null, null, null, endLink)
        }
    }

    handleChangeSelectedControllerIFrame = (event) => {
      console.log(event)
      this.setState({selectedControllerIFrame: event.target.value})
    }

    toggleChecked = (connectionInfo) => {
      if(connectionInfo.device_connection_is_enabled){
        this.setState( { loadingToggle: true },
          () => {
            putData('https://api.iotflows.com/v1/devices/dev_0a8a4c2d20cb10848ad099ece8500a43/connections', 
            {'from_port': connectionInfo.port,
            'from_ip':connectionInfo.ip_address,
            'enable_connection': false})
            .then(async response => {
              console.log('response', response)
              if(response.ok){ 
                let iframeController = this.state.selectedControllerIFrame
                iframeController.device_connection_is_enabled = false
                console.log('iframeController 111', iframeController)
                this.setState({
                  selectedControllerIFrame: iframeController,
                  loadingToggle: false
                })
                this.props.showSuccessSnackbar("Connection has been disconnected!");
              }
              else{
                this.setState({loadingToggle: false})
                this.props.showErrorSnackbar("Connection failed to disconnected");
  
              }
            })
          } )
      }else if(connectionInfo.device_connection_is_enabled === false){
        this.setState( {loadingToggle: true},
          // callback function inside setState to connect to Node Red live session
          () =>{ 
            console.log('connectionInfo 2.2', connectionInfo)
            putData('https://api.iotflows.com/v1/devices/dev_0a8a4c2d20cb10848ad099ece8500a43/connections', 
            {'from_port': connectionInfo.port,
              'from_ip':connectionInfo.ip_address,
              'enable_connection': true})
              .then(async response => {
                console.log('response', response)
                if(response.ok){
                  let iframeController = this.state.selectedControllerIFrame
                  iframeController.device_connection_is_enabled = true
                  console.log('iframeController 222', iframeController)
                  this.setState({
                    selectedControllerIFrame: iframeController,
                    loadingToggle: false
                  })
                  this.props.showSuccessSnackbar("Connection has been requested successfully!");
                  
                }
                else{
                  this.setState({loadingToggle: false})
                  this.props.showErrorSnackbar("Connection request failed");
                }
              })
          })
      } 
    }

    openAlphaboxLink = (connectionInfo) => {
      if(!this.state.loadingController){
        this.setState({
          loadingController: true
        }, () =>{
          // disconnection from connection in order to reconnect later
          putData('https://api.iotflows.com/v1/devices/dev_0a8a4c2d20cb10848ad099ece8500a43/connections', 
            {'from_port': connectionInfo.port,
            'from_ip':connectionInfo.ip_address,
            'enable_connection': false})
            .then(async response => {
              if(response.ok){ 
                // Reconnectiong to controller to ensure there is a proper connection
                putData('https://api.iotflows.com/v1/devices/dev_0a8a4c2d20cb10848ad099ece8500a43/connections', 
                {'from_port': connectionInfo.port,
                  'from_ip':connectionInfo.ip_address,
                  'enable_connection': true})
                  .then(async response => {
                    if(response.ok){
                      setTimeout(() => {
                        window.open(`https://${connectionInfo.controller}-${connectionInfo.port}-dev_0a8a4c2d20cb10848ad099ece8500a43.s2.devices.iotflows.com/`  , "_blank")
                        this.setState({
                          showAlphatoolboxList: false,
                          loadingController: false
                        })
  
                        this.props.showSuccessSnackbar("Connection has been requested successfully!");
                      }, 5000);
                    }
                    else{
                      this.setState({loadingToggle: false})
                      this.props.showErrorSnackbar("Connection request failed");
                    }
                  })


              }
              else{
                this.setState({loadingController: false})
                this.props.showErrorSnackbar("Connection failed");
  
              }
            })

        })
      }
      
    }

    
      render() {
        const { classes } = this.props;
        const { testData,
                testDataTable,
                loading,
                partIds,
                controllerArray,
                controllerArrayCheckedStates,
                showAlphatoolboxList,
                selectedControllerIFrame,
                loadingToggle,
                loadingController } = this.state;

        
        return (
          <div style={{height:'100vh'}}>
            <div className={classes.breadcrumbCSS}>
              <Breadcrumbs  separator="›" aria-label="breadcrumb">
                <Typography className={classes.fontStyles} style={{color:'#757575'}}>{this.props.selectedOrganization.organization_name}</Typography>
                <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/assets" >
                  Assets
                </Link>
                <Typography className={classes.fontStyles} color="textPrimary">
                  SAT-Piston-Dashboard
                  </Typography>
              </Breadcrumbs>
            </div>
                <div className={classes.filtersSection} >
                    <div className={classes.inputContainer} >
                      <button className={classes.button} disabled={loadingController} onClick={() => this.setState({showAlphatoolboxList: !this.state.showAlphatoolboxList})}>Alphatoolbox </button>
                      {loadingController && (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />
                      )}
                      {showAlphatoolboxList &&
                        <div className={classes.searchResults}  >
                          {controllerList.map(controller =>(
                            <div 
                              key={controller.controller}
                              className={classes.resultContainer} 
                              disabled={loadingController} 
                              onClick={() => this.openAlphaboxLink(controller)}
                            >
                              <div style={{display:'flex'}}>
                                <span key={controller.controller} className={classes.searchText} style={{color: loadingController && '#9ca3af' }}>{controller.controller}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      }

                    </div>
                  <div style={{margin:'8px'}}>
                    <DateRangePicker
                      style={{ maxWidth: '300px', minWidth:'200px' }}
                      placeholder="Filter by Date"
                      showOneCalendar
                      preventOverflow
                      ranges={[]}
                      disabledDate={afterToday()}
                      // onExit={(value) => console.log('value onExit',value)}
                      // onSelect={(value) => console.log('value Select',value)}
                      // onChange={(value) => console.log('value change',value)}
                      onOk={this.handleSelectedDate}
                      onClean={this.handleClearDate}
                    />
                  </div>
                  <div style={{margin:'8px'}}>
                    <PartIdSelection partIds={partIds} handlePartIdCallback={this.handlePartIdCallback} />
                  </div>
                </div>
                  
                  <Card className={classes.cardRoot} style={{width:'100%', margin:'0px'}} variant="outlined">                
                    <CardContent style={{display:'flex',flexDirection:'column', padding:'0px'}}>
                      <div style={{fontSize:'2em', fontWeight:'900', color:'#94a3b8',textAlign:'center'}}>TOTAL</div>
                      <Grid 
                          direction={{ xs: "row"}}
                          justifyContent={{ xs: "center", md:'space-around'}}
                          alignItems="center" 
                          container 
                        >
                          <Grid item xs={12} sm={4}>
                            {testData && testData.counts && testData.counts.total.nok &&
                              <div style={{display: 'flex', flexDirection:'column', margin:'0px',textAlign:'center'}}>
                                <div style={{fontSize:'40px', fontWeight:'600', color:'#64748b'}}>{testData.counts.total.ok}</div>
                                <div style={{fontSize:'16px', fontWeight:'400', color:'#64748b'}}>OK</div>
                              </div>
                            }
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            {testData && testData.counts && testData.counts.total.nok &&
                              <div style={{display: 'flex', flexDirection:'column', margin:'0px',textAlign:'center'}}>
                                <div style={{fontSize:'40px', fontWeight:'600', color:'#ef4444'}}>{testData.counts.total.nok}</div>
                                <div style={{fontSize:'16px', fontWeight:'400', color:'#ef4444'}}>NOK</div>
                              </div>
                            }
                          </Grid>
                        <Grid item xs={12} sm={4}>
                        {testData && testData.counts && testData.counts.total.ratio &&
                          <div style={{display: 'flex', flexDirection:'column', margin:'auto 0px auto 0px',textAlign:'center'}}>
                            <div style={{fontSize:'48px', fontWeight:'700', color:'rgb(129, 181, 229)'}}>{this.getPercentage(testData.counts.total.ratio)}</div>
                          </div>
                        }
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>




                {/* Testing new controller code */}
                {controllerArray.length > 0 &&
                <div style={{display:'flex', flexWrap: 'wrap'}}>
                    {controllerArray.map((controller, index)=> (
                      <div key={controller}>
                        {testData.counts && Object.keys(testData.counts[controller]).length > 0 &&
                          Object.keys(testData.counts[controller]).map((controllerSpindle) => (
                            <div key={testData.counts[controller][controllerSpindle]} style={{position: 'relative'}}> 
                              <Card className={classes.cardRootMini} style={{width:'fit-content'}} variant="outlined">
                                <CardContent style={{display:'flex', padding:'8px 32px', position:'relative'}}>
                                  {/* Checkbox */}
                                  <div style={{position:'absolute', top:6, right:10}}>
                                    <input id={`controller-checkbox-${controller}`} type="checkbox" checked={controllerArrayCheckedStates[index]} onChange={() => this.handleChangeControllerState(index)}  />
                                  </div>

                                    <div style={{display:'flex', flexDirection:'column'}}>
                                      {/* Title name */}
                                      <div style={{fontSize:'1em', fontWeight:'400', color:'rgb(107, 114, 128)', width:'240px'}}>{`${controller} ${testData.counts[controller][controllerSpindle].name}`}</div>

                                      <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                        {testData.counts[controller][controllerSpindle].ok &&
                                          <div style={{display: 'flex', flexDirection:'column', margin:'8px 0px 0px 0px'}}>
                                            <div style={{fontSize:'24px', fontWeight:'700', color:'#64748b'}}>{testData.counts[controller][controllerSpindle].ok}</div>
                                            <div style={{fontSize:'12px', fontWeight:'400', color:'#64748b'}}>OK</div>
                                          </div>
                                        }
                                        </Grid>
                                        <Grid item xs={4}>
                                        {testData.counts[controller][controllerSpindle].nok &&
                                          <div style={{display: 'flex', flexDirection:'column', margin:'8px 0px 0px 0px'}}>
                                            <div style={{fontSize:'24px', fontWeight:'700', color:'#ef4444'}}>{testData.counts[controller][controllerSpindle].nok}</div>
                                            <div style={{fontSize:'12px', fontWeight:'400', color:'#ef4444'}}>NOK</div>
                                          </div>
                                        }
                                        </Grid>
                                        <Grid item xs={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                        {testData.counts[controller][controllerSpindle].ratio && 
                                          <div >
                                            <div style={{fontSize:'24px', fontWeight:'700', color:'rgb(129, 181, 229)'}}>{this.getPercentage(testData.counts[controller][controllerSpindle].ratio)}</div>
                                          </div>
                                        }
                                        </Grid>
                                      </Grid>
                                    </div>  
                                </CardContent>
                              </Card>
                            </div>
                          ))
                        }
                        </div>


                      ))}

                </div>
                }
                {/* Testing new controller code */}


                <Card className={classes.cardRoot} style={{ padding:'8px'}} variant="outlined">
                    <CardContent style={{padding:'0px'}} >
                    {loading &&
                        <Grid item xs={12}>
                          <LinearProgress sx={{height:'4px'}} color='primary' />
                        </Grid>
                      }
                      <IoTFlowsHeatmap unit={'%'} title={'Ratio of OKs'} data={this.state.sat_status_per_day}/>
                    </CardContent>
                </Card>  

                <Card className={classes.cardRoot} style={{ padding:'8px'}} variant="outlined">
                    <CardContent style={{padding:'0px'}} >

                      <IoTFlowsHeatmap unit={''} title={'Number of OKs'} data={this.state.sat_status_per_day_oks}/>
                    </CardContent>
                </Card>  
                <div>
                  <TableSATWidget loading={loading} testDataTable={testDataTable} />
                </div>
          </div>
        );
      }
}


const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{ withTheme: true })(withRouter(DashboardSATPiston)));

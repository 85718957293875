import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { AutoSizer, Column, Table } from 'react-virtualized';
import LinearProgress from '@material-ui/core/LinearProgress';   
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Row from 'react-bootstrap/Row';



const styles = (theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  table: {
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0px !important' : undefined,
    },
  },
  tableRow: {
    cursor: 'pointer',
    hover: 'true',
    
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
    fontFamily:'Trebuchet MS',
  },
  headerText: {
    fontWeight: 'bold',
    backgroundColor: '#fafafa',
    fontFamily:'Trebuchet MS',
  },
  noClick: {
    cursor: 'initial',
  },
  
});

class LogsTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 52,
    rowHeight: 52,
  };

  getRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer = ({ cellData, columnIndex }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    var strData = String(cellData);
    if(Object.keys(strData).length > 30 && window.innerWidth <= 600){
      strData = strData.slice(0,30) + '...';
      cellData = strData;
    }
    if(Object.keys(strData).length > 80 && window.innerWidth > 600 && window.innerWidth <= 768){
      strData = strData.slice(0,80) + '...';
      cellData = strData;
    }
    if (Object.keys(strData).length > 90 && window.innerWidth > 768 && window.innerWidth <= 900) {
      strData = strData.slice(0,90) + '...';
      cellData = strData;
    }
    if (Object.keys(strData).length > 130 && window.innerWidth > 900 && window.innerWidth <= 1024) {
      strData = strData.slice(0,130) + '...';
      cellData = strData;
    }
    if (Object.keys(strData).length > 160 && window.innerWidth > 1024 && window.innerWidth <= 1280) {
      strData = strData.slice(0,160) + '...';
      cellData = strData;
    }
    if (Object.keys(strData).length > 180 && window.innerWidth > 1280 && window.innerWidth <= 1440) {
      strData = strData.slice(0,180) + '...';
      cellData = strData;
    }
    if (Object.keys(strData).length > 200 && window.innerWidth > 1440) {
      strData = strData.slice(0,200) + '...';
      cellData = strData;
    }
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
      >  
        {cellData}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex }) => {
    const { headerHeight, columns, classes } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell,classes.headerText, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
    return (
          <AutoSizer >
            {({ height, width }) => (
              <Table
                height={height}
                width={width}
                rowHeight={rowHeight}
                gridStyle={{
                  direction: 'inherit',
                }}
                headerHeight={headerHeight}
                className={classes.table}
                {...tableProps}
                rowClassName={this.getRowClassName}
              >
                
                {columns.map(({ dataKey, ...other }, index) => {
                  return (
                    <Column
                      key={dataKey}
                      headerRenderer={(headerProps) =>
                        this.headerRenderer({
                          ...headerProps,
                          columnIndex: index,
                        })
                      }
                      className={classes.flexContainer}
                      cellRenderer={this.cellRenderer}
                      dataKey={dataKey}
                      {...other}
                    />
                  );
                })}
              </Table>
            )}
          </AutoSizer>
    );
  }
}

LogsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
};

export default withStyles(styles)(LogsTable)
import {getData} from '../../ApiCalls/DataApis';

// export const fetchUsers = () => dispatch => {
//   fetch(BASE_URL + 'users')
//     .then(res => res.json())
//     .then(users => 
//       dispatch({
//         type: FETCH_USERS,
//         payload: users
//       })
//     );
// }

export const fetchCurrentUser = () => dispatch => {
  
  getData('https://api.iotflows.com/v1/users')
  .then(async response => {
    if(response.ok ){
      const data = await response.json();
      if(data){
        data.data[0].user_image_url+= ('?'+ Math.random())
        dispatch({
          type: 'FETCH_CURRENT_USER',
          payload: data.data 
        })
      }
      // this.setState({
      //   userInfo: data.data 
      // })
    }
    
  });
}


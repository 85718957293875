import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import { clearSnackbar } from "../store/actions/snackbarActions";
import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';




export default function SuccessSnackbar() {
  const dispatch = useDispatch();

  const { successSnackbarMessage, successSnackbarOpen, snackbarAlertSeverity } = useSelector(
    state => state.ui
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


  return (
    // <div>
      // <Slide direction="right" in={successSnackbarOpen} mountOnEnter unmountOnExit>
        <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
          <Alert onClose={handleClose}  severity={snackbarAlertSeverity}>
            {successSnackbarMessage}
          </Alert>
        </Snackbar>
      // </Slide>
      
    // </div>
    
    
    // <Snackbar
    //   anchorOrigin={{
    //     vertical: "bottom",
    //     horizontal: "left"
    //   }}
    //   open={successSnackbarOpen}
    //   autoHideDuration={4000}
    //   onClose={handleClose}
    //   aria-describedby="client-snackbar"
    //   message={
    //     <span id="client-snackbar">
    //       <Icon>check_circle</Icon>
    //       {successSnackbarMessage}
    //     </span>
    //   }
    //   action={[
    //     <IconButton
    //       key="close"
    //       aria-label="close"
    //       color="inherit"
    //       onClick={handleClose}
    //     >
    //       <Icon>close</Icon>
    //     </IconButton>
    //   ]}
    // />
  );
}
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, rgbToHex } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData, putData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import IconButton from '@material-ui/core/IconButton';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PasswordInfoModal from '../components/PasswordInfoModal';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(70),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    // [theme.breakpoints.down('md')]:{
    //   width: theme.spacing(110),
    // },
    // [theme.breakpoints.down('sm')]:{
    //   width: theme.spacing(70),
    // },
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    width: 'auto',
    height: 'auto',
    padding: '4px',
    margin: '0px',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-end',
    alignItems:'flex-end',
    alignItems: 'flex-end',
    fontFamily:'Trebuchet MS',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    borderRadius: 10, 
    maxWidth: theme.spacing(150),
  },
  cardCSS: {
    width: '100%',
    borderRadius: '10px',
    border:'1px solid #f5f5f5',
    padding: '8px',
    backgroundColor: '#fafafa',
    boxShadow: '2px 4px #f5f5f5',
    cursor:'pointer',
    '&:hover': {
      // backgroundColor:'#eeeeee',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid #bdbdbd',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'#eeeeee',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor:'#eeeeee',
      border:'1px solid #bdbdbd',
      boxShadow: '2px 4px #bdbdbd',
    },
  },
  payAsYouGoCardCSS: {
    maxWidth: '400px',
    width: '100%',
    borderRadius: '10px',
    border:'1px solid #f5f5f5',
    padding: '8px',
    backgroundColor: '#fafafa',
    boxShadow: '2px 4px #f5f5f5',
    cursor:'pointer',
    '&:hover': {
      // backgroundColor:'#eeeeee',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid #bdbdbd',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'#eeeeee',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor:'#eeeeee',
      border:'1px solid #bdbdbd',
      boxShadow: '2px 4px #bdbdbd',
    },
  },
  payAsYouGoCardCSSSelected: {
    maxWidth: '400px',
    width: '100%',
    borderRadius: '10px',
    padding: '8px',
    outline: 'none',
    backgroundColor:'#eeeeee',
    border:'1px solid #bdbdbd',
    boxShadow: '2px 4px #bdbdbd',
    position:'relative',
    cursor:'pointer',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  cardCSSSelected: {
    width: '100%',
    borderRadius: '10px',
    padding: '8px',
    outline: 'none',
    backgroundColor:'#eeeeee',
    border:'1px solid #bdbdbd',
    boxShadow: '2px 4px #bdbdbd',
    position:'relative',
    cursor:'pointer',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  headerCSS: {
    textAlign: 'center',
    color: '#1976d2',
    fontFamily:'Trebuchet MS',
  },
  bodyCSS: {
    fontSize: '14px',
    color: '#525f7f',
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
  },
  pricingCSS: {
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
    marginTop: '16px',
    marginBottom: '16px',
  },
  customButton: {
    width: '100%',
    marginBottom: '10px', 
    marginTop: '10px',
    borderRadius: '5px',
    border:'1px solid #f5f5f5',
    backgroundColor:'rgba(87, 147, 222, 0.1)',
    '&:hover': {
      backgroundColor:'rgba(87, 147, 222, 0.3)',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid rgba(87, 147, 222, 0.5)',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'rgba(87, 147, 222, 0.4)',
      border:'1px solid rgba(87, 147, 222, 0.7)',
    },
    '&:focus': {
      outline: 'none',
      // backgroundColor:'#eeeeee',
      // border:'1px solid #bdbdbd',
      // boxShadow: '2px 4px #bdbdbd',
    },
  },
  itemsCSS: {
    fontSize: '12px',
    color: '#525f7f',
    textAlign: 'left',
    fontFamily:'Trebuchet MS',
    listStyleType: 'none',
    marginTop: '15px',
    padding: '0px',
    height: '340px',
    [theme.breakpoints.down('md')]:{
      height: '370px',
    },
    [theme.breakpoints.down('sm')]:{
      height: '370px',
    },
    [theme.breakpoints.down('xs')]:{
      height: '100%',
    }
  },
  itemsFreeCSS: {
    fontSize: '12px',
    color: '#525f7f',
    textAlign: 'left',
    fontFamily:'Trebuchet MS',
    listStyleType: 'none',
    marginTop: '15px',
    padding: '0px',
    // height: '250px',
    // [theme.breakpoints.down('md')]:{
    //   height: '250px',
    // },
    // [theme.breakpoints.down('sm')]:{
    //   height: '350px',
    // },
    // [theme.breakpoints.down('xs')]:{
    //   height: '100%',
    // }
  },
  itemsPayAsYouGoCSS: {
    fontSize: '12px',
    color: '#525f7f',
    textAlign: 'left',
    fontFamily:'Trebuchet MS',
    listStyleType: 'none',
    marginTop: '15px',
    padding: '0px',
  },
  itemCSS: {
    padding: '5px',
  },
  subItemCSS: {
    fontSize: '11px',
    padding: '5px',
    paddingLeft: '25px',
  },
  checkmarkCSS: {
    fontSize: '14px',
    color:'rgb(0,209,0)',
  },
  circleCSS: {
    fontSize: '14px',
    color:'rgb(191, 191, 191)',
  },

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class EditDeviceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      deviceName: this.props.device_name,
      deviceDescription: this.props.device_description,
      // subscriptionType:this.props.subscriptionType,
      // deviceName: '',
      // deviceDescription: '',
      // subscriptionType:'',

    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
      deviceName: this.props.device_name,
      deviceDescription: this.props.device_description,
    });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
      // deviceName: '',
      // deviceDescription: '',
      // deviceIsPrivate: '',
      // deviceType: '',
      // subscriptionType:'',
    });
  };

  handleDeviceNameChange = (event) => {
    this.setState({deviceName: event.target.value});
  }

  handleDeviceDescriptionChange = (event) => {
    this.setState({deviceDescription: event.target.value});
  }

  handleDeviceTypeChange = (event) => {
    this.setState({deviceType: event.target.value});
  }
  // handleSubscriptionTypeChange = (event) => {
  //   this.setState({subscriptionType: event});
  // }

  componentWillReceiveProps(nextProps) {
    this.setState({
      deviceName: nextProps.deviceName,
      deviceDescription: nextProps.deviceDescription,
      device_uuid: nextProps.device_uuid,
    }); 
  }

  

  submitButtonClick = () => {
    
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        putData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid , 
        {'device_name': this.state.deviceName,
          'device_description': this.state.deviceDescription})
          .then(async response => {
            if(response.ok){
              const data = await response.json();
              this.props.showSuccessSnackbar("Device has been successfully modified!");
              this.props.refreshDeviceList();
              this.setState({
                loading: false,
                success: true,
                open:false,
              });
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false});
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  


  render() {
    const { classes, selectedOrganization } = this.props;
    const { open, 
            loading, 
            success, 
            deviceName,
            deviceDescription} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
      >
        
        <IconButton className={classes.button} variant="outlined" onClick={this.handleOpen}><EditOutlinedIcon style={{color: '#757575'}}/></IconButton>

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Edit Device</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px'}}>

                
                
                  <TextField
                    style={{marginTop: '0px'}}
                    autoFocus
                    margin="normal"
                    id="deviceName"
                    label="Name"
                    type="text"
                    value={deviceName} 
                    onChange={this.handleDeviceNameChange}
                    fullWidth
                    required
                  />

                  <TextField
                    autoFocus
                    margin="normal"
                    id="deviceDescription"
                    label="Description"
                    type="text"
                    value={deviceDescription} 
                    onChange={this.handleDeviceDescriptionChange}
                    fullWidth
                  />
                
                {/* {!selectedOrganization.organization_is_personal  ?  
                  <div>
                    <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '24px'}}>
                      <b>Edge Device Plans</b>
                    </DialogContentText>
                    <Grid container spacing={1} style={{marginBottom: '16px', textAlign: 'center'}}>
                      <Grid item xs={12} sm={6} md={3}>
                        <button className={subscriptionType == 'IOT_GATEWAY_PAY_AS_YOU_GO' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_PAY_AS_YOU_GO')} >
                          <h4 className={classes.headerCSS}>Pay-as-you-go</h4>
                          <Divider/>
                          <div className={classes.pricingCSS}>
                            $0
                          </div>
                          <div className={classes.bodyCSS}>
                            per month per device
                          </div>
                          <ul className={classes.itemsCSS}>
                            <div style={{height: '86px'}}>
                              <li className={classes.itemCSS}><b>Real-Time IoT Interactions: $1 per 1000 interactions</b></li>
                            </div>
                            <div style={{height: '86px'}}>
                              <li className={classes.itemCSS}><b>Remote Access: $29 per month</b></li>
                                <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Node-RED or SHH connections</li>
                            </div>
                            <br/>
                            <li className={classes.itemCSS}><i>Top Features:</i> </li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Secure Authentication</li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Publish / Subscription Permission Control</li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> SSL SHA-256 Encrypted Secure Communication</li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> HTTPS / MQTTS / Secure WebSockets</li>
                          </ul>
                        </button>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <button className={subscriptionType == 'IOT_GATEWAY_E1' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_E1')} >
                          <h4 className={classes.headerCSS}>E1</h4>
                          <Divider/>
                          <div className={classes.pricingCSS}>
                            $79
                          </div>
                          <div className={classes.bodyCSS}>
                            per month per device
                          </div>
                          <ul className={classes.itemsCSS}>
                            <div style={{height: '86px'}}>
                              <li className={classes.itemCSS}><b>Real-Time IoT Interactions: 200k</b> </li>
                                <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Additional $1 per 1000 interactions</li>
                            </div>
                            <div style={{height: '86px'}}>
                              <li className={classes.itemCSS}><b>Remote Access: 1</b> </li>
                                <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Node-RED or SHH connections</li>
                            </div>
                              <br/>
                            <li className={classes.itemCSS}><i>Top Features:</i> </li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Secure Authentication</li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Publish / Subscription Permission Control</li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> SSL SHA-256 Encrypted Secure Communication</li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> HTTPS / MQTTS / Secure WebSockets</li>
                          </ul>
                        </button>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                      <button className={subscriptionType == 'IOT_GATEWAY_E2' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_E2')}>
                          <h4 className={classes.headerCSS}>E2</h4>
                          <Divider/>
                          <div className={classes.pricingCSS}>
                            $499
                          </div>
                          <div className={classes.bodyCSS}>
                            per month per device
                          </div>
                          <ul className={classes.itemsCSS}>
                            <div style={{height: '86px'}}>
                              <li className={classes.itemCSS}><b>Real-Time IoT Interactions: 2.5 million</b> </li>
                                <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Additional $1 per 1000 interactions</li>
                            </div>
                            <div style={{height: '86px'}}>
                              <li className={classes.itemCSS}><b>Remote Access: 2</b> </li>
                                <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Node-RED &amp; SHH connections</li>
                            </div>
                            <br/>
                            <li className={classes.itemCSS}> <i>Top Features:</i> </li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Secure Authentication</li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Publish / Subscription Permission Control</li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> SSL SHA-256 Encrypted Secure Communication</li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> HTTPS / MQTTS / Secure WebSockets</li>
                          </ul>
                        </button>
                      </Grid> 
                      <Grid item xs={12} sm={6} md={3}>
                      <button className={subscriptionType == 'IOT_GATEWAY_E3' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_E3')}>
                          <h4 className={classes.headerCSS}>E3</h4>
                          <Divider/>
                          <div className={classes.pricingCSS}>
                            $999
                          </div>
                          <div className={classes.bodyCSS}>
                            per month per device
                          </div>
                          <ul className={classes.itemsCSS}>
                            <div style={{height: '86px'}}>
                              <li className={classes.itemCSS}><b>Real-Time IoT Interactions: 10 million</b> </li>
                                <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Additional $1 per 1000 interactions</li>
                            </div>
                            <div style={{height: '86px'}}>
                              <li className={classes.itemCSS}><b>Remote Access: 4</b> </li>
                                <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Node-RED, SHH &amp; two custom connections</li>
                            </div>
                              <br/>
                            <li className={classes.itemCSS}> <i>Top Features:</i> </li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Secure Authentication</li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Publish / Subscription Permission Control</li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> SSL SHA-256 Encrypted Secure Communication</li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> HTTPS / MQTTS / Secure WebSockets</li>
                          </ul>
                        </button>
                      </Grid> 
                    </Grid>
                  </div> 
                  :null}

                  {selectedOrganization.organization_is_personal  ?   
                  <div>
                    <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '24px'}}>
                      <b>Edge Device Plans</b>
                    </DialogContentText>
                    <Grid container spacing={1} style={{marginBottom: '16px', textAlign: 'center', justifyContent: 'center'}}>
                      <Grid item xs={12} sm={6} >
                        <button className={subscriptionType == 'IOT_GATEWAY_FREE' ? classes.cardCSSSelected : classes.cardCSS} style={{maxWidth: '400px'}} onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_FREE')} >
                          <h4 className={classes.headerCSS}>Personal</h4>
                          <Divider/>
                          <div className={classes.pricingCSS}>
                            Free
                          </div>
                          <div className={classes.bodyCSS}>
                            per month per device
                          </div>
                          <ul className={classes.itemsFreeCSS}>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Remote Access: Limited to 10 min/day</li>
                            <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Real-Time IoT Interactions: Limited to 2000/day</li>
                          </ul>
                        </button>
                      </Grid>
                    </Grid>
                  </div>
                  : null} */}


                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading || (deviceName == '') } onClick={this.submitButtonClick}>
                    {'Edit'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(EditDeviceModal));

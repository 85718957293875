import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import { fade, withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Col from 'react-bootstrap/Col';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DeleteModal from './DeleteModal';
import IconButton from '@material-ui/core/IconButton';
import  {postData, getData } from '../ApiCalls/DataApis';
import { useDispatch, useSelector } from "react-redux";
import {searchbarUserHandler} from '../store/actions/searchbarUserActions';
import { connect} from "react-redux";


const styles = theme => ({
  
  search: {
    position: 'relative',
    borderRadius: '25px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    border:'1px solid #bdbdbd',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  dialog: {borderRadius: 10 } ,
  listItemsCSS: {
    borderRadius:'10px',
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  }
});

class SearchMemberWithDelete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filtered: []
        };
        this.handleChange = this.handleChange.bind(this);
    }
    
 
    
  handleChange(e) {
    var newList = [];
    if (e.target.value !== "") {
    getData('https://api.iotflows.com/v1/users/search/containing?q='+ e.target.value )
    .then(async response => {
      // JSON data parsed by response.json() call
      if(response.ok ){
        const data = await response.json();
        newList = data.data
        this.setState({
          filtered: newList
        });
      }
    });
    } else {
            // If the search bar is empty, set newList to original task list
      newList = [];
      this.setState({
        filtered: newList
      });
    }
        // Set the filtered state based on what our rules added to newList
  }
    
    render() {
      const { classes, searchbarUsername } = this.props;

        return (
            <div>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  type="text"
                  className="input" 
                  onChange={this.handleChange}
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                />
              </div>
                <List>
                  {this.state.filtered.map(item => (
                    <ListItem button className={classes.listItemsCSS} key={item.user_username} selected={item.user_username == searchbarUsername} onClick={() => { this.props.searchbarUserHandler(item.user_username); }} >
                      <ListItemAvatar>
                        <Avatar className={classes.avatar} src={item.user_image_url} alt={item.user_first_name} />
                      </ListItemAvatar>
                      <Col>
                        <ListItemText primary={item.user_first_name + ' ' + item.user_last_name} />
                        <ListItemText secondary={item.user_username_public} />
                      </Col>
                      <IconButton edge="end" >
                        <DeleteModal  modalButton={<DeleteOutlinedIcon style={{color: '#bdbdbd'}}/> } dialogTitle={'Remove Member'} removingItem={'Member'} />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
  searchbarUserHandler: username => dispatch(searchbarUserHandler(username)),

})
// const mapStateToProps = state => state
const mapStateToProps = state => {
  return {
    searchbarUsername: state.searchbarUser.searchbarUsername
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(SearchMemberWithDelete)) 

import React from 'react';
// import { withStyles, makeStyles } from '@material-ui/core/styles';
import './App.css';
// import SideNav from './SideNav/SideNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainNavPage from './SideNav/MainNavPage';
// import {GlobalStateApp} from './Test/GlobalStateApp'
// import { GlobalStateProvider} from "./Test/GlobalStateTest";
// import { GlobalStateProvider} from "./GlobalStates";
// import { withAuthenticator } from 'aws-amplify-react'
import Amplify, { Auth, Hub } from 'aws-amplify';
import { withOAuth } from 'aws-amplify-react';
import { Component} from 'react';
// import { findDOMNode } from 'react-dom';
// import $ from 'jquery';
import SnackbarPopUp from './components/SnackbarPopUp';
import { Provider } from 'react-redux';
import store from './store.js';
import './styles/ScrollBarStyles.scss';
import './styles/FabFloatStyles.scss';
import ReactGA from 'react-ga';
// import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
// import ContactSupportModal from './components/ContactSupportModal';
import { Mixpanel } from './Mixpanel/Mixpanel.js';
// import {globalStyles} from "./styles/globalStyles";
import Login from './MyLoginPage/Login';
import CredentialsRouting from './MyLoginPage/CredentialsRouting';

Amplify.configure({
  Auth: {            
      identityPoolId: 'us-east-1:5684b65a-8bf4-4697-b9a4-87eadbf03ef5',
      region: 'us-east-1',
      identityPoolRegion: 'us-east-1',      
      userPoolId: 'us-east-1_3EP1MRzAy',            
      // userPoolWebClientId: 'n29ikkv6oe9sdk47d3c8v4e6j',
      userPoolWebClientId: '18230ih88kfb3ouj67mrid5er3',
      oauth: {
          domain: 'auth.iotflows.com',
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          // redirectSignIn: 'https://www.console.iotflows.com/', 
          // redirectSignOut: 'https://www.console.iotflows.com/', 
          redirectSignIn: 'http://localhost:3000/', 
          redirectSignOut: 'http://localhost:3000/', 
          responseType: 'token', // DO NOT USE 'code'!!! it goes through a forever loop //or 'token', note that REFRESH token will only be generated when the responseType is code
          // responseType: 'code', 
          options: {
              // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
              AdvancedSecurityDataCollectionFlag : true
          },
      }
  }
});


// function App() {
class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      user: null,
      customState: null,
      openConsoleSupportModal: false,
    };
    ReactGA.initialize('UA-169833572-2');
    ReactGA.pageview(window.location.pathname);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    
  }
  

  
    

  componentDidMount() 
  {
    console.log('111 user')
    console.log(this.state.user)
    var hasHubBeenCalled = false;
    // Hub listener for redirect callback of OAuth2
    Hub.listen("auth", ({ payload: { event, data } }) => 
    {
      // console.log("Hub called.")
      hasHubBeenCalled = true;      
      switch (event) {
        case "signIn":                          
          // console.log(data);
          if(data)
          {                
            // console.log("User logged in successfully by ridirect url.")                              
            this.setState({ user: data });                
          }                            
          break;
        case "signOut":
          this.setState({ user: null });   
          // Mixpanel.track('User web logout');              
          break;
        default:            
          // console.log("Default")
          break;
      }          
    });

    // OLD
    // if we haven't found an authenticated user, wait a bit and if no redirect from Hub was detected, call federatedSignIn
    //onsole.iotflows.com/#access_token
    // setTimeout(function()
    // {      
    //   if(!hasHubBeenCalled)
    //   {
    //     console.log("Federated SignIn");
    //     Auth.federatedSignIn();
    //   }         
    // }, 5000);

    // just check if token is not passed:
    if(!window.location.href.includes('#'))
    {
      Auth.federatedSignIn();
    }

  }    

  async authenticate()
  {    
    // Check if there is a current authenticated user
    try 
    {
      await Auth.currentAuthenticatedUser().then(user => { 
        if (user) 
        {           
          // console.log(user);
          this.setState({ user: user });  
        } 
      })
      .catch(err => 
      { 
        console.log(err);             
      }); 
    } 
    catch (err) 
    { 
      console.log(err); 
    }           

  }
  
  handleCloseModal() {
    this.setState({openConsoleSupportModal: false})
  }

  
  render() {
    const { user, openConsoleSupportModal } = this.state;
    return (
      <div className="App" 
        // style={globalStyles.DarkTheme}
        >
        
        {/* <button onClick={() => this.authenticate()}>authenticate</button>                 */}
        {/* {user === null && (<button onClick={() => Auth.federatedSignIn()}>Sign In</button>)} */}
        {/* {user !== null && (<button onClick={() => Auth.signOut()}>Sign Out</button>)} */}

        {/* {user== null && <CredentialsRouting user={user} />} */}
        {user !== null && 
          <div className="App" 
            // style={globalStyles.DarkTheme}
            > 
            

            {/* Here are a few examples on how to call an API with accessToken              */}
            {/* <button onClick={() => user_info_get()}>user_info_get</button>                 
            <button onClick={() => organizations_get()}>organizations_get</button>                 
            <button onClick={() => projects_get()}>projects_get</button>                 
            <button onClick={() => organization_create()}>organization_create</button>                              */}
            
            <Provider store={store}>
              {/* <CredentialsRouting /> */}
              <SnackbarPopUp />
              <MainNavPage/>
            </Provider>
            
          </div>
          }
      </div>
    );
  }
}

// export default App;
export default withOAuth(App);
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles , fade} from '@material-ui/core/styles';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import IoTFlowsLogo from '../IoTFlowsLogoWhiteFont.png';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import Badge from '@material-ui/core/Badge';
import SelectedProjectPage from '../MyProjectsPage/SelectedProjectPage';
import MainDevicesPage from '../MyDevicesPage/MainDevicesPage';
import OrganizationAssetsPage from '../MyAssetsPage/OrganizationAssetsPage';
import SelectedDevicePage from '../MyDevicesPage/SelectedDevicePage';
import SelectedAssetPage from '../MyAssetsPage/SelectedAssetPage';
import MembersListPage from '../MyMembersPage/MembersListPage';
import MainDataStreamsPage from '../MyDataStreamsPage/MainDataStreamsPage';
import MainLogsPage from '../MyLogsPage/MainLogsPage';
import ApiKeysPage from '../MyApiKeysPage/ApiKeysPage';
import AlertsPage from '../MyAlertsPage/AlertsPage';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import ProjectListPage from '../MyProjectsPage/ProjectListPage';
import "../styles/ButtonStyles.scss";
// import RouterOutlinedIcon from '@material-ui/icons/RouterOutlined';
import NetworksListPage from '../MyNetworksPage/NetworksListPage';
import BillingPage from '../MyBillingPage/BillingPage';
import UsagePage from '../MyUsagePage/UsagePage';
// import WalletPage from '../MyBillingPage/WalletPage';
import {getData} from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {fetchCurrentUser} from '../store/actions/userActions';
import {fetchOrganizations, fetchSelectedOrganization, fetchingOrganizationsComplete} from '../store/actions/organizationActions';
import Error404 from '../ErrorPage/Error404';
import ProfileButton from './ProfileButton';
import OrganizationButtons from './OrganizationButtons';
import OrganizationSelection from './OrganizationSelection';
import IoTFlowsMemberRoleButton from './IoTFlowsMemberRoleButton';
import FeedbackButton from './FeedbackButton';
import MqttService from '../core/services/MqttService';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import SubtitlesOutlinedIcon from '@material-ui/icons/SubtitlesOutlined';
import SuspendedPersonalOrgModal from '../components/SuspendedPersonalOrgModal';
import AddFreeTrialOrganizationModal from '../components/AddFreeTrialOrganizationModal';
import AddOrganizationModal from '../components/AddOrganizationModal';
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';
import LaptopMacOutlinedIcon from '@material-ui/icons/LaptopMacOutlined';
import InstancesPage from '../MyInstancesPage/InstancesPage';
// import { Mixpanel } from '../Mixpanel/Mixpanel.js';
import {globalStyles} from "../styles/globalStyles";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import StorageRoundedIcon from '@material-ui/icons/StorageRounded';
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

import DashboardTestPage from '../MyDashboards/DashboardTestPage';
import DashboardWithAssetTrackingMap from '../MyDashboards/DashboardWithAssetTrackingMap';
import DashboardSATPiston from '../MyDashboards/DashboardSATPiston';
import DashboardSATBechtel from '../MyDashboards/DashboardSATBechtel';
import SelectedAssetPageTestingDash from '../MyAssetsPage/SelectedAssetPageTestingDash';

const drawerWidth = 57;

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2196f3",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 16,
    border: '1px solid #fafafa',
    // arrow: {
    //   backgroundColor: '#fafafa',
    //   color: '#2196f3',
    // },
    
  },
  arrow: {
    // backgroundColor: '#fafafa',
    // border: '1px solid #fafafa',
    color: '#2196f3',
  },
}))(Tooltip);

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 0,
    top: 0,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 0px',
  },
}))(Badge);


const styles = theme => ({
  root: {
    flexGrow: 1,
    // height: 460,
    // zIndex: 1,
    // overflow: 'hidden',
    // position: 'relative',
    display: 'flex',
  },
  imageContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    paddingTop: theme.spacing(1),
    // paddingLeft: theme.spacing(1),
    margin: '0px',
    flex: 1,
    // height: '34px',
    width: '130px',
    [theme.breakpoints.down('md')]: {
      width: '105px',
      height: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '95px',
      height: '35px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '77px',
      height: '30px',
    },
    
  },
  imageContainerSBD: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    paddingTop: theme.spacing(1),
    // paddingLeft: theme.spacing(1),
    margin: '0px',
    flex: 1,
    width: '190px',
    maxHeight: '32px',
    [theme.breakpoints.down('md')]: {
      width: '170px',
      // height: '45px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90px',
      // height: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '77px',
    },
    
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // padding: theme.spacing(4.5),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: globalStyles.DarkTheme.backgroundColor,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    overflow: 'scroll',
  },
  search: {
    position: 'relative',
    overflow: 'hidden',
    E: theme.shape.borderRadius,
    borderRadius: '25px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: '100%',
      
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      overflow: 'hidden',
      width: '100%',
      
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
      // marginRight: theme.spacing(5),
      // alignItems: 'flex-start',
      // justifyContent: 'flex-start',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  hoverText: {    
    fontSize: 33,
    paddingLeft: 0,
    // '&:active': {
    //   backgroundColor: '#dd2c00',
    // },
    // '&:focus': {
    //   // outline: 'none',
    //   // outlineOffset: 'inherit',
    //   color: '#dd2c00'
    // }
  },
  // active: {
  //   backgroundColor: '#dd2c00'
  // }
  activeState: {
    color: '#2196f3', 
    backgroundColor:'#e8eaf6', 
    borderRadius:'5px'
  },
  iconButtonAvatar: {
    padding: 1,
    outline: 'none',
  },
  navButton: {
    color: '#e0e0e0',
    // fontSize: 50,
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    padding: '0px',
    margin: '0px',
    justifyContent: 'center',
    alignItems:'center',
    alignItems: 'center',
    // display:'inline-block',
    // cursor:'pointer',
    // fontSize:'14px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
      // border:'1px solid #e0e0e0',

    },
    // '&:active': {
    //   position:'relative',
    //   top:'1px',
    //   color:'#fafafa',
      
    // },
  },
  listItemCSS: {
    paddingRight: '0px', 
    paddingLeft: '0px', 
    textAlign:'center',
  },
  listItemIconCSS: {
    minWidth: '56px',
    width:'56px',
    justifyContent:'center', 
    alignContent: 'center', 
    alignItems: 'center'
  },
  trialCSS: {
    textAlign: 'center',
    color:'#444444', 
    border:'1px solid rgb(218,218,218)', 
    backgroundColor: 'rgb(218,218,218)',  
    fontSize: '0.875rem', 
    fontFamily:'Trebuchet MS', 
    padding: '8px', 
    borderRadius: '5px', 
    margin: '0 0 16px 0',
    position: 'fixed',
    bottom:'10px',
    width: '300px',
    left: '50%',
    marginLeft: '-150px',
    // [theme.breakpoints.down('xs')]: {
    //   width: '300px',
    //   fontSize: '0.675rem', 
    // },
  },
  userOfflineCSS: {
    textAlign: 'center',
    color:'#a41c4e', 
    border:'1px solid #fde2dd', 
    backgroundColor: '#fde2dd',  
    fontSize: '0.875rem', 
    fontFamily:'Trebuchet MS', 
    padding: '8px', 
    borderRadius: '5px', 
    margin: '0 0 16px 0',
    position: 'fixed',
    top:'20px',
    width: '200px',
    left: '50%',
    marginLeft: '-100px',
    zIndex: 10000,
    // [theme.breakpoints.down('xs')]: {
    //   width: '300px',
    //   fontSize: '0.675rem', 
    // },
  }

  
});




// const ProfileButton = React.forwardRef(({ children, onClick }, ref) => (
//   <a
//     href=""
//     ref={ref}
//     onClick={e => {
//       e.preventDefault();
//       onClick(e);
//     }}
//   >
//     { <Avatar alt="Ulises Carrasco" src={currentUserInfo[0].user_image_url}  />}
//     {/* &#x25bc; */}
//     {children}
//   </a>
// ));

const NotificationButton = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    { <NotificationsActiveOutlinedIcon fontSize="large" style={{color:'#e0e0e0'}}  />}
    {/* &#x25bc; */}
    {children}
  </a>
));



class MainNavPage extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      current_organization_uuid: '',
      user_cards: [],
      toPath: '',
      redirect: false,
      // suspendedPersonalOrgModalOpen: false,
      iotflows_member_role_id: '',
      subscriptionTrialDetails: [],
      subscriptionTrialEnded: true,
      isUserOnline: true,
      addFreeTrialOrganizationModalOpen: false,
      addOrganizationModalOpen: false,
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.resetModalStates = this.resetModalStates.bind(this);
    this.usageNavigation = this.usageNavigation.bind(this);

    
  }

  componentDidMount() {

    
    this.props.fetchOrganizations();
    this.props.fetchCurrentUser();
    localStorage.removeItem('ssh_username');
    localStorage.removeItem('ssh_password');

    getData('https://api.iotflows.com/v1/users/iotflows')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data && (data.data.length>0) && data.data[0].iotflows_member_role_id){
          this.setState({iotflows_member_role_id: data.data[0].iotflows_member_role_id})
        }    
      }
    });
    
    MqttService.connectClient(
      this.mqttSuccessHandler,
      this.mqttConnectionLostHandler
    );

  } 
  

  mqttSuccessHandler = () => {
    console.log("connected to mqtt");
    this.setState({
      isUserOnline: true
    });
  };

  mqttConnectionLostHandler = () => {
    console.log("lost connection to mqtt");

    this.setState({
      isUserOnline: false
    });
  };

  componentDidUpdate(prevProps, prevState) {

    // if(Object.keys(this.props.currentUserInfo[0]).length > Object.keys(prevProps.currentUserInfo[0]).length){
    //   Mixpanel.identify(this.props.currentUserInfo[0].user_id);
    //   Mixpanel.track('User web login');
    //   Mixpanel.people.set({
    //     $first_name: this.props.currentUserInfo[0].user_first_name,
    //     $last_name: this.props.currentUserInfo[0].user_last_name,
    //     $email: this.props.currentUserInfo[0].user_email,
    //     $avatar: this.props.currentUserInfo[0].user_image_url,
    //     $phone: this.props.currentUserInfo[0].user_image_url,
    //     $created: this.props.currentUserInfo[0].user_created_at,
    //     $username: this.props.currentUserInfo[0].user_username,
    //   });
    // }

    // checking to see if user has an organization or starting a free trial
    if(this.props.isFetchingOrganizationsComplete){
      // if(this.props.currentUserInfo[0] && this.props.allOrganizations && (Object.keys(this.props.currentUserInfo[0]).length > Object.keys(prevProps.currentUserInfo[0]).length || Object.keys(prevProps.allOrganizations).length != Object.keys(this.props.allOrganizations).length) ){
      if(this.props.currentUserInfo[0] ){

        // Does the user have an organization?
        if(this.props.currentUserInfo[0] && Object.keys(this.props.allOrganizations).length == 0 &&  !this.props.currentUserInfo[0].user_has_used_free_trial){
          // New user with a free tier and no organizations 
          // console.log('inside free tier with no org')
          this.setState({addFreeTrialOrganizationModalOpen: true})
          this.props.fetchingOrganizationsComplete(false);
          
        }else if(this.props.currentUserInfo[0] && Object.keys(this.props.allOrganizations).length == 0 && this.props.currentUserInfo[0].user_has_used_free_trial) {
          // add organization with CC
          // console.log('inside NO org with NO free tier')
          this.setState({addOrganizationModalOpen: true})
          this.props.fetchingOrganizationsComplete(false);
        }
        if(this.props.currentUserInfo[0] && Object.keys(this.props.allOrganizations).length > 0 ){
          // console.log('inside HAS Organizatrion')
          this.setState({
            addFreeTrialOrganizationModalOpen: false,
            addOrganizationModalOpen: false})
            this.props.fetchingOrganizationsComplete(false);
        }

      }
    }




    if(Object.keys(prevProps.allOrganizations).length > Object.keys(this.props.allOrganizations).length){
      this.props.fetchSelectedOrganization(this.props.allOrganizations[0]);
        // Checking to ensure organization is not suspended and has proper payment in place and that theres more than 1 organization in place
          if ( Object.keys(this.props.allOrganizations).length > 0 && this.props.allOrganizations[0].organization_is_suspended){
            this.setState({
              toPath: '/billing',
              // suspendedPersonalOrgModalOpen: false
            }, () => {this.setState({redirect: true})} );
          } else if(Object.keys(this.props.allOrganizations).length > 0) {

            this.setState({
              // suspendedPersonalOrgModalOpen: false,
              subscriptionTrialEnded: true,
              toPath: '/projects',
            }, () => {this.setState({redirect: true})})
  
            // console.log('made it to 1 ')
            
  
            // Checking to see if we are currently in a trial version NEW CODEEEEEEEE
            getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/subscription-trial-end-days')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                this.setState({ 
                  subscriptionTrialDetails: data.data
                });
                // console.log('Made it to 111 free trial days')
                // console.log(data.data.trial_end_days)
  
                // Are they still in the trial period???
                if(data.data.trial_end_days > 0) {
  
                  this.setState({
                    subscriptionTrialEnded: false,
                    toPath: '/projects',
                  },() => {
                      this.setState({redirect: true});
                  });
                  
                  // checking to see if they have CC in place
                  getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/payment_methods/card')
                  .then(async response => {
                    if(response.ok ){
                      const data = await response.json();
                      this.setState({
                        user_cards: data.data,
                      });
                    }
                  });
                }else {
                  this.setState({ subscriptionTrialEnded: true });
                  
                  // Checking to see if they have a CC in place
                  getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/payment_methods/card')
                  .then(async response => {
                    if(response.ok ){
                      const data = await response.json();
                      this.setState({
                        user_cards: data.data,
                      },() => {
                        if (this.state.user_cards == '') {
                          this.setState({
                            toPath: '/billing'
                          }, () => {this.setState({redirect: true})} );
                        } else {
                          this.setState({
                            toPath: '/projects'
                          }, () => {this.setState({redirect: true})} );
                        }
                      });
                    }
                  });
                }
              }
            });

            

            // Old CODE MIGHT REVERT BACK BASED ON TRIAL IMPLEMENTATION
            // getData('https://api.iotflows.com/v1/organizations/' + this.props.allOrganizations[0].organization_uuid+ '/payment_methods/card')
            // .then(async response => {
            //   if(response.ok ){
            //     const data = await response.json();
            //     this.setState({
            //       user_cards: data.data,
            //     },() => {
            //       if (this.state.user_cards == '' && !this.props.allOrganizations[0].organization_is_personal) {
                    
            //         this.setState({
            //           toPath: '/billing',
            //           suspendedPersonalOrgModalOpen: false
            //         }, () => {this.setState({redirect: true})} );
            //       } else {
            //         this.setState({
            //           toPath: '/projects',
            //           suspendedPersonalOrgModalOpen: false
            //         }, () => {this.setState({redirect: true})} );
            //       }
            //     });
            //   }
            // });

          }else if(Object.keys(this.props.allOrganizations).length == 0) {
            window.location.reload();
          }

        // }
        // else if(this.props.allOrganizations[0].organization_is_personal){
        //   if(this.props.allOrganizations[0].organization_is_suspended){
        //     this.setState({
        //       suspendedPersonalOrgModalOpen: true,
        //       toPath: '/projects',
        //     }, () => {this.setState({redirect: true})})
        //   }else {
        //     this.setState({
        //       suspendedPersonalOrgModalOpen: false,
        //       toPath: '/projects',
        //     }, () => {this.setState({redirect: true})})
        //   }
        // }
    }

    // resetting redirect state after rerouting
    if(prevState.redirect === true){
      this.setState({redirect: false})
    }

    
    // Initilizing the first Selected organization in order to initially populate page (FIRST SELECTED ORG)
      
    if(prevProps.allOrganizations == ''){
      if(this.props.selectedOrganization == undefined){
        this.props.fetchSelectedOrganization(this.props.allOrganizations[0]);
        // Checking to ensure organization is not suspended and has proper payment in place 
        if(this.props.allOrganizations != '' ) {

          if (this.props.allOrganizations[0].organization_is_suspended){
            this.setState({
              toPath: '/billing',
              // suspendedPersonalOrgModalOpen: false
            }, () => {this.setState({redirect: true})} );
          } else {
            // console.log('data inside first ORG')
            this.setState({
              toPath: '/projects',
            },() => {
                this.setState({redirect: true});
            });
            
            // Checking to see if we are currently in a trial version NEW CODEEEEEEEE
            getData('https://api.iotflows.com/v1/organizations/' + this.props.allOrganizations[0].organization_uuid + '/billing/subscription-trial-end-days')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                // console.log('data 22')
                // console.log(data.data)
                // console.log(data.data.trial_end_days)
                this.setState({ 
                  subscriptionTrialDetails: data.data
                });

                if(data.data.trial_end_days > 0) {
                  this.setState({ subscriptionTrialEnded: false });
                  // checking to see if they have CC in place
                  getData('https://api.iotflows.com/v1/organizations/' + this.props.allOrganizations[0].organization_uuid + '/payment_methods/card')
                  .then(async response => {
                    if(response.ok ){
                      const data = await response.json();
                      this.setState({
                        user_cards: data.data,
                        toPath: '/projects',
                      },() => {
                          this.setState({redirect: true});
                      });
                    }
                  });
                }else {
                  this.setState({ subscriptionTrialEnded: true });
                  // Checking to see if they have a CC in place and redirect them i they don't Due to trial being over
                  getData('https://api.iotflows.com/v1/organizations/' + this.props.allOrganizations[0].organization_uuid + '/payment_methods/card')
                  .then(async response => {
                    if(response.ok ){
                      const data = await response.json();
                      this.setState({
                        user_cards: data.data,
                      },() => {
                        if (this.state.user_cards == '' && !this.props.allOrganizations[0].organization_is_personal) {
                          this.setState({
                            toPath: '/billing',
                            // suspendedPersonalOrgModalOpen: false
                          }, () => {this.setState({redirect: true})} );
                        } else {
                          this.setState({
                            toPath: '/projects',
                            // suspendedPersonalOrgModalOpen: false
                          }, () => {this.setState({redirect: true})} );
                        }
                      });
                    }
                  });
                }
              }
            });
          }
        } 
      }
    } 

    // After clicking a new organization logic
    if(prevProps.selectedOrganization != undefined) {
      if(prevProps.selectedOrganization.organization_uuid != this.props.selectedOrganization.organization_uuid) {
        // After a different organization is selected, ensuring account is not suspended and has valid payment
        if(this.props.selectedOrganization.organization_is_suspended ){
          this.setState({toPath: '/billing'}, () => {this.setState({redirect: true})} );
        } 
        // else if (!this.props.selectedOrganization.organization_is_personal) {

        //     // Old CODE MIGHT REVERT BACK BASED ON TRIAL IMPLEMENTATION
        //     getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/payment_methods/card')
        //     .then(async response => {
        //       if(response.ok ){
        //         const data = await response.json();
        //         this.setState({
        //           user_cards: data.data,
        //         },() => {
        //           if (this.state.user_cards == '') {
        //             this.setState({
        //               toPath: '/billing',
        //               suspendedPersonalOrgModalOpen: false
        //             }, () => {this.setState({redirect: true})} );
        //           } else {
        //             this.setState({
        //               toPath: '/projects',
        //               suspendedPersonalOrgModalOpen: false
        //             }, () => {this.setState({redirect: true})} );
        //           }
        //         });
        //       }
        //     });
        // } 
        // else if(this.props.selectedOrganization.organization_is_suspended ){
        //   this.setState({
        //     suspendedPersonalOrgModalOpen: true,
        //     toPath: '/projects',
        //   }, () => {this.setState({redirect: true})})
        // }
        else {

          this.setState({
            // suspendedPersonalOrgModalOpen: false,
            subscriptionTrialEnded: true,
            toPath: '/projects',
          }, () => {this.setState({redirect: true})})

          // console.log('made it to 3 ')

          // Checking to see if we are currently in a trial version NEW CODEEEEEEEE
          getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/subscription-trial-end-days')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              // console.log('data 333')
              //   console.log(data.data.trial_end_days)
              this.setState({ 
                subscriptionTrialDetails: data.data
              });

              // Are they still in the trial period???
              if(data.data.trial_end_days > 0) {

                this.setState({
                  subscriptionTrialEnded: false,
                  toPath: '/projects',
                },() => {
                    this.setState({redirect: true} );
                });
                
                // checking to see if they have CC in place
                getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/payment_methods/card')
                .then(async response => {
                  if(response.ok ){
                    const data = await response.json();
                    this.setState({
                      user_cards: data.data,
                    });
                  }
                });
              }else {
                this.setState({ subscriptionTrialEnded: true });

                // Checking to see if they have a CC in place
                getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/payment_methods/card')
                .then(async response => {
                  if(response.ok ){
                    const data = await response.json();
                    this.setState({
                      user_cards: data.data,
                    },() => {
                      if (this.state.user_cards == '') {
                        this.setState({
                          toPath: '/billing'
                        }, () => {this.setState({redirect: true})} );
                      } else {
                        this.setState({
                          toPath: '/projects'
                        }, () => {this.setState({redirect: true})} );
                      }
                    });
                  }
                });
              }
            }
          });

        }
      }
    }


  }

  handleDrawerToggle = () => {
    this.state.mobileOpen == true ? this.setState( {mobileOpen: false} ) : this.setState( {mobileOpen: true} )
  }

  resetModalStates = () => {
    this.setState({
      // suspendedPersonalOrgModalOpen: false,
      addFreeTrialOrganizationModalOpen: false,
      addOrganizationModalOpen: false
    })
  }
  usageNavigation = () => {
    this.setState({
      redirect: false,
      toPath: '/usage',
    }, () => {this.setState({redirect: true})})
  }
  


  render() {
    const { classes, container, selectedOrganization} = this.props
    const {mobileOpen, 
          current_organization_uuid, 
          toPath, 
          redirect, 
          suspendedPersonalOrgModalOpen, 
          iotflows_member_role_id,
          subscriptionTrialDetails,
          subscriptionTrialEnded,
          isUserOnline,
          addFreeTrialOrganizationModalOpen,
          addOrganizationModalOpen} = this.state
    
    return(
      <div className={classes.root}>
      <Router>
      {redirect ? <Redirect push exact to={toPath} /> : null}
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} style={globalStyles.DarkTheme} >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Grid container spacing={3} alignItems="center" >

            <Grid item xs={6}  >
            {/* {(selectedOrganization && selectedOrganization.organization_logo_url) ? 
              <Row className={classes.imageContainerSBD}>
                <NavLink to="/projects"  > 
                <Image  src={selectedOrganization.organization_logo_url} alt="IoTFlows Logo" fluid style={{justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start', maxHeight:'31px'}} />
                </NavLink>
              </Row>
              :
              <Row className={classes.imageContainer}>
                <NavLink to="/projects"  > 
                  <Image  src={IoTFlowsLogo} alt="IoTFlows Logo" fluid style={{justifyContent: 'flex-end', alignItems: 'flex-end', alignContent: 'flex-end'}} />
                </NavLink>
              </Row>
            } */}
            {selectedOrganization != undefined ? <OrganizationSelection/> : null}
            </Grid>

            {/* <Grid item xs={3} style={{textAlign: 'left'}} >
              {selectedOrganization != undefined ? <OrganizationButtons/> : null}
            </Grid> */}

            
            <Hidden xsDown> 
            <Grid item xs={1} style={{alignItems: 'flex-end'}} >
              {/* xs down 1  */}
              {/* <div className={classes.search}  >
                <div className={classes.searchIcon} >
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div> */}
            </Grid>
            </Hidden>

            {/* <Grid item xs={1} style={{textAlign: 'right', paddingTop: '16px'}} >
                <Dropdown>
                  <StyledBadge badgeContent={4} color="primary">
                    <Dropdown.Toggle as={NotificationButton} >
                    </Dropdown.Toggle>
                  </StyledBadge >

                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="1" ><Alert variant="outlined" severity="error">This is an error alert — check it out!</Alert></Dropdown.Item>
                    <Dropdown.Item eventKey="2"><Alert variant="outlined" severity="warning">This is a warning alert — check it out!</Alert></Dropdown.Item>
                    <Dropdown.Item eventKey="3" ><Alert variant="outlined" severity="info">This is an info alert — check it out!</Alert></Dropdown.Item>
                    <Dropdown.Item eventKey="4" ><Alert variant="outlined" severity="success">This is a success alert — check it out!</Alert></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            </Grid> */}
            <Grid item xs={4} >
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <div>
                  {iotflows_member_role_id != '' ? <IoTFlowsMemberRoleButton/> : null}
                </div>
                <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                  <FeedbackButton/>
                </div>
              </div>
            </Grid>

            <Grid item xs={1} style={{textAlign: 'center'}}>
              <ProfileButton usageNavigation={this.usageNavigation} /> 
            </Grid>

            <Hidden smUp>
              <Grid item xs={1}  >
              {/* smUp 1 content */}
              </Grid>
            </Hidden>
            
            
          </Grid>
        </Toolbar>
      </AppBar>
      
      <nav className={classes.drawer} aria-label="navigation button">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor='left'
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {/* <div className={classes.toolbar} /> */}
            <Grid >
              <Grid item>
                <ListItem button  className={classes.listItemCSS}    >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                    <NavLink to="/projects"  style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3'}} >
                      <LightTooltip  className={classes.hoverText} title="Projects" placement="right-start" arrow  >
                        <AssignmentOutlinedIcon   />
                      </LightTooltip> 
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                <ListItem button  className={classes.listItemCSS}    >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                    <NavLink to="/assets"  style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3'}} >
                      <LightTooltip  className={classes.hoverText} title="Assets" placement="right-start" arrow  >
                        <RoomPreferencesOutlinedIcon  />
                      </LightTooltip> 
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                <ListItem button  className={classes.listItemCSS}    >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                    <NavLink to="/instances"  style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3'}} >
                      <LightTooltip  className={classes.hoverText} title="Cloud Node-RED Servers" placement="right-start" arrow  >
                        <StorageRoundedIcon  />
                      </LightTooltip> 
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                <ListItem button  className={classes.listItemCSS}   >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                  <NavLink to="/devices" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3'}}>
                      <LightTooltip className={classes.hoverText} title="Devices" placement="right-start" arrow>
                        <DeviceHubIcon  />
                      </LightTooltip>
                      </NavLink> 
                  </ListItemIcon>
                </ListItem>
                <ListItem button  className={classes.listItemCSS}   >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                    <NavLink to="/members" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3'}}>
                      <LightTooltip className={classes.hoverText} title="Members" placement="right-start" arrow>
                        <PeopleOutlineIcon   />
                      </LightTooltip>
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                {/* <ListItem button  className={classes.listItemCSS}   >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                    <NavLink to="/data-streams" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3' }}>
                      <LightTooltip className={classes.hoverText} title="Data Streams" placement="right-start" arrow>
                        <BlurOnIcon  />
                      </LightTooltip>
                      </NavLink>
                  </ListItemIcon>
                </ListItem> */}
              </Grid>
              <Grid item>
                <ListItem button  className={classes.listItemCSS}   >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                  <NavLink to="/alerts" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3' }}>
                      <LightTooltip className={classes.hoverText} title="Alerts" placement="right-start" arrow>
                        <AnnouncementOutlinedIcon  />
                      </LightTooltip>
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                {/* <ListItem button  className={classes.listItemCSS}   >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                  <NavLink to="/networks" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3' }}>
                      <LightTooltip className={classes.hoverText} title="Wi-Fi Networks" placement="right-start" arrow>
                        <RouterOutlinedIcon  />
                      </LightTooltip>
                    </NavLink>
                  </ListItemIcon>
                </ListItem> */}
                <ListItem button disabled={selectedOrganization && (selectedOrganization.organization_member_role_id == '1'|| selectedOrganization.organization_member_role_id == '2' || localStorage.getItem('iotflows_member_role_id') == 'IOTFLOWS_GOD') ? false : true} className={classes.listItemCSS}   >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                  <NavLink to="/logs" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3' }}>
                      <LightTooltip className={classes.hoverText} title="Logs" placement="right-start" arrow>
                        <SubtitlesOutlinedIcon  />
                      </LightTooltip>
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                <ListItem button disabled={selectedOrganization != undefined && (selectedOrganization.organization_member_role_id == '1'|| selectedOrganization.organization_member_role_id == '2' || localStorage.getItem('iotflows_member_role_id') == 'IOTFLOWS_GOD') ? false : true} className={classes.listItemCSS}   >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                  <NavLink to="/apikeys" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3' }}>
                      <LightTooltip className={classes.hoverText} title="API Keys" placement="right-start" arrow>
                        <VpnKeyRoundedIcon  />
                      </LightTooltip>
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                {/* <ListItem button  className={classes.listItemCSS}   >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                  <NavLink to="/dashboards" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3' }}>
                      <LightTooltip className={classes.hoverText} title="Dashboards" placement="right-start" arrow>
                        <DashboardOutlinedIcon  />
                      </LightTooltip>
                    </NavLink>
                  </ListItemIcon>
                </ListItem> */}
              </Grid>
              <Grid  item style={{position: 'absolute', bottom: '10px' }}> 
                <LightTooltip className={classes.hoverText} title="Billing" placement="right-start" arrow>
                  <div>
                  <ListItem button 
                  disabled={(selectedOrganization && selectedOrganization.organization_member_role_id == '1' && selectedOrganization.organization_is_auto_billing_active) || (localStorage.getItem('iotflows_member_role_id') == 'IOTFLOWS_GOD')  ? false : true} 
                  className={classes.listItemCSS}   >
                    <ListItemIcon  className={classes.listItemIconCSS}>
                      <NavLink to="/billing" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3'}}>
                        <LightTooltip className={classes.hoverText} title="" placement="right-start" arrow>
                          <ReceiptOutlinedIcon  />
                        </LightTooltip>
                      </NavLink>
                    </ListItemIcon>
                  </ListItem>
                  </div>
                </LightTooltip>
                <ListItem button className={classes.listItemCSS} >
                  <ListItemIcon className={classes.listItemIconCSS} >
                      <LightTooltip className={classes.hoverText} title="Documentation" placement="right-start" arrow>
                        <MenuBookOutlinedIcon  style={{ color: '#e0e0e0'}} onClick={() => window.open('https://docs.iotflows.com/', "_blank")} />
                      </LightTooltip>
                  </ListItemIcon>
                </ListItem>
              </Grid>
            </Grid>

          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <div className={classes.toolbar} />
            <Grid >
              <Grid item >
                <ListItem button className={classes.listItemCSS}   >
                  <ListItemIcon className={classes.listItemIconCSS} >
                    <NavLink to="/projects" style={{ color: '#e0e0e0', width: '100%', height: '100%'}} activeStyle={{ color: '#2196f3'}}>
                      <LightTooltip className={classes.hoverText} title="Projects" placement="right-start" arrow>
                        <AssignmentOutlinedIcon />
                      </LightTooltip> 
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                <ListItem button className={classes.listItemCSS}   >
                  <ListItemIcon className={classes.listItemIconCSS} >
                    <NavLink to="/assets" style={{ color: '#e0e0e0', width: '100%', height: '100%'}} activeStyle={{ color: '#2196f3'}}>
                      <LightTooltip className={classes.hoverText} title="Assets" placement="right-start" arrow>
                      <RoomPreferencesOutlinedIcon />
                      </LightTooltip> 
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                <ListItem button className={classes.listItemCSS}   >
                  <ListItemIcon className={classes.listItemIconCSS} >
                    <NavLink to="/instances" style={{ color: '#e0e0e0', width: '100%', height: '100%'}} activeStyle={{ color: '#2196f3'}}>
                      <LightTooltip className={classes.hoverText} title="Cloud Node-RED Servers" placement="right-start" arrow>
                      <StorageRoundedIcon />
                      </LightTooltip> 
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                <ListItem button className={classes.listItemCSS} >
                  <ListItemIcon className={classes.listItemIconCSS} >
                  <NavLink to="/devices" style={{ color: '#e0e0e0', width: '100%', height: '100%'}} activeStyle={{ color: '#2196f3'}}>
                      <LightTooltip className={classes.hoverText} title="Devices" placement="right-start" arrow>
                        <DeviceHubIcon  />
                      </LightTooltip>
                      </NavLink> 
                  </ListItemIcon>
                </ListItem>
                <ListItem button className={classes.listItemCSS} >
                  <ListItemIcon className={classes.listItemIconCSS} >
                    <NavLink to="/members" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3'}}>
                      <LightTooltip className={classes.hoverText} title="Members" placement="right-start" arrow>
                        <PeopleOutlineIcon   />
                      </LightTooltip>
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                {/* <ListItem button className={classes.listItemCSS} >
                  <ListItemIcon className={classes.listItemIconCSS} >
                    <NavLink to="/data-streams" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3'}}>
                      <LightTooltip className={classes.hoverText} title="Data Streams" placement="right-start" arrow>
                        <BlurOnIcon  />
                      </LightTooltip>
                      </NavLink>
                  </ListItemIcon>
                </ListItem> */}
                <ListItem button className={classes.listItemCSS} >
                  <ListItemIcon className={classes.listItemIconCSS} >
                  <NavLink to="/alerts" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3'}}>
                      <LightTooltip className={classes.hoverText} title="Alerts" placement="right-start" arrow>
                        <AnnouncementOutlinedIcon  />
                        {/* <AnnouncementOutlinedIcon fontSize="large"  onClick={() => alert("hello")}/> */}
                      </LightTooltip>
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                {/* <ListItem button className={classes.listItemCSS} >
                  <ListItemIcon className={classes.listItemIconCSS} >
                  <NavLink to="/networks" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3' }}>
                      <LightTooltip className={classes.hoverText} title="Networks" placement="right-start" arrow>
                        <RouterOutlinedIcon  />
                      </LightTooltip>
                    </NavLink>
                  </ListItemIcon>
                </ListItem> */}
                <ListItem button disabled={selectedOrganization && (selectedOrganization.organization_member_role_id == '1'|| selectedOrganization.organization_member_role_id == '2' || localStorage.getItem('iotflows_member_role_id') == 'IOTFLOWS_GOD') ? false : true} className={classes.listItemCSS}   >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                  <NavLink to="/logs" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3' }}>
                      <LightTooltip className={classes.hoverText} title="Logs" placement="right-start" arrow>
                        <SubtitlesOutlinedIcon  />
                      </LightTooltip>
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                <ListItem button disabled={selectedOrganization != undefined && (selectedOrganization.organization_member_role_id == '1'|| selectedOrganization.organization_member_role_id == '2' || localStorage.getItem('iotflows_member_role_id') == 'IOTFLOWS_GOD') ? false : true} className={classes.listItemCSS}   >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                  <NavLink to="/apikeys" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3'}}>
                      <LightTooltip className={classes.hoverText} title="API Keys" placement="right-start" arrow>
                        <VpnKeyRoundedIcon  />
                      </LightTooltip>
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                {/* <ListItem button  className={classes.listItemCSS}   >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                  <NavLink to="/dashboards" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3' }}>
                      <LightTooltip className={classes.hoverText} title="Dashboards" placement="right-start" arrow>
                        <DashboardOutlinedIcon />
                      </LightTooltip>
                    </NavLink>
                  </ListItemIcon>
                </ListItem> */}
              </Grid>

              <Grid  item style={{ position: 'absolute', bottom: '10px' }}>
              <LightTooltip className={classes.hoverText} title="Billing" placement="right-start" arrow>
                <div>
                <ListItem button 
                disabled={(selectedOrganization && selectedOrganization.organization_member_role_id == '1' && selectedOrganization.organization_is_auto_billing_active) || (localStorage.getItem('iotflows_member_role_id') == 'IOTFLOWS_GOD')  ? false : true} 
                className={classes.listItemCSS}   >
                  <ListItemIcon  className={classes.listItemIconCSS}>
                    <NavLink to="/billing" style={{ color: '#e0e0e0', width: '100%', height: '100%'}}  activeStyle={{ color: '#2196f3'}}>
                      <LightTooltip className={classes.hoverText} title="" placement="right-start" arrow>
                        <ReceiptOutlinedIcon  />
                      </LightTooltip>
                    </NavLink>
                  </ListItemIcon>
                </ListItem>
                </div>
              </LightTooltip>
                <ListItem button className={classes.listItemCSS} >
                  <ListItemIcon className={classes.listItemIconCSS} >
                      <LightTooltip className={classes.hoverText} title="Documentation" placement="right-start" arrow>
                        <MenuBookOutlinedIcon  style={{ color: '#e0e0e0'}} onClick={() => window.open('https://docs.iotflows.com/', "_blank")} />
                      </LightTooltip>
                  </ListItemIcon>
                </ListItem>
              </Grid>
            </Grid>

          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content} 
        // style={globalStyles.DarkTheme}  
        >
          
        <div className={classes.toolbar} />
        <div className={classes.rootTable}>

          {/* Modal for suspended personal organizations */}
          {/* {suspendedPersonalOrgModalOpen ? <SuspendedPersonalOrgModal open={suspendedPersonalOrgModalOpen} resetModalStates={this.resetModalStates}/> : null} */}
          {/* Adding organizations modals */}
          {addFreeTrialOrganizationModalOpen ? <AddFreeTrialOrganizationModal open={addFreeTrialOrganizationModalOpen} disableCancel={true} resetModalStates={this.resetModalStates}/> : null}
          {addOrganizationModalOpen ? <AddOrganizationModal open={addOrganizationModalOpen} handleCloseModal={this.resetModalStates} disableCancel={true} /> : null}

          <Grid container >
            <Grid item xs={12}>
        
              <Switch>

                {/* Empty links */}
                <Route exact path="/" />

                {/* Projects links */}
                {/* <Route path="/projects"  component={ProjectListPage} /> */}
                <Route path="/projects/:project_uuid" component={SelectedProjectPage} />
                <Route exact strict path="/projects"  component={() => <ProjectListPage />} />
                
                {/* Instance link */}
                <Route exact strict path="/instances"  component={() => <InstancesPage  />} />

                {/* Organization Assets links */}
                <Route exact strict path="/assets/sat/piston-group-assets" component={DashboardSATPiston} />
                <Route exact strict path="/assets/sat/bechtel-group-assets" component={DashboardSATBechtel} />
                <Route path="/assets/:asset_uuid" component={SelectedAssetPage} />
                <Route path="/assets" component={() => <OrganizationAssetsPage />} />


                {/* Devices links */}
                <Route path="/devices/:device_uuid/assets/:asset_uuid" component={SelectedAssetPage} />
                <Route path="/devices/:device_uuid" component={SelectedDevicePage} />
                <Route path="/devices" component={() => <MainDevicesPage />} />

                {/* Members Link */}
                {/* <Route path="/members" component={MainMembersPage} /> */}
                <Route path="/members" 
                  component={() => 
                    <MembersListPage 
                    listType= {'Organization'}
                      memberApi = {'https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/members '} 
                      roleApi = {'https://api.iotflows.com/v1/organizations/roles'} 
                      searchMemberApi = {'https://api.iotflows.com/v1/users/search/containing?q='}
                    />
                  }  
                  />


                {/* Data Streams links */}
                <Route path="/data-streams" component={MainDataStreamsPage} />
                {/* This was used for the global state variables  */}
                {/* <Route path="/data-streams" component={MainDataStreamsTest} />   */}

                {/* Alerts Links */}
                <Route path="/alerts" component={AlertsPage}  />

                {/* Networks Links */}
                {/* <Route path="/networks" component={() => <NetworksListPage current_organization_uuid={this.props.selectedOrganization.organization_uuid} />} /> */}
                <Route path="/networks" component={NetworksListPage} />

                {/* Logs links */}
                <Route path="/logs" component={MainLogsPage} />

                {/* Organization API Keys links */}
                <Route path="/apikeys" component={ApiKeysPage} />

                {/* Dashboard Links */}
                {/* <Route path="/dashboards" component={DashboardSATPiston} /> */}
                {/* <Route path="/dashboards" component={SelectedAssetPageTestingDash} /> */}

                {/* Billing Link */}
                {/* <Route path="/billing/wallet" component={WalletPage} /> */}
                <Route path="/billing" component={BillingPage} />

                {/* Usage links */}
                <Route path="/usage" component={UsagePage} />
                
                {/* 404 Page */}
                <Route  path="*" component={Error404} />

              </Switch>
            </Grid>
          </Grid>
        </div>
        {isUserOnline ? null : 
            <Grid container spacing={0} className={classes.userOfflineCSS}>
              <div style={{minWidth: '35px', display:'flex', textAlign: 'center', marginRight: 'auto', marginLeft: 'auto'}}>
                <div>
                  <svg style={{width: '24px',padding: '0 0px 0 0'}} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                    <path d="M4.87 18.23h12.26a1.87 1.87 0 0 0 1.62-2.8L12.62 4.71a1.86 1.86 0 0 0-3.24 0L3.25 15.43a1.87 1.87 0 0 0 1.62 2.8zM11 12.64V7.73m0 7.48v-.16" fill="none" stroke="#a41c4e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path>
                  </svg>
                </div>
                <div style={{padding: '2px 0px 2px 10px'}}>
                  <span style={{fontWeight: '900'}}>Currently offline</span>
                </div>
              </div>
            </Grid>
          }
        {subscriptionTrialEnded ? null : 
            <Grid container spacing={0} className={classes.trialCSS}>
              <div style={{minWidth: '35px', display:'flex', textAlign: 'center', marginRight: 'auto', marginLeft: 'auto'}}>
                <div>
                  <svg style={{width: '24px',padding: '0 0px 0 0'}} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                    <path d="M4.87 18.23h12.26a1.87 1.87 0 0 0 1.62-2.8L12.62 4.71a1.86 1.86 0 0 0-3.24 0L3.25 15.43a1.87 1.87 0 0 0 1.62 2.8zM11 12.64V7.73m0 7.48v-.16" fill="none" stroke="#444444" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path>
                  </svg>
                </div>
                <div style={{padding: '2px 0px 2px 10px'}}>
                  <span style={{fontWeight: '900'}}>Free Trial Period - </span> <span>{subscriptionTrialDetails.trial_end_days} days left</span>
                </div>
              </div>
            </Grid>
          }
      </main>
      </Router>

    </div>
    )
  }

}

MainNavPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.any,
};

const mapDispatchToProps = dispatch => ({
  fetchSelectedOrganization: currentOrganization => dispatch(fetchSelectedOrganization(currentOrganization)),
  fetchingOrganizationsComplete: isAllOrganizationsFetched => dispatch(fetchingOrganizationsComplete(isAllOrganizationsFetched)),
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  fetchOrganizations: () => dispatch(fetchOrganizations()),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
})

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization,
    isFetchingOrganizationsComplete: state.organizations.isFetchingOrganizationsComplete
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainNavPage))

// export default withStyles(styles)(MainNavPage)


import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { Route, Link } from 'react-router-dom';
import borderHexaGrey from './hexaGrey.svg';



const styles = theme => ({
  invert: {},
  errorWrap: {
    background: `url(${borderHexaGrey}) no-repeat`,
    backgroundSize: '100% 100%', 
    backgroundPosition: -10,
    width: 500,
    height: 500,
    [theme.breakpoints.down('sm')]: {
      width: 300,
      height: 300,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    margin: `${theme.spacing(3)}px auto`,
    '&$invert': {
      '& h1, h5': {
        color: theme.palette.common.white
      }
    },
    '& h5': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
      },
    },
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '4rem',
      marginBottom: theme.spacing(2)
    },
  },
  deco: {
    boxShadow: theme.shadows[2],
    position: 'absolute',
    borderRadius: 2,
  },
  button: {
    marginTop: 24
  }
});

const Error404 = (props) => {
  const {
    classes,
    title,
    desc,
    invert
  } = props;
  return (
    <div className={classNames(classes.errorWrap, invert && classes.invert)}>
      <Typography className={classes.title} variant="h1">404</Typography>
      <Typography variant="h5">Oops, Page Not Found :(</Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        component={Link}
        to="/projects"
      >
       Go to Projects
      </Button>
    </div>

    // <div className={classes.pageWrapper} align="center">
    //   <Typography component="h2" variant="h1" gutterBottom>
    //     <Icon className={classes.icon}>sentiment_dissatisfied</Icon>
    //   </Typography>
    //   <Typography component="h3" variant="h1" gutterBottom>
    //     404
    //   </Typography>
    //   <Typography variant="subtitle1" color="textSecondary" gutterBottom>
    //     Page not found
    //   </Typography>
    //   <br/>
    //   <br/>
    //   <Typography variant="body2" gutterBottom>
    //     The Page you are looking for doesn't exsits
    //   </Typography>
    // </div>
  )
}

Error404.propTypes = {
  classes: PropTypes.object.isRequired,
  invert: PropTypes.bool,
};

Error404.defaultProps = {
  invert: false,
};


export default withStyles(styles)(Error404);
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import "../styles/ButtonStyles.scss";
import { connect} from "react-redux";
import {fetchCurrentUser} from '../store/actions/userActions'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import {BrowserRouter as Router,Redirect, withRouter, Route, Switch} from "react-router-dom";
import {fetchOrganizations, fetchSelectedOrganization} from '../store/actions/organizationActions';
import {fetchSelectedIoTFlowsMemberRoleId} from '../store/actions/iotflowsMemberRoleActions';
import DeleteOrganizationModal from '../components/DeleteOrganizationModal';
import EditOrganizationModal from '../components/EditOrganizationModal';
import AddOrganizationModal from '../components/AddOrganizationModal';
import LeaveOrganizationModal from '../components/LeaveOrganizationModal';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import {getData} from '../ApiCalls/DataApis';
import FilterVintageOutlinedIcon from '@material-ui/icons/FilterVintageOutlined';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2196f3",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 16,
    border: '1px solid #fafafa',
    // arrow: {
    //   backgroundColor: '#fafafa',
    //   color: '#2196f3',
    // },
    
  },
  arrow: {
    // backgroundColor: '#fafafa',
    // border: '1px solid #fafafa',
    color: '#2196f3',
  },
}))(Tooltip);


const styles = theme => ({
  settingBtnCSS: {
    backgroundColor:'transparent',
    borderRadius:'5px',
    border:'0px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#11b0d4',
    fontFamily:'Trebuchet MS',
    textDecoration:'none',
    outline: 'none',
    textShadow:'0px 0px 0px #9e9e9e',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  hoverText: {    
    fontSize: 20,
    paddingLeft: 0,
  },
});

class IoTFlowsMemberRoleButton extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      userAnchorEl: null,
      redirect: false,
      userOpen: false,
      addOrganizationModalOpen: false,
      editOrganizationModalOpen: false,
      leaveOrganizationModalOpen: false,
      deleteOrganizationModalOpen: false,
      toPath: '/projects',
      user_cards: [],
      iotflows_member_role_id: '',
      iotflows_member_roles: [],

    };
    this.userHandleClick = this.userHandleClick.bind(this);
    this.userHandleClose = this.userHandleClose.bind(this);
    

  }

  componentDidMount() {
    // this.props.fetchSelectedIoTFlowsMemberRoleId();
    localStorage.removeItem('iotflows_member_role_id')
    getData('https://api.iotflows.com/v1/users/iotflows')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data && (data.data.length>0) && data.data[0].iotflows_member_role_id){
          data.data.unshift({'iotflows_member_role_id': '','iotflows_member_role_name': 'Normal View' })
          
          this.setState({
            iotflows_member_roles: data.data
          })
        }    
      }
    });

  } 

  // componentDidUpdate(prevProps, prevState) {

    
  // }


  userHandleClick = event => {
    this.setState({ userAnchorEl: event.currentTarget  });
    this.setState({ userOpen: true });
    

  };


  handleSelectedMemberRole = (role) => {
    localStorage.setItem('iotflows_member_role_id', role.iotflows_member_role_id)
    this.props.fetchOrganizations();
  };

  userHandleClose = () => {
    this.setState({ userAnchorEl: null });
    this.setState({ userOpen: false});
    };

  


  render() {
    const { classes, currentUserInfo, selectedOrganization, allOrganizations, history, selectedIoTFlowsMemberRoleId} = this.props
    const { userAnchorEl, userOpen,iotflows_member_roles} = this.state
    // const userOpen = Boolean(userAnchorEl);

    return(
      <div>
      <ButtonGroup style={{justifyContent: 'center', alignItems:'center', alignContent:'center'}}>

        <React.Fragment>
          {/* <IconButton color="inherit"  style={{ color: '#9e9e9e'}} className="organizationsSettingsButton" onClick={this.userHandleClick}> */}
          <IconButton className={classes.settingBtnCSS} onClick={this.userHandleClick}>
            <FilterVintageOutlinedIcon  />
          </IconButton>
          <Menu style={{outline: 'none'}}
            id="god-view-menu"
            anchorEl={userAnchorEl}
            open={userOpen}
            onClose={this.userHandleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
          }}
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'center',
          // }}
          PaperProps={{
            style: {
              width: 'auto',
            },
          }}
          >
            {iotflows_member_roles.map((role,id )=> (
              <MenuItem key={id} onClick={() => this.handleSelectedMemberRole(role)} >
                {role.iotflows_member_role_name}
              </MenuItem>
            ))}
            {/* <MenuItem onClick={() => this.handleSelectedMemberRole(role)} >
              Normal User
            </MenuItem> */}
            
            
          </Menu>
        </React.Fragment> 
      </ButtonGroup>


      </div>
      
  )
  
}

}

const mapDispatchToProps = dispatch => ({
  fetchSelectedIoTFlowsMemberRoleId: iotflows_member_role_id => dispatch(fetchSelectedIoTFlowsMemberRoleId(iotflows_member_role_id)),
  fetchSelectedOrganization: currentOrganization => dispatch(fetchSelectedOrganization(currentOrganization)),
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  fetchOrganizations: () => dispatch(fetchOrganizations()),
})

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization,
    selectedIoTFlowsMemberRoleId: state.iotflowsMemberRoleId.selected_iotflows_member_role_id
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IoTFlowsMemberRoleButton))

import React, { useEffect, useState } from "react"
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
// import { useParams, useHistory, useLocation } from 'react-router-dom'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 175,
    },
  },
};


function getTagStyles(tag,tagArray, theme) { 
  return {
    fontWeight:
    tagArray.indexOf(tag) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function TagSelectionButton({currentTagKey, updateURLTagSearch, tagStates}) {
    const theme = useTheme();
    // const history = useHistory();
    const [tagArray, setTagArray] = useState([]);
    
    // this useEFFECT is being used as the component did mount and its to populate the states pulled from the URL when doing a search query
    useEffect(() => {
      tagStates.map(tagJson =>{
        // Addignt this split for the + sign in order to accomodate for the URL of taking care of spaces
        let tempTagKey = tagJson.tag_key.split('+').join(' ')
        if(tempTagKey == currentTagKey.tag_key){
          let value = tagJson.tag_array
          setTagArray(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
            );
        }
      })
    }, [])


    
    useEffect(() => {
        updateURLTagSearch(tagArray, currentTagKey.tag_key)

      }, [tagArray])


    const handleChangeTag = (event) => {
        const {
        target: { value },
        } = event;
        setTagArray(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
    };

    
    return (
        <div>
          {currentTagKey && currentTagKey.tag_key &&
            <FormControl sx={{ m: 1, width:'fit-content', minWidth:175 }}>
              <InputLabel >{currentTagKey.tag_key}</InputLabel>
              <Select
              multiple
              value={tagArray}
              onChange={handleChangeTag}
              input={<OutlinedInput id="select-multiple-chip" label={currentTagKey.tag_key} />}
              renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                      <Chip key={value} label={value} />
                  ))}
                  </Box>
              )}
              MenuProps={MenuProps}
              >
              {currentTagKey.tag_values.map((tag) => (
                  <MenuItem
                  key={tag}
                  value={tag}
                  style={getTagStyles(tag,tagArray, theme)}
                  >
                  {tag}
                  </MenuItem>
              ))}
              </Select>
          </FormControl>
        }
        </div>
    )
}

export default TagSelectionButton

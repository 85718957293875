import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData, putData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import IconButton from '@material-ui/core/IconButton';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';


const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    width: 'auto',
    height: 'auto',
    padding: '4px',
    margin: '0px',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-end',
    alignItems:'flex-end',
    alignItems: 'flex-end',
    fontFamily:'Trebuchet MS',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class EditClientModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      clientName: this.props.selectedClient.client_name,
      clientDescription: this.props.selectedClient.client_description,
      device_disabled: this.props.selectedClient.device_disabled,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitButtonClick = this.submitButtonClick.bind(this);
  }



  timer = undefined;

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleOpen = () => {
    this.setState({ 
      open: true,
      clientName: this.props.selectedClient.client_name,
      clientDescription: this.props.selectedClient.client_description,
     });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClientNameChange = (event) => {
    this.setState({clientName: event.target.value});
  }

  handleClientDescriptionChange = (event) => {
    this.setState({clientDescription: event.target.value});
  }


  submitButtonClick = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        putData('https://api.iotflows.com/v1/clients/'+this.props.selectedClient.client_id, 
        {'client_name': this.state.clientName,
          'client_description': this.state.clientDescription})
          .then(async response => {
            if(response.ok){
              this.props.showSuccessSnackbar("Client has been successfully modified!");
              this.props.refreshClientList();
              this.setState({
                loading: false,
                success: true,
                open:false,
              },() => {this.setState({success: false})} );
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false,
                });
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      device_disabled: nextProps.device_disabled,
    }); 
  };


  render() {
    const { classes } = this.props;
    const { open, loading, success , clientName, clientDescription, device_disabled} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        container
        alignItems="flex-start"
        justify="flex-start"
        direction="column"
        
      >
        {/* <IconButton className={classes.button}  variant="contained"  onClick={this.handleOpen}>
          <Col>
            <Row>
              <AddCircleOutlineOutlinedIcon style={{fontSize: '24px'}} />  
            </Row>
            <Row>
              Add
            </Row> 
          </Col>
          
        </IconButton> */}
        <IconButton className={classes.button} variant="outlined" onClick={this.handleOpen} disabled={device_disabled} ><EditOutlinedIcon style={{color: '#bdbdbd'}}/></IconButton>
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">{"Edit Client ID"}</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '0px'}}>
                <TextField
                  autoFocus
                  margin="normal"
                  id="clientName"
                  label="Client Name"
                  type="text"
                  onChange={this.handleClientNameChange}
                  value={this.state.clientName}
                  fullWidth
                />

                <TextField
                  autoFocus
                  margin="normal"
                  id="clientDescription"
                  label="Client Description"
                  type="text"
                  onChange={this.handleClientDescriptionChange}
                  value={this.state.clientDescription}
                  fullWidth
                />

                {/* <TextField
                autoFocus
                margin="normal"
                id="clientId"
                label="Client ID"
                type="text"
                fullWidth
                disabled
                /> */}

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

EditClientModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => state

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(EditClientModal));

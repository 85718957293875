import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import  {getData, putData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import Tooltip from '@material-ui/core/Tooltip';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    margin:theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(44),
    }
  },
  button: {
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
  hoverText: {    
    fontSize: 33,
    paddingLeft: 0,
    
  },
  radioLabel: {
    fontSize: 14,
  },
  radioCSS: {
    // border: "1px solid blue",
    fontSize: 'small'
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ChangeMemberRoleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    loading: false,
    success: false,
    openSnackbar: false,
    roles: [],
    memberRole: '',
  }

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitButtonClick = this.submitButtonClick.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);

  }
  

  timer = undefined;

  componentDidMount() {
    
      getData(this.props.roleApi)
      .then(async response => {
        
        if(response.ok ){
          const data = await response.json();
          this.setState({
            roles: data.data 
          })
        }
        
      });
  } 

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleOpen = () => {
    this.setState({ 
      open: true,
      success: false,
      loading: false, });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      success: false,
      loading: false, });
  };

  submitButtonClick = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        putData(this.props.memberApi, 
        {'member_username': this.props.user_username,
         'role_name': this.state.memberRole})
          .then(async response => {
            if(response.ok){
              this.props.showSuccessSnackbar("Member role has been successfully changed!");
              this.setState({
                loading: false,
                success: true,
                open:false,
              },()=> {
                this.props.refreshMemberList();
                this.setState({
                  success: false,
                })
              });
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false,
                });
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };


  handleRoleChange = (event) => {
    this.setState({memberRole: event.target.value});
  };

  render() {
    const { classes } = this.props;
    const { open, loading, success, memberRole } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    

    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
        
      >
        <Button className={classes.button} onClick={this.handleOpen}>Change Role</Button>
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes={{ paper: classes.dialog }}
            >
            <Paper className={classes.paper} >

                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">{"Change Role"}</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '0px', paddingTop: '10px'}}>
                
                <FormControl  component="fieldset">
                  <FormLabel component="legend">Role</FormLabel>
                  <RadioGroup aria-label="role" name="memberRole"   value={memberRole} onChange={this.handleRoleChange}>
                    {this.state.roles.map( (role,index) => (
                      <LightTooltip key={index.toString()} className={classes.hoverText} title={role.role_description} placement="right-start" arrow  >
                        <FormControlLabel classes={{label: classes.radioLabel}} value={role.role_name} control={<Radio color="primary"  className={classes.radioCSS}/>} label={role.role_name} />
                      </LightTooltip>
                    ))}
                  </RadioGroup>
                </FormControl>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              
            </Paper>

          </Dialog>
        
      </Grid>
    );
  }
}

ChangeMemberRoleModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => state

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ChangeMemberRoleModal));

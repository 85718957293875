import React from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import Paper from '@material-ui/core/Paper';
import {getData, postData, putData, deleteData} from '../ApiCalls/DataApis';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import LastPageIcon from '@material-ui/icons/LastPage';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import TableFooter from '@material-ui/core/TableFooter';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';



const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 500,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);

const InvoiceTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 500,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.white,
    },
  },
}))(TableRow);

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '50px',
  },
  tableContainer: {
    borderRadius: '10px',
  },
  table: {
    minWidth: 400,
  },
  paidCSS: {
    borderRadius: '4px',
    backgroundColor: 'rgb(203, 244, 201)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  paidTextCSS:{
    fontSize: '11px', 
    color:'rgb(14, 98, 69)',
  },
  openCSS: {
    borderRadius: '5px',
    backgroundColor: 'rgb(214, 236, 255)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  openTextCSS:{
    fontSize: '11px', 
    color:'rgb(61, 78, 172)',
  },
  voidCSS: {
    borderRadius: '5px',
    backgroundColor: 'rgb(209, 209, 209)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  voidTextCSS:{
    fontSize: '11px', 
    color:'rgb(138, 138, 138)',
  },
  uncollectibleCSS: {
    borderRadius: '5px',
    backgroundColor: 'rgb(255, 248, 214)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  uncollectibleTextCSS:{
    fontSize: '11px', 
    color:'rgb(156, 145, 51)',
  },
  headersCSS: {
    fontSize: '24px',
    fontFamily:'Trebuchet MS',
  }, 
  button: {
    backgroundColor:'transparent',
    borderRadius:'5px',
    border:'1px solid #757575',
    display:'inline-block',
    cursor:'pointer',
    color:'#757575',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'2px 14px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
});

class PastInvoicesPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      organization_invoices: [],
      organization_invoices_dic: [],
      page: 0,
      rowsPerPage: 3,
      selected_invoice: [],
      selected_invoice_line_items_current_month: [],
      selected_invoice_line_items_next_month: [],
      current_billing_start_date: '',
      current_billing_end_date: '',
      next_billing_start_date: '',
      next_billing_end_date: '',

    }
  }

  componentDidMount() {
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/invoice')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(data.data){
            var modifyDate = {};
            for(var i=0; i<Object.keys(data.data).length; i++){
              // convert to milliseconds
              modifyDate[i] = data.data[i].created*1000;
              modifyDate[i] = new Date(modifyDate[i]);
              modifyDate[i] = modifyDate[i].toLocaleDateString() + ', ' + modifyDate[i].toLocaleTimeString([], {timeStyle: 'short'});
              data.data[i].created = modifyDate[i];
            }
            var dic = {};
            for(var i=0; i<Object.keys(data.data).length; i++){
              dic[data.data[i].id]= data.data[i]
            }
            this.setState({
              organization_invoices: data.data,
              organization_invoices_dic: dic,
            });
          }
        }
      });

  }

  // componentDidUpdate(prevProps, prevState) {

    
  // }
  
  handleChangePage = (event, newPage) => {
    this.setState({page: newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  handleRowClick = (event, invoice) => {
    getData('https://api.iotflows.com/v1/billing/invoice/' + invoice.id + '/invoice_line_items')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
            if(data.data){
              var current_month_items = [];
              var next_month_items = [];
              var current_month_date = data.data[0].period.start;
              var current_date_index = 0;
              for(var i=0; i<Object.keys(data.data).length; i++){
                if( current_month_date < data.data[i].period.start){
                  current_month_date = current_month_date;
                }else {
                  current_month_date = data.data[i].period.start;
                  current_date_index = i;
                }
              }

              var next_month_date = data.data[0].period.start;
              var next_date_index = 0;
              for(var i=0; i<Object.keys(data.data).length; i++){
                if( next_month_date > data.data[i].period.start){
                  next_month_date = next_month_date;
                }else {
                  next_month_date = data.data[i].period.start;
                  next_date_index = i;
                }
              }

              this.setState({
                current_billing_start_date: data.data[current_date_index].period.start,
                current_billing_end_date: data.data[current_date_index].period.end,
                next_billing_start_date: data.data[next_date_index].period.start,
                next_billing_end_date: data.data[next_date_index].period.end,
              });

              var current_month_items = [];
              var current_month_items_no_zeros = [];
              var next_month_items = [];
              var next_month_items_no_zeros = [];
              for(var i=0; i<Object.keys(data.data).length; i++){
                if( data.data[i].period.start < next_month_date){
                  current_month_items[i] = data.data[i];
                  if(current_month_items[i].amount){
                    current_month_items_no_zeros[i] = current_month_items[i];
                  }
                }else {
                  next_month_items[i] = data.data[i];
                  if(next_month_items[i].amount){
                    next_month_items_no_zeros[i] = next_month_items[i];
                  }
                }
              }

              this.setState({
                selected_invoice_line_items_current_month: current_month_items_no_zeros,
                selected_invoice_line_items_next_month: next_month_items_no_zeros,
              },() => {
                this.setState({selected_invoice: invoice})
                }
              );

          }
        }
      });
  }


  convertCost(price) {
    if(price){
      var stringvalue = '';
      if(price < 0) {
        stringvalue = '-$' + Math.abs(price/100)
      }  else{
        stringvalue = '$' + price /100
      }  
      return stringvalue;
    }else {
      return '$0';
    }
  }

  convertStringCost(price) {
    if(price){
      var priceToInt = parseInt(price)
      if(priceToInt == 0){
        priceToInt = parseFloat(price)
      }
      priceToInt = priceToInt /100;
      return '$' + priceToInt;
    }
    
  }
  convertStringCostTiered(row) {
    if(row){
      var price;
      var description = row.description
      if(description.includes('$0.00006')){
        price = 0.00006;
      }
      else if(description.includes('$0.00005')){
        price = 0.00005;
      }
      else if(description.includes('$0.00004')){
        price = 0.00004;
      }
      else if(description.includes('$0.00003')){
        price = 0.00003;
      }else {
        return ''
      }
      
      return '$'+ price;
    }
  }

  convertFinalCosts(amount){
    if(amount ){
      var price = this.convertCost(amount)
      return price
    } else {
      return "$0.00"
    }
  }

  dateConverter(date){
    // date.toLocaleDateString() + ', ' + date.toLocaleTimeString();
    if(date != undefined){
      var modifyDate = date*1000;
      modifyDate = new Date(modifyDate);
      return modifyDate.toLocaleDateString([], {  month: 'short', day: 'numeric' });
    } else {
      return " "
    }
  }
  billingCycleDateConverter(start_date, end_date){
    if(start_date != undefined){
      var modifyStartDate = start_date*1000;
      var modifyEndDate = end_date*1000;
      modifyStartDate = new Date(modifyStartDate);
      modifyEndDate = new Date(modifyEndDate);
      modifyStartDate = modifyStartDate.toLocaleDateString([], {  month: 'long', day: 'numeric' });
      modifyEndDate = modifyEndDate.toLocaleDateString([], {  year: 'numeric', month: 'long', day: 'numeric' });
      return (modifyStartDate + ' - ' + modifyEndDate)
    } else {
      return " "
    }
  }

  

  render(){
    const { classes} = this.props
    const {page,
          rowsPerPage,
          organization_invoices,
          selected_invoice,
          selected_invoice_line_items_current_month,
          selected_invoice_line_items_next_month,
          current_billing_start_date,
          current_billing_end_date,
          next_billing_start_date,
          next_billing_end_date,
          organization_invoices_dic} = this.state
       
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, organization_invoices.length - page * rowsPerPage);


    return (
      <div className={classes.root}>
        {/* Customer Invoices */}
        <TableContainer component={Paper} className={classes.tableContainer}>
        <div style={{textAlign: 'left', margin: '5px', marginLeft: '10px'}} >
          <span className={classes.headersCSS} >
            <span><b>Invoices</b> </span>
          </span>
        </div>
        <Table className={classes.table} >
          <colgroup>
            <col style={{width:'10%'}}/>
            <col style={{width:'5%'}}/>
            <col style={{width:'5%'}}/>
            <col style={{width:'20%'}}/>
            <col style={{width:'30%'}}/>
            <col style={{width:'10%'}}/>
            <col style={{width:'20%'}}/>
          </colgroup>
          <TableHead>
            <TableRow>
              <StyledTableCell style={{paddingLeft: '12px'}}>AMOUNT</StyledTableCell>
              <StyledTableCell align="left"> </StyledTableCell>
              <StyledTableCell align="left"> </StyledTableCell>
              <StyledTableCell align="left">INVOICE NUMBER </StyledTableCell>
              <StyledTableCell align="left">DESCRIPTION </StyledTableCell>
              <StyledTableCell align="left">DUE</StyledTableCell>
              <StyledTableCell align="left">CREATED</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? organization_invoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : organization_invoices
            ).map((invoice, id) => (
              <StyledTableRow hover style={{height: '24px',padding: '8px', cursor: 'pointer'}} key={id} onClick={(event) => this.handleRowClick(event, invoice)}>
                <StyledTableCell style={{paddingLeft: '12px'}} component="th" scope="row">
                  {this.convertFinalCosts(invoice.total)}
                </StyledTableCell>
                <StyledTableCell align="left">{invoice.currency}</StyledTableCell>
                <StyledTableCell align="left">
                  {invoice.status == 'paid' ? 
                    <div className={classes.paidCSS} >
                      <span  className={classes.paidTextCSS}>Paid</span>
                    </div>
                  : null}
                  {invoice.status == 'open' ? 
                    <div className={classes.openCSS} >
                      <span  className={classes.openTextCSS}>Open</span>
                    </div>
                  : null}
                  {invoice.status == 'void' ? 
                    <div className={classes.voidCSS} >
                      <span  className={classes.voidTextCSS}>Void</span>
                    </div>
                  : null}
                  {invoice.status == 'draft' ? 
                    <div className={classes.voidCSS} >
                      <span  className={classes.voidTextCSS}>Draft</span>
                    </div>
                  : null}
                  {invoice.status == 'uncollectible' ? 
                    <div className={classes.uncollectibleCSS} >
                      <span  className={classes.uncollectibleTextCSS}>Uncollectible</span>
                    </div>
                  : null}
                </StyledTableCell>
                <StyledTableCell align="left">{invoice.number}</StyledTableCell>
                <StyledTableCell align="left">{invoice.description}</StyledTableCell>
                <StyledTableCell align="left">{this.dateConverter(invoice.due_date)}</StyledTableCell>
                <StyledTableCell align="left">{invoice.created}</StyledTableCell>
              </StyledTableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 40 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[3, 6, 9, 12, { label: 'All', value: -1 }]}
            colSpan={3}
            count={organization_invoices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
        </TableFooter>
        </TableContainer>

        {/* Table that will display the invoice selected details */}
        {Object.keys(selected_invoice).length == 0 ? null :
        <TableContainer component={Paper} className={classes.tableContainer} style={{marginTop: '20px'}}>
          <Table className={classes.table} aria-label="spanning table">
            <colgroup>
              <col style={{width:'50%'}}/>
              <col style={{width:'15%'}}/>
              <col style={{width:'15%'}}/>
              <col style={{width:'20%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                <InvoiceTableCell style={{fontSize: '24px', borderBottom: 'none', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={4}>
                  <b>Summary</b>
                </InvoiceTableCell>
              </TableRow>
              <TableRow>
                <InvoiceTableCell style={{fontSize: '12px', color:'rgb(60, 66, 87)', paddingLeft: '12px'}} align="left" colSpan={2}>
                  Invoice number: {selected_invoice.number}
                </InvoiceTableCell>
                <InvoiceTableCell style={{paddingRight: '12px'}} align="right" colSpan={2}>
                  <Button className={classes.button} onClick={() => window.open(selected_invoice.invoice_pdf, "_blank")}>
                    <ArrowDownwardIcon fontSize='small' /> Invoice PDF
                    </Button>
                </InvoiceTableCell>
              </TableRow>
              <TableRow>
                <InvoiceTableCell style={{paddingLeft: '12px'}}>DESCRIPTION</InvoiceTableCell>
                <InvoiceTableCell align="right">QTY</InvoiceTableCell>
                <InvoiceTableCell align="right">UNIT PRICE</InvoiceTableCell>
                <InvoiceTableCell style={{paddingRight: '12px'}} align="right">AMOUNT</InvoiceTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            <TableRow>
                <InvoiceTableCell style={{fontSize: '12px', color:'rgb(105, 115, 134)', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={4}>
                  {this.billingCycleDateConverter(current_billing_start_date, current_billing_end_date)}
                </InvoiceTableCell>
              </TableRow>
              {selected_invoice_line_items_current_month.map((row) => (
                <TableRow key={row.id} >
                  <InvoiceTableCell style={{paddingLeft: '12px'}}>{row.description}</InvoiceTableCell>
                  <InvoiceTableCell align="right">{row.quantity}</InvoiceTableCell>
                  <InvoiceTableCell align="right">
                    {row.price.product == 'prod_HrbX8g4tbaDC9f' ?
                      this.convertStringCostTiered(row)
                      :
                      this.convertStringCost(row.price.unit_amount_decimal)
                    }
                  </InvoiceTableCell>
                  {/* <InvoiceTableCell align="right">{this.convertStringCost(row.price.unit_amount_decimal)}</InvoiceTableCell> */}
                  <InvoiceTableCell style={{paddingRight: '12px'}} align="right">{this.convertCost(row.amount? row.amount : null)}</InvoiceTableCell>
                </TableRow>
              ))}
              {/* Next month charges */}
              <TableRow>
                <StyledTableCell style={{fontSize: '12px', color:'rgb(105, 115, 134)', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={4}>
                  {this.billingCycleDateConverter(next_billing_start_date, next_billing_end_date)}
                </StyledTableCell>
              </TableRow>
              {selected_invoice_line_items_next_month.map((row) => (
                <TableRow key={row.id}>
                  <StyledTableCell style={{paddingLeft: '12px'}}>{row.description}</StyledTableCell>
                  <StyledTableCell align="right">{row.quantity}</StyledTableCell>
                  <StyledTableCell align="right">{this.convertStringCost(row.price.unit_amount_decimal)}</StyledTableCell>
                  <StyledTableCell style={{paddingRight: '12px'}} style={{paddingRight: '12px'}} align="right">{this.convertCost(row.amount? row.amount : null)}</StyledTableCell>
                </TableRow>
              ))}
              <TableRow>
                <InvoiceTableCell colSpan={2}/>
                <InvoiceTableCell style={{textAlign: 'end'}} >Subtotal</InvoiceTableCell>
                <InvoiceTableCell style={{paddingRight: '12px'}} align="right">{this.convertFinalCosts(selected_invoice.subtotal? selected_invoice.subtotal : null)}</InvoiceTableCell>
              </TableRow>
              {!organization_invoices_dic[selected_invoice.id].discount ? null:
                  <TableRow>
                    <StyledTableCell colSpan={1} />
                    {organization_invoices_dic[selected_invoice.id].discount.coupon.percent_off != null ?
                      <StyledTableCell style={{textAlign: 'end'}} colSpan={2}  style={{color: '#697386', textAlign: 'end'}} >{`${organization_invoices_dic[selected_invoice.id].discount.coupon.name} (%${organization_invoices_dic[selected_invoice.id].discount.coupon.percent_off})`}</StyledTableCell>
                      :
                      <StyledTableCell colSpan={2}  style={{color: '#697386', textAlign: 'end'}} >{`${organization_invoices_dic[selected_invoice.id].discount.coupon.name} (${this.convertCost(organization_invoices_dic[selected_invoice.id].discount.coupon.amount_off)})`}</StyledTableCell>
                    }
                    <StyledTableCell style={{paddingRight: '12px'}} align="right">{this.convertFinalCosts(organization_invoices_dic[selected_invoice.id].total_discount_amounts[0].amount? organization_invoices_dic[selected_invoice.id].total_discount_amounts[0].amount : null)}</StyledTableCell>
                  </TableRow>
              }
              <TableRow>
                <InvoiceTableCell colSpan={2}/>
                <InvoiceTableCell style={{textAlign: 'end'}} >Total</InvoiceTableCell>
                <InvoiceTableCell style={{paddingRight: '12px'}} align="right">{this.convertFinalCosts(selected_invoice.total? selected_invoice.total : null)}</InvoiceTableCell>
              </TableRow>
              <TableRow>
                <InvoiceTableCell colSpan={2}/>
                <InvoiceTableCell style={{textAlign: 'end'}} >Amount due</InvoiceTableCell>
                <InvoiceTableCell style={{paddingRight: '12px'}} align="right">{this.convertFinalCosts(selected_invoice.amount_due? selected_invoice.amount_due: null)}</InvoiceTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        }

        </div>
      )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(PastInvoicesPage));
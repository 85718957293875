import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import {Container, Grid} from '@material-ui/core';
import DataStreamsTable from '../MyDataStreamsPage/DataStreamsTable';
import MembersListPage from '../MyMembersPage/MembersListPage';
import {Redirect, Link} from "react-router-dom";
import MqttService from '../core/services/MqttService';
import {DeviceListColumns} from '../MyDevicesPage/DeviceListColumns'
import {getData} from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
// import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import "../styles/WaitingDataPulse.scss";
import "../styles/HeartbeatLED.scss";
import Box from '@material-ui/core/Box';
import DeviceDataModal from '../MyDevicesPage/DeviceDataModal';
import DeleteDeviceModal from '../components/DeleteDeviceModal';
import AddDeviceModal from '../components/AddDeviceModal';
import EditDeviceModal from '../components/EditDeviceModal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import LastPageIcon from '@material-ui/icons/LastPage';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Tooltip from '@material-ui/core/Tooltip';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Map from '../components/Map';
// import {globalStyles} from "../styles/globalStyles";




const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);


const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        // style={globalStyles.LightDarkTheme}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton 
        // style={globalStyles.LightDarkTheme} 
        onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        // style={globalStyles.LightDarkTheme}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        // style={globalStyles.LightDarkTheme}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: globalStyles.DarkTheme.backgroundColor,
    // color: globalStyles.DarkTheme.color,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 500,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    // backgroundColor: globalStyles.DarkTheme.backgroundColor,
    // color: globalStyles.DarkTheme.color,
    // borderBottom: `1px solid ${globalStyles.ExtraLightDarkTheme.color}`,
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: globalStyles.DarkTheme.backgroundColor,
      backgroundColor: theme.palette.action.white,
    },
  },
}))(TableRow);


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{padding: '24px 0px'}} 
          // style={globalStyles.DarkTheme} 
          p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const ProjectTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
    borderBottom: '0px solid #e8e8e8',
    // backgroundColor: 'transparent',
    outline: 'none',
    borderRadius: '10px',

  },
  indicator: {
    backgroundColor: '#4f79ce',
  },
})(Tabs);

const ProjectTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    minHeight: 30,
    fontWeight: theme.typography.fontWeightRegular,
    // marginRight: theme.spacing(4),
    borderRadius: '10px',
    outline: 'none',
    fontSize: '13px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
    fontFamily: [
      'Trebuchet MS',
    ].join(','),
    '&:hover': {
      color: '#4f86ce',
      opacity: 1,
      outline: 'none',
    },
    '&$selected': {
      color: '#4f79ce',
      fontWeight: theme.typography.fontWeightMedium,
      outline: 'none',
    },
    '&:focus': {
      color: '#4f86ce',
      outline: 'none',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  headerTabs: {
    maxHeight: '40px',
    borderRadius: '5px',
    paddingBottom: '1px',
    backgroundColor:'#e0e0e0',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  AppBarHeaderTabs:{
    maxHeight: '40px',
    borderRadius: '5px',
    paddingBottom: '5px',
    backgroundColor:'#e0e0e0',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  rootDesc: {
    overflow: 'hidden',
    marginTop: theme.spacing(0),
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  headerFont: {
    fontSize: '13px',
    fontFamily:'Trebuchet MS',
    color: '#212121',
    paddingBottom: theme.spacing(5),
    outline: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      backgroundColor:'transparent',
      color: '#212121',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  membersContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // width: '900px',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.spacing(0),  
    fontFamily:'Trebuchet MS', 
  },
  demo: {
    backgroundColor: '#fafafa',
    border: '1px solid #212121',
    borderRadius: '20px',
    borderOutline: '#212121',
    margin: theme.spacing(1, 1, 1),
    height: '100px',
    alignContent: 'center',
    justifyContent: 'center',
  },
  labelContainer: {
    width: "auto",
    padding: 0
  },
  iconLabelWrapper: {
    flexDirection: "row"
  },
  btnContainer: {
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),

  },
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '3px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
  },
  waitingCSS:{
    // padding: '5px',
    position: 'absolute',
    // borderRadius: '10px',
    // backgroundColor: '#fafafa',
    // border:'1px solid #4f86ce',
    zIndex: '2',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    left: 'calc(50% - 148px)',
    top: '15%',
    // textShadow:'1px 1px 1px #9eb9ff',
  },
  addBtnCSS: {
    color: '#2196f3',
    backgroundColor:'transparent',
    fontSize: 'large',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  addBtnCSS1: {
    color: '#2196f3',
    backgroundColor:'transparent',
    fontSize: '40px',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  tableContainer: {
    borderRadius: '10px',
    // boxShadow: '0px 2px 1px -1px rgb(255 255 255 / 20%), 0px 1px 1px 0px rgb(255 255 255 / 14%), 0px 1px 3px 0px rgb(255 255 255 / 12%)',
    // webkitBoxShadow: '0px 2px 1px -1px rgb(255 255 255 / 20%), 0px 1px 1px 0px rgb(255 255 255 / 14%), 0px 1px 3px 0px rgb(255 255 255 / 12%)',
  },
  table: {
    minWidth: 400,
  },
  headersCSS: {
    fontSize: '24px',
    fontFamily:'Trebuchet MS',
  }, 
  buttonProgress: {
    color: 'rgb(33, 117, 243)',
  },
  hoverText: {    
    fontSize: 33,
    paddingLeft: 0,
  },
  E1SubscriptionCSS: {
    width: '85px',
    borderRadius: '5px',
    backgroundColor: 'rgb(232, 235, 192)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  E2SubscriptionCSS: {
    width: '85px',
    borderRadius: '4px',
    backgroundColor: 'rgb(201, 220, 244)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  E3SubscriptionCSS: {
    width: '85px',
    borderRadius: '4px',
    backgroundColor: 'rgb(203, 244, 201)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  messagesNumBg: {
    borderRadius: '4px',
    backgroundColor: "rgba(16, 18, 22, 0.5)",
    height: '26px',
    padding: '3px 2px',
    textAlign: 'center',
  },
  messagesNum: {
    fontSize: '12px', 
    color:'rgba(255,255,255,1)',
    fontWeight: 700,
  },
  freeSubscriptionCSS: {
    width: '85px',
    borderRadius: '5px',
    backgroundColor: 'rgb(219, 219, 219)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  mapContainer: {
    [theme.breakpoints.down('md')]: {
      height: '300px',
      width:'100%'
    },
    [theme.breakpoints.down('xs')]: {
      height: '300px',
      width:'100%'
    },
  },
  cardRootMessagesSize: {
    borderRadius: '8px',
    margin: '0px 4px 4px 0px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('sm')]: {
      margin: '4px 2px 4px 0px',
    },
  },
  cardRootTodays: {
    borderRadius: '8px',
    margin: '0px 4px 4px 4px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('sm')]: {
      margin: '4px 2px 4px 2px',
    },
  },
  cardRootMonths: {
    borderRadius: '8px',
    margin: '0px 0px 4px 4px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('sm')]: {
      margin: '4px 0px 4px 2px',
    },
  },
  cardHeader: {
    fontSize:'20px',
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    // fontFamily:'"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
    textAlign:'center',
    marginTop: '5px',
    marginBottom: '2px',
    paddingBottom: '0px',
  },
  interactionsContainer: {
    display: 'flex',
    flexDirection:'column',
    position: 'absolute',
    top: '52%',
    fontFamily:'Trebuchet MS',
    right: '28%',
    width: '100px',
    textAlign: 'center',
  },
  sizeSum: {
    fontSize: '22px',
    fontWeight: 700,
  },
  interactionsSumTop: {
    fontSize: '24px',
    fontWeight: 700,
  },
  interactionsSumBottom: {
    fontSize:'11px', 
    color:'#9CA3AF',
    fontWeight: 300,
  },
  // selectorIconPagination: {
  //   color: globalStyles.DarkTheme.color,
  // }
});

// Functions and parameters to create tabs 
// function TabContainer({ children, dir }) {
//   return (
//     <Typography component="div" dir={dir} style={{ paddingTop: 4 * 3 }}>
//       {children}
//     </Typography>
//   );
// }

// TabContainer.propTypes = {
//   children: PropTypes.node.isRequired,
//   dir: PropTypes.string.isRequired,
// };

const data_streams = [];

class SelectedProjectPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      isConnected: false,
      message: '',
      toPath:'',
      rows: [],
      value: 0,
      deviceColumns: DeviceListColumns,
      devices_list: [],
      devicesDic: [],
      selected_device: [],
      device_added: false,
      device_edited: false,
      device_deleted: false,
      organization_data_streams_dic: [],
      organization_clients_dic: [],
      openDeviceDataModal: false,
      deviceDataRow: [],
      selectedDataRow: [],
      todays_dic:[],
      months_dic:[],
      deviceTodaysData: [],
      deviceMonthsData: [],
      todaysTotalSumInteger: 0,
      monthsTotalSumInteger: 0,
      todaysTotalSumSummary: 0,
      monthsTotalSumSummary: 0,
      totalSizeSummary: '',
      columns: [
        { title: 'Project Name', 
          field: 'project_name',         
        },
        { title: 'Privacy',
          field: 'project_is_private',
          lookup: { true: 'Private', false: 'Public' }, 
        },
        { title: 'Project Description', field: 'project_description' },
        { title: 'Project Role', field: 'project_member_role_name', editable:'never'},
      ],
      projects_list: [],
      interactionOptions: {
        credits: {enabled: false},
        title: {
          text: 'Published Messages',
          margin: 0,
          style: {
            fontSize: '22px',
            fontWeight: '900',
            fontFamily:'Trebuchet MS',
          }
        },
        chart: {
          height: '225px',
        },
        scrollbar: {
          enabled: false
        },
        time: {
          timezoneOffset: new Date().getTimezoneOffset()
        },
        navigator: {
          height: 15,
          outlineWidth: 0,
          outlineColor: '#ffffff',
          xAxis: {
            gridLineWidth: 0,
            gridLineColor: '#ffffff',
          },
          yAxis: {
            gridLineWidth: 0,
            gridLineColor: '#ffffff',
          },
          series: {
              lineWidth: 0.5,
          }
        },
        rangeSelector:{
          allButtonsEnabled: true,
          buttons: [
            {
            type: 'day',
            count: 1,
            text: '1d'
          }, {
            type: 'day',
            count: 7,
            text: '7d'
          }, {
            type: 'day',
            count: 14,
            text: '14d'
          },{
            type: 'month',
            count: 1,
            text: '1m'
          },{
            type: 'all',
            text: 'All'
          }],
          selected: 4
        },
        plotOptions: {
          area: {
              marker: {
                  radius: 2
              },
              lineWidth: 1,
              states: {
                  hover: {
                      lineWidth: 1
                  }
              },
              threshold: null
          }
        },
        series: [{
          type: 'area',
          // color: '#4caf50',
          name: 'Interactions',
          data: [[Date().toLocaleString(),1],[Date().toLocaleString(),1],[Date().toLocaleString(),1],[Date().toLocaleString(),1]], 
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1
            },
            stops: [
              [0, 'rgba(1,31,75,0.8)'],
              [1, 'rgba(1,31,75,0.3)']
              // [0, 'rgba(1,31,75,0.8)'],
              // [1, 'rgba(3,57,108,0.4)']
            ]
          },
          navigator: {
            series: {
                color: '#4caf50',
            }
          },
        }]
      },
      pieChartSizeOptions: {
          chart: {
            // renderTo: 'container',
            backgroundColor: null,
            height: 140,
            // width: 125,
          },
          colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
          // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
          credits: {enabled: false},
          title: {
            text: "",
            verticalAlign: 'middle',
            style: { 
              color:'#000',
              fontSize: '22px',
            } 
          },
          exporting: {
            enabled: false
          },
          series: [{
            type: 'pie',
            name: 'Size',
            innerSize: '80%',
            data: [{
                name: '0 Bytes',
                y: 1,
                // sliced: true,
                // selected: true
            }]
          }],
          plotOptions: {
            // series: {
            //   animation: false
            // },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size: 115,
                dataLabels: {
                    enabled: false
                },
                borderWidth: 0,
                margin: 0,
            }
          },
          legend: {
            itemDistance:0,
          },
          tooltip: {
            pointFormat: '{series.name}: 0'
          }
        },
      pieChartTodaysOptions: {
          chart: {
            // renderTo: 'container',
            backgroundColor: null,
            height: 140,
            // width: 125,
          },
          colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
          // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
          credits: {enabled: false},
          title: {
            text: "",
            verticalAlign: 'middle',
            style: { 
              color:'#000',
              fontSize: '22px',
            } 
          },
          exporting: {
            enabled: false
          },
          series: [{
            type: 'pie',
            name: 'Messages',
            innerSize: '80%',
            data: [{
                name: 'No Messages Published',
                y: 1,
                // sliced: true,
                // selected: true
            }]
          }],
          plotOptions: {
            // series: {
            //   animation: false
            // },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size: 115,
                dataLabels: {
                    enabled: false
                },
                borderWidth: 0,
                margin: 0,
            }
        },
          legend: {
            itemDistance:0,
          }
        },
      pieChartMonthsOptions: {
          chart: {
            // renderTo: 'container',
            backgroundColor: null,
            height: 140,
            // width: 125,
          },
          colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
          // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
          credits: {enabled: false},
          title: {
            text: "",
            verticalAlign: 'middle',
            style: { 
              color:'#000',
              fontSize: '22px',
            } 
          },
          exporting: {
            enabled: false
          },
          series: [{
            type: 'pie',
            name: 'Messages',
            innerSize: '80%',
            data: [{
                name: 'No Messages Published',
                y: 1,
                // sliced: true,
                // selected: true
            }]
          }],
          plotOptions: {
            // series: {
            //   animation: false
            // },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size: 115,
                dataLabels: {
                    enabled: false
                },
                borderWidth: 0,
                margin: 0,
            }
        },
          legend: {
            itemDistance:0,
          }
        },
        user: [],
        intervalId: '',
    };
    this.refreshDeviceList = this.refreshDeviceList.bind(this);
    this.handleCloseDeviceDataModal = this.handleCloseDeviceDataModal.bind(this)

  }

  handleChange = (event, value) => {
    this.setState({ value });
  };
  
  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  // MQTT functions below
  componentDidMount() {


    // getData('https://api.iotflows.com/v1/users/authorize')
    // .then(async response => {
    //   if(response.ok ){
    //     const data = await response.json();
    //     if(data.data){
    //       this.setState(
    //         {user: data.data[0]}, () => this.readData()
    //       )
    //     }
        
    //   }
    // });

    getData('https://api.iotflows.com/v1/projects/' + this.props.match.params.project_uuid + '/historical_data')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          let modifyDate = {};
          let dataSize = {};
          for(var i=0; i<Object.keys(data.data).length; i++){
            // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
            modifyDate[i] = new Date(data.data[i].received_at);
            modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
            data.data[i].received_at = modifyDate[i];

            // get data size
            dataSize[i] = Object.keys(data.data[i].data).length;
            dataSize[i] = this.formatBytes(dataSize[i]);
            data.data[i].data_stream_size = dataSize[i];
          }

          this.setState({
            rows: data.data,
          })
        }
      } else{
        try{
          const data = await response.json();
          this.props.showErrorSnackbar(data.message);
          
        }catch (e){
          this.props.showErrorSnackbar('Something went wrong');
        }
      }
    });

    // Get today project interactions
    getData('https://api.iotflows.com/v1/projects/' + this.props.match.params.project_uuid +'/devices_interaction_count/today')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(Object.keys(data.data).length > 0){
          var intervalId = setInterval(this.timer, 60000);
          // store intervalId in the state so it can be accessed later:
          this.setState({intervalId: intervalId});

            // var dic = {};
            // for(var i=0; i<Object.keys(data.data).length; i++){
            //   dic[data.data[i].device_uuid]= data.data[i]
            // }
            // this.setState({
            //   todays_dic: dic,
            // })

          var todaysData = data.data.map(result =>({
              name: result.device_name,
              y: parseInt(result.count),
              device_uuid: result.device_uuid
          }))
          var todaysSum = 0
          for(var i = 0; i < Object.keys(todaysData).length; i++) {
            todaysSum = todaysSum + todaysData[i].y
          }
          var todaysSumInteger = todaysSum;
          if(todaysSumInteger >= 1000000){
            todaysSum = (Number.parseFloat(todaysSumInteger)/1000000.00).toFixed(1)
            todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
            todaysSum = todaysSum+"M"
          }
          else if( todaysSumInteger >= 100000  ){
            todaysSum = (Number.parseFloat(todaysSumInteger)/1000.00).toFixed(1)
            todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
            todaysSum = todaysSum+"k"
          }
          else{
            todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
          }

          this.setState({
            todaysTotalSumInteger: todaysSumInteger,
            todaysTotalSumSummary: todaysSum,
            deviceTodaysData: todaysData,
          })

          this.setState({
            pieChartTodaysOptions: {
              chart: {
                // renderTo: 'container',
                backgroundColor: null,
                height: 140,
                // width: 125,
              },
              colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
              // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
              credits: {enabled: false},
              title: {
                text: "",
                // text: todaysSum,
                verticalAlign: 'middle',
                style: { 
                  color:'#000',
                  fontSize: '22px',
                } 
              },
              exporting: {
                enabled: false
              },
              series: [{
                type: 'pie',
                name: 'Messages',
                innerSize: '80%',
                data: todaysData
              }],
              plotOptions: {
                series: {
                  animation: false
                },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: 115,
                    dataLabels: {
                        enabled: false
                    },
                    borderWidth: 0,
                    margin: 0,
                }
            },
              legend: {
                itemDistance:0,
              }
            },
          })
        }
      }
    });

    // Get months project interactions
    getData('https://api.iotflows.com/v1/projects/' + this.props.match.params.project_uuid +'/devices_interaction_count/this_month')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(Object.keys(data.data).length > 0){
          
          var dic = {};
            for(var i=0; i<Object.keys(data.data).length; i++){
              dic[data.data[i].device_uuid]= data.data[i]
            }
            this.setState({
              months_dic: dic,
            })

          var monthsData = data.data.map(result =>({
              name: result.device_name,
              y: parseInt(result.count),
              device_uuid: result.device_uuid
          }))


          var monthsSum = 0
          for(var i = 0; i < Object.keys(monthsData).length; i++) {
            monthsSum = monthsSum + monthsData[i].y
          }

          var monthsSumInteger = monthsSum;
          if(monthsSum > 1000000){
            monthsSum = (Number.parseFloat(monthsSum)/1000000.00).toFixed(2)
            monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
            monthsSum = monthsSum+"M"
          }
          else if( monthsSum > 100000 ){
            monthsSum = (Number.parseFloat(monthsSum)/1000.00).toFixed(1)
            monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
            monthsSum = monthsSum+"k"
          }
          else {
            monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
          }
          

          this.setState({
            monthsTotalSumInteger: monthsSumInteger,
            monthsTotalSumSummary: monthsSum,
            deviceMonthsData: monthsData,
          })



          this.setState({
            pieChartMonthsOptions: {
              chart: {
                // renderTo: 'container',
                backgroundColor: null,
                height: 140,
                // width: 125,
              },
              colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
              // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
              // colors: ['#0e2139', '#153255', '#1c4272', '#23538f', '#2a64ab', '#3175c8', '#4b87d2', '#679ad9', '#84ade0', '#a0c0e7'],
              // colors: ['#172330', '#223448', '#2e4660', '#395778', '#456990', '#517aa8', '#678cb6', '#7f9ec1', '#97b1cd', '#afc3d8'],
              credits: {enabled: false},
              title: {
                text: "",
                // text: monthsSum,
                verticalAlign: 'middle',
                style: { 
                  color:'#000',
                  fontSize: '22px',
                } 
              },
              exporting: {
                enabled: false
              },
              series: [{
                type: 'pie',
                name: 'Messages',
                innerSize: '80%',
                data: monthsData
              }],
              plotOptions: {
                series: {
                  animation: false
                },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: 115,
                    dataLabels: {
                        enabled: false
                    },
                    borderWidth: 0,
                    margin: 0,
                }
            },
              legend: {
                itemDistance:0,
              }
            },
          })
        }
          
        
      }
    });

    // Historical interactions charts
    getData('https://api.iotflows.com/v1/projects/' + this.props.match.params.project_uuid + '/interaction_count/historical/highcharts')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(Object.keys(JSON.parse(data.data)).length > 0 ){
            this.setState({
                interactionOptions: {
                  ...this.state.interactionOptions,
                  series: 
                    [{...this.state.interactionOptions.series[0],
                      data: JSON.parse(data.data)}],
                }}, () => {
                  this.setState({
                    interactionOptions: {
                      credits: {enabled: false},
                      title: {
                        text: 'Published Messages',
                        margin: 0,
                        style: {
                          fontSize: '22px',
                          fontWeight: '900',
                          fontFamily:'Trebuchet MS',
                        }
                      },
                      chart: {
                        height: '225px',
                      },
                      scrollbar: {
                        enabled: false
                      },
                      time: {
                        timezoneOffset: new Date().getTimezoneOffset()
                      },
                      navigator: {
                        height: 15,
                        outlineWidth: 0,
                        outlineColor: '#ffffff',
                        xAxis: {
                          gridLineWidth: 0,
                          gridLineColor: '#ffffff',
                        },
                        yAxis: {
                          gridLineWidth: 0,
                          gridLineColor: '#ffffff',
                        },
                        series: {
                            lineWidth: 0.5,
                        }
                      },
                      rangeSelector:{
                        allButtonsEnabled: true,
                        buttons: [
                          {
                          type: 'day',
                          count: 1,
                          text: '1d'
                        }, {
                          type: 'day',
                          count: 7,
                          text: '7d'
                        }, {
                          type: 'day',
                          count: 14,
                          text: '14d'
                        },{
                          type: 'month',
                          count: 1,
                          text: '1m'
                        },{
                          type: 'all',
                          text: 'All'
                        }],
                        selected: 4
                      },
                      plotOptions: {
                        area: {
                            marker: {
                                radius: 2
                            },
                            lineWidth: 1,
                            states: {
                                hover: {
                                    lineWidth: 1
                                }
                            },
                            threshold: null
                        }
                      },
                      series: [{
                        type: 'area',
                        // color: '#4caf50',
                        name: 'Interactions',
                        data: JSON.parse(data.data), 
                        color: {
                          linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                          },
                          stops: [
                            [0, 'rgba(1,31,75,0.8)'],
                            [1, 'rgba(1,31,75,0.3)']
                            // [0, 'rgba(1,31,75,0.8)'],
                            // [1, 'rgba(3,57,108,0.4)']
                          ]
                        },
                        navigator: {
                          series: {
                              color: '#4caf50',
                          }
                        },
                      }]
                    }
                  })
                }
            );
          }
        }
      });

    // Get data messages sizes
    getData('https://api.iotflows.com/v1/projects/' + this.props.match.params.project_uuid +'/devices_interaction_size')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        
        if(Object.keys(data.data).length > 0){

          var dataSizes = data.data.map(result =>({
              name: result.device_name,
              y: parseInt(result.size),
              prettyY: this.formatBytes(result.size),
              device_uuid: result.device_uuid
          }))

          let sizeSum = 0
          for(var i = 0; i < Object.keys(dataSizes).length; i++) {
            sizeSum = sizeSum + dataSizes[i].y
          }

          var formattedSize = this.formatBytes(sizeSum)

          this.setState({
            totalSizeSummary: formattedSize,
          })

          this.setState(
            {
              pieChartSizeOptions: {
                ...this.state.pieChartSizeOptions,
                series: 
                  [{...this.state.pieChartSizeOptions.series[0],
                    data: dataSizes}],
              }}, () => {
                this.setState({
                  pieChartSizeOptions: {
                    chart: {
                      // renderTo: 'container',
                      backgroundColor: null,
                      height: 140,
                      // width: 125,
                    },
                    colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
                    // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
                    credits: {enabled: false},
                    title: {
                      text: "",
                      verticalAlign: 'middle',
                      style: { 
                        color:'#000',
                        fontSize: '22px',
                      } 
                    },
                    exporting: {
                      enabled: false
                    },
                    series: [{
                      type: 'pie',
                      name: 'Message storage',
                      innerSize: '80%',
                      data: dataSizes
                    }],
                    plotOptions: {
                      // series: {
                      //   animation: false
                      // },
                      pie: {
                          allowPointSelect: true,
                          cursor: 'pointer',
                          size: 115,
                          dataLabels: {
                              enabled: false
                          },
                          borderWidth: 0,
                          margin: 0,
                      }
                    },
                    legend: {
                      itemDistance:0,
                    },
                    tooltip: {
                      pointFormat: '{series.name}: {point.prettyY}'
                    }
                  },
                })
          })
        }
      }
    });

    // Get function to receive project device list
    getData('https://api.iotflows.com/v1/projects/' + this.props.match.params.project_uuid + '/devices')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          var dic = {};
          for(var i=0; i<Object.keys(data.data).length; i++){
            if (data.data[i].device_last_heard){
              let localDateTime = new Date(data.data[i].device_last_heard);
              localDateTime = localDateTime.toLocaleTimeString()+' '+localDateTime.toLocaleDateString();

              data.data[i].device_last_heard = localDateTime;
              dic[data.data[i].device_uuid]= data.data[i];
            } else {
              data.data[i].device_last_heard = '';
              dic[data.data[i].device_uuid]= data.data[i];
            }
          }
          this.setState({
            devices_list: data.data,
            devicesDic: dic,
            },this.subscribedDevicesMQTT
          )
        }
      }
    });

    // Getting the list of all the device types
    this.state.deviceColumns.map(column => {
      if(column.field == 'device_type'){
        // Get function to receive project device list
        getData('https://api.iotflows.com/v1/devices/types')
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            var lookup = {};
            const result = data.data
            result.map( device_type => {
              lookup[device_type.device_type_name] = device_type.device_type_name;
            })
            column.lookup = lookup
          }    
        });
      }
    })
    
    // MQTT for data streams functions
    for (let i = 0; i < Object.keys(data_streams).length; i++) {
      // data_streams[i].severity_level_icon = alerts_icon[data_streams[i].severity_level]
        this.state.rows.push(data_streams[i]);
      }

    if(MqttService.isConnected) {
      MqttService.subscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/' + this.props.match.params.project_uuid + '/devices/+/data-streams/#', this.onDataStreamProject)
      MqttService.subscribe('v1/api/users/' + this.props.currentUserInfo[0].user_username + '/notifications', this.onDeviceNotifications)
    }

    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/data_streams')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          var dic = {};
          if(data.data){
            for(var i=0; i<Object.keys(data.data).length; i++){
              dic[data.data[i].data_stream_uuid]= data.data[i]
            }
            this.setState({
              organization_data_streams_dic: dic,
            })
          }
        }
      });

      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/devices/clients')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          var dic = {};
          if(data.data){
            for(var i=0; i<Object.keys(data.data).length; i++){
              dic[data.data[i].client_id]= data.data[i]
            }
            this.setState({
              organization_clients_dic: dic,
            })
          }
        }
      });
  }

  formatBytes = (bytes, decimals = 1) => {
    if (bytes == 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
  }

  refreshDeviceList() {
    // Get function to receive project device list
    getData('https://api.iotflows.com/v1/projects/' + this.props.match.params.project_uuid + '/devices')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        var dic = {};
        if(data.data){
          for(var i=0; i<Object.keys(data.data).length; i++){
            if (data.data[i].device_last_heard){
              let localDateTime = new Date(data.data[i].device_last_heard);
              localDateTime = localDateTime.toLocaleTimeString()+' '+localDateTime.toLocaleDateString();

              data.data[i].device_last_heard = localDateTime;
              dic[data.data[i].device_uuid]= data.data[i];
            } else {
              data.data[i].device_last_heard = '';
              dic[data.data[i].device_uuid]= data.data[i];
            }
          }
          this.setState({
            devices_list: data.data,
            devicesDic: dic,
            },this.subscribedDevicesMQTT
          )
        }
      }
    });
  }

  timer = () => {
    // Get today project interactions
    getData('https://api.iotflows.com/v1/projects/' + this.props.match.params.project_uuid +'/devices_interaction_count/today')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(Object.keys(data.data).length > 0){

          var todaysData = data.data.map(result =>({
              name: result.device_name,
              y: parseInt(result.count),
              device_uuid: result.device_uuid
          }))

          this.setState({
            pieChartTodaysOptions: {
              chart: {
                // renderTo: 'container',
                backgroundColor: null,
                height: 140,
                // width: 125,
              },
              colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
              // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
              credits: {enabled: false},
              title: {
                text: "",
                // text: todaysSum,
                verticalAlign: 'middle',
                style: { 
                  color:'#000',
                  fontSize: '22px',
                } 
              },
              exporting: {
                enabled: false
              },
              series: [{
                type: 'pie',
                name: 'Messages',
                innerSize: '80%',
                data: todaysData
              }],
              plotOptions: {
                series: {
                  animation: false
                },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: 115,
                    dataLabels: {
                        enabled: false
                    },
                    borderWidth: 0,
                    margin: 0,
                }
            },
              legend: {
                itemDistance:0,
              }
            },
          })
        }
      }
    });

    // Get months project interactions
    getData('https://api.iotflows.com/v1/projects/' + this.props.match.params.project_uuid +'/devices_interaction_count/this_month')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(Object.keys(data.data).length > 0){

          var monthsData = data.data.map(result =>({
              name: result.device_name,
              y: parseInt(result.count),
              device_uuid: result.device_uuid
          }))
          this.setState({
            pieChartMonthsOptions: {
              chart: {
                backgroundColor: null,
                height: 140,
              },
              colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
              credits: {enabled: false},
              title: {
                text: "",
                verticalAlign: 'middle',
                style: { 
                  color:'#000',
                  fontSize: '22px',
                } 
              },
              exporting: {
                enabled: false
              },
              series: [{
                type: 'pie',
                name: 'Messages',
                innerSize: '80%',
                data: monthsData
              }],
              plotOptions: {
                series: {
                  animation: false
                },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: 115,
                    dataLabels: {
                        enabled: false
                    },
                    borderWidth: 0,
                    margin: 0,
                }
            },
              legend: {
                itemDistance:0,
              }
            },
          })
        }
          
        
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    if(MqttService.isConnected) {
      MqttService.unsubscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/' + this.props.match.params.project_uuid + '/devices/+/data-streams/#')
      MqttService.unsubscribe('v1/api/users/' + this.props.currentUserInfo[0].user_username + '/notifications')
      for (let i=0; i<Object.keys(this.state.devices_list).length; i++){
        MqttService.unsubscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/clients/' + this.state.devices_list[i].device_uuid + '/status/#')
      }
    }
    
  }

  

  // async readData() {
  //   console.log("Reached READ DATA")
  //   const {loadIoTFlows} = require('@iotflows/iotflows-js')

  //   if(Object.keys(this.state.user).length > 0) {
  //     console.log("Inside loadIoTFlows")
  //     var iotflows = await loadIoTFlows(this.state.user.client_username, this.state.user.client_password)

  //   iotflows.subscribeMQTT(
  //     'v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/' + this.props.match.params.project_uuid + '/devices/+/data-streams/#',
  //       function handler(topic, payload){
  //           console.log("New message received!")        
  //           console.log(topic)
  //           console.log(payload)
  //       }
  //   )
  //   }
  // }

  onDeviceStatus = message => {
    // updating state of device online and last heard
    try{
      const updatedDeviceState = JSON.parse(message.payloadString);
      var currentDeviceUuid = updatedDeviceState.username;
      var deviceOnlineStatus = updatedDeviceState.is_online;
  
      let localDateTime = new Date(parseInt(updatedDeviceState.timestamp));
      localDateTime = localDateTime.toLocaleTimeString()+' '+localDateTime.toLocaleDateString();

      var device_dic = this.state.devicesDic
      device_dic[currentDeviceUuid].device_is_online = deviceOnlineStatus;
      device_dic[currentDeviceUuid].device_last_heard = localDateTime;

      this.setState({
        devicesDic: device_dic,
      });
    }
    catch(e){
      console.log("Error in reading heartbeat from MQTT");
      console.log(e.message);
    }
  }
  
  onDeviceNotifications = message => {
    try {
      const notification_message = JSON.parse(message.payloadString);

      if(notification_message.code == 200 && notification_message.service == 'virtual-devices'){
        this.props.showSuccessSnackbar(notification_message.message);
        this.refreshDeviceList();
      }
      
    }
    catch(e){
      console.log("Error in reading data stream in project from MQTT");
      console.log(e.message);
    }
  }


  onDataStreamProject = message => {
    // console.log('message')
    // console.log(message)
    // const data_stream_message = JSON.parse(message.payloadString);
    // var rows = this.state.rows;
    // // alert_message.severity_level_icon = alerts_icon[alert_message.severity_level]
    // rows.push(data_stream_message)
    // this.setState({rows:rows})
    try{
      const data_stream_message = JSON.parse(message.payloadString);
      const data_topic = message.topic;
      const splitData = data_topic.split("/")
      const indexOfDevice = splitData.indexOf('devices')
      // if(indexOfDevice != -1){
      //   const deviceUuidPublished = splitData[indexOfDevice + 1];

      //   for(let i=0; i<Object.keys(this.state.deviceTodaysData).length; i++) { 
      //     if(deviceUuidPublished == this.state.deviceTodaysData[i].device_uuid){
      //        let data_today = this.state.deviceTodaysData;
      //        data_today[i].y = data_today[i].y + 1;
 
      //        this.setState({
      //          deviceTodaysData: data_today,
      //        })
 
      //        this.setState({pieChartTodaysOptions: {
      //          ...this.state.pieChartTodaysOptions,
      //          series: 
      //            [{...this.state.pieChartTodaysOptions.series[0],
      //              data: data_today}],
      //          },
      //        })
      //     }
      //   }
      //   for(let i=0; i<Object.keys(this.state.deviceMonthsData).length; i++) { 
      //     if(deviceUuidPublished == this.state.deviceMonthsData[i].device_uuid){
      //        let data_month = this.state.deviceMonthsData;
      //        data_month[i].y = data_month[i].y + 1;
 
      //        this.setState({
      //          deviceMonthsData: data_month,
      //        })
 
      //        this.setState({pieChartMonthsOptions: {
      //          ...this.state.pieChartMonthsOptions,
      //          series: 
      //            [{...this.state.pieChartMonthsOptions.series[0],
      //              data: data_month}],
      //          },
      //        })
      //     }
      //   }
      // }
       

      // adding to the todays messages in project list
      var currentTodaysSumInt = this.state.todaysTotalSumInteger + 1;
      var currentTodaysSumSummary = currentTodaysSumInt;
      if(currentTodaysSumInt >= 1000000){
        currentTodaysSumSummary = (Number.parseFloat(currentTodaysSumInt)/1000000.00).toFixed(1)
        currentTodaysSumSummary = currentTodaysSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
        currentTodaysSumSummary = currentTodaysSumSummary+"M"
      }
      else if( currentTodaysSumInt >= 100000  ){
        currentTodaysSumSummary = (Number.parseFloat(currentTodaysSumInt)/1000.00).toFixed(1)
        currentTodaysSumSummary = currentTodaysSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
        currentTodaysSumSummary = currentTodaysSumSummary+"k"
      }
      else{
        currentTodaysSumSummary = currentTodaysSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
      }
      this.setState({
        todaysTotalSumInteger: currentTodaysSumInt,
        todaysTotalSumSummary: currentTodaysSumSummary,
      })



      // adding to the months messages in project list
      var currentMonthsSumInt = this.state.monthsTotalSumInteger + 1;
      var currentMonthsSumSummary = currentMonthsSumInt;
      if(currentMonthsSumInt >= 1000000){
        currentMonthsSumSummary = (Number.parseFloat(currentMonthsSumInt)/1000000.00).toFixed(2)
        currentMonthsSumSummary = currentMonthsSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
        currentMonthsSumSummary = currentMonthsSumSummary+"M"
      }
      else if( currentMonthsSumInt >= 100000 ){
        currentMonthsSumSummary = (Number.parseFloat(currentMonthsSumInt)/1000.00).toFixed(1)
        currentMonthsSumSummary = currentMonthsSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
        currentMonthsSumSummary = currentMonthsSumSummary+"k"
      }
      else {
        currentMonthsSumSummary = currentMonthsSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
      }

      this.setState({
        monthsTotalSumInteger: currentMonthsSumInt,
        monthsTotalSumSummary: currentMonthsSumSummary 
      })


      var data_stream_uuid = data_stream_message.data_stream_id;
      var client_id = data_stream_message.client_id;
      var data = String(data_stream_message.data);
      // var topicSliced = message.topic.split('/');
      // var dataStreamUuid = topicSliced[topicSliced.indexOf('data-streams')+1];
  
      let data_stream_name = this.state.organization_data_streams_dic[data_stream_uuid].data_stream_name;
      let data_stream_unit = this.state.organization_data_streams_dic[data_stream_uuid].data_stream_unit;
      let client_name = this.state.organization_clients_dic[client_id].client_name;
      let device_name = this.state.organization_clients_dic[client_id].device_name;
      // let project_name = this.state.organization_clients_dic[client_id].project_name;
  
      var rows = this.state.rows;
      data_stream_message.client_name = client_name;
      data_stream_message.data_stream_name = data_stream_name;
      data_stream_message.data = data;
      data_stream_message.data_stream_unit = data_stream_unit;
      data_stream_message.device_name = device_name;
      // data_stream_message.project_name = project_name;
      data_stream_message.received_at = (new Date()).toLocaleTimeString() + " " + (new Date()).toLocaleDateString();

      // get data stream size
      let dataSize = 0; 
      dataSize = Object.keys(data_stream_message.data).length;
      dataSize = this.formatBytes(dataSize);
      data_stream_message.data_stream_size = dataSize;
  
      rows.unshift(data_stream_message);

      this.setState({rows:rows.slice(0,100)})
    }
    catch(e){
      console.log("Error in reading data stream in project from MQTT");
      console.log(e.message);
    }
    
    
  }

  // callback function to ensure all device are listed
  subscribedDevicesMQTT(){
    if(MqttService.isConnected) {
      for (let i=0; i<Object.keys(this.state.devices_list).length; i++){
        MqttService.subscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/clients/' + this.state.devices_list[i].device_uuid + '/status/#', this.onDeviceStatus)
      }
    }
  }

  handleCloseDeviceDataModal = () => {
    this.setState({
      openDeviceDataModal: false,
      deviceDataRow: [],
      selectedDataRow: [],
      })
  }

  handleChangePage = (event, newPage) => {
    this.setState({page: newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  handleRowClick = (e, device) => {
    this.setState({selected_device: device})
    this.setState({toPath: `/devices/${device.device_uuid}`})
    this.setState({redirect: true})
  }

  numberAdjust = (number) => {
    if(number > 1000000){
      number = (Number.parseFloat(number)/1000000.00).toFixed(1)+"M"
    }
    else if( number > 1000 ){
      number = (Number.parseFloat(number)/1000.00).toFixed(1)+"k"
    }
    return number
  }

  render(){
    const { classes, theme, selectedOrganization} = this.props
    const { deviceColumns, 
            devices_list, 
            value, 
            rows,
            openDeviceDataModal,
            deviceDataRow,
            selectedDataRow,
            page,
            rowsPerPage,
            devicesDic,
            pieChartTodaysOptions,
            pieChartMonthsOptions,
            pieChartSizeOptions,
            interactionOptions,
            todaysTotalSumSummary,
            monthsTotalSumSummary,
            todaysTotalSumInteger,
            monthsTotalSumInteger,
            totalSizeSummary} = this.state
    const current_project_uuid = this.props.match.params.project_uuid;
    const selected_project_name = this.props.location.state.selected_project_name;

    
    // if (this.state.redirect) {
    //   return <Redirect push to={this.state.toPath} />
    // }
    if (this.state.redirect) {
      return <Redirect push to={{pathname: this.state.toPath, state: {selected_device: this.state.selected_device} }} />
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, devices_list.length - page * rowsPerPage);

    
    
    


    return (
      <div style={{marginBottom: '60px'}}>
        <div className={classes.breadcrumbCSS}>
          <Breadcrumbs  
            // style={{color: globalStyles.LightDarkTheme.color}} 
            separator="›" aria-label="breadcrumb">
            {/* <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/" >
              {this.props.selectedOrganization.organization_name}
            </Link> */}
            <Typography className={classes.fontStyles} style={{color:'#757575'}}>{selectedOrganization.organization_name}</Typography>
            <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/projects" >
              Projects
            </Link>
            <Typography className={classes.fontStyles} 
              // style={{color: globalStyles.DarkTheme.color}}
              >{selected_project_name}</Typography>
          </Breadcrumbs>
        </div>
        

      <Paper className={classes.rootDesc} elevation={0}>
        <AppBar position="static" color="default" style={{borderRadius: '10px'}} 
        // style={globalStyles.IoTFlowsColorDark} 
        >
          <ProjectTabs 
            value={value}
            onChange={this.handleChange}
            variant="fullWidth"
            // className={classes.headerTabs}
            // value={value}
            // onChange={this.handleChange}
            // variant="fullWidth"
            // indicatorColor="primary"
            // // textColor="primary"
            // aria-label="selected project tabs"
            
          >
            <ProjectTab  classes={{wrapper: classes.iconLabelWrapper,labelContainer: classes.labelContainer}} {...a11yProps(0)} icon={<DeviceHubIcon  />} label="&nbsp;DEVICES" />
            <ProjectTab  classes={{wrapper: classes.iconLabelWrapper,labelContainer: classes.labelContainer}} {...a11yProps(1)} icon={<PeopleOutlineIcon />} label="&nbsp;MEMBERS" />
            {/* <ProjectTab  classes={{wrapper: classes.iconLabelWrapper,labelContainer: classes.labelContainer}} {...a11yProps(2)} icon={<BlurOnIcon  />} label="&nbsp;DATA STREAMS" /> */}
          </ProjectTabs>
        </AppBar>

        <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={this.handleChangeIndex}
           
          >
            {/* <TabContainer  dir={theme.direction}> */}
            <TabPanel value={value} index={0} dir={theme.direction}>
              

              {/* Project Devices */}
              <TableContainer component={Paper} className={classes.tableContainer} >
              <Table className={classes.table} 
                // style={globalStyles.DarkTheme} 
                >
                <colgroup>
                  <col style={{width:'3%'}}/>
                  <col style={{width:'25%'}}/>
                  <col style={{width:'30%'}}/>
                  {/* <col style={{width:'9%'}}/>
                  <col style={{width:'9%'}}/> */}
                  <col style={{width:'10%'}}/>
                  <col style={{width:'26%'}}/>
                  <col style={{width:'3%'}}/>
                  <col style={{width:'3%'}}/>
                </colgroup>
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{fontSize: '24px', borderBottom: 'none', paddingLeft: '12px', paddingRight: '12px',paddingBottom:'0px'}} align="left" colSpan={3}>
                      <b>Devices</b>
                    </StyledTableCell>
                    <StyledTableCell style={{paddingRight: '12px', borderBottom: 'none',paddingBottom:'0px'}} align="right" colSpan={4}>
                      <AddDeviceModal current_project_uuid={current_project_uuid} refreshDeviceList={this.refreshDeviceList} />
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={9} style={{borderBottom: 'none', padding:'8px 4px 0px 4px', }}>
                    {Object.keys(devices_list).length > 0?
                      <Grid container spacing={1} >
                        <Grid item sm={12} md={12} lg={6} className={classes.mapContainer}>
                          <div style={{display: 'inline-flex', width:'100%', height:'100%', padding: '1px 0px 0px',borderRadius: '8px',overflow:'hidden'}}>
                            <Map devices_list={devices_list} devicesDic={devicesDic} />
                          </div>
                        </Grid>
                        <Grid container item sm={12} md={12} lg={6}>
                          <Grid item xs={12} sm={4} md={4} >
                            <Card className={classes.cardRootMessagesSize}  variant="outlined">
                              <CardContent style={{position:'relative',textAlign: 'center', padding: '0px', width: '225px', marginLeft: 'auto', marginRight: 'auto'}}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography className={classes.cardHeader}  gutterBottom>
                                    Storage
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <div className={classes.interactionsContainer}>
                                    <span className={classes.sizeSum} >{totalSizeSummary} </span>
                                  </div>
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    constructorType={'chart'}
                                    options={pieChartSizeOptions}
                                  />
                                </Grid>
                              </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} >
                            <Card className={classes.cardRootTodays}  variant="outlined">
                              <CardContent style={{position:'relative',textAlign: 'center', padding: '0px', width: '225px', marginLeft: 'auto', marginRight: 'auto'}}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography className={classes.cardHeader}  gutterBottom>
                                    Today's Messages
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <div className={classes.interactionsContainer}>
                                    <span className={classes.interactionsSumTop} >{todaysTotalSumSummary} </span>
                                    <span className={classes.interactionsSumBottom} >{todaysTotalSumInteger.toLocaleString('en-US', {maximumFractionDigits:2})} </span>
                                  </div>
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    constructorType={'chart'}
                                    options={pieChartTodaysOptions}
                                  />
                                </Grid>
                              </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} >
                            <Card className={classes.cardRootMonths} variant="outlined">
                              <CardContent style={{position:'relative',textAlign: 'center', padding: '0px', width: '225px', marginLeft: 'auto', marginRight: 'auto'}}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography className={classes.cardHeader}  gutterBottom>
                                    Month's Messages
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <div className={classes.interactionsContainer}>
                                    <span className={classes.interactionsSumTop} >{monthsTotalSumSummary} </span>
                                    <span className={classes.interactionsSumBottom} >{monthsTotalSumInteger.toLocaleString('en-US', {maximumFractionDigits:2})} </span>
                                  </div>
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    constructorType={'chart'}
                                    options={pieChartMonthsOptions}
                                  />
                                </Grid>
                              </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                          {/* Highcharts historical chart */}
                          <Grid item xs={12} sm={12} md={12} >
                            <Card className={classes.cardRootTodays} style={{margin: '4px 0px 1px 0px'}} variant="outlined">
                              <CardContent style={{textAlign: 'center', padding: '0px'}}>
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  constructorType={'stockChart'}
                                  options={interactionOptions}
                                />
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                      :null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell ></StyledTableCell>
                    <StyledTableCell align="left"> Device Name</StyledTableCell>
                    <StyledTableCell align="left"> Device Description</StyledTableCell>
                    {/* <StyledTableCell align="center"> Today's Messages</StyledTableCell>
                    <StyledTableCell align="center"> Month's Messages</StyledTableCell> */}
                    <StyledTableCell align="left"> Device Type</StyledTableCell>
                    <StyledTableCell align="left"> Last Connection Established</StyledTableCell>
                    <StyledTableCell align="left"> </StyledTableCell>
                    <StyledTableCell align="left"> </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? devices_list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : devices_list
                  ).map((device, id) => (
                    <StyledTableRow hover  style={{height: '24px',padding: '8px', cursor: 'pointer'}} key={id} >
                      <StyledTableCell  onClick={(event) => this.handleRowClick(event, device)}  component="th" scope="row">
                        {device.device_is_virtual && !device.device_virtual_is_ready ? 
                          <LightTooltip  className={classes.hoverText} title="Building virtual device..." placement="right-start" arrow  >
                            <CircularProgress size={24} className={classes.buttonProgress} /> 
                          </LightTooltip>
                          :
                          devicesDic[device.device_uuid].device_is_online ? <span className="heartbeatOnlineAllDevices"> </span>: <span className="heartbeatOfflineAllDevices"> </span>
                        }
                      </StyledTableCell>
                      <StyledTableCell  onClick={(event) => this.handleRowClick(event, device)}  component="th" scope="row">
                        {device.device_name} 
                      </StyledTableCell>
                      <StyledTableCell  onClick={(event) => this.handleRowClick(event, device)} align="left">{device.device_description}</StyledTableCell>
                      {/* <StyledTableCell  onClick={(event) => this.handleRowClick(event, device)} align="center">
                        <div className={classes.messagesNumBg} >
                          <span className={classes.messagesNum}  >
                            {todays_dic && Object.keys(todays_dic).length > 0 && todays_dic[device.device_uuid] ? 
                              this.numberAdjust(todays_dic[device.device_uuid].count)
                              : 
                              0}
                          </span>
                        </div>
                        </StyledTableCell> */}
                      {/* <StyledTableCell  onClick={(event) => this.handleRowClick(event, device)} align="center">
                        <div className={classes.messagesNumBg} >
                          <span className={classes.messagesNum} >
                          {months_dic && Object.keys(months_dic).length > 0 && months_dic[device.device_uuid] ? 
                              this.numberAdjust(months_dic[device.device_uuid].count)
                              : 
                              0}
                          </span>
                        </div>
                      </StyledTableCell> */}
                      <StyledTableCell onClick={(event) => this.handleRowClick(event, device)} align="left">
                        {device.device_is_virtual ? 
                          <div className={classes.E3SubscriptionCSS} >
                            <span style={{fontSize: '11px', color:'rgb(14, 98, 69)'}} >
                              {device.subscription_plan_identifier == "vr-dev-basic" ? "V-Basic": null}
                              {device.subscription_plan_identifier == "vr-dev-standard" ? "V-Std": null}
                              {device.subscription_plan_identifier == "vr-dev-pro" ? "V-Pro": null}
                              {device.subscription_plan_identifier == "ent-vr-dev-standard" ? "Ent-V-Std": null}
                              {device.subscription_plan_identifier == "ent-vr-dev-pro" ? "Ent-V-Pro": null}
                            </span>
                          </div>
                        : <div className={classes.E2SubscriptionCSS} >
                            <span style={{fontSize: '11px', color:'rgb(14, 50, 97)'}} >
                              {device.subscription_plan_identifier == "dev-basic" ? "Dev-Basic": null}
                              {device.subscription_plan_identifier == "dev-standard" ? "Dev-Std": null}
                              {device.subscription_plan_identifier == "dev-pro" ? "Dev-Pro": null}
                              {device.subscription_plan_identifier == "ent-dev-standard" ? "Ent-Dev-Std": null}
                              {device.subscription_plan_identifier == "ent-dev-pro" ? "Ent-Dev-Pro": null}
                              {device.subscription_plan_identifier == "IOT_GATEWAY_E2" ? "Physical": null}
                              {device.subscription_plan_identifier == "IOT_GATEWAY_FREE" ? "Virtual": null}
                              {device.subscription_plan_identifier == "IOT_GATEWAY_PAY_AS_YOU_GO" ? "Virtual": null}
                            </span>
                          </div>
                          }
                        {/* {device.subscription_plan_identifier == "IOT_GATEWAY_FREE" ? 
                          <div className={classes.freeSubscriptionCSS} >
                            <span style={{fontSize: '11px', color:'rgb(99, 99, 99)'}} >Virtual</span>
                          </div>
                        : null}
                        {device.subscription_plan_identifier == "IOT_GATEWAY_PAY_AS_YOU_GO" ? 
                          <div className={classes.freeSubscriptionCSS} >
                            <span style={{fontSize: '11px', color:'rgb(99, 99, 99)'}} >Physical</span>
                          </div>
                        : null}
                        {device.subscription_plan_identifier == "IOT_GATEWAY_E1" ? 
                          <div className={classes.E1SubscriptionCSS} >
                            <span style={{fontSize: '11px', color:'rgb(106, 112, 22)'}} >Physical</span>
                          </div>
                        : null}
                        {device.subscription_plan_identifier == "IOT_GATEWAY_E2" ? 
                          <div className={classes.E2SubscriptionCSS} >
                            <span style={{fontSize: '11px', color:'rgb(14, 50, 97)'}}>Physical</span>
                          </div>
                        : null}
                        {device.subscription_plan_identifier == "IOT_GATEWAY_E3" ? 
                          <div className={classes.E3SubscriptionCSS} >
                            <span style={{fontSize: '11px', color:'rgb(14, 98, 69)'}}>Physical</span>
                          </div>
                          : null} */}
                      </StyledTableCell>
                      <StyledTableCell  onClick={(event) => this.handleRowClick(event, device)} align="left">{devicesDic[device.device_uuid].device_last_heard}</StyledTableCell>
                      <StyledTableCell align="left"> 
                        <EditDeviceModal 
                          device={device}
                          device_name={device.device_name}
                          device_description={device.device_description}
                          device_uuid={device.device_uuid} 
                          refreshDeviceList={this.refreshDeviceList}
                        /> 
                      </StyledTableCell>
                      <StyledTableCell align="left"> <DeleteDeviceModal deviceName={device.device_name} refreshDeviceList={this.refreshDeviceList} device_uuid={device.device_uuid} /> </StyledTableCell>
                    </StyledTableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 40 * emptyRows }}>
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TableFooter 
                // style={globalStyles.DarkTheme}
                >
              <TableRow 
                // style={globalStyles.DarkTheme}
                >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={devices_list.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  // style={{borderBottom: 'transparent', color: globalStyles.DarkTheme.color}}
                  // classes={{
                  //   selectIcon: classes.selectorIconPagination,
                  //   select: classes.selectorIconPagination,
                  // }}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
              </TableFooter>
              </TableContainer>
              

            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <MembersListPage 
                listType = {'Project'} 
                memberApi = {'https://api.iotflows.com/v1/projects/' + current_project_uuid + '/members'} 
                roleApi = {'https://api.iotflows.com/v1/projects/roles'} 
                searchMemberApi = {'https://api.iotflows.com/v1/organizations/' + selectedOrganization.organization_uuid + '/members/search/containing?q='}
              />
            </TabPanel>
            {/* <TabPanel value={value} index={2} dir={theme.direction}>
              <Container  >
                <Paper  style={{ height: 700}}>
                {rows == '' ? 
                  <Paper elevation={3}  >
                    <div className={classes.waitingCSS}  >
                      <span className="waitingOnData"> 
                      <Col   >
                        <Row style={{ justifyContent:'center'}}>
                          <Typography  variant="subtitle2" style={{ fontFamily:'Trebuchet MS'}}  >
                            WAITING ON DATA
                          </Typography>
                        </Row>
                        <Row style={{ justifyContent:'center'}}>
                          <Typography  variant="body2"  style={{ fontSize: '12px', fontFamily:'Trebuchet MS' }}>
                              Device messages will be published here in real-time
                          </Typography>
                        </Row>
                      </Col>
                      </span>
                    </div>
                  </Paper> 
                  : null}
                  <DataStreamsTable 
                    rowCount={rows.length}
                    rowGetter={({ index }) => rows[index]}
                    onRowClick={(event) => { 
                      this.setState({
                        deviceDataRow: event.rowData.data,
                        selectedDataRow: event.rowData
                      }, ()=> this.setState({openDeviceDataModal: true }))
                    }}
                    columns={[
                      {
                        width: 200,
                        label: 'Device',
                        dataKey: 'device_name',
                      },
                      {
                        width: 300,
                        label: 'Client',
                        dataKey: 'client_name',
                      },
                      {
                        width: 300,
                        label: 'Data Stream',
                        dataKey: 'data_stream_name',
                      },
                      {
                        width: 350,
                        label: 'Data',
                        dataKey: 'data',
                        numeric: true,
                      },
                      {
                        width: 50,
                        label: 'Unit',
                        dataKey: 'data_stream_unit',
                      },
                      {
                        width: 150,
                        label: 'Size',
                        dataKey: 'data_stream_size',
                        
                      },
                      {
                        width: 300,
                        label: 'Received At',
                        dataKey: 'received_at',
                        date: true,
                      },
                    ]}
                  />
                {openDeviceDataModal?
                  <DeviceDataModal openDeviceDataModal={openDeviceDataModal} title={'Device Data'} deviceDataRow={deviceDataRow} selectedDataRow={selectedDataRow} handleCloseDeviceDataModal={this.handleCloseDeviceDataModal} />
                  : null}                
                  </Paper>
              </Container>
            </TabPanel> */}
          </SwipeableViews>
        
      </Paper>
      </div>

    )
  }  
}

// export default MainDevicesPage
const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    allOrganizations: state.organizations.allOrganizations,
    currentUserInfo: state.users.currentUserInfo,

  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles, { withTheme: true })(SelectedProjectPage));


// export default withStyles(styles)(SelectedProjectPage)

import {network_data_api, network_names} from '../MyNetworksPage/NetworkDataApi'
import React from 'react';

export const DeviceListColumns = [
  // { title: "", render: rowData => <span className={`${rowData.device_uuid == '8d786feef417aeb398f8076fecbe0242' ? "heartbeatOnline" : "heartbeatOffline"}`}> </span>, width: 5 },
  // { title: "", render: rowData => <span className={`${rowData.heartbeatIsOn ? "heartbeatOnline" : "heartbeatOffline"}`}> </span>, width: 5 },
  // { title: "", render: rowData => console.log(rowData.heartbeatIsOn), width: 5 },
  { title: 'Device Name', 
    field: 'device_name' 
  },
  // { title: 'Privacy',
  //   field: 'device_is_private',
  //   lookup: { true: 'Private', false: 'Public' }, 
  // },
  { title: 'Device Description', 
    field: 'device_description' 
  },
  { title: 'Device Type', 
    field: 'device_type',
    lookup: {},
    editable: 'onAdd', 
  },
  { title: 'Last Heard', 
    field: 'device_last_heard',
    type: 'datetime',
    editable: 'never',
   },
  // { title: 'Network', 
  //   field: 'network',
  //   lookup: network_names,
    
  //  },
];

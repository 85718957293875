import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';


const styles = theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    // width: theme.spacing(200),
    maxWidth: '800px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    // [theme.breakpoints.down('lg')]:{
    //   width: theme.spacing(200),
    //   maxWidth: '850px',
    // },
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    minWidth: '180px',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  dialog: { 
    borderRadius: 10,
    maxWidth: '1500px' ,
  },
  buttonContainer: {
    padding: '12px'
  }

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class LogsDataModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openLogsDataModal: this.props.openLogsDataModal,
      logDataRow: this.props.logDataRow,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }



  handleOpen = () => {
    this.setState({ 
      openLogsDataModal: true,
    });
  };

  handleClose = () => {
    this.props.handleCloseLogsDataModal();
    this.setState({ 
      openLogsDataModal: false,
    });
  };

  
  


  render() {
    const { classes } = this.props;
    const { openLogsDataModal,
          } = this.state;

    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
      >
        
        {/* <Button className={classes.button} variant="contained"   onClick={this.handleopenLogsDataModal}><AddCircleOutlineOutlinedIcon />&nbsp;Remote Connection </Button> */}

          <Dialog
              open={openLogsDataModal}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">{this.props.title}</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px'}}>
                  
                  <div style={{overflowY:'auto'}}>
                    {this.props.logDataRow}
                  </div>
                </DialogContent>
                <DialogActions style={{justifyContent:'flex-start'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  {/* <Button  style={{marginLeft:'auto'}} color="primary" onClick={() => this.downloadDataButtonClick(logDataRow)}>
                    {'Download'}
                  </Button> */}
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

LogsDataModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(LogsDataModal));






import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, rgbToHex } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {putData, getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import Tooltip from '@material-ui/core/Tooltip';
import '../styles/emailAddressesList.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import MachinePicEditor from './MachinePicEditor';
import ManufacturerLogoEditor from './ManufacturerLogoEditor';
import Box from '@mui/material/Box';


const styles = theme => ({
  paper: {
    position: 'relative',
    maxWidth: theme.spacing(200),
    minWidth: theme.spacing(90),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('md')]:{
      maxWidth: theme.spacing(110),
      minWidth: theme.spacing(80),
    },
    [theme.breakpoints.down('sm')]:{
      maxWidth: theme.spacing(70),
      minWidth: theme.spacing(60),
    },
    [theme.breakpoints.down('xs')]:{
      maxWidth: theme.spacing(43),
      minWidth: theme.spacing(43),
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    borderRadius: 10, 
    maxWidth: theme.spacing(200),
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class AddAssetModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.openEditAssetModal,
      loading: false,
      success: false,
      assetName: '',
      assetDescription: '',
      assetIdentifier: '',
      assetHomepage:'',
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount (){
    if(this.props.asset.asset_custom_name){
      this.setState({assetName: this.props.asset.asset_custom_name})
    }else if(!this.props.asset.asset_custom_name && this.props.asset.machine_name ){
      this.setState({assetName: this.props.asset.machine_name})
    }

    if(this.props.asset.asset_custom_description){
      this.setState({assetDescription: this.props.asset.asset_custom_description})
    }else if(!this.props.asset.asset_custom_name && this.props.asset.machine_description ){
      this.setState({assetDescription: this.props.asset.machine_description})
    }

    if(this.props.asset.asset_custom_identifier){
      this.setState({assetIdentifier: this.props.asset.asset_custom_identifier})
    }else if(!this.props.asset.asset_custom_identifier && this.props.asset.machine_identifier ){
      this.setState({assetIdentifier: this.props.asset.machine_identifier})
    }

    if(this.props.asset.asset_custom_homepage_url){
      this.setState({assetHomepage: this.props.asset.asset_custom_homepage_url})
    }else if(!this.props.asset.asset_custom_homepage_url && this.props.asset.machine_homepage_url ){
      this.setState({assetHomepage: this.props.asset.machine_homepage_url})
    }
    
  }


  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
      assetName: '',
      assetDescription: '',
      assetIdentifier: '',
      assetHomepage:'',

    });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
      assetName: '',
      assetDescription: '',
      assetIdentifier: '',
      assetHomepage:'',
    })
  };

  handleAssetNameChange = (event) => {
    this.setState({assetName: event.target.value});
  }

  handleAssetDescriptionChange = (event) => {
    this.setState({assetDescription: event.target.value});
  }
  handleAssetIdentifierChange = (event) => {
    this.setState({assetIdentifier: event.target.value});
  }

  handleAssetHomepageChange = (event) => {
    this.setState({assetHomepage: event.target.value});
  }
  


  submitButtonClick = () => {
    const { loading, assetName, assetDescription, assetIdentifier, assetHomepage} = this.state;
    
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          var tempMachineInfo={};
          if(this.props.asset.machine_name != assetName){
            tempMachineInfo.asset_custom_name = assetName;
          }
          if(this.props.asset.machine_description != assetDescription){
            tempMachineInfo.asset_custom_description = assetDescription;
          }
          if(this.props.asset.machine_identifier != assetIdentifier){
            tempMachineInfo.asset_custom_identifier = assetIdentifier;
          }
          if(this.props.asset.machine_homepage_url != assetHomepage){
            tempMachineInfo.asset_custom_homepage_url = assetHomepage;
          }
          // console.log('tempMachineInfo')
          // console.log(tempMachineInfo)
        putData('https://api.iotflows.com/v1/assets/' + this.props.asset.asset_uuid , tempMachineInfo)
          .then(async response => {
            if(response.ok){
              // const data = await response.json();
              this.props.showSuccessSnackbar("Asset has been edited!");
              this.props.handleOpenEditAssetModal();
              this.props.refreshAssetList();
              this.handleClose();
              this.setState({
                loading: false,
                success: true,
                open:false,
              });
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false});
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };



  render() {
    const { classes, selectedOrganization } = this.props;
    const { open, 
            loading, 
            success, 
            assetName,
            assetDescription,
            assetIdentifier,
            assetHomepage,} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    
    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
      >
        
        {/* <Button className={classes.button} variant="contained"   onClick={this.handleOpen}><AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Add Asset</Button> */}

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >

                
                  <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Add Asset</DialogTitle>
                  <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px', minHeight:'300px'}}>
                  
                    <TextField
                      style={{marginTop: '0px'}}
                      autoFocus
                      margin="normal"
                      id="assetName"
                      label="Name"
                      type="text"
                      value={assetName} 
                      onChange={this.handleAssetNameChange}
                      fullWidth
                      required
                    />

                    <TextField
                      margin="normal"
                      id="assetDescription"
                      label="Description"
                      type="text"
                      value={assetDescription} 
                      onChange={this.handleAssetDescriptionChange}
                      fullWidth
                    />
                    <TextField
                      margin="normal"
                      id="assetIdentifier"
                      label="Identifier"
                      type="text"
                      value={assetIdentifier} 
                      onChange={this.handleAssetIdentifierChange}
                      fullWidth
                    />
                    <TextField
                      margin="normal"
                      id="assetHomepage"
                      label="Homepage"
                      type="url"
                      value={assetHomepage} 
                      onChange={this.handleAssetHomepageChange}
                      fullWidth
                    />
                    

                  </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading || (assetName == '') } onClick={this.submitButtonClick}>
                    {'Edit Asset'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AddAssetModal));


import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
// import DataStreamsTable from '../MyDataStreamsPage/DataStreamsTable';
// import {Container} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Divider from '@material-ui/core/Divider';
import "../styles/ButtonStyles.scss";
import "../styles/HeartbeatLED.scss";
import "../styles/WaitingDataPulse.scss";
import '../styles/ScrollBarStyles.scss';
import '../styles/fontStyles.scss';
import CreateDataStreamModal from '../components/CreateDataStreamModal';
// import DownloadDataStreamDataModal from '../components/DownloadDataStreamDataModal';
import CreateActionModal from '../components/CreateActionModal';
// import AddWifiToDeviceModal from '../components/AddWifiToDeviceModal';
// import DownloadConfigurationFile from '../MyDevicesPage/DownloadConfigurationFile';
import CreateClientModal from '../components/CreateClientModal';
import Switch from '@material-ui/core/Switch';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import { green } from '@material-ui/core/colors';
import MqttService from '../core/services/MqttService';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import {getData} from '../ApiCalls/DataApis';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditClientModal from '../components/EditClientModal'
import EditDataStreamModal from '../components/EditDataStreamModal'
import EditActionModal from '../components/EditActionModal'
import DeleteDataStreamModal from '../components/DeleteDataStreamModal'
import DeleteActionModal from '../components/DeleteActionModal'
import DeleteClientModal from '../components/DeleteClientModal'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import RemoteConnectionsTabPage from './RemoteConnectionsTabPage';
// import AssetsTabPage from '../MyAssetsPage/AssetsTabPage';
// import DeviceDataModal from './DeviceDataModal';
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNightBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import Button from '@material-ui/core/Button';
// import Highcharts from 'highcharts/highstock';
// import HighchartsReact from 'highcharts-react-official';
// import ResetDeviceModal from "./ResetDeviceModal.js"
// import HeaderDevice from './HeaderDevice';
import OverviewDevice from './OverviewDevice';
import LogsDevice from './LogsDevice';
import SvgIcon from '@material-ui/core/SvgIcon';
import ExecuteActionModal from '../components/ExecuteActionModal';
import ContainersTabPage from './ContainersTabPage';


// const Component = () => {
//   const codeString = '(num) => num + 1';
//   return (
//     <SyntaxHighlighter language="javascript" style={dark}>
//       {codeString}
//     </SyntaxHighlighter>
//   );
// };

function GearIcon(props) {
  return (
    <SvgIcon style={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434l.071-.286zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5zm0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78h4.723zM5.048 3.967c-.03.021-.058.043-.087.065l.087-.065zm-.431.355A4.984 4.984 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8 4.617 4.322zm.344 7.646.087.065-.087-.065z"/>
    </SvgIcon>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{padding:'24px 0px 0px 0px'}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const DeviceTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
    borderBottom: '0px solid #e8e8e8',
    // backgroundColor: 'transparent',
    outline: 'none',
    borderRadius: '10px',

  },
  indicator: {
    backgroundColor: '#4f79ce',
  },
})(Tabs);

const DeviceTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    borderRadius: '10px',
    outline: 'none',
    fontFamily: [
      'Trebuchet MS',
    ].join(','),
    '&:hover': {
      color: '#4f86ce',
      opacity: 1,
      outline: 'none',
    },
    '&$selected': {
      color: '#4f79ce',
      fontWeight: theme.typography.fontWeightMedium,
      outline: 'none',
    },
    '&:focus': {
      color: '#4f86ce',
      outline: 'none',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 20,
    padding: 0,
    margin: '0px 0px 0px 10px',
    // marginTop: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const drawerWidth = 375;


const styles = theme => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    height: 120,
    backgroundColor: '#eeeeee',
  },
  cardRootBig: {
    maxHeight: 400,
    overflow: 'auto',
    backgroundColor: '#eeeeee',
  },
  appBar: {
    paddingTop: '60px',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  // title: {
  //   flexGrow: 1,
  // },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    // backgroundColor: '#eeeeee',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#eeeeee',
    overflowX: 'hidden',
  },
  drawerHeader: {
    backgroundColor: '#eeeeee',
    paddingTop: '70px',
    display: 'flex',
    alignItems: 'center',
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  chevBtnCSS: {
    outline: 'none',
    padding: '0px',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  content: {
    flexGrow: 1,
    paddingTop: '55px',
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    [theme.breakpoints.down('xs')]: {
      paddingTop: '70px',
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  paper: {
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    margin: 'auto',
    backgroundColor: '#fff',
  },
  headerCSS: {
    paddingLeft: 80, 
    paddingTop: 60, 
    // paddingBottom: '5px',
    backgroundColor: '#ffffff',
    border:'0px solid #ffffff',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10, 
    },
  },
  headerCSS2: {
    borderRadius: '5px',
    height:'auto',
    // paddingLeft: '10px',
    // paddingBottom: '8px',
    // paddingTop: '8px',
    backgroundColor: '#eeeeee',
    // [theme.breakpoints.down('xs')]: {
    //   paddingLeft: 10, 
    // },
  },
  deviceFontSize: {
    fontSize: '14px'
  },
  title: {
    fontSize: 14,
    textAlign: 'left',
  },
  titleDeviceClients: {
    fontSize: 14,
    textAlign: 'left',
    margin: '0px'
  },
  initialSetupText: {
    fontSize: 12,
    textAlign: 'left',
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    height: '34px',
    padding:'3px 14px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
    },
  },
  executeButton: {
    backgroundColor:'transparent',
    borderRadius:'25px',
    // border:'0px solid #C2410C',
    display:'inline-block',
    cursor:'pointer',
    // color:'#C2410C',
    // fontFamily:'Trebuchet MS',
    // fontSize:'12px',
    // font-weight:bold;
    padding:'3px 3px',
    outline: 'none',
    '&:hover': {
      backgroundColor:'rgba(37, 99, 235,0.1)',
      opacity:'0.8',
      outline: 'none',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  gearButton: {
    padding: '4px', 
    marginRight: '16px', 
    borderRadius: '4px', 
    // color: '#4f87cc',
    backgroundColor:'transparent',
    border:'0px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'14px',
    // font-weight:bold;
    height: '34px',
    padding:'3px 14px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:focus': {
      outline:'none',
    },
    '&:active': {
      position:'relative',
      top:'1px',
    },
  },
  tourIconCSS: {
    padding: '0px', 
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '20px',
    // marginLeft: '0px', 
    // marginBottom: '0px',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  errorIconCSS: {
    padding: '4px', 
    marginLeft: '0px', 
    marginBottom: '4px',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  errorIconCSS2: {
    padding: '4px', 
    marginLeft: '0px', 
    marginBottom: '6px',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  nodeRedContainer:{
    paddingLeft: '30px',
  },
  hoverText: {    
    fontSize: 33,
    paddingLeft: 0,
  },
  paperDialog: {
    position: 'relative',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    margin:theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(44),
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  dialogContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  listItemsCSS: {
    borderRadius:'10px',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  listPaperCSS: {
    maxHeight: 250, 
    overflow: 'auto', 
    padding: '0px',
    backgroundColor: '#f5f5f5',
  },
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '3px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
  },
  waitingCSS:{
    // padding: '5px',
    position: 'absolute',
    // borderRadius: '10px',
    // backgroundColor: '#fafafa',
    // border:'1px solid #4f86ce',
    zIndex: '2',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    left: 'calc(50% - 148px)',
    top: '15%',
    // textShadow:'1px 1px 1px #9eb9ff',
  },
  setUpBtn: {
    borderRadius: '5px',
    color: 'rgb(42, 42, 47)',
    border: '1px solid rgba(42, 42, 47,0.7)',
    backgroundColor: 'transparent',
    marginRight: '10px',
    '&:hover': {
      color: 'rgba(42, 42, 47,0.8)',
      border: '1px solid rgba(42, 42, 47,1)',
      outline: 'none',
      transform: 'translateY(-1px)',
    },
  },
  tourSection: {
    textAlign: 'left',
    color:'rgb(102, 60, 0)', 
    border:'1px solid #df7800', 
    fontSize: '0.875rem', 
    fontFamily:'Trebuchet MS', 
    padding: '8px', 
    borderRadius: '5px', 
    margin: '0 0 16px 0',
  },
  chartBoxCSS: {
    border:'1px solid #d1d1d1',
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



class SelectedDevicePage extends React.Component{
  
  constructor(props){
    super(props);
    this.state = {
      nodeRedSwitchChecked: false,
      sshSwitchChecked: false,
      ssh_connect: false,
      openNodeRedConfigurationModal: false,
      connectedServer: false,
      connectedDevice: false,
      failed: false,
      isConnected: false,
      message: '',
      drawerOpen: false,
      client_list: [],
      data_streams_list: [],
      actions_list: [],
      server_url_ssh: '',
      nodeRedConnectionEstablished: false,
      sshConnectionEstablished: false,
      heartbeatIsOn: false,
      lastHeard: '',
      initial_setup: false,
      first: true, 
      isDeviceEnabled: true,
      isDeviceOnline: false,
      selectedDevice: [],
      value: 0,
      deviceDataRow: [],
      openDeviceDataModal: false,
      run: false,
      deviceGeolocation: [],
      deviceInfo:{},
      openExecuteActionModal: false,
      actionSelected: [],
      upTimeOptions: {
        credits: {enabled: false},
        title: {
          text: 'Uptime',
          margin: 0,
        },
        chart: {
          height: '200px',
          alignTicks: false,
        },
        scrollbar: {
          enabled: false
        },
        // rangeSelector:{},
        rangeSelector:{
          allButtonsEnabled: true,
          buttons: [
            {
            type: 'day',
            count: 1,
            text: '1d'
          }, {
            type: 'day',
            count: 7,
            text: '7d'
          },{
            type: 'day',
            count: 14,
            text: '14d'
          },{
            type: 'month',
            count: 1,
            text: '1m'
          },{
            type: 'month',
            count: 3,
            text: '3m'
          },{
            type: 'month',
            count: 6,
            text: '6m'
          }],
          selected: 5
        },
        xAxis: {       
          ordinal: false,
          gridLineWidth: 1
        },
        yAxis: {
          gridLineWidth: 1,
          allowDecimals: false,
          ceiling: 1,
          max: 1,
          tickPositioner: function (min, max) {
            var ticks = [],
                interval = (max - min) / (this.chart.yAxis[0].tickPositions.length - 1);

            while (min <= max) {
                ticks.push(min);
                min += interval;
            }

            return ticks;
          },
        },
        plotOptions: {
          series: {
            lineWidth: 0,
          },
        },
        time: {
          timezoneOffset: new Date().getTimezoneOffset()
        },
        series: [{
          type: 'area',
          color: '#4caf50',
          name: 'Uptime',  
          data: [Date().toLocaleString(),0],        
          // data: [[1615726420133.15,1],[1615726438339.27,0],[1616483432104.93,1],[1616486511376.51,0],[1616488341604.9,1],[1616488708046.84,0],[1616490118260.24,1],[1616490129180.98,0],[1616875607868.05,1],[1616875635271.49,0],[1616875770994.73,1],[1616875797883.67,0],[1616876236014.07,1],[1616876262935.6,0],[1616876420090.32,1],[1616876447659.11,0],[1616876491094.86,1],[1616876518296.01,0],[1617776466203.34,1],[1617777618948.78,0]],
          step: 'left',
          dataGrouping: {
            units: [[
              'millisecond', // unit name
              [1, 2, 5, 10, 20, 25, 50, 100, 200, 500] // allowed multiples
          ], [
              'second',
              [1, 2, 5, 10, 15, 30]
          ], [
              'minute',
              [1, 2, 5, 10, 15, 30]
          ], [
              'hour',
              [1, 2, 3, 4, 6, 8, 12]
          ], [
              'day',
              [1]
          ], [
              'week',
              [1]
          ], [
              'month',
              [1, 3, 6]
          ], [
              'year',
              null
          ]]
          }
        }],
        navigator: {
          height: 15,
          outlineWidth: 0,
          outlineColor: '#ffffff',
          xAxis: {
            gridLineWidth: 0,
            gridLineColor: '#ffffff',
          },
          yAxis: {
            gridLineWidth: 0,
            gridLineColor: '#ffffff',
          },
          chart: {
            type: 'area',
            color: '#4caf50',
          },
          series: {
              type: 'area',
              step: 'left',
              color: '#4caf50',
              lineWidth: 0.5,
          }
        },
      },
      steps: [
        {
          content: <h5 style={{fontFamily: 'Trebuchet MS', fontSize: '18px', margin: '0px', fontWeight: '700'}}>Quick steps to publish data!</h5>,
          locale: { skip: <strong aria-label="skip">End</strong> },
          placement: 'center',
          target: 'body',
        },
        {
          title: <h5 style={{fontFamily: 'Trebuchet MS', fontSize: '18px', margin: '0px', fontWeight: '700'}}>1.Device API Keys</h5>,
          target: '.tourClientStep',
          placement: 'left-start',
          placementBeacon: 'left',
          locale: { skip: <strong aria-label="skip">End</strong> },
          content: (
            <React.Fragment >
              <div style={{textAlign: 'left', fontFamily: 'Trebuchet MS'}}>
                <h6 style={{fontFamily: 'Trebuchet MS'}}><b>Create a Device API Key:</b> Device API Keys are used in any applications or scripts on your device that communicate to the cloud in real-time.</h6>
                <p style={{ fontSize: '14px', fontFamily: 'Trebuchet MS' }}>
                  <i>for details go to&nbsp;
                    <a  style={{textDecoration: 'none'}} href="https://docs.iotflows.com/iotflows-platform/creating-a-device-client" target="_blank">
                      Device API Key Documentation
                    </a>
                  </i>
                </p>
              </div>              
            </React.Fragment>
          ),
        },
        {
          title: <h5 style={{fontFamily: 'Trebuchet MS', fontSize: '18px', margin: '0px', fontWeight: '700'}}>2.Data Stream</h5>,
          target: '.tourDataStreamStep',
          placement: 'left-start',
          placementBeacon: 'left',
          locale: { skip: <strong aria-label="skip">End</strong> },
          content: (
            <React.Fragment >
              <div style={{textAlign: 'left', fontFamily: 'Trebuchet MS'}}>
                <h6 style={{fontFamily: 'Trebuchet MS'}}><b>Create a Data Stream:</b> A data stream is a channel that you can use with Device API Key to define the properties of the data that you publish. This includes, name, description, and unit of the data. </h6>
                <p style={{ fontSize: '14px',fontFamily: 'Trebuchet MS'}}>
                  <i>for details go to&nbsp;
                    <a  style={{textDecoration: 'none'}} href="https://docs.iotflows.com/iotflows-platform/creating-a-data-stream" target="_blank">
                      Data Stream Documentation
                    </a>
                  </i>
                </p>
              </div>              
            </React.Fragment>
          ),
        },
        {
          title: <h5 style={{fontFamily: 'Trebuchet MS', fontSize: '18px', margin: '0px', fontWeight: '700'}}>3.Publishing & Subscribing</h5>,
          placement: 'center',
          target: 'body',
          styles:{
            options: {
              width:'100%',
              // minWidth: 500,
              fontFamily: 'Trebuchet MS',
              overflow: 'scroll',
            }
          },
          locale: { skip: <strong aria-label="skip">End</strong> },
          content: (
            <React.Fragment >
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 style={{fontSize: '16px', fontFamily: 'Trebuchet MS', fontWeight: '700'}}>Publish with Python</h4>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={0} style={{textAlign: 'left', fontSize: '14px'}}>
                  <Grid item xs={12}>
                  <p style={{ fontSize: '14px',fontFamily: 'Trebuchet MS',lineHeight: '1.2'}}>
                    <i>For details on Publishing a Data Stream and Alert, Subscribing to a Data Stream, Defining a Cloud Action, and Calling/Executing a Cloud Action, go to&nbsp;
                      <a  style={{textDecoration: 'none'}} href="https://docs.iotflows.com/sdks/python-sdk" target="_blank">
                        Python Documentation
                      </a>
                    </i>
                 </p>
                  </Grid>
                  <Grid item xs={12}>
                    <h5 style={{fontSize: '16px',fontWeight: '700', lineHeight: '1.2',fontFamily: 'Trebuchet MS',}}>Quick Start</h5>
                  </Grid>
                  <Grid item xs={12}>
                    <h6 style={{lineHeight: '1.1', fontSize: '12px',fontFamily: 'Trebuchet MS',}}>Install the iotflows python module:</h6>
                  </Grid>
                  <Grid item xs={12}>
                    <SyntaxHighlighter style={tomorrowNightBlue} language="shell" >{`sudo pip3 install pathlib2`}</SyntaxHighlighter>
                  </Grid>
                  <Grid item xs={12}>
                    <SyntaxHighlighter style={tomorrowNightBlue} language="shell" >{`sudo pip3 install iotflows`}</SyntaxHighlighter>
                  </Grid>
                  <Grid item xs={12}>
                    <h6 style={{fontSize: '14px',fontWeight: '600', lineHeight: '1.1', margin: '0px 0px 8px 0px', fontFamily: 'Trebuchet MS',}}>Initialize</h6>
                  </Grid>
                  <Grid item xs={12}>
                    <SyntaxHighlighter style={tomorrowNightBlue} language="python" showLineNumbers={true} wrapLines={true} >
                     {`import iotflows.realtime as iotflowsRT
IoTFlows = iotflowsRT.init('API_KEY_USERNAME', 'API_KEY_PASSWORD')`}
                    </SyntaxHighlighter>
                  </Grid>
                  <Grid item xs={12}>
                    <h6 style={{fontSize: '14px',fontWeight: '600', lineHeight: '1.1', margin: '0px 0px 8px 0px', fontFamily: 'Trebuchet MS'}}>Publish</h6>
                  </Grid>
                  <Grid item xs={12}>
                    <SyntaxHighlighter style={tomorrowNightBlue} language="python" showLineNumbers={true} wrapLines={true} >
                     {`IoTFlows.publish(
    data_stream_uuid='ds_xxxxxxxxxxxxxxxxxxxxxxx', 
    data='Hello World!')`}
                    </SyntaxHighlighter>
                  </Grid>
                  <Grid item xs={12}>
                  <h6 style={{fontSize: '14px',fontWeight: '600', lineHeight: '1.1', margin: '0px 0px 8px 0px', fontFamily: 'Trebuchet MS'}}>Subscribe</h6>
                  </Grid>
                  <Grid item xs={12}>
                    <SyntaxHighlighter style={tomorrowNightBlue} language="python" showLineNumbers={true} wrapLines={true} >
                     {`def handlerFunction(topic, payload):
    print('received new payload!!!')
    print(payload)

IoTFlows.subscribe(
    data_stream_uuid = 'ds_xxxxxxxxxxxxxxxxxxxxxxx',        
    qos = 2,
    callback = handlerFunction)`}
                    </SyntaxHighlighter>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>  
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 style={{fontSize: '16px', fontFamily: 'Trebuchet MS', fontWeight: '700'}}>Publish with Javascript</h4>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={0} style={{textAlign: 'left', fontSize: '14px'}}>
                  <Grid item xs={12}>
                  <p style={{ fontSize: '14px',fontFamily: 'Trebuchet MS',lineHeight: '1.2'}}>
                    <i>For details on Publishing a Data Stream and Alert, Subscribing to a Data Stream, Defining a Cloud Action, and Calling/Executing a Cloud Action, go to&nbsp;
                      <a  style={{textDecoration: 'none'}} href="https://docs.iotflows.com/sdks/javascript-sdk" target="_blank">
                        Javascript Documentation
                      </a>
                    </i>
                 </p>
                  </Grid>
                  <Grid item xs={12}>
                    <h5 style={{fontSize: '16px',fontWeight: '700', lineHeight: '1.2',fontFamily: 'Trebuchet MS',}}>Quick Start</h5>
                  </Grid>
                  <Grid item xs={12}>
                    <h6 style={{lineHeight: '1.1', fontSize: '12px',fontFamily: 'Trebuchet MS'}}>Use npm to install the iotflows.js module:</h6>
                  </Grid>
                  <Grid item xs={12}>
                    <SyntaxHighlighter style={tomorrowNightBlue} language="shell" >{`npm install @iotflows/iotflows-js`}</SyntaxHighlighter>
                  </Grid>
                  <Grid item xs={12}>
                    <h6 style={{fontSize: '14px',fontWeight: '600', lineHeight: '1.1', margin: '0px 0px 8px 0px', fontFamily: 'Trebuchet MS',}}>Initialize</h6>
                  </Grid>
                  <Grid item xs={12}>
                    <SyntaxHighlighter style={tomorrowNightBlue} language="javascript" showLineNumbers={true} wrapLines={true} >
                     {`const {loadIoTFlows} = require('@iotflows/iotflows-js')
var iotflows = await loadIoTFlows('API_KEY_USERNAME', 'API_KEY_PASSWORD');')`}
                    </SyntaxHighlighter>
                  </Grid>
                  <Grid item xs={12}>
                    <h6 style={{fontSize: '14px',fontWeight: '600', lineHeight: '1.1', margin: '0px 0px 8px 0px', fontFamily: 'Trebuchet MS'}}>Publish</h6>
                  </Grid>
                  <Grid item xs={12}>
                    <SyntaxHighlighter style={tomorrowNightBlue} language="python" showLineNumbers={true} wrapLines={true} >
                     {`await iotflows.publish({
    data_stream_uuid: 'ds_xxxxxxxxxxxxxxxxxxxxxxx',
    data: '81'
})  `}
                    </SyntaxHighlighter>
                  </Grid>
                  <Grid item xs={12}>
                  <h6 style={{fontSize: '14px',fontWeight: '600', lineHeight: '1.1', margin: '0px 0px 8px 0px', fontFamily: 'Trebuchet MS'}}>Subscribe</h6>
                  </Grid>
                  <Grid item xs={12}>
                    <SyntaxHighlighter style={tomorrowNightBlue} language="python" showLineNumbers={true} wrapLines={true} >
                     {`await iotflows.subscribe({
    data_stream_uuid: 'ds_xxxxxxxxxxxxxxxxxxxxxxx',        
    callback: function handler(topic, payload){
        console.log("New message received!")        
        console.log(payload)        
    }
})`}
                    </SyntaxHighlighter>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>  
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 style={{fontSize: '16px', fontFamily: 'Trebuchet MS', fontWeight: '700'}}>Publish with Node-RED</h4>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={0} style={{textAlign: 'left', fontSize: '14px'}}>
                  <Grid item xs={12}>
                  <p style={{ fontSize: '14px',fontFamily: 'Trebuchet MS',lineHeight: '1.2'}}>
                    <i>For details on Publishing a Data Stream and Alert, Subscribing to a Data Stream, Defining a Cloud Action, and Calling/Executing a Cloud Action, go to&nbsp;
                      <a  style={{textDecoration: 'none'}} href="https://docs.iotflows.com/publishing-a-data-stream-from-node-red#4-configure-the-data-stream-out-node-in-node-red" target="_blank">
                        Node-RED Documentation
                      </a>
                    </i>
                 </p>
                  </Grid>
                </Grid>
                </AccordionDetails>
              </Accordion>            
            </React.Fragment>
          ),
        },
      ],
    }
    this.refreshClientList = this.refreshClientList.bind(this);
    this.refreshDataStreamList = this.refreshDataStreamList.bind(this);
    this.refreshActionsList = this.refreshActionsList.bind(this);
    // this.onReceiveHeartbeats= this.onReceiveHeartbeats.bind(this);
    this.onDeviceStatus= this.onDeviceStatus.bind(this);
    // this.refreshWifiStatus = this.refreshWifiStatus.bind(this)
    this.deviceStatusChange = this.deviceStatusChange.bind(this)
    this.refreshDevice = this.refreshDevice.bind(this);
    this.handleCloseDeviceDataModal = this.handleCloseDeviceDataModal.bind(this);
    this.handleClickStartDemo = this.handleClickStartDemo.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

  }

  // MQTT functions below
  componentDidMount() {

    localStorage.removeItem('ssh_username');
    localStorage.removeItem('ssh_password');

    getData('https://api.iotflows.com/v1/clients/' + this.props.match.params.device_uuid + '/historical_status/highcharts')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            upTimeOptions: {
              ...this.state.upTimeOptions,
              series: 
                [{...this.state.upTimeOptions.series[0],
                  data: JSON.parse(data.data)}],
            },
          }, ()=> {
            this.setState({

              upTimeOptions: {
                scrollbar: {
                  enabled: false
                },
                credits: {enabled: false},
                title: {
                  text: 'Uptime',
                  margin: 0,
                },
                chart: {
                  height: '200px',
                  alignTicks: false,
                },
                rangeSelector:{
                  allButtonsEnabled: true,
                  buttons: [
                    {
                    type: 'day',
                    count: 1,
                    text: '1d'
                  },{
                    type: 'day',
                    count: 7,
                    text: '7d'
                  },{
                    type: 'day',
                    count: 14,
                    text: '14d'
                  },{
                    type: 'month',
                    count: 1,
                    text: '1m'
                  },{
                    type: 'month',
                    count: 3,
                    text: '3m'
                  },{
                    type: 'month',
                    count: 6,
                    text: '6m'
                  }],
                  selected: 5
                },
                xAxis: {       
                  ordinal: false,
                  gridLineWidth: 1,
                  scrollbar: {enabled: false},
                },
                yAxis: {
                  gridLineWidth: 1,
                  allowDecimals: false,
                  ceiling: 1,
                  max: 1,
                  tickPositioner: function (min, max) {
                    var ticks = [],
                        interval = (max - min) / (this.chart.yAxis[0].tickPositions.length - 1);
    
                    while (min <= max) {
                        ticks.push(min);
                        min += interval;
                    }
    
                    return ticks;
                  },
                },
                plotOptions: {
                  series: {
                    lineWidth: 0,
                  },
                },
                series: [{
                  type: 'area',
                  color: '#4caf50',
                  name: 'Uptime',  
                  data: JSON.parse(data.data),        
                  step: 'left',
                  dataGrouping: {
                    units: [[
                      'millisecond', // unit name
                      [1, 2, 5, 10, 20, 25, 50, 100, 200, 500] // allowed multiples
                  ], [
                      'second',
                      [1, 2, 5, 10, 15, 30]
                  ], [
                      'minute',
                      [1, 2, 5, 10, 15, 30]
                  ], [
                      'hour',
                      [1, 2, 3, 4, 6, 8, 12]
                  ], [
                      'day',
                      [1]
                  ], [
                      'week',
                      [1]
                  ], [
                      'month',
                      [1, 3, 6]
                  ], [
                      'year',
                      null
                  ]]
                  }
                }],
                navigator: {
                  scrollbar: {enabled: false},
                  height: 15,
                  outlineWidth: 0,
                  outlineColor: '#ffffff',
                  xAxis: {
                    gridLineWidth: 0,
                    gridLineColor: '#ffffff',
                  },
                  yAxis: {
                    gridLineWidth: 0,
                    gridLineColor: '#ffffff',
                  },
                  chart: {
                    type: 'area',
                    color: '#4caf50',
                  },
                  series: {
                      type: 'area',
                      step: 'left',
                      color: '#4caf50',
                      lineWidth: 0.5,
                  }
                },
              },
            })
            }


          )
                  
              
        }
      });

    getData('https://api.iotflows.com/v1/devices/'+ this.props.match.params.device_uuid +'/connections')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(Object.keys(data.data).length < 1) {
          this.setState({ value: 2 })
        }
      }
    });


    getData('https://api.iotflows.com/v1/geolocation/ipaddress/'+ this.props.location.state.selected_device.device_peer_host)
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({ deviceGeolocation: data.data })
        }
      }
    });
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/devices')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        for(var i=0; i<Object.keys(data.data).length; i++) {
          if(data.data[i].device_uuid == this.props.match.params.device_uuid) { 
            // let localDateTime = new Date(data.data[i].device_last_heard);
            // localDateTime = localDateTime.toLocaleTimeString()+' '+localDateTime.toLocaleDateString();
            
            if (data.data[i].device_last_heard){
              let localDateTime = new Date(data.data[i].device_last_heard);
              localDateTime = localDateTime.toLocaleTimeString()+' '+localDateTime.toLocaleDateString();
              data.data[i].device_last_heard = localDateTime;
            } else {
              data.data[i].device_last_heard = '';
            }

            this.setState({
              selectedDevice: data.data[i],
              isDeviceEnabled: data.data[i].device_is_enabled,
              isDeviceOnline: data.data[i].device_is_online,
              lastHeard: data.data[i].device_last_heard,
            }, () => this.deviceInformationFormatting())
          }
        }
      }});

   

    // if(this.props.match.params.device_uuid == 'dev_1b01c8584ee30e1b688a0dc8f26b064c'){
    //   this.setState({heartbeatIsOn: true})
    // }
    if(MqttService.isConnected) {
      MqttService.subscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/clients/' + this.props.match.params.device_uuid + '/status/#', this.onDeviceStatus)
      // MqttService.subscribe('v1/iotflows/iotcore/devices/' + this.props.match.params.device_uuid + '/heartbeats', this.onReceiveHeartbeats)
    }

    getData('https://api.iotflows.com/v1/devices/' + this.props.match.params.device_uuid + '/clients')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            client_list: data.data,
          })
        }
      }
    });

    getData('https://api.iotflows.com/v1/devices/' + this.props.match.params.device_uuid + '/data_streams')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            data_streams_list: data.data,
          })
        }
      }
    });

    getData('https://api.iotflows.com/v1/devices/' + this.props.match.params.device_uuid + '/actions')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            actions_list: data.data,
          })
        }
      }
    });


  }



  deviceInformationFormatting = () => {
    if(Object.keys(this.state.selectedDevice).length > 0 && this.state.selectedDevice.device_system_information){
      let device_system_information = this.state.selectedDevice.device_system_information;
      
      // memory
      let memory_free_bytes = device_system_information.mem.free
      let memory_used_bytes = device_system_information.mem.used
      
      // cpu
      let cpu_load_percent = device_system_information.currentLoad.currentLoad
      cpu_load_percent = cpu_load_percent?.toFixed(2)+ " %"
      
      // OS
      let os_distribution = device_system_information.osInfo.distro
      let os_architecture = device_system_information.osInfo.arch
  
      // network
      let default_network_ip4 = ''
      let default_network_ip4subnet = ''
      let default_network_mac = ''
      
      // Get the info of the default network interfaces
      // iterate through network interfaces
      device_system_information.networkInterfaces.map(networkInterface => {
          // check if this is the default one
          if(networkInterface.iface == device_system_information.networkInterfaceDefault)
          {
              default_network_ip4         = networkInterface.ip4 
              default_network_ip4subnet   = networkInterface.ip4subnet 
              default_network_mac         = networkInterface.mac 
          }
      })
      
      
      let vitals = {
          memory_free_bytes,
          memory_used_bytes,
          cpu_load_percent,
          os_distribution,
          os_architecture,
          default_network_ip4,
          default_network_ip4subnet,
          default_network_mac
      }
      this.setState({deviceInfo: vitals})
    }
  }


  onDeviceStatus = message => {
    // updating state of device online and last heard
    try{
      const updatedDeviceState = JSON.parse(message.payloadString);
      var deviceOnlineStatus = updatedDeviceState.is_online;
  
      let localDateTime = new Date(parseInt(updatedDeviceState.timestamp));
      localDateTime = localDateTime.toLocaleTimeString()+' '+localDateTime.toLocaleDateString();
      this.setState({
        isDeviceOnline: deviceOnlineStatus,
        lastHeard: localDateTime,
      });
    }
    catch(e){
      console.log("Error in reading heartbeat from MQTT");
      console.log(e.message);
    }
  }

  // OLD WAY TO SEE IF DEVICE IS ONLINE**********************
  // heartbeatTimer = undefined;
  // onReceiveHeartbeats = message => {
  //   // if(this.props.match.params.device_uuid == 'dev_1b01c8584ee30e1b688a0dc8f26b064c'){
  //   //   this.setState({heartbeatIsOn: true})
  //   // } else {
  //     try{
  //       const heartbeatTimestamp = JSON.parse(message.payloadString);
  //       const date = new Date();
  //       const deltaTime = Math.abs(date.getTime()-heartbeatTimestamp.timestamp);
  //       let localDateTime = new Date(parseInt(heartbeatTimestamp.timestamp));
  //       localDateTime = localDateTime.toLocaleDateString()+' '+localDateTime.toLocaleTimeString();
  //       this.setState({lastHeard: localDateTime});
  //       if(deltaTime< 15000){ 
  //         this.setState({heartbeatIsOn: true})
  //         clearTimeout(this.heartbeatTimer)
  //         this.heartbeatTimer = setTimeout(() => {
  //           this.setState({heartbeatIsOn: false})
  //         }, 15000);
  //       }
  //     }
  //     catch(e)
  //     {
  //       console.log("Error in reading heartbeat from MQTT");
  //       console.log(e.message);
  //     }
  //   // }
    
  // }

  refreshClientList(){
    getData('https://api.iotflows.com/v1/devices/' + this.props.match.params.device_uuid + '/clients')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            client_list: data.data,
          })
        }
      }
    });
  }

  refreshDataStreamList(){
    getData('https://api.iotflows.com/v1/devices/' + this.props.match.params.device_uuid + '/data_streams')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            data_streams_list: data.data,
          })
        }
      }
    });
  }

  refreshActionsList(){
    getData('https://api.iotflows.com/v1/devices/' + this.props.match.params.device_uuid + '/actions')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            actions_list: data.data,
          })
        }
      }
    });
  }
  
  refreshDevice(){
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/devices')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        for(var i=0; i<Object.keys(data.data).length; i++) {
          if(data.data[i].device_uuid == this.props.match.params.device_uuid) { 
            this.setState({
              selectedDevice: data.data[i],
              isDeviceEnabled: data.data[i].device_is_enabled,
              isDeviceOnline: data.data[i].device_is_online,
            }, () => this.deviceInformationFormatting())
          }
        }
      }});
  }

  handleDrawerOpen = () => {
    this.setState({drawerOpen: true})
  };

  handleDrawerClose = () => {
    this.setState({drawerOpen: false})
  };

  componentWillUnmount() {
    if(MqttService.isConnected) {
    // MqttService.unsubscribe('v1/api/devices/' + this.props.match.params.device_uuid + '/heartbeats')
    MqttService.unsubscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/' + this.props.location.state.selected_device.project_uuid + '/devices/' + this.props.match.params.device_uuid + '/data-streams/#')
    MqttService.unsubscribe('v1/organizatons/' + this.props.selectedOrganization.organization_uuid + '/clients/' + this.props.match.params.device_uuid + '/status/#')
    }
  }

  deviceStatusChange = (event) => {
    this.state.isDeviceEnabled == false ? this.setState( {isDeviceEnabled: true} ) : this.setState({isDeviceEnabled: false})
    // putData('https://api.iotflows.com/v1/devices/' + this.props.match.params.device_uuid + '/status', 
    // {'device_is_enabled': !this.state.isDeviceEnabled})
    //   .then(async response => {
    //     console.log('response')
    //     console.log(response)
    //     if(response.ok){
    //       this.props.showSuccessSnackbar("Device Status changed!");
    //       this.refreshDevice();
    //       // {this.state.isDeviceEnabled == false ? this.setState( {isDeviceEnabled: true} ) : this.setState({isDeviceEnabled: false})}
    //     }
    //     else{
    //       try{
    //         const data = await response.json();
    //         this.props.showErrorSnackbar(data.message);
            
    //       }catch (e){
    //         this.props.showErrorSnackbar('Something went wrong');
    //       }
    //     }
    //   });
  }

  handleChange = (event, newValue) => {
    this.setState({value: newValue});
  };

  handleChangeIndex = (index) => {
    this.setState({value: index});
  };

  handleCloseDeviceDataModal = () => {
    this.setState({
      openDeviceDataModal: false,
      deviceDataRow: []
      })
  }


  handleClickStartDemo = (e) => {
    e.preventDefault();

    this.setState({
      drawerOpen: true,
      run: true,
    })
  }

  handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses= [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ run: false });
    }

    // console.groupCollapsed(type);
    // console.log(data);
    // console.groupEnd();
  };

  getHelpers = (helpers) => {
    this.helpers = helpers;
    // console.log('helpers')
    // console.log(helpers)
  };

  handleCloseModal = () => {
    this.setState({
      openExecuteActionModal: false,
      actionSelected: []
    })
  }

  handleExecuteAction = (action) => {
    this.setState({
      openExecuteActionModal: true,
      actionSelected: action
    })
  }

  render(){
    const { classes, selectedOrganization, theme} = this.props
    const { drawerOpen , 
            client_list,  
            nodeRedConnectionEstablished,
            sshConnectionEstablished,
            lastHeard,
            initial_setup,
            isDeviceEnabled,
            isDeviceOnline,
            selectedDevice,
            connectedServer,
            value,
            openDeviceDataModal,
            openExecuteActionModal,
            actionSelected,
            deviceDataRow,
            steps,
            run,
            upTimeOptions,
            deviceGeolocation,
            deviceInfo} = this.state;

    const current_device_uuid = this.props.match.params.device_uuid;
    const selected_device = this.props.location.state.selected_device;


    return (
      <div className={classes.root}>
        <Joyride
            callback={this.handleJoyrideCallback}
            // disableScrolling={true}
            continuous={true}
            steps={steps} 
            run={run}
            getHelpers={this.getHelpers}
            showProgress={true}
            showSkipButton={true}
            styles={{
              options: {
                primaryColor:'#4f87cc',
                textColor: '#606972',
                zIndex: 1500,
                fontFamily: 'Trebuchet MS',
              },
              buttonNext: {
                border: '0px solid #4f87cc',
              },
            }}
          />
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        }),classes.headerCSS }
      >
        <Toolbar disableGutters variant='dense' style={{padding: '0px'}}>
        <Grid item xs={10} container style={{paddingLeft: '0px'}} >
          <Breadcrumbs  separator="›" aria-label="breadcrumb">
            <Typography className={classes.fontStyles} style={{color:'#757575'}}>{selectedOrganization.organization_name}</Typography>
            <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/projects" >
              Projects
            </Link>
            <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to={{pathname: "/projects/" + selected_device.project_uuid ,state: { selected_project_name: selected_device.project_name}}}  >
              {selected_device.project_name}
            </Link>
            <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to={{pathname: "/projects/" + selected_device.project_uuid ,state: { selected_project_name: selected_device.project_name}}} >
              Devices
            </Link>
            <Typography className={classes.fontStyles} color="textPrimary">{selected_device.device_name}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={2} container style={{justifyContent:'flex-end'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={this.handleDrawerOpen}
            // style={{padding: '4px', margin: '0px', borderRadius: '4px', color: '#4f87cc'}}
            className={clsx(drawerOpen && classes.hide), classes.gearButton}
          >
            <GearIcon style={{color: '#4f87cc', fontSize: 24 }} /> &nbsp; Messaging
            {/* <KeyboardArrowLeftOutlinedIcon style={{color: '#4f87cc', border:'2px solid #4f86ce', borderRadius:'20px', fontSize: '30px'}} /> */}
          </IconButton>
        </Grid>
        </Toolbar>
      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <div  />
       

        <Paper style={{paddingLeft: '0px', paddingRight: '0px',margin:'0px 8px 60px 8px'}}>
          <AppBar position="static" color="default" style={{borderRadius: '10px'}}>
            <DeviceTabs
              value={value}
              onChange={this.handleChange}
              variant="fullWidth"
            >
              <DeviceTab label="Device Overview" {...a11yProps(0)}  />
              <DeviceTab label="Data Streams" {...a11yProps(1)}  />
              {/* <DeviceTab label="Assets" {...a11yProps(2)}  /> */}
              <DeviceTab label="Secure Remote Connections / File Transfer" {...a11yProps(2)}  />
              <DeviceTab label="Containers" {...a11yProps(3)}  />
              
            </DeviceTabs>
          </AppBar>
          <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={this.handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction} >
              {Object.keys(selected_device).length > 0 ?
                <OverviewDevice
                  handleClickStartDemo={this.handleClickStartDemo}
                  selectedDevice={selectedDevice}
                  selected_device={selected_device} 
                  isDeviceOnline={isDeviceOnline} 
                  deviceGeolocation={deviceGeolocation} 
                  device_uuid={current_device_uuid}
                  lastHeard={lastHeard}
                  project_uuid={this.props.location.state.selected_device.project_uuid}
                  deviceInfo={deviceInfo}
                />
              : null}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {Object.keys(selectedDevice).length > 0?
              <LogsDevice
                handleClickStartDemo={this.handleClickStartDemo}
                selectedDevice={selectedDevice}
                selected_device={selected_device} 
                isDeviceOnline={isDeviceOnline} 
                deviceGeolocation={deviceGeolocation} 
                device_uuid={current_device_uuid}
                lastHeard={lastHeard}
                isDeviceEnabled={isDeviceEnabled}
                deviceInfo={deviceInfo}
              />
              : null}
          </TabPanel>
          {/* <TabPanel value={value} index={2} dir={theme.direction}>
            {Object.keys(selectedDevice).length > 0?
              <AssetsTabPage 
                handleClickStartDemo={this.handleClickStartDemo}
                selectedDevice={selectedDevice}
                selected_device={selected_device} 
                isDeviceOnline={isDeviceOnline} 
                deviceGeolocation={deviceGeolocation} 
                device_uuid={current_device_uuid}
                lastHeard={lastHeard}
                isDeviceEnabled={isDeviceEnabled}
                deviceInfo={deviceInfo}
              />
              : null}
          </TabPanel> */}
          <TabPanel value={value} index={2} dir={theme.direction} >
            {Object.keys(selectedDevice).length > 0?
              <RemoteConnectionsTabPage
                device_uuid={current_device_uuid}  
                nodeRedConnectionEstablished= {nodeRedConnectionEstablished}
                device_disabled={!isDeviceEnabled}
                successServer={connectedServer}
                sshConnectionEstablished= {sshConnectionEstablished}
                selectedDevice={selectedDevice}
                selected_device={selected_device} 
                isDeviceOnline={isDeviceOnline} 
                deviceGeolocation={deviceGeolocation} 
                lastHeard={lastHeard}
                handleClickStartDemo={this.handleClickStartDemo}
                deviceInfo={deviceInfo}
              />
            : null}
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction} >
            <ContainersTabPage
              device_uuid={current_device_uuid}  
              nodeRedConnectionEstablished= {nodeRedConnectionEstablished}
              device_disabled={!isDeviceEnabled}
              successServer={connectedServer}
              sshConnectionEstablished= {sshConnectionEstablished}
              selectedDevice={selectedDevice}
              selected_device={selected_device} 
              isDeviceOnline={isDeviceOnline} 
              deviceGeolocation={deviceGeolocation} 
              lastHeard={lastHeard}
              handleClickStartDemo={this.handleClickStartDemo}
              deviceInfo={deviceInfo}
              />
          </TabPanel>
          </SwipeableViews>
          </Paper>


      </main>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
         <div style={{marginBottom: '60px'}}>
           <div className={classes.drawerHeader} >
            <IconButton onClick={this.handleDrawerClose} className={classes.chevBtnCSS} >
              <ChevronRightIcon  />
            </IconButton>
           </div>
          

            {/* Device Wi-Fi management section */}
            {/* <div style={{paddingLeft: '5px', paddingBottom: '10px', paddingTop: '0px'}}>
              <Row style={{margin: '0px', alignItems: 'baseline', justifyContent: 'flex-start'}}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Wi-Fi Management&nbsp;&nbsp;
                </Typography>
                {initial_setup && 
                  <Typography className={classes.initialSetupText} color="textSecondary" gutterBottom>
                    <i>* Add Wifi Network for initial Setup</i>
                  </Typography>
                }
              </Row>
              
              <div style={{paddingBottom: '5px'}}>
              <Grid container  spacing={3}>
                <Grid item >
                  <AddWifiToDeviceModal device_uuid ={current_device_uuid} refreshWifiStatus = {this.refreshWifiStatus} device_disabled={!isDeviceEnabled} />
                </Grid>
                <Grid item >
                  <DownloadConfigurationFile device_uuid ={current_device_uuid} heartbeatIsOn={heartbeatIsOn} isThereWifi={initial_setup} device_disabled={!isDeviceEnabled} />
                </Grid>
              </Grid>

              </div>
            </div>
            <Divider /> */}

            {/* Device API Keys section */}
            <div style={{paddingLeft: '5px', paddingBottom: '10px', paddingTop: '10px'}}>
              <Row style={{margin: '0px', alignItems: 'center', alignContent: 'center'}} >
              <LightTooltip title="A client is used in an application such as NodeRED or Python to publish and subscribe to data streams and alerts"> 
                <Typography className={classes.titleDeviceClients} color="textSecondary" gutterBottom>
                  Device API Keys 
                </Typography>
              </LightTooltip>
              <LightTooltip title="Learn more">
                <IconButton className={classes.errorIconCSS} onClick={() => window.open('https://docs.iotflows.com/real-time-data-streams-alerts-and-actions/create-a-device-api-key', "_blank")} >
                  <ErrorOutlineRoundedIcon style={{color: '#9e9e9e', fontSize: 20 }} />
                </IconButton>
              </LightTooltip>
              </Row>
              <div className="tourClientStep" style={{paddingBottom: '10px'}}>
                <CreateClientModal device_uuid={current_device_uuid} device_disabled={!isDeviceEnabled} refreshClientList={this.refreshClientList} selected_device={selected_device}  /> 
              </div>

              <Paper style={{overflow: 'hidden', padding: '0px',backgroundColor: '#f5f5f5'}} className="cardScrollbar" >
                <List style={{padding: '0px',backgroundColor: '#f5f5f5'}} >
                  
                  {client_list.map((client, index) => (
                    <ExpansionPanel key={index} style={{backgroundColor: '#f5f5f5'}} >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <ListItem dense className={classes.listItemsCSS} >
                          <ListItemText style={{fontSize: '10px'}} primary={client.client_name} />
                          
                          <div edge="end" style={{margin:'0px', padding: '0px'}}>
                            <EditClientModal device_disabled={!isDeviceEnabled} selectedClient={client} refreshClientList={this.refreshClientList} />
                          </div>
                          <div edge="end" style={{margin:'0px', padding: '0px', }}>
                            <DeleteClientModal device_disabled={!isDeviceEnabled} selectedClient={client} refreshClientList={this.refreshClientList} />
                          </div>
                        </ListItem>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Col>
                            <Row>
                              <Typography  variant="subtitle2" style={{color:'#bdbdbd'}}>
                                Client Description:&nbsp;
                              </Typography>
                            </Row>
                            <Row>
                              <Typography variant="subtitle2" >
                                  {client.client_description}
                                </Typography>
                            </Row>
                            <Row>
                              <Typography variant="subtitle2" style={{color:'#bdbdbd'}}>
                                Client ID:&nbsp; 
                              </Typography>
                            </Row>
                            <Row>
                              <Typography variant="subtitle2" >
                                {client.client_id}
                              </Typography>
                            </Row>
                        
                            <Row >
                              <Typography variant="subtitle2" style={{color:'#bdbdbd', }}>
                                Username:&nbsp; 
                              </Typography>
                            </Row>
                            <Row style={{justifyContent: 'flex-start', alignContent: 'flex-start', alignItems: 'flex-start'}}>                          
                              <Typography  variant="subtitle2">
                                {client.username}
                              </Typography>
                            </Row>
                            <Row >
                              <LightTooltip title="Device clients are only authorized to publish and subscribe to the topics starting with. Click to information button to learn more"> 
                              <Typography variant="subtitle2" style={{color:'#bdbdbd', }}>
                                Authorized Topic: 
                              </Typography>
                              </LightTooltip>
                            </Row>
                            <Grid >                          
                              <Typography variant="subtitle2" style={{textAlign: 'left', }}>
                                <code className="topic-link">v1/organizations/{selectedOrganization.organization_uuid}/projects/{selected_device.project_uuid}/#</code>
                              </Typography>
                            </Grid>
                        </Col>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                </List>
              </Paper>
            </div>

            <Divider />

            {/* Data Streams section */}
            <div style={{paddingLeft: '5px', paddingBottom: '10px', paddingTop: '10px'}}>
              <Row style={{margin: '0px', alignItems: 'center', alignContent: 'center'}} >
                <LightTooltip 
                  title="Data Streams allow you to specify the name, description, and unit of the data you are publishing and subscribing to. Example Name: Temperature, Description: Temperature at Machine X, Unit: Celsius"
                  > 
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Device Data Streams 
                  </Typography>
                </LightTooltip>
                <LightTooltip title="Learn more">
                  <IconButton className={classes.errorIconCSS2} onClick={() => window.open('https://docs.iotflows.com/real-time-data-streams-alerts-and-actions/publish-and-subscribe-real-time-messages-with-node-red', "_blank")} >
                    <ErrorOutlineRoundedIcon style={{color: '#9e9e9e', fontSize: 20 }} />
                  </IconButton>
                </LightTooltip>
              </Row>
              
              <div className="tourDataStreamStep" style={{paddingBottom: '10px'}}>
                <CreateDataStreamModal device_uuid={current_device_uuid} device_disabled={!isDeviceEnabled}  refreshDataStreamList={this.refreshDataStreamList} /> 
              </div>
            

              <Paper style={{overflow: 'hidden', padding: '0px',backgroundColor: '#f5f5f5'}} className="cardScrollbar" >
                <List style={{padding: '0px',backgroundColor: '#f5f5f5'}} >
                  
                  {this.state.data_streams_list.map((data_stream, index) => (
                    <ExpansionPanel key={data_stream.data_stream_uuid} style={{backgroundColor: '#f5f5f5'}} >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <ListItem dense className={classes.listItemsCSS} >
                          <ListItemText style={{fontSize: '10px'}} primary={data_stream.data_stream_name} />
                          
                          <div edge="end" style={{margin:'0px', padding: '0px'}}>
                            <EditDataStreamModal device_disabled={!isDeviceEnabled} selectedDataStream={data_stream} device_uuid={current_device_uuid} refreshDataStreamList={this.refreshDataStreamList} />
                          </div>
                          <div edge="end" style={{margin:'0px', padding: '0px', }}>
                            <DeleteDataStreamModal device_disabled={!isDeviceEnabled} selectedDataStream={data_stream} device_uuid={current_device_uuid} refreshDataStreamList={this.refreshDataStreamList} />
                          </div>
                        </ListItem>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Col>
                            
                            <Row>
                              <Typography variant="subtitle2" style={{color:'#bdbdbd'}}>
                                Data Stream Description:&nbsp; 
                              </Typography>
                            </Row>
                            <Row>
                              <Typography variant="subtitle2" >
                                {data_stream.data_stream_description}
                              </Typography>
                            </Row>
                        
                            <Row >
                              <Typography variant="subtitle2" style={{color:'#bdbdbd', }}>
                                Data Stream Units:&nbsp; 
                              </Typography>
                            </Row>
                            <Row style={{justifyContent: 'flex-start', alignContent: 'flex-start', alignItems: 'flex-start'}}>                          
                              <Typography  variant="subtitle2">
                                {data_stream.data_stream_unit}
                              </Typography>
                            </Row>

                            <Row >
                              <Typography variant="subtitle2" style={{color:'#bdbdbd', }}>
                                Data Stream UUID:&nbsp; 
                              </Typography>
                            </Row>
                            <Row style={{justifyContent: 'flex-start', alignContent: 'flex-start', alignItems: 'flex-start'}}>                          
                              <Typography  variant="subtitle2">
                                {data_stream.data_stream_uuid}
                              </Typography>
                            </Row>
                            <Row >
                              <LightTooltip title="MQTT topic is used to publish or subscribe to this Data Stream"> 
                              <Typography variant="subtitle2" style={{color:'#bdbdbd', }}>
                                MQTT Topic: 
                              </Typography>
                              </LightTooltip>
                            </Row>
                            <Grid >                          
                              <Typography variant="subtitle2" style={{textAlign: 'left', }}>
                                <code className="topic-link">v1/organizations/{selectedOrganization.organization_uuid}/projects/{selected_device.project_uuid}/devices/{current_device_uuid}/data-streams/{data_stream.data_stream_uuid}</code>
                              </Typography>
                            </Grid>
                        </Col>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                </List>
              </Paper>
            </div>

            <Divider />

            {/* Actions section */}
            <div style={{paddingLeft: '5px', paddingBottom: '10px', paddingTop: '10px'}}>
              <Row style={{margin: '0px', alignItems: 'center', alignContent: 'center'}} >
                <LightTooltip 
                  title="Actions allow you to execute functions on IoT devices e.g. control an actuator"
                  > 
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Device Actions
                  </Typography>
                </LightTooltip>
                <LightTooltip title="Learn more">
                  <IconButton className={classes.errorIconCSS2} onClick={() => window.open('https://docs.iotflows.com/real-time-data-streams-alerts-and-actions/define-and-execute-an-action-in-node-red', "_blank")} >
                    <ErrorOutlineRoundedIcon style={{color: '#9e9e9e', fontSize: 20 }} />
                  </IconButton>
                </LightTooltip>
              </Row>
              
              <div className="tourActionStep" style={{paddingBottom: '10px'}}>
                <CreateActionModal device_uuid={current_device_uuid} device_disabled={!isDeviceEnabled} refreshActionsList={this.refreshActionsList} /> 
              </div>
            

              <Paper style={{overflow: 'hidden', padding: '0px',backgroundColor: '#f5f5f5'}} className="cardScrollbar" >
                <List style={{padding: '0px',backgroundColor: '#f5f5f5'}} >
                  
                  {this.state.actions_list.map((action, index) => (
                    <ExpansionPanel key={action.action_uuid} style={{backgroundColor: '#f5f5f5'}} >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <ListItem dense className={classes.listItemsCSS}  >
                          <div style={{textAlign:'left', display:'flex'}}>
                            <ListItemText style={{fontSize: '10px'}} primary={action.action_name} />
                          </div>
                          <div style={{ display:'flex', marginLeft:'auto'}}>

                            <div >
                              <LightTooltip title="Execute Action"> 
                                <button onClick={() => this.handleExecuteAction(action)} className={classes.executeButton} >
                                  <svg fill="#2563EB" xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M13.54 22H10C9.75 22 9.54 21.82 9.5 21.58L9.13 18.93C8.5 18.68 7.96 18.34 7.44 17.94L4.95 18.95C4.73 19.03 4.46 18.95 4.34 18.73L2.34 15.27C2.21 15.05 2.27 14.78 2.46 14.63L4.57 12.97L4.5 12L4.57 11L2.46 9.37C2.27 9.22 2.21 8.95 2.34 8.73L4.34 5.27C4.46 5.05 4.73 4.96 4.95 5.05L7.44 6.05C7.96 5.66 8.5 5.32 9.13 5.07L9.5 2.42C9.54 2.18 9.75 2 10 2H14C14.25 2 14.46 2.18 14.5 2.42L14.87 5.07C15.5 5.32 16.04 5.66 16.56 6.05L19.05 5.05C19.27 4.96 19.54 5.05 19.66 5.27L21.66 8.73C21.79 8.95 21.73 9.22 21.54 9.37L19.43 11L19.5 12V12.19C19 12.07 18.5 12 18 12C17.83 12 17.66 12 17.5 12.03C17.5 11.41 17.4 10.79 17.2 10.2L19.31 8.65L18.56 7.35L16.15 8.39C15.38 7.5 14.32 6.86 13.12 6.62L12.75 4H11.25L10.88 6.61C9.68 6.86 8.62 7.5 7.85 8.39L5.44 7.35L4.69 8.65L6.8 10.2C6.4 11.37 6.4 12.64 6.8 13.8L4.68 15.36L5.43 16.66L7.86 15.62C8.63 16.5 9.68 17.14 10.87 17.38L11.24 20H12.35C12.61 20.75 13 21.42 13.54 22M15.96 12.36C16 12.24 16 12.12 16 12C16 9.79 14.21 8 12 8S8 9.79 8 12 9.79 16 12 16C12.12 16 12.24 16 12.36 15.96C12.97 14.29 14.29 12.97 15.96 12.36M12 14C10.9 14 10 13.11 10 12S10.9 10 12 10 14 10.9 14 12 13.11 14 12 14M16 15V21L21 18L16 15Z"/>
                                  </svg>
                                </button>  
                              </LightTooltip>                          
                            </div>
                            <div edge="end" style={{margin:'0px', padding: '0px'}}>
                              <EditActionModal device_disabled={!isDeviceEnabled} selectedAction={action} device_uuid={current_device_uuid} refreshActionsList={this.refreshActionsList} />
                            </div>
                            <div edge="end" style={{margin:'0px', padding: '0px', }}>
                              <DeleteActionModal device_disabled={!isDeviceEnabled} selectedAction={action} device_uuid={current_device_uuid} refreshActionsList={this.refreshActionsList} />
                            </div>
                          </div>
                        </ListItem>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Col>
                            
                            <Row>
                              <Typography variant="subtitle2" style={{color:'#bdbdbd'}}>
                                Action Description:&nbsp; 
                              </Typography>
                            </Row>
                            <Row>
                              <Typography variant="subtitle2" >
                                {action.action_description}
                              </Typography>
                            </Row>

                            <Row >
                              <Typography variant="subtitle2" style={{color:'#bdbdbd', }}>
                                Action UUID:&nbsp; 
                              </Typography>
                            </Row>
                            <Row style={{justifyContent: 'flex-start', alignContent: 'flex-start', alignItems: 'flex-start'}}>                          
                              <Typography  variant="subtitle2">
                                {action.action_uuid}
                              </Typography>
                            </Row>
                            <Row >
                              <LightTooltip title="MQTT topic is used to publish or subscribe to this Data Stream"> 
                              <Typography variant="subtitle2" style={{color:'#bdbdbd', }}>
                                MQTT Topic: 
                              </Typography>
                              </LightTooltip>
                            </Row>
                            <Grid >                          
                              <Typography variant="subtitle2" style={{textAlign: 'left', }}>
                                <code className="topic-link">v1/organizations/{selectedOrganization.organization_uuid}/projects/{selected_device.project_uuid}/devices/{current_device_uuid}/actions/{action.action_uuid}</code>
                              </Typography>
                            </Grid>
                        </Col>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                </List>
              </Paper>
            </div>

        </div>
        
        
      </Drawer>
      {openExecuteActionModal && <ExecuteActionModal open={openExecuteActionModal} actionSelected={actionSelected} handleCloseModal={this.handleCloseModal} />}
    </div>
    );
  } 
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})

const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    currentUserInfo: state.users.currentUserInfo,

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,{ withTheme: true })(SelectedDevicePage))

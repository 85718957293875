export const CountryCodeApi = 
{  US: "(US) United States of America",
   IE: "(IE) Ireland ",
   IL: "(IL) Israel",
   IM: "(IM) Isle of Man",
   IN: "(IN) India",
   IO: "(IO) British Indian Ocean Territory",
   IQ: "(IQ) Iraq",
   IR: "(IR) Iran (Islamic Republic of)",
   IS: "(IS) Iceland",
   IT: "(IT) Italy",
   JE: "(JE) Jersey",
   JM: "(JM) Jamaica",
   JO: "(JO) Jordan",
   JP: "(JP) Japan",
   KE: "(KE) Kenya",
   KG: "(KG) Kyrgyzstan",
   KH: "(KH) Cambodia",
   KI: "(KI) Kiribati",
   KM: "(KM) Comoros",
   KN: "(KN) Saint Kitts and Nevis",
   KP: "(KP) Korea (Democratic People's Republic of)",
   KR: "(KR) Korea, Republic of",
   KW: "(KW) Kuwait",
   KY: "(KY) Cayman Islands",
   KZ: "(KZ) Kazakhstan",
   LA: "(LA) Lao People's Democratic Republic",
   LB: "(LB) Lebanon",
   LC: "(LC) Saint Lucia",
   LI: "(LI) Liechtenstein",
   LK: "(LK) Sri Lanka",
   LR: "(LR) Liberia",
   LS: "(LS) Lesotho",
   LT: "(LT) Lithuania",
   LU: "(LU) Luxembourg",
   LV: "(LV) Latvia",
   LY: "(LY) Libya",
   MA: "(MA) Morocco",
   MC: "(MC) Monaco",
   MD: "(MD) Moldova, Republic of",
   ME: "(ME) Montenegro",
   MF: "(MF) Saint Martin (French part)",
   MG: "(MG) Madagascar",
   MH: "(MH) Marshall Islands",
   MK: "(MK) North Macedonia",
   ML: "(ML) Mali",
   MM: "(MM) Myanmar",
   MN: "(MN) Mongolia",
   MO: "(MO) Macao",
   MP: "(MP) Northern Mariana Islands",
   MQ: "(MQ) Martinique",
   MR: "(MR) Mauritania",
   MS: "(MS) Montserrat",
   MT: "(MT) Malta",
   MU: "(MU) Mauritius",
   MV: "(MV) Maldives",
   MW: "(MW) Malawi",
   MX: "(MX) Mexico",
   MY: "(MY) Malaysia",
   MZ: "(MZ) Mozambique",
   NA: "(NA) Namibia",
   NC: "(NC) New Caledonia",
   NE: "(NE) Niger",
   NF: "(NF) Norfolk Island",
   NG: "(NG) Nigeria",
   NI: "(NI) Nicaragua",
   NL: "(NL) Netherlands",
   NO: "(NO) Norway",
   NP: "(NP) Nepal",
   NR: "(NR) Nauru",
   NU: "(NU) Niue",
   NZ: "(NZ) New Zealand",
   OM: "(OM) Oman",
   PA: "(PA) Panama",
   PE: "(PE) Peru",
   PF: "(PF) French Polynesia",
   PG: "(PG) Papua New Guinea",
   PH: "(PH) Philippines",
   PK: "(PK) Pakistan",
   PL: "(PL) Poland",
   PM: "(PM) Saint Pierre and Miquelon",
   PN: "(PN) Pitcairn",
   PR: "(PR) Puerto Rico",
   PS: "(PS) Palestine, State of",
   PT: "(PT) Portugal",
   PW: "(PW) Palau",
   PY: "(PY) Paraguay",
   QA: "(QA) Qatar",
   RE: "(RE) Réunion",
   RO: "(RO) Romania",
   RS: "(RS) Serbia",
   RU: "(RU) Russian Federation",
   RW: "(RW) Rwanda",
   SA: "(SA) Saudi Arabia",
   SB: "(SB) Solomon Islands",
   SC: "(SC) Seychelles",
   SD: "(SD) Sudan",
   SE: "(SE) Sweden",
   SG: "(SG) Singapore",
   SH: "(SH) Saint Helena, Ascension and Tristan da Cunha",
   SI: "(SI) Slovenia",
   SJ: "(SJ) Svalbard and Jan Mayen",
   SK: "(SK) Slovakia",
   SL: "(SL) Sierra Leone",
   SM: "(SM) San Marino",
   SN: "(SN) Senegal",
   SO: "(SO) Somalia",
   SR: "(SR) Suriname",
   SS: "(SS) South Sudan",
   ST: "(ST) Sao Tome and Principe",
   SV: "(SV) El Salvador",
   SX: "(SX) Sint Maarten (Dutch part)",
   SY: "(SY) Syrian Arab Republic",
   SZ: "(SZ) Eswatini",
   TC: "(TC) Turks and Caicos Islands",
   TD: "(TD) Chad",
   TF: "(TF) French Southern Territories",
   TG: "(TG) Togo",
   TH: "(TH) Thailand",
   TJ: "(TJ) Tajikistan",
   TK: "(TK) Tokelau",
   TL: "(TL) Timor-Leste",
   TM: "(TM) Turkmenistan",
   TN: "(TN) Tunisia",
   TO: "(TO) Tonga",
   TR: "(TR) Turkey",
   TT: "(TT) Trinidad and Tobago",
   TV: "(TV) Tuvalu",
   TW: "(TW) Taiwan, Province of China",
   TZ: "(TZ) Tanzania, United Republic of",
   UA: "(UA) Ukraine",
   UG: "(UG) Uganda",
   UM: "(UM) United States Minor Outlying Islands",
   US: "(US) United States of America",
   UY: "(UY) Uruguay",
   UZ: "(UZ) Uzbekistan",
   VA: "(VA) Holy See",
   VC: "(VC) Saint Vincent and the Grenadines",
   VE: "(VE) Venezuela (Bolivarian Republic of)",
   VG: "(VG) Virgin Islands (British)",
   VI: "(VI) Virgin Islands (U.S.)",
   VN: "(VN) Viet Nam",
   VU: "(VU) Vanuatu",
   WF: "(WF) Wallis and Futuna",
   WS: "(WS) Samoa",
   YE: "(YE) Yemen",
   YT: "(YT) Mayotte",
   ZA: "(ZA) South Africa",
   ZM: "(ZM) Zambia",
   ZW: "(ZW) Zimbabwe",
}
  
//   { 1: "(US) United States of America",
//     2: "(IE) Ireland ",
//     3: "(IL) Israel",
//     4: "(IM) Isle of Man",
//     5: "(IN) India",
//     6: "(IO) British Indian Ocean Territory",
//     7: "(IQ) Iraq",
//     8: "(IR) Iran (Islamic Republic of)",
//     9: "(IS) Iceland",
//     10: "(IT) Italy",
//     11: "(JE) Jersey",
//     12: "(JM) Jamaica",
//     13: "(JO) Jordan",
//     14: "(JP) Japan",
//     15: "(KE) Kenya",
//     16: "(KG) Kyrgyzstan",
//     17: "(KH) Cambodia",
//     18: "(KI) Kiribati",
//     19: "(KM) Comoros",
//     20: "(KN) Saint Kitts and Nevis",
//     21: "(KP) Korea (Democratic People's Republic of)",
//     22: "(KR) Korea, Republic of",
//     23: "(KW) Kuwait",
//     24: "(KY) Cayman Islands",
//     25: "(KZ) Kazakhstan",
//     26: "(LA) Lao People's Democratic Republic",
//     27: "(LB) Lebanon",
//     28: "(LC) Saint Lucia",
//     29: "(LI) Liechtenstein",
//     30: "(LK) Sri Lanka",
//     31: "(LR) Liberia",
//     32: "(LS) Lesotho",
//     33: "(LT) Lithuania",
//     34: "(LU) Luxembourg",
//     35: "(LV) Latvia",
//     36: "(LY) Libya",
//     37: "(MA) Morocco",
//     38: "(MC) Monaco",
//     39: "(MD) Moldova, Republic of",
//     40: "(ME) Montenegro",
//     41: "(MF) Saint Martin (French part)",
//     42: "(MG) Madagascar",
//     43: "(MH) Marshall Islands",
//     44: "(MK) North Macedonia",
//     45: "(ML) Mali",
//     46: "(MM) Myanmar",
//     47: "(MN) Mongolia",
//     48: "(MO) Macao",
//     49: "(MP) Northern Mariana Islands",
//     50: "(MQ) Martinique",
//     51: "(MR) Mauritania",
//     52: "(MS) Montserrat",
//     53: "(MT) Malta",
//     54: "(MU) Mauritius",
//     55: "(MV) Maldives",
//     56: "(MW) Malawi",
//     57: "(MX) Mexico",
//     58: "(MY) Malaysia",
//     59: "(MZ) Mozambique",
//     60: "(NA) Namibia",
//     61: "(NC) New Caledonia",
//     62: "(NE) Niger",
//     63: "(NF) Norfolk Island",
//     64: "(NG) Nigeria",
//     65: "(NI) Nicaragua",
//     66: "(NL) Netherlands",
//     67: "(NO) Norway",
//     68: "(NP) Nepal",
//     69: "(NR) Nauru",
//     70: "(NU) Niue",
//     71: "(NZ) New Zealand",
//     72: "(OM) Oman",
//     73: "(PA) Panama",
//     74: "(PE) Peru",
//     75: "(PF) French Polynesia",
//     76: "(PG) Papua New Guinea",
//     77: "(PH) Philippines",
//     78: "(PK) Pakistan",
//     79: "(PL) Poland",
//     80: "(PM) Saint Pierre and Miquelon",
//     81: "(PN) Pitcairn",
//     82: "(PR) Puerto Rico",
//     83: "(PS) Palestine, State of",
//     84: "(PT) Portugal",
//     85: "(PW) Palau",
//     86: "(PY) Paraguay",
//     87: "(QA) Qatar",
//     88: "(RE) Réunion",
//     89: "(RO) Romania",
//     90: "(RS) Serbia",
//     91: "(RU) Russian Federation",
//     92: "(RW) Rwanda",
//     93: "(SA) Saudi Arabia",
//     94: "(SB) Solomon Islands",
//     95: "(SC) Seychelles",
//     96: "(SD) Sudan",
//     97: "(SE) Sweden",
//     98: "(SG) Singapore",
//     99: "(SH) Saint Helena, Ascension and Tristan da Cunha",
//     100: "(SI) Slovenia",
//     101: "(SJ) Svalbard and Jan Mayen",
//     102: "(SK) Slovakia",
//     103: "(SL) Sierra Leone",
//     104: "(SM) San Marino",
//     105: "(SN) Senegal",
//     106: "(SO) Somalia",
//     107: "(SR) Suriname",
//     108: "(SS) South Sudan",
//     109: "(ST) Sao Tome and Principe",
//     110: "(SV) El Salvador",
//     111: "(SX) Sint Maarten (Dutch part)",
//     112: "(SY) Syrian Arab Republic",
//     113: "(SZ) Eswatini",
//     114: "(TC) Turks and Caicos Islands",
//     115: "(TD) Chad",
//     116: "(TF) French Southern Territories",
//     117: "(TG) Togo",
//     118: "(TH) Thailand",
//     119: "(TJ) Tajikistan",
//     120: "(TK) Tokelau",
//     121: "(TL) Timor-Leste",
//     122: "(TM) Turkmenistan",
//     123: "(TN) Tunisia",
//     124: "(TO) Tonga",
//     125: "(TR) Turkey",
//     126: "(TT) Trinidad and Tobago",
//     127: "(TV) Tuvalu",
//     128: "(TW) Taiwan, Province of China",
//     129: "(TZ) Tanzania, United Republic of",
//     130: "(UA) Ukraine",
//     131: "(UG) Uganda",
//     132: "(UM) United States Minor Outlying Islands",
//     133: "(US) United States of America",
//     134: "(UY) Uruguay",
//     135: "(UZ) Uzbekistan",
//     136: "(VA) Holy See",
//     137: "(VC) Saint Vincent and the Grenadines",
//     138: "(VE) Venezuela (Bolivarian Republic of)",
//     139: "(VG) Virgin Islands (British)",
//     140: "(VI) Virgin Islands (U.S.)",
//     141: "(VN) Viet Nam",
//     142: "(VU) Vanuatu",
//     143: "(WF) Wallis and Futuna",
//     144: "(WS) Samoa",
//     145: "(YE) Yemen",
//     146: "(YT) Mayotte",
//     147: "(ZA) South Africa",
//     148: "(ZM) Zambia",
//     149: "(ZW) Zimbabwe",
// }
    
    


  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  



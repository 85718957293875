import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import ReactJson from 'react-json-view';
import CsvTable from './CsvTable'
import XMLViewer from 'react-xml-viewer'


const styles = theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    // width: theme.spacing(200),
    maxWidth: '1400px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    // [theme.breakpoints.down('lg')]:{
    //   width: theme.spacing(200),
    //   maxWidth: '850px',
    // },
    // [theme.breakpoints.down('md')]:{
    //   width: theme.spacing(80),
    // },
    // [theme.breakpoints.down('sm')]:{
    //   width: theme.spacing(43),
    // },
    // [theme.breakpoints.down('xs')]:{
    //   width: theme.spacing(43),
    // },
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    minWidth: '80px',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]:{
      padding:'2px 6px',
      fontSize:'12px',
      minWidth: '50px',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { 
    borderRadius: 10,
    maxWidth: '1500px' ,
  },
  buttonContainer: {
    padding: '12px',
    [theme.breakpoints.down('sm')]:{
      padding: '4px',
    },
  },
  infoContainer: {
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '0.5rem',
    borderRadius: '5px',
    marginBottom: '12px',
    overflow: 'auto',
  },
  lineInfo: {
    width: '100%',
    display: 'flex',
    padding: '4px 2px',
  },
  mainText: {
    fontSize: '12px',
    color: '#6B7280',
  },
  subText: {
    fontSize: '12px',
    color: '#374151',
  }

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

var convert = require('xml-js');

class DeviceDataModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDeviceDataModal: this.props.openDeviceDataModal,
      deviceDataRow: this.props.deviceDataRow,
      dataType: 'xml1',
      arr: [],
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }


  // componentDidMount() {

  //   var jsonObject;
  // if (typeof this.props.deviceDataRow === 'string'){
  //   jsonObject = JSON.parse(this.props.deviceDataRow);
  //   console.log("isString")
  // }else{
  //   jsonObject = this.props.deviceDataRow;
  //   console.log("NOT String")
  // }

  // // Loop 1
  // Object.entries(jsonObject).map((mapValue, index) => {
  //   console.log("mapValue "+index,mapValue)
  //   console.log("mapValue[index] "+index, mapValue[index])

  //   // Loop 2
  //   Object.entries(mapValue).map((value1, index) => {
  //     console.log('value1 '+index , value1)
  //     console.log('value1[index] '+index , value1[index])

  //     // Loop 3
  //     Object.entries(value1).map((value2, index) => {
  //       console.log('value2 '+index, value2)
  //       console.log('value2[index] '+index, value2[index])
  //     })

  //   })
  // })


    
  //   // console.log('0', this.props.deviceDataRow)
  //   // console.log('1', Object.keys(this.props.deviceDataRow).length)
  //   // console.log('2', this.props.deviceDataRow.length)
  //   // console.log('pos 50', this.props.deviceDataRow[50])
  //   // var arr =[];
  //   // var parsedData = JSON.parse(this.props.deviceDataRow)
  //   // console.log('3', Object.keys(parsedData).length)
  //   // console.log('4', parsedData.length)
  //   // console.log('pos 5', parsedData[5])
  //   // // Object.keys(parsedData).forEach(this.jsonArray());
  //   // Object.keys(parsedData).forEach(function(key) {
  //   //   arr.push(parsedData[key]);
  //   //   console.log(key, arr)
  //   // });
  //   // this.setState({arr: arr})
  //   // console.log(this.state.arr)
  // }

  // jsonArray = (item, key) => {
  //   console.log('item,key')
  //   console.log(item,key)
  //   var arr = this.state.arr;
  //   arr.push(this.props.deviceDataRow[key]);
  //   console.log('key, arr')
  //   console.log(key, arr)
  // }

  componentWillReceiveProps(nextProps) {
    this.setState({
      openDeviceDataModal: nextProps.openDeviceDataModal,
      deviceDataRow: nextProps.deviceDataRow,
    }); 
  };

  handleOpen = () => {
    this.setState({ 
      openDeviceDataModal: true,
    });
  };

  handleClose = () => {
    this.props.handleCloseDeviceDataModal();
    this.setState({ 
      openDeviceDataModal: false,
      dataType: 'xml1',
    });
  };

  cutTopic = (topic) => {
    try{
      if(topic){
        var topicSliced = topic.split('/');
        // var subTopicStart = topicSliced[topicSliced.indexOf('data-streams')+2];
        var subTopicStartIndex = topicSliced.indexOf('data-streams')+2;
        if(topicSliced[subTopicStartIndex]){
          var subTopic = topicSliced.slice(subTopicStartIndex).join('/') 
          return subTopic
        }else {
          // console.log('Not valid topic')
          return ""
        }
      }
    }
    catch(e){
      console.log('Catch')
      console.log(e)
      return ""
    }
  }

  
  dataXMLFormatter1 = (deviceDataRow) => {
    try{

      let result2 = convert.xml2js(deviceDataRow, {compact: true});

      return <ReactJson src={result2}/> 
    }
    catch(e){
      this.setState({dataType: 'csv1'})
    }
  }
  dataXMLFormatter = (deviceDataRow) => {
    try{

      let result2 = convert.xml2js(deviceDataRow, {compact: true});

      return <ReactJson src={result2}/> 
    }
    catch(e){
      console.log('error converting to XML')
      // console.log(e)
      this.setState({dataType: 'raw'})
      this.props.showErrorSnackbar('Data could not be converted to XML')
      return deviceDataRow
    }
  }

  dataCSVFormatter1 = (deviceDataRow) => {
    try{
      let lines = deviceDataRow.split('\n');
      let headers = lines[0].split(',');
      let first_row = lines[1].split(',');
      

      var data = [];
      for(var i = 1; i < lines.length; i++){
          let row = lines[i].split(',');
          data.push(row)
      }
      

      return  <CsvTable headers={headers} data={data} selectedDataRow={this.props.selectedDataRow}/> 
    }
    catch(e){
      this.setState({dataType: 'json1'})
    }
  }

  dataCSVFormatter = (deviceDataRow) => {
    try{
      let lines = deviceDataRow.split('\n');
      let headers = lines[0].split(',');
      let first_row = lines[1].split(',');
      

      var data = [];
      for(var i = 1; i < lines.length; i++){
          let row = lines[i].split(',');
          data.push(row)
      }
      

      return  <CsvTable headers={headers} data={data} selectedDataRow={this.props.selectedDataRow}/> 
    }
    catch(e){
      console.log('error converting to CSV')
      // console.log(e)
      this.setState({dataType: 'raw'})
      this.props.showErrorSnackbar('Data could not be converted to CSV')
      return deviceDataRow
    }
  }

  dataJSONFormater1 = (deviceDataRow) => {
    try{
      var parsedDeviceData = JSON.parse(deviceDataRow)

      if(typeof parsedDeviceData == "object"){
        return <ReactJson src={parsedDeviceData} enableClipboard={true} /> 
      }else {

        this.setState({dataType: 'raw'})
      }
    }
    catch(e){
      this.setState({dataType: 'raw'})
    }
  }

  dataJSONFormater = (deviceDataRow) => {
    try{
      var parsedDeviceData = JSON.parse(deviceDataRow)

      if(typeof parsedDeviceData == "object"){
          return <ReactJson src={parsedDeviceData} enableClipboard={true} /> 
      }else {
        this.setState({dataType: 'raw'})
        this.props.showErrorSnackbar('Data could not be converted to JSON')
        }
    }
    catch(e){
      this.setState({dataType: 'raw'})
      this.props.showErrorSnackbar('Data could not be converted to JSON')
      return deviceDataRow
    }
  }

  downloadDataButtonClick = (deviceDataRow) => {
    
    if(this.state.dataType == 'csv1' || this.state.dataType == 'csv'){

      // console.log("Inside download CSV file")
      
      let lines = deviceDataRow.split('\n');
      let headers = lines[0].split(',');
      let first_row = lines[1].split(',');
      

      var data = [];
      for(var i = 1; i < lines.length; i++){
          let row = lines[i].split(',');
          data.push(row)
      }


      var csv = headers+'\n';
      data.map(row => (
        csv += row+'\n'
      ))
  
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = this.props.selectedDataRow.data_stream_name+'_data.csv';
      hiddenElement.click();

    } else if (this.state.dataType == 'json1' || this.state.dataType == 'json'){
      let jsonData = JSON.parse(deviceDataRow);
      // console.log("Inside download JSON file")
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/json;charset=utf-8,' + encodeURI(JSON.stringify(jsonData, null, 4));
      hiddenElement.target = '_blank';
      hiddenElement.download = this.props.selectedDataRow.data_stream_name+'_data.json';
      hiddenElement.click();

    } else if (this.state.dataType == 'xml1' || this.state.dataType == 'xml'){
      let result2 = convert.xml2js(deviceDataRow, {compact: true});
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/json;charset=utf-8,' + encodeURI(JSON.stringify(result2, null, 4));
      hiddenElement.target = '_blank';
      hiddenElement.download = this.props.selectedDataRow.data_stream_name+'_xml_json_data.json';
      hiddenElement.click();

    } else {
      // console.log("Inside download txt file")
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/plain;charset=utf-8,' + encodeURI(deviceDataRow);
      hiddenElement.target = '_blank';
      hiddenElement.download = this.props.selectedDataRow.data_stream_name+'_data.txt';
      hiddenElement.click();
    }
    
    
  };



  render() {
    const { classes } = this.props;
    const { openDeviceDataModal,
            deviceDataRow,
            dataType ,
          } = this.state;


    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
      >
        
        {/* <Button className={classes.button} variant="contained"   onClick={this.handleopenDeviceDataModal}><AddCircleOutlineOutlinedIcon />&nbsp;Remote Connection </Button> */}

          <Dialog
              open={openDeviceDataModal}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">{this.props.title}</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px'}}>
                  <div className={classes.infoContainer}>
                    <div className={classes.lineInfo}>
                      <div>
                        <span className={classes.mainText}>Data Stream Name:</span> &nbsp;<span className={classes.subText}>{this.props.selectedDataRow.data_stream_name}</span>
                      </div>
                    </div>
                    <div className={classes.lineInfo}>
                      <div>
                        <span className={classes.mainText}>Client Name:</span> &nbsp;<span className={classes.subText}>{this.props.selectedDataRow.data_stream_name}</span>
                      </div>
                    </div>
                    <div className={classes.lineInfo}>
                      <div>
                        <span className={classes.mainText}>Received At:</span> &nbsp;<span className={classes.subText}>{this.props.selectedDataRow.received_at}</span>
                      </div>
                    </div>
                    <div className={classes.lineInfo}>
                      <div>
                        <span className={classes.mainText}>Topic:</span> &nbsp;<span className={classes.subText}>{this.props.selectedDataRow.topic}</span>
                      </div>
                    </div>
                    <div className={classes.lineInfo}>
                      <div>
                        <span className={classes.mainText}>SubTopic:</span> &nbsp;<span className={classes.subText}>{this.cutTopic(this.props.selectedDataRow.topic)}</span>
                      </div>
                    </div>
                    <div className={classes.lineInfo}>
                      <div>
                        <span className={classes.mainText}>Size:</span> &nbsp;<span className={classes.subText}>{this.props.selectedDataRow.data_stream_size}</span>
                      </div>
                    </div>
                  </div>
                  <div style={{display:'flex', justifyContent: 'flex-end', overflowX: 'scroll'}}>
                    <div className={classes.buttonContainer}>
                      <Button  className={classes.button}  onClick={() => this.setState({dataType: 'raw'})} >
                        Raw 
                      </Button>
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button  className={classes.button}  onClick={() => this.setState({dataType: 'json'})} >
                        JSON 
                      </Button>
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button  className={classes.button}  onClick={() => this.setState({dataType: 'csv'})} >
                        CSV 
                      </Button>
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button  className={classes.button}  onClick={() => this.setState({dataType: 'xml'})} >
                        XML 
                      </Button>
                    </div>
                  </div>
                  <div style={{overflowY:'auto'}}>
                    {dataType == 'xml1'? this.dataXMLFormatter1(deviceDataRow) : null}
                    {dataType == 'csv1'? this.dataCSVFormatter1(deviceDataRow) : null}
                    {dataType == 'json1'? this.dataJSONFormater1(deviceDataRow) : null}
                    {dataType == 'raw'? deviceDataRow : null}
                    {dataType == 'json'? this.dataJSONFormater(deviceDataRow) : null}
                    {dataType == 'csv'? this.dataCSVFormatter(deviceDataRow) : null}
                    {dataType == 'xml'? this.dataXMLFormatter(deviceDataRow) : null}
                  </div>
                </DialogContent>
                <DialogActions style={{justifyContent:'flex-start'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  style={{marginLeft:'auto'}} color="primary" onClick={() => this.downloadDataButtonClick(deviceDataRow)}>
                    {'Download'}
                      {dataType == 'xml1'? ' xml2json.json file': null}
                      {dataType == 'csv1'? '.csv file': null}
                      {dataType == 'json1'? '.json file': null}
                      {dataType == 'raw'? '.txt file' : null}
                      {dataType == 'json'? '.json file' : null}
                      {dataType == 'csv'? '.csv file': null}
                      {dataType == 'xml'? 'xml2json.json file': null}
                    
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

DeviceDataModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(DeviceDataModal));






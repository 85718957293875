import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import "../styles/ButtonStyles.scss";
import { connect} from "react-redux";
import {fetchCurrentUser} from '../store/actions/userActions'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import {BrowserRouter as Router,Redirect, withRouter, Route, Switch} from "react-router-dom";
import {fetchOrganizations, fetchSelectedOrganization} from '../store/actions/organizationActions';
import DeleteOrganizationModal from '../components/DeleteOrganizationModal';
import EditOrganizationModal from '../components/EditOrganizationModal';
import AddOrganizationModal from '../components/AddOrganizationModal';
import LeaveOrganizationModal from '../components/LeaveOrganizationModal';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import {getData} from '../ApiCalls/DataApis';



const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2196f3",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 16,
    border: '1px solid #fafafa',
    // arrow: {
    //   backgroundColor: '#fafafa',
    //   color: '#2196f3',
    // },
    
  },
  arrow: {
    // backgroundColor: '#fafafa',
    // border: '1px solid #fafafa',
    color: '#2196f3',
  },
}))(Tooltip);


const styles = theme => ({
  settingBtnCSS: {
    backgroundColor:'transparent',
    borderRadius:'5px',
    border:'0px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#9e9e9e',
    fontFamily:'Trebuchet MS',
    textDecoration:'none',
    outline: 'none',
    textShadow:'0px 0px 0px #9e9e9e',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  hoverText: {    
    fontSize: 20,
    paddingLeft: 0,
  },
});

class OrganizationButtons extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      userAnchorEl: null,
      redirect: false,
      userOpen: false,
      addOrganizationModalOpen: false,
      editOrganizationModalOpen: false,
      leaveOrganizationModalOpen: false,
      deleteOrganizationModalOpen: false,
      toPath: '/projects',
      user_cards: [],

    };
    this.userHandleClick = this.userHandleClick.bind(this);
    this.userHandleClose = this.userHandleClose.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

  }

  componentDidMount() {
    
    // console.log("component did mount")
    // this.props.fetchCurrentUser();
    this.props.fetchOrganizations();

  } 

  componentDidUpdate(prevProps, prevState) {

    // Updating the name and description whenever user edits it all throughout platform (i.e breadcrumbs)
    if(prevProps.allOrganizations != this.props.allOrganizations){
      this.props.allOrganizations.map((organization, index) =>(
        organization.organization_uuid == this.props.selectedOrganization.organization_uuid ? this.props.fetchSelectedOrganization(this.props.allOrganizations[index]) : null
      ))
    }
    
    if(prevState.redirect === true){
      this.setState({redirect: false})
    }
  }


  userHandleClick = event => {
    this.setState({ userAnchorEl: event.currentTarget  });
    this.setState({ userOpen: true });
    this.setState({ addOrganizationModalOpen: false });
    this.setState({ editOrganizationModalOpen: false });
    this.setState({ leaveOrganizationModalOpen: false });
    this.setState({ deleteOrganizationModalOpen: false });

  };

  handleAddOrganizationModalOpen = () => {
    this.setState({ userAnchorEl: null });
    this.setState({ userOpen: false });
    this.setState({ addOrganizationModalOpen: true });

  };

  handleEditOrganizationModalOpen = () => {
    this.setState({ userAnchorEl: null });
    this.setState({ userOpen: false });
    this.setState({ editOrganizationModalOpen: true });

  };

  handleLeaveOrganizationModalOpen = () => {
    this.setState({ userAnchorEl: null });
    this.setState({ userOpen: false });
    this.setState({ leaveOrganizationModalOpen: true });
  };

  handleDeleteOrganizationModalOpen = () => {
    this.setState({ userAnchorEl: null });
    this.setState({ userOpen: false });
    this.setState({ deleteOrganizationModalOpen: true });

  };

  userHandleClose = () => {
    this.setState({ userAnchorEl: null });
    this.setState({ userOpen: false,
      addOrganizationModalOpen: false,
      editOrganizationModalOpen: false,
      leaveOrganizationModalOpen: false ,
      deleteOrganizationModalOpen: false});
    };

  handleOrganizationChange = e => {
    this.setState({ addOrganizationModalOpen: false });
    this.setState({ editOrganizationModalOpen: false });
    this.setState({ leaveOrganizationModalOpen: false });
    this.setState({ deleteOrganizationModalOpen: false });
    this.setState({redirect: false});
    console.log(this.props.allOrganizations[0])

    this.props.allOrganizations.map((organization, index) =>(
      organization.organization_uuid == e.target.value ? this.props.fetchSelectedOrganization(this.props.allOrganizations[index]) :null
    ))

    // console.log('Organization change this.props.selectedOrganization')
    // console.log(this.props.selectedOrganization)
    // if(this.props.selectedOrganization.organization_is_suspended){
    //   console.log('organization is SUSPENDED')
    //   this.setState({toPath: '/billing/wallet'}, () => {this.setState({redirect: true})} );
    // } else {
    //     getData('https://api.iotflows.com/v1/organizations/' + e.target.value + '/payment_methods/card')
    //     .then(async response => {
    //       if(response.ok ){
    //         const data = await response.json();
    //         this.setState({
    //           user_cards: data.data,
    //         },() => {
    //           if (this.state.user_cards == '' && !this.props.selectedOrganization.organization_is_personal) {
    //             this.setState({toPath: '/billing/wallet'}, () => {this.setState({redirect: true})} );
    //           } else {
    //             this.setState({toPath: '/projects'}, () => {this.setState({redirect: true})} );
    //           }
    //         });
    //       }
    //     });
    // }
  }

  handleCloseModal(){
    this.setState({ 
      addOrganizationModalOpen: false,
      editOrganizationModalOpen: false,
      leaveOrganizationModalOpen: false,
      deleteOrganizationModalOpen: false,
    });
  }

  


  render() {
    const { classes, currentUserInfo, selectedOrganization, allOrganizations, history} = this.props
    const { userAnchorEl, userOpen, addOrganizationModalOpen, editOrganizationModalOpen, leaveOrganizationModalOpen, deleteOrganizationModalOpen, toPath} = this.state
    // const userOpen = Boolean(userAnchorEl);

    return(
      <div>
      {this.state.redirect ? <Redirect push exact to={toPath} /> : null}
      <ButtonGroup style={{justifyContent: 'center', alignItems:'center', alignContent:'center'}}>
        <LightTooltip  
          className={classes.hoverText} 
          title='Organizations List' 
          // placement="right-start" 
          arrow 
        >
          <Form.Group  controlId="organizationsForm.organizationsSelection" style={{margin: '0px' ,justifyContent: 'center', alignItems:'center', alignContent:'center'}}  > 
            <Form.Control className="organizationSelection" as="select" style={{ borderRadius: '5px', paddingTop: '0px', paddingBottom: '0px'}}  onChange={this.handleOrganizationChange}>
              {this.props.allOrganizations.map( function(organization, index){
                return (
                    <option key={organization.organization_uuid} value={organization.organization_uuid}>{organization.organization_name}</option>  
                )
              })}
            </Form.Control>
          </Form.Group>
        </LightTooltip> 

        <React.Fragment>
          {/* <IconButton color="inherit"  style={{ color: '#9e9e9e'}} className="organizationsSettingsButton" onClick={this.userHandleClick}> */}
          <IconButton className={classes.settingBtnCSS} onClick={this.userHandleClick}>
            <SettingsOutlinedIcon  />
          </IconButton>
          <Menu style={{outline: 'none'}}
            id="organization-menu"
            anchorEl={userAnchorEl}
            open={userOpen}
            onClose={this.userHandleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
          }}
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'center',
          // }}
          PaperProps={{
            style: {
              width: 'auto',
            },
          }}
          >
            <MenuItem onClick={this.handleAddOrganizationModalOpen}  >
              Add Organization
            </MenuItem>
            {selectedOrganization.organization_is_personal == true ? null :
              <MenuItem onClick={this.handleEditOrganizationModalOpen}  >
                Edit Organization
              </MenuItem>
            } 
            {selectedOrganization.organization_is_personal == true ? null :
              <MenuItem onClick={this.handleLeaveOrganizationModalOpen} >
                Leave Organization
              </MenuItem>
            } 
            {selectedOrganization.organization_is_personal == true ? null :
              <MenuItem onClick={this.handleDeleteOrganizationModalOpen} >
                Delete Organization
              </MenuItem>
            }
          </Menu>

          


        </React.Fragment> 
      </ButtonGroup>
      <AddOrganizationModal open={addOrganizationModalOpen} handleCloseModal={this.handleCloseModal} />
      <EditOrganizationModal 
        open={editOrganizationModalOpen} 
        organization_name = {this.props.selectedOrganization.organization_name}
        organization_description = {this.props.selectedOrganization.organization_description} 
        subscriptionType = {this.props.selectedOrganization.subscription_plan_identifier} 
        handleCloseModal={this.handleCloseModal}
        />
      <LeaveOrganizationModal open={leaveOrganizationModalOpen} handleCloseModal={this.handleCloseModal} /> 
      <DeleteOrganizationModal open={deleteOrganizationModalOpen} handleCloseModal={this.handleCloseModal} /> 


      </div>
      
  )
  
}

}

OrganizationButtons.propTypes = {
/**
 * Injected by the documentation to work in an iframe.
 * You won't need it on your project.
 */
container: PropTypes.any,
};

const mapDispatchToProps = dispatch => ({
  fetchSelectedOrganization: currentOrganization => dispatch(fetchSelectedOrganization(currentOrganization)),
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  fetchOrganizations: () => dispatch(fetchOrganizations()),
})

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrganizationButtons))

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, rgbToHex } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import IconButton from '@material-ui/core/IconButton';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PasswordInfoModal from '../components/PasswordInfoModal';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(70),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    minWidth: '180px',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { 
    borderRadius: 10 ,
  },
  listCard: {
    width:"100%",
    borderRadius: "5px",
    marginBottom: "12px",
    border:'1px solid rgb(87, 147, 222)',
    cursor:'pointer',
    // backgroundColor:'transparent',
    backgroundColor:'rgba(87, 147, 222, 0.7)',
    padding:'3px 10px',
    '&:hover': {
      // backgroundColor:'transparent',
      backgroundColor:'rgba(87, 147, 222, 0.9)',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'rgba(87, 147, 222, 1)',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor:'rgba(87, 147, 222, 1)',
    },
  },
  listCardSelected: {
    width:"100%",
    borderRadius: "5px",
    marginBottom: "12px",
    border:'1px solid rgb(87, 147, 222)',
    cursor:'pointer',
    position:'relative',
    backgroundColor:'rgba(87, 147, 222, 1)',
    padding:'3px 10px',
    outline:'none',
  },
  listCardText: {
    textAlign: 'left',
    fontSize: "14px",
    color: "rgb(255,255,255)",
  },
  cardCSS: {
    borderRadius: '10px',
    border:'1px solid #f5f5f5',
    padding: '8px',
    backgroundColor: '#fafafa',
    boxShadow: '2px 4px #f5f5f5',
    cursor:'pointer',
    '&:hover': {
      // backgroundColor:'#eeeeee',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid #bdbdbd',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'#eeeeee',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor:'#eeeeee',
      border:'1px solid #bdbdbd',
      boxShadow: '2px 4px #bdbdbd',
    },
  },
  cardCSSSelected: {
    borderRadius: '10px',
    padding: '8px',
    outline: 'none',
    backgroundColor:'#eeeeee',
    border:'1px solid #bdbdbd',
    boxShadow: '2px 4px #bdbdbd',
    position:'relative',
    cursor:'pointer',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  headerCSS: {
    textAlign: 'center',
    color: '#1976d2',
    fontFamily:'Trebuchet MS',
  },
  bodyCSS: {
    fontSize: '14px',
    color: '#525f7f',
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
  },
  pricingCSS: {
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
    marginTop: '16px',
    marginBottom: '16px',
  },
  customButton: {
    width: '100%',
    marginBottom: '10px', 
    marginTop: '10px',
    borderRadius: '5px',
    border:'1px solid #f5f5f5',
    backgroundColor:'rgba(87, 147, 222, 0.1)',
    '&:hover': {
      backgroundColor:'rgba(87, 147, 222, 0.3)',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid rgba(87, 147, 222, 0.5)',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'rgba(87, 147, 222, 0.4)',
      border:'1px solid rgba(87, 147, 222, 0.7)',
    },
    '&:focus': {
      outline: 'none',
      // backgroundColor:'#eeeeee',
      // border:'1px solid #bdbdbd',
      // boxShadow: '2px 4px #bdbdbd',
    },
  }

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class AddRemoteConnectionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      remoteConnectionType: '',
      remoteConnectionName: '',
      remoteConnectionFromIp: '',
      remoteConnectionPort: '',
      remoteConnectionSlug: '',
      subscriptionType:'FREE',
      customConnectionDisplay: false,

    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }



  timer = undefined;

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
      remoteConnectionType: '',
      remoteConnectionName: '',
      remoteConnectionFromIp: '',
      remoteConnectionPort: '',
      remoteConnectionSlug: '',
      customConnectionDisplay: false,
      subscriptionType: 'FREE',
    });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
      customConnectionDisplay: false,
      remoteConnectionType: '',
      remoteConnectionName: '',
      remoteConnectionFromIp: '',
      remoteConnectionPort: '',
      remoteConnectionSlug: '',
      subscriptionType: 'FREE',
    });
  };

  handleRemoteConnectionNameChange = (event) => {
    this.setState({remoteConnectionName: event.target.value});
  }
  handleRemoteConnectionFromIpChange = (event) => {
    this.setState({remoteConnectionFromIp: event.target.value.replace(/[^0-9.]/g, "")});
  }

  handleRemoteConnectionPortChange = (event) => {
    this.setState({remoteConnectionPort: event.target.value});
  }

  handleRemoteConnectionSlugChange = (event) => {
    // https://www.w3schools.com/jsref/jsref_obj_regexp.asp
    this.setState({remoteConnectionSlug:  event.target.value.replace(/[^a-z0-9-]/g, "")});
  }
  handleSubscriptionTypeChange = (event) => {
    this.setState({subscriptionType: event});
  }
  handleCustomConnectionDisplayChange = () => {
    this.state.customConnectionDisplay ? 
      this.setState({
        remoteConnectionType: '',
        remoteConnectionName: '',
        remoteConnectionFromIp: '',
        remoteConnectionPort: '',
        remoteConnectionSlug: '',
      }, ()=> this.setState({customConnectionDisplay: false}))
      : 
      this.setState({
        remoteConnectionType: 'HTTP',
        remoteConnectionName: '',
        remoteConnectionFromIp: '',
        remoteConnectionPort: '',
        remoteConnectionSlug: '',
      }, ()=> this.setState({customConnectionDisplay: true}))
  }

  nodeRedSelected = () => {
    this.setState({
      customConnectionDisplay: false,
      remoteConnectionType: 'NODERED',
      remoteConnectionName: 'Node-RED',
      remoteConnectionFromIp: '127.0.0.1',
      remoteConnectionPort: '1880',
      remoteConnectionSlug: 'nodered'
    });
  }
  sshSelected = () => {
    this.setState({
      customConnectionDisplay: false,
      remoteConnectionType: 'SSH',
      remoteConnectionName: 'SSH',
      remoteConnectionFromIp: '127.0.0.1',
      remoteConnectionPort: '22',
      remoteConnectionSlug: ' ',
    });
  }

  submitButtonClick = () => {
    
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        postData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid +'/connections', 
        {'connection_type': this.state.remoteConnectionType,
          'connection_name': this.state.remoteConnectionName,
          'from_ip': this.state.remoteConnectionFromIp,
          'from_port': this.state.remoteConnectionPort,
          'slug': this.state.remoteConnectionSlug,
          'device_connection_subscription_type': this.state.subscriptionType})
          .then(async response => {
            if(response.ok){
              const data = await response.json();
              this.props.showSuccessSnackbar("Remote connection has been successfully added!");
              this.props.refreshRemoteConnectionList();
              this.setState({
                loading: false,
                success: true,
                open:false,
              });
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false});
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  


  render() {
    const { classes } = this.props;
    const { open, 
            loading, 
            success, 
            remoteConnectionType,
            remoteConnectionName,
            remoteConnectionFromIp,
            remoteConnectionPort,
            remoteConnectionSlug,
            subscriptionType,
            customConnectionDisplay} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
      >
        
        <Button className={classes.button} variant="contained"   onClick={this.handleOpen}><AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Remote Connection </Button>

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Create Remote Connection</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px'}}>

                

                <DialogContentText style={{textAlign: 'center'}}>
                  <i>Quick Connect</i>
                </DialogContentText>

                <button className={this.state.remoteConnectionType == 'NODERED' ? classes.listCardSelected : classes.listCard}  onClick={this.nodeRedSelected} >
                  <Grid container spacing={1} direction="row" justify="space-around" >
                    <Grid item xs={2} sm={2}>
                      <div className={classes.listCardText} ><b>Node-RED</b></div>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <div className={classes.listCardText}>From IP: 127.0.0.1</div>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <div className={classes.listCardText}>Port: 1880</div>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <div className={classes.listCardText}>toPath: /nodered/</div>
                    </Grid>
                  </Grid>
                </button>

                <button className={this.state.remoteConnectionType == 'SSH' ? classes.listCardSelected : classes.listCard} onClick={this.sshSelected}>
                  <Grid container spacing={1} direction="row" justify="space-around" >
                    <Grid item xs={2} sm={2}>
                      <div className={classes.listCardText} ><b>SSH</b></div>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <div className={classes.listCardText}>From IP: 127.0.0.1</div>
                    </Grid>
                    <Grid xs={2} sm={2}>
                      <div className={classes.listCardText}>Port: 22</div>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <div className={classes.listCardText}> &nbsp;&nbsp;  </div>
                    </Grid>
                  </Grid>
                </button>

                <Button className={classes.customButton}  onClick={this.handleCustomConnectionDisplayChange} >
                  <KeyboardArrowDownOutlinedIcon/>Create Custom Connection
                </Button>
                
                {!customConnectionDisplay? null:
                  <div>
                  <TextField
                    style={{marginTop: '0px'}}
                    autoFocus
                    margin="normal"
                    id="remoteConnectionType"
                    label="Connection Type"
                    type="text"
                    value={"HTTP"} 
                    fullWidth
                    required
                    disabled
                  />

                  <TextField
                    autoFocus
                    margin="normal"
                    id="remoteConnectionName"
                    label="Name"
                    type="text"
                    value={remoteConnectionName} 
                    onChange={this.handleRemoteConnectionNameChange}
                    fullWidth
                  />
                  <TextField
                    margin="normal"
                    id="remoteConnectionFromIp"
                    label="From IP"
                    type="text"
                    value={remoteConnectionFromIp} 
                    onChange={this.handleRemoteConnectionFromIpChange}
                    fullWidth
                  />

                  <TextField
                    margin="normal"
                    id="remoteConnectionPort"
                    label="Port Number"
                    type="number"
                    value={remoteConnectionPort} 
                    onChange={this.handleRemoteConnectionPortChange}
                    fullWidth
                    required
                  />

                  <TextField
                    margin="normal"
                    id="remoteConnectionSlug"
                    label="Slug"
                    type="text"
                    value={remoteConnectionSlug} 
                    onChange={this.handleRemoteConnectionSlugChange}
                    fullWidth
                    required
                  />
                  </div>
                }
                {/* <DialogContentText style={{textAlign: 'center', marginTop: '12px'}}>
                  <i>Plan</i>
                </DialogContentText>
                <Grid container spacing={1} style={{marginBottom: '16px', textAlign: 'center'}}>
                  <Grid item xs={12}>
                    <button className={subscriptionType == 'MONTHLY' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('MONTHLY')} >
                      <h4 className={classes.headerCSS}>Monthly</h4>
                      <Divider/>
                      <div className={classes.pricingCSS}>
                        $6.99
                      </div>
                      <div className={classes.bodyCSS}>
                        per month per remote connection
                      </div>
                    </button>
                  </Grid> */}
                  {/* <Grid item xs={6}>
                  <button className={subscriptionType == 'YEARLY' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('YEARLY')}>
                      <h4 className={classes.headerCSS}>Yearly</h4>
                      <Divider/>
                      <div className={classes.bodyCSS}>
                        Monthly subscription for remote connection 
                      </div>
                      <div className={classes.pricingCSS}>
                        $69.99
                      </div>
                      <div className={classes.bodyCSS}>
                        per year per remote connection
                      </div>
                    </button>
                  </Grid>  */}


                {/* </Grid> */}


                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading || (remoteConnectionPort == '') || (remoteConnectionType == '') || (remoteConnectionSlug == '') || (remoteConnectionFromIp == '') || (subscriptionType == '')} onClick={this.submitButtonClick}>
                    {'Create'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

AddRemoteConnectionModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AddRemoteConnectionModal));

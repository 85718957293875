import React from 'react';
import MaterialTable from 'material-table';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import {Redirect} from "react-router-dom";
import {OrgDeviceListColumns} from './OrgDeviceListColumns'
import {getData, postData, putData, deleteData} from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { Link } from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import LastPageIcon from '@material-ui/icons/LastPage';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PropTypes from 'prop-types';
import EditDeviceModal from '../components/EditDeviceModal';
import DeleteDeviceModal from '../components/DeleteDeviceModal';
import Paper from '@material-ui/core/Paper';
import "../styles/HeartbeatLED.scss";
import MqttService from '../core/services/MqttService';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 500,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.white,
    },
  },
}))(TableRow);

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  btnContainer: {
    paddingTop:theme.spacing(0),
    padding: theme.spacing(2),
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'11px',
    // font-weight:bold;
    padding:'1px 8px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
    },
  },
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '3px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
    
  },
  tableContainer: {
    borderRadius: '10px',
  },
  table: {
    minWidth: 400,
  },
  E1SubscriptionCSS: {
    width: '85px',
    borderRadius: '5px',
    backgroundColor: 'rgb(232, 235, 192)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  E2SubscriptionCSS: {
    width: '85px',
    borderRadius: '4px',
    backgroundColor: 'rgb(201, 220, 244)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  E3SubscriptionCSS: {
    width: '85px',
    borderRadius: '4px',
    backgroundColor: 'rgb(203, 244, 201)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  freeSubscriptionCSS: {
    width: '85px',
    borderRadius: '5px',
    backgroundColor: 'rgb(219, 219, 219)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },

});



class MainDevicesPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      deviceColumns: OrgDeviceListColumns,
      devices_list: [],
      devicesDic: [],
      selected_device: [],
      page: 0,
      rowsPerPage: 10,
    };
    this.refreshDeviceList = this.refreshDeviceList.bind(this)
  }


  componentDidMount() {
        
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/devices')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        var dic = {};
          for(var i=0; i<Object.keys(data.data).length; i++){
            if (data.data[i].device_last_heard){
              let localDateTime = new Date(data.data[i].device_last_heard);
              localDateTime = localDateTime.toLocaleTimeString()+' '+localDateTime.toLocaleDateString();
  
              data.data[i].device_last_heard = localDateTime;
              dic[data.data[i].device_uuid]= data.data[i];
            } else {
              data.data[i].device_last_heard = '';
              dic[data.data[i].device_uuid]= data.data[i];
            }
          }

        this.setState({
          devices_list: data.data,
          devicesDic: dic,
          },this.subscribedDevicesMQTT
        )
      }
    });

    this.state.deviceColumns.map(column => {
      if(column.field == 'device_type'){
        // Get function to receive project device list
        getData('https://api.iotflows.com/v1/devices/types')
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            var lookup = {};
            const result = data.data
            result.map( device_type => {
              lookup[device_type.device_type_name] = device_type.device_type_name;
            })
            column.lookup = lookup
          }    
        });
      }
    })

  }

  
  refreshDeviceList() {
    // Get function to receive project device list
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/devices')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        var dic = {};
          for(var i=0; i<Object.keys(data.data).length; i++){
            if (data.data[i].device_last_heard){
              let localDateTime = new Date(data.data[i].device_last_heard);
              localDateTime = localDateTime.toLocaleTimeString()+' '+localDateTime.toLocaleDateString();
  
              data.data[i].device_last_heard = localDateTime;
              dic[data.data[i].device_uuid]= data.data[i];
            } else {
              data.data[i].device_last_heard = '';
              dic[data.data[i].device_uuid]= data.data[i];
            }
          }
        this.setState({
          devices_list: data.data,
          devicesDic: dic,
          },this.subscribedDevicesMQTT
        )
      }
    });
  }

  // callback function to ensure all device are listed
  subscribedDevicesMQTT(){
    if(MqttService.isConnected) {
      for (let i=0; i<Object.keys(this.state.devices_list).length; i++){
        MqttService.subscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/clients/' + this.state.devices_list[i].device_uuid + '/status/#', this.onDeviceStatus)
      }
    }
  }

  onDeviceStatus = message => {
    // console.log('Status message')
    // console.log(message)
    // updating state of device online and last heard
    try{
      const updatedDeviceState = JSON.parse(message.payloadString);
      var currentDeviceUuid = updatedDeviceState.username;
      var deviceOnlineStatus = updatedDeviceState.is_online;
  
      let localDateTime = new Date(parseInt(updatedDeviceState.timestamp));
      localDateTime = localDateTime.toLocaleTimeString()+' '+localDateTime.toLocaleDateString();

      var device_dic = this.state.devicesDic
      device_dic[currentDeviceUuid].device_is_online = deviceOnlineStatus;
      device_dic[currentDeviceUuid].device_last_heard = localDateTime;

      // console.log('device_dic')
      // console.log(device_dic)
      this.setState({
        devicesDic: device_dic,
      });
    }
    catch(e){
      console.log("Error in reading heartbeat from MQTT");
      console.log(e.message);
    }
  }

  componentWillUnmount() {
    if(MqttService.isConnected) {
      for (let i=0; i<Object.keys(this.state.devices_list).length; i++){
        MqttService.unsubscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/clients/' + this.state.devices_list[i].device_uuid + '/status/#')
      }
    }
    
  }

  handleChangePage = (event, newPage) => {
    this.setState({page: newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  handleRowClick = (e, device) => {
    this.setState({selected_device: device})
    this.setState({toPath: `/devices/${device.device_uuid}`})
    this.setState({redirect: true})
  }


  render(){
    const { deviceColumns, 
            devices_list,
            selected_device,
            page,
            rowsPerPage,
            devicesDic } = this.state
    const { classes,selectedOrganization} = this.props

    if (this.state.redirect) {
      return <Redirect push to={{pathname: this.state.toPath, state: {selected_device: this.state.selected_device} }} />
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, devices_list.length - page * rowsPerPage);

    return (

      <div className={classes.root} style={{marginBottom: '60px'}}>
        <div className={classes.breadcrumbCSS}>
          <Breadcrumbs  separator="›" aria-label="breadcrumb">
            {/* <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/" >
              {this.props.selectedOrganization.organization_name}
            </Link> */}
            {/* <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/devices" >
              Projects
            </Link> */}
            <Typography className={classes.fontStyles} style={{color:'#757575'}}>{this.props.selectedOrganization.organization_name}</Typography>
            <Typography className={classes.fontStyles} color="textPrimary">Devices</Typography>
          </Breadcrumbs>
        </div>

        
      {/* Organization Devices */}
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} >
          <colgroup>
          <col style={{width:'3%'}}/>
          <col style={{width:'15%'}}/>
          <col style={{width:'22%'}}/>
          <col style={{width:'20%'}}/>
          <col style={{width:'10%'}}/>
          <col style={{width:'20%'}}/>
          <col style={{width:'5%'}}/>
          <col style={{width:'5%'}}/>
          </colgroup>
          <TableHead>
          <TableRow>
            <StyledTableCell style={{fontSize: '24px', borderBottom: 'none', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={7}>
              <b>Devices</b>
            </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell ></StyledTableCell>
              <StyledTableCell >Device Name</StyledTableCell>
              <StyledTableCell >Assigned Project</StyledTableCell>
              <StyledTableCell align="left"> Device Description</StyledTableCell>
              <StyledTableCell align="left"> Device Type</StyledTableCell>
              <StyledTableCell align="left"> Last Connection Established</StyledTableCell>
              <StyledTableCell align="left"> </StyledTableCell>
              <StyledTableCell align="left"> </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? devices_list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : devices_list
            ).map((device) => (
              <StyledTableRow hover style={{height: '24px',padding: '8px', cursor: 'pointer'}} key={device.device_uuid} >
                <StyledTableCell onClick={(event) => this.handleRowClick(event, device)}  component="th" scope="row">
                  {devicesDic[device.device_uuid].device_is_online ? <span className="heartbeatOnlineAllDevices"> </span>: <span className="heartbeatOfflineAllDevices"> </span>}
                </StyledTableCell>
                <StyledTableCell onClick={(event) => this.handleRowClick(event, device)} component="th" scope="row">{device.device_name}</StyledTableCell>
                <StyledTableCell onClick={(event) => this.handleRowClick(event, device)} align="left">{device.project_name}</StyledTableCell>
                <StyledTableCell onClick={(event) => this.handleRowClick(event, device)} align="left">{device.device_description}</StyledTableCell>
                <StyledTableCell onClick={(event) => this.handleRowClick(event, device)} align="left">
                  {device.device_is_virtual ? 
                    <div className={classes.E3SubscriptionCSS} >
                      <span style={{fontSize: '11px', color:'rgb(14, 98, 69)'}} >
                        {device.subscription_plan_identifier == "vr-dev-basic" ? "V-Basic": null}
                        {device.subscription_plan_identifier == "vr-dev-standard" ? "V-Std": null}
                        {device.subscription_plan_identifier == "vr-dev-pro" ? "V-Pro": null}
                        {device.subscription_plan_identifier == "ent-vr-dev-standard" ? "Ent-V-Std": null}
                        {device.subscription_plan_identifier == "ent-vr-dev-pro" ? "Ent-V-Pro": null}
                      </span>
                    </div>
                  : <div className={classes.E2SubscriptionCSS} >
                      <span style={{fontSize: '11px', color:'rgb(14, 50, 97)'}} >
                        {device.subscription_plan_identifier == "dev-basic" ? "Dev-Basic": null}
                        {device.subscription_plan_identifier == "dev-standard" ? "Dev-Std": null}
                        {device.subscription_plan_identifier == "dev-pro" ? "Dev-Pro": null}
                        {device.subscription_plan_identifier == "ent-dev-standard" ? "Ent-Dev-Std": null}
                        {device.subscription_plan_identifier == "ent-dev-pro" ? "Ent-Dev-Pro": null}
                        {device.subscription_plan_identifier == "IOT_GATEWAY_E2" ? "Physical": null}
                        {device.subscription_plan_identifier == "IOT_GATEWAY_FREE" ? "Virtual": null}
                        {device.subscription_plan_identifier == "IOT_GATEWAY_PAY_AS_YOU_GO" ? "Virtual": null}
                      </span>
                    </div>
                    }
                  {/* {device.subscription_plan_identifier == "IOT_GATEWAY_FREE" ? 
                    <div className={classes.freeSubscriptionCSS} >
                      <span style={{fontSize: '11px', color:'rgb(99, 99, 99)'}} >Virtual</span>
                    </div>
                  : null}
                  {device.subscription_plan_identifier == "IOT_GATEWAY_PAY_AS_YOU_GO" ? 
                    <div className={classes.freeSubscriptionCSS} >
                      <span style={{fontSize: '11px', color:'rgb(99, 99, 99)'}} >Physical</span>
                    </div>
                  : null}
                  {device.subscription_plan_identifier == "IOT_GATEWAY_E1" ? 
                    <div className={classes.E1SubscriptionCSS} >
                      <span style={{fontSize: '11px', color:'rgb(106, 112, 22)'}} >Physical</span>
                    </div>
                  : null}
                  {device.subscription_plan_identifier == "IOT_GATEWAY_E2" ? 
                    <div className={classes.E2SubscriptionCSS} >
                      <span style={{fontSize: '11px', color:'rgb(14, 50, 97)'}}>Physical</span>
                    </div>
                  : null}
                  {device.subscription_plan_identifier == "IOT_GATEWAY_E3" ? 
                    <div className={classes.E3SubscriptionCSS} >
                      <span style={{fontSize: '11px', color:'rgb(14, 98, 69)'}}>Physical</span>
                    </div>
                    : null} */}
                </StyledTableCell>
                <StyledTableCell onClick={(event) => this.handleRowClick(event, device)} align="left">{devicesDic[device.device_uuid].device_last_heard ? devicesDic[device.device_uuid].device_last_heard : ''}</StyledTableCell>
                <StyledTableCell align="left"> 
                  <EditDeviceModal 
                    device_name={device.device_name}
                    device_description={device.device_description}
                    subscriptionType={device.subscription_plan_identifier}
                    device_uuid={device.device_uuid} 
                    refreshDeviceList={this.refreshDeviceList}
                  /> 
                </StyledTableCell>
                <StyledTableCell align="left"> <DeleteDeviceModal deviceName={device.device_name} refreshDeviceList={this.refreshDeviceList} device_uuid={device.device_uuid} /> </StyledTableCell>
              </StyledTableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 40 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, { label: 'All', value: -1 }]}
              colSpan={3}
              count={devices_list.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </TableContainer>

      </div>
      

    )

  }
}

// export default MainDevicesPage
const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(MainDevicesPage))

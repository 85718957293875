import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import IoTFlowsLogo from '../IoTFlowsLogoBlackFont.png';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
  } from "react-router-dom";
import Amplify, { Auth, Hub } from 'aws-amplify';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';

Amplify.configure({
    Auth: {            
        identityPoolId: 'us-east-1:5684b65a-8bf4-4697-b9a4-87eadbf03ef5',
        region: 'us-east-1',
        identityPoolRegion: 'us-east-1',      
        userPoolId: 'us-east-1_3EP1MRzAy',  
        userPoolWebClientId: '18230ih88kfb3ouj67mrid5er3',
        oauth: {
            domain: 'auth.iotflows.com',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            // redirectSignIn: 'https://www.console.iotflows.com/', 
            // redirectSignOut: 'https://www.console.iotflows.com/', 
            redirectSignIn: 'http://localhost:3000/', 
            redirectSignOut: 'http://localhost:3000/', 
            responseType: 'token', // DO NOT USE 'code'!!! it goes through a forever loop //or 'token', note that REFRESH token will only be generated when the responseType is code
            // responseType: 'code', 
            options: {
                // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
                AdvancedSecurityDataCollectionFlag : true
            },
        }
    }
});



const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100vh',
        overflow:'scroll',
    },
    loginContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '540px',
        paddingTop: '56px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '382px',
            paddingTop: '28px',
        },
    },
    logoContainer: {
        marginLeft:'12px',
        paddingLeft: '16px',
        paddingBottom: '40px',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '20px',
            paddingBottom: '24px',
        },
    },
    loginSection: {
        width: '540px',
        color: '#424770',
        backgroundColor: '#fff',
        borderRadius: '16px',
        padding: '56px 48px',
        webkitBoxShadow:  '0 30px 60px -12px rgb(50 50 93 / 25%), 0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%)',
        boxShadow: '0 30px 60px -12px rgb(50 50 93 / 25%), 0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '32px 20px',
            margin:'0px 16px'
        },
    },
    loginTitleContainer: {
        marginLeft: '12px',
        textAlign: 'left',
    },
    loginTitle: {
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '32px',
        fontFamily: 'sohne-var,sohne-woff,Helvetica Neue,Arial,sans-serif',
        letterSpacing: '-.2px',
        color: '#3c4257',
    },
    inputSection: {
        marginBottom: '12px',
    },
    inputContainers: {
        textAlign: 'left',
        marginLeft: '12px',
        marginTop:'12px',
    },
    labelCSS: {
        marginBottom: '0px',
        textAlign: 'left',
    },
    loginHeader: {
        lineHeight: '20px',
        fontWeight: 500,
        fontSize: '14px',
        color: '#3c4257',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
    },
    inputCSS: {
        // backgroundColor: '#f6f9fc',
        backgroundColor: '#fff',
        color: 'rgb(60, 66, 87)',
        display: 'block',
        width: '100%',
        height: 'calc(1.5em + .75rem + 2px)',
        padding: '.375rem .75rem',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
        webkitAppearance: 'none',
        mozAppearance: 'none',
        appearance: 'none',
        borderRadius: '.25rem',
        webkitFontSmoothing: 'antialiased',
        transition: 'background-color .24s,box-shadow .24s',
        '&:focus': {
            transition: 'background-color .24s,box-shadow .24s',
            borderColor: 'rgba(142, 183, 248, 1)',
            outline: 0,
            webkitBoxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px',
            mozBoxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px',
            boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px',
        },
    },
    forgotCSS: {
        textAlign: 'left',
        textDecoration: 'none',
        lineHeight: '20px',
        fontWeight: 500,
        fontSize: '14px',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
        color: '#2563EB',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'none',
            color: '#3c4257',
        },
    },
    loginButtonContainer: {
        marginLeft: '12px',
        marginTop: '24px',
    },
    loginButton: {
        backgroundColor: '#2563EB',
        color: '#fff',
        border: 'none',
        fontWeight: 500,
        padding: '4px 14px',
        fontSize: '16px',
        letterSpacing: '.025em',
        lineHeight: '28px',
        borderRadius: '4px',
        width: '100%',
        '&:hover': {
            backgroundColor: '#2563EB', 
            opacity: 0.9,
        },
        '&:disabled': {
            opacity: 0.8,
        },
    },
    signupContainer: {
        textAlign: 'left',
        paddingTop: '32px',
        paddingLeft: '20px',
    },
    signupMessage: {
        display: 'flex',
        lineHeight: '20px',
        color: '#3c4257',
        fontWeight: 400,
        fontSize: '14px',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
    },
    footerPositioner: {
        position: 'relative',
        // bottom: '10px',
        // display: 'flex',
        // flexDirection: 'column',
    },
    spacer: {
        flexGrow: 1,
        flexShrink: 1,
    },
    legal_footer: {
        padding: '12px',
    },
    legal_item: {
        textDecoration: 'none',
        color: '#606972',
        padding: '8px',
        textAlign: 'center',
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'none',
            color: 'rgba(96, 105, 114, 0.8)',
            cursor: 'pointer',
        },
    },
    angledDiv: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: '-250px',
        bottom: 0,
        zIndex: -1,
        transform: 'skewY(-12deg)',
    },
    largeGreyBackground: {
        position: 'absolute',
        top: '-1000px',
        left: 0,
        right: 0,
        transformOrigin: '0 50%',
        height: '1630px',
        // backgroundColor: '#f7fafc',
        backgroundColor: '#F3F4F6', //dark
    },
    leftStripeBackground: {
        top: '630px',
        left: '-10px',
        right: 'calc(50% + 505px)',
        height: '50px',
        backgroundColor:'#60A5FA',
        position: 'absolute',
        [theme.breakpoints.up('xl')]: {
            right: 'calc(50% + 605px)',
        },
        [theme.breakpoints.down('md')]: {
            right: 'calc(50% + 135px)',
        },
    },
    rightStripeBackground: {
        top: '580px',
        right: '-10px',
        left: 'calc(100% - 230px)',
        height: '50px',
        backgroundColor:'#60A5FA',
        position: 'absolute',
        [theme.breakpoints.down('md')]: {
            left: 'calc(100% - 110px)',
        },
    },
    textCSS: {
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
        lineHeight: '20px',
        fontWeight: 400,
        fontSize: '14px',
        color: '#3c4257',
    },
    messageContainer: {
        padding: '1px',
    },
    passwordMessage: {
        lineHeight: '16px',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',

    },
    notificationMessage: {
        lineHeight: '16px',
        fontSize: '12px',
        // color: '#cd3d64',
        fontWeight: 400,
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',

    }
   
  });

const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
const lowerCase = new RegExp('(?=.*[a-z])')
const upperCase = new RegExp('(?=.*[A-Z])')
const specialChar = new RegExp('(?=.*[^A-Za-z0-9])')
const num = new RegExp('(?=.*[0-9])')
const eightChar = new RegExp('(?=.{8,})')
const emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  

class ConfirmForgotPassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            code: '',
            newPassword: '',
            confirmNewPassword: '',
            redirect: false,
            toPath:'',
            strongPasswordCreated: false,
            passwordContainsLowerCase: false,
            passwordContainsUpperCase: false,
            passwordContainsSpecialChar: false,
            passwordContainsNum: false,
            passwordContainsEightChar: false,
            deliveryDetails: [],
            username: '',
            passwordMatch: false,
            invalidCode: false,
            issueMessage: '',
        }
    }

    componentDidMount() {
        if(this.props.location.state.deliveryDetails ){
            this.setState({deliveryDetails: this.props.location.state.deliveryDetails})
        }
        if(this.props.location.state.username){
            this.setState({username: this.props.location.state.username})
        }
    }

    componentWillUnmount() {
        this.setState({
            code: '',
            newPassword: '',
            confirmNewPassword: '',
            deliveryDetails: [],
            username: '',
        })
    }

    handleLoginRouting = () => {
        this.setState(
            {
                redirect: true,
                toPath: '/login',
            }, () => {
                this.setState({redirect: false})
            })
    }

    handleSignupRouting = () => {
        this.setState(
            {
                redirect: true,
                toPath: '/signup',
            }, () => {
                this.setState({redirect: false})
            })
    }
    handleNewPassword = (e) => {
        this.setState({
            newPassword: e.target.value
        },() =>{
            if(this.state.newPassword !== this.state.confirmNewPassword){
                this.setState({
                    passwordMatch: false,
                })
            }else{
                this.setState({
                    passwordMatch: true,
                })
                
            }
            if(strongPassword.test(this.state.newPassword)){
                this.setState({strongPasswordCreated: true})
            }else{
                this.setState({strongPasswordCreated: false})
            }
            if(lowerCase.test(this.state.newPassword)){
                this.setState({passwordContainsLowerCase: true})
            }else{
                this.setState({passwordContainsLowerCase: false})
            }
            if(upperCase.test(this.state.newPassword)){
                this.setState({passwordContainsUpperCase: true})
            }else{
                this.setState({passwordContainsUpperCase: false})
            }
            if(specialChar.test(this.state.newPassword)){
                this.setState({passwordContainsSpecialChar: true})
            }else{
                this.setState({passwordContainsSpecialChar: false})
            }
            if(num.test(this.state.newPassword)){
                this.setState({passwordContainsNum: true})
            }else{
                this.setState({passwordContainsNum: false})
            }
            if(eightChar.test(this.state.newPassword)){
                this.setState({passwordContainsEightChar: true})
            }else{
                this.setState({passwordContainsEightChar: false})
            }
        })
    }

    handleConfirmNewPassword = (e) => {
        this.setState({
            confirmNewPassword: e.target.value
        },() =>{
            if(this.state.newPassword !== this.state.confirmNewPassword){
                this.setState({
                    passwordMatch: false,
                })
            }else{
                this.setState({
                    passwordMatch: true,
                })
                
            }
        })
    }

    changePassword = () => {
        Auth.forgotPasswordSubmit(this.state.username, this.state.code, this.state.newPassword)
        .then(data => {
            this.props.showSuccessSnackbar('Password has been successfully changed');
            this.setState(
                {
                    redirect: true,
                    toPath: '/login',
                    invalidCode: false,
                    issueMessage: '',
                }, () => {
                    this.setState({redirect: false})
                })
        })
        .catch(err => {
            // console.log(err);
            this.setState({
                invalidCode: true,
                issueMessage: err.message
            })
            // this.setState(
            //     {
            //         redirect: true,
            //         toPath: '/reset',
            //     }, () => {
            //         this.setState({redirect: false})
            //     })
        });
    }

    render() {
        const { classes} = this.props
        const { toPath,
                code,
                redirect,
                deliveryDetails,
                username,
                newPassword,
                confirmNewPassword,
                passwordMatch,
                passwordContainsLowerCase,
                passwordContainsUpperCase,
                passwordContainsNum,
                passwordContainsSpecialChar,
                passwordContainsEightChar,
                strongPasswordCreated,
                invalidCode,
                issueMessage } = this.state

        

        if (redirect) {
            return <Redirect push to={toPath} />
          }


        return (
            <div className={classes.root}>
                    <div className={classes.angledDiv}>
                        <div className={classes.largeGreyBackground}>
                        </div>
                        <div className={classes.leftStripeBackground}>
                        </div>
                        <div className={classes.rightStripeBackground}>
                        </div>
                    </div>
                    
                    <div className={classes.loginContainer}>
                        <div className={classes.logoContainer}>
                            <img src={IoTFlowsLogo} height="40px" alt="IoTFlows Inc" loading="lazy" />
                        </div>
                        <div className={classes.loginSection}>
                            <form onSubmit={e => e.preventDefault()} >
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <div className={classes.loginTitleContainer} >
                                        <span className={classes.loginTitle} >Confirm forgot password</span>
                                    </div>

                                    <div className={classes.inputContainers} >
                                        {/* <span className={classes.textCSS} >We have sent a password reset code by text message to XXX. Enter it below to reset your password.</span> */}
                                        <span className={classes.textCSS} >We have sent a password reset code by {deliveryDetails && Object.keys(deliveryDetails).length > 0 && deliveryDetails.AttributeName == 'phone_number'? 'text message': deliveryDetails.AttributeName} to {deliveryDetails.Destination}. Enter it below to reset your password.</span>
                                    </div>

                                    <div className={classes.inputSection} >
                                        <div style={{marginBottom: '0px'}}>
                                            <div className={classes.inputContainers} >
                                                <label className={classes.labelCSS} for="code"><span className={classes.loginHeader} >Code</span></label> 
                                            </div>
                                            <div className={classes.inputContainers}>
                                                <input onChange={(e) => this.setState({code: e.target.value})} type="text" autocomplete="off" id="user_code" placeholder="" className={classes.inputCSS} required />
                                            </div>
                                            {invalidCode &&
                                            <div className={classes.inputContainers} style={{marginTop: '4px'}}>
                                                <div className={classes.messageContainer}>
                                                    <svg fill="#ed5f74" height="12" width="12" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0 1 13.634 16H2.365A2.365 2.365 0 0 1 .25 12.577L5.884 1.308a2.365 2.365 0 0 1 4.231 0zM8 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z" fill-rule="evenodd"></path>
                                                    </svg>
                                                    <span className={classes.notificationMessage} style={{color: '#cd3d64'}}>&nbsp;&nbsp;{issueMessage}</span>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    <div className={classes.inputSection} >
                                        <div style={{marginBottom: '0px'}}>
                                            <div className={classes.inputContainers} >
                                                <label className={classes.labelCSS} for="code"><span className={classes.loginHeader} >New password</span></label> 
                                            </div>
                                            <div className={classes.inputContainers}>
                                                <input onChange={this.handleNewPassword} type="password" autocomplete="new-password" id="user_newPassword" placeholder="" className={classes.inputCSS} required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.inputSection} >
                                        <div style={{marginBottom: '0px'}}>
                                            <div className={classes.inputContainers} >
                                                <label className={classes.labelCSS} for="code"><span className={classes.loginHeader} >Confirm new password</span></label> 
                                            </div>
                                            <div className={classes.inputContainers}>
                                                <input onChange={this.handleConfirmNewPassword} type="password" autocomplete="off" id="user_confirmNewPassword" placeholder="" className={classes.inputCSS} required />
                                            </div>
                                        </div>
                                        {/* Password requirements summary */}
                                        <div className={classes.inputContainers} style={{marginTop: '4px'}}>
                                            {newPassword && !strongPasswordCreated &&
                                                <div>
                                                    {passwordContainsLowerCase && !strongPasswordCreated?
                                                        <div className={classes.messageContainer}>
                                                            <span className={classes.passwordMessage} style={{color:'#1ea672'}}>
                                                            <svg aria-hidden="true" height="12" width="12" viewBox="0 0 16 16" fill="#1ea672" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fill-rule="evenodd"></path>
                                                            </svg>
                                                            </span>
                                                            <span className={classes.passwordMessage} >&nbsp;&nbsp;Password must contain a lower case letter</span>
                                                        </div>
                                                    :
                                                        <div className={classes.messageContainer}>
                                                            <span className={classes.passwordMessage} style={{color: '#cd3d64'}}>✖ Password must contain a lower case letter</span>
                                                        </div>
                                                    }
                                                    {passwordContainsUpperCase && !strongPasswordCreated?
                                                        <div className={classes.messageContainer}>
                                                            <span className={classes.passwordMessage} style={{color:'#1ea672'}}>
                                                            <svg aria-hidden="true" height="12" width="12" viewBox="0 0 16 16" fill="#1ea672" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fill-rule="evenodd"></path>
                                                            </svg>
                                                            </span>
                                                            <span className={classes.passwordMessage} >&nbsp;&nbsp;Password must contain a upper case letter</span>
                                                        </div>
                                                    :
                                                        <div className={classes.messageContainer}>
                                                            <span className={classes.passwordMessage} style={{color: '#cd3d64'}}>✖ Password must contain a upper case letter</span>
                                                        </div>
                                                    }
                                                    {passwordContainsSpecialChar && !strongPasswordCreated?
                                                        <div className={classes.messageContainer}>
                                                            <span className={classes.passwordMessage} style={{color:'#1ea672'}}>
                                                            <svg aria-hidden="true" height="12" width="12" viewBox="0 0 16 16" fill="#1ea672" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fill-rule="evenodd"></path>
                                                            </svg>
                                                            </span>
                                                            <span className={classes.passwordMessage} >&nbsp;&nbsp;Password must contain a special character</span>
                                                        </div>
                                                    :
                                                        <div className={classes.messageContainer}>
                                                            <span className={classes.passwordMessage} style={{color: '#cd3d64'}}>✖ Password must contain a special character</span>
                                                        </div>
                                                    }
                                                    {passwordContainsNum && !strongPasswordCreated?
                                                        <div className={classes.messageContainer}>
                                                            <span className={classes.passwordMessage} style={{color:'#1ea672'}}>
                                                            <svg aria-hidden="true" height="12" width="12" viewBox="0 0 16 16" fill="#1ea672" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fill-rule="evenodd"></path>
                                                            </svg>
                                                            </span>
                                                            <span className={classes.passwordMessage} >&nbsp;&nbsp;Password must contain a number</span>
                                                        </div>
                                                    :
                                                        <div className={classes.messageContainer}>
                                                            <span className={classes.passwordMessage} style={{color: '#cd3d64'}}>✖ Password must contain a number</span>
                                                        </div>
                                                    }
                                                    {passwordContainsEightChar ?
                                                        <div className={classes.messageContainer}>
                                                            <span className={classes.passwordMessage} style={{color:'#1ea672'}}>
                                                            <svg aria-hidden="true" height="12" width="12" viewBox="0 0 16 16" fill="#1ea672" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fill-rule="evenodd"></path>
                                                            </svg>
                                                            </span>
                                                            <span className={classes.passwordMessage} >&nbsp;&nbsp;Password must contain at least 8 characters</span>
                                                        </div>
                                                    :
                                                        <div className={classes.messageContainer}>
                                                            <span className={classes.passwordMessage} style={{color: '#cd3d64'}}>✖ Password must contain at least 8 characters</span>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {strongPasswordCreated?
                                                <div className={classes.messageContainer}>
                                                    <span className={classes.passwordMessage} style={{color:'#1ea672'}}>
                                                    <svg aria-hidden="true" height="12" width="12" viewBox="0 0 16 16" fill="#1ea672" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fill-rule="evenodd"></path>
                                                    </svg>
                                                    </span>
                                                    <span className={classes.passwordMessage} >&nbsp;&nbsp;Nice work. This is an excellent password.</span>
                                                </div>
                                            :
                                                null
                                            }
                                            {!passwordMatch && newPassword && confirmNewPassword &&
                                                <div className={classes.messageContainer}>
                                                    <svg fill="#ed5f74" height="12" width="12" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0 1 13.634 16H2.365A2.365 2.365 0 0 1 .25 12.577L5.884 1.308a2.365 2.365 0 0 1 4.231 0zM8 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z" fill-rule="evenodd"></path>
                                                    </svg>
                                                    <span className={classes.passwordMessage} style={{color: '#cd3d64'}}>&nbsp;&nbsp;Passwords must match.</span>
                                                </div>
                                            }
                                            {passwordMatch && newPassword && confirmNewPassword &&
                                                <div className={classes.messageContainer}>
                                                    <svg aria-hidden="true" height="12" width="12" viewBox="0 0 16 16" fill="#1ea672" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fill-rule="evenodd"></path>
                                                    </svg>
                                                    <span className={classes.passwordMessage} >&nbsp;&nbsp;Great job. Passwords match.</span>
                                                </div>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div >
                                <div className={classes.loginButtonContainer} >
                                    <button 
                                        className={classes.loginButton} 
                                        onClick={this.changePassword} 
                                        disabled={code== '' || newPassword == '' || confirmNewPassword == '' || !passwordMatch || !strongPasswordCreated} 
                                    >
                                        Change Password
                                    </button>
                                </div>
                                <div className={classes.inputContainers} style={{marginTop: '24px'}}>
                                    <div onClick={this.handleLoginRouting} className={classes.forgotCSS} style={{textAlign: 'center'}}>Return to login</div>
                                </div>
                            </div>

                        </div>

                        <div className={classes.signupContainer} >
                            <span className={classes.signupMessage} >Don't have an account? &nbsp; <div onClick={this.handleSignupRouting} className={classes.forgotCSS}>Sign up</div></span>
                        </div>
                        <div className={classes.spacer}/>
                        <section className={classes.footerPositioner} >
                            <div className={classes.legal_footer} >
                                <a className={classes.legal_item} href="https://www.iotflows.com/" >©IoTFlows Inc 2021</a>
                                <a className={classes.legal_item} href="https://www.iotflows.com/policies/terms-of-use/" >Terms of Use</a>
                                <a className={classes.legal_item} href="https://www.iotflows.com/policies/private-policy/" >Private Policy</a>
                            </div>
                        </section>
                    </div>

                
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
    showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  
  })
  
  const mapStateToProps = state => {
    return {
      searchbarUsername: state.searchbarUser.searchbarUsername
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ConfirmForgotPassword));

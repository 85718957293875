import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import "../styles/ButtonStyles.scss";
import { connect} from "react-redux";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CreateAlertChannelModal from '../components/CreateAlertChannelModal';
import {getData} from '../ApiCalls/DataApis';
import Divider from '@material-ui/core/Divider';
import {fetchAlertChannels, fetchSelectedAlertChannel} from '../store/actions/alertChannelActions';


const styles = theme => ({
  iconButtonAvatar: {
    padding: 1,
    outline: 'none',
  }, 
  button: { 
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    borderRadius: '25px',
    border:'1px solid #4f87cc',
    justifyContent: 'flex-start',
    padding:'2px 12px',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'13px',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      backgroundColor:'transparent',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  settingsBtn:{
    color: '#4f87cc',
  }
});

class AlertChannelList extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      userAnchorEl: null,
      userOpen: false,
      createAlertChannelModalOpen: false,
      alert_channels_list :[],

    };
    this.userHandleClick = this.userHandleClick.bind(this);
    this.userHandleClose = this.userHandleClose.bind(this);
    this.handler = this.handler.bind(this);


  }

  componentDidMount() {

    this.props.fetchAlertChannels(this.props.selectedOrganization.organization_uuid);

  //   getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/alert-channels')
  //     .then(async response => {
  //       if(response.ok ){
          
  //         const data = await response.json();
  //         // console.log('data.data')
  //         // console.log(data.data)
  //         this.setState({
  //           alert_channels_list: data.data ,
  //         })
  //       }
  //     });

  }


  userHandleClick = event => {
    this.setState({ 
      createAlertChannelModalOpen: false,
      userAnchorEl: event.currentTarget,
      userOpen: true
     });

  };

  userHandleClose = () => {
    this.setState({ 
      createAlertChannelModalOpen: false,
      userAnchorEl: null,
      userOpen: false,
     });
  };

  handleCreateAlertChannelModalOpen = () => {
    this.setState({ 
      createAlertChannelModalOpen: true,
      userAnchorEl: null,
      userOpen: false,
     });
  };
  


  // componentWillReceiveProps({open}) {
  //   this.setState({...this.state,open})
  // }

  handler = () => {
    this.setState({ 
      createAlertChannelModalOpen: false,
      userAnchorEl: null,
      userOpen: false,
     });
  };


  render() {
    const { classes, currentUserInfo, allAlertChannels, selectedAlertChannel} = this.props
    const { userAnchorEl,createAlertChannelModalOpen, userOpen, alert_channels_list} = this.state
    // const userOpen = Boolean(userAnchorEl);
    

    return(
      <div>
        
      <React.Fragment>
        <Button color="inherit"  className={classes.button} onClick={this.userHandleClick}>
          Alert Channels        
        </Button>

        <Menu style={{outline: 'none'}}
        id="user-menu"
        anchorEl={userAnchorEl}
        open={userOpen}
        onClose={this.userHandleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'center',
        // }}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
        >
        <MenuItem onClick={this.handleCreateAlertChannelModalOpen} >
          Create Alert Channel
        </MenuItem>
        <Divider />
        {allAlertChannels.map( (alert_channel, index) => (
          <MenuItem key={alert_channel.alert_channel_uuid} 
            onClick ={ () => {
              this.userHandleClose();
              this.props.fetchSelectedAlertChannel(alert_channel);
              // this.props.selectedAlertChannelHandler(alert_channel);
            }}
          > 
            {alert_channel.alert_channel_name} 
          </MenuItem>
        ))}
        </Menu>
      </React.Fragment> 
      <CreateAlertChannelModal open={createAlertChannelModalOpen}  handler={this.handler} />
    </div>
  )
}

}

AlertChannelList.propTypes = {
/**
 * Injected by the documentation to work in an iframe.
 * You won't need it on your project.
 */
container: PropTypes.any,
};

const mapDispatchToProps = dispatch => ({
  fetchSelectedAlertChannel: currentAlertChannel => dispatch(fetchSelectedAlertChannel(currentAlertChannel)),
  fetchAlertChannels: (organization_uuid) => dispatch(fetchAlertChannels(organization_uuid)),
})

const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    allAlertChannels: state.alertChannels.allAlertChannels,
    selectedAlertChannel: state.alertChannels.selectedAlertChannel
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlertChannelList))

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import  {putData } from '../ApiCalls/DataApis';
import {fetchOrganizations, fetchSelectedOrganization} from '../store/actions/organizationActions';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import OrgPicEditor from '../components/OrgPicEditor';
import DeleteOrganizationModal from './DeleteOrganizationModal';
import LeaveOrganizationModal from '../components/LeaveOrganizationModal';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ paddingLeft: '0px', paddingRight: '0px', paddingBottom: '0px'}} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const SubscriptionTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
    borderBottom: '0px solid #e8e8e8',
    // backgroundColor: 'transparent',
    outline: 'none',
    borderRadius: '10px',

  },
  indicator: {
    backgroundColor: '#4f79ce',
  },
})(Tabs);

const SubscriptionTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    borderRadius: '10px',
    outline: 'none',
    fontFamily: [
      'Trebuchet MS',
    ].join(','),
    '&:hover': {
      color: '#4f86ce',
      opacity: 1,
      outline: 'none',
    },
    '&$selected': {
      color: '#4f79ce',
      fontWeight: theme.typography.fontWeightMedium,
      outline: 'none',
    },
    '&:focus': {
      color: '#4f86ce',
      outline: 'none',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const styles = theme => ({
  paper: {
    position: 'relative',
    // width: theme.spacing(120),
    width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    // [theme.breakpoints.down('md')]:{
    //   width: theme.spacing(100),
    // },
    // [theme.breakpoints.down('sm')]:{
    //   width: theme.spacing(85),
    // },
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  contentCSS: {
    padding: '8px', 
    marginRight: '30px', 
    marginLeft: '30px',
    [theme.breakpoints.down('xs')]:{
      marginRight: '10px', 
      marginLeft: '10px',
    }
  },
  button: {
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    borderRadius: 10, 
    maxWidth: theme.spacing(150),
  },
  cardCSS: {
    width: '100%',
    borderRadius: '10px',
    // height: '750px',
    border:'1px solid #f5f5f5',
    padding: '8px',
    backgroundColor: '#fafafa',
    boxShadow: '2px 4px #f5f5f5',
    cursor:'pointer',
    '&:hover': {
      // backgroundColor:'#eeeeee',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid #bdbdbd',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      // backgroundColor:'#eeeeee',
    },
    '&:focus': {
      outline: 'none',
      // backgroundColor:'#eeeeee',
      border:'1px solid #bdbdbd',
      boxShadow: '2px 4px #bdbdbd',
    },
  },
  cardCSSSelected: {
    width: '100%',
    borderRadius: '10px',
    // height: '750px',
    padding: '8px',
    outline: 'none',
    // backgroundColor:'#eeeeee',
    backgroundColor: '#fafafa',
    border:'1px solid rgb(51, 147, 242)',
    boxShadow: '1px 2px rgba(51, 147, 242,0.5)',
    position:'relative',
    cursor:'pointer',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  headerCSS: {
    textAlign: 'center',
    color: '#1976d2',
    fontFamily:'Trebuchet MS',
    // marginTop: '20px',
    // marginBottom: '10px',
  },
  bodyCSS: {
    height: '20px',
    fontSize: '14px',
    color: 'rgb(84, 97, 128)',
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
  },
  itemsCSS: {
    fontSize: '12px',
    color: '#525f7f',
    textAlign: 'left',
    fontFamily:'Trebuchet MS',
    listStyleType: 'none',
    marginTop: '15px',
    padding: '0px',
    height: '500px',
    // [theme.breakpoints.down('md')]:{
    //   height: '600px',
    // },
    // [theme.breakpoints.down('sm')]:{
    //   height: '750px',
    // },
    // [theme.breakpoints.down('xs')]:{
    //   width: theme.spacing(43),
    // },
  },
  itemsCSSRegular: {
    fontSize: '12px',
    color: '#525f7f',
    textAlign: 'left',
    fontFamily:'Trebuchet MS',
    listStyleType: 'none',
    marginTop: '15px',
    padding: '0px',
    height: '220px',
    // [theme.breakpoints.down('md')]:{
    //   height: '600px',
    // },
    [theme.breakpoints.down('sm')]:{
      height: '290px',
    },
    [theme.breakpoints.down('xs')]:{
      height: '220px',
    },
  },
  itemCSS: {
    padding: '5px',
  },
  subItemCSS: {
    fontSize: '11px',
    padding: '5px',
    paddingLeft: '25px',
  },
  checkmarkCSS: {
    fontSize: '14px',
    color:'rgb(0,209,0)',
  },
  circleCSS: {
    fontSize: '14px',
    color:'rgb(191, 191, 191)',
  },
  pricingCSS: {
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
    marginTop: '16px',
    marginBottom: '16px',
  },
  deleteButton: {
    // backgroundColor: '#a41c4e',
    color: '#cd3d64',
  },
  leaveButton: {
    // backgroundColor: '#eaeaea',
    color: 'rgba(60, 66, 87,0.7)',
    '&:hover': {
      color: 'rgba(60, 66, 87,1)'
    },
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class EditOrganizationModal extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      open: this.props.open,
      loading: false,
      success: false,
      organization_name: this.props.organizationInfo.organization_name,
      organization_description: this.props.organizationInfo.organization_description,
      subscriptionType: '',
      deleteOrganizationModalOpen: false,
      leaveOrganizationModalOpen: false,

    }
    this.handleClose = this.handleClose.bind(this);
    this.submitButtonClick = this.submitButtonClick.bind(this);
    this.handleOrganizationNameChange = this.handleOrganizationNameChange.bind(this);
    this.handleOrganizationDescriptionChange = this.handleOrganizationDescriptionChange.bind(this);

  }
  

  handleClose = () => {
    this.props.handleCloseModal();
    this.setState({
      open: false ,
      loading: false,
      success: false,
      deleteOrganizationModalOpen: false,
      leaveOrganizationModalOpen: false
    });
  };

  handleDeleteOrganizationModalOpen = () => {
    this.setState({ deleteOrganizationModalOpen: true });

  };

  handleLeaveOrganizationModalOpen = () => {
    this.setState({ leaveOrganizationModalOpen: true });

  };

  handleOrganizationNameChange = (event) => {
    this.setState({organization_name: event.target.value});
  }

  handleOrganizationDescriptionChange = (event) => {
    this.setState({organization_description: event.target.value});
  }

  // componentWillReceiveProps(nextProps) {
  //   this.setState({
  //     open: nextProps.open,
  //     organizationInfo: nextProps.organizationInfo,
  //     organization_name: nextProps.organizationInfo.organization_name,
  //     organization_description: nextProps.organizationInfo.organization_description,
  //     // subscriptionType: nextProps.subscriptionType,
  //   }); 
  // }

  submitButtonClick = () => {
    
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        putData('https://api.iotflows.com/v1/organizations/' + this.props.organizationInfo.organization_uuid, 
        {'organization_name': this.state.organization_name,
          'organization_description': this.state.organization_description,
          // 'organization_subscription_identifier': this.state.subscriptionType,
          'organization_is_private': true})
          .then(async response => {
            if(response.ok){
              this.props.showSuccessSnackbar("Organization has been successfully modified!");
              this.setState({
                loading: false,
                // success: true,
                open:false,
              },() => { 
                this.handleClose();
                this.props.fetchOrganizations();
                this.props.handleCloseModal();
              });
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  open:false,
                },() => { 
                  this.handleClose();
                  this.props.handleCloseModal();
                });  
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
      this.setState({
        loading: false,
        success: false,
        // organization_name: this.props.selectedOrganization.organization_name,
        // organization_description: this.props.selectedOrganization.organization_description
      });
    }
    
  };

  // handleSubscriptionTypeChange = (event) => {
  //   this.setState({subscriptionType: event});
  // }

  handleChange = (event, newValue) => {
    this.setState({value: newValue});
  };

  // handleChangeIndex = (index) => {
  //   this.setState({value: index});
  // };

  render() {
    const { classes, selectedOrganization, theme, currentUserInfo } = this.props;
    const { open, 
            loading, 
            success, 
            value, 
            subscriptionType,
            organization_name,
            deleteOrganizationModalOpen,
            leaveOrganizationModalOpen } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });



    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >       

          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              // onExited={this.handleClose}
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                {/* <DialogTitle style={{paddingBottom: '0px'}} id="edit_organization_title">Edit {this.props.selectedOrganization.organization_name} Organization</DialogTitle> */}
                <DialogTitle style={{paddingBottom: '0px'}} id="edit_organization_title">Edit {organization_name} Organization</DialogTitle>
                <DialogContent dividers className={classes.contentCSS} >
                
                

                <div style={{justifyContent:'center', margin: '10px 0px 20px 0px', border:'0px solid rgb(255,255,255)'}}>
                  <OrgPicEditor organizationInfo={this.props.organizationInfo} orgPic={this.props.organizationInfo.organization_logo_url + String(Math.random())} />
                </div>

                <div style={{display: 'flex'}}>
                  <span style={{color:'rgba(60, 66, 87,0.7)', fontSize:'14px', fontWeight:500}} >Organization ID:&nbsp; </span>
                  <span style={{color:'rgba(60, 66, 87,1)', fontSize:'14px', fontWeight:500}}>{this.props.organizationInfo.organization_uuid}</span>
                </div>

                <TextField
                  autoFocus
                  margin="normal"
                  id="organization_name"
                  label="Organization Name"
                  value={organization_name}
                  onChange={this.handleOrganizationNameChange}
                  type="text"
                  fullWidth
                />

                <TextField
                  margin="normal"
                  id="organization_description"
                  label="Organization Description"
                  value={this.state.organization_description}
                  onChange={this.handleOrganizationDescriptionChange}
                  type="text"
                  fullWidth
                />

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <LightTooltip  className={classes.hoverText} title="Delete entire organization" placement="right-start" arrow  >
                    <Button className={classes.deleteButton} onClick={this.handleDeleteOrganizationModalOpen} >
                      Delete 
                    </Button>
                  </LightTooltip>
                  <LightTooltip  className={classes.hoverText} title="Leave organization" placement="right-start" arrow  >
                    <Button className={classes.leaveButton} onClick={this.handleLeaveOrganizationModalOpen} style={{marginRight: 'auto'}}>
                      Leave 
                    </Button>
                  </LightTooltip>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>

        {deleteOrganizationModalOpen? 
          <DeleteOrganizationModal organizationInfo={this.props.organizationInfo} open={deleteOrganizationModalOpen} handleCloseModal={this.handleClose} />
          :null}
        {leaveOrganizationModalOpen? 
          <LeaveOrganizationModal organizationInfo={this.props.organizationInfo} open={leaveOrganizationModalOpen} handleCloseModal={this.handleClose} />
          :null}
        
      </Grid>
    );
  }
}

EditOrganizationModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  fetchSelectedOrganization: currentOrganization => dispatch(fetchSelectedOrganization(currentOrganization)),
  fetchOrganizations: () => dispatch(fetchOrganizations()),

})

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles, {withTheme: true})(EditOrganizationModal));


const PaymentImagesJSON = [
  { id: 1, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/amex.svg`, title: 'amex', description: 'American Express' },
  { id: 2, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/cartes_bancaires.png`, title: 'cartes_bancaires', description: 'Cartes Bancaires' },
  { id: 3, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/diners_club.png`, title: 'diners_club', description: 'Diners Club' },
  { id: 4, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/discover.svg`, title: 'discover', description: 'Discover' },
  { id: 5, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/jcb.svg`, title: 'jcb', description: 'Japan Credit Bureau' },
  { id: 6, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/mastercard.svg`, title: 'mastercard', description: 'Master Card' },
  { id: 7, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/unionpay.png`, title: 'unionpay', description: 'Union Pay' },
  { id: 8, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/visa.svg`, title: 'visa', description: 'Visa' },
];

export default PaymentImagesJSON;
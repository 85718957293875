import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, rgbToHex } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import IconButton from '@material-ui/core/IconButton';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PasswordInfoModal from '../components/PasswordInfoModal';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import InitialDeviceConfigSetupModal from '../MyDevicesPage/InitialDeviceConfigSetupModal';
import CreateMachineFlowModal from '../components/CreateMachineFlowModal';
import CreateMachineFlowModalNoButton from '../components/CreateMachineFlowModalNoButton';
import DeleteSmartWidgetModal from '../components/DeleteSmartWidgetModal';
import DeleteMachineFlowModal from '../components/DeleteMachineFlowModal';
import Avatar from '@mui/material/Avatar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Box from '@mui/material/Box';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    maxWidth: theme.spacing(150),
    minWidth: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('md')]:{
      maxWidth: theme.spacing(110),
      minWidth: theme.spacing(60),
    },
    [theme.breakpoints.down('sm')]:{
      maxWidth: theme.spacing(70),
      minWidth: theme.spacing(60),
    },
    [theme.breakpoints.down('xs')]:{
      maxWidth: theme.spacing(43),
      minWidth: theme.spacing(43),
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'100px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'10px',
    width:'90px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    borderRadius: 10, 
    maxWidth: theme.spacing(150),
  },
  cardCSS: {
    width: '100%',
    borderRadius: '10px',
    border:'1px solid #f5f5f5',
    padding: '8px',
    backgroundColor: '#fafafa',
    boxShadow: '2px 4px #f5f5f5',
    cursor:'pointer',
    '&:hover': {
      // backgroundColor:'#eeeeee',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid #bdbdbd',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'#eeeeee',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor:'#eeeeee',
      border:'1px solid #bdbdbd',
      boxShadow: '2px 4px #bdbdbd',
    },
  },
  deviceCSS: {
    color:'#4f87cc',
    width: '240px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    margin: '10px',
    padding: '20px',
    textAlign: 'center',
    webkitBoxShadow: '0 30px 60px -12px rgb(50 50 93 / 25%), 0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%)',
    boxShadow: '0 30px 60px -12px rgb(50 50 93 / 25%), 0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%)',
    '&:hover': {
      // backgroundColor:'rgba(219, 219, 219, 0.3)',
      transform: 'translateY(-5px)',
    },
    [theme.breakpoints.down('md')]:{
      marginLeft:'auto',
      marginRight:'auto'
    },
  },
  headerText:{
    marginTop: '20px', 
    textTransform:'uppercase',
    [theme.breakpoints.up('md')]:{
      height: '80px',
    },
  },
  selectedDeviceCSS: {
    marginTop: '12px', 
    fontSize: '20px', 
    color:'#4f87cc', 
    cursor:'pointer',
    '&:hover': {
      color:'rgb(79, 135, 204, 0.8)',
      transform: 'translateY(-3px)',
    },
  },
  sectionPositions: {
    textAlign: 'center',
    justifyContent: 'center',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class SelectWidgetModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      widgetTemplates:[],
      chartType: '',
      selectedWidget:[],
      widgetOptions:[],
      selectMachineFlow:'',
      openCreateMachineFlowModal: false,
      openDeleteSmartWidgetModal: false,
      openDeleteMachineFlowModal: false,
      machineFlowsList: [],
      selectedExistingWidget: [],
      widgetList: [],
      isCreatingNew: false,
      selectedSmartWidgetToDelete:[],
      selectedMachineFlowToDelete:[],

    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.refreshMachineFlowList = this.refreshMachineFlowList.bind(this);
    this.refreshSmartWidgetList = this.refreshSmartWidgetList.bind(this);
  }

  componentDidMount(){
    // Get list of data streams
    getData('https://api.iotflows.com/v1/widget_templates')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){

          this.setState({
            widgetTemplates: data.data,
          })
        }
      }
    });

    // Get machine flows list
    getData('https://api.iotflows.com/v1/machines/' + this.props.machine_uuid + '/flows')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){

          this.setState({
            machineFlowsList: data.data,
          })
        }
      }
    });

    // Get smart widget list
    getData('https://api.iotflows.com/v1/machines/' + this.props.machine_uuid + '/widget_flows')
    .then(async response => {
      if(response && response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            widgetList: data.data,
          })
        }
      }
    });
  }

  refreshMachineFlowList = () => {
    // Get machine flows list
    getData('https://api.iotflows.com/v1/machines/' + this.props.machine_uuid + '/flows')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){

          this.setState({
            machineFlowsList: data.data,
          })
        }
      }
    });
  }

  refreshSmartWidgetList = () => {
     // Get smart widget list
    getData('https://api.iotflows.com/v1/machines/' + this.props.machine_uuid + '/widget_flows')
    .then(async response => {
      if(response && response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            widgetList: data.data,
          })
        }
      }
    });
  }

  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
    });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
      chartType: '',
      selectedWidget:[],
      widgetOptions:[],
      selectMachineFlow:'',
      openCreateMachineFlowModal: false,
      openDeleteSmartWidgetModal: false,
      openDeleteMachineFlowModal: false,
      selectedExistingWidget:[],
      selectedSmartWidgetToDelete:[],
      selectedMachineFlowToDelete:[]
    });
  };

  

  

  
handleSelectChart = (widget) => {
  
  if(widget.widget_template_initial_settings.options){
    for(let i = 0; i < Object.keys(widget.widget_template_initial_settings.options).length; i++){
      let key_arrays = Object.keys(widget.widget_template_initial_settings.options)
    }
    
    this.setState({
      widgetOptions: widget.widget_template_initial_settings.options
      })
    }else {
      this.setState({
        widgetOptions: []
      })
    } 
    // if(widget.widget_template_uuid == "wdgt_asset_info"){
    //   this.setState({
    //     chartType: widget.widget_template_name, 
    //     selectedWidget: widget,
    //     selectMachineFlow: ['empty name']
    //   })
    // }else{
    //   this.setState({
    //     chartType: widget.widget_template_name, 
    //     selectedWidget: widget,
    //   })
    // }
    this.setState({
      chartType: widget.widget_template_name, 
      selectedWidget: widget,
    })
  
}

handleCloseModal = () =>{
  this.setState({
    openCreateMachineFlowModal: false,
    openDeleteSmartWidgetModal: false,
    openDeleteMachineFlowModal: false,
  })
}

handleSelectMachineFlow = (e) => {
  if(e.target.value == "createNewMachineFlow"){
    this.setState({openCreateMachineFlowModal: true})
  }else{
    this.setState({selectMachineFlow: e.target.value})
  }
}
handleSelectWidget = (e) => {
  if(e.target.value == "createNewWidget"){
    this.setState({
      isCreatingNew: true,
      selectedExistingWidget: e.target.value,
      chartType: '',
      selectedWidget:[],
      widgetOptions:[],
      selectMachineFlow:'',
      openCreateMachineFlowModal: false,
    })
  }else{
    this.setState({
      selectedExistingWidget: e.target.value,
      isCreatingNew: false
    })
  }
}

createField = (widgetOptions) => {
  
const container = [];
    for(let i = 0; i < Object.keys(widgetOptions).length; i++){
      let key_arrays = Object.keys(widgetOptions);

      if(widgetOptions[key_arrays[i]].type == 'number'){
        let input =   <TextField
                        style={{marginTop: '0px'}}
                        margin="normal"
                        key={widgetOptions[key_arrays[i]].id}
                        id={widgetOptions[key_arrays[i]].id}
                        label={widgetOptions[key_arrays[i]].name}
                        type="number"
                        onChange={(e) => {
                          let allOptions = this.state.widgetOptions;
                          allOptions[key_arrays[i]].value = e.target.value
                          this.setState({widgetOptions: allOptions})
                          }
                        }
                        fullWidth
                      />
        container.push(input)
      }else if(widgetOptions[key_arrays[i]].type == 'string'){
        let input =  <TextField
                      style={{marginTop: '0px'}}
                      margin="normal"
                      key={widgetOptions[key_arrays[i]].id}
                      id={widgetOptions[key_arrays[i]].id}
                      label={widgetOptions[key_arrays[i]].name}
                      type="text"
                      onChange={(e) => {
                        let allOptions = this.state.widgetOptions;
                        allOptions[key_arrays[i]].value = e.target.value
                        this.setState({widgetOptions: allOptions})
                        }
                      }
                      fullWidth
                    />
        container.push(input)
      }else if(widgetOptions[key_arrays[i]].type == 'boolean'){
        let input =  <FormControl key={widgetOptions[key_arrays[i]].id} sx={{ mt: 2 }} component="fieldset">
                      <FormLabel  component="legend">{widgetOptions[key_arrays[i]].name}</FormLabel>
                      <RadioGroup row aria-label="arc" name="row-radio-buttons-group">
                        <FormControlLabel 
                            onChange={(e) => {
                              let allOptions = this.state.widgetOptions;
                              allOptions[key_arrays[i]].value = e.target.value
                              this.setState({widgetOptions: allOptions})
                              }
                            } 
                            value="false" 
                            control={<Radio />} 
                            label="No" 
                          />
                        <FormControlLabel 
                          onChange={(e) => {
                            let allOptions = this.state.widgetOptions;
                            allOptions[key_arrays[i]].value = e.target.value
                            this.setState({widgetOptions: allOptions})
                            }
                          } 
                          value="true" 
                          control={<Radio />} 
                          label="Yes" 
                          />
                      </RadioGroup>
                    </FormControl>
        container.push(input)
      } 
    }
    return container                  
}

  submitExistingWidget = () =>{
    let newItem = this.props.el;
    newItem.widget_uuid = this.state.selectedExistingWidget.widget_uuid;
    this.props.createWidgetDicAddWidgetToCard(this.state.selectedExistingWidget.widget_uuid, newItem)
    this.props.showSuccessSnackbar("Widget added to card");
  }

  submitButtonClick = () => {
    const { loading, selectedExistingWidget } = this.state;

      const settings = {  
        "flows":[
          {
            "flow_uuid": this.state.selectMachineFlow
          }
        ],
        "options": this.state.widgetOptions
        
      }
      if (!loading) {
        this.setState(
          {
            success: false,
            loading: true,
          },
          () => {
          postData('https://api.iotflows.com/v1/machines/' + this.props.machine_uuid + '/widgets', 
          {'widget_template_uuid': this.state.selectedWidget.widget_template_uuid,
            "widget_settings": settings,
          })
            .then(async response => {
              if(response.ok){
                const data = await response.json();
                this.props.showSuccessSnackbar("Widget has been successfully created!");
                let newItem = this.props.el;
                newItem.widget_uuid = data.data[0].new_widget_uuid
                this.props.createWidgetDicAddWidgetToCard(data.data[0].new_widget_uuid, newItem)
                this.handleClose()
                this.setState({
                  loading: false,
                  success: true,
                  open:false,
                });
              }
              else{
                try{
                  const data = await response.json();
                  this.props.showErrorSnackbar(data.message);
                  this.setState({
                    loading: false,
                    success: false,
                    open:false});
                }catch (e){
                  this.props.showErrorSnackbar('Something went wrong');
                }
              }
            })
          }
        )
      }
  };

  handleDeleteWidget = (e, widget) =>{
    e.stopPropagation()
    this.setState({
      selectedSmartWidgetToDelete: widget
    },() => {
      this.setState({openDeleteSmartWidgetModal: true})
    })
  }

  handleDeleteMachineFlow = (e, flow) =>{
    e.stopPropagation()
    this.setState({
      selectedMachineFlowToDelete: flow
    },() => {
      this.setState({openDeleteMachineFlowModal: true})
    })
  }

  handleBackButton = () => {
    this.setState({
      selectedExistingWidget:[],
      isCreatingNew:false,
      chartType: '',
      selectedWidget:[],
      widgetOptions:[],
      selectMachineFlow:'',
      openCreateMachineFlowModal: false,
    })
  }


  render() {
    const { classes, selectedOrganization } = this.props;
    const { open, 
            loading, 
            success,
            chartType,
            widgetTemplates,
            selectedWidget,
            widgetOptions,
            selectMachineFlow,
            openCreateMachineFlowModal,
            openDeleteSmartWidgetModal,
            openDeleteMachineFlowModal,
            machineFlowsList,
            selectedExistingWidget,
            isCreatingNew,
            widgetList,
            selectedSmartWidgetToDelete,
            selectedMachineFlowToDelete} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });


    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{height:'100%', marginTop: 'auto'}}
      >
        
        <Button   className={classes.button} variant="contained"   onClick={this.handleOpen}>
          Select Widget
          <SettingsOutlinedIcon/>
        </Button>
        

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                {openCreateMachineFlowModal &&
                <CreateMachineFlowModalNoButton asset_uuid={this.props.match.params.asset_uuid} refreshMachineFlowList={this.refreshMachineFlowList} handleCloseModal={this.handleCloseModal} open={openCreateMachineFlowModal} />
                }
                {/* Delete Smart Widget Modal */}
                {openDeleteSmartWidgetModal &&
                  <DeleteSmartWidgetModal selectedSmartWidgetToDelete={selectedSmartWidgetToDelete} refreshSmartWidgetList={this.refreshSmartWidgetList} handleCloseModal={this.handleCloseModal} open={openDeleteSmartWidgetModal} />
                }
                {/* Delete Machine Flow Modal */}
                {openDeleteMachineFlowModal &&
                  <DeleteMachineFlowModal selectedMachineFlowToDelete={selectedMachineFlowToDelete} refreshMachineFlowList={this.refreshMachineFlowList} handleCloseModal={this.handleCloseModal} open={openDeleteMachineFlowModal} />
                }
              
                
                    <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">
                      Select Widget
                    </DialogTitle>

                    <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px'}}>
                      <div>
                        

                        {!isCreatingNew && 
                        <>
                          <FormControl required variant="standard" sx={{ mt: 1,mb:3, width: '100%' }}>
                          <InputLabel id="demo-simple-select-standard-label">Select Widget</InputLabel>
                          <Select
                            value={selectedExistingWidget}
                            sx={{width:'100%'}}
                            onChange={this.handleSelectWidget}
                            label="Select Widget"
                            renderValue={(selected) => (
                              selected == 'createNewWidget'? "Create New Widget" : 
                              <Box sx={{ display: 'flex',alignItems:'center', flexWrap: 'wrap', gap: 0.5 }}>
                                <div>
                                  <Avatar
                                    alt={selected.widget_template_name}
                                    src={selected.widget_template_image_url}
                                    sx={{ width: 35, height: 35 }}
                                    variant="rounded"
                                  />
                                </div>
                                <div style={{marginLeft:'4px', display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                                  <span style={{fontSize:'15px', fontWeight:600, fontFamily:'Trebuchet MS'}}>{selected.widget_template_name}</span>
                                  <span style={{fontSize:'13px', fontWeight:400, fontFamily:'Trebuchet MS'}}>{selected.flow_name}</span>
                                </div>
                              </Box>
                            )}
                          >
                            <MenuItem style={{color:'#4f87cc', justifyContent:'center'}} value="createNewWidget">
                              <AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Create New Widget
                            </MenuItem>
                            {widgetList.map(widget => (
                              <MenuItem key={widget.widget_uuid} value={widget}>
                                <Box sx={{ display: 'flex',alignItems:'center', flexWrap: 'wrap', gap: 0.5, width:'100%' }}>
                                  <div>
                                    <Avatar
                                      alt={widget.widget_template_name}
                                      src={widget.widget_template_image_url}
                                      sx={{ width: 35, height: 35 }}
                                      variant="rounded"
                                    />
                                  </div>
                                  <div style={{marginLeft:'4px', display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                                    <span style={{fontSize:'15px', fontWeight:600, fontFamily:'Trebuchet MS'}}>{widget.widget_template_name}</span>
                                    <span style={{fontSize:'13px', fontWeight:400, fontFamily:'Trebuchet MS'}}>{widget.flow_name}</span>
                                  </div>
                                  <div style={{marginLeft:'auto'}} >
                                  <IconButton onClick={(e) => this.handleDeleteWidget(e, widget)}><DeleteOutlinedIcon /></IconButton>
                                  </div>
                                </Box>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        </>
                        }

                      {isCreatingNew && 
                      <>
                      <IconButton style={{marginLeft:'-16px', marginBottom:'-12px'}} onClick={this.handleBackButton} >
                        <ArrowBackIosNewOutlinedIcon sx={{ fontSize: 16 }}/>
                      </IconButton>
                      <div  className={classes.sectionPositions} style={{ display: (!chartType ) ? "block" : "none" }} >
                        <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '20px'}}>
                          <b>Select Chart type</b>
                        </DialogContentText>
                        <div style={{display:'flex'}} >
                        <Grid container spacing={3}>
                        {Object.keys(widgetTemplates).length > 0 &&
                          widgetTemplates.map(widget => (
                            <Grid key={widget.widget_template_uuid} item sm={12} md={4} lg={4}>
                              <div key={widget.widget_template_uuid} className={classes.deviceCSS} onClick={()=> this.handleSelectChart(widget)} >
                                <h5 className={classes.headerText}  >{widget.widget_template_name}</h5>
                                <Avatar
                                  alt={widget.widget_template_name}
                                  src={widget.widget_template_image_url}
                                  sx={{ 
                                    width: { xs: 200, sm: 200, md:200 }, 
                                    height:  { xs: 200, sm: 200, md:200 }, 
                                    borderRadius:'10px',
                                    ml: 'auto',
                                    mr:'auto',
                                    mt:'10px'
                                  }}
                                  variant="rounded"
                                />
                              </div>
                            </Grid>
                          ))
                        }
                        </Grid>
                        </div>
                      </div>

                      <div  style={{ display: (chartType) ? "block" : "none" }} >
                        <div className={classes.selectedDeviceCSS} onClick={()=> this.setState({chartType: '', selectMachineFlow:''})} >
                          <h5 style={{textTransform: 'uppercase', display: 'flex', margin:'0px auto'}}>
                            Chart Type:&nbsp;
                            {chartType}
                          </h5>
                        </div>
                        {selectedWidget && Object.keys(selectedWidget).length > 0 && selectedWidget.widget_template_uuid != "wdgt_asset_info" &&
                          <div>
                            <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '20px'}}>
                              <b>Chart Parameter</b>
                            </DialogContentText>
                            <FormControl required variant="standard" sx={{ mt: 1,mb:3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-standard-label">Select Machine Flow</InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectMachineFlow}
                                sx={{width:'100%'}}
                                onChange={this.handleSelectMachineFlow}
                                label="Machine Flow"
                              >
                                <MenuItem style={{color:'#4f87cc', justifyContent:'center'}} value="createNewMachineFlow">
                                  <AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Create Machine Flow
                                </MenuItem>
                                {machineFlowsList.map(flow => (
                                    <MenuItem disabled={isCreatingNew && selectedWidget && Object.keys(selectedWidget).length > 0 && selectedWidget.widget_template_required_settings && selectedWidget.widget_template_required_settings.flow_types && selectedWidget.widget_template_required_settings.flow_types[0] && (selectedWidget.widget_template_required_settings.flow_types[0] != flow.flow_mqtt_payload_parsing_expression.data_type)} value={flow.flow_uuid}>
                                      <Box sx={{ display: 'flex',alignItems:'center', flexWrap: 'wrap', width:'100%' }}>
                                        <div >
                                          <span style={{fontSize:'15px', fontWeight:600, fontFamily:'Trebuchet MS'}}>{flow.flow_name}</span>
                                        </div>
                                        <div style={{marginLeft:'auto'}} >
                                          <IconButton onClick={(e) => this.handleDeleteMachineFlow(e, flow)}><DeleteOutlinedIcon /></IconButton>
                                        </div>
                                      </Box>
                                    </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            {Object.keys(widgetOptions).length > 0 &&
                              <>
                                <div style={{margin:'16px 0px 0px 0px'}}>
                                  <span style={{fontSize: '14px', fontFamily:'Trebuchet MS', fontWeight: 500}}><i>Optional Parameters</i></span>
                                </div>
                                <div>
                                  {this.createField(widgetOptions)}
                                </div>
                              </>
                            }
                          </div> 
                        }
                      </div>
                      </>
                      }
                      </div>
                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>

                  {selectedExistingWidget && Object.keys(selectedExistingWidget).length > 0 &&  selectedExistingWidget != 'createNewWidget' ?
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={Object.keys(selectedExistingWidget).length == 0  } onClick={this.submitExistingWidget}>
                    {'Use Widget'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                  :
                  <Button  
                    color="primary" 
                    type='submit' 
                    className={buttonClassname} 
                    onClick={this.submitButtonClick}
                    disabled={loading || (chartType == '') || selectedWidget && Object.keys(selectedWidget).length == 0 || selectedWidget.widget_template_uuid != "wdgt_asset_info" && !selectMachineFlow } >
                    {'Create New'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                  }
                  
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

// export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(SelectWidgetModal));
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(withRouter(SelectWidgetModal)));


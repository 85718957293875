import React from 'react';
import { withAuthenticator } from 'aws-amplify-react'
import Amplify, { Auth, Hub } from 'aws-amplify';
import { useDispatch, useSelector } from "react-redux";
import {fetchSelectedIoTFlowsMemberRoleId} from '../store/actions/iotflowsMemberRoleActions';



  export async function getData(url = '') {
    var accessToken = '' ;
    try {
      accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
      
    }
    catch(e)
    {
      window.location.reload(false);
    }
    const response = await fetch(url, {
      method: 'GET', 
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken,
        // 'iotflows-member-role': selected_iotflows_member_role_id,
        'iotflows-member-role': localStorage.getItem('iotflows_member_role_id')
      },
      redirect: 'follow', 
      referrerPolicy: 'no-referrer', 
    });
    return response; 
  }

  export async function postData(url = '', data = {}) {
    var accessToken = ''
    try {
      accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    }
    catch(e)
    {
      window.location.reload(false);    
    }

    const response = await fetch(url, {
      method: 'POST', 
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken,
        // 'iotflows-member-role': selected_iotflows_member_role_id,
        'iotflows-member-role': localStorage.getItem('iotflows_member_role_id')
      },
      body: JSON.stringify(data),
      redirect: 'follow', 
      referrerPolicy: 'no-referrer', 
    });
    return response; 
  }

  export async function postDataText(url = '', data = {}) {
    var accessToken = ''
    try {
      accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    }
    catch(e)
    {
      window.location.reload(false);    
    }

    const response = await fetch(url, {
      method: 'POST', 
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Content-Type': 'text/html; charset=UTF-8',
        'Authorization': 'Bearer ' + accessToken,
        // 'iotflows-member-role': selected_iotflows_member_role_id,
        'iotflows-member-role': localStorage.getItem('iotflows_member_role_id')
      },
      body: data,
      redirect: 'follow', 
      referrerPolicy: 'no-referrer', 
    });
    return response; 
  }


  export async function postDataProfilePic(url = '', data = '') {
    var accessToken = ''
    try {
      accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    }
    catch(e)
    {
      window.location.reload(false);    
    }
    const response = await fetch(url, {
      method: 'POST', 
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        // 'iotflows-member-role': selected_iotflows_member_role_id,
        'iotflows-member-role': localStorage.getItem('iotflows_member_role_id')
      },
      body: data,
      redirect: 'follow', 
      referrerPolicy: 'no-referrer', 
    });
    return response; 
  }


  export async function putData(url = '', data = {}) {
    var accessToken = '' 
    try {
      accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    }
    catch(e)
    {
      window.location.reload(false);    
    }
    const response = await fetch(url, {
      method: 'PUT', 
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken,
        // 'iotflows-member-role': selected_iotflows_member_role_id,
        'iotflows-member-role': localStorage.getItem('iotflows_member_role_id')
      },
      body: JSON.stringify(data),
      redirect: 'follow', 
      referrerPolicy: 'no-referrer', 
    });
    return response; 
  }

  export async function deleteData(url = '', data = {}) {
    var accessToken = '' 
    try {
      accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    }
    catch(e)
    {
      window.location.reload(false);    
    }
    const response = await fetch(url, {
      method: 'DELETE', 
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken,
        // 'iotflows-member-role': selected_iotflows_member_role_id,
        'iotflows-member-role': localStorage.getItem('iotflows_member_role_id')
      },
      body: JSON.stringify(data),
      redirect: 'follow', 
      referrerPolicy: 'no-referrer', 
    });
    return response; 
  }


  

  export async function updateUserPhoneNumber(phone_number) {
    let user = await Auth.currentAuthenticatedUser();
    let response = await Auth.updateUserAttributes(user, {'phone_number': phone_number});
    if(response == 'SUCCESS')
    {
      // verify the phone number by generating a code
      Auth.verifyCurrentUserAttribute('phone_number');      
    }    
    return response; 
  }


  export async function verifyUserPhoneNumber(code) 
  {
    let response = await Auth.verifyCurrentUserAttributeSubmit('phone_number', code);
    return response; 
  }
  
  
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import  {getData } from '../ApiCalls/DataApis';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);


const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    width: 'auto',
    height: 'auto',
    padding: '4px',
    margin: '0px',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-end',
    alignItems:'flex-end',
    alignItems: 'flex-end',
    fontFamily:'Trebuchet MS',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class RebootCloudServerModal extends React.Component {
  state = {
    open: false,
    loading: false,
    success: false,
  };

  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false
    });
  };

  submitButtonClick = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          getData('https://api.iotflows.com/v1/cloud-servers/'+ this.props.cloud_server_uuid +'/reboot')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                this.props.showSuccessSnackbar('Cloud Server has been restarted');
                this.handleClose();
              }else{
                try{
                  const data = await response.json();
                  this.props.showErrorSnackbar(data.message)
                  this.handleClose();
                }catch (e){
                  this.props.showErrorSnackbar('Something went wrong')
                  this.handleClose();
                }
              }
            });
        }
      )
    }
  };

  

  render() {
    const { classes } = this.props;
    const { open, loading, success } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
        <LightTooltip title="Reboot Cloud Server">
          <IconButton className={classes.button} variant="outlined" onClick={this.handleOpen}><CachedOutlinedIcon style={{color: '#757575'}}/></IconButton>
        </LightTooltip>
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title"> Reboot Cloud Node-RED Server <b>{this.props.cloud_server_name}</b> </DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '20px'}}>
                
                  <DialogContentText style={{margin: '0px'}} id="alert-dialog-description">
                    Are you sure you want to reboot Cloud Node-RED Server <b>{this.props.cloud_server_name}</b> ?
                  </DialogContentText>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Reboot { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(RebootCloudServerModal));

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Typography from '@material-ui/core/Typography';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {getData} from '../ApiCalls/DataApis';
import MqttService from '../core/services/MqttService';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  cardRoot: {
    borderRadius: '10px',
    margin: '5px',
  },
  chartContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: '14px',
    fontWeight: 450,
    fontFamily:'Trebuchet MS',
  },
  cardValue: {
    fontSize:'26px',
    fontWeight: 550,
    fontFamily:'Trebuchet MS',
  },
  switchText: {
    textAlign:'right',
    [theme.breakpoints.down('xs')]: {
      textAlign:'left',
    },
  },
});


class BillingOverviewPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      totalCost: 0.0,
      forecastCost: 0.0,
      currentDeviceNumber: 0,
      totalInteractions: 0,
      totalCurrentInteractions: 0,
      currentDailyInteractionCount: 0,
      yesterdaysDate: '',
      firstDayOfMonth: '',
      redirect: false,
      value: 0,
      costOptions: {
        credits: {enabled: false},
        series: [{
          type: 'column',
          color: '#4caf50',
          name: 'Estimated cost',          
          data: [],
          dataGrouping: {
            units: [ [
                'month',
                [1, 2, 3, 4, 6]
            ]]
          }
        }]
      },
      deviceOptions: {
        credits: {enabled: false},
        series: [{
          type: 'column',
          name: 'Devices',          
          data: [],
          // dataGrouping: {
          //   units: [[
          //       'week', // unit name
          //       [1] // allowed multiples
          //   ], [
          //       'month',
          //       [1, 2, 3, 4, 6]
          //   ]]
          // }
        }]
      },
      interactionOptions: {
        credits: {enabled: false},
        series: [{
          type: 'column',
          name: 'Interactions',
          data: [],
          // dataGrouping: {
          //   units: [[
          //       'week', // unit name
          //       [1] // allowed multiples
          //   ], [
          //       'month',
          //       [1, 2, 3, 4, 6]
          //   ]]
          // }
        }]
      },
    }
  }

  componentDidMount() {

    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1); 
    this.setState({yesterdaysDate: yesterday.toDateString()});

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    this.setState({firstDayOfMonth: firstDay.toDateString()});
    
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/daily_cost/highcharts')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            costOptions: {
              series: [
                {data: JSON.parse(data.data)}
              ]
            }
          });
        }
      });
   
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/daily_interaction_usage/highcharts')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
         
          
          this.setState({
            interactionOptions: {
              series: [
                {data: JSON.parse(data.data)}
              ]
            }
          });
        }
      });

      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/daily_device_usage/highcharts')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            deviceOptions: {
              series: [
                {data: JSON.parse(data.data)}
              ]
            }
          });
        }
      });

      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/this_month_usage')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          var totalInteractions = data.data[0].current_month_interaction;
          if(totalInteractions > 1000000){
            totalInteractions = (Number.parseFloat(totalInteractions)/1000000.00).toFixed(1)+"M"
          }
          else if( totalInteractions > 1000 ){
            totalInteractions = (Number.parseFloat(totalInteractions)/1000.00).toFixed(1)+"k"
          }
          // MAKE ZERO if null
          if((totalInteractions == null && data.data[0].current_device_count == 0) || (data.data[0].current_month_cost == null)){
            this.setState({
              totalCost: 0.0,
              forecastCost: 0.0,
              currentDeviceNumber: data.data[0].current_device_count,
              totalInteractions: 0,
              totalCurrentInteractions: 0,
            });
          } else if( totalInteractions == null) {
            this.setState({
              totalCost: data.data[0].current_month_cost.toFixed(2),
              forecastCost: data.data[0].forecasted_cost.toFixed(2),
              currentDeviceNumber: data.data[0].current_device_count,
              totalInteractions: 0,
              totalCurrentInteractions: 0,
            });
          }else {
            this.setState({
              totalCost: data.data[0].current_month_cost.toFixed(2),
              forecastCost: data.data[0].forecasted_cost.toFixed(2),
              currentDeviceNumber: data.data[0].current_device_count,
              totalInteractions: totalInteractions,
              totalCurrentInteractions: data.data[0].current_month_interaction,
            });
          }
          
        }
      });

      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/current_interaction_count')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            currentDailyInteractionCount: parseInt(data.data),
          });
        }
      });

      // MQTT subscribing to everything
      if(MqttService.isConnected) {
        MqttService.subscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/#', this.onEverything)
        
      }

  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    MqttService.unsubscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/#')
    
  }

  onEverything = message => {

    try{
      var addingInteractions = this.state.currentDailyInteractionCount + 1;
      var currentTotal = this.state.totalCurrentInteractions + addingInteractions;
      var totalInteractions = '';

      if(currentTotal > 1000000){
        totalInteractions = (Number.parseFloat(currentTotal)/1000000.00).toFixed(1)+"M"
      }
      else if( currentTotal > 1000 ){
        var totalInteractions = (Number.parseFloat(currentTotal)/1000.00).toFixed(1)+"k"
      }
      
      this.setState({
        currentDailyInteractionCount: addingInteractions,
        totalInteractions: totalInteractions,
      })
    }
    catch(e){
      console.log("Error in reading On Everything notification from MQTT");
      console.log(e.message);
    }
  }

  render(){
    const { classes, theme} = this.props
    const {costOptions,
          interactionOptions,
          deviceOptions,
          totalCost,
          totalInteractions,
          currentDeviceNumber,
          forecastCost,
          currentDailyInteractionCount,
          firstDayOfMonth,
          yesterdaysDate,
          } = this.state

  

    return (
      <div className={classes.root}>

        {/* Card to display current cost  */}
        <Card className={classes.cardRoot} variant="outlined">
          <CardContent style={{textAlign: 'left'}}>
            <Grid container spacing={1}>
              <Grid item sm={6}>
                <Typography className={classes.cardValue} >
                  ${totalCost}
                </Typography>
                <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                  Current Estimated Month's Cost 
                </Typography>
                <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                  {/* ({firstDayOfMonth} - {yesterdaysDate}) */}
                </Typography>
              </Grid>
              <Grid className={classes.switchText} item sm={6}>
                <Typography className={classes.cardValue}  >
                  ${forecastCost}
                </Typography>
                <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                  Month's Estimated Forecast Cost
                </Typography>
              </Grid>
            </Grid>
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={costOptions}
            />
          </CardContent>
        </Card>
          
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Card className={classes.cardRoot} variant="outlined">
              <CardContent style={{textAlign: 'left'}}>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <Typography className={classes.cardValue} >
                    {totalInteractions}
                  </Typography>
                  <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                    Current Month's Interaction
                  </Typography>
                </Grid>
                <Grid item sm={6} className={classes.switchText}  >
                  <Typography className={classes.cardValue} >
                    {currentDailyInteractionCount}
                  </Typography>
                  <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                    Today's Interactions
                  </Typography>
                </Grid>
              </Grid>
                
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={interactionOptions}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.cardRoot} variant="outlined">
              <CardContent style={{textAlign: 'left'}}>
                <Typography className={classes.cardValue} >
                  {currentDeviceNumber}
                </Typography>
                <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                  Current Linux Devices
                </Typography>
                
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={deviceOptions}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </div>
        
       
    )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{ withTheme: true })(BillingOverviewPage));
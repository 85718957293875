import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, rgbToHex } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import IconButton from '@material-ui/core/IconButton';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PasswordInfoModal from '../components/PasswordInfoModal';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    minWidth: '180px',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { 
    borderRadius: 10 
  },
  listCard: {
    width:"100%",
    borderRadius: "5px",
    marginBottom: "12px",
    border:'1px solid rgb(87, 147, 222)',
    cursor:'pointer',
    // backgroundColor:'transparent',
    backgroundColor:'rgba(87, 147, 222, 0.7)',
    padding:'3px 10px',
    '&:hover': {
      // backgroundColor:'transparent',
      backgroundColor:'rgba(87, 147, 222, 0.9)',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'rgba(87, 147, 222, 1)',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor:'rgba(87, 147, 222, 1)',
    },
  },
  listCardSelected: {
    width:"100%",
    borderRadius: "5px",
    marginBottom: "12px",
    border:'1px solid rgb(87, 147, 222)',
    cursor:'pointer',
    position:'relative',
    backgroundColor:'rgba(87, 147, 222, 1)',
    padding:'3px 10px',
    outline:'none',
  },
  listCardText: {
    textAlign: 'left',
    fontSize: "14px",
    color: "rgb(255,255,255)",
  },
  cardCSS: {
    borderRadius: '10px',
    border:'1px solid #f5f5f5',
    padding: '8px',
    backgroundColor: '#fafafa',
    boxShadow: '2px 4px #f5f5f5',
    cursor:'pointer',
    '&:hover': {
      // backgroundColor:'#eeeeee',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid #bdbdbd',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'#eeeeee',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor:'#eeeeee',
      border:'1px solid #bdbdbd',
      boxShadow: '2px 4px #bdbdbd',
    },
  },
  cardCSSSelected: {
    borderRadius: '10px',
    padding: '8px',
    outline: 'none',
    backgroundColor:'#eeeeee',
    border:'1px solid #bdbdbd',
    boxShadow: '2px 4px #bdbdbd',
    position:'relative',
    cursor:'pointer',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  headerCSS: {
    textAlign: 'center',
    color: '#1976d2',
    fontFamily:'Trebuchet MS',
  },
  bodyCSS: {
    fontSize: '14px',
    color: '#525f7f',
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
  },
  pricingCSS: {
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
    marginTop: '16px',
    marginBottom: '16px',
  },
  customButton: {
    width: '100%',
    marginBottom: '10px', 
    marginTop: '10px',
    borderRadius: '5px',
    border:'1px solid #f5f5f5',
    backgroundColor:'rgba(87, 147, 222, 0.1)',
    '&:hover': {
      backgroundColor:'rgba(87, 147, 222, 0.3)',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid rgba(87, 147, 222, 0.5)',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'rgba(87, 147, 222, 0.4)',
      border:'1px solid rgba(87, 147, 222, 0.7)',
    },
    '&:focus': {
      outline: 'none',
      // backgroundColor:'#eeeeee',
      // border:'1px solid #bdbdbd',
      // boxShadow: '2px 4px #bdbdbd',
    },
  }

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class AddRemoteConnectionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      loading: false,
      success: false,
      sshUsername: '',
      sshPassword: '',

    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
      sshUsername: '',
      sshPassword: '',
    });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
      sshUsername: '',
      sshPassword: '',
    });
    this.props.handlerCloseFileManagerCredentialsModal();
  };

  handleSshUsernameChange = (event) => {
    this.setState({sshUsername: event.target.value});
  }
  handleSshPasswordChange = (event) => {
    this.setState({sshPassword: event.target.value});
  }

  

  submitButtonClick = () => {
    
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          localStorage.setItem('ssh_username', this.state.sshUsername);
          localStorage.setItem('ssh_password', this.state.sshPassword);
          this.props.showSuccessSnackbar("Initiating Device File Manager!");
          this.handleClose();
        // postData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid +'/connections', 
        // {'device_username': this.state.sshUsername,
        //   'device_password': this.state.sshPassword,
        //   })
        //   .then(async response => {
        //     if(response.ok){
        //       const data = await response.json();
        //       this.props.showSuccessSnackbar("Initiating Device File Manager!");
        //       this.handleClose();
        //       this.setState({
        //         loading: false,
        //         success: true,
        //         open:false,
        //       });
        //     }
        //     else{
        //       try{
        //         const data = await response.json();
        //         this.props.showErrorSnackbar(data.message);
        //         this.setState({
        //           loading: false,
        //           success: false,
        //           open:false});
        //       }catch (e){
        //         this.props.showErrorSnackbar('Something went wrong');
        //       }
        //     }
        //   })
        }
      )
    }
  };

  


  render() {
    const { classes } = this.props;
    const { open, 
            loading, 
            success, 
            sshUsername,
            sshPassword} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
      >
        
        {/* <Button className={classes.button} variant="contained"   onClick={this.handleOpen}><AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Remote Connection </Button> */}

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Secure File Transfer</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px'}}>

                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontSize:'12', marginBottom: '8px'}}><i>* Enter the SSH credentials of your device</i></span>
                    <span style={{fontSize:'12'}}>For virtual devices</span>
                    <span style={{fontSize:'12'}}><i>SSH Username: <b>iotflows</b></i></span>
                    <span style={{fontSize:'12'}}><i>SSH Password: <b>password</b></i></span>
                  </div>
                  <TextField
                    autoFocus
                    margin="normal"
                    id="device_username"
                    label="SSH Username"
                    type="text"
                    value={sshUsername} 
                    onChange={this.handleSshUsernameChange}
                    fullWidth
                  />
                  <TextField
                    margin="normal"
                    id="device_password"
                    label="SSH Password"
                    type="password"
                    value={sshPassword} 
                    onChange={this.handleSshPasswordChange}
                    fullWidth
                  />



                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading || (sshUsername == '') || (sshPassword == '') } onClick={this.submitButtonClick}>
                    {'Connect'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

AddRemoteConnectionModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AddRemoteConnectionModal));

import React from 'react';
import { withStyles} from '@material-ui/core/styles';
import {getData, postData, putData, deleteData} from '../ApiCalls/DataApis';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIoTApiKeyModal from '../components/DeleteIoTApiKeyModal';
import EditIoTApiKeyModal from '../components/EditIoTApiKeyModal';
import CreateIoTApiKeyModal from '../components/CreateIoTApiKeyModal';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '3px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
  },
  tableContainer: {
    borderRadius: '10px',
  },
  table: {
    minWidth: 400,
  },
  readOnlyCSS: {
    borderRadius: '4px',
    backgroundColor: 'rgb(201, 208, 244)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
    width: '67px',
  },
  readWriteCSS: {
    borderRadius: '5px',
    backgroundColor: 'rgb(203, 244, 201)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
    width: '112px',
  },
  headersCSS: {
    fontSize: '24px',
    fontFamily:'Trebuchet MS',
  }
});


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);


class ApiKeysPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      organization_clients: [],
      line_items: [],
      line_items_this_month: [],
      line_items_next_month: [],
      next_month_start_date: '',
      next_month_end_date: '',
    }
    this.refreshClientList = this.refreshClientList.bind(this);
  }

  componentDidMount() {
      
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/clients')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            organization_clients: data.data
          });
        }
      });
  }

  refreshClientList(){
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/clients')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        this.setState({
          organization_clients: data.data,
        })
      }
    });
  }
  

  dateConverter(date){
    // date.toLocaleDateString() + ', ' + date.toLocaleTimeString();
    if(date != undefined){
      // var modifyDate = date*1000;
      var modifyDate = new Date(date);
      // return modifyDate.toLocaleDateString();
      return modifyDate.toLocaleDateString([], {  month: 'short', day: 'numeric' });
    } else {
      return " "
    }
  }

  render(){
    const { classes} = this.props
    const {organization_clients} = this.state


    return (
      <div className={classes.root} style={{marginBottom: '60px'}}>
        <div className={classes.breadcrumbCSS}>
          <Breadcrumbs  separator="›" aria-label="breadcrumb">
            <Typography className={classes.fontStyles} style={{color:'#757575'}}>{this.props.selectedOrganization.organization_name}</Typography>
            <Typography className={classes.fontStyles} color="textPrimary">API Keys</Typography>
          </Breadcrumbs>
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="spanning table">
            <colgroup>
              <col style={{width:'15%'}}/>
              <col style={{width:'15%'}}/>
              <col style={{width:'25%'}}/>
              <col style={{width:'25%'}}/>
              <col style={{width:'10%'}}/>
              <col style={{width:'5%'}}/>
              <col style={{width:'5%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{fontSize: '24px', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={3}>
                  <b>IoT API Keys</b>
                </StyledTableCell>
                <StyledTableCell style={{paddingRight: '12px'}} align="right" colSpan={4}>
                  <CreateIoTApiKeyModal refreshClientList={this.refreshClientList}/>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell style={{paddingLeft: '12px'}}>Name</StyledTableCell>
                <StyledTableCell >Authorization</StyledTableCell>
                <StyledTableCell >Description</StyledTableCell>
                <StyledTableCell >Client username</StyledTableCell>
                <StyledTableCell style={{paddingRight: '12px'}} >Created</StyledTableCell>
                <StyledTableCell ></StyledTableCell>
                <StyledTableCell ></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organization_clients.map((client, index) => (
                <TableRow key={index} >
                  <StyledTableCell style={{paddingLeft: '12px'}}>{client.client_name}</StyledTableCell>
                  <StyledTableCell align="left">
                    {client.client_is_organization_subscription_only ? 
                      <div className={classes.readOnlyCSS} >
                        <span style={{fontSize: '11px', color:'rgb(14, 98, 69)'}}>Read Only</span>
                      </div>
                      :
                      <div className={classes.readWriteCSS} >
                        <span style={{fontSize: '11px', color:'rgb(138, 138, 138)'}} >Read/Write Access</span>
                      </div>
                    }
                  </StyledTableCell>
                  <StyledTableCell >{client.client_description}</StyledTableCell>
                  <StyledTableCell ><code>{client.client_id}</code></StyledTableCell>
                  <StyledTableCell style={{paddingRight: '12px'}} >{this.dateConverter(client.client_created_at)}</StyledTableCell>
                  <StyledTableCell align="right" style={{paddingRight: '12px'}} >
                    <EditIoTApiKeyModal client_id={client.client_id} refreshClientList={this.refreshClientList} iotApiKeyName={client.client_name} iotApiKeyDescription={client.client_description} />
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{paddingRight: '12px'}} >
                    <DeleteIoTApiKeyModal client_id={client.client_id} refreshClientList={this.refreshClientList} />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
      </div>
      )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ApiKeysPage));
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LogsTable from './LogsTable';
import {Container} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { connect} from "react-redux";
import {getData} from '../ApiCalls/DataApis';
import "../styles/HeartbeatLED.scss";
import "../styles/WaitingDataPulse.scss";
import LogsDataModal from './LogsDataModal';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    // maxWidth: 752,
  },
  title: {
    margin: theme.spacing(0, 1, 1),
    fontFamily:'Trebuchet MS',
  },
  header: {
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: '10px',
  },
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '0px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
  },
  waitingCSS:{
    // padding: '5px',
    position: 'absolute',
    // borderRadius: '10px',
    // backgroundColor: '#fafafa',
    // border:'1px solid #4f86ce',
    zIndex: '2',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    left: 'calc(50% - 148px)',
    top: '15%',
    // textShadow:'1px 1px 1px #9eb9ff',
  }
});




class MainDataStreamPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      rows: [],
      logDataRow: [],
      openLogsDataModal: false,
    }
    this.handleCloseLogsDataModal = this.handleCloseLogsDataModal.bind(this);

  }


  // MQTT Functions Below
  componentDidMount() {
    
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/events')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        var modifyDate = {};
        for(var i=0; i<Object.keys(data.data).length; i++){
          // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
          modifyDate[i] = new Date(data.data[i].organization_event_timestamp);
          modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
          data.data[i].organization_event_timestamp = modifyDate[i];
        }
        this.setState({
          rows: data.data,
        })
      }
    });
  }

  handleCloseLogsDataModal = () => {
    this.setState({
      openLogsDataModal: false,
      logDataRow: []
      })
  }

  render(){
    const { classes, selectedOrganization} = this.props
    const { rows,
            logDataRow,
            openLogsDataModal } = this.state


    return (
      <div className={classes.root} style={{marginBottom: '60px'}}>
        <div className={classes.breadcrumbCSS}>
          <Breadcrumbs  separator="›" aria-label="breadcrumb">
            {/* <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/" >
              {this.props.selectedOrganization.organization_name}
            </Link> */}
            <Typography className={classes.fontStyles} style={{color:'#757575'}}>{selectedOrganization.organization_name}</Typography>
            <Typography className={classes.fontStyles} color="textPrimary">Logs</Typography>
          </Breadcrumbs>
        </div>

        <Container  >
          <Row className={classes.header}>
            <Typography variant="h5" className={classes.title}>
              Organization Logs
            </Typography>
          </Row>

        
          <Paper  style={{ height:900}}>   
          {rows == '' ? 
             <Paper elevation={3}  >
               <div className={classes.waitingCSS}  >
                  <span className="waitingOnData"> 
                  <Col   >
                    <Row style={{ justifyContent:'center'}}>
                      <Typography  variant="subtitle2" style={{ fontFamily:'Trebuchet MS'}}  >
                        WAITING ON LOG
                      </Typography>
                    </Row>
                    <Row style={{ justifyContent:'center'}}>
                      <Typography  variant="body2"  style={{ fontSize: '12px', fontFamily:'Trebuchet MS' }}>
                          Log messages will be published here
                      </Typography>
                    </Row>
                  </Col>
                  </span>
                </div>
             </Paper> 
            : null}

            <LogsTable 
              rowCount={rows.length}
              rowGetter={({ index }) => rows[index]}
              onRowClick={(event) => { 
                this.setState({
                  logDataRow: event.rowData.organization_event_description 
                }, ()=> this.setState({openLogsDataModal: true }))
              }}
              columns={[
                {
                  width: 1000,
                  label: 'Log',
                  dataKey: 'organization_event_description',
                },
                {
                  width: 400,
                  label: 'Received At',
                  dataKey: 'organization_event_timestamp',
                  date: true,
                },
              ]}
            />
            {openLogsDataModal?
              <LogsDataModal openLogsDataModal={openLogsDataModal} title={'Log Information'} logDataRow={logDataRow} handleCloseLogsDataModal={this.handleCloseLogsDataModal} />
            : null}
            </Paper>
        </Container>
      </div>

    );
    
  } 
}

const mapStateToProps = state => {
  return {
    // currentUserInfo: state.users.currentUserInfo,
    // allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

export default connect(mapStateToProps)(withStyles(styles)(MainDataStreamPage))
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import ReactJson from 'react-json-view';
import CsvTable from './CsvTable'


const styles = theme => ({
  paper: {
    position: 'relative',
    // width: '100%',
    width: theme.spacing(100),
    maxWidth: '1400px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    // [theme.breakpoints.down('lg')]:{
    //   width: theme.spacing(200),
    //   maxWidth: '850px',
    // },
    // [theme.breakpoints.down('md')]:{
    //   width: theme.spacing(80),
    // },
    // [theme.breakpoints.down('sm')]:{
    //   width: theme.spacing(43),
    // },
    // [theme.breakpoints.down('xs')]:{
    //   width: theme.spacing(43),
    // },
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    minWidth: '180px',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  dialog: { 
    borderRadius: 10,
    maxWidth: '1500px' ,
  },
  headerContainer: {
    // paddingLeft: '0px',
  },
  // header: {
  //   fontFamily:'Trebuchet MS',
  //   fontSize: '14px',
  //   color:'#212121',
  // },
  headerItem: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
    color: '#757575',
  },
  headerItemValue: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
    color:'#212121',
  },
  // subHeaderContainer:{
  //   // paddingLeft: '4px',
  // },
  // subHeader: {
  //   fontFamily:'Trebuchet MS',
  //   fontSize: '14px',
  //   color:'#212121',
  // },
  subHeaderItem: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
    color: '#757575',
    paddingLeft: '12px',
  },
  subHeaderItemValue: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
    color:'#212121',
  },
  // thirdSubHeaderContainer: {
  //   // paddingLeft: '8px',
  // },
  // thirdSubHeader: {
  //   fontFamily:'Trebuchet MS',
  //   fontSize: '14px',
  //   color:'#212121',
  // },
  thirdSubHeaderItem: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
    color: '#757575',
    paddingLeft: '24px',
  },
  thirdSubHeaderItemValue: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
    color:'#212121',
  }

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DeviceDataModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      selectedDevice: this.props.selectedDevice,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }


  // componentWillReceiveProps(nextProps) {
  //   this.setState({
  //     open: nextProps.open,
  //     selectedDevice: nextProps.selectedDevice,
  //   }); 
  // };

  handleOpen = () => {
    this.setState({ 
      open: true,
    });
  };

  handleClose = () => {
    this.props.handlerCloseExpandDeviceInfoModal();
    this.setState({ 
      open: false,
    });
  };

  convertToPercent = (number) => {
    if(number){
      var percent = number
      percent = percent.toFixed(2)+ " %"

      return percent
    }
  }

  formatBytes = (bytes, decimals = 1) => {
    try{
      if (bytes == 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k));
  
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
    }
    catch(e){
      return "-"
    }
    
  }

  dataJSONFormater = (deviceInfo) => {
    try{
      // var parsedDeviceData = JSON.parse(deviceInfo)

      return <ReactJson src={deviceInfo} /> 
    }
    catch(e){
      console.log('error converting to JSON')
      console.log(e)
      this.props.showErrorSnackbar('Issues displaying device information')
      return deviceInfo
    }
  }

  


  render() {
    const { classes, selectedDevice } = this.props;
    const { open,
          } = this.state;


    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
      >
        
        {/* <Button className={classes.button} variant="contained"   onClick={this.handleopenDeviceInfoModal}><AddCircleOutlineOutlinedIcon />&nbsp;Remote Connection </Button> */}

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">{this.props.selectedDevice.device_name} device system information</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px'}}>
                  
                  <div style={{overflowY:'auto'}}>
                    {this.dataJSONFormater(selectedDevice.device_system_information)}
                  </div>
                </DialogContent>
                <DialogActions style={{justifyContent:'flex-start'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  {/* <Button  style={{marginLeft:'auto'}} color="primary" >
                    {'Download'}
                  </Button> */}
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

DeviceDataModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(DeviceDataModal));






{/* <div className={classes.headerItem} >
  CPU&nbsp;
</div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Brand:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.brand}
      </div>
    </div>
    <div className={classes.subHeaderItem} >
      Cache&nbsp;
    </div>
        <div style={{display: 'flex'}}>
          <div className={classes.thirdSubHeaderItem} >
            l1d:&nbsp;
          </div>
          <div className={classes.thirdSubHeaderItemValue} >
            {selectedDevice.device_system_information.cpu.cache.l1d}
          </div>
        </div>
        <div style={{display: 'flex'}}>
          <div className={classes.thirdSubHeaderItem} >
            l1i:&nbsp;
          </div>
          <div className={classes.thirdSubHeaderItemValue} >
            {selectedDevice.device_system_information.cpu.cache.l1i}
          </div>
        </div>
        <div style={{display: 'flex'}}>
          <div className={classes.thirdSubHeaderItem} >
            l2:&nbsp;
          </div>
          <div className={classes.thirdSubHeaderItemValue} >
            {selectedDevice.device_system_information.cpu.cache.l2}
          </div>
        </div>
        <div style={{display: 'flex'}}>
          <div className={classes.thirdSubHeaderItem} >
            l3:&nbsp;
          </div>
          <div className={classes.thirdSubHeaderItemValue} >
            {selectedDevice.device_system_information.cpu.cache.l3}
          </div>
        </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Cores:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.cores}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Family:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.family}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Flags:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.flags}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Governor:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.governor}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Manufacturer:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.manufacturer}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Model:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.model}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Physical Cores:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.physicalCores}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Processors:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.processors}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Revision:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.revision}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Socket:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.socket}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Speed:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.speed}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Speed max:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.speedMax}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Speed min:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.speedMin}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Stepping:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.stepping}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Vendor:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.vendor}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Virtualization:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.virtualization}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Voltage:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.cpu.voltage}
      </div>
    </div>
<div className={classes.headerItem} >
  Current Load&nbsp;
</div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Average load:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.currentLoad.avgLoad}
      </div>
    </div>
    {selectedDevice.device_system_information.currentLoad.cpus.map((cpu, index) => (
      <>
      <div className={classes.subHeaderItem} >
        CPU {index + 1}
      </div>
          <div style={{display: 'flex'}}>
            <div className={classes.thirdSubHeaderItem} >
              Load:&nbsp;
            </div>
            <div className={classes.thirdSubHeaderItemValue} >
              {this.convertToPercent(selectedDevice.device_system_information.currentLoad.cpus[index].load)}
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className={classes.thirdSubHeaderItem} >
              Load idle:&nbsp;
            </div>
            <div className={classes.thirdSubHeaderItemValue} >
              {this.convertToPercent(selectedDevice.device_system_information.currentLoad.cpus[index].loadIdle)}
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className={classes.thirdSubHeaderItem} >
              Load IRQ:&nbsp;
            </div>
            <div className={classes.thirdSubHeaderItemValue} >
              {this.convertToPercent(selectedDevice.device_system_information.currentLoad.cpus[index].loadIrq)}
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className={classes.thirdSubHeaderItem} >
              Load nice:&nbsp;
            </div>
            <div className={classes.thirdSubHeaderItemValue} >
              {this.convertToPercent(selectedDevice.device_system_information.currentLoad.cpus[index].loadNice)}
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className={classes.thirdSubHeaderItem} >
              Load system:&nbsp;
            </div>
            <div className={classes.thirdSubHeaderItemValue} >
              {this.convertToPercent(selectedDevice.device_system_information.currentLoad.cpus[index].loadSystem)}
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className={classes.thirdSubHeaderItem} >
              Load user:&nbsp;
            </div>
            <div className={classes.thirdSubHeaderItemValue} >
              {this.convertToPercent(selectedDevice.device_system_information.currentLoad.cpus[index].loadUser)}
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className={classes.thirdSubHeaderItem} >
              Raw load:&nbsp;
            </div>
            <div className={classes.thirdSubHeaderItemValue} >
              {selectedDevice.device_system_information.currentLoad.cpus[index].rawLoad}
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className={classes.thirdSubHeaderItem} >
              Raw load idle:&nbsp;
            </div>
            <div className={classes.thirdSubHeaderItemValue} >
              {selectedDevice.device_system_information.currentLoad.cpus[index].rawLoadIdle}
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className={classes.thirdSubHeaderItem} >
              Raw load IRQ:&nbsp;
            </div>
            <div className={classes.thirdSubHeaderItemValue} >
              {selectedDevice.device_system_information.currentLoad.cpus[index].rawLoadIrq}
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className={classes.thirdSubHeaderItem} >
              Raw load nice:&nbsp;
            </div>
            <div className={classes.thirdSubHeaderItemValue} >
              {selectedDevice.device_system_information.currentLoad.cpus[index].rawLoadNice}
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className={classes.thirdSubHeaderItem} >
              Raw load system:&nbsp;
            </div>
            <div className={classes.thirdSubHeaderItemValue} >
              {selectedDevice.device_system_information.currentLoad.cpus[index].rawLoadSystem}
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className={classes.thirdSubHeaderItem} >
              Raw load user:&nbsp;
            </div>
            <div className={classes.thirdSubHeaderItemValue} >
              {selectedDevice.device_system_information.currentLoad.cpus[index].rawLoadUser}
            </div>
          </div>
      </>
    ))}
<div className={classes.headerItem} >
  Memory&nbsp;
</div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Active:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(selectedDevice.device_system_information.mem.active)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Available:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(selectedDevice.device_system_information.mem.available)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Cache buffer:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(selectedDevice.device_system_information.mem.buffcache)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Buffers:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(selectedDevice.device_system_information.mem.buffers)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Cached:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(selectedDevice.device_system_information.mem.cached)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Free:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(selectedDevice.device_system_information.mem.free)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Slab:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(selectedDevice.device_system_information.mem.slab)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Swap free:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(selectedDevice.device_system_information.mem.swapfree)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Swap total:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(selectedDevice.device_system_information.mem.swaptotal)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Swap used:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(selectedDevice.device_system_information.mem.swapused)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Used:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(selectedDevice.device_system_information.mem.used)}
      </div>
    </div>
<div style={{display: 'flex'}}>
  <div className={classes.headerItem} >
    Default Network Gateway:&nbsp;
  </div>
  <div className={classes.headerItemValue} >
    {selectedDevice.device_system_information.networkGatewayDefault}
  </div>
</div>
<div style={{display: 'flex'}}>
  <div className={classes.headerItem} >
    Default Network Interface:&nbsp;
  </div>
  <div className={classes.headerItemValue} >
    {selectedDevice.device_system_information.networkInterfaceDefault}
  </div>
</div>
{selectedDevice.device_system_information.networkInterfaces.map((netInterface, index) => (
  <>
    <div className={classes.headerItem} >
      Network Interfaces&nbsp;{index + 1}
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Carrier changes:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.carrierChanges}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      DHCP:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.dhcp}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      DNS suffix:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.dnsSuffix}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Duplex:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.duplex}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      ieee8021xAuth:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.ieee8021xAuth}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      ieee8021xState:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.ieee8021xState}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      iface:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.iface}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      ifaceName:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.ifaceName}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Internal:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.internal}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      IP4:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.ip4}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      IP4 subnet:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.ip4subnet}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      IP6:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.ip6}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      IP6 subnet:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.ip6subnet}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Mac:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.mac}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      MTU:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.mtu}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Operstate:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.operstate}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Speed:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.speed}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Type:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.type}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Virtual:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netInterface.virtual}
      </div>
    </div>
  </>
))}
{selectedDevice.device_system_information.networkStats.map((netStat, index) => (
  <>
    <div className={classes.headerItem} >
      Network Statistics&nbsp;{index+1}
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        iface:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netStat.iface}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        ms:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netStat.ms}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Operstate:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {netStat.operstate}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        RX bytes:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(netStat.rx_bytes)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        RX dropped:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(netStat.rx_dropped)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        RX errors:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(netStat.rx_errors)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        RX sec:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(netStat.rx_sec)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        TX bytes:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(netStat.tx_bytes)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        TX dropped:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(netStat.tx_dropped)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        TX errors:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(netStat.tx_errors)}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        TX sec:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {this.formatBytes(netStat.tx_sec)}
      </div>
    </div>
  </>
))}
<div className={classes.headerItem} >
  OS information&nbsp;
</div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Architecture:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.arch}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Build:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.build}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Codename:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.codename}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Codepage:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.codepage}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Distributor:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.distro}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Fully qualified domain name:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.fqdn}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Host name:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.hostname}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Kernel:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.kernel}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Logo file:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.logofile}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Platform:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.platform}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Release:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.release}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Serial:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.serial}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Service pack:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.servicepack}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Unified extensible firmware interface:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.osInfo.uefi}
      </div>
    </div>
<div className={classes.headerItem} >
  System&nbsp;
</div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Manufacturer:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.system.manufacturer}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Model:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.system.model}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Serial:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.system.serial}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        SKU:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.system.sku}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        UUID:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.system.uuid}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Version:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.system.version}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
        Virtual:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.system.virtual}
      </div>
    </div>
    {selectedDevice.device_system_information.system.raspberry && Object.keys(selectedDevice.device_system_information.system.raspberry).length > 0 ?
    <>
    <div className={classes.subHeaderItem} >
      Raspberry:&nbsp;
    </div>
      <div style={{display: 'flex'}}>
        <div className={classes.thirdSubHeaderItem} >
          Manufacturer:&nbsp;
        </div>
        <div className={classes.thirdSubHeaderItemValue} >
          {selectedDevice.device_system_information.system.raspberry.manufacturer}
        </div>
      </div>
      <div style={{display: 'flex'}}>
        <div className={classes.thirdSubHeaderItem} >
          Processor:&nbsp;
        </div>
        <div className={classes.thirdSubHeaderItemValue} >
          {selectedDevice.device_system_information.system.raspberry.processor}
        </div>
      </div>
      <div style={{display: 'flex'}}>
        <div className={classes.thirdSubHeaderItem} >
          Revision:&nbsp;
        </div>
        <div className={classes.thirdSubHeaderItemValue} >
          {selectedDevice.device_system_information.system.raspberry.revision}
        </div>
      </div>
      <div style={{display: 'flex'}}>
        <div className={classes.thirdSubHeaderItem} >
          Type:&nbsp;
        </div>
        <div className={classes.thirdSubHeaderItemValue} >
          {selectedDevice.device_system_information.system.raspberry.type}
        </div>
      </div>
      </>
    : null}
<div className={classes.headerItem} >
  Time&nbsp;
</div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Current:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.time.current}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Timezone:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.time.timezone}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Timezone name:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.time.timezoneName}
      </div>
    </div>
    <div style={{display: 'flex'}}>
      <div className={classes.subHeaderItem} >
      Uptime:&nbsp;
      </div>
      <div className={classes.subHeaderItemValue} >
        {selectedDevice.device_system_information.time.uptime}
      </div>
    </div>
<div style={{display: 'flex'}}>
  <div className={classes.headerItem} >
    Version:&nbsp;
  </div>
  <div className={classes.headerItemValue} >
    {selectedDevice.device_system_information.version}
  </div>
</div> */}






import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Row from 'react-bootstrap/Row';
import ProfilePicEditor from './ProfilePicEditor';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import  {putData , updateUserPhoneNumber, verifyUserPhoneNumber} from '../ApiCalls/DataApis';
import EnterNewNumberModal from './phoneNumberComponents/EnterNewNumberModal'
import {fetchCurrentUser} from '../store/actions/userActions';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import QRcodeMFA from './QRcodeMFA'
import Amplify, { Auth, Hub } from 'aws-amplify';

Amplify.configure({
  Auth: {            
      identityPoolId: 'us-east-1:5684b65a-8bf4-4697-b9a4-87eadbf03ef5',
      region: 'us-east-1',
      identityPoolRegion: 'us-east-1',      
      userPoolId: 'us-east-1_3EP1MRzAy',  
      userPoolWebClientId: '18230ih88kfb3ouj67mrid5er3',
      oauth: {
          domain: 'auth.iotflows.com',
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          // redirectSignIn: 'https://www.console.iotflows.com/', 
          // redirectSignOut: 'https://www.console.iotflows.com/', 
          redirectSignIn: 'http://localhost:3000/', 
          redirectSignOut: 'http://localhost:3000/', 
          responseType: 'token', // DO NOT USE 'code'!!! it goes through a forever loop //or 'token', note that REFRESH token will only be generated when the responseType is code
          // responseType: 'code', 
          options: {
              // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
              AdvancedSecurityDataCollectionFlag : true
          },
      }
  }
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 20,
    padding: 0,
    margin: '0px 0px 0px 10px',
    // marginTop: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


const styles = theme => ({
  paper: {
    position: 'relative',
    maxWidth: theme.spacing(80),
    minWidth: theme.spacing(43),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    // [theme.breakpoints.down('sm')]:{
    //   width: theme.spacing(43),
    // }
  },
  button: {
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      
    },
  },
  fontStyle: {
    fontSize: '14px',
    fontFamily:'Trebuchet MS',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
  largeAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class EditProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open, 
      loading: false,
      success: false,
      // user_first_name: '',
      // user_last_name: '',
      user_first_name: this.props.user_first_name,
      user_last_name: this.props.user_last_name,
      user_username_public: this.props.user_username_public,
      isMFAEnabled: false,
      openQRcodeMFA: false,
      user: {},
      
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleUserFirstNameChange = this.handleUserFirstNameChange.bind(this);
    this.handleUserUsernamePublicChange = this.handleUserUsernamePublicChange.bind(this);
    this.refreshUserInfo = this.refreshUserInfo.bind(this);
  }

  componentDidMount(){
    Auth.currentAuthenticatedUser()
    .then(user => {
      this.setState({user: user})
      Auth.getPreferredMFA(user,{
        // Optional, by default is false. 
        // If set to true, it will get the MFA type from server side instead of from local cache.
        bypassCache: false 
      }).then((data) => {
          // console.log('Current preferred MFA type is: ' + data);
          if(data === 'SMS_MFA' || data === 'SOFTWARE_TOKEN_MFA' ){
            this.setState({isMFAEnabled: true})
          }else {
            // console.log('No MFA enabled')
          }
      })
    })
    .catch(err => {
        
        // console.log('error',err);
    });

  }
  

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false, });
      this.props.handler();
  };

  handleUserFirstNameChange = (event) => {
    this.setState({user_first_name: event.target.value});
  }

  handleUserLastNameChange = (event) => {
    this.setState({user_last_name: event.target.value});
  }
  handleUserUsernamePublicChange = (event) => {
    this.setState({user_username_public: event.target.value});
  }

  componentWillReceiveProps({open}) {
    this.setState({...this.state,open})
  }

  refreshUserInfo () {
    this.props.fetchCurrentUser();
  }

  

  submitButtonClick = () => {
    const { loading } = this.state;
    if (!loading){
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        putData('https://api.iotflows.com/v1/users', 
        {'user_first_name': this.state.user_first_name,
          'user_last_name': this.state.user_last_name,
          'user_username_public': this.state.user_username_public})
          .then(async response => {
            
            if(response.ok){
              this.props.showSuccessSnackbar("Profile has been successfully updated!");
              this.props.fetchCurrentUser();
              this.setState({
                loading: false,
                success: true,
                open:false,
              },()=> {this.props.handler()});
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false,
                },()=> {this.props.handler()});
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }      
      )
    }
  };

  handleCloseQRcodeMFA =(mfaVerified) => {
    if(!mfaVerified){
      this.setState({
        isMFAEnabled: false,
      }) 
    }
    this.setState({
      openQRcodeMFA: false
    })
  }

  MFAStatusChange = (event) => {
    this.state.isMFAEnabled == false ? 
      this.setState({
        isMFAEnabled: true,
        openQRcodeMFA: true
      }) 
    : 
    this.setState({isMFAEnabled: false});
    Auth.setPreferredMFA(this.state.user, 'NOMFA');
  }



  render() {
    const { classes, currentUserInfo } = this.props;
    const { open, 
            loading, 
            success, 
            user_first_name, 
            user_last_name, 
            user_username_public,
            isMFAEnabled,
            openQRcodeMFA,
            user } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
      >
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">{"Edit Profile"}</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '15px', paddingTop: '15px'}}>

                <Row style={{justifyContent:'center'}}>
                  {/* <Avatar alt="Ulises" src={UliProfilePic} className={classes.largeAvatar} /> */}
                  <ProfilePicEditor profilePic={currentUserInfo[0].user_image_url + String(Math.random())} />
                </Row>
                

                <TextField
                  autoFocus
                  margin="normal"
                  id="user_first_name"
                  label="First Name"
                  value={user_first_name}
                  onChange={this.handleUserFirstNameChange}
                  // value={this.props.user_first_name}
                  type="text"
                  fullWidth
                  required
                />

                <TextField
                  margin="normal"
                  id="user_last_name"
                  label="Last Name"
                  value={user_last_name}
                  onChange={this.handleUserLastNameChange}
                  // value={this.props.user_last_name}
                  type="text"
                  fullWidth
                  required
                />

                <TextField
                  margin="normal"
                  id="telephoneNumber"
                  label="Phone Number"
                  value={currentUserInfo[0].user_phone_number}
                  type="tel"
                  fullWidth
                  disabled
                  InputProps={{ endAdornment: <EnterNewNumberModal refreshUserInfo={this.refreshUserInfo} />  }}
                />

                <TextField
                  margin="normal"
                  id="email"
                  label="Email"
                  value={currentUserInfo[0].user_email}
                  type="email"
                  fullWidth
                  disabled
                />

                <TextField
                  margin="normal"
                  id="username"
                  label="Username"
                  onChange={this.handleUserUsernamePublicChange}
                  value={user_username_public}
                  type="text"
                  fullWidth
                  // disabled
                />

                <Accordion style={{marginTop: '12px'}}>
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{padding: '0px 6px'}}
                  >
                        <Grid container spacing={1}>
                          <Grid item xs={8}>
                            <Typography className={classes.fontStyle} style={{textAlign:'left'}} >Two-factor Authentication</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            {isMFAEnabled ?
                              <Typography className={classes.fontStyle} style={{textAlign:'right',color: '#065F46'}} >Enabled</Typography>
                            :
                              <Typography className={classes.fontStyle} style={{textAlign:'right',color: '#DC2626'}} >Disabled</Typography>
                            }
                          </Grid>
                        </Grid>
                  </AccordionSummary>
                  <AccordionDetails >
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                        <Typography className={classes.fontStyle} style={{textAlign:'left', marginTop: '14px'}} >Authentication Method</Typography>
                      </Grid>
                      <Grid item xs={4} style={{textAlign: 'right'}}>
                        <FormControlLabel
                          style={{margin: '0px 0px 0.35em 0px'}}
                          control={<IOSSwitch checked={isMFAEnabled} onChange={this.MFAStatusChange} name="isMFAEnabled" />}
                          label={<span style={{ fontSize: '12px', fontFamily: 'Trebuchet MS', color: '#757575'}}>Status</span>}
                          labelPlacement="top"
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography className={classes.fontStyle} style={{textAlign:'left'}} >
                          Two-Factor Authentication adds an additional layer of security to your account. When you sign in, you’ll need to enter your password and a verification code.
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        {isMFAEnabled && openQRcodeMFA &&
          <QRcodeMFA open={openQRcodeMFA} user={user} handleCloseQRcodeMFA={this.handleCloseQRcodeMFA} />
        }
        
      </Grid>
    );
  }
}

EditProfileModal.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),

})
const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(EditProfileModal));

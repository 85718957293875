const initialState = {
  iotflows_member_role_id: '',
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'SELECTED_IOTFLOWS_MEMBER_ROLE_ID':
      return {
        ...state,
        selected_iotflows_member_role_id: action.iotflows_member_role_id
      }
    // case 'SELECTED_USER':
    //   return {
    //     ...state,
    //     selectedUser: action.payload
    //   }
   
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import  {postData } from '../ApiCalls/DataApis';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';
import Row from 'react-bootstrap/Row';
import Tooltip from '@material-ui/core/Tooltip';
import ReconfigureRemoteAgentModal from './ReconfigureRemoteAgentModal';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(70),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(40),
    }
  },
  textSizeCSS: {
    fontSize: '0.875rem',
    [theme.breakpoints.down('xs')]:{
      fontSize: '0.675rem',
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    // border:'1px solid #4f86ce',
    border:'1px solid #d86327',
    display:'inline-block',
    cursor:'pointer',
    color:'#d86327',
    width: '240px',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class VerifyReconfigureRemoteAgentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      openReconfigureRemoteAgentModal: false,
      initalSetupParameters: [],
    };
    this.handleCloseKeyModal = this.handleCloseKeyModal.bind(this)
  }

  
  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
      openReconfigureRemoteAgentModal: false,
      generated_remote_access_key:'',
      generated_password: ''
    });
  };

  submitButtonClick = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
          openReconfigureRemoteAgentModal: false,
        },
        () => {
          postData('https://api.iotflows.com/v1/devices/'+ this.props.device_uuid + '/clients/iotcore', {})
          .then(async response => {
            if(response.ok){
              const data = await response.json();
              console.log('data reconfigure remote agent')
              console.log(data)
              this.setState({initalSetupParameters: data.data.config.initial_setup},
                () => {
                  this.setState({openReconfigureRemoteAgentModal: true});
                  this.handleClose()
                }
                )
              
            }
            else{
              try{
                const data = await response.json();
                this.setState({
                  openReconfigureRemoteAgentModal: false,
                }, ()=> {this.handleClose()});
                // this.props.showErrorSnackbar(data.message);
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };


  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
    });
  };
  
  handleCloseKeyModal() {
    this.setState({
      openReconfigureRemoteAgentModal: false,
      generated_remote_access_key: '',
      generated_password: ''})
  }

  

  render() {
    const { classes } = this.props;
    const { open, 
            loading, 
            success,
            openReconfigureRemoteAgentModal,
            initalSetupParameters } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
        {/* <LightTooltip title="Generate a remote access key in order to..."> */}
          <Button className={classes.button} variant="contained"   onClick={this.handleOpen}>
            Reconfigure Remote Access &nbsp;<ReplayOutlinedIcon style={{fontSize: '16px'}}/>
          </Button>
          {openReconfigureRemoteAgentModal?
            <ReconfigureRemoteAgentModal 
              device_uuid={this.props.device_uuid} 
              open={openReconfigureRemoteAgentModal}
              initalSetupParameters={initalSetupParameters}
              handleCloseKeyModal={this.handleCloseKeyModal}
            />
            : null}
        {/* </LightTooltip> */}
        
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title"> Remote Access Agent </DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '20px'}}>
                
                  <div style={{margin: '0px'}} id="alert-dialog-description">
                    Are you sure you want to create a new remote access agent?
                    <Alert className={classes.textSizeCSS} severity="error"  >
                      CAUTION! Creating a new remote access key will REVOKE any previous agents. You will lose access to your device to remotely manage, restart, or program. Are you sure you want to continue? 
                    </Alert> 
                    
                  </div>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Cancel
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Yes { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}


const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(VerifyReconfigureRemoteAgentModal));




import React from "react";
import _ from "lodash";
import { WidthProvider, Responsive } from "react-grid-layout";
import "./resizeStyles.css"
import "./reactGridStyles.css"
import { withStyles } from '@material-ui/core/styles';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Button from '@material-ui/core/Button';
import SelectWidgetModal from '../MyAssetsPage/SelectWidgetModal';

import AssetTrackingMap from './AssetTrackingMap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTheme } from '@mui/material';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import  { getData } from '../ApiCalls/DataApis';

const ResponsiveReactGridLayout = WidthProvider(Responsive);


  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.gray,
      fontWeight: 700,
      fontFamily:'Trebuchet MS',
      fontSize: 11,
      height: '20px',
      padding: '8px',
    },
    body: {
      fontSize: 13,
      fontFamily:'Trebuchet MS',
      height: '24px',
      padding: '8px',
    },
  }))(TableCell);

const styles = theme => ({
    button: {
        backgroundColor:'transparent',
        borderRadius:'25px',
        border:'1px solid #4f86ce',
        display:'inline-block',
        minWidth:'70px',
        maxHeight: '40px',
        cursor:'pointer',
        color:'#4f87cc',
        fontFamily:'Trebuchet MS',
        fontSize:'12px',
        // font-weight:bold;
        padding:'3px 10px',
        textDecoration:'none',
        textShadow:'0px 0px 0px #9eb9ff',
        outline: 'none',
        '&:hover': {
          backgroundColor:'transparent',
        },
        '&:active': {
          outline: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
      },
    buttonSave: {
        backgroundColor:'transparent',
        borderRadius:'25px',
        border:'1px solid #d97706',
        display:'inline-block',
        minWidth:'70px',
        maxHeight: '40px',
        cursor:'pointer',
        color:'#d97706',
        fontFamily:'Trebuchet MS',
        fontSize:'12px',
        // font-weight:bold;
        padding:'3px 10px',
        textDecoration:'none',
        textShadow:'0px 0px 0px #9eb9ff',
        outline: 'none',
        '&:hover': {
          backgroundColor:'transparent',
        },
        '&:active': {
          outline: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
      },
    cardRoot: {
        borderRadius: '10px',
        margin: '5px',
      },
    mapContainer: {
        width:'100%',
        height:'400px',
        // [theme.breakpoints.down('md')]: {
        //   height: '300px',
        //   width:'100%'
        // },
        // [theme.breakpoints.down('xs')]: {
        //   height: '300px',
        //   width:'100%'
        // },
    },
    tableContainer: {
        borderRadius: '10px',
      },
      table: {
        minWidth: 450,
      },
})


/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */
class DashboardTestPage extends React.PureComponent {
    static defaultProps = {
        className: "layout",
        cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
        rowHeight: 100
      };
    
      constructor(props) {
        super(props);
        this.state = {
            isEditing: true,
            isDraggable:true,
            isResizable:true,
            items: [0, 1, 2, 3, 4].map(function(i, key, list) {
            return {
                i: i.toString(),
                x: i * 2,
                y: 0,
                w: 2,
                h: 2,
                // static: i === (list.length - 1),
            //   add: i === (list.length - 1)
            };
            }),
            newCounter: 0,
        };
    
        this.onAddItem = this.onAddItem.bind(this);
        this.onBreakpointChange = this.onBreakpointChange.bind(this);
      }

      
    
      createElement(el) {
        const removeStyle = {
          position: "absolute",
          right: "2px",
          top: 0,
          cursor: "pointer"
        };
        const noDisplay = {
          display: "none",
        };
        const i = el.add ? "+" : el.i;
        return (
          <div key={i} data-grid={el} style={{backgroundColor:'rgba(0,0,0,0.1)', borderRadius:'10px'}}>
            {/* {el.add ? (
              <span
                className="add text"
                onClick={this.onAddItem}
                title="You can add an item by clicking here, too."
              >
                Add +
              </span>
            ) : (
              <span className="text">{i}</span>
            )} */}
            {this.state.isEditing &&
            <div style={{height:'100%'}}>
                <SelectWidgetModal machine_uuid={this.props.machine_uuid}/>
                
            </div>
            }
            <span
                className="remove"
                style={this.state.isEditing? removeStyle: noDisplay}
                onClick={this.onRemoveItem.bind(this, i)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </span>
          </div>
        );
      }
    
      onAddItem() {
        /*eslint no-console: 0*/
        console.log("adding", "n" + this.state.newCounter);
        this.setState({
          // Add a new item. It must have a unique key!
          items: this.state.items.concat({
            i: "n" + this.state.newCounter,
            x: (this.state.items.length * 2) % (this.state.cols || 12),
            y: Infinity, // puts it at the bottom
            w: 2,
            h: 2
          }),
          // Increment the counter to ensure key is always unique.
          newCounter: this.state.newCounter + 1
        });
      }
    
      // We're using the cols coming back from this to calculate where to add new items.
      onBreakpointChange(breakpoint, cols) {
        this.setState({
          breakpoint: breakpoint,
          cols: cols
        });
      }
    
      onLayoutChange = (layout) => {
        console.log('layout')
        console.log(layout)
        // this.props.onLayoutChange(layout);
        this.setState({ layout: layout });
      }
    
      onRemoveItem(i) {
        console.log("removing", i);
        this.setState({ items: _.reject(this.state.items, { i: i }) });
      }

      onEditItem = () => {
        this.setState({
            isEditing: !this.state.isEditing,
            isDraggable: !this.state.isDraggable,
            isResizable: !this.state.isResizable
        })
      }
    
      render() {
        const { classes } = this.props;
        const { isDraggable,
                isResizable,
                isEditing } = this.state;

        

        return (
          <div style={{height:'100vh'}}>
            <div style={{padding:'12px', display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                {/* <h2 style={{fontWeight:700, fontSize:'24px', fontFamily: 'Trebuchet MS',marginRight:'auto' }}>
                    {isEditing? 'Editing Machine Dashboard Template': 'Asset Workspace'}
                </h2> */}
                {isEditing && 
                <div style={{margin:'0px 8px'}} >
                    <Button className={classes.button} variant="contained" onClick={this.onAddItem}><AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Add Card</Button>
                </div>
                }
                <div style={{margin:'0px 8px'}} >
                    <Button className={classes.buttonSave} variant="contained" onClick={this.onEditItem}>
                        {isEditing ? 'Create':'Save Edit'}
                    </Button>
                </div>
                
            </div>
            
            <ResponsiveReactGridLayout
                onLayoutChange={this.onLayoutChange}
                onBreakpointChange={this.onBreakpointChange}
                useCSSTransforms={true}
                // allowOverlap={true}
                isDraggable={isDraggable}
                isResizable={isResizable}
                {...this.props}
            >
              {_.map(this.state.items, el => this.createElement(el))}
            </ResponsiveReactGridLayout>

            
          </div>
        );
      }
}

export default withStyles(styles)(DashboardTestPage);
import React from 'react';
import { withStyles} from '@material-ui/core/styles';
import {getData, postData, putData, deleteData} from '../ApiCalls/DataApis';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import MqttService from '../core/services/MqttService';
import Grid from '@material-ui/core/Grid';
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNightBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import FileManagementTable from "./FileManagementTable.js"
import FileManagerCredentialsModal from './FileManagerCredentialsModal';
import HeaderDevice from './HeaderDevice';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddContainerModal from './AddContainerModal';
import EditContainerModal from './EditContainerModal';
import DeleteContainerModal from './DeleteContainerModal';
import DeleteRunningContainerModal from './DeleteRunningContainerModal';
import {apiLink} from '../ApiCalls/ApisVariables'
import DangerousIcon from '@mui/icons-material/Dangerous';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  tableContainer: {
    borderRadius: '10px',
  },
  table: {
    minWidth: 450,
  },
  iconButton: {
    backgroundColor:'transparent',
    borderRadius:'999px',
    border:'0px solid #4f86ce',
    display:'inline-block',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    padding:'3px',
    textDecoration:'none',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonProgress: {
    color: '#22c55e',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'transparent',
    color: theme.palette.common.gray,
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    // height: '44px',
    padding: '8px',
    // border:'0px solid #dc2626' 
  },
}))(TableCell);

const SubStyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'transparent',
    color: theme.palette.common.gray,
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    fontSize: 12,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    // height: '44px',
    padding: '8px',
  },
}))(TableCell);


function DefinedContainerRow({definedContainer, refreshContainerList}) {
  const [open, setOpen] = React.useState(false);
  let privatePort = ''
  let publicPort = ''
  let environmentVariables = []

  if(definedContainer && definedContainer.container_port_bindings && definedContainer.container_port_bindings[0] && definedContainer.container_port_bindings[0]['private_port']){
    privatePort = definedContainer.container_port_bindings[0]['private_port']
  }
  if(definedContainer && definedContainer.container_port_bindings && definedContainer.container_port_bindings[0] && definedContainer.container_port_bindings[0]['public_port']){
    publicPort = definedContainer.container_port_bindings[0]['public_port']
  }
  if(definedContainer && definedContainer.container_environment_variables && Object.keys(definedContainer.container_environment_variables).length > 0){

    for (const [key, value] of Object.entries(definedContainer.container_environment_variables)) {
      // console.log(`${key}: ${value}`);
      environmentVariables.push({key, value})
    }
  }

  return (
    <React.Fragment >
      <TableRow >
        <StyledTableCell >
          <IconButton
            aria-label="expand row"
            size="small"
            style={{outline:'none'}}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{definedContainer.container_image}</StyledTableCell>
        <StyledTableCell>{definedContainer.container_kill_before_run? 'true': 'false'}</StyledTableCell>
        <StyledTableCell>{publicPort}</StyledTableCell>
        <StyledTableCell>{privatePort}</StyledTableCell>
        <StyledTableCell>
          <EditContainerModal refreshContainerList={refreshContainerList} definedContainer={definedContainer} />
        </StyledTableCell>
        <StyledTableCell >
          <DeleteContainerModal refreshContainerList={refreshContainerList} definedContainer={definedContainer}/>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border:'0px solid #dc2626' }} colSpan={6} >
          <Collapse in={open} timeout="auto" unmountOnExit >
            <Box sx={{ marginTop:1,marginBottom:1, padding: 1, backgroundColor:'#f1f5f9', borderRadius:'10px' }}>
              {/* ********** Environment Variables Section ********** */}
              <div style={{ border:'0px solid #e2e8f0', borderBottomWidth: '1px', paddingBottom:'8px'}}>
                <div style={{fontSize:'16px',fontWeight:900, fontFamily:'Trebuchet MS',marginTop:'16px'}} >
                Environment Variables
                </div>
                <div style={{display:'flex', flexDirection:'column', marginLeft:'8px'}}>
                  {Object.keys(environmentVariables).length > 0 && environmentVariables.map((values, index) => (
                    <div style={{display:'flex', fontSize:14, color:'#1f2937', fontFamily:'Trebuchet MS', borderBottom:'1px solid #e5e7eb'}} >
                      <div style={{padding:'4px 0px', fontWeight:500}}>{values.key}</div>
                      <div style={{padding:'4px 16px', fontWeight:500}}>{values.value}</div>
                    </div>
                  ) )}  
                </div>
                
                
              </div>
              {/* ********** Environment Variables Section ********** */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


function Row({row, refreshContainerList, device_uuid}) {
  const [open, setOpen] = React.useState(false);

  

  return (
    <React.Fragment>
      <TableRow >
        <StyledTableCell >
          <IconButton
            aria-label="expand row"
            size="small"
            style={{outline:'none'}}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell >{row.Image}</StyledTableCell>
        <StyledTableCell >{row.Names[0].split('/')[1] || row.Names[0]}</StyledTableCell>
        <StyledTableCell style={{color: row.State ==='running'? '#15803d': ''}} >{row.State}</StyledTableCell>
        {/* <StyledTableCell >
          <LightTooltip title="Restart">
            <IconButton style={{color:'#6b7280', outline:'none'}}>
                <RestartAltIcon color='inherit' fontSize='medium' />
              </IconButton>
          </LightTooltip>
        </StyledTableCell> */}
        <StyledTableCell >
          <DeleteRunningContainerModal refreshContainerList={refreshContainerList} row={row} device_uuid={device_uuid} />
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border:'0px solid #dc2626' }} colSpan={6} >
          <Collapse in={open} timeout="auto" unmountOnExit >
            <Box sx={{ marginTop:1,marginBottom:1, padding: 1, backgroundColor:'#f1f5f9', borderRadius:'10px' }}>
              <div style={{display:'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', border:'0px solid #e2e8f0', borderBottomWidth: '1px'}}>
                <div style={{display:'flex',paddingBottom:'8px', flexDirection:'column', fontSize:14, color:'#6b7280', fontFamily:'Trebuchet MS', paddingRight:'24px',border:'0px solid #e2e8f0', borderRightWidth: '1px'}}>
                  <div style={{padding:'4px 0px', fontWeight:400}}>Name: <span style={{color:'#1f2937', padding:'4px 0px', fontWeight:500}}>{row && row.Names[0]? row.Names[0]:''}</span></div>
                  <div style={{padding:'4px 0px', fontWeight:400}}>Name id: <span style={{color:'#1f2937', padding:'4px 0px', fontWeight:500}}>{row && row.Id ? row.Id : ''}</span></div>
                  <div style={{padding:'4px 0px', fontWeight:400}}>Image: <span style={{color:'#1f2937', padding:'4px 0px', fontWeight:500}}>{row && row.Image ? row.Image : ''}</span></div>
                  <div style={{padding:'4px 0px', fontWeight:400}}>Image id: <span style={{color:'#1f2937', padding:'4px 0px', fontWeight:500}}>{row && row.ImageID ? row.ImageID :''}</span></div>
                </div>
                <div style={{display:'flex',paddingBottom:'8px', flexDirection:'column', fontSize:14, color:'#6b7280', fontFamily:'Trebuchet MS', marginLeft:'8px'}}>
                  <div style={{padding:'4px 0px', fontWeight:400}}>State: <span style={{color: row.State ==='running'? '#15803d': '#1f2937', padding:'4px 0px', fontWeight:500}}>{row && row.State ? row.State : ''}</span></div>
                  <div style={{padding:'4px 0px', fontWeight:400}}>Status: <span style={{color:'#1f2937', padding:'4px 0px', fontWeight:500}}>{row && row.Status ? row.Status : ''}</span></div>
                  <div style={{padding:'4px 0px', fontWeight:400}}>Command: <span style={{color:'#1f2937', padding:'4px 0px', fontWeight:500}}>{row && row.Command ? row.Command : ''}</span></div>
                </div>
              </div>
              {/* ********** Network Settings Section ********** */}
              {/* <div style={{ border:'0px solid #e2e8f0', borderBottomWidth: '1px', paddingBottom:'8px'}}>
                <div style={{fontSize:'16px',fontWeight:900, fontFamily:'Trebuchet MS',marginTop:'16px'}} >
                  Network Settings
                </div>
                <div style={{fontSize:'14px',fontWeight:500, fontFamily:'Trebuchet MS',marginTop:'8px'}} >
                  Networks
                </div>
                <div style={{display:'flex', flexDirection:'column', fontSize:14, color:'#1f2937', fontFamily:'Trebuchet MS'}}>
                  <div style={{padding:'4px 0px'}}>Bridge: <span style={{color:'#4b5563', padding:'4px 0px'}}>Bridge Object</span></div>
                </div>
              </div> */}
              {/* ********** Network Settings Section ********** */}
              {/* ********** Host Configuration Section ********** */}
              {/* <div style={{fontSize:'16px',fontWeight:900, fontFamily:'Trebuchet MS',marginTop:'16px'}} >
                Host Configuration
              </div>
              <div style={{display:'flex', border:'0px solid #e2e8f0', borderBottomWidth: '1px'}}>
                <div style={{display:'flex',paddingBottom:'8px', flexDirection:'column', fontSize:14, color:'#1f2937', fontFamily:'Trebuchet MS', paddingRight:'24px',border:'0px solid #e2e8f0', borderRightWidth: '1px'}}>
                  <div style={{padding:'4px 0px', fontWeight:800}}>Network Mode: <span style={{color:'#4b5563', padding:'4px 0px', fontWeight:500}}>{row.hostConfig?.networkMode}</span></div>
                </div>
              </div> */}
              {/* ********** Host Configuration  Section ********** */}
              {/* ********** Ports Section ********** */}
              <div style={{fontSize:'16px',fontWeight:900, fontFamily:'Trebuchet MS',marginTop:'16px'}} >
                Ports
              </div>
              <Table size="small" aria-label="ports">
                <TableHead>
                  <TableRow>
                    <SubStyledTableCell>IP Address</SubStyledTableCell>
                    <SubStyledTableCell>Public Port</SubStyledTableCell>
                    <SubStyledTableCell>Private Port</SubStyledTableCell>
                    <SubStyledTableCell>Type</SubStyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.Ports?.map((port,index) => (
                    <TableRow key={index}>
                      <SubStyledTableCell>{port.IP || ''}</SubStyledTableCell>
                      <SubStyledTableCell >{port.PublicPort || ''}</SubStyledTableCell>
                      <SubStyledTableCell>{port.PrivatePort || ''}</SubStyledTableCell>
                      <SubStyledTableCell >{port.Type || ''}</SubStyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* ********** Ports Section ********** */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

class ContainersTabPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      device_disabled: this.props.device_disabled,
      device_uuid: this.props.device_uuid,
      selectedDevice: this.props.selectedDevice,
      containerList:[],
      definedContainerList:[],
      loadingRedeploy:false,
    }
    
    this.refreshContainerList = this.refreshContainerList.bind(this);
    this.handleRedeploy = this.handleRedeploy.bind(this);
  }

  componentDidMount() {

    getData(apiLink + '/v1/devices/'+ this.props.device_uuid +'/containers')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        this.setState({definedContainerList: data.data})
        
        if( data.data[0] && data.data[0].device_system_information.containers && Object.keys(data.data[0].device_system_information.containers).length > 0){
          this.setState({containerList: data.data[0].device_system_information.containers})
        }
      }
    });

    if(MqttService.isConnected) {
      MqttService.subscribe('v1/iotflows/device-management/organizations/' + this.props.selectedOrganization.organization_uuid + '/devices/' + this.props.device_uuid +'/to-cloud/system-information', this.containerService)
    }
  }
  componentWillUnmount() {
    if(MqttService.isConnected) {
      MqttService.unsubscribe('v1/iotflows/device-management/organizations/' + this.props.selectedOrganization.organization_uuid + '/devices/' + this.props.device_uuid +'/to-cloud/system-information')
    }
  }

  containerService = message => {
    // updating state of device online and last heard
    try{
      let updatedContainerState = JSON.parse(message.payloadString);

      if(updatedContainerState && updatedContainerState.system_information && updatedContainerState.system_information.containers){
        this.setState({containerList: updatedContainerState.system_information.containers})
      }else{
        this.setState({containerList: []})
      }
    }
    catch(e){
      console.log("Error in reading heartbeat from MQTT");
      console.log(e.message);
    }
  }

  handleRedeploy = () => {
    try{
      const { loadingRedeploy } = this.state;
      if(!loadingRedeploy){
        this.setState({
          loadingRedeploy: true
        }, () => {
          getData(apiLink + '/v1/devices/'+ this.props.device_uuid +'/containers/redeploy')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              this.props.showSuccessSnackbar("Redeploy successful!")
              // this.refreshContainerList()
              this.setState({loadingRedeploy: false})
            }
          });
        })
      }
    }catch(e){
      console.log('error',e.message)
    }
    
  }

  handleFetchContainers = () => {
      const { loadingFetch } = this.state;
      try{
        if(!loadingFetch){
          this.setState({
            loadingFetch: true
          }, () => {
            getData(apiLink + '/v1/devices/'+ this.props.device_uuid +'/containers/fetch')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                this.props.showSuccessSnackbar("Fetch successfull!")
                this.setState({loadingFetch: false})
              }
            });
          })
        }

      }catch(e){
        console.log('error',e.message)
      }
      
  }


  componentWillReceiveProps(nextProps) {
    this.setState({
      device_disabled: nextProps.device_disabled,
      device_uuid: nextProps.device_uuid,
      selectedDevice: nextProps.selectedDevice,
    }); 
  };

  refreshContainerList(){
    getData(apiLink + '/v1/devices/'+ this.props.device_uuid +'/containers')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        this.setState({definedContainerList: data.data})
        
        if( data.data[0] && data.data[0].device_system_information.containers && Object.keys(data.data[0].device_system_information.containers).length > 0){
          this.setState({containerList: data.data[0].device_system_information.containers})
        }
      
      }
    });
  }



  render(){
    const { classes} = this.props
    const {loadingRedeploy,
            loadingFetch,
            device_disabled, 
            containerList,
            definedContainerList} = this.state

          


    return (
      <div className={classes.root} >
        <HeaderDevice 
            selected_device={this.props.selected_device} 
            selectedDevice={this.props.selectedDevice} 
            isDeviceOnline={this.props.isDeviceOnline} 
            deviceGeolocation={this.props.deviceGeolocation} 
            device_uuid={this.props.device_uuid}
            lastHeard={this.props.lastHeard} 
            handleClickStartDemo={this.props.handleClickStartDemo}
            deviceInfo= {this.props.deviceInfo}
          />

        {/* defined Containers */}
        <TableContainer component={Paper} >
          <Table className={classes.table} >
            <colgroup>
              
              <col style={{width:'5%'}}/>
              <col style={{width:'25%'}}/>
              <col style={{width:'25%'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'15%'}}/>
              <col style={{width:'5%'}}/>
              <col style={{width:'5%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{fontSize: '24px', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={3}>
                  <b>Defined Containers</b>
                </StyledTableCell>
                <StyledTableCell style={{paddingRight: '12px'}} align="right" colSpan={2}>
                  <LightTooltip title="Redeploy Containers">
                    <IconButton className={classes.iconButton} onClick={this.handleRedeploy}>
                        <PlayCircleIcon color='inherit' fontSize='medium' /> { loadingRedeploy && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </IconButton>
                  </LightTooltip>
                </StyledTableCell>
                <StyledTableCell style={{paddingRight: '12px'}} align="right" colSpan={3}>
                  <AddContainerModal device_uuid={this.props.device_uuid} refreshContainerList={this.refreshContainerList} />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell  />
                <StyledTableCell >Image</StyledTableCell>
                <StyledTableCell >Kill Before Run</StyledTableCell>
                <StyledTableCell >Public Port</StyledTableCell>
                <StyledTableCell >Private Port</StyledTableCell>
                <StyledTableCell  />
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody >
              {definedContainerList.map((definedContainer, index) => (
                <React.Fragment key={definedContainer.container_uuid + 'definedContainer'}>
                  <DefinedContainerRow  definedContainer={definedContainer} refreshContainerList={this.refreshContainerList} />
                </React.Fragment>
                  
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{height:'16px', width:'100%', backgroundColor:'#fafafa'}} />

        {/* Active Containers */}
        <TableContainer component={Paper}>
          <Table className={classes.table} >
            <colgroup>
              
              <col style={{width:'5%'}}/>
              <col style={{width:'30%'}}/>
              <col style={{width:'30%'}}/>
              <col style={{width:'30%'}}/>
              <col style={{width:'5%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{fontSize: '24px', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={3}>
                  <b>Running Containers</b>
                </StyledTableCell>
                <StyledTableCell style={{paddingRight: '12px'}} align="right" colSpan={2}>
                  <LightTooltip title="Fetch Containers">
                    <IconButton className={classes.iconButton} onClick={this.handleFetchContainers}>
                      <RestartAltIcon color='inherit' fontSize='medium' /> { loadingFetch && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </IconButton>
                  </LightTooltip>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell  />
                <StyledTableCell >Image</StyledTableCell>
                <StyledTableCell >Name</StyledTableCell>
                <StyledTableCell >State</StyledTableCell>
                <StyledTableCell ></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {containerList.map((container, index) => (
                <React.Fragment key={container.container_uuid + 'runningContainer'}>
                  <Row row={container} refreshContainerList={this.refreshContainerList} device_uuid={this.props.device_uuid} />
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          </TableContainer>
        
              
      </div>
      )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    currentUserInfo: state.users.currentUserInfo,
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ContainersTabPage));
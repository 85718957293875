import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { NavLink } from 'react-router-dom';
import {CountryCodeApi} from './CountryCodeApi';
import {network_data_api, network_names} from './NetworkDataApi'
import {getData, postData, putData, deleteData} from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import AddWifiNetworkModal from '../components/AddWifiNetworkModal'
import EditWifiNetworkModal from '../components/EditWifiNetworkModal'


const styles = theme => ({
  root: {
    flexGrow: 1,
    // maxWidth: 752,
  },
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '3px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
  },
  addBtnCSS: {
    color: '#2196f3',
    backgroundColor:'transparent',
    fontSize: 'large',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  addBtnCSS1: {
    color: '#2196f3',
    backgroundColor:'transparent',
    fontSize: '40px',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
});

class NetworksListPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      columns: [
      
        { title: 'Network Type', 
          field: 'wifi_type',
          // lookup: { Personal: 'Personal', Enterprise: 'Enterprise' }, 
        },
        { title: 'Country',
          field: 'country_code',
          // lookup: CountryCodeApi, 
        },
        { title: 'Network Nickname', 
          field: 'network_name' 
        },
        { title: 'Network SSID', 
          field: 'network_ssid',
        },
        // { title: 'Network Password', 
        //   field: 'network_password',
        //   editComponent: props => (
        //     <input
        //       type="password"
        //       value={props.value}
        //       onChange={e => props.onChange(e.target.value)}
        //     />
        //   )
        // },
        { title: 'Identity Username', 
          field: 'identity_username',
        },

        // { title: 'Identity Password', 
        //   field: 'identity_password',
        //   editComponent: props => (
        //     <input
        //       type="password"
        //       value={props.value}
        //       onChange={e => props.onChange(e.target.value)}
        //     />
        //   )
        // },
      ],
      wifi_networks: [],
      wifi_network_added: false,
      wifi_network_edited: false,
      wifi_network_deleted: false,
      open_add_wifi_modal: false,
      open_edit_wifi_modal: false,
      selected_wifi_row_data: [],
    }
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.refreshWifiList = this.refreshWifiList.bind(this);
  }

  handleCloseModal(){
    console.log('made it to close modal networks')
     this.setState({
      open_add_wifi_modal: false,
      open_edit_wifi_modal: false
    })
   }

  componentDidMount() {
      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/wifi_networks ')
      .then(async response => {
        
        if(response.ok ){
          const data = await response.json();
          // console.log(data.data)
          this.setState({
            wifi_networks: data.data ,
          })
        }
      });
  }

  componentDidUpdate(prevState, prevProps) {
    if(this.state.wifi_network_added){
      this.refreshWifiList();
      this.setState({wifi_network_added: false})
    }
    if(this.state.wifi_network_edited){
      this.refreshWifiList();
      this.setState({wifi_network_edited: false})
    }
    if(this.state.wifi_network_deleted){
      this.refreshWifiList();
      this.setState({wifi_network_deleted: false})
    }
  }

  refreshWifiList() {
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/wifi_networks ')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          // console.log(data.data)
          this.setState({
            wifi_networks: data.data ,
          })
        }
      });
  }

  render(){
    const {selectedOrganization, classes} = this.props
    const { columns, 
            wifi_networks, 
            open_add_wifi_modal, 
            open_edit_wifi_modal, 
            selected_wifi_row_data } = this.state

    // const requiredMessage = "Personal Network Requirements: \n Country\n Network Name\n Network SSID\n Network Password \n \n Enterprise Network Requirements: \n Country\n Network Name\n Network SSID\n Identity Username\n Identity Password ";
    // const formattedRequiredMessage = requiredMessage.split('\n').map((item, key) => {
    //     return <span style={{fontSize: '14px'}} key={key}>{item}<br/></span>
    //   })


    return (
      <div className={classes.root}>
        <div className={classes.breadcrumbCSS}>
          <Breadcrumbs  separator="›" aria-label="breadcrumb">
            {/* <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/" >
              {this.props.selectedOrganization.organization_name}
            </Link> */}
            <Typography className={classes.fontStyles} style={{color:'#757575'}}>{this.props.selectedOrganization.organization_name}</Typography>
            {/* <Link color="inherit" href="/projects" >
              Projects
            </Link> */}
            <Typography className={classes.fontStyles} color="textPrimary">Wi-Fi Networks</Typography>
          </Breadcrumbs>
        </div>
        
        <MaterialTable
              title="Network Management"
              // actions={[
              //   {
              //     icon: 'info_outlined',
              //     tooltip: formattedRequiredMessage,
              //     isFreeAction: true,
              //     onClick: (event) => alert(requiredMessage)
              //   }
              // ]}
              columns={columns} 
              data={wifi_networks}
              // icons={{
              //   add: props => 
              //         <div className={classes.addBtnCSS}>
              //           <AddCircleOutlineOutlinedIcon {...props} className={classes.addBtnCSS1}  />
              //         </div>
              // }}
              actions={[
                {
                  // icon: 'add',
                  icon: props =>
                        <div className={classes.addBtnCSS}>
                          <AddCircleOutlineOutlinedIcon {...props} className={classes.addBtnCSS1}  />
                        </div>,
                  tooltip: 'Add Network',
                  isFreeAction: true,
                  onClick: (event) => this.setState({open_add_wifi_modal: true})
                },
                {
                  icon: 'edit',
                  tooltip: 'Edit Network',
                  // isFreeAction: true,
                  onClick: (event, rowData) => this.setState({open_edit_wifi_modal: true, selected_wifi_row_data: rowData})
                }
              ]}
              // actions={[
              //   {
              //     icon: 'add',
              //     // tooltip: 'Edit User',
              //     onClick: (event, rowData) => alert('You are adding ' + rowData.name)
              //   },
              // ]}
              // detailPanel={[
              //   {
              //     icon: 'info_outlined',
              //     tooltip:  formattedRequiredMessage,
              //     // tooltip: requiredMessage.split('\n').map((item, key) => {
              //     //             return <span style={{fontSize: '14px'}} key={key}>{item}<br/></span>
              //     //           }),
              //     render: rowData => {
              //       return (
              //         <div
              //           style={{
              //             fontSize: 11,
              //             textAlign: 'left',
              //             color: '#212121',
              //             backgroundColor: '#fafafa',
              //           }}
              //         >
              //           {formattedRequiredMessage}
              //         </div>
              //       )
              //     },
              //   },
              // ]}
              editable={{
                // onRowAdd: (newData) => 
                //   new Promise((resolve) => {
                //     var isValid = false;
                //     if(newData.wifi_type == 'Personal'){
                //       if(newData.network_name && newData.network_ssid && newData.network_password && newData.country_code && newData.wifi_type){
                //         isValid = true
                //       }
                //     }
                //     else if(newData.wifi_type == 'Enterprise'){
                //       if(newData.network_name && newData.identity_username && newData.identity_password && newData.network_ssid && newData.country_code && newData.wifi_type){
                //         isValid = true
                //       }
                //     }
                //     if(isValid){
                //       // POST Function for creating a wifi network 
                //       postData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/wifi_networks', 
                //       { "network_name": newData.network_name,
                //         "network_ssid": newData.network_ssid,
                //         "network_password": newData.network_password,
                //         "identity_username": newData.identity_username,
                //         "identity_password": newData.identity_password,
                //         "wifi_type": newData.wifi_type,
                //         "country_code": newData.country_code,
                //       })
                //         .then(async response => {
                //           if(response.ok){
                //             this.setState({wifi_network_added: true})
                //             this.props.showSuccessSnackbar("New wifi network has been successfully added!");
                //           }
                //           else{
                //             try{
                //               const data = await response.json();
                //               this.setState({wifi_network_added: false})
                //               this.props.showErrorSnackbar(data.message);
                //             }catch (e){
                //               this.props.showErrorSnackbar('Something went wrong');
                //             }
                //           }
                //         })
                //       setTimeout(() => {
                //         resolve();
                //         this.setState((prevState) => {
                //           const data = [...prevState.wifi_networks];
                //           data.push(newData);
                //           return { ...prevState, data };
                //         });
                //       }, 600);
                      
                //     }
                //     else {
                //       this.props.showErrorSnackbar("Missing require fields!");
                //       resolve();

                //     }
                //   }),
                // onRowUpdate: (newData, oldData) =>
                //   new Promise((resolve) => {
                //   var isValid = false;
                //   if(newData.wifi_type == 'Personal'){
                //     if(newData.network_name && newData.network_ssid && newData.network_password && newData.country_code && newData.wifi_type){
                //       isValid = true
                //     }
                //   }
                //   else if(newData.wifi_type == 'Enterprise'){
                //     if(newData.network_name && newData.identity_username && newData.identity_password && newData.network_ssid && newData.country_code && newData.wifi_type){
                //       isValid = true
                //     }
                //   }
                //   if(isValid){
                //     // POST Function for creating a wifi network 
                //     putData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/wifi_networks', 
                //     { "network_id": newData.network_id,
                //       "network_name": newData.network_name,
                //       "network_ssid": newData.network_ssid,
                //       "network_password": newData.network_password,
                //       "identity_username": newData.identity_username,
                //       "identity_password": newData.identity_password,
                //       "wifi_type": newData.wifi_type,
                //       "country_code": newData.country_code,
                //     })
                //       .then(async response => {
                //         if(response.ok){
                //           this.setState({wifi_network_edited: true})
                //           this.props.showSuccessSnackbar("Wifi network has been successfully modifies!");
                //         }
                //         else{
                //           try{
                //             const data = await response.json();
                //             this.props.showErrorSnackbar(data.message);
                //             this.setState({wifi_network_edited: false})
                //           }catch (e){
                //             this.props.showErrorSnackbar('Something went wrong');
                //           }
                //         }
                //       })
                //     setTimeout(() => {
                //       resolve();
                //       this.setState((prevState) => {
                //         const data = [...prevState.wifi_networks];
                //         data.push(newData);
                //         return { ...prevState, data };
                //       });
                //     }, 600);
                    
                //   }
                //   else {
                //     this.props.showErrorSnackbar("Missing require fields!");
                //     resolve();

                //   }
                // }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    deleteData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/wifi_networks', 
                      {'network_id': oldData.network_id})
                        .then(async response => {
                          if(response.ok){ 
                            this.props.showSuccessSnackbar("Wifi network has been successfully removed!");
                            this.setState({
                              loading: false,
                              success: true,
                              open:false,
                              wifi_network_deleted: true
                            });
                          }
                          else{
                            try{
                              const data = await response.json();
                              this.setState({
                                loading: false,
                                success: false,
                                open:false,
                                wifi_network_deleted: false
                              });
                              this.props.showErrorSnackbar(data.message);
                            }catch (e){
                              this.props.showErrorSnackbar('Something went wrong');
                            }
                          }
                        })
                    setTimeout(() => {
                      resolve();
                      this.setState((prevState) => {
                        const data = [...prevState.wifi_networks];
                        data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data };
                      });
                    }, 600);
                  }),
              }}
              options={{
                actionsColumnIndex: -1,
                headerStyle: {
                  // backgroundColor: '#bdbdbd',
                  // color: '#FFF',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                }
              }}
            />
            <AddWifiNetworkModal open={open_add_wifi_modal} handleCloseModal={this.handleCloseModal} refreshWifiList={this.refreshWifiList} />
            <EditWifiNetworkModal open={open_edit_wifi_modal} handleCloseModal={this.handleCloseModal} refreshWifiList={this.refreshWifiList} selected_wifi_row_data = {selected_wifi_row_data} />

       </div>
    )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(NetworksListPage));

// export default withStyles(styles)(NetworksListPage)

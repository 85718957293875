import React from 'react'
import { Grid, Typography, IconButton, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Row from 'react-bootstrap/Row';
import ResetDeviceModal from "./ResetDeviceModal.js"
import DownloadDataStreamDataModal from '../components/DownloadDataStreamDataModal';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import "../styles/HeartbeatLED.scss";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    border: '1px solid #fafafa',
  },
}))(Tooltip);

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  tableContainer: {
    borderRadius: '10px',
  },
  table: {
    minWidth: 400,
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'5px',
    border:'1px solid #757575',
    display:'inline-block',
    cursor:'pointer',
    color:'#757575',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'2px 14px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 500,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);


class CsvTable extends React.Component{
  constructor(props){
    super(props);
    this.state = {
    }
  }

  downloadDataButtonClick = () => {
    
    
    var csv = this.props.headers+'\n';
    this.props.data.map(row => (
      csv += row+'\n'
    ))

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = this.props.selectedDataRow.data_stream_name+'_data.csv';
    hiddenElement.click();
    
  };

  
  render() {
    const { classes} = this.props

   
    

    return (
      <div className={classes.root}>
        <div style={{display:'flex'}}>
          <div style={{fontSize: '18px', marginTop:'auto', marginBottom: 'auto'}}>
            <b>CSV Data</b>
          </div>
          {/* <div style={{marginLeft:'auto'}}>
            <Button  className={classes.button} onClick={this.downloadDataButtonClick}>
              Download 
            </Button>
          </div> */}

        </div>
        <TableContainer component={Paper} style={{marginBottom: '50px'}}>
          <Table className={classes.table} aria-label="spanning table">
            <TableHead>
              <TableRow>
                {this.props.headers.map((header) => (
                  <StyledTableCell style={{paddingLeft: '4px'}}>{header}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.data.map((row, index) => (
                  <TableRow key={index} >
                    {row.map((dataInCell) => (
                      <StyledTableCell style={{paddingLeft: '4px'}}>{dataInCell}</StyledTableCell>
                    ))}
                  </TableRow>
                
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
        
      </div>
    )

  }
}



export default withStyles(styles,{ withTheme: true })(CsvTable)

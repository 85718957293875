import React,  { useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import {Container} from '@material-ui/core';
import {DropdownButton, Dropdown}  from 'react-bootstrap';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Row from 'react-bootstrap/Row';
import Divider from '@material-ui/core/Divider';
import "../styles/ButtonStyles.scss";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AlertsTable from '../MyAlertsPage/AlertsTable';
import Paper from '@material-ui/core/Paper';
import NI from '@material-ui/icons/NotificationImportant';
import CreateAlertChannelModal from '../components/CreateAlertChannelModal';
import EditAlertChannelModal from '../components/EditAlertChannelModal';
import DeleteAlertChannelModal from '../components/DeleteAlertChannelModal';
import AddAlertChannelMemberModal from '../components/AddAlertChannelMemberModal';
import ListMemberModal from '../components/ListMemberModal';
import LeaveAlertChannelModal from '../components/LeaveAlertChannelModal';
import ManageNotificationsModal from '../components/ManageNotificationsModal';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MqttService from '../core/services/MqttService';
import {getData} from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import AlertSettings from './AlertSettings';
import "../styles/ButtonStyles.scss";
import AlertChannelList from './AlertChannelList';
import {fetchAlertChannels, fetchSelectedAlertChannel} from '../store/actions/alertChannelActions';
import store from '../store';
import Col from 'react-bootstrap/Col';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import "../styles/WaitingDataPulse.scss";


const styles = theme => ({
  root: {
    flexGrow: 1,
    // maxWidth: 752,
  },
  container: {
    alignContent: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),  
    width: '1200px',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridGap: theme.spacing(0),   
  },
  header: {
    alignItems: 'center',
  },
  toggleBtnContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
    }, 
  },
  paper: {
    // padding: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    // margin: 'auto',
    backgroundColor: '#eeeeee',
    // alignItems: 'flex-end',
    
  },
  buttonPlacement1: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
    }, 
  },
  buttonPlacement2: {
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
    }, 
  },
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '10px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
  },
  settingsBtn: {
    color: '#e0e0e0',
  },
  waitingCSS:{
    // padding: '5px',
    position: 'absolute',
    // borderRadius: '10px',
    // backgroundColor: '#fafafa',
    // border:'1px solid #4f86ce',
    zIndex: '2',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    left: 'calc(50% - 148px)',
    top: '15%',
    // textShadow:'1px 1px 1px #9eb9ff',
  },
  mqttTopicCSS: {
    color: '#616161',
    fontSize:'12px', 
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
    }, 
  },
});


var alerts_icon = {
  "INFORMATIVE": <NI style={{ color: '#757575' }}/>,
  // "Warning": <NI style={{ color: '#f9a825' }}/>,
  "MINOR": <NI style={{ color: '#f57f17' }}/>,
  "MAJOR": <NI style={{ color: '#bf360c' }}/>,
  // "Critical": <NI style={{ color: '#d50000' }}/>,
}



const sample = [
  // {
  //   "severity_level": "Critical",
  //   "client": "Temperature",
  //   "description":  "Vibration device started running", 
  //   "publisher":  "device 1 nodeRed",
  //   "received_at" :  "10:35 pm 01-31-2010"
  // },
  // {
  //   "severity_level": "Warning",
  //   "client": "Vibration",
  //   "description":  "Vibration device started running", 
  //   "publisher":  "device 1 nodeRed",
  //   "received_at" :  "10:35 pm 01-31-2010"
  // },
  
];



class Alerts extends React.Component{
  
  constructor(props){
    super(props);
    this.state = {
      isConnected: false,
      message: '',
      rows: [],
      filtered_rows: [],
      alert_channels_list :[],
      selected_alert_channel: [],
      allAlertsChecked: true,
      majorAlertsChecked: false,
      minorAlertsChecked: false,
      informativeAlertsChecked: false,
      organizationClients: [],
      openClicked1: false,
      first: true,
    }
  
    this.onAlert = this.onAlert.bind(this);
    this.handleTooltipClose1 = this.handleTooltipClose1.bind(this);
    this.toggleChangeFilterList = this.toggleChangeFilterList.bind(this);
  }

  
  componentDidMount() {
   
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/devices/clients')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          var dic = {};
          for(var i=0; i<Object.keys(data.data).length; i++){
            dic[data.data[i].client_id]= data.data[i]
          }
          this.setState({
            organizationClients: dic,
          })
        }
      });

  }



  onAlert = message => {
    try{
      const alert_message = JSON.parse(message.payloadString);
      alert_message.severity_level = alert_message.severity_level.toUpperCase();
      if(!(alert_message.severity_level == "INFORMATIVE" || alert_message.severity_level == "MINOR" || alert_message.severity_level == "MAJOR"))
      {
        alert_message.severity_level = "INFORMATIVE"
      }
      let client_id = alert_message.client_id;
      let client_name = this.state.organizationClients[client_id].client_name;
      let device_name = this.state.organizationClients[client_id].device_name;
      let project_name = this.state.organizationClients[client_id].project_name;
  
      var rows = this.state.rows;
      alert_message.severity_level_icon = alerts_icon[alert_message.severity_level];
      alert_message.client_name = client_name;
      alert_message.device_name = device_name;
      alert_message.project_name = project_name;
      alert_message.received_at = (new Date()).toLocaleTimeString() +' '+ (new Date()).toLocaleDateString();
      rows.unshift(alert_message);
  
      this.setState({rows:rows})
      this.toggleChangeFilterList();
    }
    catch(e)
    {
      console.log("Error in reading alert streaming from MQTT");
      console.log(e.message);
    }
    
    

  }

  handleTooltipClose1(){
    this.setState({
      openClicked1: false,
    })
  }

  componentDidUpdate(prevState, prevProps, snapshot) {
      
    if(prevState.allAlertChannels != this.props.allAlertChannels){
      if(this.props.selectedAlertChannel != undefined){
        this.props.allAlertChannels.map( (alert_channel, index) => {
          if(alert_channel.alert_channel_uuid == this.props.selectedAlertChannel.alert_channel_uuid){
            this.props.fetchSelectedAlertChannel(alert_channel);
          }
        })
      }
    }
    
    // Typical usage (don't forget to compare props):
    if (this.props.selectedAlertChannel !== prevState.selectedAlertChannel) {
      if(prevState.selectedAlertChannel != undefined){
        MqttService.unsubscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/alert-channels/' + prevState.selectedAlertChannel.alert_channel_uuid)
      }
      // Get function to get historical data of alert channel messages
      getData('https://api.iotflows.com/v1/alert-channels/' + this.props.selectedAlertChannel.alert_channel_uuid + '/historical_data')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          var modifyDate = {};
          for(var i=0; i<Object.keys(data.data).length; i++){
            // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
            modifyDate[i] = new Date(data.data[i].received_at);
            modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
            data.data[i].received_at = modifyDate[i];
            data.data[i].severity_level = data.data[i].severity_level.toUpperCase();
            data.data[i].severity_level_icon = alerts_icon[data.data[i].severity_level];
          }
          this.setState({
            rows: data.data,
          }, ()=> {this.toggleChangeFilterList()})
        }
      });

      // this.setState({rows: []})
      this.setState({filtered_rows: []})
      this.setState({selected_alert_channel: this.props.selectedAlertChannel})
      this.setState({ first: true });
      if(MqttService.isConnected) {
        // MqttService.unsubscribe('ulises/alert/' + prevState.selected_alert_channel.alert_channel_name)
        MqttService.subscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/alert-channels/' + this.props.selectedAlertChannel.alert_channel_uuid , this.onAlert)
      }
    }
    
  }

  componentWillUnmount() {
    if(MqttService.isConnected) {
      if(this.props.selectedAlertChannel != undefined){
        MqttService.unsubscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/alert-channels/' + this.props.selectedAlertChannel.alert_channel_uuid )
      }
    }
  }

  allAlertsToggleChecked = (event) => {
    this.state.allAlertsChecked == false ? this.setState( {allAlertsChecked: true}, () => {this.toggleChangeFilterList()} ) : this.setState( {allAlertsChecked: false, majorAlertsChecked: true, minorAlertsChecked: true, informativeAlertsChecked: true}, () => {this.toggleChangeFilterList()} );
  }

  majorAlertsToggleChecked = (event) => {
    this.state.majorAlertsChecked == false ? this.setState( {majorAlertsChecked: true}, () => {this.toggleChangeFilterList()} ) : this.setState( {majorAlertsChecked: false}, () => {this.toggleChangeFilterList()} )
    
  }
  minorAlertsToggleChecked = (event) => {
    this.state.minorAlertsChecked == false ? this.setState( {minorAlertsChecked: true}, () => {this.toggleChangeFilterList()} ) : this.setState( {minorAlertsChecked: false}, () => {this.toggleChangeFilterList()} )
  }
  informativeAlertsToggleChecked = (event) => {
    this.state.informativeAlertsChecked == false ? this.setState( {informativeAlertsChecked: true}, () => {this.toggleChangeFilterList()} ) : this.setState( {informativeAlertsChecked: false}, () => {this.toggleChangeFilterList()} )
  }

  toggleChangeFilterList(){
    if(this.state.allAlertsChecked){
      this.setState({filtered_rows: this.state.rows})
    }
    else if(this.state.majorAlertsChecked && this.state.minorAlertsChecked && this.state.informativeAlertsChecked){
      this.setState({filtered_rows: this.state.rows})
    }
    else if(this.state.majorAlertsChecked && this.state.minorAlertsChecked ){
      this.setState({filtered_rows: this.state.rows.filter(eachAlert => eachAlert.severity_level == 'MAJOR'|| eachAlert.severity_level == 'MINOR')})
    }
    else if(this.state.majorAlertsChecked && this.state.informativeAlertsChecked ){
      this.setState({filtered_rows: this.state.rows.filter(eachAlert => eachAlert.severity_level == 'MAJOR'|| eachAlert.severity_level == 'INFORMATIVE')})
    }
    else if(this.state.minorAlertsChecked && this.state.informativeAlertsChecked ){
      this.setState({filtered_rows: this.state.rows.filter(eachAlert => eachAlert.severity_level == 'MINOR'|| eachAlert.severity_level == 'INFORMATIVE')})
    }
    else if(this.state.majorAlertsChecked){
      this.setState({filtered_rows: this.state.rows.filter(eachAlert => eachAlert.severity_level == 'MAJOR')})
    }
    else if(this.state.minorAlertsChecked){
      this.setState({filtered_rows: this.state.rows.filter(eachAlert => eachAlert.severity_level == 'MINOR')})
    }
    else if(this.state.informativeAlertsChecked){
      this.setState({filtered_rows: this.state.rows.filter(eachAlert => eachAlert.severity_level == 'INFORMATIVE')})
    }
    
  }

  copyToClipboard = (textId) => {

    var textfield = document.querySelector(textId);
    var range = document.createRange();
    range.selectNode(textfield);
    window.getSelection().addRange(range);

    try {
      // Now that we've selected the anchor text, execute the copy command
      var successful = document.execCommand('copy');
      if(successful){
        if(textId == '.mqtt-topic'){
          this.setState({openClicked1: true},
            ()=> {if (this.state.first) {
              this.copyToClipboard('.mqtt-topic');
              this.setState({first: false});
            }}
          )
        }
      }
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copy command was ' + msg);
    } catch(err) {
      console.log('Oops, unable to copy');
    }

    // Remove the selections - NOTE: Should use
    // removeRange(range) when it is supported
    window.getSelection().removeAllRanges();
  };

  render(){

    const { classes, selectedOrganization, selectedAlertChannel} = this.props
    const {rows,
            filtered_rows, 
            selected_alert_channel, 
            allAlertsChecked, 
            majorAlertsChecked, 
            minorAlertsChecked,
            informativeAlertsChecked, 
            openClicked1} = this.state

    if(this.state.allAlertsChecked == false && this.state.majorAlertsChecked == false && this.state.minorAlertsChecked == false && this.state.informativeAlertsChecked == false){
      this.setState( {allAlertsChecked: true}, () => {this.toggleChangeFilterList()} )
    }

    
    return(
      
      <div className={classes.root} style={{marginBottom: '60px'}}>
        <div className={classes.breadcrumbCSS}>
          <Breadcrumbs  separator="›" aria-label="breadcrumb">
            {/* <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/" >
              {selectedOrganization.organization_name}
            </Link> */}
            <Typography className={classes.fontStyles} style={{color:'#757575'}}>{selectedOrganization.organization_name}</Typography>
            <Typography className={classes.fontStyles} color="textPrimary">Alert Channels</Typography>
          </Breadcrumbs>
        </div>

        <Container  >
          <Paper className={classes.paper}>
            <Grid container spacing={2} className={classes.header}> 

              <Grid item sm={9} container  >
                <Col>
                  <Row> 
                    <Typography variant="body1" gutterBottom >
                      {selectedAlertChannel == undefined? null : selectedAlertChannel.alert_channel_name }&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="body1"  style={{color: '#616161'}} gutterBottom>
                      {selectedAlertChannel == undefined? null : selectedAlertChannel.alert_channel_description}&nbsp;&nbsp;
                    </Typography>
                  </Row>
                  <Row style={{alignItems: 'baseline'}}> 
                    {/* <Grid item container sm={2}> */}
                      <Typography variant="body1" gutterBottom style={{fontSize: '12px'}} >
                      {selectedAlertChannel == undefined? null : 'MQTT Topic:' }&nbsp;&nbsp;
                      </Typography>
                    {/* </Grid> */}
                    {/* <Grid item container sm={10}> */}
                      <div  className={classes.mqttTopicCSS} >
                        
                        {selectedAlertChannel == undefined? null :
                          <div>
                            <code className="mqtt-topic">v1/organizations/{selectedOrganization.organization_uuid}/alert-channels/{selectedAlertChannel.alert_channel_uuid}</code>&nbsp;&nbsp;
                            <ClickAwayListener onClickAway={this.handleTooltipClose1}>
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={this.handleTooltipClose1}
                                  open={openClicked1}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title="Copied!"
                                >
                                  <IconButton  className="mqtt-topic-copybtn"  size="small" onClick={() => this.copyToClipboard('.mqtt-topic')}>
                                    <FileCopyOutlinedIcon style={{fontSize: '16px'}} />
                                  </IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                          </div>
                        }
                      </div>
                    {/* </Grid> */}
                  </Row>
                </Col>
                  
              </Grid>
              <Grid item sm={2} container className={classes.buttonPlacement1}>
                <Row style={{paddingTop: '8px', paddingBottom: '8px'}}>
                 <span className={`${selectedAlertChannel == undefined ? "clickAlertList" : ""}`} >
                 <AlertChannelList  />
                 </span>
                </Row>
              </Grid>

              <Grid item sm={1} container className={classes.buttonPlacement2}>
                {selectedAlertChannel == undefined ? 
                    <SettingsOutlinedIcon disabled className={classes.settingsBtn} style={{fontSize:"24px"}} />
                : 
                  <AlertSettings  selected_alert_channel={selected_alert_channel}/>
                } 
              </Grid>
            </Grid>
          </Paper>
        </Container>
        
        <Container >
          <Grid container spacing={2} > 
              <Grid item sm container className={classes.toggleBtnContainer}>
                <Row>
                  <FormControlLabel
                      value="all"
                      control={<Switch size="small" color="primary" checked={allAlertsChecked} onChange={this.allAlertsToggleChecked} />}
                      label="All"
                      labelPlacement="end"
                      disabled={selectedAlertChannel == undefined ? true:false}
                    />
                </Row>
              </Grid>
              <Grid item sm container className={classes.toggleBtnContainer}>
                <Row>
                  <FormControlLabel
                    value="major"
                    control={<Switch size="small" color="primary" checked={majorAlertsChecked} onChange={this.majorAlertsToggleChecked}  />}
                    label="Major"
                    labelPlacement="end"
                    disabled={selectedAlertChannel == undefined || this.state.allAlertsChecked == true ? true:false }
                  />
                </Row>
              </Grid>
              <Grid item sm container className={classes.toggleBtnContainer}>
                <Row>
                    <FormControlLabel
                      value="minor"
                      control={<Switch size="small" color="primary" checked={minorAlertsChecked} onChange={this.minorAlertsToggleChecked}  />}
                      label="Minor"
                      labelPlacement="end"
                      disabled={selectedAlertChannel == undefined || this.state.allAlertsChecked == true ? true:false}
                    />
                </Row>
              </Grid>
              <Grid item sm container className={classes.toggleBtnContainer}>
                <Row>
                    <FormControlLabel
                    value="informative"
                    control={<Switch size="small" color="primary" checked={informativeAlertsChecked} onChange={this.informativeAlertsToggleChecked}  />}
                    label="Informative"
                    labelPlacement="end"
                    disabled= {selectedAlertChannel == undefined || this.state.allAlertsChecked == true ? true:false}
                  />
                </Row>
              </Grid>
          </Grid>
        </Container>

        <Container >
          <Paper  style={{ height: 700}}>
              {rows == '' ? 
                <Paper  elevation={3}  >
                  {selectedAlertChannel == undefined ? null :
                  <div className={classes.waitingCSS}  >
                    <span className="waitingOnData"> 
                    <Col   >
                      <Row style={{ justifyContent:'center'}}>
                        <Typography  variant="subtitle2" style={{ fontFamily:'Trebuchet MS'}}  >
                         WAITING ON ALERT
                        </Typography>
                      </Row>
                      <Row style={{ justifyContent:'center'}}>
                        <Typography  variant="body2"  style={{ fontSize: '12px', fontFamily:'Trebuchet MS' }}>
                          Alert messages will be published here in real-time
                        </Typography>
                      </Row>
                    </Col>
                    </span>
                  </div>
                  }
                </Paper> 
              : null}
            <AlertsTable 
              rowCount={filtered_rows.length}
              rowGetter={({ index }) => filtered_rows[index]}
              columns={[
                {
                  width: 75,
                  label: ' ',
                  dataKey: 'severity_level_icon',
                  formatter: 'icon',
                },
                {
                  width: 150,
                  label: 'Severity Level',
                  dataKey: 'severity_level',
                  filter: true,
                },
                {
                  width: 200,
                  label: 'Project\u00A0',
                  dataKey: 'project_name',
                },
                {
                  width: 200,
                  label: 'Device\u00A0',
                  dataKey: 'device_name',
                },
                {
                  width: 200,
                  label: 'Client\u00A0',
                  dataKey: 'client_name',
                },
                {
                  width: 200,
                  label: 'Subject\u00A0',
                  dataKey: 'subject',
                  // numeric: true,
                },
                {
                  width: 300,
                  label: 'Description\u00A0',
                  dataKey: 'description',
                  
                },
                {
                  width: 250,
                  label: 'Received At\u00A0',
                  dataKey: 'received_at',
                  date: true,
                },
              ]}
            />
          </Paper>
          </Container>
      </div>
    )
  }
}



const mapDispatchToProps = dispatch => ({
  fetchSelectedAlertChannel: currentAlertChannel => dispatch(fetchSelectedAlertChannel(currentAlertChannel)),
  // fetchAlertChannels: (organization_uuid) => dispatch(fetchAlertChannels(organization_uuid)),
})

const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    allAlertChannels: state.alertChannels.allAlertChannels,
    selectedAlertChannel: state.alertChannels.selectedAlertChannel
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Alerts));


import React from 'react';
import {  withStyles } from '@material-ui/core/styles';
import  { getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import ReactMapGL, {Marker, Popup} from 'react-map-gl';
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import getCenter from 'geolib/es/getCenter';
import "../styles/HeartbeatLED.scss";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const styles = theme => ({
    popupBoxHeader: {
    // borderRadius: '10',
    // boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding:'8px 3px 1px 3px',
    fontSize: '14px',
    backgroundColor: 'transparent',
  },
  popupBoxSubHeader: {
    fontSize: '12px',
    padding:'1px 3px 1px 3px',
  }
});


class Map extends React.Component {

  constructor(props){
    super(props);
    this.state ={
      viewport: {
        width: '100%',
        height: '100%',
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 2,
      },
      selectedLocation:[],
      devicesWithCoordinates: [],
      coordinates: [],
      devicesDic: this.props.devicesDic,
      devices_list: this.props.devices_list
    }
  } 

  componentDidMount() {

    // remove logo
    let elements = document.getElementsByClassName('mapboxgl-ctrl-logo')    
    if(elements && elements.length) elements[0].parentNode.removeChild(elements[0]);

    
    //   Tranform devices to lat and long
    var adjustCoordinates = [];
    var newDeviceInfo = [];
    var center = [];
    this.state.devices_list.map((results, index) => {
        if(results.device_peer_host){
            getData('https://api.iotflows.com/v1/geolocation/ipaddress/'+ results.device_peer_host)
            .then(async response => {
            if(response.ok ){
                const data = await response.json();
                if(data.data){
                    this.state.devicesDic[results.device_uuid].latitude = data.data.latitude;
                    this.state.devicesDic[results.device_uuid].longitude = data.data.longitude;
                    this.state.devicesDic[results.device_uuid].country_code = data.data.country_code;
                    this.state.devicesDic[results.device_uuid].city = data.data.city;
                    this.state.devicesDic[results.device_uuid].country_flag_emoji = data.data.location.country_flag_emoji;

                    newDeviceInfo.push(this.state.devicesDic[results.device_uuid]);
                    let array = {'latitude': data.data.latitude, 'longitude': data.data.longitude};
                    adjustCoordinates.push(array);
                    this.setState({ coordinates: adjustCoordinates })
                    this.setState({ devicesWithCoordinates: newDeviceInfo })

                    center = getCenter(adjustCoordinates);
                    var newViewport = {
                        width: '100%',
                        height: '100%',
                        latitude: center.latitude,
                        longitude: center.longitude,
                        zoom: 2,
                    }
                    this.setState({viewport: newViewport})
                }
            }
            });
        }
    })

  }


  render() {
    const { classes } = this.props;
    const { viewport, coordinates, selectedLocation, devicesWithCoordinates } = this.state;


    if(Object.keys(devicesWithCoordinates).length > 0 && (Object.keys(this.props.devicesDic).length < Object.keys(devicesWithCoordinates).length)){
      // console.log("adjusting COORDINATES")
      //   Tranform devices to lat and long
      let adjustCoordinates = [];
      let newDeviceInfo = [];
      let center = [];
      this.props.devices_list.map((results, index) => {
          if(results.device_peer_host){
              getData('https://api.iotflows.com/v1/geolocation/ipaddress/'+ results.device_peer_host)
              .then(async response => {
              if(response.ok ){
                  const data = await response.json();
                  if(data.data){
                      this.props.devicesDic[results.device_uuid].latitude = data.data.latitude;
                      this.props.devicesDic[results.device_uuid].longitude = data.data.longitude;
                      this.props.devicesDic[results.device_uuid].country_code = data.data.country_code;
                      this.props.devicesDic[results.device_uuid].city = data.data.city;
                      this.props.devicesDic[results.device_uuid].country_flag_emoji = data.data.location.country_flag_emoji;

                      newDeviceInfo.push(this.props.devicesDic[results.device_uuid]);
                      let array = {'latitude': data.data.latitude, 'longitude': data.data.longitude};
                      adjustCoordinates.push(array);
                      this.setState({ coordinates: adjustCoordinates })
                      this.setState({ devicesWithCoordinates: newDeviceInfo })

                      center = getCenter(adjustCoordinates);
                      var newViewport = {
                          width: '100%',
                          height: '100%',
                          latitude: center.latitude,
                          longitude: center.longitude,
                          zoom: 2,
                      }
                      this.setState({viewport: newViewport})
                  }
              }
              });
          }
      })
    }



    return (
        <ReactMapGL 
            mapStyle='mapbox://styles/iotflows/cks2ifj255lqk17p2i5nwheqy'
            mapboxApiAccessToken='pk.eyJ1IjoiaW90Zmxvd3MiLCJhIjoiY2tzMmk2anFuMG9waTJ5bzRoN2FkbHJmNSJ9.OqgJVydjwZerowqBY-K2lA'
            {...viewport}
            width="100%"
            height="100%"
            attributionControl={false}
            onViewportChange={(nextViewport) => this.setState({viewport: nextViewport})}
            
        >
          {Object.keys(devicesWithCoordinates).length <= Object.keys(this.state.devicesDic).length &&
            devicesWithCoordinates.map(result => (
                <div key={result.device_uuid}>
                    <Marker
                        latitude={result.latitude}
                        longitude={result.longitude}
                        // offsetLeft={-20}
                        // offsetTop={-10}
                    >
                        {this.state.devicesDic[result.device_uuid].device_is_online != undefined && this.state.devicesDic[result.device_uuid].device_is_online ? 
                          <p onClick={() => this.setState({selectedLocation: result})} className="heartbeatOnlineAllDevices"> </p>
                        : 
                          <p onClick={() => this.setState({selectedLocation: result})} className="heartbeatOfflineAllDevices"> </p> 
                        }
                    </Marker>

                    {/* Popup if we click on a marker */}
                    {selectedLocation.longitude === result.longitude ? (
                        <Popup 
                            onClose={() => this.setState({selectedLocation: []})}
                            closeOnClick={true}
                            latitude={result.latitude}
                            longitude={result.longitude}
                            // tipSize={15}
                        >
                            <div >
                                <div className={classes.popupBoxHeader}>
                                    {result.device_name}
                                </div>
                                <div className={classes.popupBoxSubHeader}>
                                    {result.city},&nbsp;{result.country_code}&nbsp;{result.country_flag_emoji}
                                </div>
                              
                            </div>
                            
                        </Popup>
                        ): (
                            false
                        )
                    }
                </div>
            ))
        }
        </ReactMapGL>
      
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    searchbarUsername: state.searchbarUser.searchbarUsername,
    selectedAlertChannel: state.alertChannels.selectedAlertChannel


  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Map));

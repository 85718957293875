import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData, postDataText } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import IconButton from '@material-ui/core/IconButton';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-ruby";
import "ace-builds/src-noconflict/mode-sass";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-handlebars";
import "ace-builds/src-noconflict/mode-golang";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-coffee";
import "ace-builds/src-noconflict/mode-css";


const languagesAvailable = [ 'json','xml', 'html','css' ]

const styles = theme => ({
  paper: {
    position: 'relative',
    width: '600px',
    // width: theme.spacing(80),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    // [theme.breakpoints.down('xs')]:{
    //   width: theme.spacing(43),
    // }
  },
  button: {
    width: 'auto',
    height: 'auto',
    padding: '4px',
    margin: '0px',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-end',
    alignItems:'flex-end',
    alignItems: 'flex-end',
    fontFamily:'Trebuchet MS',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { 
    borderRadius: 10 ,
  },
  languageOptionsDropdown: {
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 300,
    backgroundColor: '#fff',
    padding: '0.5rem',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    // inset: '40px auto auto 0px',
    borderRadius: '5px',
    maxHeight: '300px',
    overflow: 'auto',
  },
  resultContainer: {
    width: '100%',
    display: 'flex',
    padding: '8px 8px 8px 12px',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#F3F4F6',
    }
  },
  searchText: {
    fontSize: '14px',
    color: '#3B82F6',
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    textTransform:'uppercase'
  },
  languageHeader:{
    cursor:'pointer', 
    display:'flex', 
    height:'40px', 
    width:'100px', 
    backgroundColor:'rgb(0,43,55)', 
    color: '#fff', 
    borderRadius: '10px 10px 0px 0px',
    justifyContent:'center',
    "&:hover": {
      opacity: '0.8',
    }
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ExecuteActionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      loading: false,
      success: false,
      editorScript:`{ "testCommand": true}`,
      error: false,
      errorMessage: 'Formatting Issues',
      showSelectedLanguage: false,
      languageSelected:'json'
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitButtonClick = this.submitButtonClick.bind(this);
  }

  componentDidMount() {

    getData('https://api.iotflows.com/v1/actions/' + this.props.actionSelected.action_uuid)
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.action_last_confirmed_status && (data.action_last_confirmed_status.length > 0)){
          this.setState({editorScript: data.action_last_confirmed_status})
        }
      }
    });

  }



  handleOpen = () => {
    this.setState({ 
      open: true,
     });
  };

  handleClose = () => {
    this.props.handleCloseModal();
    this.setState({ 
      open: false ,
      error: false,
      // errorMessage: '',
      showSelectedLanguage: false,
      languageSelected:'json'
    });
  };

  handleEditorChange = (newValue) => {
    this.setState({editorScript: newValue})
  }

  submitButtonClick = () => {
    const { loading, languageSelected } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          let editorScriptFormatted = this.state.editorScript;
          if(languageSelected == 'json'){
            try{
              editorScriptFormatted = JSON.parse(this.state.editorScript)
              postData('https://api.iotflows.com/v1/actions/' + this.props.actionSelected.action_uuid, 
              editorScriptFormatted)
                .then(async response => {
                  if(response.ok){
                    this.props.showSuccessSnackbar("Action has been executed!");
                    this.props.handleCloseModal();
                    this.setState({
                      loading: false,
                      success: true,
                      open:false,
                    },() => {this.setState({success: false})} );
                  }
                  else{
                    try{
                      const data = await response.json();
                      this.props.showErrorSnackbar(data.message);
                      this.setState({
                        loading: false,
                        success: false,
                        open:false,
                      });
                    }catch (e){
                      this.props.showErrorSnackbar('Something went wrong');
                    }
                  }
                })
            } 
            catch(err){
              this.props.showErrorSnackbar("Incorrect JSON Format");
              this.setState({
                error: true,
                errorMessage: err.toString(),
                loading: false,
                success: false,
              })
              // console.log("e 1")
              // console.log(JSON.stringify(err, Object.getOwnPropertyNames(err)))
              // console.log("e toString")
              // console.log(err.toString())
            }
          }else{
            try{
              postDataText('https://api.iotflows.com/v1/actions/' + this.props.actionSelected.action_uuid, 
              editorScriptFormatted)
                .then(async response => {
                  if(response.ok){
                    this.props.showSuccessSnackbar("Action has been executed!");
                    this.props.handleCloseModal();
                    this.setState({
                      loading: false,
                      success: true,
                      open:false,
                    },() => {this.setState({success: false})} );
                  }
                  else{
                    try{
                      const data = await response.json();
                      this.props.showErrorSnackbar(data.message);
                      this.setState({
                        loading: false,
                        success: false,
                        open:false,
                      });
                    }catch (e){
                      this.props.showErrorSnackbar('Something went wrong');
                      this.setState({
                        loading: false,
                        success: false,
                      });
                    }
                  }
                })
            }
            catch(e){
              this.props.showErrorSnackbar("Issues Executing Action");
              this.setState({
                error: true,
                errorMessage: e.toString(),
                loading: false,
                success: false,
              })
            }
          }
        }
      )
    }
  };

  handleSelectedLanguage = (language) => {
    this.setState({
      languageSelected: language,
      showSelectedLanguage: false
    })
  }


  render() {
    const { classes, actionSelected } = this.props;
    const { open, 
            loading, 
            success,
            editorScript,
            errorMessage,
            error,
            showSelectedLanguage,
            languageSelected} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    

    return (
      <Grid
        container
        alignItems="flex-start"
        justify="flex-start"
        direction="column"
        
      >
        {/* <IconButton className={classes.button} variant="outlined" onClick={this.handleOpen}  ><EditOutlinedIcon style={{color: '#bdbdbd'}}/></IconButton> */}
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Execute {actionSelected.action_name} Action</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '20px'}}>
                
                <div onClick={() => this.setState({showSelectedLanguage: !showSelectedLanguage})} className={classes.languageHeader} >
                  <span style={{fontFamily:'Trebuchet MS', paddingTop: '10px', paddingLeft:'5px', textTransform:'uppercase'}}>
                    {languageSelected}
                  </span>
                  <span style={{paddingTop: '9px', paddingLeft:'3px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </span>
                </div>
                {showSelectedLanguage && 
                  <div className={classes.languageOptionsDropdown}  >
                    {languagesAvailable.map(language => (
                      <div key={language} className={classes.resultContainer} onClick={() => this.handleSelectedLanguage(language)} >
                        <div>
                          <span className={classes.searchText}>{language}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                }
                <AceEditor
                  placeholder="Place code here"
                  mode={languageSelected}
                  theme="solarized_dark"
                  name="blah2"
                  onLoad={this.onLoad}
                  onChange={this.handleEditorChange}
                  fontSize={14}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  value={editorScript}
                  style={{width: 'auto', borderRadius: '0px 10px 10px 10px'}}
                  setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                  }}/>

                  {error && 
                  <div style={{marginTop:'14px', marginBottom: '10px'}}>
                    <span style={{fontSize:'14px', color: '#DC2626'}}>
                      {errorMessage}
                    </span>
                  </div>
                  }

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Execute Action { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => state

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ExecuteActionModal));

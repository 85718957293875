import React from 'react';
import { withStyles} from '@material-ui/core/styles';
import {getData, postData, putData, deleteData} from '../ApiCalls/DataApis';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteCloudServerModal from '../components/DeleteCloudServerModal';
import RebootCloudServerModal from './RebootCloudServerModal';
import CreateInstanceModal from '../components/CreateInstanceModal';
import EditInstanceModal from '../components/EditInstanceModal';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MqttService from '../core/services/MqttService';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileManagerCredentialsModal from '../MyDevicesPage/FileManagerCredentialsModal';
import FileManagementTable from "../MyDevicesPage/FileManagementTable.js"
import SshServerConnectedInfoModal from "./SshServerConnectedInfoModal"
import MenuComponentNodeRed from "./MenuComponentNodeRed"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '3px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'10px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    minWidth:'70px',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'10px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    "$selected &": {
      backgroundColor: "rgb(232, 232, 232)",
    },
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  tableContainer: {
    borderRadius: '10px',
  },
  table: {
    minWidth: 400,
  },
  CN1SubscriptionCSS: {
    width: '78px',
    borderRadius: '5px',
    backgroundColor: 'rgb(219, 219, 219)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  CN2SubscriptionCSS: {
    width: '78px',
    borderRadius: '5px',
    backgroundColor: 'rgb(232, 235, 192)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  CN3SubscriptionCSS: {
    width: '78px',
    borderRadius: '4px',
    backgroundColor: 'rgb(201, 220, 244)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  CN4SubscriptionCSS: {
    width: '78px',
    borderRadius: '4px',
    backgroundColor: 'rgb(203, 244, 201)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  buttonProgress: {
    color: 'rgb(33, 117, 243)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(52, 58, 64,0.5)"
    }
  },
  hover: {},
  selected: {},
  tableCell: {
    "$selected &": {
      color: "rgb(232, 232, 232)"
    }
  },
});


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);


class ApiKeysPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      cloudNodeRedInstances: [],
      line_items: [],
      line_items_this_month: [],
      line_items_next_month: [],
      next_month_start_date: '',
      next_month_end_date: '',
      selectedCloudServer: [],
      selectedCloudServerUrl: '',
      selectedCloudServerUuid: '',
      openFileManager: false,
      openSshServerConnectedInfoModal: false,
      userAnchorEl: null,
      userOpen: false,
    }
    this.refreshCloudNodeRedInstanceList = this.refreshCloudNodeRedInstanceList.bind(this);
    this.handlerCloseSshServerConnectedInfoModal = this.handlerCloseSshServerConnectedInfoModal.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem('ssh_username');
    localStorage.removeItem('ssh_password');
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/cloud-servers')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            cloudNodeRedInstances: data.data
          });
        }
      });

      if(MqttService.isConnected) {
        MqttService.subscribe('v1/api/users/' + this.props.currentUserInfo[0].user_username + '/notifications', this.onCloudNotifications)
      }
  }

  onCloudNotifications = message => {
    try {
      const notification_message = JSON.parse(message.payloadString);

      if(notification_message.code == 200 && notification_message.service == "cloud-servers" ){
        this.props.showSuccessSnackbar(notification_message.message);
        this.refreshCloudNodeRedInstanceList();
      }
      
    }
    catch(e){
      console.log("Error in reading data stream in project from MQTT");
      console.log(e.message);
    }
  }

  refreshCloudNodeRedInstanceList(){
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/cloud-servers')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        this.setState({
          cloudNodeRedInstances: data.data,
        })
      }
    });
  }
  

  dateConverter(date){
    // date.toLocaleDateString() + ', ' + date.toLocaleTimeString();
    if(date != undefined){
      // var modifyDate = date*1000;
      var modifyDate = new Date(date);
      // return modifyDate.toLocaleDateString();
      return modifyDate.toLocaleDateString([], {  month: 'short', day: 'numeric' });
    } else {
      return " "
    }
  }

  componentWillUnmount() {
    if(MqttService.isConnected) {
      MqttService.unsubscribe('v1/api/users/' + this.props.currentUserInfo[0].user_username + '/notifications')
    }
  }

  dateConverter(date){
    // date.toLocaleDateString() + ', ' + date.toLocaleTimeString();
    if(date){
      var modifyDate = date;
      modifyDate = new Date(modifyDate);
      return modifyDate.toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' });
    } else {
      return " "
    }
  }

  handlerOpenFileManager = (instance) => {
    this.setState({ 
      selectedCloudServer: [],
      openFileManager: false
    }, () => {
      this.setState({ 
        selectedCloudServer: instance,
        openFileManager: true
      })
    })
  }

  handlerSshServerConnectedInfoModal = (instance) => {
    this.setState({ 
      selectedCloudServer: instance
    }, () =>{
      this.setState({ 
        openSshServerConnectedInfoModal: true
      })
    })
    
  }

  handlerCloseSshServerConnectedInfoModal = () => {
    this.setState({ 
      selectedCloudServer: [],
      openSshServerConnectedInfoModal: false
    });
  }

  

  render(){
    const { classes} = this.props
    const {cloudNodeRedInstances,
            openFileManager,
            selectedCloudServer,
            openSshServerConnectedInfoModal,
          } = this.state


    return (
      <div className={classes.root} style={{marginBottom: '60px'}}>
        <div className={classes.breadcrumbCSS}>
          <Breadcrumbs  separator="›" aria-label="breadcrumb">
            <Typography className={classes.fontStyles} style={{color:'#757575'}}>{this.props.selectedOrganization.organization_name}</Typography>
            <Typography className={classes.fontStyles} color="textPrimary">Instances</Typography>
          </Breadcrumbs>
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="spanning table">
            <colgroup>
              <col style={{width:'10%'}}/>
              <col style={{width:'12%'}}/>
              <col style={{width:'8%'}}/>
              <col style={{width:'8%'}}/>
              <col style={{width:'8%'}}/>
              <col style={{width:'15%'}}/>
              <col style={{width:'10%'}}/>
              <col style={{width:'10%'}}/>
              <col style={{width:'10%'}}/>
              <col style={{width:'3%'}}/>
              <col style={{width:'3%'}}/>
              <col style={{width:'3%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{fontSize: '24px', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={5}>
                  <b>Cloud Node-RED Servers</b>
                </StyledTableCell>
                <StyledTableCell style={{paddingRight: '12px'}} align="right" colSpan={6}>
                  <CreateInstanceModal refreshCloudNodeRedInstanceList={this.refreshCloudNodeRedInstanceList}/>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell style={{paddingLeft: '12px'}}>Name</StyledTableCell>
                <StyledTableCell >Description</StyledTableCell>
                <StyledTableCell >Cloud Server Type</StyledTableCell>
                <StyledTableCell >Node-RED</StyledTableCell>
                <StyledTableCell >SSH</StyledTableCell>
                <StyledTableCell ></StyledTableCell>
                <StyledTableCell >DNS</StyledTableCell>
                <StyledTableCell >IP Address</StyledTableCell>
                <StyledTableCell >Created at</StyledTableCell>
                <StyledTableCell ></StyledTableCell>
                <StyledTableCell ></StyledTableCell>
                <StyledTableCell ></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(cloudNodeRedInstances).length > 0 ?
                cloudNodeRedInstances.map((instance, index) => (
                  <TableRow id={index} 
                    selected={instance.cloud_server_uuid == selectedCloudServer.cloud_server_uuid} 
                    classes={{ hover: classes.hover, selected: classes.selected }}
                    className={classes.tableRow}  
                  >
                    <StyledTableCell className={classes.tableCell} style={{paddingLeft: '12px'}}>{instance.cloud_server_name}</StyledTableCell>
                    <StyledTableCell className={classes.tableCell} >{instance.cloud_server_description}</StyledTableCell>
                    <StyledTableCell className={classes.tableCell} align="left">
                      {instance.subscription_plan_identifier == "cns-small" ? 
                        <div className={classes.CN1SubscriptionCSS} >
                          <span style={{fontSize: '11px', color:'rgb(99, 99, 99)'}} >Small</span>
                        </div>
                      : null}
                      {instance.subscription_plan_identifier == "cns-medium" ? 
                        <div className={classes.CN2SubscriptionCSS} >
                          <span style={{fontSize: '11px', color:'rgb(106, 112, 22)'}} >Medium</span>
                        </div>
                      : null}
                      {instance.subscription_plan_identifier == "cns-large" ? 
                        <div className={classes.CN3SubscriptionCSS} >
                          <span style={{fontSize: '11px', color:'rgb(14, 50, 97)'}}>Large</span>
                        </div>
                      : null}
                      {instance.subscription_plan_identifier == "ent-cns-medium" ? 
                        <div className={classes.CN2SubscriptionCSS} >
                          <span style={{fontSize: '11px', color:'rgb(106, 112, 22)'}} >Ent-Medium</span>
                        </div>
                      : null}
                      {instance.subscription_plan_identifier == "ent-cns-large" ? 
                        <div className={classes.CN3SubscriptionCSS} >
                          <span style={{fontSize: '11px', color:'rgb(14, 50, 97)'}}>Ent-Large</span>
                        </div>
                      : null}
                      {instance.subscription_plan_identifier == "ent-cns-cluster" ? 
                        <div className={classes.CN4SubscriptionCSS} >
                          <span style={{fontSize: '11px', color:'rgb(14, 98, 69)'}}>Ent-Clusters</span>
                        </div>
                      : null}
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableCell} >
                      <MenuComponentNodeRed instance={instance} selected={instance.cloud_server_uuid == selectedCloudServer.cloud_server_uuid}/>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableCell} >
                      <Button 
                        className={classes.button} 
                        disabled={!instance.cloud_server_is_ready}
                        variant="contained"
                        onClick={() => this.handlerSshServerConnectedInfoModal(instance) }
                      >
                        {'SSH'}  {!instance.cloud_server_is_ready && <CircularProgress size={24} className={classes.buttonProgress} /> }      
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableCell}>
                      <Button 
                        className={classes.button} 
                        style={{minWidth: '85px'}}
                        disabled={!instance.cloud_server_is_ready}
                        variant="contained"
                        onClick={() => this.handlerOpenFileManager(instance) }
                      >
                        {'Secure File Transfer'}  {!instance.cloud_server_is_ready && <CircularProgress size={24} className={classes.buttonProgress} /> }      
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableCell} >{instance.cloud_server_dns_record_name}</StyledTableCell>
                    <StyledTableCell className={classes.tableCell} >{instance.cloud_server_public_ip_address}</StyledTableCell>
                    <StyledTableCell className={classes.tableCell} >{this.dateConverter(instance.cloud_server_created_at)} </StyledTableCell>
                    <StyledTableCell align="right" style={{paddingRight: '12px'}} >
                      <RebootCloudServerModal cloud_server_uuid={instance.cloud_server_uuid} cloud_server_name={instance.cloud_server_name}/>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableCell} align="center" style={{paddingRight: '12px'}} >
                      <EditInstanceModal cloud_server_uuid={instance.cloud_server_uuid} cloud_server_name={instance.cloud_server_name} cloud_server_description={instance.cloud_server_description} refreshCloudNodeRedInstanceList={this.refreshCloudNodeRedInstanceList} />
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableCell} align="center" style={{paddingRight: '12px'}} >
                      <DeleteCloudServerModal cloud_server_uuid={instance.cloud_server_uuid} cloud_server_name={instance.cloud_server_name} refreshCloudNodeRedInstanceList={this.refreshCloudNodeRedInstanceList} />
                    </StyledTableCell>
                  </TableRow>
                ))
              :null}

            </TableBody>
          </Table>
        </TableContainer>

        {/* {Object.keys(selectedCloudServer).length > 0 && localStorage.getItem('ssh_username') && localStorage.getItem('ssh_password') ?  */}
        {openFileManager && Object.keys(selectedCloudServer).length > 0 ? 
          <FileManagementTable path={'/'} instrumentType_uuid={`cloud-servers/${selectedCloudServer.cloud_server_uuid}`} server_url_ssh={selectedCloudServer.cloud_server_dns_record_name} serverName={selectedCloudServer.cloud_server_name} /> 
          : null}

        {openSshServerConnectedInfoModal && selectedCloudServer?
          <SshServerConnectedInfoModal 
            open={openSshServerConnectedInfoModal}  
            selectedCloudServer={selectedCloudServer} 
            handlerCloseSshServerConnectedInfoModal={this.handlerCloseSshServerConnectedInfoModal} 
          />
          :null}
        
      </div>
      )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    currentUserInfo: state.users.currentUserInfo,
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ApiKeysPage));






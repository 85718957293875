import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {putData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import EnvironmentVariablesInputs from './EnvironmentVariablesInputs';
import {apiLink} from '../ApiCalls/ApisVariables'


const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(70),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    width: 'auto',
    height: 'auto',
    padding: '4px',
    margin: '0px',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-end',
    alignItems:'flex-end',
    alignItems: 'flex-end',
    fontFamily:'Trebuchet MS',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class EditContainerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      // imageWithTag: this.props.row.imageWithTag,
      imageWithTag: this.props.definedContainer.container_image,
      killBeforeRunState: this.props.definedContainer.container_kill_before_run ? 'killTrue':'killFalse',
      privatePort: this.props.definedContainer && this.props.definedContainer.container_port_bindings && this.props.definedContainer.container_port_bindings[0]['private_port'] ? this.props.definedContainer.container_port_bindings[0]['private_port'] : '',
      publicPort: this.props.definedContainer && this.props.definedContainer.container_port_bindings && this.props.definedContainer.container_port_bindings[0]['public_port'] ? this.props.definedContainer.container_port_bindings[0]['public_port'] : '',
      environmentVariablesValues: [
        {
          environmentVarKey:'',
          environmentVarValue:'',
        }
      ]
      // killBeforeRunState: this.props.row.killBeforeRunState,
      // environmentVariablesValues: this.props.row.environmentVariablesValues
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitButtonClick = this.submitButtonClick.bind(this);
  }

  componentDidMount(){
    let tempEnvVar = [];
    for (const [key, value] of Object.entries(this.props.definedContainer.container_environment_variables)) {
      // console.log(`${key}: ${value}`)
      tempEnvVar.push({environmentVarKey: key, environmentVarValue: value})
    }
    // console.log(tempEnvVar)
    this.setState({environmentVariablesValues: tempEnvVar})
  }

  componentWillReceiveProps(nextProps) {
    // console.log('nextProps', nextProps)
    // this.setState({
    //   imageWithTag: nextProps.row.imageWithTag,
    //   privatePort: nextProps.row.privatePort,
    //   publicPort: nextProps.row.publicPort,
    //   killBeforeRunState: nextProps.row.killBeforeRunState,
    //   environmentVariablesValues: nextProps.row.environmentVariablesValues,
    // }); 
  };

  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
    });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
    });
  };

  handleImageWithTagChange = (event) => {
    this.setState({imageWithTag: event.target.value});
  }
  handlePrivatePortChange = (event) => {
    this.setState({privatePort: event.target.value});
  }
  handlePublicPortChange = (event) => {
    this.setState({publicPort: event.target.value});
  }

  handleKillBeforeRunChange = (event) => {
    this.setState({killBeforeRunState: event.target.value});
  }

  submitButtonClick = () => {
    const { loading, environmentVariablesValues } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        let portBindings = [{'private_port':this.state.privatePort,"public_port":this.state.publicPort}]
        let tempVar ={}
        for(let i=0; i <= Object.keys(environmentVariablesValues).length - 1; i++ ){
          if(environmentVariablesValues[i].environmentVarKey && environmentVariablesValues[i].environmentVarValue){
            let currentkey = environmentVariablesValues[i].environmentVarKey
            let currentval = environmentVariablesValues[i].environmentVarValue
            tempVar[currentkey] = currentval
          }
        }

        let killRun = true
        if(this.state.killBeforeRunState === 'killFalse'){
          killRun = false
        }

        putData(apiLink + '/v1/containers/' + this.props.definedContainer.container_uuid , 
        {'container_image': this.state.imageWithTag,
          'container_kill_before_run': killRun,
          'container_port_bindings': portBindings,
          'container_environment_variables': tempVar})
          .then(async response => {
            if(response.ok){
              this.props.showSuccessSnackbar("Container has been successfully modified!");
              this.props.refreshContainerList();
              this.setState({
                loading: false,
                success: true,
                open:false,
              }, ()=> {this.handleClose()});
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false,
                }, ()=> {this.handleClose()});
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  // ********** Functions for Environment variables Inputs **********
  handleChangeEnvironmentVariablesValues = (i, e, id) => {
    // console.log('id', id)
    let newEnvironmentVariablesValues = [...this.state.environmentVariablesValues];
    
    newEnvironmentVariablesValues[i][e.target.name] = e.target.value

    this.setState({environmentVariablesValues: newEnvironmentVariablesValues})
    

  }
    
  addPartInputFormFields = () => {
    const {environmentVariablesValues} = this.state
    
    let tempVar = [...environmentVariablesValues, {environmentVarKey:'', environmentVarValue:''}]
    this.setState({environmentVariablesValues: tempVar})
      
  }

  RemoveEnvironmentVariablesFields = (i) => {
      // console.log('i',i)
      let newEnvironmentVariablesValues = [...this.state.environmentVariablesValues];
      newEnvironmentVariablesValues.splice(i, 1);
      this.setState({environmentVariablesValues: newEnvironmentVariablesValues})
  }

  // ********** Functions for Environment variables Inputs **********



  render() {
    const { classes } = this.props;
    const { open, 
            loading, 
            success,
            privatePort,
            publicPort,
            imageWithTag,
            killBeforeRunState,
            environmentVariablesValues} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    
    // console.log('this.props.definedContainer', this.props.definedContainer)
    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
      >
        
        <IconButton className={classes.button} variant="outlined" onClick={this.handleOpen}><EditOutlinedIcon style={{color: '#757575'}}/></IconButton>

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Edit Container</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px'}}>

                  <TextField
                    style={{marginTop: '0px'}}
                    margin="normal"
                    id="imageWithTag"
                    label="Image with tag"
                    type="text"
                    value={imageWithTag} 
                    onChange={this.handleImageWithTagChange}
                    fullWidth
                    required
                    size="small"
                  />
                  
                  <div style={{fontSize:'14px',fontWeight:700, fontFamily:'Trebuchet MS',marginTop:'16px'}} >
                  Kill before run
                  </div>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={killBeforeRunState}
                      onChange={this.handleKillBeforeRunChange}
                    >
                      <FormControlLabel value='killTrue' control={<Radio color="primary" />} label="True" />
                      <FormControlLabel value='killFalse' control={<Radio color="primary" />} label="False" />
                    </RadioGroup>
                  </FormControl>

                  <div style={{fontSize:'14px',fontWeight:700, fontFamily:'Trebuchet MS',marginTop:'16px'}} >
                    Port Bindings
                  </div>
                  <TextField
                    style={{marginTop: '0px'}}
                    margin="normal"
                    id="privatePort"
                    label="Private Port"
                    type="text"
                    value={privatePort} 
                    onChange={this.handlePrivatePortChange}
                    fullWidth
                    required
                    size="small"
                  />
                  <TextField
                    style={{marginTop: '0px'}}
                    margin="normal"
                    id="publicPort"
                    label="Public Port"
                    type="text"
                    value={publicPort} 
                    onChange={this.handlePublicPortChange}
                    fullWidth
                    required
                    size="small"
                  />

                  <div style={{fontSize:'14px',fontWeight:700, fontFamily:'Trebuchet MS',marginTop:'16px'}} >
                   Environment Variables
                  </div>
                  <div style={{marginTop:'8px'}}>
                    {environmentVariablesValues.map((variables, index) => (
                      <div key={index}>
                        <EnvironmentVariablesInputs variables={variables} index={index} handleChangeEnvironmentVariablesValues={this.handleChangeEnvironmentVariablesValues} RemoveEnvironmentVariablesFields={this.RemoveEnvironmentVariablesFields} />
                      </div>
                    ))}
                  </div>
                  <div className='w-full flex pt-1'>
                    <div style={{fontSize:'12px',fontWeight:700, fontFamily:'Trebuchet MS',marginTop:'8px', cursor:'pointer', color:'#2563eb', width:' fit-content'}} onClick={this.addPartInputFormFields}>
                      Add another environment variable
                    </div>
                  </div>
                


                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(EditContainerModal));

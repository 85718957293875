import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, rgbToHex } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import IconButton from '@material-ui/core/IconButton';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PasswordInfoModal from '../components/PasswordInfoModal';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import InitialDeviceConfigSetupModal from '../MyDevicesPage/InitialDeviceConfigSetupModal';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    maxWidth: theme.spacing(150),
    minWidth: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('md')]:{
      maxWidth: theme.spacing(110),
      minWidth: theme.spacing(60),
    },
    [theme.breakpoints.down('sm')]:{
      maxWidth: theme.spacing(70),
      minWidth: theme.spacing(60),
    },
    [theme.breakpoints.down('xs')]:{
      maxWidth: theme.spacing(43),
      minWidth: theme.spacing(43),
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    borderRadius: 10, 
    maxWidth: theme.spacing(150),
  },
  cardCSS: {
    width: '100%',
    borderRadius: '10px',
    border:'1px solid #f5f5f5',
    padding: '8px',
    backgroundColor: '#fafafa',
    boxShadow: '2px 4px #f5f5f5',
    cursor:'pointer',
    '&:hover': {
      // backgroundColor:'#eeeeee',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid #bdbdbd',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'#eeeeee',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor:'#eeeeee',
      border:'1px solid #bdbdbd',
      boxShadow: '2px 4px #bdbdbd',
    },
  },
  payAsYouGoCardCSS: {
    maxWidth: '400px',
    width: '100%',
    borderRadius: '10px',
    border:'1px solid #f5f5f5',
    padding: '8px',
    backgroundColor: '#fafafa',
    boxShadow: '2px 4px #f5f5f5',
    cursor:'pointer',
    '&:hover': {
      // backgroundColor:'#eeeeee',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid #bdbdbd',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'#eeeeee',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor:'#eeeeee',
      border:'1px solid #bdbdbd',
      boxShadow: '2px 4px #bdbdbd',
    },
  },
  payAsYouGoCardCSSSelected: {
    maxWidth: '400px',
    width: '100%',
    borderRadius: '10px',
    padding: '8px',
    outline: 'none',
    backgroundColor:'#eeeeee',
    border:'1px solid #bdbdbd',
    boxShadow: '2px 4px #bdbdbd',
    position:'relative',
    cursor:'pointer',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  cardCSSSelected: {
    width: '100%',
    borderRadius: '10px',
    padding: '8px',
    outline: 'none',
    backgroundColor:'#eeeeee',
    border:'1px solid #bdbdbd',
    boxShadow: '2px 4px #bdbdbd',
    position:'relative',
    cursor:'pointer',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  headerCSS: {
    textAlign: 'center',
    color: '#1976d2',
    fontFamily:'Trebuchet MS',
  },
  bodyCSS: {
    fontSize: '14px',
    color: '#525f7f',
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
  },
  pricingCSS: {
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
    marginTop: '16px',
    marginBottom: '16px',
  },
  customButton: {
    width: '100%',
    marginBottom: '10px', 
    marginTop: '10px',
    borderRadius: '5px',
    border:'1px solid #f5f5f5',
    backgroundColor:'rgba(87, 147, 222, 0.1)',
    '&:hover': {
      backgroundColor:'rgba(87, 147, 222, 0.3)',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid rgba(87, 147, 222, 0.5)',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'rgba(87, 147, 222, 0.4)',
      border:'1px solid rgba(87, 147, 222, 0.7)',
    },
    '&:focus': {
      outline: 'none',
      // backgroundColor:'#eeeeee',
      // border:'1px solid #bdbdbd',
      // boxShadow: '2px 4px #bdbdbd',
    },
  },
  itemsCSS: {
    fontSize: '12px',
    color: '#525f7f',
    textAlign: 'left',
    fontFamily:'Trebuchet MS',
    listStyleType: 'none',
    marginTop: '15px',
    padding: '0px',
    height: '170px',
    [theme.breakpoints.down('md')]:{
      height: '215px',
    },
    [theme.breakpoints.down('sm')]:{
      height: '150px',
    },
    [theme.breakpoints.down('xs')]:{
      height: '100%',
    }
  },
  itemsFreeCSS: {
    fontSize: '12px',
    color: '#525f7f',
    textAlign: 'left',
    fontFamily:'Trebuchet MS',
    listStyleType: 'none',
    marginTop: '15px',
    padding: '0px',
    // height: '250px',
    // [theme.breakpoints.down('md')]:{
    //   height: '250px',
    // },
    // [theme.breakpoints.down('sm')]:{
    //   height: '350px',
    // },
    // [theme.breakpoints.down('xs')]:{
    //   height: '100%',
    // }
  },
  itemCSS: {
    padding: '5px',
  },
  subItemCSS: {
    fontSize: '11px',
    padding: '5px',
    paddingLeft: '10px',
  },
  checkmarkCSS: {
    fontSize: '14px',
    color:'rgb(0,209,0)',
  },
  circleCSS: {
    fontSize: '14px',
    color:'rgb(191, 191, 191)',
  },
  deviceCSS: {
    color:'#4f87cc',
    width: '240px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    margin: '10px',
    padding: '20px',
    textAlign: 'center',
    webkitBoxShadow: '0 30px 60px -12px rgb(50 50 93 / 25%), 0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%)',
    boxShadow: '0 30px 60px -12px rgb(50 50 93 / 25%), 0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%)',
    '&:hover': {
      backgroundColor:'rgba(219, 219, 219, 0.3)',
      transform: 'translateY(-5px)',
    },
  },
  selectedDeviceCSS: {
    marginTop: '12px', 
    fontSize: '20px', 
    color:'#4f87cc', 
    cursor:'pointer',
    '&:hover': {
      color:'rgb(79, 135, 204, 0.8)',
      transform: 'translateY(-1px)',
    },
  },
  sectionPositions: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  subscriptionCard: {
    width: '240px',
    margin: '10px',
    borderRadius: '10px',
    // border:'1px solid #f5f5f5',
    border:'1px solid rgba(207, 207, 207, 0.3)',
    padding: '16px 8px',
    backgroundColor: '#fff',
    // backgroundColor: '#fafafa',
    // boxShadow: '2px 4px #f5f5f5',
    cursor:'pointer',
    webkitBoxShadow: '0 30px 60px -12px rgb(50 50 93 / 25%), 0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%)',
    boxShadow: '0 30px 60px -12px rgb(50 50 93 / 25%), 0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%)',
    '&:hover': {
      border:'1px solid rgba(207, 207, 207, 0.5)',
      backgroundColor:'rgba(219, 219, 219, 0.3)',
      transform: 'translateY(-5px)',
    },
  },

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class AddDeviceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      deviceName: '',
      deviceDescription: '',
      deviceIsPrivate: '',
      deviceType: '',
      subscriptionType:'',
      initialDeviceConfigSetupModalOpen: false,
      initalSetupParameters: [],

    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
      deviceName: '',
      deviceDescription: '',
      deviceIsPrivate: '',
      deviceType: '',
      subscriptionType:'',
      initialDeviceConfigSetupModalOpen: false,
      initalSetupParameters: [],
    });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
      deviceName: '',
      deviceDescription: '',
      deviceIsPrivate: '',
      deviceType: '',
      subscriptionType:'',
      initialDeviceConfigSetupModalOpen: false,
      initalSetupParameters: [],
    });
  };

  handleDeviceNameChange = (event) => {
    this.setState({deviceName: event.target.value});
  }

  handleDeviceDescriptionChange = (event) => {
    this.setState({deviceDescription: event.target.value});
  }

  handleSubscriptionTypeChange = (event) => {
    this.setState({subscriptionType: event});
  }

  

  submitButtonClick = () => {
    const { loading, deviceType } = this.state;

    var API_Link = '';
    if(deviceType === 'physicalDevice'|| deviceType === 'physicalEnterpriseDevice'){
      API_Link = 'https://api.iotflows.com/v1/projects/' + this.props.current_project_uuid + '/devices';
    }else if (deviceType === 'virtualDevice' || deviceType === 'virtualEnterpriseDevice') {
      API_Link = 'https://api.iotflows.com/v1/projects/' + this.props.current_project_uuid + '/virtual_devices';
    }
    
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        postData(API_Link, 
        {'device_name': this.state.deviceName,
          'device_description': this.state.deviceDescription,
          "device_subscription_identifier": this.state.subscriptionType,
        })
          .then(async response => {
            if(response.ok){
              const data = await response.json();
              if(deviceType === 'physicalDevice' || deviceType === 'physicalEnterpriseDevice'){
                this.setState({initalSetupParameters: data.data.config.initial_setup},
                  () => this.setState({initialDeviceConfigSetupModalOpen: true})
                  )
                
              }
              
              this.props.showSuccessSnackbar("Device has been successfully added!");
              this.props.refreshDeviceList();
              this.setState({
                loading: false,
                success: true,
                open:false,
              });
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false});
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  


  render() {
    const { classes, selectedOrganization } = this.props;
    const { open, 
            loading, 
            success, 
            deviceName,
            deviceDescription,
            deviceType,
            subscriptionType,
            initialDeviceConfigSetupModalOpen,
            initalSetupParameters} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
      >
        
        <Button className={classes.button} variant="contained"   onClick={this.handleOpen}><AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Add Device</Button>

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >

                
                    <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">
                    {selectedOrganization.organization_is_enterprise?
                      'Add Enterprise Device'
                      :
                      'Add Device'
                    }
                    </DialogTitle>
                    <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px'}}>

                    {selectedOrganization.organization_is_enterprise ?
                      <div>
                      <div  className={classes.sectionPositions} style={{ display: (!deviceType && !subscriptionType ) ? "block" : "none" }} >
                        <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '20px'}}>
                          <b>Select Enterprise Device Type</b>
                        </DialogContentText>
                        <div style={{display:'flex'}} >
                            <div className={classes.deviceCSS} onClick={()=> this.setState({deviceType: 'physicalEnterpriseDevice'})} >
                              <h4 style={{marginTop: '20px'}} >Enterprise Device</h4>
                            </div>
                            <div className={classes.deviceCSS} onClick={()=> this.setState({deviceType: 'virtualEnterpriseDevice'})}>
                              <h4 style={{marginTop: '10px'}}>Virtual Enterprise Device</h4>
                            </div>
                        </div>
                      </div>

                      <div  style={{ display: (deviceType && !subscriptionType) ? "block" : "none" }} >
                        <div className={classes.selectedDeviceCSS} onClick={()=> this.setState({deviceType: ''})} >
                          <h5 style={{textTransform: 'uppercase', display: 'flex'}}>
                            <b>Enterprise Device Type:&nbsp;</b>
                            {deviceType === "physicalEnterpriseDevice" ? 'Enterprise Device' : null}
                            {deviceType === "virtualEnterpriseDevice" ? 'Virtual Enterprise Device':null}
                          </h5>
                        </div>
                        {deviceType == 'virtualEnterpriseDevice' ? 
                          // virtual device subscriptions
                          <div>
                            <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '20px'}}>
                              <b>Virtual Enterprise Device Plans</b>
                            </DialogContentText>
                            <div style={{display:'flex'}} >
                                <div className={classes.subscriptionCard}  onClick={()=> this.setState({subscriptionType: 'ent-vr-dev-standard'})}>
                                  <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Enterprise Standard</h4>
                                </div>
                                <div className={classes.subscriptionCard}   onClick={()=> this.setState({subscriptionType: 'ent-vr-dev-pro'})}>
                                  <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Enterprise Pro</h4>
                                </div>
                            </div>
                          </div> 
                          :
                          // Physical device subscriptions
                          <div>
                            <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '20px'}}>
                              <b>Enterprise Device Plans</b>
                            </DialogContentText>
                            <div style={{display:'flex'}} >
                                <div className={classes.subscriptionCard}  onClick={()=> this.setState({subscriptionType: 'ent-dev-standard'})}>
                                  <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Enterprise Standard</h4>
                                </div>
                                <div className={classes.subscriptionCard}   onClick={()=> this.setState({subscriptionType: 'ent-dev-pro'})}>
                                  <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Enterprise Pro</h4>
                                </div>
                            </div>
                          </div> 

                        }
                      </div>
                      </div>
                  : 
                      <div>
                      <div  className={classes.sectionPositions} style={{ display: (!deviceType && !subscriptionType ) ? "block" : "none" }} >
                        <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '20px'}}>
                          <b>Select Device Type</b>
                        </DialogContentText>
                        <div style={{display:'flex'}} >
                            <div className={classes.deviceCSS} onClick={()=> this.setState({deviceType: 'physicalDevice'})} >
                              <h4 style={{marginTop: '10px'}} >Device</h4>
                            </div>
                            <div className={classes.deviceCSS} onClick={()=> this.setState({deviceType: 'virtualDevice'})}>
                              <h4 style={{marginTop: '10px'}}>Virtual Device</h4>
                            </div>
                        </div>
                      </div>

                      <div  style={{ display: (deviceType && !subscriptionType) ? "block" : "none" }} >
                        <div className={classes.selectedDeviceCSS} onClick={()=> this.setState({deviceType: ''})} >
                          <h5 style={{textTransform: 'uppercase', display: 'flex'}}>
                            <b>Device Type:&nbsp;</b>
                            {deviceType === "physicalDevice" ? 'Device' : null}
                            {deviceType === "virtualDevice" ? 'Virtual Device':null}
                          </h5>
                        </div>
                        {deviceType == 'virtualDevice' ? 
                          // virtual device subscriptions
                          <div>
                            <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '20px'}}>
                              <b>Virtual Device Plans</b>
                            </DialogContentText>
                            <div style={{display:'flex'}} >
                                <div className={classes.subscriptionCard} onClick={()=> this.setState({subscriptionType: 'vr-dev-basic'})} >
                                  <h4 className={classes.headerCSS} style={{marginTop: '10px'}} >Basic</h4>
                                </div>
                                <div className={classes.subscriptionCard}  onClick={()=> this.setState({subscriptionType: 'vr-dev-standard'})}>
                                  <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Standard</h4>
                                </div>
                                <div className={classes.subscriptionCard}   onClick={()=> this.setState({subscriptionType: 'vr-dev-pro'})}>
                                  <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Pro</h4>
                                </div>
                            </div>
                          </div> 
                          :
                          // Physical device subscriptions
                          <div>
                            <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '20px'}}>
                              <b>Device Plans</b>
                            </DialogContentText>
                            <div style={{display:'flex'}} >
                                <div className={classes.subscriptionCard} onClick={()=> this.setState({subscriptionType: 'dev-basic'})} >
                                  <h4 className={classes.headerCSS} style={{marginTop: '10px'}} >Basic</h4>
                                </div>
                                <div className={classes.subscriptionCard}  onClick={()=> this.setState({subscriptionType: 'dev-standard'})}>
                                  <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Standard</h4>
                                </div>
                                <div className={classes.subscriptionCard}   onClick={()=> this.setState({subscriptionType: 'dev-pro'})}>
                                  <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Pro</h4>
                                </div>
                            </div>
                          </div> 

                        }
                      </div>
                      </div>
                } 
                
                  <div style={{ display: (deviceType && subscriptionType ) ? "block" : "none" }}>
                    <div className={classes.selectedDeviceCSS} onClick={()=> this.setState({deviceType: '', subscriptionType: ''})} >
                      <h5 style={{textTransform: 'uppercase', display: 'flex'}}>
                      <b>Device Type:&nbsp;</b>
                        {deviceType === "physicalDevice" ? 'Device' : null}
                        {deviceType === "virtualDevice" ? 'Virtual Device':null}
                        {deviceType === "physicalEnterpriseDevice" ? 'Enterprise Device' : null}
                        {deviceType === "virtualEnterpriseDevice" ? 'Virtual Enterprise Device':null}
                      </h5>
                    </div>
                    <div className={classes.selectedDeviceCSS} onClick={()=> this.setState({subscriptionType: ''})} >
                      <h5 style={{textTransform: 'uppercase', display: 'flex'}}>
                        <b>Subscription:&nbsp;</b>
                        {subscriptionType === "dev-basic" ? 'Basic Device' : null}
                        {subscriptionType === "dev-standard" ? 'Standard Device':null}
                        {subscriptionType === "dev-pro" ? 'Pro Device' : null}
                        {subscriptionType === "vr-dev-basic" ? 'Virtual Basic Device':null}
                        {subscriptionType === "vr-dev-standard" ? 'Virtual Standard Device' : null}
                        {subscriptionType === "vr-dev-pro" ? 'Virtual Pro Device':null}

                        {subscriptionType === "ent-dev-standard" ? 'Enterprise Standard Device':null}
                        {subscriptionType === "ent-dev-pro" ? 'Enterprise Pro Device':null}
                        {subscriptionType === "ent-vr-dev-standard" ? 'Virtual Enterprise Standard Device':null}
                        {subscriptionType === "ent-vr-dev-pro" ? 'Virtual Enterprise Pro Device':null}
                      </h5>
                    </div>
                    <TextField
                      style={{marginTop: '0px'}}
                      autoFocus
                      margin="normal"
                      id="deviceName"
                      label="Name"
                      type="text"
                      value={deviceName} 
                      onChange={this.handleDeviceNameChange}
                      fullWidth
                      required
                    />

                    <TextField
                      autoFocus
                      margin="normal"
                      id="deviceDescription"
                      label="Description"
                      type="text"
                      value={deviceDescription} 
                      onChange={this.handleDeviceDescriptionChange}
                      fullWidth
                    />

                    {initialDeviceConfigSetupModalOpen? 
                      <InitialDeviceConfigSetupModal open={initialDeviceConfigSetupModalOpen} initalSetupParameters={initalSetupParameters} handleClose={this.handleClose} /> 
                      : null}

                  </div>
                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading || (deviceName == '') || (deviceType == '') || (subscriptionType == '') } onClick={this.submitButtonClick}>
                    {'Create'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

AddDeviceModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AddDeviceModal));



// Old code

// <Dialog
//               open={open}
//               onClose={this.handleClose}
//               TransitionComponent={Transition}
//               keepMounted
//               classes = {{paper: classes.dialog}}
//             >
//               <Paper className={classes.paper} >
//                 <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Add Device</DialogTitle>
//                 <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px'}}>

//                   <div  className={classes.sectionPositions} style={{ display: (!deviceType && !subscriptionType ) ? "block" : "none" }} >
//                     <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '20px'}}>
//                       <b>Select Device Type</b>
//                     </DialogContentText>
//                     <div style={{display:'flex'}} >
//                         <div className={classes.deviceCSS} onClick={()=> this.setState({deviceType: 'physicalDevice'})} >
//                           <h4 style={{marginTop: '10px'}} >Device</h4>
//                         </div>
//                         <div className={classes.deviceCSS} onClick={()=> this.setState({deviceType: 'virtualDevice'})}>
//                           <h4 style={{marginTop: '10px'}}>Virtual Device</h4>
//                         </div>
//                     </div>
//                   </div>

//                   <div  style={{ display: (deviceType && !subscriptionType) ? "block" : "none" }} >
//                     <div className={classes.selectedDeviceCSS} onClick={()=> this.setState({deviceType: ''})} >
//                       <h5 style={{textTransform: 'uppercase', display: 'flex'}}>
//                         <b>Device Type:&nbsp;</b>
//                         {deviceType === "physicalDevice" ? 'Device' : null}
//                         {deviceType === "virtualDevice" ? 'Virtual Device':null}
//                       </h5>
//                     </div>
//                     {deviceType == 'virtualDevice' ? 
//                       // virtual device subscriptions
//                       <div>
//                         <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '20px'}}>
//                           <b>Virtual Device Plans</b>
//                         </DialogContentText>
//                         <div style={{display:'flex'}} >
//                             <div className={classes.subscriptionCard} onClick={()=> this.setState({subscriptionType: 'vr-dev-basic'})} >
//                               <h4 className={classes.headerCSS} style={{marginTop: '10px'}} >Basic</h4>
//                             </div>
//                             <div className={classes.subscriptionCard}  onClick={()=> this.setState({subscriptionType: 'vr-dev-standard'})}>
//                               <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Standard</h4>
//                             </div>
//                             <div className={classes.subscriptionCard}   onClick={()=> this.setState({subscriptionType: 'vr-dev-pro'})}>
//                               <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Pro</h4>
//                             </div>
//                         </div>
//                         {/* <Grid container spacing={1} style={{marginBottom: '16px', textAlign: 'center'}}>
//                           <Grid item xs={12} sm={6} md={3}>
//                             <button className={subscriptionType == 'VD1' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('VD1')} >
//                               <h4 className={classes.headerCSS}>VD1</h4>
//                               <Divider/>
//                               <div className={classes.pricingCSS}>
//                                 $30 + data
//                               </div>
//                               <div className={classes.bodyCSS}>
//                                 per virtual device/month
//                               </div>
//                               <ul className={classes.itemsCSS}>
//                                 <li className={classes.itemCSS}><i>Includes:</i> </li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 100KB daily publishing limit</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 5KB publishing size limit</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 1 remote access per device</li>
//                               </ul>
//                             </button>
//                           </Grid>
//                           <Grid item xs={12} sm={6} md={3}>
//                             <button className={subscriptionType == 'VD2' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('VD2')} >
//                               <h4 className={classes.headerCSS}>VD2</h4>
//                               <Divider/>
//                               <div className={classes.pricingCSS}>
//                                 $70 + data
//                               </div>
//                               <div className={classes.bodyCSS}>
//                                 per virtual device/month
//                               </div>
//                               <ul className={classes.itemsCSS}>
//                               <li className={classes.itemCSS}><i>Includes:</i> </li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 1M daily publishing limit</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 20KB publishing size limit</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 2 remote access per device</li>
//                               </ul>
//                             </button>
//                           </Grid>
//                           <Grid item xs={12} sm={6} md={3}>
//                           <button className={subscriptionType == 'VD3' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('VD3')}>
//                               <h4 className={classes.headerCSS}>VD3</h4>
//                               <Divider/>
//                               <div className={classes.pricingCSS}>
//                                 $120 + data
//                               </div>
//                               <div className={classes.bodyCSS}>
//                                 per virtual device/month
//                               </div>
//                               <ul className={classes.itemsCSS}>
//                               <li className={classes.itemCSS}><i>Includes:</i> </li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 10M daily publishing limit</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 100KB publishing size limit</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Unlimited remote access per device</li>
//                               </ul>
//                             </button>
//                           </Grid> 
//                           <Grid item xs={12} sm={6} md={3}>
//                           <button className={subscriptionType == 'VDE' ? classes.cardCSSSelected : classes.cardCSS} >
//                               <h4 className={classes.headerCSS}>Enterprise</h4>
//                               <Divider/>
//                               <div className={classes.pricingCSS}>
//                                 Enterprise
//                               </div>
//                               <div className={classes.bodyCSS}>
//                                 sales@iotflows.com
//                               </div>
//                               <ul className={classes.itemsCSS}>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Peace of mind with enterprise-grade security and compliance</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Tailored support with a designated account and customer success team</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Advanced identity management through SAML-based SSO.</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 99.9% guaranteed uptime SLA</li>
//                               </ul>
//                             </button>
//                           </Grid> 
//                         </Grid> */}
//                       </div> 
//                       :
//                       // Physical device subscriptions
//                       <div>
//                         <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '20px'}}>
//                           <b>Device Plans</b>
//                         </DialogContentText>
//                         <div style={{display:'flex'}} >
//                             <div className={classes.subscriptionCard} onClick={()=> this.setState({subscriptionType: 'dev-basic'})} >
//                               <h4 className={classes.headerCSS} style={{marginTop: '10px'}} >Basic</h4>
//                             </div>
//                             <div className={classes.subscriptionCard}  onClick={()=> this.setState({subscriptionType: 'dev-standard'})}>
//                               <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Standard</h4>
//                             </div>
//                             <div className={classes.subscriptionCard}   onClick={()=> this.setState({subscriptionType: 'dev-pro'})}>
//                               <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Pro</h4>
//                             </div>
//                         </div>
//                         {/* <Grid container spacing={1} style={{marginBottom: '16px', textAlign: 'center'}}>
//                           <Grid item xs={12} sm={6} md={3}>
//                             <button className={subscriptionType == 'basic' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('basic')} >
//                               <h4 className={classes.headerCSS}>Basic</h4>
//                               <Divider/>
//                               <div className={classes.pricingCSS}>
//                                 $10 + data
//                               </div>
//                               <div className={classes.bodyCSS}>
//                                 per device/month
//                               </div>
//                               <ul className={classes.itemsCSS}>
//                                 <li className={classes.itemCSS}><i>Includes:</i> </li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 100KB daily publishing limit</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 5KB publishing size limit</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 1 remote access per device</li>
//                               </ul>
//                             </button>
//                           </Grid>
//                           <Grid item xs={12} sm={6} md={3}>
//                             <button className={subscriptionType == 'standard' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('standard')} >
//                               <h4 className={classes.headerCSS}>Standard</h4>
//                               <Divider/>
//                               <div className={classes.pricingCSS}>
//                                 $50 + data
//                               </div>
//                               <div className={classes.bodyCSS}>
//                                 per device/month
//                               </div>
//                               <ul className={classes.itemsCSS}>
//                               <li className={classes.itemCSS}><i>Includes:</i> </li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 1M daily publishing limit</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 20KB publishing size limit</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 2 remote access per device</li>
//                               </ul>
//                             </button>
//                           </Grid>
//                           <Grid item xs={12} sm={6} md={3}>
//                           <button className={subscriptionType == 'pro' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('pro')}>
//                               <h4 className={classes.headerCSS}>Pro</h4>
//                               <Divider/>
//                               <div className={classes.pricingCSS}>
//                                 $100 + data
//                               </div>
//                               <div className={classes.bodyCSS}>
//                                 per device/month
//                               </div>
//                               <ul className={classes.itemsCSS}>
//                               <li className={classes.itemCSS}><i>Includes:</i> </li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 10M daily publishing limit</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 100KB publishing size limit</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Unlimited remote access per device</li>
//                               </ul>
//                             </button>
//                           </Grid> 
//                           <Grid item xs={12} sm={6} md={3}>
//                           <button className={subscriptionType == 'deviceEnterprise' ? classes.cardCSSSelected : classes.cardCSS} >
//                               <h4 className={classes.headerCSS}>Enterprise</h4>
//                               <Divider/>
//                               <div className={classes.pricingCSS}>
//                                 Enterprise
//                               </div>
//                               <div className={classes.bodyCSS}>
//                                 sales@iotflows.com
//                               </div>
//                               <ul className={classes.itemsCSS}>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Peace of mind with enterprise-grade security and compliance</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Tailored support with a designated account and customer success team</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Advanced identity management through SAML-based SSO.</li>
//                                 <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 99.9% guaranteed uptime SLA</li>
//                               </ul>
//                             </button>
//                           </Grid> 
//                         </Grid> */}
//                       </div> 

//                     }
//                   </div>

                 
                
//                   <div style={{ display: (deviceType && subscriptionType ) ? "block" : "none" }}>
//                     <div className={classes.selectedDeviceCSS} onClick={()=> this.setState({deviceType: '', subscriptionType: ''})} >
//                       <h5 style={{textTransform: 'uppercase', display: 'flex'}}>
//                       <b>Device Type:&nbsp;</b>
//                         {deviceType === "physicalDevice" ? 'Device' : null}
//                         {deviceType === "virtualDevice" ? 'Virtual Device':null}
//                       </h5>
//                     </div>
//                     <div className={classes.selectedDeviceCSS} onClick={()=> this.setState({subscriptionType: ''})} >
//                       <h5 style={{textTransform: 'uppercase', display: 'flex'}}>
//                         <b>Subscription:&nbsp;</b>
//                         {subscriptionType === "dev-basic" ? 'Basic Device' : null}
//                         {subscriptionType === "dev-standard" ? 'Standard Device':null}
//                         {subscriptionType === "dev-pro" ? 'Pro Device' : null}
//                         {subscriptionType === "vr-dev-basic" ? 'Virtual Basic Device':null}
//                         {subscriptionType === "vr-dev-standard" ? 'Virtual Standard Device' : null}
//                         {subscriptionType === "vr-dev-pro" ? 'Virtual Pro Device':null}
//                       </h5>
//                     </div>
//                     <TextField
//                       style={{marginTop: '0px'}}
//                       autoFocus
//                       margin="normal"
//                       id="deviceName"
//                       label="Name"
//                       type="text"
//                       value={deviceName} 
//                       onChange={this.handleDeviceNameChange}
//                       fullWidth
//                       required
//                     />

//                     <TextField
//                       autoFocus
//                       margin="normal"
//                       id="deviceDescription"
//                       label="Description"
//                       type="text"
//                       value={deviceDescription} 
//                       onChange={this.handleDeviceDescriptionChange}
//                       fullWidth
//                     />

//                     {initialDeviceConfigSetupModalOpen? 
//                       <InitialDeviceConfigSetupModal open={initialDeviceConfigSetupModalOpen} initalSetupParameters={initalSetupParameters} handleClose={this.handleClose} /> 
//                       : null}

//                   </div>
                  

//                   {/* {!selectedOrganization.organization_is_personal  ?  
//                   <div>
//                     <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '24px'}}>
//                       <b>Edge Device Plans</b>
//                     </DialogContentText>
//                     <Grid container spacing={1} style={{marginBottom: '16px', textAlign: 'center'}}>
//                       <Grid item xs={12} sm={6} md={3}>
//                         <button className={subscriptionType == 'IOT_GATEWAY_PAY_AS_YOU_GO' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_PAY_AS_YOU_GO')} >
//                           <h4 className={classes.headerCSS}>Pay-as-you-go</h4>
//                           <Divider/>
//                           <div className={classes.pricingCSS}>
//                             $0
//                           </div>
//                           <div className={classes.bodyCSS}>
//                             per month per device
//                           </div>
//                           <ul className={classes.itemsCSS}>
//                             <div style={{height: '86px'}}>
//                               <li className={classes.itemCSS}><b>Real-Time IoT Interactions: $1 per 1000 interactions</b></li>
//                             </div>
//                             <div style={{height: '86px'}}>
//                               <li className={classes.itemCSS}><b>Remote Access: $29 per month</b></li>
//                                 <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Node-RED or SHH connections</li>
//                             </div>
//                             <br/>
//                             <li className={classes.itemCSS}><i>Top Features:</i> </li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Secure Authentication</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Publish / Subscription Permission Control</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> SSL SHA-256 Encrypted Secure Communication</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> HTTPS / MQTTS / Secure WebSockets</li>
//                           </ul>
//                         </button>
//                       </Grid>
//                       <Grid item xs={12} sm={6} md={3}>
//                         <button className={subscriptionType == 'IOT_GATEWAY_E1' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_E1')} >
//                           <h4 className={classes.headerCSS}>E1</h4>
//                           <Divider/>
//                           <div className={classes.pricingCSS}>
//                             $79
//                           </div>
//                           <div className={classes.bodyCSS}>
//                             per month per device
//                           </div>
//                           <ul className={classes.itemsCSS}>
//                             <div style={{height: '86px'}}>
//                               <li className={classes.itemCSS}><b>Real-Time IoT Interactions: 200k</b> </li>
//                                 <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Additional $1 per 1000 interactions</li>
//                             </div>
//                             <div style={{height: '86px'}}>
//                               <li className={classes.itemCSS}><b>Remote Access: 1</b> </li>
//                                 <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Node-RED or SHH connections</li>
//                             </div>
//                               <br/>
//                             <li className={classes.itemCSS}><i>Top Features:</i> </li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Secure Authentication</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Publish / Subscription Permission Control</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> SSL SHA-256 Encrypted Secure Communication</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> HTTPS / MQTTS / Secure WebSockets</li>
//                           </ul>
//                         </button>
//                       </Grid>
//                       <Grid item xs={12} sm={6} md={3}>
//                       <button className={subscriptionType == 'IOT_GATEWAY_E2' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_E2')}>
//                           <h4 className={classes.headerCSS}>E2</h4>
//                           <Divider/>
//                           <div className={classes.pricingCSS}>
//                             $499
//                           </div>
//                           <div className={classes.bodyCSS}>
//                             per month per device
//                           </div>
//                           <ul className={classes.itemsCSS}>
//                             <div style={{height: '86px'}}>
//                               <li className={classes.itemCSS}><b>Real-Time IoT Interactions: 2.5 million</b> </li>
//                                 <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Additional $1 per 1000 interactions</li>
//                             </div>
//                             <div style={{height: '86px'}}>
//                               <li className={classes.itemCSS}><b>Remote Access: 2</b> </li>
//                                 <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Node-RED &amp; SHH connections</li>
//                             </div>
//                             <br/>
//                             <li className={classes.itemCSS}> <i>Top Features:</i> </li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Secure Authentication</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Publish / Subscription Permission Control</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> SSL SHA-256 Encrypted Secure Communication</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> HTTPS / MQTTS / Secure WebSockets</li>
//                           </ul>
//                         </button>
//                       </Grid> 
//                       <Grid item xs={12} sm={6} md={3}>
//                       <button className={subscriptionType == 'IOT_GATEWAY_E3' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_E3')}>
//                           <h4 className={classes.headerCSS}>E3</h4>
//                           <Divider/>
//                           <div className={classes.pricingCSS}>
//                             $999
//                           </div>
//                           <div className={classes.bodyCSS}>
//                             per month per device
//                           </div>
//                           <ul className={classes.itemsCSS}>
//                             <div style={{height: '86px'}}>
//                               <li className={classes.itemCSS}><b>Real-Time IoT Interactions: 10 million</b> </li>
//                                 <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Additional $1 per 1000 interactions</li>
//                             </div>
//                             <div style={{height: '86px'}}>
//                               <li className={classes.itemCSS}><b>Remote Access: 4</b> </li>
//                                 <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Node-RED, SHH &amp; two custom connections</li>
//                             </div>
//                               <br/>
//                             <li className={classes.itemCSS}> <i>Top Features:</i> </li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Secure Authentication</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Publish / Subscription Permission Control</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> SSL SHA-256 Encrypted Secure Communication</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> HTTPS / MQTTS / Secure WebSockets</li>
//                           </ul>
//                         </button>
//                       </Grid> 
//                     </Grid>
//                   </div> 
//                   :null} */}

//                   {/* {selectedOrganization.organization_is_personal  ?   
//                   <div>
//                     <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '24px'}}>
//                       <b>Edge Device Plans</b>
//                     </DialogContentText>
//                     <Grid container spacing={1} style={{marginBottom: '16px', textAlign: 'center', justifyContent: 'center'}}>
//                       <Grid item xs={12} sm={6} >
//                         <button className={subscriptionType == 'IOT_GATEWAY_FREE' ? classes.cardCSSSelected : classes.cardCSS} style={{maxWidth: '400px'}} onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_FREE')} >
//                           <h4 className={classes.headerCSS}>Personal</h4>
//                           <Divider/>
//                           <div className={classes.pricingCSS}>
//                             Free
//                           </div>
//                           <ul className={classes.itemsFreeCSS}>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Remote Access: Limited to 10 min/day</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Real-Time IoT Interactions: Limited to 2000/day</li>
//                           </ul>
//                         </button>
//                       </Grid>
//                     </Grid>
//                   </div>
//                   : null} */}

//                 {/* {selectedOrganization.subscription_plan_is_enterprise ? 
//                 <div>
//                   <DialogContentText style={{textAlign: 'center', marginTop: '12px'}}>
//                     <i>Enterprise Plan</i>
//                   </DialogContentText>
//                   <Grid container spacing={1} style={{marginBottom: '16px', textAlign: 'center'}}>
//                     <Grid item xs={12} sm={6}>
//                       <button className={subscriptionType == 'IOT_GATEWAY_ENTERPRISE_STANDARD' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_ENTERPRISE_STANDARD')} >
//                         <h4 className={classes.headerCSS}>Standard</h4>
//                         <Divider/>
//                         <div className={classes.pricingCSS}>
//                           $499
//                         </div>
//                         <div className={classes.bodyCSS}>
//                           per month per device
//                         </div>
//                         <ul className={classes.itemsCSS}>
//                           <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Remote Access: 2</li>
//                           <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Real-Time IoT Interactions: 2 Million</li>
//                         </ul>
//                       </button>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                     <button className={subscriptionType == 'IOT_GATEWAY_ENTERPRISE_PRO' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_ENTERPRISE_PRO')}>
//                         <h4 className={classes.headerCSS}>Pro</h4>
//                         <Divider/>
//                         <div className={classes.pricingCSS}>
//                           $1499
//                         </div>
//                         <div className={classes.bodyCSS}>
//                           per month per device
//                         </div>
//                         <ul className={classes.itemsCSS}>
//                           <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Remote Access: Unlimited</li>
//                           <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Real-Time IoT Interactions: Unlimited</li>
//                         </ul>
//                       </button>
//                     </Grid> 
//                   </Grid>
//                 </div>
//                  : null} 

//                 {!selectedOrganization.subscription_plan_is_enterprise && !selectedOrganization.organization_is_personal  ?   
//                   <div>
//                     <DialogContentText style={{textAlign: 'center', marginTop: '12px'}}>
//                       <i>Plan</i>
//                     </DialogContentText>
//                     <Grid container spacing={1} style={{marginBottom: '16px', textAlign: 'center'}}>
//                       <Grid item xs={12} sm={6}>
//                         <button className={subscriptionType == 'IOT_GATEWAY_STANDARD' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_STANDARD')} >
//                           <h4 className={classes.headerCSS}>Standard</h4>
//                           <Divider/>
//                           <div className={classes.pricingCSS}>
//                             $49
//                           </div>
//                           <div className={classes.bodyCSS}>
//                             per month per device
//                           </div>
//                           <ul className={classes.itemsCSS}>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Remote Access: 1</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Real-Time IoT Interactions: 500,000</li>
//                           </ul>
//                         </button>
//                       </Grid>
//                       <Grid item xs={12} sm={6}>
//                       <button className={subscriptionType == 'IOT_GATEWAY_PRO' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('IOT_GATEWAY_PRO')}>
//                           <h4 className={classes.headerCSS}>Pro</h4>
//                           <Divider/>
//                           <div className={classes.pricingCSS}>
//                             $149
//                           </div>
//                           <div className={classes.bodyCSS}>
//                             per month per device
//                           </div>
//                           <ul className={classes.itemsCSS}>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Remote Access: 2</li>
//                             <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Real-Time IoT Interactions: 2 Million</li>
//                           </ul>
//                         </button>
//                       </Grid> 
//                     </Grid>
//                   </div>
//                 : null} */}

                


//                 </DialogContent>
//                 <DialogActions style={{justifyContent:'flex-end'}}>
//                   <Button onClick={this.handleClose} >
//                     Close
//                   </Button>
//                   <Button  color="primary" type='submit' className={buttonClassname} disabled={loading || (deviceName == '') || (deviceType == '') || (subscriptionType == '') } onClick={this.submitButtonClick}>
//                     {'Create'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
//                   </Button>
//                 </DialogActions>
//               </Paper>
         
//         </Dialog>

import React from "react";
import _ from "lodash";
import { WidthProvider, Responsive } from "react-grid-layout";
import "./resizeStyles.css"
import "./reactGridStyles.css"
import { withStyles } from '@material-ui/core/styles';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Button from '@material-ui/core/Button';
import SelectWidgetModal from './SelectWidgetModal';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTheme } from '@mui/material';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import  { getData, postData, putData } from '../ApiCalls/DataApis';

import TextField from '@material-ui/core/TextField';
import Grid from '@mui/material/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import SmartWidget from '@iotflows/iotflows-smart-widget/dist/components/SmartWidget'

const ResponsiveReactGridLayout = WidthProvider(Responsive);


  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.gray,
      fontWeight: 700,
      fontFamily:'Trebuchet MS',
      fontSize: 11,
      height: '20px',
      padding: '8px',
    },
    body: {
      fontSize: 13,
      fontFamily:'Trebuchet MS',
      height: '24px',
      padding: '8px',
    },
  }))(TableCell);

const styles = theme => ({
    button: {
        backgroundColor:'transparent',
        borderRadius:'25px',
        border:'1px solid #4f86ce',
        display:'inline-block',
        minWidth:'70px',
        maxHeight: '40px',
        cursor:'pointer',
        color:'#4f87cc',
        fontFamily:'Trebuchet MS',
        fontSize:'12px',
        // font-weight:bold;
        padding:'3px 10px',
        textDecoration:'none',
        textShadow:'0px 0px 0px #9eb9ff',
        outline: 'none',
        '&:hover': {
          backgroundColor:'transparent',
        },
        '&:active': {
          outline: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
      },
    buttonSave: {
        backgroundColor:'transparent',
        borderRadius:'25px',
        border:'1px solid #d97706',
        display:'inline-block',
        minWidth:'70px',
        maxHeight: '40px',
        cursor:'pointer',
        color:'#d97706',
        fontFamily:'Trebuchet MS',
        fontSize:'12px',
        // font-weight:bold;
        padding:'3px 10px',
        textDecoration:'none',
        textShadow:'0px 0px 0px #9eb9ff',
        outline: 'none',
        '&:hover': {
          backgroundColor:'transparent',
        },
        '&:active': {
          outline: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
      },
      buttonCancel: {
        backgroundColor:'transparent',
        marginRight:'12px',
        borderRadius:'25px',
        border:'1px solid #6b7280',
        display:'inline-block',
        minWidth:'70px',
        maxHeight: '40px',
        cursor:'pointer',
        color:'#6b7280',
        fontFamily:'Trebuchet MS',
        fontSize:'12px',
        // font-weight:bold;
        padding:'3px 10px',
        textDecoration:'none',
        textShadow:'0px 0px 0px #9eb9ff',
        outline: 'none',
        '&:hover': {
          backgroundColor:'transparent',
        },
        '&:active': {
          outline: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
      },
    cardRoot: {
        borderRadius: '10px',
        margin: '5px',
      },
    mapContainer: {
        width:'100%',
        height:'400px',
        // [theme.breakpoints.down('md')]: {
        //   height: '300px',
        //   width:'100%'
        // },
        // [theme.breakpoints.down('xs')]: {
        //   height: '300px',
        //   width:'100%'
        // },
    },
    tableContainer: {
        borderRadius: '10px',
      },
      table: {
        minWidth: 450,
      },
      buttonProgress: {
        color: '#d97706',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
})


/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */
class EditDashboardPage extends React.PureComponent {
    static defaultProps = {
        className: "layout",
        cols:{lg: 60, md: 50, sm: 30, xs: 20, xxs: 10},
        rowHeight: 10,
      };
    
      constructor(props) {
        super(props);
        this.state = {
            dashboardName: this.props.selectedAssetDashboard.machine_dashboard_name,
            dashboardDescription:this.props.selectedAssetDashboard.machine_dashboard_description,
            loading:false,
            isEditing: true,
            isDraggable:true,
            isResizable:true,
            widgetDic: {},
            renderGrid: false,
            items: this.props.selectedAssetDashboard.machine_dashboard_settings,
            // items: [0].map(function(i, key, list) {
            // return {
            //     i: i.toString(),
            //     x: i * 2,
            //     y: 0,
            //     w: 2,
            //     h: 2,
            //     widget_uuid: null,
            //     // static: i === (list.length - 1),
            // //   add: i === (list.length - 1)
            // };
            // }),
            newCounter: Object.keys(this.props.selectedAssetDashboard.machine_dashboard_settings).length + 1,
        };
    
        this.onAddItem = this.onAddItem.bind(this);
        this.onBreakpointChange = this.onBreakpointChange.bind(this);
      }

      componentDidMount() {

        getData('https://api.iotflows.com/v1/machine_dashboards/' + this.props.selectedAssetDashboard.machine_dashboard_uuid + '/widgets')
          .then(async response => {
            if(response && response.ok ){
              try{
                const data = await response.json();
                if(data && data.data ){

                  let dic = this.state.widgetDic
                  data.data.map(widget => {
                    dic[widget.widget_uuid] = widget
                  })
                  this.setState({
                      widgetDic: dic,
                  }, () => this.setState({renderGrid: true}))
                }
              }catch(e){
                console.log(e)
              }
                
            }
          });
        
      }

      createWidgetDicAddWidgetToCard = (widget_uuid, updatedItem) => {
        // Does asset have a selected dashboard
        getData('https://api.iotflows.com/v1/widgets/' + widget_uuid )
        .then(async response => {
          if(response && response.ok ){
            try{
              const data = await response.json();
              if(data && data.data && data.data[0]){
                let dic = this.state.widgetDic
                if(data.data[0].widget_uuid){
                  dic[data.data[0].widget_uuid] = data.data[0]
                  this.setState({widgetDic: dic})


                  // ***Adding Widget to card
                  this.setState({
                    renderGrid: false
                  }, ()=>{
                    let currentItems = this.state.layout
                    this.state.items.map((item, index) =>{
                      currentItems[index].x = this.state.layout[index].x
                      currentItems[index].y = this.state.layout[index].y
                      currentItems[index].h = this.state.layout[index].h
                      currentItems[index].w = this.state.layout[index].w
                      currentItems[index].widget_uuid = item.widget_uuid
                      if(item.i == updatedItem.i){
                        // currentItems[index]= updatedItem;
                        currentItems[index].widget_uuid = updatedItem.widget_uuid 
                        currentItems[index].i = updatedItem.widget_uuid + Date.now();
                      }
                    })
                    this.setState({
                      items: currentItems
                    }, ()=> {
                      this.setState({renderGrid:true})
                    })
                  })
                  // ***Adding Widget to card

                }

              }
            }catch(e){
              console.log(e)
            }  
          }
        });
      }

    
      createElement(el) {
        const removeStyle = {
          position: "absolute",
          right: "2px",
          top: 0,
          cursor: "pointer"
        };
        const noDisplay = {
          display: "none",
        };
        const i = el.add ? "+" : el.i;
        return (
          <div key={i} data-grid={el} 
            style={{
              backgroundColor:'rgba(0,0,0,0.1)', 
              borderRadius:'10px',
              boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1), 0px -4px 15px -2px rgba(0,0,0,0.1)',
            }}
          >
            {/* {el.add ? (
              <span
                className="add text"
                onClick={this.onAddItem}
                title="You can add an item by clicking here, too."
              >
                Add +
              </span>
            ) : (
              <span className="text">{i}</span>
            )} */}
            {this.props.machine_uuid && el.widget_uuid && this.state.widgetDic && this.state.widgetDic[el.widget_uuid]  ? 
                // <div style={{width:'100%', height:'100%', objectFit:'contain'}}>
                //   <img src={this.state.widgetDic[el.widget_uuid].widget_template_image_url} style={{width:'100%', height:'100%'}}></img>
                // </div>
                <div style={{color:'#374151', width:'100%', height:'100%',justifyContent:'center', display:'flex', flexDirection:'column', fontFamily:'Trebuchet MS',}}>
                  <h5>{this.state.widgetDic[el.widget_uuid].widget_template_name}</h5>
                  <h6>{this.state.widgetDic[el.widget_uuid].widget_template_description}</h6>
                </div>
               :
              <div style={{height:'100%'}}>
                <SelectWidgetModal createWidgetDicAddWidgetToCard={this.createWidgetDicAddWidgetToCard} el={el} machine_uuid={this.props.machine_uuid}/>
              </div>
              }
            <span
                className="remove"
                style={this.state.isEditing? removeStyle: noDisplay}
                onClick={this.onRemoveItem.bind(this, i)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </span>
          </div>
        );
      }
    
      onAddItem() {
        /*eslint no-console: 0*/
        // console.log("adding", "n" + this.state.newCounter);
        this.setState({
          // Add a new item. It must have a unique key!
          items: this.state.items.concat({
            i: "n" + this.state.newCounter,
            x: (this.state.items.length * 2) % (this.state.cols || 12),
            y: Infinity, // puts it at the bottom
            w: 10,
            h: 10,
          }),
          // Increment the counter to ensure key is always unique.
          newCounter: this.state.newCounter + 1
        });
      }
    
      // We're using the cols coming back from this to calculate where to add new items.
      onBreakpointChange(breakpoint, cols) {
        this.setState({
          breakpoint: breakpoint,
          cols: cols
        });
      }
    
      onLayoutChange = (layout) => {
        // console.log('layout')
        // console.log(layout)
        // this.props.onLayoutChange(layout);
        this.setState({ layout: layout });
      }
    
      onRemoveItem(i) {
        // console.log("removing", i);
        this.setState({ items: _.reject(this.state.items, { i: i }) });
      }

      

      handleDashboardNameChange = (e) => {
        this.setState({dashboardName: e.target.value})
    
      }
      handleDashboardDescriptionChange = (e) => {
        this.setState({dashboardDescription: e.target.value})
    
      }
      cancelEditDashboard = () => {
        this.setState({
          loading:false,
        })
        this.props.handleCloseCreateMachineDashboardPage()
      }

      submitEditDashboard = () => {
       
        const { loading, items, layout } = this.state;

        var isCardEmpty = false;
        for(let i=0; i < Object.keys(items).length; i++){
          if(!items[i].widget_uuid){
            this.props.showErrorSnackbar("All cards must be assiged a Widget");
            isCardEmpty = true;
          }
        }
        
        if (!loading && !isCardEmpty) {
          this.setState(
            {
              loading: true,
            },
            () => {
              let currentLayout = layout;
              for(let i=0; i < Object.keys(items).length; i++){
                if(items[i].widget_uuid){
                  currentLayout[i].widget_uuid = items[i].widget_uuid
                }
              }
              for(let i=0; i < Object.keys(currentLayout).length; i++){
                currentLayout[i].widget_template_uuid = this.state.widgetDic[currentLayout[i].widget_uuid].widget_template_uuid
              }
              
            putData('https://api.iotflows.com/v1/machine_dashboards/' + this.props.selectedAssetDashboard.machine_dashboard_uuid , 
            { "machine_dashboard_name": this.state.dashboardName,
              "machine_dashboard_description": this.state.dashboardDescription,
              "machine_dashboard_settings": currentLayout,
            })
              .then(async response => {
                if(response.ok){
                  const data = await response.json();
                  this.props.handleEditedDashboard(this.props.selectedAssetDashboard.machine_dashboard_uuid)
                  this.props.showSuccessSnackbar(`${this.state.dashboardName} dashboard has been edited!`);
                  this.setState({
                    loading: false,
                    success: true,
                    open:false,
                  });
                }
                else{
                  try{
                    const data = await response.json();
                    this.props.showErrorSnackbar(data.message);
                    this.setState({
                      loading: false,
                      success: false,
                      open:false});
                  }catch (e){
                    this.props.showErrorSnackbar('Something went wrong');
                  }
                }
              })
            }
          )
        }
      }
    
      render() {
        const { classes } = this.props;
        const { loading,
                isDraggable,
                isResizable,
                isEditing,
                dashboardName,
                dashboardDescription,
                renderGrid } = this.state;


        return (
          <div style={{marginBottom:'100px'}}>
            <Grid container spacing={3} style={{padding:'0px 12px'}}>
              <Grid item xs={12} sm={6}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="dashboardName"
                    label="Dashboard Name"
                    type="text"
                    variant="standard" 
                    value={dashboardName} 
                    onChange={this.handleDashboardNameChange}
                    fullWidth
                    required
                  />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="standard" 
                  margin="normal"
                  id="dashboardDescription"
                  label="Dashboard Description"
                  type="text"
                  value={dashboardDescription} 
                  onChange={this.handleDashboardDescriptionChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <div style={{padding:'12px', display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
              <div style={{margin:'0px 8px'}} >
                  <Button className={classes.button} variant="contained" onClick={this.onAddItem}><AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Add Card</Button>
              </div>
              <div style={{marginLeft:'auto'}} >
                  <Button className={classes.buttonCancel}  variant="contained" onClick={this.cancelEditDashboard}>
                      {'Cancel '}
                  </Button>
                  <Button className={classes.buttonSave}  disabled={loading} variant="contained" onClick={this.submitEditDashboard}>
                      {'Deploy'}{ loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
              </div>
            </div>
            
            {renderGrid &&
              <ResponsiveReactGridLayout
                  onLayoutChange={this.onLayoutChange}
                  onBreakpointChange={this.onBreakpointChange}
                  useCSSTransforms={true}
                  // allowOverlap={true}
                  isDraggable={isDraggable}
                  isResizable={isResizable}
                  {...this.props}
              >
                {_.map(this.state.items, el => this.createElement(el))}
              </ResponsiveReactGridLayout>
            }

            
          </div>
        );
      }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(EditDashboardPage));

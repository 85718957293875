import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Row from 'react-bootstrap/Row';
import Typography from '@material-ui/core/Typography';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteCreditCardModal from '../components/DeleteCreditCardModal';
import EditInvoiceEmailModal from '../components/EditInvoiceEmailModal';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Col from 'react-bootstrap/Col';
import PaymentImagesJSON from './PaymentImagesJSON';
import Button from '@material-ui/core/Button';
import {getData, postData} from '../ApiCalls/DataApis';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import Divider from '@material-ui/core/Divider';
import PaymentModal from './PaymentModal';
import PaymentRequiredModal from '../components/PaymentRequiredModal';
import SuspendedOrganizationModal from '../components/SuspendedOrganizationModal';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    margin: '6px'
  },
  defaultCSS: {
    borderRadius: '5px',
    backgroundColor: 'rgb(214, 236, 255)',
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'5px',
    border:'1px solid #757575',
    display:'inline-block',
    cursor:'pointer',
    color:'#757575',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'5px 14px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  headersCSS: {
    fontSize: '20px',
    fontFamily:'Trebuchet MS',
  }
});


class WalletPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      user_cards: [],
      default_payment: '',
      invoice_email: '',
      paymentRequiredModalOpen: false,
      suspendedOrganizationModalOpen: false,
    }
    this.refreshCreditCardList = this.refreshCreditCardList.bind(this);
    this.changeDefaultCard = this.changeDefaultCard.bind(this);
    this.refreshDefaultCardList = this.refreshDefaultCardList.bind(this);
  }

  componentDidMount() {

    if(this.props.selectedOrganization.organization_is_suspended){
      
      this.setState({suspendedOrganizationModalOpen: true} );
    } else {
      this.setState({suspendedOrganizationModalOpen: false} );
    }
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/payment_methods/card')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          // UNINOVA card removed 
          if(this.props.selectedOrganization.organization_uuid == 'd80683e84c3ba448930779fbe4733bd5' ){
            var index =  -1;
            for(var i=0; i<Object.keys(data.data).length; i++){
              if(data.data[i].id == "card_1HS5KCKAq3NkrrXWe6JUSBF1"){
                index = i;
                break;
              }
            }
            if(index != -1){
              data.data.splice(index,1);
            }
          }
          this.setState({
            user_cards: data.data,
          },() => {
            if (this.state.user_cards == '' && !this.props.selectedOrganization.organization_is_personal && (this.props.selectedOrganization.organization_uuid != 'd80683e84c3ba448930779fbe4733bd5')) {
              this.setState({paymentRequiredModalOpen: true} );
            } else {
              this.setState({paymentRequiredModalOpen: false} );
            }
          });
        }
      });

    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/customer')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            default_payment: data.invoice_settings.default_payment_method,
            invoice_email: data.email,
          });
        }
      });

  }

  componentDidUpdate(prevProps, prevState) {

    if(prevProps.selectedOrganization.organization_uuid != this.props.selectedOrganization.organization_uuid){
      if(prevState.paymentRequiredModalOpen === true){
        this.setState({paymentRequiredModalOpen: false})
      }
      if(prevState.suspendedOrganizationModalOpen === true){
        this.setState({suspendedOrganizationModalOpen: false})
      }

      // After a different organization is selected, ensuring account is not suspended and has valid payment
      if(this.props.selectedOrganization.organization_is_suspended){
        
        this.setState({suspendedOrganizationModalOpen: true} );
      } else {
        this.setState({suspendedOrganizationModalOpen: false} );
      }
      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/payment_methods/card')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          // UNINOVA card removed 
          if(this.props.selectedOrganization.organization_uuid == 'd80683e84c3ba448930779fbe4733bd5' ){
            var index =  -1;
            for(var i=0; i<Object.keys(data.data).length; i++){
              if(data.data[i].id == "card_1HS5KCKAq3NkrrXWe6JUSBF1"){
                index = i;
                break;
              }
            }
            if(index != -1){
              data.data.splice(index,1);
            }
          }
          this.setState({
            user_cards: data.data,
          },() => {
            if (this.state.user_cards == '' && !this.props.selectedOrganization.organization_is_personal && (this.props.selectedOrganization.organization_uuid != 'd80683e84c3ba448930779fbe4733bd5')) {
              this.setState({paymentRequiredModalOpen: true} );
            } else {
              this.setState({paymentRequiredModalOpen: false} );
            }
          });
        }
      });
      
      // refreshing payments and email after changing organization
      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/customer')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            default_payment: data.invoice_settings.default_payment_method,
            invoice_email: data.email,
          });
        }
      });
    }
    
  }

  refreshCreditCardList(){
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/payment_methods/card')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          // UNINOVA card removed 
          if(this.props.selectedOrganization.organization_uuid == 'd80683e84c3ba448930779fbe4733bd5' ){
            var index =  -1;
            for(var i=0; i<Object.keys(data.data).length; i++){
              if(data.data[i].id == "card_1HS5KCKAq3NkrrXWe6JUSBF1"){
                index = i;
                break;
              }
            }
            if(index != -1){
              data.data.splice(index,1);
            }
          }
          this.setState({
            user_cards: data.data,
          },() => {
            if (this.state.user_cards == '' && !this.props.selectedOrganization.organization_is_personal && (this.props.selectedOrganization.organization_uuid != 'd80683e84c3ba448930779fbe4733bd5')) {
              this.setState({paymentRequiredModalOpen: true} );
            } else {
              this.setState({paymentRequiredModalOpen: false} );
            }
          });
        }
      });
  }

  refreshDefaultCardList(){
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/customer')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            default_payment: data.invoice_settings.default_payment_method,
            invoice_email: data.email,
          });
        }
      });
  }

  changeDefaultCard = (payment_method_id) => {
    postData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/payment_methods/card/default', 
    {'payment_method': payment_method_id})
      .then(async response => {
        if(response.ok){
          this.refreshDefaultCardList();
          this.props.showSuccessSnackbar("Card has been set to default");
        }
        else{
          try{
            const data = await response.json();
            this.props.showErrorSnackbar(data.message);
            this.setState({device_added: false})
          }catch (e){
            this.props.showErrorSnackbar('Something went wrong');
          }
        }
      })
  }


  

  render(){
    const { classes} = this.props
    const {user_cards, 
          default_payment, 
          invoice_email, 
          paymentRequiredModalOpen,
          suspendedOrganizationModalOpen} = this.state
       

    return (
      <div className={classes.root} style={{marginBottom: '60px'}}>
          {/* Customer Billing information */}
          <Grid container spacing={1} style={{alignItems:'center', textAlign: 'left', marginLeft: '10px', }}>
            <Grid item xs={8} style={{textAlign: 'right'}} >
              <Row style={{marginLeft: '6px', alignItems: 'center'}}>
                <h6 style={{color:'rgb(105, 115, 134)', margin: '0px'}}>
                  <PersonOutlineOutlinedIcon/> Customer Billing
                </h6>
              </Row>
              <Row style={{marginLeft: '10px', alignItems: 'center'}}>
                <h5 style={{paddingTop: '8px',paddingBottom: '8px', margin: '0px' }}><b>{invoice_email}</b></h5>
                <div style={{marginLeft:'8px', padding: '0px', alignItems: 'baseline'}}>
                  <EditInvoiceEmailModal invoice_email={invoice_email}  refreshDefaultCardList={this.refreshDefaultCardList} />
                </div>
              </Row>
            
            </Grid>
            <Grid item xs={4} style={{alignItems:'center', justifyContent: 'center', alignContent: 'center'}} >
              <PaymentModal refreshCreditCardList={this.refreshCreditCardList} />
            </Grid>
          </Grid>
          <Divider style={{marginBottom: '15px', marginTop:'10px'}}/>

        {/* Customer Credit Cards list */}
        <div style={{textAlign: 'left', margin: '5px', marginLeft: '10px'}} >
          <span className={classes.headersCSS} >
            <span><b>Your Payment Methods</b> </span>
          </span>
        </div>
        <Paper style={{overflow: 'hidden', padding: '0px',backgroundColor: '#ffffff', marginRight: 'auto',marginLeft: 'auto', }} className="cardScrollbar" >
          <List style={{padding: '0px',backgroundColor: '#ffffff'}} >
            
            {user_cards.map((user_card, index) => (
              <ExpansionPanel key={index} style={{backgroundColor: '#ffffff', margin: '0px'}} >
                <ExpansionPanelSummary
                  style={{margin: '0px'}}
                  classes={{ content: classes.content}}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <ListItem dense  key={index} >
                    {PaymentImagesJSON.map((card_image, index) =>
                      card_image.title == user_card.card.brand ? <img key={index} style={{width: '45px'}} src={card_image.src} alt={user_card.card.brand}/> : null
                    )}
                  
                    <ListItemText style={{paddingLeft: '8px'}} > 
                      <Col>
                        <Row style={{fontSize: '14px'}}>
                          {`Card ending in ${user_card.card.last4}`}&nbsp;&nbsp;
                          {user_card.id == default_payment ? 
                            <div className={classes.defaultCSS}>
                              <span style={{padding: '5px'}} >Default</span>
                            </div>
                          : null
                          }
                        </Row>
                        <Row style={{fontSize: '12px', color:'rgb(105, 115, 134)'}}>
                          {`Expires ${user_card.card.exp_month}/${user_card.card.exp_year}`}
                        </Row>
                      </Col>
                    </ListItemText>
                    
                    <div edge="end" style={{margin:'0px', padding: '0px', }}>
                      <DeleteCreditCardModal payment_method_id = {user_card.id} refreshCreditCardList={this.refreshCreditCardList} />
                    </div>
                  </ListItem>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{paddingLeft: '40px'}} >
                  <Col >
                      {/* <Row>
                        <Typography  variant="subtitle2" style={{color:'rgb(105, 115, 134)'}}>
                          Name on Card
                        </Typography>
                      </Row>
                      <Row>
                        <Typography variant="subtitle2" >
                          {user_card.billing_details.name}
                        </Typography>
                      </Row>
                      <Row>
                        <Typography variant="subtitle2" style={{color:'rgb(105, 115, 134)'}}>
                          Email
                        </Typography>
                      </Row>
                      <Row>
                        <Typography variant="subtitle2" >
                        {user_card.billing_details.email}
                        </Typography>
                      </Row>
                      <Row >
                        <Typography variant="subtitle2" style={{color:'rgb(105, 115, 134)', }}>
                          Phone Number
                        </Typography>
                      </Row>
                      <Row style={{justifyContent: 'flex-start', alignContent: 'flex-start', alignItems: 'flex-start'}}>                          
                        <Typography  variant="subtitle2">
                          {user_card.billing_details.phone}
                        </Typography>
                      </Row> */}
                  </Col>
                  <Col >
                      {/* <Row >
                        <Typography  variant="subtitle2" style={{color:'rgb(105, 115, 134)'}}>
                          Billing Address
                        </Typography>
                      </Row>
                      <Row >
                        <Typography variant="subtitle2" >
                          {user_card.billing_details.address.line1}
                        </Typography>
                      </Row>
                      <Row >
                        <Typography variant="subtitle2" >
                        {user_card.billing_details.address.line2}
                        </Typography>
                      </Row>
                      <Row >                          
                        <Typography  variant="subtitle2">
                          {user_card.billing_details.address.city},{user_card.billing_details.address.state}, {user_card.billing_details.address.postal_code}
                        </Typography>
                      </Row>
                      <Row >
                        <Typography variant="subtitle2" >
                        {user_card.billing_details.address.country}
                        </Typography>
                      </Row> */}
                      <Row  style={{justifyContent: 'flex-end'}}>
                        <Button className={classes.button} variant="outlined" onClick={() => this.changeDefaultCard(user_card.id) }  >
                          Set as default
                        </Button>
                      </Row>
                  </Col>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </List>
        </Paper>
        {/* Popup modal if there are no forms of payment on file */}
        <PaymentRequiredModal open={paymentRequiredModalOpen} />
        {/* Popup modal if there are no forms of payment on file */}
        <SuspendedOrganizationModal open={suspendedOrganizationModalOpen} />

      </div>
        
       
    )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(WalletPage));


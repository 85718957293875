import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import  {putData } from '../ApiCalls/DataApis';
import {fetchAlertChannels} from '../store/actions/alertChannelActions';


const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    // fontSize:'14px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class EditAlertChannelModal extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      open: this.props.open,
      loading: false,
      success: false,
      alert_channel_name: '',
      alert_channel_description: '',
      // alert_channel_name: this.props.selected_alert_channel.alert_channel_name,
      // alert_channel_description: this.props.selected_alert_channel.alert_channel_description
    }
    // this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitButtonClick = this.submitButtonClick.bind(this);
    this.handleAlertChannelNameChange = this.handleAlertChannelNameChange.bind(this);
    this.handleAlertChannelDescriptionChange = this.handleAlertChannelDescriptionChange.bind(this);

  }
 

  timer = undefined;

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
     });
  };

  

  // componentWillReceiveProps({open}) {
  //   this.setState({...this.state,open})
  // }
  componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.open,
    }); 
    if(this.state.alert_channel_name == ''){
      this.setState({
        alert_channel_name: nextProps.alert_channel_name,
        alert_channel_description: nextProps.alert_channel_description,
      }); 
    }
    
  }

  handleAlertChannelNameChange = (event) => {
    this.setState({alert_channel_name: event.target.value});
  }

  handleAlertChannelDescriptionChange = (event) => {
    this.setState({alert_channel_description: event.target.value});
  }

  submitButtonClick = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        putData('https://api.iotflows.com/v1/alert-channels/'+ this.props.selectedAlertChannel.alert_channel_uuid, 
        {'alert_channel_name': this.state.alert_channel_name,
          'alert_channel_description': this.state.alert_channel_description})
          .then(async response => {
            if(response.ok){   
              this.props.showSuccessSnackbar("Alert channel has been successfully modified!");
              this.setState({
                loading: false,
                success: true,
                open:false,
              }, () => { this.props.fetchAlertChannels(this.props.selectedOrganization.organization_uuid) } );
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: true,
                  open:false,
                });
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
      this.setState({
        loading: false,
        success: false,
        open:false,
      });
      // calling function in parent to permanently close modal
      this.props.handler()
    }
  };



  render() {
    const { classes } = this.props;
    const { open, loading, success} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    


    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
        {/* <Button className={classes.button} variant="outlined"  onClick={this.handleOpen}>Edit Alert Channel</Button> */}
        
          <Dialog
              open={open}
              onClose={this.props.handler}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">{"Edit Alert Channel"}</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '0px'}}>
                
                <TextField
                autoFocus
                margin="normal"
                id="alertChannelName"
                label="Alert Channel Name"
                value={this.state.alert_channel_name}
                onChange={this.handleAlertChannelNameChange}
                type="text"
                fullWidth
                />

                <TextField
                autoFocus
                margin="normal"
                id="alertChannelDescription"
                label="Alert Channel Description"
                value={this.state.alert_channel_description}
                onChange={this.handleAlertChannelDescriptionChange}
                type="text"
                fullWidth
                />

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.props.handler} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

EditAlertChannelModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  fetchAlertChannels: (organization_uuid) => dispatch(fetchAlertChannels(organization_uuid)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    selectedAlertChannel: state.alertChannels.selectedAlertChannel
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(EditAlertChannelModal));

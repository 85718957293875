const initialState = {
  users: [],
  currentUserInfo: [{}],
  selectedUser: {}
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'FETCH_CURRENT_USER':
      return {
        ...state,
        currentUserInfo: action.payload
      }
    // case 'FETCH_USERS':
    //   return {
    //     ...state,
    //     users: action.payload
    //   }
    // case 'SELECTED_USER':
    //   return {
    //     ...state,
    //     selectedUser: action.payload
    //   }
   
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import EditProfileModal from '../components/EditProfileModal';
import "../styles/ButtonStyles.scss";
import LogoutModal from '../components/LogoutModal';
import { connect} from "react-redux";
import {fetchCurrentUser} from '../store/actions/userActions'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import {BrowserRouter as Router,Redirect, Link} from "react-router-dom";
import BlurOnOutlinedIcon from '@material-ui/icons/BlurOnOutlined';
import {fetchOrganizations, fetchSelectedOrganization} from '../store/actions/organizationActions';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import EditOrganizationModal from '../components/EditOrganizationModal';
import AddOrganizationModal from '../components/AddOrganizationModal';
import AddFreeTrialOrganizationModal from '../components/AddFreeTrialOrganizationModal';



const styles = theme => ({
  dropdownButton: {
    padding: 1,
    border: '0px solid rgba(255,255,255,0.2)',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  }, 
  editButton: {
    '&:hover': {
      opacity: 0.7,
    },
  },
  hideSection: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  }
});

class OrganizationSelection extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      userAnchorEl: null,
      userOpen: false,
      editOrganizationModalOpen: false,
      addOrganizationModalOpen: false,
      addFreeTrialOrganizationModalOpen: false,
      organizationInfo: [],
    };
    this.userHandleClick = this.userHandleClick.bind(this);
    this.userHandleClose = this.userHandleClose.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleEditOrganization = this.handleEditOrganization.bind(this);
    this.handleOrgChange = this.handleOrgChange.bind(this);

  }

  // componentDidMount(){
    
  //   // if(this.props.currentUserInfo[0].user_first_name == null || this.props.currentUserInfo[0].user_first_name == '' || this.props.currentUserInfo[0].user_first_name == undefined ||
  //   // this.props.currentUserInfo[0].user_last_name == null || this.props.currentUserInfo[0].user_last_name == '' || this.props.currentUserInfo[0].user_last_name == undefined){
  //   //   setTimeout(() => {
  //   //     this.setState({editProfileModalOpen: true})
  //   //   }, 1000);
  //   // }
  //   if(this.props.currentUserInfo[0].user_first_name == null || this.props.currentUserInfo[0].user_first_name == '' || this.props.currentUserInfo[0].user_first_name == undefined){
  //     console.log("name is true")
  //     setTimeout(() => {
  //       this.setState({editProfileModalOpen: true})
  //     }, 1000);
  //   }
  // }

  componentDidUpdate(prevState, prevProps){
    if(!prevProps.editProfileModalOpen){
      if(this.props.currentUserInfo[0].user_first_name == null || this.props.currentUserInfo[0].user_first_name == '' || this.props.currentUserInfo[0].user_first_name == undefined ||
      this.props.currentUserInfo[0].user_last_name == null || this.props.currentUserInfo[0].user_last_name == '' || this.props.currentUserInfo[0].user_last_name == undefined){
          this.setState({editProfileModalOpen: true})
      }
    }
  }

  userHandleClick = event => {
    this.setState({ logoutModalOpen: false });
    this.setState({ 
      userAnchorEl: event.currentTarget ,
      userOpen: true,
     });

  };

  userHandleClose = () => {
    this.setState({ 
      addOrganizationModalOpen: false,
      addFreeTrialOrganizationModalOpen: false,
      editOrganizationModalOpen: false,
      userAnchorEl: null,
      userOpen: false });

  };

  handleOrgChange = (organization) => {
    this.props.fetchSelectedOrganization(organization)
    this.setState({ 
      addOrganizationModalOpen: false,
      addFreeTrialOrganizationModalOpen: false,
      editOrganizationModalOpen: false,
      userAnchorEl: null,
      userOpen: false });

  }



  handleEditOrganization = (organization) => {
    this.setState({
      organizationInfo: organization,
      userAnchorEl: null,
      userOpen: false
    }, () => this.setState({editOrganizationModalOpen: true}) )
  }

  handleAddOrganizationModalOpen = () => {
    this.setState({ userAnchorEl: null });
    this.setState({ userOpen: false });

    if(!this.props.currentUserInfo[0].user_has_used_free_trial) {
      this.setState({ addFreeTrialOrganizationModalOpen: true });
    }else {
      this.setState({ addOrganizationModalOpen: true });
    }

  };


  handleCloseModal(){
    this.setState({ 
      addOrganizationModalOpen: false,
      addFreeTrialOrganizationModalOpen: false,
      editOrganizationModalOpen: false,
      userAnchorEl: null,
      userOpen: false
    });
  }



  render() {
    const { classes, currentUserInfo, selectedOrganization} = this.props
    const { userAnchorEl, 
            userOpen, 
            organizationInfo, 
            editOrganizationModalOpen, 
            addOrganizationModalOpen, 
            addFreeTrialOrganizationModalOpen} = this.state

    return(
      <div style={{textAlign: 'left'}}>
        
      <React.Fragment>
        <button  className={classes.dropdownButton} onClick={this.userHandleClick}>
          <div style={{display: 'flex'}}>
            <div style={{width: '28px'}} >
              <Avatar style={{ height: '28px', width: '28px', backgroundColor:'rgba(255,255,255,0.2)' }} variant="rounded"  alt={`${this.props.selectedOrganization.organization_name}`} src={this.props.selectedOrganization.organization_logo_url} >
                {this.props.selectedOrganization.organization_name[0]}
              </Avatar>
            </div>
            <div className={classes.hideSection} style={{maxWidth: '110px',textAlign:'left', overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingLeft: '6px'}}>
              <span style={{color: '#fff', fontWeight:500, fontSize: '16px', lineHeight: '28px', letterSpacing: '0.2px' }}>{this.props.selectedOrganization.organization_name}</span>
            </div>
            <div className={classes.hideSection} style={{width:'12px', marginLeft: '8px', lineHeight: '28px',fontSize: '14px', flex: '0 0 auto'}}>
              <svg style={{color: '#fff'}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M13.591 5.293a1 1 0 0 1 1.416 1.416l-6.3 6.3a1 1 0 0 1-1.414 0l-6.3-6.3A1 1 0 0 1 2.41 5.293L8 10.884z"/>
              </svg>
            </div>
          </div>
        </button>

        <Menu style={{outline: 'none'}}
        id="user-menu"
        anchorEl={userAnchorEl}
        open={userOpen}
        onClose={this.userHandleClose}
        // onExit= {this.userHandleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'center',
        // }}
        PaperProps={{
          style: {
            width: 250,
          },
        }}
        >
        {this.props.allOrganizations.map( (organization, index) => (
          <MenuItem key={organization.organization_uuid}  style={{padding: '4px 4px 4px 4px'}} >
            <div style={{display: 'flex'}}>
              <div style={{width: '28px'}} onClick={() => this.handleOrgChange(organization)} >
                <Avatar style={{ height: '28px', width: '28px'}} variant="rounded" src={organization.organization_logo_url} alt={`${organization.organization_name}`}>
                  {organization.organization_name[0]}
                </Avatar>
              </div>
              <div style={{width: '180px', overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingLeft: '6px',lineHeight: '28px'}} onClick={() => this.handleOrgChange(organization)} >
                <span style={{fontWeight:500, fontSize: '16px', letterSpacing: '0.2px' }}>{organization.organization_name}</span>
              </div>
              <div style={{width: '25px', marginLeft: '4px', lineHeight: '28px',fontSize: '14px', zIndex: 5}} onClick={() => this.handleEditOrganization(organization) }>
                <span className={classes.editButton} >Edit</span>
              </div>
            </div>
          </MenuItem>
        ))}

        
         
        <MenuItem onClick={this.handleAddOrganizationModalOpen} style={{padding: '4px 4px 4px 4px'}}>
          <div style={{display: 'flex'}}>
              <div style={{ height: '28px', width: '28px'}} >
                <AddCircleOutlineOutlinedIcon/>
                {/* <Avatar style={{ height: '28px', width: '28px'}} variant="rounded" src="./brokenLink.png" alt={`${organization.organization_name}`} /> */}
              </div>
              <div style={{width: '205px', overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingLeft: '6px',lineHeight: '28px'}}>
                <span style={{fontWeight:500, fontSize: '16px', letterSpacing: '0.2px' }}>New Organization</span>
              </div>
            </div>
        </MenuItem>
        </Menu>
      </React.Fragment> 
      
      {editOrganizationModalOpen? 
        <EditOrganizationModal 
          open={editOrganizationModalOpen} 
          organizationInfo = {organizationInfo}
          handleCloseModal={this.handleCloseModal}
          />
        :null}
        {addOrganizationModalOpen ? 
          <AddOrganizationModal open={addOrganizationModalOpen} handleCloseModal={this.handleCloseModal} />
          : null}
        {addFreeTrialOrganizationModalOpen ?
          <AddFreeTrialOrganizationModal open={addFreeTrialOrganizationModalOpen} disableCancel={false} resetModalStates={this.handleCloseModal} />
          : null}


     
      
      
    </div>
  )
}

}

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

const mapDispatchToProps = dispatch => ({
  fetchSelectedOrganization: currentOrganization => dispatch(fetchSelectedOrganization(currentOrganization)),
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  fetchOrganizations: () => dispatch(fetchOrganizations()),
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrganizationSelection))

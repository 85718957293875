import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
// import Grid from '@material-ui/core/Grid';
// import {getData} from '../ApiCalls/DataApis';
// import MqttService from '../core/services/MqttService';
// import HeaderDevice from './HeaderDevice';
// import DataStreamsTable from '../MyDataStreamsPage/DataStreamsTable';
import DeviceDataModal from './DeviceDataModal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import '../styles/emailAddressesList.css'
// import LinearProgress from '@material-ui/core/LinearProgress';
// import 'rsuite/dist/styles/rsuite-default.css';
// import { DateRangePicker } from 'rsuite';
// import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = theme => ({
  waitingCSS:{
    position: 'absolute',
    zIndex: '2',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    left: 'calc(50% - 148px)',
    top: '25%',
  },
  tableContainer: {
    borderRadius: '10px',
  },
  table: {
    minWidth: 400,
    minHeight: 400,
  },
  tableRow: {
    cursor: 'pointer',
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(52, 58, 64,0.7)"
    }
  },
  tableCell: {
    "$selected &": {
      color: "rgb(232, 232, 232)"
    }
  },
  hover: {},
  selected: {},
  buttonProgress: {
    color: '#9CA3AF',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);



class LogsDeviceTable extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      openDeviceDataModal: false,
      deviceDataRow: [],
      selectedDataRow: [],
      updatedRows:[],
    }
    this.handleCloseDeviceDataModal = this.handleCloseDeviceDataModal.bind(this);
  }


  handleCloseDeviceDataModal = () => {
    this.setState({
      openDeviceDataModal: false,
      deviceDataRow: [],
      selectedDataRow: [],
      })
  }

  formatBytes = (bytes, decimals = 1) => {
    try{
      if (bytes == 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k));
  
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    catch(e){
      return "-"
    }
    
  }

  cutTopic = (topic) => {
    try{
      if(topic){
        var topicSliced = topic.split('/');
        // var subTopicStart = topicSliced[topicSliced.indexOf('data-streams')+2];
        var subTopicStartIndex = topicSliced.indexOf('data-streams')+2;
        if(topicSliced[subTopicStartIndex]){
          var subTopic = topicSliced.slice(subTopicStartIndex).join('/') 
          subTopic = this.cutName(subTopic)
          return subTopic
        }else {
          return ""
        }
      }
    }
    catch(e){
      return ""
    }
  }

  cutData = (cellData) => {
    try {
      var strData = String(cellData);
      if(Object.keys(strData).length > 30 && window.innerWidth <= 600){
        strData = strData.slice(0,30) + '...';
        cellData = strData;
      }
      if(Object.keys(strData).length > 30 && window.innerWidth > 600 && window.innerWidth <= 768){
        strData = strData.slice(0,30) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 30 && window.innerWidth > 768 && window.innerWidth <= 900) {
        strData = strData.slice(0,30) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 40 && window.innerWidth > 900 && window.innerWidth <= 1024) {
        strData = strData.slice(0,40) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 55 && window.innerWidth > 1024 && window.innerWidth <= 1280) {
        strData = strData.slice(0,55) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 70 && window.innerWidth > 1280 && window.innerWidth <= 1440) {
        strData = strData.slice(0,70) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 80 && window.innerWidth > 1440) {
        strData = strData.slice(0,80) + '...';
        cellData = strData;
      }
  
      return cellData
    }
    catch {
      return ""
    }

  }

  cutName = (cellData) => {
    try {
      var strData = String(cellData);
      if(Object.keys(strData).length > 13 && window.innerWidth <= 600){
        strData = strData.slice(0,13) + '...';
        cellData = strData;
      }
      if(Object.keys(strData).length > 13 && window.innerWidth > 600 && window.innerWidth <= 768){
        strData = strData.slice(0,13) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 25 && window.innerWidth > 768 && window.innerWidth <= 900) {
        strData = strData.slice(0,25) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 30 && window.innerWidth > 900 && window.innerWidth <= 1024) {
        strData = strData.slice(0,30) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 30 && window.innerWidth > 1024 && window.innerWidth <= 1280) {
        strData = strData.slice(0,30) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 45 && window.innerWidth > 1280 && window.innerWidth <= 1440) {
        strData = strData.slice(0,45) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 70 && window.innerWidth > 1440) {
        strData = strData.slice(0,70) + '...';
        cellData = strData;
      }
  
      return cellData
    }
    catch {
      return ""
    }

  }

  handleRowSelected = (row) => {
    var key = (row.id || '') + (row.data_stream_uuid || row.data_stream_id)  + row.received_at;
    this.setState({
      deviceDataRow: row.data,
      selectedDataRow: row
    }, ()=> this.setState({openDeviceDataModal: true }))
  }



  render(){
    const { classes, theme, isSearched, searchedRows, rows, fetchingData} = this.props
    const { openDeviceDataModal,
            deviceDataRow,
            selectedDataRow,
          } = this.state

          
    return (
      <div className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="data table">
            <colgroup>
              <col style={{width:'15%'}}/>
              <col style={{width:'15%'}}/>
              <col style={{width:'25%'}}/>
              <col style={{width:'5%'}}/>
              <col style={{width:'10%'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'10%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{paddingLeft: '12px'}}>Publisher</StyledTableCell>
                <StyledTableCell >Data Stream</StyledTableCell>
                <StyledTableCell align="right">Data</StyledTableCell>
                <StyledTableCell >Unit</StyledTableCell>
                <StyledTableCell >Size</StyledTableCell>
                <StyledTableCell >Received At</StyledTableCell>
                <StyledTableCell >Topic</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              
                {!fetchingData && Object.keys(rows).length == 0 &&
                <TableRow >
                  <StyledTableCell colSpan={7}>
                  <div  style={{position: 'relative', height:'200px'}}>
                    <div className={classes.waitingCSS}  >
                      <span className="waitingOnData"> 
                      <Col   >
                        <Row style={{ justifyContent:'center'}}>
                          <Typography  variant="subtitle2" style={{ fontFamily:'Trebuchet MS'}}  >
                            WAITING ON DATA
                          </Typography>
                        </Row>
                        <Row style={{ justifyContent:'center'}}>
                          <Typography  variant="body2"  style={{ fontSize: '12px', fontFamily:'Trebuchet MS' }}>
                              Device messages will be published here in real-time
                          </Typography>
                        </Row>
                      </Col>
                      </span>
                    </div>
                  </div> 
                  </StyledTableCell>
                </TableRow>
                }
              {!isSearched &&
                rows.map((dataRow) => (
                  <TableRow 
                    // key={(dataRow.id || '') + (dataRow.data_stream_uuid || dataRow.data_stream_id)  + dataRow.received_at} 
                    hover
                    classes={{ hover: classes.hover }}
                    className={classes.tableRow} 
                    onClick={() => this.handleRowSelected(dataRow)}
                    >
                    <StyledTableCell style={{paddingLeft: '12px'}}>{this.cutName(dataRow.client_name)}</StyledTableCell>
                    <StyledTableCell align="left" >{this.cutName(dataRow.data_stream_name)}</StyledTableCell>
                    <StyledTableCell align="right">{this.cutData(dataRow.data)}</StyledTableCell>
                    <StyledTableCell align="left" >{this.cutName(dataRow.data_stream_unit)}</StyledTableCell>
                    <StyledTableCell align="left">{dataRow.data_stream_size}</StyledTableCell>
                    <StyledTableCell align="left" >
                      {dataRow.received_at}
                    </StyledTableCell>
                    <StyledTableCell >
                    {this.cutTopic(dataRow.topic)}
                    </StyledTableCell>
                  </TableRow>
                ))
              }
              {isSearched &&
                searchedRows.map((dataRow, index) => (
                  <TableRow 
                    // key={dataRow.id + dataRow.data_stream_uuid} 
                    hover
                    classes={{ hover: classes.hover }}
                    className={classes.tableRow} 
                    onClick={() => this.handleRowSelected(dataRow)}
                    >
                    <StyledTableCell style={{paddingLeft: '12px'}}>{this.cutName(dataRow.client_name)}</StyledTableCell>
                    <StyledTableCell align="left" >{this.cutName(dataRow.data_stream_name)}</StyledTableCell>
                    <StyledTableCell align="right">{this.cutData(dataRow.data)}</StyledTableCell>
                    <StyledTableCell align="left" >{this.cutName(dataRow.data_stream_unit)}</StyledTableCell>
                    <StyledTableCell align="left">{dataRow.data_stream_size}</StyledTableCell>
                    <StyledTableCell align="left" >
                      {dataRow.received_at}
                    </StyledTableCell>
                    <StyledTableCell >
                    {this.cutTopic(dataRow.topic)}
                    </StyledTableCell>
                  </TableRow>
                ))
              }
              {!this.props.searchMoreData &&
                <>
                <TableRow>
                  <StyledTableCell colSpan={7} style={{textAlign:'center'}}>
                    <CircularProgress size={42} className={classes.buttonProgress}/>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell colSpan={7} ></StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell colSpan={7} ></StyledTableCell>
                </TableRow>
                </>
              }
            </TableBody>
          </Table>
        </TableContainer> 
      {openDeviceDataModal?
      <DeviceDataModal openDeviceDataModal={openDeviceDataModal} title={'Device Data'} deviceDataRow={deviceDataRow} selectedDataRow={selectedDataRow} handleCloseDeviceDataModal={this.handleCloseDeviceDataModal} />
        : null}
        
        

      </div>


    )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{ withTheme: true })(LogsDeviceTable));

import React from "react";
import _ from "lodash";
import { WidthProvider, Responsive } from "react-grid-layout";
import {withRouter} from 'react-router-dom';
import "./resizeStyles.css"
import "./reactGridStyles.css"
import { withStyles } from '@material-ui/core/styles';
// import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
// import Button from '@material-ui/core/Button';
// import AssetTrackingMap from './AssetTrackingMap';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableFooter from '@material-ui/core/TableFooter';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import { useTheme } from '@mui/material';
// import Paper from '@material-ui/core/Paper';
// import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import  { getData } from '../ApiCalls/DataApis';
import Checkbox from '@mui/material/Checkbox';
// import LinearProgress from '@material-ui/core/LinearProgress';
import 'rsuite/dist/styles/rsuite-default.css';
import { DateRangePicker } from 'rsuite';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {IoTFlowsHeatmap} from './IoTFlowsHeatmap'
import TableSATWidget from './TableSATWidget'
import PartIdSelection from './PartIdSelection'


const {
  allowedMaxDays,
  allowedDays,
  allowedRange,
  beforeToday,
  afterToday,
  combine
} = DateRangePicker;


// mainDataDic1: {
//   'CONTROLLER1/ASSET/SPINDLE1' : { checkBoxState: true, ok: 12, nok: 35 },
//   'CONTROLLER1/ASSET/SPINDLE2' : { checkBoxState: true, ok: 32, nok: 42 },
//   'CONTROLLER1/ASSET/SPINDLE3' : { checkBoxState: true, ok: 32, nok: 45 },
//   'CONTROLLER1/ASSET/SPINDLE4' : { checkBoxState: true, ok: 32, nok: 16 },
//   'CONTROLLER1/ASSET/SPINDLE5' : { checkBoxState: true, ok: 32, nok: 43 },
//   'CONTROLLER1/ASSET/SPINDLE6' : { checkBoxState: true, ok: 32, nok: 150 },
//   'CONTROLLER1/ASSET/SPINDLE7' : { checkBoxState: true, ok: 32, nok: 44 },
//   'CONTROLLER1/ASSET/SPINDLE8' : { checkBoxState: true, ok: 32, nok: 36 },
//   'CONTROLLER1/ASSET/SPINDLE9' : { checkBoxState: true, ok: 53, nok: 65 },
//   'CONTROLLER1/ASSET/SPINDLE10': { checkBoxState: true, ok: 32, nok: 65 },
//   'CONTROLLER1/ASSET/SPINDLE11': { checkBoxState: true, ok: 32, nok: 65 },
//   'CONTROLLER1/ASSET/SPINDLE12': { checkBoxState: true, ok: 59, nok: 65 },
//   'CONTROLLER1/ASSET/SPINDLE13': { checkBoxState: true, ok: 72, nok: 65 },
//   'CONTROLLER1/ASSET/SPINDLE14': { checkBoxState: true, ok: 30, nok: 65 },
//   'CONTROLLER1/ASSET/SPINDLE15': { checkBoxState: true, ok: 64, nok: 65 },
// },
// mainDataDic2: {
//   'CONTROLLER2/ASSET/SPINDLE1' : { checkBoxState: true, ok: 75, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE2' : { checkBoxState: true, ok: 21, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE3' : { checkBoxState: true, ok: 32, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE4' : { checkBoxState: true, ok: 60, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE5' : { checkBoxState: true, ok: 32, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE6' : { checkBoxState: true, ok: 23, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE7' : { checkBoxState: true, ok: 32, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE8' : { checkBoxState: true, ok: 32, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE9' : { checkBoxState: true, ok: 32, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE10': { checkBoxState: true, ok: 32, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE11': { checkBoxState: true, ok: 32, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE12': { checkBoxState: true, ok: 32, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE13': { checkBoxState: true, ok: 32, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE14': { checkBoxState: true, ok: 32, nok: 65 },
//   'CONTROLLER2/ASSET/SPINDLE15': { checkBoxState: true, ok: 32, nok: 65 },
// },


// const ResponsiveReactGridLayout = WidthProvider(Responsive);

// const StyledTableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: theme.palette.common.white,
//     color: theme.palette.common.gray,
//     fontWeight: 700,
//     fontFamily:'Trebuchet MS',
//     fontSize: 11,
//     height: '20px',
//     padding: '8px',
//   },
//   body: {
//     fontSize: 13,
//     fontFamily:'Trebuchet MS',
//     height: '24px',
//     padding: '8px',
//   },
// }))(TableCell);

const styles = theme => ({
    button: {
        backgroundColor:'transparent',
        borderRadius:'5px',
        border:'1px solid #4f86ce',
        display:'inline-block',
        minWidth:'70px',
        maxHeight: '40px',
        cursor:'pointer',
        color:'#4f87cc',
        fontFamily:'Trebuchet MS',
        fontSize:'13px',
        // font-weight:bold;
        padding:'4px 10px',
        textDecoration:'none',
        textShadow:'0px 0px 0px #9eb9ff',
        outline: 'none',
        '&:hover': {
            backgroundColor:'transparent',
        },
        '&:active': {
            position:'relative',
            top:'1px',
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    cardRoot: {
        margin: '8px',
        borderRadius:'20px', 
        // boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1), 0px -4px 15px -2px rgba(0,0,0,0.1)',
        backgroundColor:'transparent', 
        border:'0px solid transparent'
      },
    cardRootMini: {
        margin: '8px',
        borderRadius:'20px', 
        boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1), 0px -4px 15px -2px rgba(0,0,0,0.1)',
      },
    tableContainer: {
      borderRadius: '10px',
    },
    table: {
      minWidth: 450,
    },
    colorPrimary: {
      backgroundColor: '#4f86ce',
    },
    barColorPrimary: {
      backgroundColor: '#bfdaff',
    },
    buttonProgress: {
      height: '2px'
    },
    breadcrumbCSS: {
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      paddingBottom: '3px',
    },
    fontStyles: {
      fontFamily:'Trebuchet MS',
      fontSize: '14px',
    },
    downloadButton: {
      backgroundColor:'transparent',
      borderRadius:'5px',
      border:'1px solid #757575',
      display:'inline-block',
      cursor:'pointer',
      color:'#757575',
      fontFamily:'Trebuchet MS',
      fontSize:'12px',
      // font-weight:bold;
      padding:'2px 14px',
      textDecoration:'none',
      textShadow:'0px 0px 0px #9eb9ff',
      outline: 'none',
      '&:hover': {
        backgroundColor:'rgba(117, 117, 117,0.1)',
      },
      '&:active': {
        position:'relative',
        top:'1px',
        outline: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    filtersSection:{
      padding:'0px 8px',
      display:'flex', 
      justifyContent:'flex-end',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding:'8px',
      }

    } 
})


/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */
class DashboardSATBechtel extends React.PureComponent {
      constructor(props) {
        super(props);
        this.state = {
            testData: [],
            testDataTable: [],
            checked1: true,
            controllerCheck1: true,
            controllerCheck2: true,
            controller1Dic:{},
            controller2Dic:{},
            loading:false,
            startDate: "",
            endDate: "",
            selectedSpindleTopics:'',
            selectedSpindles:[],
            sat_status_per_day: {},
            partIds:[],
            selectedPartIds:[],
        };
      }

      componentDidMount() {        
  
        // hack: read sat_status_per_day
        var self = this
        this.fetchSatStatusPerDay(null,null,null)                        
        
        // let start = (new Date( (new Date()).getTime() - (7 * 24 * 60 * 60 * 1000))).toLocaleDateString('en-CA')
        let start = (new Date( (new Date()).getTime() - (7 * 24 * 60 * 60 * 1000))).toISOString()

        // let end = (new Date()).toLocaleDateString('en-CA') 
        let end = (new Date()).toISOString() 
        
        this.setState({
          startDate: start,
          endDate: end,
        })                   
        getData(`https://api.iotflows.com/v1/data_streams/ds_2e420d6725134d078ead21608ca49898/sat_data?starting_date=${start}&ending_date=${end}&version=1`)
        .then(async response => {
          if(response && response.ok ){
              const data = await response.json();
              if(data ){
                  if(data.counts){
                    // start populating the spindle states for other functions
                    let spindleStateTopics = [];
                    if(data.counts.CONTROLLER1){
                      var dic1 = data.counts.CONTROLLER1;
                      spindleStateTopics.push("CONTROLLER1")
                      Object.keys(data.counts.CONTROLLER1).map(spindle =>(
                        dic1[spindle].checkBoxState = true
                      ))
                      this.setState({controller1Dic: dic1})
                    }
                    if(data.counts.CONTROLLER2){
                      var dic2 = data.counts.CONTROLLER2;
                      spindleStateTopics.push("CONTROLLER2")
                      Object.keys(data.counts.CONTROLLER2).map(spindle =>(
                        dic2[spindle].checkBoxState = true
                      ))
                      this.setState({controller2Dic: dic2})
                    }
                    this.setState({selectedSpindles: spindleStateTopics})
                  }
                  if(data.part_ids){
                    this.setState({
                      partIds: data.part_ids
                    })  
                  }
                  this.setState({
                    testData: data,
                    testDataTable: data.data,
                  })              
              }
          }
        });
        this.fetchSatStatusPerDay(start, end, null)
      }    

      handleCheckbox1 = (id, event) => {
        if(!event.target.checked){
          this.setState({controllerCheck1: false})
        }
        // Update state of all check boxes
        let current = this.state.controller1Dic;
        current[id].checkBoxState = event.target.checked
        this.setState(prevState => ({
          ...prevState,
          controller1Dic: {
            ...prevState.controller1Dic,
            testID: current[id]
          }        
        }), () => {
          const { loading, startDate, endDate, controller1Dic, controller2Dic, controllerCheck2, selectedPartIds} = this.state;

          // checking to see if all check boxes are checked, if so then check the main controller boc
          let val = true
          Object.keys(controller1Dic).map(spindle =>{
            if(!controller1Dic[spindle].checkBoxState){
              val = false
            }
          })
          this.setState({controllerCheck1: val})

          // Updating topic state of all checkbox for API
          // create an array for API topic 
          let spindleStateTopics = [];
          if(Object.keys(controller1Dic).length > 0){
            if(val){
              spindleStateTopics.push("CONTROLLER1")
            }
            else{
              Object.keys(controller1Dic).map(spindle =>{
                if(spindle != "testID" && controller1Dic[spindle].checkBoxState)
                spindleStateTopics.push(spindle)
              })
            }
          }
          if(Object.keys(controller2Dic).length > 0){
            if(controllerCheck2){
              spindleStateTopics.push("CONTROLLER2")
            }else{
              Object.keys(controller2Dic).map(spindle =>{
                if(spindle != "testID" && controller2Dic[spindle].checkBoxState)
                spindleStateTopics.push(spindle)
              })
            }
          }
          if(!loading){
            var endLink = '';
            if(Object.keys(spindleStateTopics).length > 0  && startDate && endDate){
              endLink = 'topic_containing=' + spindleStateTopics.toString() + '&starting_date=' + startDate + '&ending_date=' + endDate;
            }else if(Object.keys(spindleStateTopics).length > 0) {
              endLink = 'topic_containing=' + spindleStateTopics.toString() 
            }else if(startDate && endDate){
              endLink = 'starting_date=' + startDate + '&ending_date=' + endDate;
            }

            let stringIds = selectedPartIds.toString();
            if(endLink && Object.keys(selectedPartIds).length >0){
              endLink += `&part_id=${stringIds}`
            }else if(!endLink && Object.keys(selectedPartIds).length >0){
              endLink = `part_id=${stringIds}`
            }
            
            getData('https://api.iotflows.com/v1/data_streams/ds_2e420d6725134d078ead21608ca49898/sat_data?'+ endLink + '&version=1' )
            .then(async response => {
              if(response && response.ok ){
                  const data = await response.json();
                  if(data ){
                      this.setState({loading: false})
                      this.setState({
                        testData: data,
                        testDataTable: data.data,
                      })

                      if(data.counts.CONTROLLER1){
                        var dic1 = data.counts.CONTROLLER1;
                        Object.keys(data.counts.CONTROLLER1).map(spindle =>(
                          dic1[spindle].checkBoxState = true
                        ))
                        this.setState({controller1Dic: dic1})
                      }

                  }
              }
            });
            this.fetchSatStatusPerDay(null, null, null, endLink)
          }
          this.setState({selectedSpindles:spindleStateTopics})
        })
      }
      handleCheckbox2 = (id, event) => {
        if(!event.target.checked){
          this.setState({controllerCheck2: false})
        }
        // is this current worthless
        let current = this.state.controller2Dic;
        current[id].checkBoxState = event.target.checked
        this.setState(prevState => ({
          ...prevState,
          controller2Dic: {
            ...prevState.controller2Dic,
            testID: current[id]
          }
        }), () => {
          const { loading, startDate, endDate, controller1Dic,controllerCheck1, controller2Dic, controllerCheck2, selectedPartIds} = this.state;

          // checking to see if all check boxes are checked, if so then check the main controller boc
          let val = true
          Object.keys(controller2Dic).map(spindle =>{
            if(!controller2Dic[spindle].checkBoxState){
              val = false
            }
          })
          this.setState({controllerCheck2: val})

          // Updating topic state of all checkbox for API
          // create an array for API topic 
          let spindleStateTopics = [];
          if(Object.keys(controller1Dic).length > 0){
            if(controllerCheck1){
              spindleStateTopics.push("CONTROLLER1")
            }
            else{
              Object.keys(controller1Dic).map(spindle =>{
                if(spindle != "testID" && controller1Dic[spindle].checkBoxState)
                spindleStateTopics.push(spindle)
              })
            }
          }
          if(Object.keys(controller2Dic).length > 0){
            if(val){
              spindleStateTopics.push("CONTROLLER2")
            }else{
              Object.keys(controller2Dic).map(spindle =>{
                if(spindle != "testID" && controller2Dic[spindle].checkBoxState)
                spindleStateTopics.push(spindle)
              })
            }
          }
          if(!loading){
            var endLink = '';
            if(Object.keys(spindleStateTopics).length > 0  && startDate && endDate){
              endLink = 'topic_containing=' + spindleStateTopics.toString() + '&starting_date=' + startDate + '&ending_date=' + endDate;
            }else if(Object.keys(spindleStateTopics).length > 0) {
              endLink = 'topic_containing=' + spindleStateTopics.toString() 
            }else if(startDate && endDate){
              endLink = 'starting_date=' + startDate + '&ending_date=' + endDate;
            }

            let stringIds = selectedPartIds.toString();
            if(endLink && Object.keys(selectedPartIds).length >0){
              endLink += `&part_id=${stringIds}`
            }else if(!endLink && Object.keys(selectedPartIds).length >0){
              endLink = `part_id=${stringIds}`
            }
            getData('https://api.iotflows.com/v1/data_streams/ds_2e420d6725134d078ead21608ca49898/sat_data?'+ endLink + '&version=1' )
            .then(async response => {
              if(response && response.ok ){
                  const data = await response.json();
                  if(data ){
                      this.setState({loading: false})
                      this.setState({
                        testData: data,
                        testDataTable: data.data,
                      })

                      if(data.counts.CONTROLLER2){
                        var dic2 = data.counts.CONTROLLER2;
                        Object.keys(data.counts.CONTROLLER2).map(spindle =>(
                          dic2[spindle].checkBoxState = true
                        ))
                        this.setState({controller2Dic: dic2})
                      }
                  }
              }
            });
            this.fetchSatStatusPerDay(null, null, null, endLink)
          }
          this.setState({selectedSpindles:spindleStateTopics})
        })
      }

      handleControllerCheck1 = (event) =>{
        this.setState({controllerCheck1: event.target.checked})

        const { loading, startDate, endDate, controller1Dic, controller2Dic, controllerCheck2, selectedPartIds} = this.state;
        // create an array for API topic 
        let spindleStateTopics = [];
        if(Object.keys(controller1Dic).length > 0){
          if(event.target.checked){
            spindleStateTopics.push("CONTROLLER1")
          }
          // else{
          //   Object.keys(controller1Dic).map(spindle =>{
          //     if(spindle != "testID" && controller1Dic[spindle].checkBoxState)
          //     spindleStateTopics.push(spindle)
          //   })
          // }
        }
        if(Object.keys(controller2Dic).length > 0){
          if(controllerCheck2){
            spindleStateTopics.push("CONTROLLER2")
          }else{
            Object.keys(controller2Dic).map(spindle =>{
              if(spindle != "testID" && controller2Dic[spindle].checkBoxState)
              spindleStateTopics.push(spindle)
            })
          }
        }
        if(!loading){
          var endLink = '';
          if(Object.keys(spindleStateTopics).length > 0  && startDate && endDate){
            endLink = 'topic_containing=' + spindleStateTopics.toString() + '&starting_date=' + startDate + '&ending_date=' + endDate;
          }else if(Object.keys(spindleStateTopics).length > 0) {
            endLink = 'topic_containing=' + spindleStateTopics.toString() 
          }else if(startDate && endDate){
            endLink = 'starting_date=' + startDate + '&ending_date=' + endDate;
          }
          let stringIds = selectedPartIds.toString();
          if(endLink && Object.keys(selectedPartIds).length >0){
            endLink += `&part_id=${stringIds}`
          }else if(!endLink && Object.keys(selectedPartIds).length >0){
            endLink = `part_id=${stringIds}`
          }
          getData('https://api.iotflows.com/v1/data_streams/ds_2e420d6725134d078ead21608ca49898/sat_data?'+ endLink + '&version=1' )
          .then(async response => {
            if(response && response.ok ){
                const data = await response.json();
                if(data ){
                    this.setState({loading: false})
                    this.setState({
                      testData: data,
                      testDataTable: data.data,
                    })

                    if(data.counts.CONTROLLER1){
                      var dic1 = data.counts.CONTROLLER1;
                      Object.keys(data.counts.CONTROLLER1).map(spindle =>(
                        dic1[spindle].checkBoxState = true
                      ))
                      this.setState({controller1Dic: dic1})
                    }
                }
            }
          });
          this.fetchSatStatusPerDay(null, null, null, endLink)
        }
        this.setState({selectedSpindles:spindleStateTopics})
        

        // select all spindles for visuals
        if(event.target.checked){
          let current = this.state.controller1Dic;
          Object.keys(this.state.controller1Dic).map(spindle =>{
            if(spindle != "testID"){
              current[spindle].checkBoxState = true
            }
          })

          this.setState(prevState => ({
            ...prevState,
            controller1Dic: {
              ...prevState.controller1Dic,
              testID: false
            }
          }))
        }
        // unselect all spindles
        if(!event.target.checked){
          let current = this.state.controller1Dic;
          Object.keys(this.state.controller1Dic).map(spindle =>{
            if(spindle != "testID"){
              current[spindle].checkBoxState = false
            }
          })

          this.setState(prevState => ({
            ...prevState,
            controller1Dic: {
              ...prevState.controller1Dic,
              testID: true
            }
          }))
        }
      }

      handleControllerCheck2 = (event) =>{
        this.setState({controllerCheck2: event.target.checked})

        const { loading, startDate, endDate, controller1Dic, controller2Dic, controllerCheck2, controllerCheck1, selectedPartIds} = this.state;
        // create an array for API topic 
        let spindleStateTopics = [];
        if(Object.keys(controller2Dic).length > 0){
          if(event.target.checked){
            spindleStateTopics.push("CONTROLLER2")
          }
          // else{
          //   Object.keys(controller1Dic).map(spindle =>{
          //     if(spindle != "testID" && controller1Dic[spindle].checkBoxState)
          //     spindleStateTopics.push(spindle)
          //   })
          // }
        }
        if(Object.keys(controller1Dic).length > 0){
          if(controllerCheck1){
            spindleStateTopics.push("CONTROLLER1")
          }else{
            Object.keys(controller1Dic).map(spindle =>{
              if(spindle != "testID" && controller1Dic[spindle].checkBoxState)
              spindleStateTopics.push(spindle)
            })
          }
        }
        
        if(!loading){
          var endLink = '';
          if(Object.keys(spindleStateTopics).length > 0  && startDate && endDate){
            endLink = 'topic_containing=' + spindleStateTopics.toString() + '&starting_date=' + startDate + '&ending_date=' + endDate;
          }else if(Object.keys(spindleStateTopics).length > 0) {
            endLink = 'topic_containing=' + spindleStateTopics.toString() 
          }else if(startDate && endDate){
            endLink = 'starting_date=' + startDate + '&ending_date=' + endDate;
          }

          let stringIds = selectedPartIds.toString();
          if(endLink && Object.keys(selectedPartIds).length >0){
            endLink += `&part_id=${stringIds}`
          }else if(!endLink && Object.keys(selectedPartIds).length >0){
            endLink = `part_id=${stringIds}`
          }
          
          getData('https://api.iotflows.com/v1/data_streams/ds_2e420d6725134d078ead21608ca49898/sat_data?'+ endLink + '&version=1' )
          .then(async response => {
            if(response && response.ok ){
                const data = await response.json();
                if(data ){
                    this.setState({loading: false})
                    this.setState({
                      testData: data,
                      testDataTable: data.data,
                    })

                    if(data.counts.CONTROLLER2){
                      var dic2 = data.counts.CONTROLLER2;
                      Object.keys(data.counts.CONTROLLER2).map(spindle =>(
                        dic2[spindle].checkBoxState = true
                      ))
                      this.setState({controller2Dic: dic2})
                    }
                }
            }
          });
          this.fetchSatStatusPerDay(null, null, null, endLink)
        }
        this.setState({selectedSpindles:spindleStateTopics})
        

        // select all spindles for visuals
        if(event.target.checked){
          let current = this.state.controller2Dic;
          Object.keys(this.state.controller2Dic).map(spindle =>{
            if(spindle != "testID"){
              current[spindle].checkBoxState = true
            }
          })

          this.setState(prevState => ({
            ...prevState,
            controller2Dic: {
              ...prevState.controller2Dic,
              testID: false
            }
          }))
        }
        // unselect all spindles
        if(!event.target.checked){
          let current = this.state.controller2Dic;
          Object.keys(this.state.controller2Dic).map(spindle =>{
            if(spindle != "testID"){
              current[spindle].checkBoxState = false
            }
          })

          this.setState(prevState => ({
            ...prevState,
            controller1Dic: {
              ...prevState.controller1Dic,
              testID: true
            }
          }))
        }
      }

      getPercentage = (ratio) => {
        if(ratio){
          let percent
          percent = (ratio)*100
          percent = percent.toFixed(1)+'%'
          return percent
        }
      }

      formatDate = (date) => {
        if(date){
          let modifyDate = new Date(date);
          modifyDate = modifyDate.toLocaleTimeString()+' '+modifyDate.toLocaleDateString();
          return modifyDate
        }
      }

      handleSelectedDate = (value) => {
        var start = new Date(value[0]);
        // get date to be lie 1/1/2022

        start = start.toISOString();
        // start = start.toLocaleDateString();
        

        // start = start.getTime();
        // start = parseInt(start);
        var end = new Date(value[1]);

        end = end.toISOString();
        // end = end.toLocaleDateString();
        

        // end = end.getTime();
        // end = parseInt(end);
        //  convert it to the end of the day
        // end = end + 86399000; 
        
        const { loading,selectedSpindles, selectedPartIds} = this.state;
        if(!loading){
          this.setState({
              loading: true,
              startDate: start,
              endDate: end,
            }, () => {
              if(start && end){
                var endLink = 'starting_date=' + start + '&ending_date=' + end;
                var topicContaining = ''
                // if(Object.keys(selectedSpindles).length > 0){
                //   endLink = 'topic_containing=' + selectedSpindles.toString() + '&starting_date=' + start + '&ending_date=' + end;
                //   topicContaining = 'topic_containing=' + selectedSpindles.toString()
                // }
                // let stringIds = selectedPartIds.toString();
                // if(endLink && Object.keys(selectedPartIds).length >0){
                //   endLink += `&part_id=${stringIds}`
                // }
                getData('https://api.iotflows.com/v1/data_streams/ds_2e420d6725134d078ead21608ca49898/sat_data?'+ endLink + '&version=1' )
                .then(async response => {
                  if(response && response.ok ){
                      const data = await response.json();
                      if(data && data.data){

                        this.setState({loading: false})
                        this.setState({
                          testData: data,
                          testDataTable: data.data,
                        })
                        let spindleStateTopics = [];
                        let dic1 = {}
                        let dic2 = {}
                        if(data.counts.CONTROLLER1){
                          dic1 = data.counts.CONTROLLER1;
                          spindleStateTopics.push("CONTROLLER1")
                          Object.keys(data.counts.CONTROLLER1).map(spindle =>(
                            dic1[spindle].checkBoxState = true
                          ))
                        }
                        if(data.counts.CONTROLLER2){
                          dic2 = data.counts.CONTROLLER2;
                          spindleStateTopics.push("CONTROLLER2")
                          Object.keys(data.counts.CONTROLLER2).map(spindle =>(
                            dic2[spindle].checkBoxState = true
                          ))
                        }
                        this.setState({
                          controllerCheck1: true,
                          controllerCheck2: true,
                          controller1Dic: dic1,
                          controller2Dic: dic2,
                          selectedSpindles: spindleStateTopics
                        })
                        if(data.part_ids){
                          this.setState({
                            partIds: data.part_ids,
                            selectedPartIds:[]
                          })  
                        }
                      }
                  }
                });                
                                          
                this.fetchSatStatusPerDay(null, null, topicContaining, endLink)
              }
          })
        }
      }
      
      // hack: read sat_status_per_day
      fetchSatStatusPerDay(starting_time, ending_time, topic_containing, endLink) {        
        var self = this  

        // let start = starting_time ? (new Date(starting_time)).toLocaleDateString('en-CA') : (new Date( (new Date()).getTime() - (7 * 24 * 60 * 60 * 1000))).toLocaleDateString('en-CA')
        let start = starting_time ? (new Date(starting_time)).toISOString() : (new Date( (new Date()).getTime() - (7 * 24 * 60 * 60 * 1000))).toISOString()

        // let end = ending_time ? (new Date(ending_time)).toLocaleDateString('en-CA')     : (new Date()).toLocaleDateString('en-CA')            
        let end = ending_time ? (new Date(ending_time)).toISOString()     : (new Date()).toISOString()            
        let topicContaining = topic_containing || ''        
        var url = ''
        if(endLink)
        {
          url = `https://api.iotflows.com/v1/data_streams/ds_2e420d6725134d078ead21608ca49898/sat_status_per_day?${endLink}&version=1`
          if(!url.includes('starting_date') || !url.includes('ending_date'))        
            url += `&starting_date=${start}&ending_date=${end}`
        }          
        else
        {
          url = `https://api.iotflows.com/v1/data_streams/ds_2e420d6725134d078ead21608ca49898/sat_status_per_day?starting_date=${start}&ending_date=${end}&version=1`
        }                   
        if(topicContaining && !url.includes('topicContaining'))        
          url += `&` + topicContaining             
        getData(url)
        .then(async response => {
          if(response && response.ok ){
            const data = await response.json();
            if(data && data.data){
              self.setState({sat_status_per_day: data.data})                      
            }
          }
        })
      }

      handleClearDate = (e) => {
        try{
          const { loading,selectedSpindles, selectedPartIds} = this.state;

          if(!loading){

            // setting date to always be at 7 days 
            // let start = (new Date( (new Date()).getTime() - (7 * 24 * 60 * 60 * 1000))).toLocaleDateString('en-CA')
            let start = (new Date( (new Date()).getTime() - (7 * 24 * 60 * 60 * 1000))).toISOString()
            // let end = (new Date()).toLocaleDateString('en-CA')  
            let end = (new Date()).toISOString()  

            this.setState({
                loading: true,
                startDate: start,
                endDate: end,
              })
            var endLink = `starting_date=${start}&ending_date=${end}`;
            // if(Object.keys(selectedSpindles).length > 0){
            //   endLink += '&topic_containing=' + selectedSpindles.toString();
            // }
            // let stringIds = selectedPartIds.toString();
            // if(endLink && Object.keys(selectedPartIds).length >0){
            //   endLink += `&part_id=${stringIds}`
            // }
            getData('https://api.iotflows.com/v1/data_streams/ds_2e420d6725134d078ead21608ca49898/sat_data?'+ endLink +'&version=1')
            .then(async response => {
              if(response && response.ok ){
                  const data = await response.json();
                  if(data && data.data){
                      this.setState({loading: false})
                      this.setState({
                        testData: data,
                        testDataTable: data.data,
                      })

                      let dic1 = {}
                      let dic2 = {}
                      if(data.counts){
                        if(data.counts.CONTROLLER1){
                          dic1 = data.counts.CONTROLLER1;
                          Object.keys(data.counts.CONTROLLER1).map(spindle =>(
                            dic1[spindle].checkBoxState = true
                          ))
                          this.setState({controller1Dic: dic1})
                        }
                        if(data.counts.CONTROLLER2){
                          dic2 = data.counts.CONTROLLER2;
                          Object.keys(data.counts.CONTROLLER2).map(spindle =>(
                            dic2[spindle].checkBoxState = true
                          ))
                          this.setState({controller2Dic: dic2})
                        }
                      }
                      if(data.part_ids){
                        this.setState({
                          partIds: data.part_ids,
                          selectedPartIds:[]
                        })  
                      }
                  }
              }
            });
            this.fetchSatStatusPerDay(null, null, null, endLink)
          }
        }catch(e){
          console.log(e)
        }
      }


      handlePartIdCallback = (ids) => {
        this.setState({selectedPartIds:ids})
        if(this.state.startDate && this.state.endDate){
          try{
            if(!this.state.loading){
              this.setState({
                  loading: true,
                })
                var endLink = 'starting_date=' + this.state.startDate + '&ending_date=' + this.state.endDate;
                if(Object.keys(ids).length >0){
                  let stringIds = ids.toString();
                  endLink += `&part_id=${stringIds}`
                } 
    
                getData('https://api.iotflows.com/v1/data_streams/ds_2e420d6725134d078ead21608ca49898/sat_data?'+ endLink + '&version=1' )
                .then(async response => {
                  if(response && response.ok ){
                      const data = await response.json();
                      if(data && data.data){
    
                        this.setState({loading: false})
                        this.setState({
                          testData: data,
                          testDataTable: data.data,
                        })
                        let spindleStateTopics = [];
                        let dic1 = {}
                        let dic2 = {}
                        if(data.counts.CONTROLLER1){
                          dic1 = data.counts.CONTROLLER1;
                          spindleStateTopics.push("CONTROLLER1")
                          Object.keys(data.counts.CONTROLLER1).map(spindle =>(
                            dic1[spindle].checkBoxState = true
                          ))
                        }
                        if(data.counts.CONTROLLER2){
                          dic2 = data.counts.CONTROLLER2;
                          spindleStateTopics.push("CONTROLLER2")
                          Object.keys(data.counts.CONTROLLER2).map(spindle =>(
                            dic2[spindle].checkBoxState = true
                          ))
                        }
                        this.setState({
                          controllerCheck1: true,
                          controllerCheck2: true,
                          controller1Dic: dic1,
                          controller2Dic: dic2,
                          selectedSpindles: spindleStateTopics
                        })
                      }
                  }
                });                              
                this.fetchSatStatusPerDay(null, null,null, endLink)
              }
            
          }catch (e){
            console.log(e)
          }
          
          
        }else{
          console.log('no date')
        }
        
      }

    
      render() {
        const { classes } = this.props;
        const { 
                testData,
                testDataTable,
                mainData,
                mainDataDic1,
                mainDataDic2,
                controllerCheck1,
                controllerCheck2,
                checboxState,
                controller1Dic,
                controller2Dic,
                loading,
                partIds } = this.state;


        return (
          <div style={{height:'100vh'}}>
            <div className={classes.breadcrumbCSS}>
              <Breadcrumbs  separator="›" aria-label="breadcrumb">
                <Typography className={classes.fontStyles} style={{color:'#757575'}}>{this.props.selectedOrganization.organization_name}</Typography>
                <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/assets" >
                  Assets
                </Link>
                <Typography className={classes.fontStyles} color="textPrimary">
                  SAT-Bechtel-Dashboard
                  </Typography>
              </Breadcrumbs>
            </div>
            <div className={classes.filtersSection} >
              <div style={{margin:'8px'}}>
                <DateRangePicker
                  style={{ maxWidth: '300px', minWidth:'200px' }}
                  placeholder="Filter by Date"
                  showOneCalendar
                  preventOverflow
                  ranges={[]}
                  disabledDate={afterToday()}
                  // onExit={(value) => console.log('value onExit',value)}
                  // onSelect={(value) => console.log('value Select',value)}
                  // onChange={(value) => console.log('value change',value)}
                  onOk={this.handleSelectedDate}
                  onClean={this.handleClearDate}
                />
              </div>
              <div style={{margin:'8px'}}>
                <PartIdSelection partIds={partIds} handlePartIdCallback={this.handlePartIdCallback} />
              </div>
            </div>
              
              <Card className={classes.cardRoot} style={{width:'100%', margin:'0px'}} variant="outlined">                
                <CardContent style={{display:'flex',flexDirection:'column', padding:'0px'}}>
                  <div style={{fontSize:'2em', fontWeight:'900', color:'#94a3b8',textAlign:'center'}}>TOTAL</div>
                  <Grid 
                      direction={{ xs: "row"}}
                      justifyContent={{ xs: "center", md:'space-around'}}
                      alignItems="center" 
                      container 
                    >
                      <Grid item xs={12} sm={4}>
                        {testData && testData.counts && testData.counts.total.nok &&
                          <div style={{display: 'flex', flexDirection:'column', margin:'0px',textAlign:'center'}}>
                            <div style={{fontSize:'40px', fontWeight:'600', color:'#64748b'}}>{testData.counts.total.ok}</div>
                            <div style={{fontSize:'16px', fontWeight:'400', color:'#64748b'}}>OK</div>
                          </div>
                        }
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        {testData && testData.counts && testData.counts.total.nok &&
                          <div style={{display: 'flex', flexDirection:'column', margin:'0px',textAlign:'center'}}>
                            <div style={{fontSize:'40px', fontWeight:'600', color:'#ef4444'}}>{testData.counts.total.nok}</div>
                            <div style={{fontSize:'16px', fontWeight:'400', color:'#ef4444'}}>NOK</div>
                          </div>
                        }
                      </Grid>
                    <Grid item xs={12} sm={4}>
                    {testData && testData.counts && testData.counts.total.ratio &&
                      <div style={{display: 'flex', flexDirection:'column', margin:'auto 0px auto 0px',textAlign:'center'}}>
                        <div style={{fontSize:'48px', fontWeight:'700', color:'rgb(129, 181, 229)'}}>{this.getPercentage(testData.counts.total.ratio)}</div>
                      </div>
                    }
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

            {/* Controller 1 paramters */}
            {Object.keys(controller1Dic).length > 0 && 
            <div style={{display:'flex', flexDirection:'column',margin:'8px 0px', backgroundColor:'transparent', borderRadius:'20px'}}>
            {/* <div style={{display:'flex', flexDirection:'column',margin:'8px 0px', backgroundColor:'#fff', borderRadius:'20px', boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1), 0px -4px 15px -2px rgba(0,0,0,0.1)',}}> */}
              <div style={{margin:'auto 0px'}}>
                <Card className={classes.cardRoot} style={{width:'fit-content', marginBottom:'0px'}} variant="outlined">
                  <CardContent style={{display:'flex', padding:'0px', position:'relative'}}>
                    {/* <div style={{position:'absolute', top:0, right:0}}>
                      <Checkbox
                        checked={controllerCheck1}
                        onChange={this.handleControllerCheck1}
                        sx={{ color: '#9ca3af',
                            '&.Mui-checked': {
                              color: '#93c5fd',
                            },
                            '& .MuiSvgIcon-root': { fontSize: 18 } }}
                      />
                    </div> */}
                      <div style={{display:'flex'}}>
                        <div style={{fontSize:'1em', fontWeight:'700', color:'#94a3b8', width:'130px', padding:'8px'}}>CONTROLLER 1</div>
                          <div>
                            <Checkbox
                              checked={controllerCheck1}
                              onChange={this.handleControllerCheck1}
                              sx={{ color: '#9ca3af',
                                  '&.Mui-checked': {
                                    color: '#93c5fd',
                                  },
                                  '& .MuiSvgIcon-root': { fontSize: 18 } }}
                            />
                          </div>
                      </div>  
                  </CardContent>
                </Card>
              </div>
              <div style={{display:'flex', overflow:'scroll'}}>
                {Object.keys(controller1Dic).map(currentSpindle => (
                  // adding this currentSpindle != "testID" in order have react re-render the page but I don't want to show it
                  currentSpindle != "testID" &&
                  <div key={currentSpindle}>
                    <Card  key={currentSpindle} className={classes.cardRootMini} style={{width:'fit-content'}} variant="outlined">
                      <CardContent key={currentSpindle} style={{display:'flex', padding:'8px 32px', position:'relative'}}>
                        <div key={currentSpindle} style={{position:'absolute', top:0, right:0}}>
                          <Checkbox
                            key={currentSpindle}
                            checked={controller1Dic[currentSpindle].checkBoxState}
                            onChange={(event) => this.handleCheckbox1(currentSpindle, event)}
                            sx={{ color: '#9ca3af',
                                '&.Mui-checked': {
                                  color: '#93c5fd',
                                },
                                '& .MuiSvgIcon-root': { fontSize: 18 } }}
                          />
                        </div>
                          <div style={{display:'flex', flexDirection:'column'}}>
                            <div key={currentSpindle} style={{fontSize:'1em', fontWeight:'400', color:'rgb(107, 114, 128)', width:'240px'}}>{controller1Dic[currentSpindle].name}</div>
                            <Grid container spacing={1}>
                              <Grid item xs={4}>
                              {controller1Dic[currentSpindle].ok &&
                                <div style={{display: 'flex', flexDirection:'column', margin:'8px 0px 0px 0px'}}>
                                  <div key={currentSpindle} style={{fontSize:'24px', fontWeight:'700', color:'#64748b'}}>{controller1Dic[currentSpindle].ok}</div>
                                  <div style={{fontSize:'12px', fontWeight:'400', color:'#64748b'}}>OK</div>
                                </div>
                              }
                              
                              </Grid>
                              <Grid item xs={4}>
                              {controller1Dic[currentSpindle].nok &&
                                <div style={{display: 'flex', flexDirection:'column', margin:'8px 0px 0px 0px'}}>
                                  <div key={currentSpindle} style={{fontSize:'24px', fontWeight:'700', color:'#ef4444'}}>{controller1Dic[currentSpindle].nok}</div>
                                  <div style={{fontSize:'12px', fontWeight:'400', color:'#ef4444'}}>NOK</div>
                                </div>
                              }
                              </Grid>
                              <Grid item xs={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                              {controller1Dic[currentSpindle].ratio && 
                                <div >
                                  <div key={currentSpindle} style={{fontSize:'24px', fontWeight:'700', color:'rgb(129, 181, 229)'}}>{this.getPercentage(controller1Dic[currentSpindle].ratio)}</div>
                                </div>
                              }
                              </Grid>
                            </Grid>
                          </div>  
                      </CardContent>
                    </Card>
                    </div>
                ))}
              </div>
            </div>
            }

            {/* Controller 2 parameters */}
            {Object.keys(controller2Dic).length > 0 && 
            <div style={{display:'flex', flexDirection:'column',margin:'8px 0px', backgroundColor:'transparent', borderRadius:'20px'}}>
              <div style={{margin:'auto 0px'}}>
                <Card className={classes.cardRoot} style={{width:'fit-content', marginBottom:'0px'}} variant="outlined">
                    <CardContent style={{display:'flex', padding:'0px', position:'relative'}}>
                      <div style={{display:'flex'}}>
                        <div style={{fontSize:'1em', fontWeight:'700', color:'#94a3b8', width:'130px', padding:'8px'}}>CONTROLLER 2</div>
                          <div>
                            <Checkbox
                              checked={controllerCheck2}
                              onChange={this.handleControllerCheck2}
                              sx={{ color: '#9ca3af',
                                  '&.Mui-checked': {
                                    color: '#93c5fd',
                                  },
                                  '& .MuiSvgIcon-root': { fontSize: 18 } }}
                            />
                          </div>
                      </div>   
                  </CardContent>
                </Card>
              </div>
              <div style={{display:'flex', overflow:'scroll'}}>
                {Object.keys(controller2Dic).map(currentSpindle => (
                   // adding this currentSpindle != "testID" in order have react re-render the page but I don't want to show it
                   currentSpindle != "testID" &&
                  <div key={currentSpindle}>
                    <Card  key={currentSpindle} className={classes.cardRootMini} style={{width:'fit-content'}} variant="outlined">
                      <CardContent key={currentSpindle} style={{display:'flex', padding:'8px 32px', position:'relative'}}>
                        <div key={currentSpindle} style={{position:'absolute', top:0, right:0}}>
                          <Checkbox
                            key={currentSpindle}
                            checked={controller2Dic[currentSpindle].checkBoxState}
                            onChange={(event) => this.handleCheckbox2(currentSpindle, event)}
                            sx={{ color: '#9ca3af',
                                '&.Mui-checked': {
                                  color: '#93c5fd',
                                },
                                '& .MuiSvgIcon-root': { fontSize: 18 } }}
                          />
                        </div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                          <div key={currentSpindle} style={{fontSize:'1em', fontWeight:'400', color:'rgb(107, 114, 128)', width:'240px'}}>{controller2Dic[currentSpindle].name}</div>
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                            {controller2Dic[currentSpindle].ok &&
                              <div style={{display: 'flex', flexDirection:'column', margin:'8px 0px 0px 0px'}}>
                                <div key={currentSpindle} style={{fontSize:'24px', fontWeight:'700', color:'#64748b'}}>{controller2Dic[currentSpindle].ok}</div>
                                <div style={{fontSize:'12px', fontWeight:'400', color:'#64748b'}}>OK</div>
                              </div>
                            }
                            </Grid>
                            <Grid item xs={4}>
                            {controller2Dic[currentSpindle].nok &&
                              <div style={{display: 'flex', flexDirection:'column', margin:'8px 0px 0px 0px'}}>
                                <div key={currentSpindle} style={{fontSize:'24px', fontWeight:'700', color:'#ef4444'}}>{controller2Dic[currentSpindle].nok}</div>
                                <div style={{fontSize:'12px', fontWeight:'400', color:'#ef4444'}}>NOK</div>
                              </div>
                            }
                            </Grid>
                            <Grid item xs={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                              {controller2Dic[currentSpindle].ratio &&
                                <div >
                                  <div key={currentSpindle} style={{fontSize:'24px', fontWeight:'700', color:'rgb(129, 181, 229)'}}>{this.getPercentage(controller2Dic[currentSpindle].ratio)}</div>
                                </div>
                              }
                            </Grid>
                          </Grid>
                        </div>  
                      </CardContent>
                    </Card>
                    </div>
                ))}
              </div>
            </div>
            }
            <Card className={classes.cardRoot} style={{ padding:'8px'}} variant="outlined">
                <CardContent style={{padding:'0px'}} >
                  <IoTFlowsHeatmap data={this.state.sat_status_per_day}/>
                </CardContent>
            </Card>  
            <div>
              <TableSATWidget loading={loading} testDataTable={testDataTable} />
            </div>          
            
          </div>
        );
      }
}


const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{ withTheme: true })(withRouter(DashboardSATBechtel)));




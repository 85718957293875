import React from 'react';
import { withStyles} from '@material-ui/core/styles';
import {getData, postData, putData, deleteData} from '../ApiCalls/DataApis';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  tableContainer: {
    borderRadius: '10px',
  },
  table: {
    minWidth: 400,
  },
  truePaidCSS: {
    borderRadius: '4px',
    backgroundColor: 'rgb(203, 244, 201)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  falsePaidCSS: {
    borderRadius: '5px',
    backgroundColor: 'rgb(209, 209, 209)',
    height: '18px',
    padding: '1px 6px',
    textAlign: 'center',
  },
  headersCSS: {
    fontSize: '24px',
    fontFamily:'Trebuchet MS',
  }
});


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 500,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);


class UpcomingInvoicePage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      upcoming_invoice: [],
      line_items: [],
      line_items_this_month: [],
      line_items_next_month: [],
      next_month_start_date: '',
      next_month_end_date: '',
    }
  }

  componentDidMount() {
      
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/upcoming_invoice')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(data){
            this.setState({
              upcoming_invoice: data,
            }, ()=> {
              // calling the GET function inside of call back to ensure that upcoming invoice state is set
              getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/upcoming_invoice_line_items')
                .then(async response => {
                  if(response.ok ){
                    const data = await response.json();
                    if(data.data){
                      this.setState({
                        line_items: data.data,
                      });
                    }
                  }
                });
            });
          }
        }
      });

    

  }

  componentDidUpdate(prevProps, prevState) {

    if((this.state.upcoming_invoice.period_end != undefined) && (prevState.line_items != this.state.line_items)){
      var current_month_items = [];
      var next_month_items = [];
      var next_month_items_no_zeros = [];
      var current_month_items_no_zeros = [];
      for(var i=0; i<Object.keys(this.state.line_items).length; i++){
        if( this.state.line_items[i].period.end <= this.state.upcoming_invoice.period_end){
          current_month_items[i] = this.state.line_items[i];
          if(current_month_items[i].amount){
            current_month_items_no_zeros[i] = current_month_items[i];
          }
        }else {
          next_month_items[i] = this.state.line_items[i];
          if(next_month_items[i].amount){
            next_month_items_no_zeros[i] = next_month_items[i];
          }
        }
      }

      this.setState({
        line_items_this_month: current_month_items_no_zeros.reverse(),
        line_items_next_month: next_month_items_no_zeros.reverse(),
      }, ()=>{
          if(this.state.line_items_next_month[0]){
            this.setState({
              next_month_start_date: this.state.line_items_next_month[0].period.start,
              next_month_end_date: this.state.line_items_next_month[0].period.end,
            })
          }
        
      });
    }
    
  }
  
  convertCost(price) {
    if(price){
      var stringvalue = '';
      if(price < 0) {
        stringvalue = '-$' + Math.abs(price/100)
      }  else{
        stringvalue = '$' + price /100
      }  
      return stringvalue;
    }else {
      return '$0';
    }
  }

  convertStringCost(price) {
    if(price){
      var priceToInt = parseInt(price)
      if(priceToInt == 0){
        priceToInt = parseFloat(price)
      }
      
      priceToInt = priceToInt /100;
      return '$'+priceToInt;
    }
  }

  convertStringCostTiered(row) {
    if(row){
      var price;
      var description = row.description
      if(description.includes('$0.00006')){
        price = 0.00006;
      }
      else if(description.includes('$0.00005')){
        price = 0.00005;
      }
      else if(description.includes('$0.00004')){
        price = 0.00004;
      }
      else if(description.includes('$0.00003')){
        price = 0.00003;
      }else {
        return ''
      }

      return '$'+ price;
    }
  }

  convertFinalCosts(amount){
    if(amount){
      var price = this.convertCost(amount)
      return price
    } else {
      return "$0.00"
    }
  }

  dateConverter(date){
    // date.toLocaleDateString() + ', ' + date.toLocaleTimeString();
    if(date != undefined){
      var modifyDate = date*1000;
      modifyDate = new Date(modifyDate);
      return modifyDate.toLocaleDateString([], {  month: 'long', day: 'numeric' });
    } else {
      return " "
    }
  }
  billingCycleDateConverter(start_date, end_date){
    if(start_date != undefined){
      var modifyStartDate = start_date*1000;
      var modifyEndDate = end_date*1000;
      modifyStartDate = new Date(modifyStartDate);
      modifyEndDate = new Date(modifyEndDate);
      modifyStartDate = modifyStartDate.toLocaleDateString([], {  month: 'long', day: 'numeric' });
      modifyEndDate = modifyEndDate.toLocaleDateString([], {  year: 'numeric', month: 'long', day: 'numeric' });
      return (modifyStartDate + ' - ' + modifyEndDate)
    } else {
      return " "
    }
  }

  render(){
    const { classes} = this.props
    const {upcoming_invoice, 
      line_items, 
      line_items_this_month, 
      line_items_next_month, 
      next_month_start_date, 
      next_month_end_date} = this.state

      // console.log('line_items_this_month')
      // console.log(line_items_this_month)
    return (
      <div className={classes.root}>
        <TableContainer component={Paper} style={{marginBottom: '50px'}}>
          <Table className={classes.table} aria-label="spanning table">
            <colgroup>
              <col style={{width:'50%'}}/>
              <col style={{width:'15%'}}/>
              <col style={{width:'15%'}}/>
              <col style={{width:'20%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{fontSize: '24px', borderBottom: 'none', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={4}>
                  <b>Upcoming invoice</b>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell style={{fontSize: '12px', color:'rgb(60, 66, 87)', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={4}>
                  This is a preview of the invoice that will be billed on {this.dateConverter(upcoming_invoice.period_end)}. It may change if the subscription is updated.
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell style={{paddingLeft: '12px'}}>DESCRIPTION</StyledTableCell>
                <StyledTableCell align="right">QTY</StyledTableCell>
                <StyledTableCell align="right">UNIT PRICE</StyledTableCell>
                <StyledTableCell style={{paddingRight: '12px'}} align="right">AMOUNT</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {upcoming_invoice.period_start && upcoming_invoice.period_end?
              <TableRow>
                <StyledTableCell style={{fontSize: '12px', color:'rgb(105, 115, 134)', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={4}>
                  {this.billingCycleDateConverter(upcoming_invoice.period_start, upcoming_invoice.period_end)}
                </StyledTableCell>
              </TableRow>
              :null}
              {line_items_this_month.map((row) => (
                  <TableRow key={row.id} >
                    <StyledTableCell style={{paddingLeft: '12px'}}>{row.description}</StyledTableCell>
                    <StyledTableCell align="right">{row.quantity}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.price.product == 'prod_HrbX8g4tbaDC9f' ?
                        this.convertStringCostTiered(row)
                        :
                        this.convertStringCost(row.price.unit_amount_decimal? row.price.unit_amount_decimal : null)
                      }
                    </StyledTableCell>
                    <StyledTableCell style={{paddingRight: '12px'}} align="right">{this.convertCost(row.amount? row.amount:null)}</StyledTableCell>
                  </TableRow>
                
              ))}
              {next_month_start_date && next_month_end_date ? 
                <TableRow>
                  <StyledTableCell style={{fontSize: '12px', color:'rgb(105, 115, 134)', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={4}>
                    {this.billingCycleDateConverter(next_month_start_date, next_month_end_date)}
                  </StyledTableCell>
                </TableRow>
              :null}
              {line_items_next_month.map((row) => (
                <TableRow key={row.id}>
                  <StyledTableCell style={{paddingLeft: '12px'}}>{row.description}</StyledTableCell>
                  <StyledTableCell align="right">{row.quantity}</StyledTableCell>
                  <StyledTableCell align="right">{this.convertStringCost(row.price.unit_amount_decimal)}</StyledTableCell>
                  <StyledTableCell style={{paddingRight: '12px'}} style={{paddingRight: '12px'}} align="right">{this.convertCost(row.amount? row.amount : null)}</StyledTableCell>
                </TableRow>
              ))}
              <TableRow>
                <StyledTableCell colSpan={2}/>
                <StyledTableCell style={{textAlign: 'end'}} >Subtotal</StyledTableCell>
                <StyledTableCell style={{paddingRight: '12px'}} align="right">{this.convertFinalCosts(upcoming_invoice.subtotal? upcoming_invoice.subtotal : null)}</StyledTableCell>
              </TableRow>
              {!upcoming_invoice.discount ? null:
                  <TableRow>
                    <StyledTableCell colSpan={1} />
                    {upcoming_invoice.discount.coupon.percent_off != null ?
                    <StyledTableCell colSpan={2}  style={{color: '#697386', textAlign: 'end'}} >{`${upcoming_invoice.discount.coupon.name} (%${upcoming_invoice.discount.coupon.percent_off? upcoming_invoice.discount.coupon.percent_off : null})`}</StyledTableCell>
                    :
                    <StyledTableCell colSpan={2}  style={{color: '#697386', textAlign: 'end'}} >{`${upcoming_invoice.discount.coupon.name} (${this.convertCost(upcoming_invoice.discount.coupon.amount_off? upcoming_invoice.discount.coupon.amount_off: null)})`}</StyledTableCell>
                    }
                    <StyledTableCell style={{paddingRight: '12px'}} align="right">{this.convertFinalCosts(upcoming_invoice.total_discount_amounts[0].amount)}</StyledTableCell>
                  </TableRow>
              }
              <TableRow>
              <StyledTableCell colSpan={2}/>
                <StyledTableCell style={{textAlign: 'end'}} >Total</StyledTableCell>
                <StyledTableCell style={{paddingRight: '12px'}} align="right">{this.convertFinalCosts(upcoming_invoice.total? upcoming_invoice.total: null)}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan={2}/>
                <StyledTableCell style={{textAlign: 'end'}} >Amount due</StyledTableCell>
                <StyledTableCell style={{paddingRight: '12px'}} align="right">{this.convertFinalCosts(upcoming_invoice.amount_due? upcoming_invoice.amount_due: null)}</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        
      </div>
      )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(UpcomingInvoicePage));
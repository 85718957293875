import React from 'react';
import { withStyles, makeStyles, useTheme  } from '@material-ui/core/styles';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Row from 'react-bootstrap/Row';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {getData} from '../ApiCalls/DataApis';
import MqttService from '../core/services/MqttService';
import PaymentModal from './PaymentModal';
import BillingOverviewPage from './BillingOverviewPage';
// import ReservedDataPage from './ReservedDataPage';
import YourPaymentsPage from './YourPaymentsPage';
import UpcomingInvoicePage from './UpcomingInvoicePage';
import PastInvoicesPage from './PastInvoicesPage';
import {Redirect, Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{padding: '24px 0px'}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const BillingTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
    borderBottom: '0px solid #e8e8e8',
    // backgroundColor: 'transparent',
    outline: 'none',
    borderRadius: '10px',

  },
  indicator: {
    backgroundColor: '#4f79ce',
  },
})(Tabs);

const BillingTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    borderRadius: '10px',
    outline: 'none',
    fontFamily: [
      'Trebuchet MS',
    ].join(','),
    '&:hover': {
      color: '#4f86ce',
      opacity: 1,
      outline: 'none',
    },
    '&$selected': {
      color: '#4f79ce',
      fontWeight: theme.typography.fontWeightMedium,
      outline: 'none',
    },
    '&:focus': {
      color: '#4f86ce',
      outline: 'none',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '3px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
  },
});


class BillingPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      value: 0,
    }
  }

  componentDidMount() {
    if(this.props.selectedOrganization.organization_is_suspended){
      console.log('organization is SUSPENDED')
      this.setState({value: 0} );
    } else {
      this.setState({value: 0} );
    }
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/payment_methods/card')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if (data.data == '' && !this.props.selectedOrganization.organization_is_personal) {
            this.setState({value: 0} );
          } else {
            this.setState({value: 0} );
          }
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevProps.selectedOrganization.organization_uuid != this.props.selectedOrganization.organization_uuid){

      // After a different organization is selected, ensuring account is not suspended and has valid payment
      if(this.props.selectedOrganization.organization_is_suspended){
        console.log('organization is SUSPENDED')
        this.setState({value: 0} );
      } else {
        this.setState({value: 0} );
      }
      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/payment_methods/card')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if (data.data == '' && !this.props.selectedOrganization.organization_is_personal) {
            this.setState({value: 0} );
          } else {
            this.setState({value: 0} );
          }
        }
      });
    }
  }

  
  handleChange = (event, newValue) => {
    this.setState({value: newValue});
  };

  handleChangeIndex = (index) => {
    this.setState({value: index});
  };

  render(){
    const { classes, theme} = this.props
    const {value} = this.state

  

    return (
      <div className={classes.root}>
        <div className={classes.breadcrumbCSS}>
          <Breadcrumbs  separator="›" aria-label="breadcrumb">
            {/* <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/" >
              {this.props.selectedOrganization.organization_name}
            </Link> */}
            <Typography className={classes.fontStyles} style={{color:'#757575'}}>{this.props.selectedOrganization.organization_name}</Typography>
            {/* <Link color="inherit" href="/projects" >
              Projects
            </Link> */}
            <Typography className={classes.fontStyles} color="textPrimary">Billing</Typography>
          </Breadcrumbs>
        </div>

        <div>
          <h4 style={{fontFamily:'Trebuchet MS'}}>Billing and Cost Management</h4>
        </div>

        <AppBar position="static" color="default" style={{borderRadius: '10px'}}>
          <BillingTabs
            value={value}
            onChange={this.handleChange}
            variant="fullWidth"
          >
            {/* <BillingTab label="Reserved Data Plans" {...a11yProps(0)}  /> */}
            <BillingTab label="Your Payments" {...a11yProps(0)}  />
            <BillingTab label="Current Invoice" {...a11yProps(1)}  />
            <BillingTab label="Invoices" {...a11yProps(2)}  />
          </BillingTabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={this.handleChangeIndex}
        >
          {/* <TabPanel value={value} index={0} dir={theme.direction}>
            <ReservedDataPage/>
          </TabPanel> */}
          <TabPanel value={value} index={0} dir={theme.direction}>
            <YourPaymentsPage/>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <UpcomingInvoicePage/>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <PastInvoicesPage/>
          </TabPanel>
        </SwipeableViews>
        
      </div>
        
       
    )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{ withTheme: true })(BillingPage));



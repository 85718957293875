import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import  {putData} from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import {fetchOrganizations} from '../store/actions/organizationActions';
import Form from 'react-bootstrap/Form';
import {CountryCodeJson} from '../MyNetworksPage/CountryCodeJson';


const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class AddWifiNetworkModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      loading: false,
      success: false,
      network_id: '',
      wifi_type: 'Personal',
      selected_country_code: 'US',
      network_name: '',
      network_ssid: '',
      network_password: '',
      identity_username: '',
      identity_password: '',
      disable_fields: true,
      selected_wifi_row_data: [],
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleNetworkNameChange = this.handleNetworkNameChange.bind(this);
  }

  

  timer = undefined;

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
      network_password: '',
      identity_password: '',
       },() => { this.props.handleCloseModal() });
  };


  submitButtonClick = () => {
    // console.log('this.state.network_name')
    // console.log(this.state.network_name)
    // console.log('this.state.network_ssid')
    // console.log(this.state.network_ssid)
    // console.log('this.state.network_password')
    // console.log(this.state.network_password)
    // console.log('this.state.identity_username')
    // console.log(this.state.identity_username)
    // console.log('this.state.wifi_type')
    // console.log(this.state.wifi_type)
    // console.log('this.state.selected_country_code')
    // console.log(this.state.selected_country_code)
    
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          var isValid = false;
          if(this.state.wifi_type == 'Personal'){
            if(this.state.network_name && this.state.network_ssid && this.state.network_password && this.state.selected_country_code && this.state.wifi_type){
              isValid = true
            }
          }
          else if(this.state.wifi_type == 'Enterprise'){
            if(this.state.network_name && this.state.identity_username && this.state.identity_password && this.state.network_ssid && this.state.selected_country_code && this.state.wifi_type){
              isValid = true
            }
          }
          if(isValid){
            // POST Function for creating a wifi network 
            putData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/wifi_networks', 
            { "network_id": this.state.network_id,
              "network_name": this.state.network_name,
              "network_ssid": this.state.network_ssid,
              "network_password": this.state.network_password,
              "identity_username": this.state.identity_username,
              "identity_password": this.state.identity_password,
              "wifi_type": this.state.wifi_type,
              "country_code": this.state.selected_country_code,
            })
              .then(async response => {
                if(response.ok){
                  this.props.showSuccessSnackbar("New wifi network has been successfully added!");
                  this.setState({
                    loading: false,
                    success: true,
                    open:false,
                  }, () => { 
                    this.handleClose();
                    // this.props.handleCloseModal();
                    this.props.refreshWifiList();
                  });
                }
                else{
                  try{
                    const data = await response.json();
                    this.props.showErrorSnackbar(data.message);
                    this.setState({
                      loading: false,
                      success: false,
                      open:false,
                    }, () => { 
                      this.handleClose();
                      this.props.handleCloseModal();
                    });
                  }catch (e){
                    this.props.showErrorSnackbar('Something went wrong');
                  }
                }
              })
          }
          else {
            this.props.showErrorSnackbar("Missing require fields!");
            this.setState({
              loading: false,
              success: false,
              open:false,
            }, () => { 
              this.handleClose();
              this.props.handleCloseModal();
            });
          }
        }
      )
    }
    
  };

  handleWifiNetworkTypeChange = e => {
    this.setState({wifi_type: e.target.value },() => 
    {this.state.wifi_type == 'Personal' ? this.setState({disable_fields: true}): this.setState({disable_fields: false})});
  }
  handleCountryCodeChange = e => {
    this.setState({selected_country_code: e.target.value })
  }
  handleNetworkNameChange = (event) => {
    this.setState({network_name: event.target.value});
  }
  handleNetworkSSIDChange = (event) => {
    this.setState({network_ssid: event.target.value});
  }
  handleNetworkPasswordChange = (event) => {
    this.setState({network_password: event.target.value});
  }
  handleIdentityUsernameChange = (event) => {
    this.setState({identity_username: event.target.value});
  }
  handleIdentityPasswordChange = (event) => {
    this.setState({identity_password: event.target.value});
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.open,
      selected_wifi_row_data: nextProps.selected_wifi_row_data,
      network_id: nextProps.selected_wifi_row_data.network_id,
      wifi_type: nextProps.selected_wifi_row_data.wifi_type,
      selected_country_code: nextProps.selected_wifi_row_data.country_code,
      network_name: nextProps.selected_wifi_row_data.network_name,
      network_ssid: nextProps.selected_wifi_row_data.network_ssid,
      // network_password: nextProps.selected_wifi_row_data.network_password,
      identity_username: nextProps.selected_wifi_row_data.identity_username,
      // identity_password: nextProps.selected_wifi_row_data.identity_password,
    },()=> 
    {this.state.wifi_type == 'Personal' ? this.setState({disable_fields: true}): this.setState({disable_fields: false})});
  }

  

  render() {
    const { classes, selected_wifi_row_data } = this.props;
    const { open, 
            loading, 
            success, 
            wifi_type, 
            disable_fields, 
            network_name,
            network_ssid,
            network_password,
            identity_username,
            identity_password,
            selected_country_code } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
        {/* <Button className={classes.button} variant="outlined"  onClick={this.handleOpen}>Add Organization</Button> */}
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Edit Wi-Fi Network</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '15px', paddingTop: '0px'}}>
                
                <Form>
                <Form.Group controlId="deviceForm.SelectNetwork">
                  <Form.Label> Network Type</Form.Label>
                  <Form.Control as="select" value={wifi_type} onChange={this.handleWifiNetworkTypeChange}>
                    <option>Personal</option>
                    <option>Enterprise</option>
                  </Form.Control>
                </Form.Group>

                
                  <Form.Group controlId="deviceForm.SelectNetwork">
                    <Form.Label style={{paddingTop:'10px'}}>Add Network to Device</Form.Label>
                    <Form.Control as="select" value={selected_country_code} onChange={this.handleCountryCodeChange} >
                      {CountryCodeJson.map( (country,index) =>(
                              // <option key={network.network_id} value={network.network_id}>{country_code}</option>  
                              <option key={index} value={country.country_code}>{country.country_name}</option>  
                          
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form>

                <TextField
                  autoFocus
                  margin="normal"
                  id="network_name"
                  label="Network Nickname"
                  type="text"
                  fullWidth
                  value={network_name} 
                  onChange={this.handleNetworkNameChange}
                  />

                  <TextField 
                  autoFocus
                  margin="normal"
                  id="network_ssid"
                  label="Network SSID"
                  type="text"
                  fullWidth
                  value={network_ssid } 
                  onChange={this.handleNetworkSSIDChange}
                  />

                  

                  <TextField 
                  autoFocus
                  margin="normal"
                  id="network_password"
                  label="Network Password"
                  type="password"
                  fullWidth
                  value={network_password} 
                  onChange={this.handleNetworkPasswordChange}
                  disabled={!disable_fields}
                  />

                  <TextField 
                  autoFocus
                  margin="normal"
                  id="identity_username"
                  label="Identity Username "
                  type="text"
                  fullWidth
                  value={identity_username} 
                  onChange={this.handleIdentityUsernameChange}
                  disabled={disable_fields}
                  />

                  <TextField 
                  autoFocus
                  margin="normal"
                  id="identityPassword"
                  label="Identity Password "
                  type="password"
                  fullWidth
                  value={identity_password} 
                  onChange={this.handleIdentityPasswordChange}
                  disabled={disable_fields}
                  />

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

AddWifiNetworkModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  fetchOrganizations: () => dispatch(fetchOrganizations()),

})
const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AddWifiNetworkModal));
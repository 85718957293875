import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, rgbToHex } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import Tooltip from '@material-ui/core/Tooltip';
import '../styles/emailAddressesList.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import MachinePicEditor from './MachinePicEditor';
import ManufacturerLogoEditor from './ManufacturerLogoEditor';
import Box from '@mui/material/Box';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import IconButton from '@material-ui/core/IconButton';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    maxWidth: theme.spacing(200),
    minWidth: theme.spacing(90),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('md')]:{
      maxWidth: theme.spacing(110),
      minWidth: theme.spacing(80),
    },
    [theme.breakpoints.down('sm')]:{
      maxWidth: theme.spacing(70),
      minWidth: theme.spacing(60),
    },
    [theme.breakpoints.down('xs')]:{
      maxWidth: theme.spacing(43),
      minWidth: theme.spacing(43),
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    borderRadius: 10, 
    maxWidth: theme.spacing(200),
  },
  searchResults: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 300,
    backgroundColor: '#fff',
    padding: '0.5rem',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    inset: '40px auto auto 0px',
    borderRadius: '5px',
    maxHeight: '220px',
    overflow: 'auto',
  },
  resultContainer: {
    width: '100%',
    display: 'flex',
    padding: '16px',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#F3F4F6',
    }
  },
  searchHeaderCSS: {
    fontSize: '16px',
    color: '#1f2937',
    fontWeight: 500,
    fontFamily:'Trebuchet MS',
  },
  searchSubHeaderCSS: {
    fontSize: '12px',
    color: '#6B7280',
    fontStyle: 'italic',
    fontWeight: 300,
    fontFamily:'Trebuchet MS',
  },
  inputContainer:{
    display: 'inline-flex',
    flexGrow: 1,
    width: '100%',
    border: '1px solid #E5E7EB',
    borderRadius:'25px',
    padding: '0px',
    margin: '4px',
    textAlign: 'left',
    backgroundColor: "#fff",
    height: '2.25rem',
    position: 'relative',
    "&:focus": {
      border: '1px solid #60a5fa',
    },
    "&:active": {
      border: '1px solid #60a5fa',
    },
    "&:selected": {
      border: '1px solid #60a5fa',
    },
    "&:target": {
      border: '1px solid #60a5fa',
    }
  },
  searchIconContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
    padding: '0.125rem 0.5rem 0.125rem 0.25rem'
  },
  searchIcon: {
    color: '#6B7280',
    height: '1rem'
  },
  inputCSS: {
    backgroundColor: 'transparent',
    border:'0px solid transparent',
    textAlign: 'left',
    outline: 'none',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    padding: '0.5rem 0.25rem',
    minWidth: '100%',
    color: '#4B5563'
  },
  inputContainers: {
    textAlign: 'left',
    marginLeft: '12px',
    marginTop:'12px',
  },
  createContainers: {
    textAlign: 'center',
    marginTop:'12px',
  },
  newMachineButton: {
    backgroundColor: '#3b82f6',
    color: '#fff',
    border: 'none',
    fontWeight: 500,
    padding: '4px 14px',
    fontSize: '16px',
    letterSpacing: '.025em',
    lineHeight: '28px',
    borderRadius: '4px',
    width: '100%',
    '&:hover': {
        backgroundColor: '#2563eb',
    },
    '&:disabled': {
        opacity: 0.8,
    },
  },
  createManufacturerMachineButton: {
    backgroundColor: 'transparent',
    color: '#3b82f6',
    border: '1px solid #3b82f6',
    fontWeight: 500,
    padding: '4px 14px',
    fontSize: '16px',
    letterSpacing: '.025em',
    lineHeight: '28px',
    borderRadius: '4px',
    width: 'fit-content',
    textTransform: 'capitalize',
    '&:disabled': {
      color: '#d1d5db',
      backgroundColor: 'transparent',
      border: '1px solid #d1d5db',
    },
    '&:hover': {
        color: '#3b82f6',
        backgroundColor: '#eff6ff',
        border: '1px solid #3b82f6',
    },
  },
  createMachineButton: {
    backgroundColor: 'transparent',
    color: '#3b82f6',
    border: '1px solid #3b82f6',
    fontWeight: 500,
    padding: '4px 14px',
    fontSize: '16px',
    letterSpacing: '.025em',
    lineHeight: '28px',
    borderRadius: '4px',
    width: 'fit-content',
    textTransform: 'capitalize',
    '&:disabled': {
      color: '#d1d5db',
      backgroundColor: 'transparent',
      border: '1px solid #d1d5db',
    },
    '&:hover': {
        color: '#3b82f6',
        backgroundColor: '#eff6ff',
        border: '1px solid #3b82f6',
    },
  },
  backButton: {
    backgroundColor: 'transparent',
    borderRadius:'25px',
    outline:'none',
    padding:'4px',
    '&:hover': {
      backgroundColor: '#f3f4f6',
      transform: 'translateY(-2px)',
      outline:'none',
    },
    '&:focus': {
      outline:'none',
    },
  },
  selectedDeviceCSS: {
    display:'flex',
    alignItems:'flex-end',
    marginTop: '12px', 
    fontSize: '20px', 
    color:'#4f87cc', 
    cursor:'pointer',
    '&:hover': {
      color:'rgb(79, 135, 204, 0.8)',
      transform: 'translateY(-3px)',
    },
  },
  assignDeviceCSS: {
    marginTop: '12px', 
    fontSize: '18px', 
    color:'#4b5563', 
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class AddAssetModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      assetName: '',
      assetDescription: '',
      assetIdentifier: '',
      assetHomepage:'',
      currentTag: '',
      assetTags: [],
      machineSearchText: '',
      machineList: [],
      showMachineList: false,
      selectedMachine: [],
      assetImage: '',
      newPicUploaded: false,
      assetImageFile:null,
      newMachineImage:'',
      newMachineImageFile: null,
      createNewMachine: false,
      manufacturerList: [],
      selectedNewMachineManufacturer:[],
      newMachineName: '',
      newMachineDescription: '',
      newMachineIdentifier: '',
      newMachineHomepageUrl: '',
      newManufacturerName: '',
      newManufacturerDescription: '',
      newManufacturerHomepageUrl: '',
      newManufacturerSupportHomepageUrl: '',
      newManufacturerSupportEmail: '',
      newManufacturerSupportPhone: '',
      newManufacturerLogo:'',
      newManufacturerLogoFile: null,
      loadingNewMfgMachine: false,
      loadingNewMachine: false,
      devices_list:[],
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changeNewPic = this.changeNewPic.bind(this);
  }

  componentDidMount() {


    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/devices')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        this.setState({devices_list: data.data})

      }
    });

    // Get list of machines
    getData('https://api.iotflows.com/v1/machines')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            machineList: data.data,
          })
        }
      }
    });

    // Get list of manufacturer
    getData('https://api.iotflows.com/v1/manufacturers')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            manufacturerList: data.data,
          })
        }
      }
    });
  }

  refreshManufacturers() {
    // Get list of manufacturer
    getData('https://api.iotflows.com/v1/manufacturers')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            manufacturerList: data.data,
          })
        }
      }
    });

  }


  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
      assetName: '',
      assetDescription: '',
      assetIdentifier: '',
      assetHomepage:'',
      showMachineList: false,
      machineSearchText: '',
      selectedMachine: [],
      assetImage: '',
      newPicUploaded: false,
      assetImageFile:null,
      createNewMachine: false,
      newMachineImage: '',
      newMachineImageFile: null,
      selectedNewMachineManufacturer:[],
      newMachineName: '',
      newMachineDescription: '',
      newMachineIdentifier: '',
      newMachineHomepageUrl: '',
      newManufacturerName: '',
      newManufacturerDescription: '',
      newManufacturerHomepageUrl: '',
      newManufacturerSupportHomepageUrl: '',
      newManufacturerSupportEmail: '',
      newManufacturerSupportPhone: '',
      newManufacturerLogo:'',
      newManufacturerLogoFile: null,
      loadingNewMfgMachine: false,
      loadingNewMachine: false,

    });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
      selectedDevice:[],
      assetName: '',
      assetDescription: '',
      assetIdentifier: '',
      assetHomepage:'',
      showMachineList: false,
      machineSearchText: '',
      selectedMachine: [],
      assetImage: '',
      newPicUploaded: false,
      assetImageFile:null,
      createNewMachine: false,
      newMachineImage: '',
      newMachineImageFile: null,
      selectedNewMachineManufacturer:[],
      newMachineName: '',
      newMachineDescription: '',
      newMachineIdentifier: '',
      newMachineHomepageUrl: '',
      newManufacturerName: '',
      newManufacturerDescription: '',
      newManufacturerHomepageUrl: '',
      newManufacturerSupportHomepageUrl: '',
      newManufacturerSupportEmail: '',
      newManufacturerSupportPhone: '',
      newManufacturerLogo:'',
      newManufacturerLogoFile: null,
      loadingNewMfgMachine: false,
      loadingNewMachine: false,
    });
  };
  submitButtonClick = () => {
    const { loading, selectedMachine, assetName, assetDescription, assetIdentifier, assetHomepage, assetImage, newPicUploaded } = this.state;
    
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          var tempMachineInfo={'machine_uuid': selectedMachine.machine_uuid};
          if(selectedMachine.machine_name != assetName){
            tempMachineInfo.asset_custom_name = assetName;
          }
          if(selectedMachine.machine_description != assetDescription){
            tempMachineInfo.asset_custom_description = assetDescription;
          }
          if(selectedMachine.machine_identifier != assetIdentifier){
            tempMachineInfo.asset_custom_identifier = assetIdentifier;
          }
          if(selectedMachine.machine_homepage_url != assetHomepage){
            tempMachineInfo.asset_custom_homepage_url = assetHomepage;
          }if(newPicUploaded){
            tempMachineInfo.asset_custom_picture = assetImage;
          }
          // console.log('tempMachineInfo')
          // console.log(tempMachineInfo)
        postData('https://api.iotflows.com/v1/devices/' + this.state.selectedDevice.device_uuid + '/assets', tempMachineInfo)
          .then(async response => {
            if(response.ok){
              // const data = await response.json();
              this.props.showSuccessSnackbar("Asset has been successfully added!");
              this.props.refreshAssetList();
              this.handleClose();
              this.setState({
                loading: false,
                success: true,
                open:false,
              });
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false});
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  handleDelete = tag => {
    this.setState({
      assetTags: this.state.assetTags.filter(i => i !== tag)
    });
  };

  handleAssetTagsChange = (event) => {
    this.setState({currentTag: event.target.value});
  }

  handleKeyDownTags = async evt => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();

      var currentTag = this.state.currentTag.trim();

      this.setState({
        assetTags: [...this.state.assetTags, this.state.currentTag],
        currentTag: ""
      });
    }
  };

  handleBlur = async event => {
    if (this.state.currentTag) {
      event.preventDefault();

      var currentTag = this.state.currentTag.trim();
      this.setState({
        assetTags: [...this.state.assetTags, this.state.currentTag],
        currentTag: ""
      });

    }

  }

  handleChangeMachineSearchText = (e) => {
    this.setState({machineSearchText: e.target.value})
  }

  handleSelectMachine = (machine) => {
    this.setState({
      selectedMachine: machine,
      showMachineList: false,
    })
    this.setState({
      assetName: machine.machine_name,
      assetDescription: machine.machine_description,
      assetIdentifier: machine.machine_identifier,
      assetHomepage: machine.machine_homepage_url,
      assetImage: machine.machine_picture_url,

    })
  }

  changeNewPic = (image, imageFile, isEdit) => {
    if(isEdit){
      this.setState({
        assetImage: image,
        assetImageFile:imageFile,
        newPicUploaded: true
      })
    }else {
      this.setState({
        newMachineImage: image,
        newMachineImageFile:imageFile,
      })
    }
    
  }

  changeNewManufacturerLogo = (image, imageFile) => {
    this.setState({
      newManufacturerLogo: image,
      newManufacturerLogoFile:imageFile
    })
  }

  // Create new machine functions
  handledCreateNewMachine = () => {
    this.setState({
      createNewMachine: true,
      selectedMachine: [],
      newMachineImage: '',
      newMachineImageFile: null,
      selectedNewMachineManufacturer:[],
      newMachineName: '',
      newMachineDescription: '',
      newMachineIdentifier: '',
      newMachineHomepageUrl: '',
      newManufacturerName: '',
      newManufacturerDescription: '',
      newManufacturerHomepageUrl: '',
      newManufacturerSupportHomepageUrl: '',
      newManufacturerSupportEmail: '',
      newManufacturerSupportPhone: '',
      newManufacturerLogo:'',
      newManufacturerLogoFile: null,
      loadingNewMfgMachine: false,
      loadingNewMachine: false,
    })
  }

  handleSelectManufacturer = (e) => {
    this.setState({
      selectedNewMachineManufacturer: e.target.value,
      newMachineName:'',
      newMachineDescription:'',
      newMachineIdentifier:'',
      newMachineHomepageUrl:'',
      newMachineImage:'',
      newMachineImageFile:null,
      newManufacturerName:'',
      newManufacturerDescription:'',
      newManufacturerHomepageUrl:'',
      newManufacturerSupportHomepageUrl: '',
      newManufacturerSupportEmail: '',
      newManufacturerSupportPhone:'',
      newManufacturerLogo:'',
      newManufacturerLogoFile: null
    })
  }


  handleNewMachineNameChange = (e) => {
    this.setState({newMachineName: e.target.value})
  }

  handleNewMachineDescriptionChange = (e) => {
    this.setState({newMachineDescription: e.target.value})
  }

  handleNewMachineIdentifierChange = (e) => {
    this.setState({newMachineIdentifier: e.target.value})
  }

  handleNewMachineHomepageURLChange = (e) => {
    this.setState({newMachineHomepageUrl: e.target.value})
  }

  handleNewManufacturerNameChange = (e) => {
    this.setState({newManufacturerName: e.target.value})
  }
  handleNewManufacturerDescriptionChange = (e) => {
    this.setState({newManufacturerDescription: e.target.value})
  }
  handleNewManufacturerHomepageURLChange = (e) => {
    this.setState({newManufacturerHomepageUrl: e.target.value})
  }
  handleNewManufacturerSupportHomepageURLChange = (e) => {
    this.setState({newManufacturerSupportHomepageUrl: e.target.value})
  }
  handleNewManufacturerSupportEmailChange = (e) => {
    this.setState({newManufacturerSupportEmail: e.target.value})
  }
  handleNewManufacturerSupportPhoneChange = (e) => {
    this.setState({newManufacturerSupportPhone: e.target.value})
  }

  backToSelectMachine = () => {
    this.setState({createNewMachine: false})
  }


  handledAddNewManufacturerMachine = () => {
    
  }
  handledAddNewManufacturerMachine = () => {
    const { loadingNewMfgMachine, 
            selectedNewMachineManufacturer, 
            newMachineName, 
            newMachineDescription, 
            newMachineIdentifier, 
            newMachineImageFile, 
            newMachineHomepageUrl,
            newManufacturerName,
            newManufacturerDescription,
            newManufacturerHomepageUrl,
            newManufacturerSupportHomepageUrl,
            newManufacturerSupportEmail,
            newManufacturerSupportPhone,
            newManufacturerLogo,
            newMachineImage} = this.state;
    
    if (!loadingNewMfgMachine) {
      this.setState(
        {
          loadingNewMfgMachine: true,
        },
        () => {
          let newManufacturerMachineInfo ={
            "manufacturer_name": newManufacturerName,
            "manufacturer_description": newManufacturerDescription,
            "manufacturer_logo_picture": newManufacturerLogo,
            "manufacturer_homepage_url": newManufacturerHomepageUrl,
            "manufacturer_support_homepage_url": newManufacturerSupportHomepageUrl,
            "manufacturer_support_email": newManufacturerSupportEmail,
            "manufacturer_support_phone": newManufacturerSupportPhone,
            "machine_name": newMachineName,
            "machine_description": newMachineDescription,
            "machine_identifier": newMachineIdentifier,
            "machine_picture": newMachineImage,
            "machine_homepage_url": newMachineHomepageUrl,
            }
        postData('https://api.iotflows.com/v1/manufacturers_machines',newManufacturerMachineInfo)
          .then(async response => {
            if(response.ok){
              const data = await response.json();

              this.setState({
                loadingNewMfgMachine: false,
                createNewMachine: false,
              })
              this.addNewMachineAsAsset(data.data[0].new_machine_uuid)
              this.props.showSuccessSnackbar("Manufacture and Machine have been added");
              
              // clear all items
                this.setState({
                  selectedNewMachineManufacturer: [],
                  newMachineName:'',
                  newMachineDescription:'',
                  newMachineIdentifier:'',
                  newMachineHomepageUrl:'',
                  newMachineImage:'',
                  newMachineImageFile:null,
                  newManufacturerName:'',
                  newManufacturerDescription:'',
                  newManufacturerHomepageUrl:'',
                  newManufacturerSupportHomepageUrl: '',
                  newManufacturerSupportEmail: '',
                  newManufacturerSupportPhone:'',
                  newManufacturerLogo:'',
                  newManufacturerLogoFile: null
                })
              this.refreshManufacturers()
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }

  }
  handledAddNewMachine = () => {
    const { loadingNewMachine, 
            selectedNewMachineManufacturer, 
            newMachineName, 
            newMachineDescription, 
            newMachineIdentifier, 
            newMachineImage, 
            newMachineHomepageUrl } = this.state;
    
    if (!loadingNewMachine) {
      this.setState(
        {
          loadingNewMachine: true,
        },
        () => {
          let newMachineInfo ={
            "machine_name": newMachineName,
            "machine_description": newMachineDescription,
            "machine_identifier": newMachineIdentifier,
            "machine_picture": newMachineImage,
            "machine_homepage_url": newMachineHomepageUrl,
            "manufacturer_uuid": selectedNewMachineManufacturer.manufacturer_uuid
            }
            // console.log('newMachineInfo')
            // console.log(newMachineInfo)
        postData('https://api.iotflows.com/v1/machines',newMachineInfo)
          .then(async response => {
            if(response && response.ok){
              const data = await response.json();
              if(data && data.data){
                this.setState({
                  loadingNewMachine: false,
                  createNewMachine: false,
                })
                this.addNewMachineAsAsset(data.data[0].new_machine_uuid)
                this.props.showSuccessSnackbar("Machine has been added");
                // clear all items
                this.setState({
                  selectedNewMachineManufacturer: [],
                  newMachineName:'',
                  newMachineDescription:'',
                  newMachineIdentifier:'',
                  newMachineHomepageUrl:'',
                  newMachineImage:'',
                  newMachineImageFile:null,
                  newManufacturerName:'',
                  newManufacturerDescription:'',
                  newManufacturerHomepageUrl:'',
                  newManufacturerSupportHomepageUrl: '',
                  newManufacturerSupportEmail: '',
                  newManufacturerSupportPhone:'',
                  newManufacturerLogo:'',
                  newManufacturerLogoFile: null
                })

              }
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }

  }

  addNewMachineAsAsset = (machineUUID) => {
    // Get list of machines
    getData('https://api.iotflows.com/v1/machines')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            machineList: data.data,
          })
          // putting new created machine as the one selected to create and add to device
          let machine_list = data.data
          machine_list.map(machine => {
            if(machine.machine_uuid == machineUUID){
              this.setState({
                selectedMachine: machine,
              })
              this.setState({
                assetName: machine.machine_name,
                assetDescription: machine.machine_description,
                assetIdentifier: machine.machine_identifier,
                assetHomepage: machine.machine_homepage_url,
                assetImage: machine.machine_picture_url,
          
              })
            }
          })
        }
      }
    })
  }

    handleSelectDeviceChange = (event) => {
      this.setState({selectedDevice: event.target.value});
    };

    handleAssetNameChange = (event) => {
      this.setState({assetName: event.target.value});
    }
  
    handleAssetDescriptionChange = (event) => {
      this.setState({assetDescription: event.target.value});
    }
    handleAssetIdentifierChange = (event) => {
      this.setState({assetIdentifier: event.target.value});
    }
  
    handleAssetHomepageChange = (event) => {
      this.setState({assetHomepage: event.target.value});
    }

    handleBackButton = () => {
      this.setState({
        selectedMachine:[]
      })
    }


  render() {
    const { classes, selectedOrganization } = this.props;
    const { open, 
            loading, 
            success, 
            devices_list,
            selectedDevice,
            assetName,
            assetDescription,
            assetIdentifier,
            assetHomepage,
            assetImage,
            currentTag,
            assetTags,
            machineSearchText,
            showMachineList,
            machineList,
            selectedMachine,
            createNewMachine,
            selectedNewMachineManufacturer,
            manufacturerList,
            newMachineName,
            newMachineDescription,
            newMachineIdentifier,
            newMachineHomepageUrl,
            newManufacturerName,
            newManufacturerDescription,
            newManufacturerHomepageUrl,
            newManufacturerSupportHomepageUrl,
            newManufacturerSupportEmail,
            newManufacturerSupportPhone,
            newManufacturerLogo,
            loadingNewMfgMachine,
            loadingNewMachine,
            newMachineImage} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });


    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
      >
        
        <Button className={classes.button} variant="contained"   onClick={this.handleOpen}><AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Add Asset</Button>

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >

                
                    <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Add Asset</DialogTitle>
                    <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px', minHeight:'450px'}}>
                    
                    

                    {selectedDevice && Object.keys(selectedDevice).length > 0 && selectedDevice.device_name && !createNewMachine ?
                      <div className={classes.selectedDeviceCSS} onClick={()=> this.setState({selectedDevice: []})} >
                        <div style={{textTransform: 'uppercase', display: 'flex', fontSize:'15px', lineHeight:'24px', alignItems:'center', fontWeight:600 }}>
                        {selectedDevice.project_name} 
                          &nbsp;
                          <ArrowForwardIosOutlinedIcon sx={{ fontSize: 12, color:'inherit' }} />
                          &nbsp;
                        {selectedDevice.device_name}
                        </div>
                      </div>
                      :
                      <div style={createNewMachine ? {display:'none'} : {}}  >
                        <div className={classes.assignDeviceCSS} >
                          <div style={{textTransform: 'uppercase', fontSize:'15px', lineHeight:'24px',fontWeight:600 }}>
                           Assign asset to a device
                          </div>
                        </div>
                        <FormControl sx={{ m: 1, minWidth: 160, width:'100%' }}>
                          <InputLabel id="demo-simple-select-autowidth-label">Select Device</InputLabel>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            onChange={this.handleSelectDeviceChange}
                            sx={{width:'100%'}}
                            label="Select Device"
                          >
                            {devices_list.map(device =>(
                              <MenuItem sx={{display:'flex'}} key={device.device_uuid} value={device}>
                               {device.project_name}&nbsp;
                               <ArrowForwardIosOutlinedIcon sx={{ fontSize: 14 }} />
                                &nbsp;{device.device_name} 
                                </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    }

                    {selectedDevice && Object.keys(selectedDevice).length > 0 && !createNewMachine && selectedMachine && Object.keys(selectedMachine).length > 0 &&
                      <IconButton style={{marginLeft:'-16px', marginBottom:'-12px'}} onClick={this.handleBackButton} >
                        <ArrowBackIosNewOutlinedIcon sx={{ fontSize: 18 }}/>
                      </IconButton>
                      }

                    {selectedDevice && Object.keys(selectedDevice).length > 0 &&  !createNewMachine && selectedMachine && Object.keys(selectedMachine).length == 0 &&
                      <div  style={{marginBottom:'16px'}}>
                        <div className={classes.inputContainers} >
                            <button 
                                // type="submit"
                                onClick={this.handledCreateNewMachine} 
                                className={classes.newMachineButton} 
                            >
                                Create new machine
                            </button>
                        </div>
                        <div style={{margin:'16px 0px', textAlign:'center'}}>
                          <span style={{fontSize:'17px', fontWeight:600, fontFamily:'Trebuchet MS', color:'#1f2937'}}>Or</span>
                        </div>
                      </div>
                    }
                    
                    
                    {/* Search Asset input */}
                    {selectedDevice && Object.keys(selectedDevice).length > 0 && !createNewMachine && selectedMachine && Object.keys(selectedMachine).length == 0 &&
                    <div className={classes.inputContainer} >
                      <div className={classes.searchIconContainer} >
                          <svg xmlns="http://www.w3.org/2000/svg" className={classes.searchIcon}   fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                          </svg>
                      </div>
                      <input className={classes.inputCSS} value={machineSearchText} onChange={this.handleChangeMachineSearchText} onClick={()=>this.setState({showMachineList: !showMachineList})}  placeholder="Search Machine..." />
                    
                      {Object.keys(machineList).length > 0 && showMachineList &&
                        <div className={classes.searchResults}  >
                          {machineList.map((machine, index) => (
                            <div key={machine.machine_uuid} onClick={() => this.handleSelectMachine(machine)} className={classes.resultContainer}>
                              {/* Machine image */}
                              <div>
                                <Avatar
                                  alt={machine.machine_name}
                                  src={machine.machine_picture_url}
                                  sx={{ width: 50, height: 50 }}
                                  variant="rounded"
                                />
                              </div>
                              <div style={{display:'flex', flexDirection:'column', marginLeft:'8px', justifyContent:'space-between'}}>
                                {/* Name and id of machine */}
                                  <div className={classes.searchHeaderCSS}>
                                  {machine.machine_name}{' - '} <i>{machine.machine_identifier}</i>
                                  </div>
                                  <div className={classes.searchSubHeaderCSS}>
                                  {machine.manufacturer_name}
                                  </div>
                              </div>
                              <div style={{marginLeft:'auto'}}>
                                <Avatar
                                  alt={machine.manufacturer_name}
                                  src={machine.manufacturer_logo_url}
                                  sx={{ width: 40, height: 40 }}
                                  variant="rounded"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      }
                    </div>
                    }
                    {selectedDevice && Object.keys(selectedDevice).length > 0 && selectedMachine && Object.keys(selectedMachine).length > 0 && !createNewMachine &&
                    <>
                      <div style={{justifyContent:'center', margin: '10px 0px 20px 0px', border:'0px solid rgb(255,255,255)'}}>
                        <MachinePicEditor changeNewPic={this.changeNewPic} editMachine={true} machineAvatarPic={assetImage} machinePic={assetImage + String(Math.random())} />
                      </div>

                      {selectedMachine.manufacturer_name && 
                      <div style={{margin:'12px 0px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Avatar
                          alt={selectedMachine.manufacturer_name}
                          src={selectedMachine.manufacturer_logo_url}
                          sx={{ width: 30, height: 30 }}
                          variant="rounded"
                        />
                        <span style={{fontSize:'16px', fontWeight:400,paddingLeft:'8px', fontFamily: "Roboto, Helvetica, Arial, sans-serif", color:'#4b5563',fontStyle:'italic'}}>
                          {selectedMachine.manufacturer_name}
                        </span> 
                      </div>
                      }

                      <TextField
                        style={{marginTop: '0px'}}
                        autoFocus
                        margin="normal"
                        id="assetName"
                        label="Name"
                        type="text"
                        value={assetName} 
                        onChange={this.handleAssetNameChange}
                        fullWidth
                        required
                      />

                      <TextField
                        margin="normal"
                        id="assetDescription"
                        label="Description"
                        type="text"
                        value={assetDescription} 
                        onChange={this.handleAssetDescriptionChange}
                        fullWidth
                      />
                      <TextField
                        margin="normal"
                        id="assetIdentifier"
                        label="Identifier"
                        type="text"
                        value={assetIdentifier} 
                        onChange={this.handleAssetIdentifierChange}
                        fullWidth
                      />
                      <TextField
                        margin="normal"
                        id="assetHomepage"
                        label="Homepage"
                        type="url"
                        value={assetHomepage} 
                        onChange={this.handleAssetHomepageChange}
                        fullWidth
                      />

                      {/* <TextField
                      //   autoFocus
                      //   margin="normal"
                      //   id="assetTag"
                      //   label="Asset Tag(s). E.g. europe, germany, welding, automotive"
                      //   type="text"
                      //   value={currentTag} 
                      //   onChange={this.handleAssetTagsChange}
                      //   placeholder="Type tags for asset"
                      //   onKeyDown={this.handleKeyDownTags}
                      //   onBlur={this.handleBlur}
                      //   fullWidth
                      // />

                      // {Object.keys(assetTags).length > 0 &&
                      //   assetTags.map(tag => (
                      //     <div className="asset-tag-item" key={tag}>
                      //       <div style={{marginRight:'4px'}}>
                      //         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                      //           <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z"/>
                      //           <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z"/>
                      //         </svg>
                      //       </div>
                      //       {tag}
                      //       <button
                      //         type="button"
                      //         className="button"
                      //         onClick={() => this.handleDelete(tag)}
                      //       >
                      //         &times;
                      //       </button>
                      //     </div>
                      // ))} */}
                    </>
                    }

                    {Object.keys(selectedMachine).length == 0 && createNewMachine &&
                    <>
                    <IconButton style={{marginLeft:'-16px', marginBottom:'-8px'}} onClick={this.backToSelectMachine} >
                        <ArrowBackIosNewOutlinedIcon sx={{ fontSize: 18 }}/>
                      </IconButton>

                    {/* //  Select Manufacturer dropdown */}
                    <div>
                      <FormControl sx={{ m: 1, minWidth: 200, width:'100%'}}>
                        <InputLabel sx={{ display:'flex', alignItems:'center'}} id="demo-simple-select-autowidth-label">Manufacturer</InputLabel>
                        <Select
                          labelId="manufacturer-label"
                          value={selectedNewMachineManufacturer}
                          onChange={this.handleSelectManufacturer}
                          sx={{width:'100%'}}
                          label="Manufacturer"
                          renderValue={(selected) => (
                            selected == 'addNew'? "Add New Manufacturer" :
                            <Box sx={{ display: 'flex',alignItems:'center', flexWrap: 'wrap', gap: 0.5 }}>
                              <div>
                                <Avatar
                                  alt={selected.manufacturer_name}
                                  src={selected.manufacturer_logo_url}
                                  sx={{ width: 30, height: 30 }}
                                  variant="rounded"
                                />
                              </div>
                              <div style={{marginLeft:'4px'}}>
                                {selected.manufacturer_name}
                              </div>
                            </Box>
                          )}
                        >
                          <MenuItem value={"addNew"}>
                            <div style={{display:'flex', alignItems:'center', marginLeft:'auto', marginRight:'auto'}}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#2563eb" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                              </svg>
                              <span style={{paddingLeft:'8px',color:'#2563eb', fontWeight:500}}><em>Add New Manufacturer</em></span>
                            </div>
                            
                          </MenuItem>
                          {manufacturerList.map(manufacturer => (
                            <MenuItem style={{display:'flex'}} key={manufacturer.manufacturer_uuid} value={manufacturer}>
                              <div>
                                <Avatar
                                  alt={manufacturer.manufacturer_name}
                                  src={manufacturer.manufacturer_logo_url}
                                  sx={{ width: 50, height: 50 }}
                                  variant="rounded"
                                />
                              </div>
                              <div style={{marginLeft:'8px'}}>
                                {manufacturer.manufacturer_name}
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    </>
                    }

                    {/* Create new Manufacturer information */}
                    {Object.keys(selectedMachine).length == 0 && createNewMachine && selectedNewMachineManufacturer=="addNew" &&
                    <>
                      <div style={{margin:'8px 0px'}}>
                        <span style={{fontSize:'16px', fontWeight:400, fontFamily: "Roboto, Helvetica, Arial, sans-serif", }}>Manufacturer Logo</span>
                      </div>
                      <div style={{margin: '10px 0px 20px 0px', border:'0px solid rgb(255,255,255)'}}>
                        <ManufacturerLogoEditor changeNewManufacturerLogo={this.changeNewManufacturerLogo} manufacturingAvatarPic={newManufacturerLogo} manufacturerLogo={newManufacturerLogo + String(Math.random())} />
                      </div>

                      <TextField
                        margin="normal"
                        id="newManufacturerName"
                        label="Manufacturer Name"
                        type="text"
                        value={newManufacturerName} 
                        onChange={this.handleNewManufacturerNameChange}
                        fullWidth
                        required
                      />

                      <TextField
                        margin="normal"
                        id="newManufacturerDescription"
                        label="Manufacturer Description"
                        type="text"
                        value={newManufacturerDescription} 
                        onChange={this.handleNewManufacturerDescriptionChange}
                        fullWidth
                      />
                      
                      
                      <TextField
                        margin="normal"
                        id="newManufacturerHomepageUrl"
                        label="Manufacturer Homepage URL"
                        type="url"
                        value={newManufacturerHomepageUrl} 
                        onChange={this.handleNewManufacturerHomepageURLChange}
                        fullWidth
                      />

                      <TextField
                        margin="normal"
                        id="newManufacturerSupportHomepageUrl"
                        label="Manufacturer Support Homepage URL"
                        type="url"
                        value={newManufacturerSupportHomepageUrl} 
                        onChange={this.handleNewManufacturerSupportHomepageURLChange}
                        fullWidth
                      />

                      <TextField
                        margin="normal"
                        id="newManufacturerSupportEmail"
                        label="Manufacturer Support Email"
                        type="email"
                        value={newManufacturerSupportEmail} 
                        onChange={this.handleNewManufacturerSupportEmailChange}
                        fullWidth
                      />
                      <TextField
                        margin="normal"
                        id="newManufacturerSupportPhone"
                        label="Manufacturer Support Phone"
                        type="tel"
                        value={newManufacturerSupportPhone} 
                        onChange={this.handleNewManufacturerSupportPhoneChange}
                        fullWidth
                      />
                    </>
                    }

                    {/* Create new machine information */}
                    {Object.keys(selectedMachine).length == 0 && createNewMachine && Object.keys(selectedNewMachineManufacturer).length > 0 &&
                    <>
                      <div style={{margin:'24px 0px 8px 0px'}}>
                        <span style={{fontSize:'16px', fontWeight:400, fontFamily: "Roboto, Helvetica, Arial, sans-serif", }}>Machine Picture</span>
                      </div>
                      <div style={{justifyContent:'center', margin: '10px 0px 20px 0px', border:'0px solid rgb(255,255,255)'}}>
                        <MachinePicEditor changeNewPic={this.changeNewPic} editMachine={false} machineAvatarPic={newMachineImage} machinePic={newMachineImage + String(Math.random())}  />
                      </div>

                      <TextField
                        margin="normal"
                        id="newMachineName"
                        label="Machine Name"
                        type="text"
                        value={newMachineName} 
                        onChange={this.handleNewMachineNameChange}
                        fullWidth
                        required
                      />

                      <TextField
                        margin="normal"
                        id="newMachineDescription"
                        label="Machine Description"
                        type="text"
                        value={newMachineDescription} 
                        onChange={this.handleNewMachineDescriptionChange}
                        fullWidth
                      />
                      <TextField
                        margin="normal"
                        id="newMachineIdentifier"
                        label="Machine Indentifier"
                        type="text"
                        value={newMachineIdentifier} 
                        onChange={this.handleNewMachineIdentifierChange}
                        fullWidth
                      />
                      <TextField
                        margin="normal"
                        id="newMachineHomepageUrl"
                        label="Machine Homepage URL"
                        type="url"
                        value={newMachineHomepageUrl} 
                        onChange={this.handleNewMachineHomepageURLChange}
                        fullWidth
                      />
                    </>
                    }
                    {Object.keys(selectedMachine).length == 0 && createNewMachine && selectedNewMachineManufacturer=="addNew" &&
                    <div >
                      <div className={classes.createContainers} >
                          <button 
                              // type="submit"
                              onClick={this.handledAddNewManufacturerMachine} 
                              className={classes.createManufacturerMachineButton} 
                              disabled={loadingNewMfgMachine || (newManufacturerName == '') || (newMachineName == '')}
                          >
                              {'ADD MANUFACTURE & MACHINE'} { loadingNewMfgMachine && <CircularProgress size={24} className={classes.buttonProgress} />}
                          </button>
                      </div>
                    </div>
                    }
                    {Object.keys(selectedMachine).length == 0 && createNewMachine && selectedNewMachineManufacturer!="addNew" && Object.keys(selectedNewMachineManufacturer).length >0 &&
                    <div >
                      <div className={classes.createContainers} >
                          <button 
                              // type="submit"
                              onClick={this.handledAddNewMachine} 
                              className={classes.createMachineButton} 
                              disabled={loadingNewMachine || (newMachineName == '')}
                          >
                              {'ADD MACHINE'} { loadingNewMachine && <CircularProgress size={24} className={classes.buttonProgress} />}
                          </button>
                      </div>
                    </div>
                    }

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  
                    color="primary" 
                    type='submit' 
                    className={buttonClassname} disabled={loading || (assetName == '') || (Object.keys(selectedMachine).length == 0) || !selectedDevice || Object.keys(selectedDevice).length == 0 } 
                    onClick={this.submitButtonClick}
                  >
                    {'Add Asset'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AddAssetModal));


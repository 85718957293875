import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import Alert from '@material-ui/lab/Alert';



const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(70),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    // backgroundColor:'transparent',
    borderRadius:'25px',
    // width: '100%',
    // height: '100%',
    border:'1px solid #388e3c',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    color:'#388e3c',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    outline: 'none',
    // // font-weight:bold;
    padding:'0px 12px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
      outline: 'none',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class SuspendedPersonalOrgModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      loading: false,
      success: false,
      countdown: '',
    };
  }

  componentDidMount(){

    // function to get time until account is unsuspended
    // Get the UTC date for the next day at 00:00
    var d = new Date();
    var tomorrow = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());
    tomorrow.setDate(new Date().getUTCDate()+1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(1);
    tomorrow.setSeconds(0);
    // Get today's date and time in the UTC timezone
    var now1 = new Date().getTime();
    var now = new Date();
    var nowUTC = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
    // Find the distance between now and the count down date
    var distance = tomorrow - nowUTC;
    // Time calculations for days, hours, minutes and seconds
    // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    var countdown = hours + "h " + minutes + "m ";
    this.setState({countdown: countdown})

  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.resetModalStates();
  };
  
  componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.open,
    }); 
  }



  render() {
    const { classes } = this.props;
    const { open, countdown } = this.state;

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title"> Limitation Reached</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '20px'}}>
                
                  <div style={{margin: '0px'}} id="alert-dialog-description">
                    <Alert severity="error">
                      <b>You have reached the limitations of your allowed daily interactions.</b>  
                      Limitations for Personal Organizations are 10,000 interactions/day. To get more interactions, please created an Organization.
                      <br/>
                      <br/>
                      You will be able to start publishing again in 
                      <span style={{textAlign: 'center', }}><b>{countdown}</b></span>
                    </Alert> 
                  </div>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  {/* <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button> */}
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

SuspendedPersonalOrgModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(SuspendedPersonalOrgModal));




import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import  {postData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import {fetchAlertChannels} from '../store/actions/alertChannelActions';


const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    // fontSize:'14px',
    // // font-weight:bold;
    padding:'0px 0px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      backgroundColor:'transparent',
      
      
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class CreateAlertChannelModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      loading: false,
      success: false,
      alert_channel_name: '',
      alert_channel_description: '',
    }
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitButtonClick = this.submitButtonClick.bind(this);
  };

  timer = undefined;

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      loading: false,
      success: false,
      open:false,
      alert_channel_name: '',
      alert_channel_description: '',
    });
  };

  componentWillReceiveProps({open}) {
    this.setState({...this.state,open})
  }


  handleAlertChannelNameChange = (event) => {
    this.setState({alert_channel_name: event.target.value});
  }

  handleAlertChannelDescriptionChange = (event) => {
    this.setState({alert_channel_description: event.target.value});
  }

  componentDidUpdate(prevState) {
    if(this.state.success){
      console.log('success was true')
      this.setState({
        loading: false,
        success: false,
        alert_channel_name: '',
        alert_channel_description: '',
      })
    }
  }


  submitButtonClick = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        postData('https://api.iotflows.com/v1/organizations/' +this.props.selectedOrganization.organization_uuid + '/alert-channels', 
        {'alert_channel_name': this.state.alert_channel_name,
          'alert_channel_description': this.state.alert_channel_description})
          .then(async response => {
            if(response.ok){
              this.props.showSuccessSnackbar("New alert channel has been successfully created!");
              this.setState({
                loading: false,
                success: true,
                open:false,
              }, () => { this.props.fetchAlertChannels(this.props.selectedOrganization.organization_uuid); } );
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false,
                });
                this.handleClose();
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
      // // callback function to update alert channel list
      // this.props.refreshAlertChannelList()
      // this.setState({
      //   loading: false,
      //   success: false,
      //   open:false,
      //   alert_channel_name: '',
      //   alert_channel_description: '',
      // });
    }
  };



  render() {
    const { classes, selectedOrganization } = this.props;
    const { open, loading, success,  } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
        {/* <Button className={classes.button} variant="outlined"  onClick={this.handleOpen}>Create Alert Channel</Button>    */}
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">{"Create Alert Channel"}</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '0px'}}>
                <TextField
                autoFocus
                margin="normal"
                id="alertChannelName"
                label="Alert Channel Name"
                type="text"
                value= {this.state.alert_channel_name}
                onChange={this.handleAlertChannelNameChange}
                fullWidth
                />

                <TextField
                autoFocus
                margin="normal"
                id="alertChannelDescription"
                label="Alert Channel Description"
                type="text"
                value= {this.state.alert_channel_description}
                onChange={this.handleAlertChannelDescriptionChange}
                fullWidth
                />

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

CreateAlertChannelModal.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  fetchAlertChannels: (organization_uuid) => dispatch(fetchAlertChannels(organization_uuid)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(CreateAlertChannelModal));

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import  {postData } from '../ApiCalls/DataApis';
import Alert from '@material-ui/lab/Alert';
import { Typography, IconButton } from '@material-ui/core';
import Row from 'react-bootstrap/Row';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNightBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    maxWidth: theme.spacing(150),
    minWidth: theme.spacing(43),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    // [theme.breakpoints.down('xs')]:{
    //   width: theme.spacing(46),
    // }
  },
  textSizeCSS: {
    fontSize: '0.875rem',
    display: 'flex',
    [theme.breakpoints.down('xs')]:{
      fontSize: '0.675rem',
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    width: '240px',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class InitialDeviceConfigSetupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      loading: false,
      success: false,
      openClicked1: false,
      openClicked2: false,
      openClicked3: false,
    };
  }


  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false}, ()=> {this.props.handleClose()} );
    
  };

  copyCode = (copiedTextId) => {
    if(copiedTextId == 'bashCommand') {
      this.setState({openClicked1: true})
    }else if (copiedTextId == 'installRemoteAccess'){
      this.setState({openClicked2: true})
    }else if (copiedTextId == 'remoteAccessCredentials'){
      this.setState({openClicked3: true})
    }
    var copyText = document.getElementById(copiedTextId).children[0].children[0].textContent;
    navigator.clipboard.writeText(copyText);

    
  }

  handleTooltipClose1 = () => {
    this.setState({
      openClicked1: false,
    })
  }
  handleTooltipClose2 = () => {
    this.setState({
      openClicked2: false,
    })
  }
  handleTooltipClose3 = () => {
    this.setState({
      openClicked3: false,
    })
  }

  

  render() {
    const { classes } = this.props;
    const { open, 
            loading, 
            success,
            openClicked1,
            openClicked2,
            openClicked3} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
        {/* <LightTooltip title="Generate a remote access key in order to..."> */}
          {/* <Button className={classes.button} variant="contained"   onClick={this.handleOpen}>Generate Remote Access Key </Button> */}
        {/* </LightTooltip> */}
        
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title"> Remote Access Agent </DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '20px'}}>

                  <div >
                    <div style={{marginBottom: '16px'}}>
                      <h6 style={{lineHeight: '1.1', fontSize: '14px',fontFamily: 'Trebuchet MS', }}>
                        Run the following command on your Debian-Based device (e.g. Ubuntu, MX Linux, Linux Mint, Raspberry Pi) to install the remote access agent and connect your device to the cloud.
                      </h6>
                    </div>
                    <div style={{flexGrow: 1}}>
                      <Grid container spacing={1}>
                        <Grid item xs={11}>
                          <SyntaxHighlighter  id="bashCommand" style={tomorrowNightBlue} language="shell" >
                            {this.props.initalSetupParameters.one_line_setup}
                          </SyntaxHighlighter>                    
                        </Grid>
                        <Grid item xs={1}>
                        <ClickAwayListener onClickAway={this.handleTooltipClose1}>
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={this.handleTooltipClose1}
                                  open={openClicked1}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title="Copied!"
                                >
                                  <IconButton onClick={() => this.copyCode("bashCommand")} style={{padding: '2px 4px 4px 4px'}} ><FileCopyOutlinedIcon fontSize="small"/></IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div style={{margin: '0px',marginBottom: '16px'}} >
                    <Alert className={classes.textSizeCSS} severity="info">
                      <span><i><b>This is the last time these credentials will be available.</b></i></span> 
                      However, you can reconfigure a remote access agent at any time but please be aware that previous agents will be revoked.
                    </Alert> 
                  </div>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                        <h6 style={{lineHeight: '1.1', fontSize: '14px',fontFamily: 'Trebuchet MS', fontWeight:'700'}}>For Other Operating Systems:</h6>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container spacing={0} style={{textAlign: 'left', fontSize: '14px'}}>
                      <Grid item xs={12}>
                        <h6 style={{lineHeight: '1.1', fontSize: '14px',fontFamily: 'Trebuchet MS'}}>Install npm and <a style={{textDecoration:'none'}} href="https://nodejs.org/en/" target="_blank">Node.js</a>, then run the following command on your <b>device terminal</b> to install IoTFlows Remote Access:</h6>
                      </Grid>
                      <Grid item xs={11}>
                        <SyntaxHighlighter id="installRemoteAccess" style={tomorrowNightBlue} language="shell" >{`sudo npm install -g --unsafe-perm @iotflows/iotflows-remote-access`}</SyntaxHighlighter>
                      </Grid>
                      <Grid item xs={1}>
                      <ClickAwayListener onClickAway={this.handleTooltipClose2}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={this.handleTooltipClose2}
                            open={openClicked2}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Copied!"
                          >
                            <IconButton onClick={() => this.copyCode("installRemoteAccess")} style={{padding: '2px 4px 4px 4px'}} ><FileCopyOutlinedIcon fontSize="small"/></IconButton>
                          </Tooltip>
                      </ClickAwayListener>
                        
                      </Grid>
                      <Grid item xs={12}>
                        <h6 style={{lineHeight: '1.1', fontSize: '14px',fontFamily: 'Trebuchet MS'}}>Now run the following command to connect the agent to the cloud:</h6>
                      </Grid>
                      <Grid item xs={11}>
                        <SyntaxHighlighter id="remoteAccessCredentials" style={tomorrowNightBlue} language="shell" >{`sudo iotflows-remote-access username=${this.props.initalSetupParameters.device_uuid} password=${this.props.initalSetupParameters.password} command=reset_credentials`}</SyntaxHighlighter>
                      </Grid>
                      <Grid item xs={1}>
                      <ClickAwayListener onClickAway={this.handleTooltipClose3}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={this.handleTooltipClose3}
                            open={openClicked3}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Copied!"
                          >
                            <IconButton onClick={() => this.copyCode("remoteAccessCredentials")} style={{padding: '2px 4px 4px 4px'}} ><FileCopyOutlinedIcon fontSize="small"/></IconButton>
                          </Tooltip>
                      </ClickAwayListener>
                        </Grid>
                      <Grid item xs={12} style={{marginTop: '20px'}}>
                        <p style={{ fontSize: '14px',fontFamily: 'Trebuchet MS',lineHeight: '1.2'}}>
                          <i>For details go to&nbsp;
                            <a  style={{textDecoration: 'none'}} href="https://www.npmjs.com/package/@iotflows/iotflows-remote-access" target="_blank">
                              IoTFlows Remote Access Documentation
                            </a>
                          </i>
                        </p>
                      </Grid>
                    </Grid>
                    </AccordionDetails>
                  </Accordion>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  {/* <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button> */}
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}


const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(InitialDeviceConfigSetupModal));




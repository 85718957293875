import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import { green } from '@material-ui/core/colors';
// import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import {fetchOrganizations} from '../store/actions/organizationActions';
import Typography from '@material-ui/core/Typography';
// import { CopyBlock, dracula,tomorrowNightBlue } from 'react-code-blocks'
// import CodeSnippet from '../CodeSnippet';
// import CodeSnippet from '@bit/carbon-design-system.carbon-components-react.code-snippet';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Alert from '@material-ui/lab/Alert';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  paper: {
    position: 'relative',
    // width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    // [theme.breakpoints.down('sm')]:{
    //   width: theme.spacing(43),
    // }
  },
  button: {
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      
    },
  },
  dialog: { borderRadius: 10 } ,
  textSizeCSS: {
    display:'flex',
    fontSize: '0.875rem',
    [theme.breakpoints.down('xs')]:{
      fontSize: '0.675rem',
    }
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

 



class SshConnectedInfoModal extends React.Component {
  constructor(props) {
    super(props)
 
    this.state = {
      open: this.props.open,
      openClicked1: false,
      openClicked2: false,
      openClicked3: false,
      openClicked4: false,
      openClicked5: false,
      openClicked6: false,
      openClicked7: false,
      first: this.props.first, 
      expanded: 'panel1',
    }
    this.copyToClipboard = this.copyToClipboard.bind(this)
    this.handleTooltipClose1 = this.handleTooltipClose1.bind(this)
    this.handleTooltipClose2 = this.handleTooltipClose2.bind(this)
    this.handleTooltipClose3 = this.handleTooltipClose3.bind(this)
    this.handleTooltipClose4 = this.handleTooltipClose4.bind(this)
    this.handleTooltipClose5 = this.handleTooltipClose5.bind(this)
    this.handleTooltipClose6 = this.handleTooltipClose6.bind(this)
    this.handleTooltipClose7 = this.handleTooltipClose7.bind(this)
  }


  handleOpen = () => {
    this.setState({ 
      open: true,
      first: true });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      first: true });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.open,
      first: nextProps.first,
    }); 
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  copyToClipboard = (textId) => {

    var textfield = document.querySelector(textId);
    var range = document.createRange();
    range.selectNode(textfield);
    window.getSelection().addRange(range);

    try {
      // Now that we've selected the anchor text, execute the copy command
      var successful = document.execCommand('copy');
      if(successful){
        if(textId == '.server-name'){
          this.setState({openClicked1: true},
            ()=> {if (this.state.first) {
              this.copyToClipboard('.server-name');
              this.setState({first: false});
            }}
          )
        }else if(textId == '.ssh-config'){
          this.setState({openClicked2: true},
            ()=> {if (this.state.first) {
              this.copyToClipboard('.ssh-config');
              this.setState({first: false});
            }}
          )
        }else if(textId == '.ssh-install'){
          this.setState({openClicked2: true},
            ()=> {if (this.state.first) {
              this.copyToClipboard('.ssh-install');
              this.setState({first: false});
            }}
          )
        }else if(textId == '.host'){
          this.setState({openClicked3: true},
            ()=> {if (this.state.first) {
              this.copyToClipboard('.host');
              this.setState({first: false});
            }}
          )
        }else if(textId == '.dnsOne'){
          this.setState({openClicked4: true},
            ()=> {if (this.state.first) {
              this.copyToClipboard('.dnsOne');
              this.setState({first: false});
            }}
          )
        }else if(textId == '.dnsTwo'){
          this.setState({openClicked5: true},
            ()=> {if (this.state.first) {
              this.copyToClipboard('.dnsTwo');
              this.setState({first: false});
            }}
          )
        }else if(textId == '.dnsThree'){
          this.setState({openClicked6: true},
            ()=> {if (this.state.first) {
              this.copyToClipboard('.dnsThree');
              this.setState({first: false});
            }}
          )
        }else if(textId == '.dnsFour'){
          this.setState({openClicked7: true},
            ()=> {if (this.state.first) {
              this.copyToClipboard('.dnsFour');
              this.setState({first: false});
            }}
          )
        }
      }
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copy email command was ' + msg);
    } catch(err) {
      console.log('Oops, unable to copy');
    }

    // Remove the selections - NOTE: Should use
    // removeRange(range) when it is supported
    window.getSelection().removeAllRanges();
  };

  

  handleTooltipClose1(){
    this.setState({
      openClicked1: false, 
    })
  }
  handleTooltipClose2(){
    this.setState({
      openClicked2: false,})
  }
  handleTooltipClose3(){
    this.setState({
      openClicked3: false,
    })
  }
  handleTooltipClose4(){
    this.setState({
      openClicked4: false,
    })
  }
  handleTooltipClose5(){
    this.setState({
      openClicked5: false,
    })
  }
  handleTooltipClose6(){
    this.setState({
      openClicked6: false,
    })
  }
  handleTooltipClose7(){
    this.setState({
      openClicked7: false,
    })
  }

  handleChange = (panel) => (event, newExpanded) => {
    this.setState({expanded: newExpanded ? panel : false});
  };
  

  render() {
    const { classes } = this.props;
    const { open, 
            openClicked1, 
            openClicked2, 
            openClicked3, 
            openClicked4,
            openClicked5,
            openClicked6,
            openClicked7,
            expanded } = this.state;

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
          
          <Dialog
              open={open}
              onClose={this.props.handlerCloseSshInfoModal}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
              fullWidth
              maxWidth="xl"
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} > Access your device remotely via SSH</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '20px'}}>


                <Accordion square expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography className={classes.heading}>One-line command</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{flexDirection: 'column'}}>
                    <Typography variant="subtitle1" gutterBottom>
                      <b>1. Open an SSH client.</b>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      You can use the regular Terminal in MacOS or Linux. For Windows use <a href='https://docs.microsoft.com/en-us/windows/wsl/install' target="_blank" style={{ textDecoration: 'none'}}>WSL</a>.
                    </Typography> 
                    <Typography variant="subtitle1" gutterBottom>
                      <b>2. Connect to your device using its Public DNS:</b>             
                    </Typography> 
                    {this.props.selectedDevice.device_is_virtual?
                      <>
                      <Typography variant="subtitle1" gutterBottom>
                        <code className="dnsOne">ssh iotflows@{this.props.device_uuid}.{this.props.server_url_ssh}.ssh -o ProxyCommand="openssl s_client -quiet -servername {this.props.device_uuid}.{this.props.server_url_ssh}.ssh -connect {this.props.server_url_ssh}:443"</code>&nbsp;
                        <ClickAwayListener onClickAway={this.handleTooltipClose4}>
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={this.handleTooltipClose4}
                              open={openClicked4}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Copied!"
                            >
                              <IconButton  className="js-copybtn"  size="small">
                                <FileCopyOutlinedIcon style={{fontSize: '16px'}} onClick={() => this.copyToClipboard('.dnsOne')}/>
                              </IconButton> 
                            </Tooltip>
                        </ClickAwayListener>      
                      </Typography>  
                      <Typography variant="subtitle1" gutterBottom>
                        Admin user password is <strong>password</strong>
                      </Typography> 

                      <Alert className={classes.textSizeCSS} severity="warning"  >
                          It is highly recommended to change the password of your machine:<br/>
                          <span>1. SSH into server with old <b>password</b></span>
                          <span>2. Run <code>sudo passwd iotflows</code></span>
                          <span>3. Follow the instructions to <b>change</b> the <b>password</b></span>
                      </Alert> 
                      </>
                    :
                      <>
                      <Typography variant="subtitle1" gutterBottom>
                        <code className="dnsTwo">ssh <span style={{color: 'rgb(74, 140, 207)'}}>username</span>@{this.props.device_uuid}.{this.props.server_url_ssh}.ssh -o ProxyCommand="openssl s_client -quiet -servername {this.props.device_uuid}.{this.props.server_url_ssh}.ssh -connect {this.props.server_url_ssh}:443"</code>&nbsp;
                        <ClickAwayListener onClickAway={this.handleTooltipClose5}>
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={this.handleTooltipClose5}
                              open={openClicked5}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Copied!"
                            >
                              <IconButton  className="js-copybtn"  size="small">
                                <FileCopyOutlinedIcon style={{fontSize: '16px'}} onClick={() => this.copyToClipboard('.dnsTwo')}/>
                              </IconButton> 
                            </Tooltip>
                        </ClickAwayListener>      
                      </Typography>  
                      <Typography variant="subtitle1" gutterBottom>
                        Replace <span style={{color: 'rgb(74, 140, 207)'}}>username</span> with the username of your device such as pi, debian, ubuntu, or root.
                      </Typography> 

                      <Alert className={classes.textSizeCSS} severity="warning"  >
                          It is highly recommended to change the password of your machine:<br/>
                          <span>1. SSH into server with old <b>password</b></span>
                          <span>2. Run <code>sudo passwd <span style={{color: 'rgb(74, 140, 207)'}}>USERNAME</span></code></span>
                          <span>3. Follow the instructions to <b>change</b> the <b>password</b></span>
                      </Alert> 
                      </>
                    }
                  </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                  >
                    <Typography className={classes.heading}>Using config file</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{flexDirection: 'column'}}>
                    <Typography variant="subtitle1" gutterBottom>
                    <b>1. Open an SSH client.</b>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      You can use the regular Terminal in MacOS or Linux. For Windows use <a href='https://docs.microsoft.com/en-us/windows/wsl/install' target="_blank" style={{ textDecoration: 'none'}}>WSL</a>.
                    </Typography>  
                    <Typography variant="subtitle1" gutterBottom>
                      <b>2. Open the ssh config file:  </b>                
                    </Typography>  
                    <Typography variant="subtitle1" gutterBottom>
                      <code className="ssh-config">sudo nano ~/.ssh/config</code>
                      <ClickAwayListener onClickAway={this.handleTooltipClose2}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={this.handleTooltipClose2}
                            open={openClicked2}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Copied!"
                          >
                            <IconButton  size="small">
                              <FileCopyOutlinedIcon style={{fontSize: '16px'}} onClick={() => this.copyToClipboard('.ssh-config')}/>
                            </IconButton> 
                          </Tooltip>
                      </ClickAwayListener>  
                    </Typography>  
                    <Typography variant="subtitle1" gutterBottom>
                      Make sure you have the following server in your ssh configuration file, it may change:&nbsp;
                      <code style={{color: '#01579b'}} >{this.props.server_url_ssh}</code>
                    </Typography> 
                    <Typography variant="subtitle1" gutterBottom>
                    <b>3. Add this host in a new line if it doesn't exist:</b>            
                    </Typography> 
                    <Typography variant="subtitle1" gutterBottom>
                      <code className="host">Host *.{this.props.server_url_ssh}.ssh<br/>&nbsp;&nbsp;&nbsp;&nbsp;ProxyCommand openssl s_client -quiet -servername %h -connect {this.props.server_url_ssh}:443</code>   
                      <ClickAwayListener onClickAway={this.handleTooltipClose3}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={this.handleTooltipClose3}
                            open={openClicked3}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Copied!"
                          >
                            <IconButton  className="js-copybtn"  size="small">
                              <FileCopyOutlinedIcon style={{fontSize: '16px'}} onClick={() => this.copyToClipboard('.host')}/>
                            </IconButton> 
                          </Tooltip>
                      </ClickAwayListener>                          
                    </Typography> 
                    <Typography variant="subtitle1" gutterBottom>
                    <b>4. Connect to your device using its Public DNS:</b>             
                    </Typography> 
                    {this.props.selectedDevice.device_is_virtual?
                      <>
                      <Typography variant="subtitle1" gutterBottom>
                        <code className="dnsThree">ssh iotflows@{this.props.device_uuid}.{this.props.server_url_ssh}.ssh</code> &nbsp;
                        <ClickAwayListener onClickAway={this.handleTooltipClose6}>
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={this.handleTooltipClose6}
                              open={openClicked6}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Copied!"
                            >
                              <IconButton  className="js-copybtn"  size="small">
                                <FileCopyOutlinedIcon style={{fontSize: '16px'}} onClick={() => this.copyToClipboard('.dnsThree')}/>
                              </IconButton> 
                            </Tooltip>
                        </ClickAwayListener>      
                      </Typography>  
                      <Typography variant="subtitle1" gutterBottom>
                        Admin user password is <strong>password</strong>
                      </Typography> 

                      <Alert className={classes.textSizeCSS} severity="warning"  >
                          It is highly recommended to change the password of your machine:<br/>
                          <span>1. SSH into server with old <b>password</b></span>
                          <span>2. Run <code>sudo passwd iotflows</code></span>
                          <span>3. Follow the instructions to <b>change</b> the <b>password</b></span>
                      </Alert> 
                      </>
                    :
                      <>
                      <Typography variant="subtitle1" gutterBottom>
                        <code className="dnsFour">ssh <span style={{color: 'rgb(74, 140, 207)'}}>username</span>@{this.props.device_uuid}.{this.props.server_url_ssh}.ssh</code> &nbsp;
                        <ClickAwayListener onClickAway={this.handleTooltipClose7}>
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={this.handleTooltipClose7}
                              open={openClicked7}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Copied!"
                            >
                              <IconButton  className="js-copybtn"  size="small">
                                <FileCopyOutlinedIcon style={{fontSize: '16px'}} onClick={() => this.copyToClipboard('.dnsFour')}/>
                              </IconButton> 
                            </Tooltip>
                        </ClickAwayListener>      
                      </Typography>  
                      <Typography variant="subtitle1" gutterBottom>
                        Replace <span style={{color: 'rgb(74, 140, 207)'}}>username</span> with the username of your device such as pi, debian, ubuntu, or root.
                      </Typography> 

                      <Alert className={classes.textSizeCSS} severity="warning"  >
                          It is highly recommended to change the password of your machine:<br/>
                          <span>1. SSH into server with old <b>password</b></span>
                          <span>2. Run <code>sudo passwd <span style={{color: 'rgb(74, 140, 207)'}}>USERNAME</span></code></span>
                          <span>3. Follow the instructions to <b>change</b> the <b>password</b></span>
                      </Alert> 
                      </>
                    }
                  </AccordionDetails>
                </Accordion>
                
                  {/* <Typography variant="subtitle1" gutterBottom>
                  <b>1. Install <a href='https://nodejs.org/en/' style={{ textDecoration: 'none'}}>Node.js</a></b>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                  <b>1. Open an SSH client.</b>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    <code className="ssh-install">sudo npm install -g --unsafe-perm @iotflows/iotflows-remote-access</code>
                    <ClickAwayListener onClickAway={this.handleTooltipClose2}>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={this.handleTooltipClose2}
                          open={openClicked2}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="Copied!"
                        >
                          <IconButton  size="small">
                            <FileCopyOutlinedIcon style={{fontSize: '16px'}} onClick={() => this.copyToClipboard('.ssh-install')}/>
                          </IconButton> 
                        </Tooltip>
                    </ClickAwayListener>  
                  </Typography> 
                  <Typography variant="subtitle1" gutterBottom>
                    <code className="host">sudo iotflows-remote-access</code>   
                    <ClickAwayListener onClickAway={this.handleTooltipClose3}>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={this.handleTooltipClose3}
                          open={openClicked3}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="Copied!"
                        >
                          <IconButton  className="js-copybtn"  size="small">
                            <FileCopyOutlinedIcon style={{fontSize: '16px'}} onClick={() => this.copyToClipboard('.host')}/>
                          </IconButton> 
                        </Tooltip>
                    </ClickAwayListener>                          
                  </Typography> 
                  <Typography variant="subtitle1" gutterBottom>
                   <b>4. Connect to your device using its Public DNS:</b>             
                  </Typography> 
                  <Typography variant="subtitle1" gutterBottom>
                    <code className="dns" >ssh <span style={{color: 'rgb(74, 140, 207)'}}>username</span>@{this.props.device_uuid}.{this.props.server_url_ssh}.ssh</code> &nbsp;
                    <ClickAwayListener onClickAway={this.handleTooltipClose4}>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={this.handleTooltipClose4}
                          open={openClicked4}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="Copied!"
                        >
                          <IconButton  className="js-copybtn"  size="small">
                            <FileCopyOutlinedIcon style={{fontSize: '16px'}} onClick={() => this.copyToClipboard('.dns')}/>
                          </IconButton> 
                        </Tooltip>
                    </ClickAwayListener>      
                  </Typography>  
                  <Typography variant="subtitle1" gutterBottom>
                    Replace <span style={{color: 'rgb(74, 140, 207)'}}>username</span> with the username of your device such as pi, debian, ubuntu, or root.
                  </Typography>   */}         

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.props.handlerCloseSshInfoModal} >
                    Close
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

SshConnectedInfoModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  fetchOrganizations: () => dispatch(fetchOrganizations()),

})

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(SshConnectedInfoModal));
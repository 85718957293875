import React from 'react';
import { withStyles} from '@material-ui/core/styles';
import {getData, postData, putData, deleteData} from '../ApiCalls/DataApis';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AddFolderModal from './FileManagementModals/AddFolderModal';
import RenameModal from './FileManagementModals/RenameModal';
import DeleteFileModal from './FileManagementModals/DeleteFileModal';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FileDrop } from 'react-file-drop'
// import '../styles/FileDropCss.css';
import FolderIcon from '@material-ui/icons/Folder';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: '20px'

  },
  tableContainer: {
    borderRadius: '10px',
  },
  table: {
    minWidth: 450,
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    minWidth:'70px',
    maxHeight: '23px',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'10px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  iconButtonsCSS: {
    padding: '8px',
    margin: '0px 8px',
    '&:focus': {
      outline: 'none',
    },
  },
  iconButtons2CSS: {
    padding: '2px',
    margin: '0px 0px',
    '&:focus': {
      outline: 'none',
    },
  },
  tableRow: {
    cursor: 'pointer',
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(52, 58, 64,0.7)"
    }
  },
  tableCell: {
    "$selected &": {
      color: "rgb(232, 232, 232)"
    }
  },
  hover: {},
  selected: {},
  colorPrimary: {
    backgroundColor: '#4f86ce',
  },
  barColorPrimary: {
    backgroundColor: '#bfdaff',
  },
  buttonProgress: {
    height: '2px'
  },
  DropInCss: {
    backgroundColor: 'rgba(0,0,0,0.3)', 
  }
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '24px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
    
  },
}))(TableCell);

function AddFolder(props) {
  return (
    <SvgIcon  style={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props}>
      <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"/>
      <path d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"/>
    </SvgIcon>
  );
}
function UploadFile(props) {
  return (
    <SvgIcon  style={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props}>
      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
      <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
    </SvgIcon>
  );
}

function RenameFile(props) {
  return (
    <SvgIcon  style={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props}>
        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
    </SvgIcon>
  );
}

function DownloadFile(props) {
  return (
    <SvgIcon  style={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props}>
      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
    </SvgIcon>
  );
}
function DeleteFile(props) {
  return (
    <SvgIcon  style={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props}>
      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
      <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
    </SvgIcon>
  );
}

function FolderIconOutline(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(60,147,208)" viewBox="0 0 20 20">
      <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"/>
    </svg>
  );
}
function FileIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
  </svg>
  );
}
function BackButton(props) {
  return (
    <SvgIcon  style={{ width: 12, height: 12 }} viewBox="0 0 16 16" {...props}>
      <path fill-rule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"/>
    </SvgIcon>
  );
}
function RefreshButton(props) {
  return (
    <SvgIcon  style={{ width: 12, height: 12 }} viewBox="0 0 16 16" {...props}>
      <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
      <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
    </SvgIcon>
  );
}




class FileManagementTable extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      instrumentType_uuid: this.props.instrumentType_uuid,
      server_url_ssh: this.props.server_url_ssh,
      directoryfiles: [],
      openAddFolderModal: false,
      openRenameModal: false,
      openDeleteFileModal: false,
      selectedFile: [],
      isFileSelected: false,
      disableDownload: true,
      selectedUploadFile: [],
      isUploadFileSelected: false,
      inputPath: '',
      path: this.props.path,
      loading: false,
      droppingFileIn: false,
    }
    
    this.handleCloseModal= this.handleCloseModal.bind(this);
    this.refreshDeviceFileList= this.refreshDeviceFileList.bind(this);
    
  }

  

  componentDidMount() {
    

    postData('https://api.iotflows.com/v1/' + this.props.instrumentType_uuid + '/sftp/list', 
      {'username': localStorage.getItem('ssh_username'),
        'password': localStorage.getItem('ssh_password'),
        "host": this.props.server_url_ssh,
        "path": this.state.path,
      })
        .then(async response => {
          if(response.ok){
            const data = await response.json();
            this.setState({
              directoryfiles: data.data,
              inputPath: this.state.path
            })
          }
          else{
            try{
              const data = await response.json();
              this.props.showErrorSnackbar(data.message)
            }catch (e){
              this.props.showErrorSnackbar('Something went wrong')
            }
          }
        })
  }


refreshDeviceFileList = () => {
  const { loading } = this.state;

      if(!loading){
        this.setState({ loading: true },
          () => {
            postData('https://api.iotflows.com/v1/' + this.props.instrumentType_uuid + '/sftp/list', 
            {'username': localStorage.getItem('ssh_username'),
              'password': localStorage.getItem('ssh_password'),
              "host": this.props.server_url_ssh,
              "path": this.state.path,
            })
              .then(async response => {
                if(response.ok){
                  const data = await response.json();
                  this.setState({
                    directoryfiles: data.data,
                    selectedFile: [],
                    isFileSelected: false,
                    disableDownload: true,
                    inputPath: this.state.path,
                    loading: false,
                  })  
                  
                
                }
                else{
                  try{
                    const data = await response.json();
                    this.props.showErrorSnackbar(data.message)
                    this.setState({ loading: false })
                  }catch (e){
                    this.props.showErrorSnackbar('Something went wrong')
                    this.setState({ loading: false })
                  }
                }
              })
        })
      }
  
}
  
  dateConverter(date){
    if(date != undefined){
      var modifyDate = date;
      modifyDate = new Date(modifyDate);
      return modifyDate.toLocaleDateString() + " " + modifyDate.toLocaleTimeString();
    } else {
      return " "
    }
  }
  handlerAddFolder = () => {
    this.setState({openAddFolderModal: true});
  }

  handlerRename = () => {
    this.setState({openRenameModal: true});
  }

  handlerDeleteFile = () => {
    this.setState({openDeleteFileModal: true});
  }

  changeHandler = (event) => {
    var self = this;
    var newPath = self.state.path + '/' + event.target.files[0].name;

    var reader = new FileReader();
    reader.onload = function() {
      let arrayBuffer = this.result
      let input = new Buffer(arrayBuffer)

      const { loading } = self.state;
      
      if(!loading){
        self.setState({ loading: true },
          () => {
            postData('https://api.iotflows.com/v1/' + self.props.instrumentType_uuid + '/sftp/put', 
            {'username': localStorage.getItem('ssh_username'),
              'password': localStorage.getItem('ssh_password'),
              "host": self.props.server_url_ssh,
              "path": newPath,
              "input": input
            })
            .then(async response => {
              if(response.ok){
                const data = await response.json();
                
                self.props.showSuccessSnackbar('File successfully uploaded!')
                self.setState({
                  loading: false,
                }, () => {self.refreshDeviceFileList()})  
                
              
              }
              else{
                try{
                  const data = await response.json();
                  self.props.showErrorSnackbar(data.message)
                  self.setState({ loading: false })
                }catch (e){
                  self.setState({ loading: false })
                  self.props.showErrorSnackbar('Something went wrong')
                }
              }
            })
          })
      }
    }
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  changeDropzoneHandler = (event) => {
    var files = event[0];
    
    var self = this;
    var newPath = self.state.path + '/' + files.name;

    var reader = new FileReader();
    reader.onload = function() {
      let arrayBuffer = this.result
      let input = new Buffer(arrayBuffer)

      const { loading } = self.state;
      
      if(!loading){
        self.setState({ loading: true },
          () => {
            postData('https://api.iotflows.com/v1/' + self.props.instrumentType_uuid + '/sftp/put', 
            {'username': localStorage.getItem('ssh_username'),
              'password': localStorage.getItem('ssh_password'),
              "host": self.props.server_url_ssh,
              "path": newPath,
              "input": input
            })
            .then(async response => {
              if(response.ok){
                const data = await response.json();
                
                self.props.showSuccessSnackbar('File successfully uploaded!')
                self.setState({
                  loading: false,
                }, () => {self.refreshDeviceFileList()})  
                
              
              }
              else{
                try{
                  const data = await response.json();
                  self.props.showErrorSnackbar(data.message)
                  self.setState({ loading: false })
                }catch (e){
                  self.setState({ loading: false })
                  self.props.showErrorSnackbar('Something went wrong')
                }
              }
            })
          })
      }
    }
    reader.readAsArrayBuffer(files);
  };

  handleCloseModal = () => {
    this.setState({
      openAddFolderModal: false,
      openRenameModal: false,
      openDeleteFileModal: false,
    })
  }

  handleInputPathNameChange = (event) => {
    let pathFiltered = event.target.value.replace(/\/{2,}/g, "/")
    this.setState({
      inputPath: pathFiltered.replace(/[^A-Za-z0-9-._/]/g, "")
    })
  }

  keyPress = (e) => {
    if(e.keyCode == 13){
      const { loading } = this.state;
      if(!loading){
        this.setState({ loading: true },
          () => {
          //  console.log('valueeeeeee', e.target.value);
          let filteredPath = this.state.inputPath;
          
          if (filteredPath != null && Object.keys(filteredPath).length > 1 && filteredPath[Object.keys(filteredPath).length - 1] == '/') {
            filteredPath = filteredPath.substring(0, Object.keys(filteredPath).length - 1);
          }
          postData('https://api.iotflows.com/v1/' + this.props.instrumentType_uuid + '/sftp/list', 
          {'username': localStorage.getItem('ssh_username'),
            'password': localStorage.getItem('ssh_password'),
            "host": this.props.server_url_ssh,
            "path": filteredPath,
          })
            .then(async response => {
              if(response.ok){
                const data = await response.json();
                this.setState({
                  path: filteredPath,
                  directoryfiles: data.data,
                  selectedFile: [],
                  isFileSelected: false,
                  disableDownload: true,
                  inputPath: filteredPath,
                  loading: false,
                })  
                
              
              }
              else{
                try{
                  const data = await response.json();
                  this.props.showErrorSnackbar(data.message)
                  this.setState({ loading: false })
                }catch (e){
                  this.setState({ loading: false })
                  this.props.showErrorSnackbar('Something went wrong')
                }
              }
            })
        })
      }
    }
 }

  handleNavigateToPath = (file) => {
    if(file.type == "d"){
      let folderPath = ""
      if(this.state.path == "/"){
        folderPath = this.state.path + this.state.selectedFile.name;
      }else {
        folderPath = this.state.path + '/' + this.state.selectedFile.name;
      }
      this.setState({path: folderPath}, ()=> this.refreshDeviceFileList())
    }
  }
  handleRowSelected = (file) => {
    this.setState({
      selectedFile: file,
      isFileSelected: true,
    })
    if(file.type == "d"){
      this.setState({disableDownload: true})
    }else {
      this.setState({disableDownload: false})
    }
  }
  handlerBackButton = () => {
      let str = this.state.path;
      let paths = str.split('/')
      paths.pop()
      paths = paths.join('/')
      if(!paths) paths = '/'
      this.setState({path: paths}, ()=> this.refreshDeviceFileList())
  }

  handlerDownloadFile = () => {
    var filePath = this.state.path + '/' + this.state.selectedFile.name
    postData('https://api.iotflows.com/v1/' + this.props.instrumentType_uuid + '/sftp/get', 
      {'username': localStorage.getItem('ssh_username'),
        'password': localStorage.getItem('ssh_password'),
        "host": this.props.server_url_ssh,
        "path": filePath,
      })
      .then(async response => {
        if(response.ok){
          const results = await response.json()
          let buffer = results.data.data;
           let buffer_binary = new Uint8Array(buffer);
          let blob = new Blob([buffer_binary]);
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = this.state.selectedFile.name;
          a.click();
          window.URL.revokeObjectURL(url);
         
        }
        else{
          try{
            const data = await response.json();
            this.props.showErrorSnackbar(data.message)
          }catch (e){
            this.props.showErrorSnackbar('Something went wrong')
          }
        }
      })
      
  }


  render(){
    const { classes} = this.props
    const {openAddFolderModal,
          openRenameModal,
          openDeleteFileModal,
          directoryfiles,
          selectedFile,
          isFileSelected,
          disableDownload,
          host,
          path,
          inputPath,
          loading,
          droppingFileIn
        } = this.state

        

    return (
      <div className={classes.root} >
        <FileDrop
              onFrameDragEnter={(event) => this.setState({droppingFileIn: true})}
              onFrameDragLeave={(event) => this.setState({droppingFileIn: false})}
              onFrameDrop={(event) => this.setState({droppingFileIn: false})}
              // onDragOver={(event) => console.log('onDragOver', event)}
              // onDragLeave={(event) => console.log('onDragLeave', event)}
              onDrop={(files, event) => this.changeDropzoneHandler(files)}
            >
        <TableContainer className={droppingFileIn ? classes.DropInCss : null} style={{height: '500px'}} component={Paper} >
          <Table stickyHeader className={classes.table} >
            <colgroup>
              
              <col style={{width:'3%'}}/>
              <col style={{width:'3%'}}/>
              <col style={{width:'42%'}}/>
              <col style={{width:'10%'}}/>
              {/* <col style={{width:'20%'}}/> */}
              <col style={{width:'42%'}}/>
            </colgroup>
            <TableHead >
              <TableRow >
                <StyledTableCell style={{fontSize: '24px', paddingLeft: '12px', paddingRight: '12px',top: 0}} align="left" colSpan={3}>
                   <b>{this.props.serverName? this.props.serverName: null} Device File Manager</b>
                </StyledTableCell>
                <StyledTableCell style={{fontSize: '24px', paddingLeft: '12px', paddingRight: '12px', top: 0}} colSpan={2}>
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <LightTooltip  title="Add Folder" placement="bottom" arrow  >
                      <IconButton color="primary" aria-label="Add folder" className={classes.iconButtonsCSS} onClick={this.handlerAddFolder} >
                        <AddFolder  style={{ fontSize: 24 }}/>
                      </IconButton>
                    </LightTooltip>
                    
                      <input id="icon-button-file" type="file" style={{ display: 'none' }} onChange={this.changeHandler} />
                      <label htmlFor="icon-button-file" style={{margin: '0px'}}>
                        <LightTooltip  title="Upload File" placement="bottom" arrow  >
                          <IconButton  color="primary" aria-label="UploadFile" className={classes.iconButtonsCSS} component="span">
                            <UploadFile style={{ fontSize: 24 }} />
                          </IconButton>
                        </LightTooltip>
                      </label>
                    <LightTooltip  title="Rename" placement="bottom" arrow  >
                      <IconButton color="primary" aria-label="RenameFile" disabled={!isFileSelected} className={classes.iconButtonsCSS} onClick={this.handlerRename} >
                        <RenameFile style={{ fontSize: 24 }} />
                      </IconButton>
                    </LightTooltip>
                    <LightTooltip  title="Download" placement="bottom" arrow  >
                      <IconButton onClick={this.handlerDownloadFile}  disabled={disableDownload} className={classes.iconButtonsCSS} color="primary" aria-label="Download file">
                        <DownloadFile style={{ fontSize: 24 }} />
                      </IconButton>
                    </LightTooltip>
                    <LightTooltip  title="Delete" placement="bottom" arrow  >
                      <IconButton color="primary" aria-label="Delete file" disabled={!isFileSelected} className={classes.iconButtonsCSS} onClick={this.handlerDeleteFile}>
                        <DeleteFile style={{ fontSize: 24 }} />
                      </IconButton>
                    </LightTooltip>
                  </div>
                </StyledTableCell>
                
              </TableRow>
              <TableRow >
                <StyledTableCell style={{top: 57}} colSpan={1}> 
                  <LightTooltip  title="Back" placement="bottom" arrow  >
                    <IconButton color="primary" aria-label="Back" className={classes.iconButtons2CSS} onClick={this.handlerBackButton} >
                      <BackButton  style={{ fontSize: 22 }}/>
                    </IconButton>
                  </LightTooltip>
                </StyledTableCell>
                <StyledTableCell style={{top: 57}} colSpan={1}> 
                  <LightTooltip  title="Refresh list" placement="bottom" arrow  >
                    <IconButton color="primary" aria-label="Refresh" className={classes.iconButtons2CSS} onClick={this.refreshDeviceFileList} >
                      <RefreshButton  style={{ fontSize: 22 }}/>
                    </IconButton>
                  </LightTooltip>
                </StyledTableCell>
                <StyledTableCell style={{top: 57}} colSpan={3}>
                  <TextField 
                    id="RemoteSiteInput" 
                    label="Remote Site" 
                    variant="outlined" 
                    fullWidth 
                    size="small"
                    value={inputPath} 
                    onChange={this.handleInputPathNameChange}
                    onKeyDown={this.keyPress}
                    />
                     {loading && <LinearProgress className={classes.buttonProgress} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary,}} />}
                </StyledTableCell>
              </TableRow>
              <TableRow >
                <StyledTableCell style={{top: 114}} ></StyledTableCell>
                <StyledTableCell style={{top: 114}} colSpan={2} >Filename</StyledTableCell>
                <StyledTableCell style={{top: 114, textAlign: 'right'}} >Filesize</StyledTableCell>
                {/* <StyledTableCell style={{top: 114}} >Filetype</StyledTableCell> */}
                <StyledTableCell style={{top: 114}} >Last modified</StyledTableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              {directoryfiles.map((file, index) => (
                <TableRow hover id={index} 
                  selected={file.name == selectedFile.name} 
                  classes={{ hover: classes.hover, selected: classes.selected }}
                  className={classes.tableRow} 
                  onClick={() => this.handleRowSelected(file)} onDoubleClick={()=> this.handleNavigateToPath(file)} 
                >
                    <StyledTableCell  className={classes.tableCell}>
                      {file.type == "d" ? <FolderIcon style={{ color: 'rgb(96,183,233)', fontSize: 20 }}/> : <FileIcon/>}
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableCell} colSpan={2} >{file.name}</StyledTableCell>
                    <StyledTableCell className={classes.tableCell} style={{textAlign: 'right'}}>{file.size}</StyledTableCell>
                    {/* <StyledTableCell className={classes.tableCell} >
                      {file.type == 'd' ? "Directory" : null}
                      {file.type == '-' ? "File" : null}
                      {file.type != '-' && file.type != 'd'? file.type : null}
                    </StyledTableCell> */}
                    <StyledTableCell className={classes.tableCell}>
                      {this.dateConverter(file.modifyTime)}
                    </StyledTableCell>
                </TableRow>
                        
              ))}
            </TableBody>
              
          </Table>
        </TableContainer>
        </FileDrop>
              

        {openAddFolderModal? 
          <AddFolderModal 
            open={openAddFolderModal} 
            instrumentType_uuid={this.props.instrumentType_uuid} 
            handleCloseModal={this.handleCloseModal}
            refreshDeviceFileList={this.refreshDeviceFileList}
            username={localStorage.getItem('ssh_username')}
            password={localStorage.getItem('ssh_password')}
            host={this.props.server_url_ssh}
            path={path} 
          /> 
          : null}

        {openRenameModal? 
          <RenameModal 
            open={openRenameModal} 
            instrumentType_uuid={this.props.instrumentType_uuid} 
            handleCloseModal={this.handleCloseModal} 
            username={localStorage.getItem('ssh_username')}
            password={localStorage.getItem('ssh_password')}
            host={this.props.server_url_ssh}
            path={path} 
            refreshDeviceFileList={this.refreshDeviceFileList}
            selectedFile={selectedFile}
          /> 
          : null}

        {openDeleteFileModal? 
          <DeleteFileModal 
            open={openDeleteFileModal} 
            instrumentType_uuid={this.props.instrumentType_uuid} 
            handleCloseModal={this.handleCloseModal} 
            username={localStorage.getItem('ssh_username')}
            password={localStorage.getItem('ssh_password')}
            host={this.props.server_url_ssh}
            path={path} 
            refreshDeviceFileList={this.refreshDeviceFileList}
            selectedFile={selectedFile}
          /> 
          : null}

        
      </div>
      )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    currentUserInfo: state.users.currentUserInfo,
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(FileManagementTable));
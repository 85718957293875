import React from "react";
import _ from "lodash";
import { WidthProvider, Responsive } from "react-grid-layout";
import "./resizeStyles.css"
import "./reactGridStyles.css"
import { withStyles } from '@material-ui/core/styles';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Button from '@material-ui/core/Button';
// import SelectWidgetModal from './SelectWidgetModal';

import AssetTrackingMap from './AssetTrackingMap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTheme } from '@mui/material';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

const ResponsiveReactGridLayout = WidthProvider(Responsive);


  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.gray,
      fontWeight: 700,
      fontFamily:'Trebuchet MS',
      fontSize: 11,
      height: '20px',
      padding: '8px',
    },
    body: {
      fontSize: 13,
      fontFamily:'Trebuchet MS',
      height: '24px',
      padding: '8px',
    },
  }))(TableCell);

const styles = theme => ({
    button: {
        backgroundColor:'transparent',
        borderRadius:'5px',
        border:'1px solid #4f86ce',
        display:'inline-block',
        minWidth:'70px',
        maxHeight: '40px',
        cursor:'pointer',
        color:'#4f87cc',
        fontFamily:'Trebuchet MS',
        fontSize:'13px',
        // font-weight:bold;
        padding:'4px 10px',
        textDecoration:'none',
        textShadow:'0px 0px 0px #9eb9ff',
        outline: 'none',
        '&:hover': {
            backgroundColor:'transparent',
        },
        '&:active': {
            position:'relative',
            top:'1px',
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    cardRoot: {
        borderRadius: '10px',
        margin: '5px',
      },
    mapContainer: {
        width:'100%',
        height:'400px',
        // [theme.breakpoints.down('md')]: {
        //   height: '300px',
        //   width:'100%'
        // },
        // [theme.breakpoints.down('xs')]: {
        //   height: '300px',
        //   width:'100%'
        // },
    },
    tableContainer: {
        borderRadius: '10px',
      },
      table: {
        minWidth: 450,
      },
})


/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */
class DashboardTestPage extends React.PureComponent {
    static defaultProps = {
        className: "layout",
        cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
        rowHeight: 100
      };
    
      constructor(props) {
        super(props);
        this.state = {
            isEditing: true,
            isDraggable:true,
            isResizable:true,
            items: [0, 1, 2, 3, 4].map(function(i, key, list) {
            return {
                i: i.toString(),
                x: i * 2,
                y: 0,
                w: 2,
                h: 2,
                // static: i === (list.length - 1),
            //   add: i === (list.length - 1)
            };
            }),
            newCounter: 0,
        };
    
        this.onAddItem = this.onAddItem.bind(this);
        this.onBreakpointChange = this.onBreakpointChange.bind(this);
      }
    
      createElement(el) {
        const removeStyle = {
          position: "absolute",
          right: "2px",
          top: 0,
          cursor: "pointer"
        };
        const noDisplay = {
          display: "none",
        };
        const i = el.add ? "+" : el.i;
        return (
          <div key={i} data-grid={el} style={{backgroundColor:'rgba(0,0,0,0.1)', borderRadius:'10px'}}>
            {/* {el.add ? (
              <span
                className="add text"
                onClick={this.onAddItem}
                title="You can add an item by clicking here, too."
              >
                Add +
              </span>
            ) : (
              <span className="text">{i}</span>
            )} */}
            {/* <div style={{margin:'20%'}}>
                <SelectWidgetModal/>
            </div> */}
            <span
                className="remove"
                style={this.state.isEditing? removeStyle: noDisplay}
                onClick={this.onRemoveItem.bind(this, i)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </span>
          </div>
        );
      }
    
      onAddItem() {
        /*eslint no-console: 0*/
        console.log("adding", "n" + this.state.newCounter);
        this.setState({
          // Add a new item. It must have a unique key!
          items: this.state.items.concat({
            i: "n" + this.state.newCounter,
            x: (this.state.items.length * 2) % (this.state.cols || 12),
            y: Infinity, // puts it at the bottom
            w: 2,
            h: 2
          }),
          // Increment the counter to ensure key is always unique.
          newCounter: this.state.newCounter + 1
        });
      }
    
      // We're using the cols coming back from this to calculate where to add new items.
      onBreakpointChange(breakpoint, cols) {
        this.setState({
          breakpoint: breakpoint,
          cols: cols
        });
      }
    
      onLayoutChange = (layout) => {
        console.log('layout')
        console.log(layout)
        // this.props.onLayoutChange(layout);
        this.setState({ layout: layout });
      }
    
      onRemoveItem(i) {
        console.log("removing", i);
        this.setState({ items: _.reject(this.state.items, { i: i }) });
      }

      onEditItem = () => {
        this.setState({
            isEditing: !this.state.isEditing,
            isDraggable: !this.state.isDraggable,
            isResizable: !this.state.isResizable
        })
      }
    
      render() {
        const { classes } = this.props;
        const { isDraggable,
                isResizable } = this.state;

        return (
          <div style={{height:'100vh'}}>
            {/* <div style={{padding:'12px', display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                <h2 style={{fontWeight:700, fontSize:'24px', fontFamily: 'Trebuchet MS', }}>
                    Dashboard
                </h2>
                <div style={{margin:'0px 8px'}} >
                    <Button className={classes.button} variant="outlined" onClick={this.onEditItem}>Toggle Edit</Button>
                </div>
                <div style={{margin:'0px 8px'}} >
                    <Button className={classes.button} variant="outlined" onClick={this.onAddItem}><AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Add Item</Button>
                </div>
            </div> */}
            
            {/* <ResponsiveReactGridLayout
                onLayoutChange={this.onLayoutChange}
                onBreakpointChange={this.onBreakpointChange}
                useCSSTransforms={true}
                // allowOverlap={true}
                isDraggable={isDraggable}
                isResizable={isResizable}
                {...this.props}
            >
              {_.map(this.state.items, el => this.createElement(el))}
            </ResponsiveReactGridLayout> */}

            <Card className={classes.cardRoot} style={{ padding:'8px', height:'700px'}} variant="outlined">
                <CardContent style={{padding:'0px'}} >
                  <div className={classes.mapContainer}>
                    <div style={{display: 'inline-flex', width:'100%', height:'100%', padding: '1px 0px 0px',borderRadius: '8px',overflow:'hidden'}}>
                        <AssetTrackingMap/>
                    </div>
                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="spanning table">
                            <colgroup>
                                
                                <col style={{width:'25%'}}/>
                                <col style={{width:'50%'}}/>
                                <col style={{width:'25%'}}/>
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                <StyledTableCell style={{fontSize: '24px', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={4}>
                                    <b>Assets Tracking</b>
                                </StyledTableCell>
                                </TableRow>
                                <TableRow>
                                <StyledTableCell >Name</StyledTableCell>
                                <StyledTableCell >Location</StyledTableCell>
                                <StyledTableCell >Time</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <StyledTableCell >Asset XYZ</StyledTableCell>
                                    <StyledTableCell >Miami, Fl (Longitutde: -122.493782,Latitude:37.833683)</StyledTableCell>
                                    <StyledTableCell >Jan 10, 12:23:42</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell >Asset XYZ</StyledTableCell>
                                    <StyledTableCell >Miami, Fl (Longitutde: -122.493782,Latitude:37.833683)</StyledTableCell>
                                    <StyledTableCell >Jan 10, 10:13:22</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell >Asset XYZ</StyledTableCell>
                                    <StyledTableCell >Miami, Fl (Longitutde: -122.493782,Latitude:37.833683)</StyledTableCell>
                                    <StyledTableCell >Jan 9, 15:51:21</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell >Asset XYZ</StyledTableCell>
                                    <StyledTableCell >San Francisco, CA (Longitutde: -122.493782,Latitude:37.833683)</StyledTableCell>
                                    <StyledTableCell >Jan 7, 16:11:16</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell >Asset XYZ</StyledTableCell>
                                    <StyledTableCell >San Francisco, CA (Longitutde: -122.493782,Latitude:37.833683)</StyledTableCell>
                                    <StyledTableCell >Jan 7, 18:21:42</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                  </div>
                </CardContent>
              </Card>
          </div>
        );
      }
}

export default withStyles(styles)(DashboardTestPage);



// import React from "react";
// import ReactDOM from "react-dom";

// import Interactable from "./Interactable";

// import "./styles.css";

// const position = { x: 0, y: 0 };
// const cloneOptions = {
//     move: event => {
        
//         // var interaction = event.interaction;

//         // // if the pointer was moved while being held down
//         // // and an interaction hasn't started yet
//         // if (interaction.pointerIsDown && !interaction.interacting()) {
//         //     console.log('clonevent')
//         //     console.log(event)
//         //     var original = event.currentTarget,
//         //     // create a clone of the currentTarget element
//         //     clone = original.cloneNode(true);


//         //     // insert the clone to the page
//         //     // TODO: position the clone appropriately
//         //     const currentDiv= document.querySelector("#clone1")
//         //     currentDiv.appendChild(clone);

//         //     const x =
//         //     (parseFloat(original.getAttribute('data-x')) || 0) +
//         //     original.offsetLeft -
//         //     clone.offsetLeft;
//         //     const y =
//         //     (parseFloat(original.getAttribute('data-y')) || 0) +
//         //     original.offsetTop -
//         //     clone.offsetTop;
//         //     clone.setAttribute('data-x', x);
//         //     clone.setAttribute('data-y', y);
//         //     clone.style.webkitTransform = clone.style.transform =
//         //     'translate(' + x + 'px, ' + y + 'px)';
//         //     // start a drag interaction targeting the clone
//         //     interaction.start({ name: 'drag' }, event.interactable, clone);
//         // }
//         const { currentTarget, interaction } = event;
//         let element = currentTarget;

//         // If we are dragging an item from the sidebar, its transform value will be ''
//         // We need to clone it, and then start moving the clone
//         if (
//         interaction.pointerIsDown &&
//         !interaction.interacting() &&
//         currentTarget.style.transform === ""
//         ) {
//         element = currentTarget.cloneNode(true);

//         // Add absolute positioning so that cloned object lives right on top of the original object
//         element.style.position = "absolute";
//         element.style.left = 0;
//         element.style.top = 0;

//         // Add the cloned object to the document
//         const container = document.querySelector("#container");
//         container && container.appendChild(element);

//         const { offsetTop, offsetLeft } = currentTarget;
//         position.x = offsetLeft;
//         position.y = offsetTop;

//         // If we are moving an already existing item, we need to make sure the position object has
//         // the correct values before we start dragging it
//         } else if (interaction.pointerIsDown && !interaction.interacting()) {
//         const regex = /translate\(([\d]+)px, ([\d]+)px\)/i;
//         const transform = regex.exec(currentTarget.style.transform);

//         if (transform && transform.length > 1) {
//             position.x = Number(transform[1]);
//             position.y = Number(transform[2]);
//         }
//         }

//         // Start the drag event
//         interaction.start({ name: "drag" }, event.interactable, element);
//     }
// };

// const draggableCloneOptions = {
//     manualStart: true,
//     listeners: {
//         move(event) {
//           position.x += event.dx;
//           position.y += event.dy;
//           event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;
//         }
//       }
// };

// const draggableOptions = {
//   onmove: event => {
//     // console.log('event')
//     // console.log(event)
//     const target = event.target;
//     // keep the dragged position in the data-x/data-y attributes
//     const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
//     const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

//     console.log('event.dx')
//     console.log(target.getAttribute('id'))
//     console.log(parseFloat(target.getAttribute("data-x")))

//     // translate the element
//     target.style.webkitTransform = target.style.transform =
//       "translate(" + x + "px, " + y + "px)";

//     // update the posiion attributes
//     target.setAttribute("data-x", x);
//     target.setAttribute("data-y", y);
    
//   },
// //   onend: event => {
// //     console.log('ON end event')
// //     console.log(event)
// //   },
//   inertia: true,
// };

// const resizableOptions = {
//     edges: { top: true, left: true, bottom: true, right: true },
//     listeners: {
//       move: function (event) {
//         let { x, y } = event.target.dataset

//         x = (parseFloat(x) || 0) + event.deltaRect.left
//         y = (parseFloat(y) || 0) + event.deltaRect.top


//         Object.assign(event.target.style, {
//           width: `${event.rect.width}px`,
//           height: `${event.rect.height}px`,
//           transform: `translate(${x}px, ${y}px)`
//         })

//         Object.assign(event.target.dataset, { x, y })
//       }
//     }
// }

// const dataPoint = {
//     x:207.669,
//     y:-1.3565,
//     width: 236,
//     height: 175,
// }

// class DashboardTestPage extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//         droppedItems: [],

//       };

//     this.handleDrop = this.handleDrop.bind(this);
//   }

// //   componentDidMount(){
// //       if(dataPoint){
// //         const currentDiv= document.querySelector("#div1")
// //         const x = (parseFloat(currentDiv.getAttribute("data-x")) || 0) + dataPoint.x;
// //         const y = (parseFloat(currentDiv.getAttribute("data-y")) || 0) + dataPoint.y;

// //         // set width and height
// //         Object.assign(currentDiv.style, {
// //             width: `${dataPoint.width}px`,
// //             height: `${dataPoint.height}px`,
// //           })

// //         // translate the element
// //         currentDiv.style.webkitTransform = currentDiv.style.transform =
// //         "translate(" + x + "px, " + y + "px)";
        

// //         // update the posiion attributes
// //         currentDiv.setAttribute("data-x", x);
// //         currentDiv.setAttribute("data-y", y);

// //       }

// //   }

//   handleDrop(event) {
//     console.log("DROP", event);
//   }

//   addItem = () => {
//     let i = Object.keys(this.state.droppedItems).length + 1;
//     let itemsList = this.state.droppedItems;
//     let newDragItem = {
//         id: "item" + i,
//         // x: 0,
//         // y: 0,
//         // width: 200,
//         // height: 200,

//     }
//     itemsList.push(newDragItem)
//     this.setState({
//         droppedItems: itemsList
//     }, () => {
//         document.getElementById(newDragItem.id).scrollIntoView();
//     })

//   }


  

//   render() {
//     const that = this;
//     const {droppedItems} = this.state

//     console.log('droppedItems')
//     console.log(droppedItems)

//     return (
//       <div className="App">
//         <h1>Edit Dashboard</h1>
//         <button onClick={this.addItem}>
//             Add Item
//         </button>
//         {/* <div id="container">
//             <Interactable key={'cloneTest11'} cloneable={true} cloneOptions={cloneOptions} draggable={true} draggableOptions={draggableOptions}>
//             <div key={'cloneTest1'} id="clone1" className="draggable drag-item">
//                 Clone Me
//             </div>
//             </Interactable>

//         </div> */}

       
//         <Interactable key={'testing11'} draggable={true} draggableOptions={draggableCloneOptions} resizable={true} resizableOptions={resizableOptions}>
//           <div key={'testing1'} id="div1" className="draggable drag-item">
//             Drag Item 1
//           </div>
//         </Interactable>
//         {/* <Interactable key={'testing22'} draggable={true} draggableOptions={draggableOptions} resizable={true} resizableOptions={resizableOptions}>
//           <div key={'testing2'} id="div2" className="draggable drag-item">
//             Drag Item 2
//           </div>
//         </Interactable>
//         <Interactable key={'testing33'} draggable={true} draggableOptions={draggableOptions} resizable={true} resizableOptions={resizableOptions}>
//           <div key={'testing3'} id="div3" className="draggable drag-item">
//             Drag Item 3
//           </div>
//         </Interactable>
//         <Interactable key={'testing44'} draggable={true} draggableOptions={draggableOptions} resizable={true} resizableOptions={resizableOptions}>
//           <div key={'testing4'} id="div4" className="draggable drag-item">
//             Drag Item 4
//           </div>
//         </Interactable>
//         <Interactable key={'testing55'} draggable={true} draggableOptions={draggableOptions} resizable={true} resizableOptions={resizableOptions}>
//           <div key={'testing5'} id="div5" className="draggable drag-item">
//             Drag Item 5
//           </div>
//         </Interactable> */}

//         {droppedItems.map(draggedItem => (
//             <Interactable key={draggedItem.id + "12"}  draggable={true} draggableOptions={draggableOptions} resizable={true} resizableOptions={resizableOptions}>
//                 <div key={draggedItem.id} id={draggedItem.id} className="draggable drag-item">
//                     {draggedItem.id}
//                 </div>
//             </Interactable>
//         ))}


//         <hr />

//         <Interactable
//           dropzone={true}
//           dropzoneOptions={{
//             // only accept elements matching this CSS selector
//             accept: ".drag-item",
//             // Require a 75% element overlap for a drop to be possible
//             overlap: 0.75,

//             // listen for drop related events:

//             ondropactivate: function(event) {
//               // add active dropzone feedback
//               event.target.classList.add("drop-active");
//             },

//             ondragenter: function(event) {
//               var draggableElement = event.relatedTarget,
//                 dropzoneElement = event.target;

//               // feedback the possibility of a drop
//               dropzoneElement.classList.add("drop-target");
//               draggableElement.classList.add("can-drop");
//               draggableElement.textContent = "Dragged in";
//             },

//             ondragleave: function(event) {
//               // remove the drop feedback style
//               event.target.classList.remove("drop-target");
//               event.relatedTarget.classList.remove("can-drop");
//               event.relatedTarget.textContent = "Dragged out";
//             },

//             ondrop: function(event) {
//               that.handleDrop(event);
//               event.relatedTarget.textContent = "Dropped";
//             },

//             ondropdeactivate: function(event) {
//               // remove active dropzone feedback
//               event.target.classList.remove("drop-active");
//               event.target.classList.remove("drop-target");
//             }
//           }}
//         >
//           <div className="dropzone" id="outer-dropzone">
//             #outer-dropzone
//           </div>
//         </Interactable>
//       </div>
//     );
//   }
// }

// export default DashboardTestPage;

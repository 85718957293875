import {getData} from '../../ApiCalls/DataApis';

// export const fetchUsers = () => dispatch => {
//   fetch(BASE_URL + 'users')
//     .then(res => res.json())
//     .then(users => 
//       dispatch({
//         type: FETCH_USERS,
//         payload: users
//       })
//     );
// }

export const fetchAlertChannels = (organization_uuid) => dispatch => {
  
  getData('https://api.iotflows.com/v1/organizations/' + organization_uuid + '/alert-channels')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(data.data){
            dispatch({
              type: 'FETCH_ALERT_CHANNELS',
              payload: data.data 
            })
          }
        }
      });
}

export const fetchSelectedAlertChannel = currentAlertChannel => {
  return dispatch => {
    
    dispatch({ type: "SELECTED_ALERT_CHANNEL", currentAlertChannel });
  };
};

// export const fetchSelectedOrganization = (id) => dispatch => {
//   // TODO: Remove and uncomment when API is ready.
//   dispatch({
//     type: SELECTED_ORGANIZATION,
//     payload: dummyUser
//   })
//   // fetch(BASE_URL + 'user/' + id)
//   //   .then(res => res.json())
//   //   .then(user => 
//   //     dispatch({
//   //       type: SELECTED_USER,
//   //       payload: user
//   //     })
//   //   );
// }


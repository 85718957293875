import React, { Component } from 'react'
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
  } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import Login from './Login';
import Signup from './Signup';
import Reset from './Reset';
import ConfirmForgotPassword from './ConfirmForgotPassword';
import EmailVerification from './EmailVerification';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { withOAuth } from 'aws-amplify-react';
import SnackbarPopUp from '../components/SnackbarPopUp';
import { Provider } from 'react-redux';
import store from '../store.js';
import MainNavPage from '../SideNav/MainNavPage';
import ReactGA from 'react-ga';
import { withAuthenticator } from 'aws-amplify-react';


Amplify.configure({
    Auth: {            
        identityPoolId: 'us-east-1:5684b65a-8bf4-4697-b9a4-87eadbf03ef5',
        region: 'us-east-1',
        identityPoolRegion: 'us-east-1',      
        userPoolId: 'us-east-1_3EP1MRzAy',  
        userPoolWebClientId: '18230ih88kfb3ouj67mrid5er3',
        oauth: {
            domain: 'auth.iotflows.com',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            // redirectSignIn: 'https://www.console.iotflows.com/', 
            // redirectSignOut: 'https://www.console.iotflows.com/', 
            redirectSignIn: 'http://localhost:3000/', 
            redirectSignOut: 'http://localhost:3000/', 
            responseType: 'token', // DO NOT USE 'code'!!! it goes through a forever loop //or 'token', note that REFRESH token will only be generated when the responseType is code
            // responseType: 'code', 
            options: {
                // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
                AdvancedSecurityDataCollectionFlag : true
            },
        }
    }
});

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
   
  });

class CredentialsRouting extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            user: null,
            hasReadUser: null,
            toPath: '',
            redirect: false,
        }
        ReactGA.initialize('UA-169833572-2');
        ReactGA.pageview(window.location.pathname);
        this.setUser = this.setUser.bind(this);
    }

    componentDidMount () {
        Auth.currentAuthenticatedUser()
            .then(user => {
                this.setState({
                    user: user,
                    hasReadUser: true,
                } )
            })
            .catch(this.setState({hasReadUser: true}));
    }

    setUser = (loginUser) => {
        // console.log("Setting USER")
        // console.log(loginUser)
        this.setState(
            {
                user: loginUser,
            })
        
    }

    

    render() {
        const { classes} = this.props
        const { hasReadUser, 
                user, 
                toPath
                } = this.state

        
        return (
            <div className={classes.root}>
                <Router>
                    <Redirect from="</^[^.]+$|\.(?!(css|gif|ico|jpg|js|png|txt|svg|woff|ttf|map|json)$)([^.]+$)/>" to="/" />
                    {hasReadUser !== null && user !== null  && 
                        <div className="App" 
                        // style={globalStyles.DarkTheme}
                        > 
                        
                            <Provider store={store}>
                                <SnackbarPopUp />
                                <MainNavPage/>
                            </Provider>
                        
                        </div>
                    }
                    {hasReadUser !== null && user === null &&
                        
                            <Provider store={store}>
                                <SnackbarPopUp />
                                <Switch>
                                    {/* <Route exact path="/" component={Login} />
                                    <Route path="/login" component={Login} /> */}
                                    <Route exact path="/confirm-forgot-password" component={ConfirmForgotPassword} />
                                    <Route exact path="/verify-email" component={EmailVerification} />
                                    <Route exact path="/login" component={() => <Login setUser={this.setUser} />}  />
                                    <Route exact path="/reset" component={Reset} />
                                    <Route exact path="/signup" component={Signup} />
                                    <Route path="/" component={() => <Login setUser={this.setUser} />}  />
                                    {/* <Route path="/" component={ConfirmForgotPassword}   /> */}
                                </Switch>
                            </Provider>
                        
                    }
                </Router>
            </div>
        )
    }
}

// export default withAuthenticator(withStyles(styles)(CredentialsRouting));
export default withStyles(styles)(CredentialsRouting);

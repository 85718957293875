import React from 'react';
import { withStyles} from '@material-ui/core/styles';
import {getData, postData, putData, deleteData} from '../ApiCalls/DataApis';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import ConnectingToRemoteTunnelModal from './ConnectingToRemoteTunnelModal';
import EditDeviceCredentialsModal from '../components/EditDeviceCredentialsModal';
import SshConnectedInfoModal from '../components/SshConnectedInfoModal';
import AddRemoteConnectionModal from './AddRemoteConnectionModal';
import DeleteRemoteConnectionModal from './DeleteRemoteConnectionModal';
import EditRemoteConnectionModal from './EditRemoteConnectionModal';
import VerifyReconfigureRemoteAgentModal from './VerifyReconfigureRemoteAgentModal';
import MqttService from '../core/services/MqttService';
import Grid from '@material-ui/core/Grid';
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNightBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import FileManagementTable from "./FileManagementTable.js"
import FileManagerCredentialsModal from './FileManagerCredentialsModal';
import HeaderDevice from './HeaderDevice';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  tableContainer: {
    borderRadius: '10px',
  },
  table: {
    minWidth: 450,
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    minWidth:'70px',
    maxHeight: '23px',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'10px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  expireCSS: {
    borderRadius: '5px',
    // border:'1px solid rgba(255, 0, 0,0.8)',
    backgroundColor: 'rgba(255, 0, 0,0.4)',
    height: '20px',
    width: '88px',
    padding: '1px 6px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  setUpBtn: {
    borderRadius: '5px',
    color: '#2a2a2f',
    border: '1px solid rgba(42, 42, 47,0.7)',
    backgroundColor: 'transparent',
    marginRight: '10px',
  },
  tourIconCSS: {
    padding: '0px', 
    marginLeft: '10px',
    marginRight: 'auto',
    borderRadius: '20px',
    // marginLeft: '0px', 
    // marginBottom: '0px',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);





class RemoteConnectionsTabPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isConnected: false,
      message: '',
      nodeRedConnectionInfo:[],
      sshConnectionInfo:[],
      hasNodeRedConnection: false,
      connectionEstablished: false,
      device_disabled: this.props.device_disabled,
      device_uuid: this.props.device_uuid,
      server_url_ssh: '',
      openSshConnectedInfoModal: false,
      openFileManagerCredentialsModal: false,
      count: true,
      customConnections: [],
      hasSshConnection: false,
      connectionFailed:false,
      openConfigurationModal: false,
      loadingDevice: false,
      loadingServer: false,
      successDevice: false,
      connectedDevice: false,
      connectedServer: false,
      hasConnection: false,
      selectedDevice: this.props.selectedDevice
    }
    
    this.handleCloseConfigurationModal= this.handleCloseConfigurationModal.bind(this);
    this.handlerCloseSshInfoModal= this.handlerCloseSshInfoModal.bind(this);
    this.handlerCloseFileManagerCredentialsModal= this.handlerCloseFileManagerCredentialsModal.bind(this);
    this.refreshRemoteConnectionList= this.refreshRemoteConnectionList.bind(this);
    this.onReceiveNotifications= this.onReceiveNotifications.bind(this);
  }

  componentDidMount() {


    getData('https://api.iotflows.com/v1/devices/'+ this.props.device_uuid +'/connections')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        var otherConnections = [];
        data.data.map((connection, i) => {
          if(connection.device_connection_type_name == 'NODERED'){
            this.setState({
              nodeRedConnectionInfo: connection,
              hasNodeRedConnection: true,
            })
          }else if(connection.device_connection_type_name == 'SSH'){
            this.setState({
              sshConnectionInfo: connection,
              hasSshConnection: true,
              server_url_ssh: connection.device_connection_management_server_url,
            })
          } else {
            otherConnections.unshift(connection);
              
          }
        })
        this.setState({ customConnections: otherConnections })
      }
    });

    if(MqttService.isConnected) {
      MqttService.subscribe('v1/api/users/' + this.props.currentUserInfo[0].user_username + '/notifications', this.onReceiveNotifications)
    }
  }

  onReceiveNotifications = message => {

    try{
      const notification_message = JSON.parse(message.payloadString);
    
      if(notification_message.service == 'remote-tunnel' && notification_message.source == 'Device Connection Management Server' && notification_message.code == 200)
      {
        this.setState({connectedServer: true}, ()=>{this.connectedDevice()})
        this.props.showSuccessSnackbar("Server Configured Successfully");
      }
      else if(notification_message.service == 'remote-tunnel' && notification_message.source == 'Device Connection Management Server' && notification_message.code == 500)
      {
        this.setState({failed: true})
        this.props.showErrorSnackbar("Server Configuration Error..")
      }
      else if(notification_message.service == 'remote-tunnel' && notification_message.source == 'Device' && notification_message.code == 200)
      {
        this.setState({connectedDevice: true}, ()=>{this.connectedDevice()})
        this.props.showSuccessSnackbar("Device is configured properly")
      } 
    }
    catch(e){
      console.log("Error in reading On receiving notification from MQTT");
      console.log(e.message);
    }
  }

  connectedDevice(){
   
    if(this.state.connectedServer && this.state.connectedDevice){
      this.setState({
        connectionEstablished: true,
        loadingServer: false, 
        loadingDevice: false,
        successServer: true,
        successDevice: true
      }, () => this.handleCloseConfigurationModal());
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      device_disabled: nextProps.device_disabled,
      device_uuid: nextProps.device_uuid,
      selectedDevice: nextProps.selectedDevice,
    }); 
  };

  toggleChecked = (connectionInfo) => {
    if(connectionInfo.device_connection_is_enabled){
      this.setState( { openConfigurationModal: false, loadingServer: false, loadingDevice: false, successServer: false, successDevice: false},
        () => {
          putData('https://api.iotflows.com/v1/devices/'+ this.props.device_uuid + '/connections', 
          {'from_port': connectionInfo.device_connection_from_port,
          'from_ip':connectionInfo.device_connection_from_ip,
          'enable_connection': false})
          .then(async response => {
            if(response.ok){ 
              // const data = await response.json();
              this.refreshRemoteConnectionList();
              this.props.showSuccessSnackbar("Connection has been disconnected!");
            }
            else{
              this.refreshRemoteConnectionList();
              this.props.showErrorSnackbar("Connection failed to disconnected");

            }
          })
        } )
    }else if(connectionInfo.device_connection_is_enabled == false){
      this.setState( {openConfigurationModal: true,loadingServer: true, loadingDevice: true},
        // callback function inside setState to connect to Node Red live session
        () =>{ 
          putData('https://api.iotflows.com/v1/devices/'+ this.props.device_uuid + '/connections', 
          {'from_port': connectionInfo.device_connection_from_port,
            'from_ip':connectionInfo.device_connection_from_ip,
            'enable_connection': true})
            .then(async response => {
              if(response.ok){
                // const data = await response.json();
                this.refreshRemoteConnectionList();
                this.props.showSuccessSnackbar("Connection has been requested successfully!");
                this.timer = setTimeout(() => {
                  if(this.state.connectionEstablished){
                  }else{
                    this.setState({connectionFailed: true});
                  }
                }, 5000);
              }
              else{
                this.props.showErrorSnackbar("Connection request failed");
              }
            })
        })
    } 
  }

  refreshRemoteConnectionList(){
    getData('https://api.iotflows.com/v1/devices/'+ this.props.device_uuid +'/connections')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        this.setState({
          hasNodeRedConnection: false,
          hasSshConnection: false,
        }, () => {
          var otherConnections = [];
          data.data.map((connection, i) => {
            if(connection.device_connection_type_name == 'NODERED'){
              this.setState({
                nodeRedConnectionInfo: connection,
                hasNodeRedConnection: true,
              })
            }else if(connection.device_connection_type_name == 'SSH'){
              this.setState({
                sshConnectionInfo: connection,
                hasSshConnection: true,
                server_url_ssh: connection.device_connection_management_server_url,
              })
            } else {
              otherConnections.unshift(connection);
                
            }
          })
          this.setState({ customConnections: otherConnections })
        })
        
      }
    });
  }

  componentWillUnmount() {
    MqttService.unsubscribe('v1/api/users/' + this.props.currentUserInfo[0].user_username + '/notifications');
    localStorage.removeItem('ssh_username');
    localStorage.removeItem('ssh_password');

  }

  handleCloseConfigurationModal = () => {
    this.setState({ 
      openConfigurationModal: false,
      loadingServer: false, 
      loadingDevice: false,
      connectionFailed: false,
      connectedDevice: false,
      connectedServer: false,
     });
  };

  handlerOpenSshModal = () => {
    this.setState({ 
      openSshConnectedInfoModal: true,
      count: true,
    });
  }

  handlerCloseSshInfoModal = () => {
    this.setState({ 
      openSshConnectedInfoModal: false,
      count: true,
    });
  }
  handlerFileManagerCredentialsModal = () => {
    this.setState({ 
      openFileManagerCredentialsModal: true
    });
  }
  handlerCloseFileManagerCredentialsModal = () => {
    this.setState({ 
      openFileManagerCredentialsModal: false
    });
  }
  
  dateConverter(date){
    // date.toLocaleDateString() + ', ' + date.toLocaleTimeString();
    if(date != undefined){
      var modifyDate = date;
      modifyDate = new Date(modifyDate);
      return modifyDate.toLocaleDateString([], {  month: 'short', day: 'numeric' });
    } else {
      return " "
    }
  }



  render(){
    const { classes} = this.props
    const {device_disabled,
          server_url_ssh, 
          openSshConnectedInfoModal, 
          openFileManagerCredentialsModal,
          count,
          customConnections,
          hasNodeRedConnection,
          hasSshConnection,
          nodeRedConnectionInfo,
          sshConnectionInfo,
          connectionFailed,
          loadingServer,
          loadingDevice,
          successDevice,
          openConfigurationModal,
          successServer} = this.state

          


    return (
      <div className={classes.root} >
        <HeaderDevice 
            selected_device={this.props.selected_device} 
            selectedDevice={this.props.selectedDevice} 
            isDeviceOnline={this.props.isDeviceOnline} 
            deviceGeolocation={this.props.deviceGeolocation} 
            device_uuid={this.props.device_uuid}
            lastHeard={this.props.lastHeard} 
            handleClickStartDemo={this.props.handleClickStartDemo}
            deviceInfo= {this.props.deviceInfo}
          />

        <TableContainer component={Paper} >
          <Table className={classes.table} aria-label="spanning table">
            <colgroup>
              
              <col style={{width:'20%'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'10%'}}/>
              <col style={{width:'15%'}}/>
              <col style={{width:'5%'}}/>
              <col style={{width:'5%'}}/>
              <col style={{width:'5%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{fontSize: '24px', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={5}>
                  <b>Device Remote Connections</b>
                 
                </StyledTableCell>
                <StyledTableCell className="tourRemoteAccessKey" style={{paddingRight: '12px'}} align="right" colSpan={3}>
                  <AddRemoteConnectionModal device_uuid={this.props.device_uuid} refreshRemoteConnectionList={this.refreshRemoteConnectionList} />
                </StyledTableCell>
                <StyledTableCell className="tourRemoteConnection" style={{paddingRight: '12px'}} align="right" colSpan={2}>
                {!this.props.selectedDevice.device_is_virtual ? <VerifyReconfigureRemoteAgentModal device_uuid={this.props.device_uuid} /> : "" }
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell >Name</StyledTableCell>
                <StyledTableCell >IP</StyledTableCell>
                <StyledTableCell >Port</StyledTableCell>
                <StyledTableCell >Slug</StyledTableCell>
                <StyledTableCell style={{textAlign: 'right', paddingRight: '24px'}}>Enable</StyledTableCell>
                <StyledTableCell ></StyledTableCell> 
                <StyledTableCell ></StyledTableCell> 
                <StyledTableCell ></StyledTableCell>
                <StyledTableCell ></StyledTableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {/* SSH connection row */}
              {!hasSshConnection? null:
                <TableRow >
                  <StyledTableCell >SSH</StyledTableCell>
                  <StyledTableCell >{sshConnectionInfo.device_connection_from_ip}</StyledTableCell>
                  <StyledTableCell >{sshConnectionInfo.device_connection_from_port}</StyledTableCell>
                  <StyledTableCell >{sshConnectionInfo.device_connection_slug}</StyledTableCell>
                  <StyledTableCell style={{textAlign: 'right',}} >
                    <LightTooltip className={classes.hoverText} title={'Activate SSH Connection'} placement="top-end" arrow> 
                      <FormControlLabel style={{textAlign:'right'}}
                      value="activateSSH"
                      control={<Switch size="small" color="primary" checked={sshConnectionInfo.device_connection_is_enabled} onChange={()=> this.toggleChecked(sshConnectionInfo)} />}
                      label=""
                      labelPlacement="end"
                      disabled={device_disabled}
                      />
                    </LightTooltip>
                  </StyledTableCell>
                  <StyledTableCell style={{textAlign: 'right',}}>
                    <Button className={classes.button} disabled={!sshConnectionInfo.device_connection_is_enabled || device_disabled} onClick={this.handlerOpenSshModal} variant="contained" >
                      Connect        
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell style={{textAlign: 'left',}} colSpan={2}>
                    <Button className={classes.button} style={{minWidth: '135px'}} disabled={!sshConnectionInfo.device_connection_is_enabled || device_disabled} onClick={this.handlerFileManagerCredentialsModal} variant="contained" >
                      Secure File Transfer     
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell style={{textAlign: 'center'}}>
                      <DeleteRemoteConnectionModal remoteConnectionName={sshConnectionInfo.device_connection_name} device_uuid={this.props.device_uuid} portNumber={sshConnectionInfo.device_connection_from_port} from_ip={sshConnectionInfo.device_connection_from_ip} refreshRemoteConnectionList={this.refreshRemoteConnectionList} />
                      {/* <div className={classes.expireCSS} >
                        <span style={{fontSize: '10px', color:'rgb(107,107,107)', textAlign:'center'}} >EXPIRES:{this.dateConverter(sshConnectionInfo.device_connection_expire_at)}</span>
                      </div> */}
                  </StyledTableCell>
                </TableRow>
              }
              {customConnections.map((connection, index) => (
                <TableRow key={index} id={index}>
                <StyledTableCell >{connection.device_connection_name}</StyledTableCell>
                <StyledTableCell >{connection.device_connection_from_ip}</StyledTableCell>
                <StyledTableCell >{connection.device_connection_from_port}</StyledTableCell>
                <StyledTableCell >{connection.device_connection_slug}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'right',}}>
                  <LightTooltip className={classes.hoverText} title={`Activate ${connection.device_connection_name} Connection`} placement="top-end" arrow> 
                    <FormControlLabel style={{textAlign:'right'}}
                    value="activateSSH"
                    control={<Switch size="small" color="primary" checked={connection.device_connection_is_enabled} onChange={()=> this.toggleChecked(connection)} />}
                    label=""
                    labelPlacement="end"
                    disabled={device_disabled}
                    />
                  </LightTooltip>
                </StyledTableCell>
                <StyledTableCell  style={{textAlign: 'right',}}>
                  <Button className={classes.button} disabled={!connection.device_connection_is_enabled || device_disabled}
                    variant="contained"
                    onClick={() => window.open('https://'+ connection.device_connection_slug + '-' + connection.device_connection_from_port + '-'  + this.props.device_uuid + '.' + connection.device_connection_management_server_url  , "_blank")}
                  >
                    Connect       
                  </Button>
                </StyledTableCell>
                <StyledTableCell style={{textAlign: 'right',}} >
                    {/* empty column due to no change in credentials like NODE RED */}
                  </StyledTableCell>
                <StyledTableCell style={{textAlign: 'right',}} >
                  <EditRemoteConnectionModal refreshRemoteConnectionList={this.refreshRemoteConnectionList} connectionInfo={connection} />
                </StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}} >
                    <DeleteRemoteConnectionModal remoteConnectionName={connection.device_connection_name} device_uuid={this.props.device_uuid} portNumber={connection.device_connection_from_port} from_ip={connection.device_connection_from_ip} refreshRemoteConnectionList={this.refreshRemoteConnectionList}/>
                    {/* <div className={classes.expireCSS} >
                      <span style={{fontSize: '10px', color:'rgb(107,107,107)', textAlign:'center'}} >EXPIRES:{this.dateConverter(connection.device_connection_expire_at)}</span>
                    </div> */}
                </StyledTableCell>
              </TableRow>
              ))}
              {/* NodeRed connection row */}
              {!hasNodeRedConnection ? null :
                <TableRow >
                  <StyledTableCell >Node-RED</StyledTableCell>
                  <StyledTableCell >{nodeRedConnectionInfo.device_connection_from_ip}</StyledTableCell>
                  <StyledTableCell >{nodeRedConnectionInfo.device_connection_from_port}</StyledTableCell>
                  <StyledTableCell >{nodeRedConnectionInfo.device_connection_slug}</StyledTableCell>
                  <StyledTableCell style={{textAlign: 'right',}}>
                    <LightTooltip className={classes.hoverText} title={'Activate Node-Red Connection'} placement="top-end" arrow> 
                      <FormControlLabel
                        value="activateNodeRed"
                        disabled={device_disabled}
                        control={<Switch size="small" color="primary" checked={nodeRedConnectionInfo.device_connection_is_enabled} onChange={()=> this.toggleChecked(nodeRedConnectionInfo)}/>}
                      />
                    </LightTooltip>
                  </StyledTableCell>
                  <StyledTableCell  style={{textAlign: 'right',}}>
                    <Button className={classes.button} disabled={!nodeRedConnectionInfo.device_connection_is_enabled || device_disabled}
                      variant="contained"
                      onClick={() => window.open('https://' + nodeRedConnectionInfo.device_connection_slug + '-' + nodeRedConnectionInfo.device_connection_from_port + '-' + this.props.device_uuid + '.' + nodeRedConnectionInfo.device_connection_management_server_url + '/nodered/' , "_blank")}
                    >
                      Connect         
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell style={{textAlign: 'left'}}>
                    <EditDeviceCredentialsModal device_uuid={this.props.device_uuid} device_disabled={device_disabled}  />
                  </StyledTableCell>
                  <StyledTableCell style={{}}>
                    <EditRemoteConnectionModal refreshRemoteConnectionList={this.refreshRemoteConnectionList} connectionInfo={nodeRedConnectionInfo} />
                  </StyledTableCell>
                  <StyledTableCell style={{textAlign: 'center', }} >
                      <DeleteRemoteConnectionModal remoteConnectionName={nodeRedConnectionInfo.device_connection_name} device_uuid={this.props.device_uuid} portNumber={nodeRedConnectionInfo.device_connection_from_port} from_ip={nodeRedConnectionInfo.device_connection_from_ip} refreshRemoteConnectionList={this.refreshRemoteConnectionList}/>
                      {/* <div className={classes.expireCSS} >
                        <span style={{fontSize: '10px', color:'rgb(107,107,107)', textAlign:'center'}} >EXPIRES:{this.dateConverter(nodeRedConnectionInfo.device_connection_expire_at)}</span>
                      </div> */}
                  </StyledTableCell>
                </TableRow>
              }
              {/* {!hasNodeRedConnection ? null :
                <TableRow >
                  <StyledTableCell style={{borderTop: 'none'}} ></StyledTableCell>
                  <StyledTableCell style={{borderTop: 'none'}} ></StyledTableCell>
                  <StyledTableCell style={{borderTop: 'none'}} colSpan={3} style={{textAlign: 'right'}}></StyledTableCell>
                  <StyledTableCell style={{textAlign: 'right', borderTop: 'none'}} colSpan={2}>
                    <EditDeviceCredentialsModal device_uuid={this.props.device_uuid} device_disabled={device_disabled}  />
                  </StyledTableCell>
                </TableRow>
              } */}
            </TableBody>
          </Table>
        </TableContainer>

        {this.props.device_uuid && sshConnectionInfo && sshConnectionInfo.device_connection_is_enabled && localStorage.getItem('ssh_username') && localStorage.getItem('ssh_password') ? 
          <FileManagementTable path={'/home/'+localStorage.getItem('ssh_username')} instrumentType_uuid={`devices/${this.props.device_uuid}`} server_url_ssh={server_url_ssh} /> 
          : null}

        {/* Remote connection modal */}
        <ConnectingToRemoteTunnelModal
          successServer={successServer}
          openConfigurationModal = {openConfigurationModal} 
          loadingDevice = {loadingDevice}
          loadingServer= {loadingServer}
          failed={connectionFailed}
          handleCloseConfigurationModal={this.handleCloseConfigurationModal}
          successDevice= {successDevice}
          />

        {/* SSH connection information modal */}
        {openSshConnectedInfoModal ? 
          <SshConnectedInfoModal 
          open={openSshConnectedInfoModal} 
          first={count} 
          handlerCloseSshInfoModal={this.handlerCloseSshInfoModal} 
          server_url_ssh={server_url_ssh} 
          device_uuid={this.props.device_uuid}
          selectedDevice={this.props.selectedDevice} />
        : null}
        
        {openFileManagerCredentialsModal ? 
          <FileManagerCredentialsModal 
          open={openFileManagerCredentialsModal} 
          device_uuid={this.props.device_uuid}
          handlerCloseFileManagerCredentialsModal={this.handlerCloseFileManagerCredentialsModal} />
        : null}
        
        
      </div>
      )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    currentUserInfo: state.users.currentUserInfo,
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(RemoteConnectionsTabPage));
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Portal from '@material-ui/core/Portal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import  {putData, getData } from '../ApiCalls/DataApis';


// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    // fontSize:'14px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  columnSpacing: {
    padding: '0px',
  },
  dialog: { borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});





class ManageNotificationsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      loading: false,
      success: false,
      openSnackbar: false,
      notification_push_major: false,
      notification_sms_major: false,
      notification_email_major: false,
      notification_push_minor: false,
      notification_sms_minor: false,
      notification_email_minor: false,
      notification_push_informative: false,
      notification_sms_informative: false,
      notification_email_informative: false,
      notification: '',
      severity: '',
      notificationStatus: '',
      active_phone_number: true,
    };

  }
  

  componentDidMount() {
    // console.log('this.props.currentUserInfo')
    // console.log(this.props.currentUserInfo)
    if(this.props.currentUserInfo[0].user_phone_number != undefined){
      // console.log('not undefined')
      this.setState({active_phone_number: false})
    }

    getData('https://api.iotflows.com/v1/alert-channels/' + this.props.selectedAlertChannel.alert_channel_uuid + '/members/notifications')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          // console.log('data.data')
          // console.log(data)
          if(data.is_member){
            this.setState({
              notification_push_major: data.data[0].notification_push_major,
              notification_sms_major: data.data[0].notification_sms_major,
              notification_email_major: data.data[0].notification_email_major,
              notification_push_minor: data.data[0].notification_push_minor,
              notification_sms_minor: data.data[0].notification_sms_minor,
              notification_email_minor: data.data[0].notification_email_minor,
              notification_push_informative: data.data[0].notification_push_informative,
              notification_sms_informative : data.data[0].notification_sms_informative, 
              notification_email_informative: data.data[0].notification_email_informative, 
            })
          }
          else{
            this.props.showErrorSnackbar("You are not a member of Alert Channel")
          }
        }
        
      });
  }

  handleChangeMajorPush = () => {
    this.state.notification_push_major == true ? this.setState( {notification_push_major: false, notification: 'Push notifications', severity: 'major alerts',notificationStatus: 'OFF', openSnackbar: true} ) 
    : this.setState( {notification_push_major: true, notification: 'Push notifications', severity: 'major alerts',notificationStatus: 'ON', openSnackbar: true} )
  }
  handleChangeMajorSMS = () => {
    this.state.notification_sms_major == true ? this.setState( {notification_sms_major: false, notification: 'SMS notifications', severity: 'major alerts',notificationStatus: 'OFF', openSnackbar: true} ) 
    : this.setState( {notification_sms_major: true, notification: 'SMS notifications', severity: 'major alerts',notificationStatus: 'ON', openSnackbar: true} )
  }
  handleChangeMajorEmail = () => {
    this.state.notification_email_major == true ? this.setState( {notification_email_major: false, notification: 'Email notifications', severity: 'major alerts',notificationStatus: 'OFF', openSnackbar: true} ) 
    : this.setState( {notification_email_major: true, notification: 'Email notifications', severity: 'major alerts',notificationStatus: 'ON', openSnackbar: true} )
  }

  handleChangeMinorPush = () => {
    this.state.notification_push_minor == true ? this.setState( {notification_push_minor: false, notification: 'Push notifications', severity: 'minor alerts',notificationStatus: 'OFF', openSnackbar: true} ) 
    : this.setState( {notification_push_minor: true, notification: 'Push notifications', severity: 'minor alerts',notificationStatus: 'ON', openSnackbar: true} )
  }
  handleChangeMinorSMS = () => {
    this.state.notification_sms_minor == true ? this.setState( {notification_sms_minor: false, notification: 'SMS notifications', severity: 'minor alerts',notificationStatus: 'OFF', openSnackbar: true} ) 
    : this.setState( {notification_sms_minor: true, notification: 'SMS notifications', severity: 'minor alerts',notificationStatus: 'ON', openSnackbar: true} )
  }
  handleChangeMinorEmail = () => {
    this.state.notification_email_minor == true ? this.setState( {notification_email_minor: false, notification: 'Email notifications', severity: 'minor alerts',notificationStatus: 'OFF', openSnackbar: true} ) 
    : this.setState( {notification_email_minor: true, notification: 'Email notifications', severity: 'minor alerts',notificationStatus: 'ON', openSnackbar: true} )
  }

  handleChangeInformativePush = () => {
    this.state.notification_push_informative == true ? this.setState( {notification_push_informative: false, notification: 'Push notifications', severity: 'informative alerts',notificationStatus: 'OFF', openSnackbar: true} ) 
    : this.setState( {notification_push_informative: true, notification: 'Push notifications', severity: 'informative alerts',notificationStatus: 'ON', openSnackbar: true} )
  }
  handleChangeInformativeSMS = () => {
    this.state.notification_sms_informative == true ? this.setState( {notification_sms_informative: false, notification: 'SMS notifications', severity: 'informative alerts',notificationStatus: 'OFF', openSnackbar: true} ) 
    : this.setState( {notification_sms_informative: true, notification: 'SMS notifications', severity: 'informative alerts',notificationStatus: 'ON', openSnackbar: true} )
  }
  handleChangeInformativeEmail = () => {
    this.state.notification_email_informative == true ? this.setState( {notification_email_informative: false, notification: 'Email notifications', severity: 'informative alerts',notificationStatus: 'OFF', openSnackbar: true} ) 
    : this.setState( {notification_email_informative: true, notification: 'Email notifications', severity: 'informative alerts',notificationStatus: 'ON', openSnackbar: true} )
  }

  timer = undefined;

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentWillReceiveProps({open}) {
    this.setState({...this.state,open})
  }

  componentDidUpdate(prevState) {
    if(prevState.open != this.state.open){
      getData('https://api.iotflows.com/v1/alert-channels/' + this.props.selectedAlertChannel.alert_channel_uuid + '/members/notifications')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          // console.log('data.data')
          // console.log(data)
          if(data.is_member){
            this.setState({
              notification_push_major: data.data[0].notification_push_major,
              notification_sms_major: data.data[0].notification_sms_major,
              notification_email_major: data.data[0].notification_email_major,
              notification_push_minor: data.data[0].notification_push_minor,
              notification_sms_minor: data.data[0].notification_sms_minor,
              notification_email_minor: data.data[0].notification_email_minor,
              notification_push_informative: data.data[0].notification_push_informative,
              notification_sms_informative : data.data[0].notification_sms_informative, 
              notification_email_informative: data.data[0].notification_email_informative, 
            })
          }
          else{
            this.props.showErrorSnackbar("You are not a member of Alert Channel")
          }
        }
        
      });
    }
    if(this.state.success){
      this.setState({
        success: false,
        
      })
    }
  }

  submitButtonClick = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          // console.log('this.state.notification_push_informative')
          // console.log(this.state.notification_push_informative)
        putData('https://api.iotflows.com/v1/alert-channels/'+ this.props.selectedAlertChannel.alert_channel_uuid+'/members/notifications', 
        {'notification_push_informative': this.state.notification_push_informative,
          "notification_push_minor": this.state.notification_push_minor,
          "notification_push_major": this.state.notification_push_major,
          "notification_sms_informative": this.state.notification_sms_informative,
          "notification_sms_minor": this.state.notification_sms_minor,
          "notification_sms_major": this.state.notification_sms_major,
          "notification_email_informative": this.state.notification_email_informative,
          "notification_email_minor": this.state.notification_email_minor,
          "notification_email_major": this.state.notification_email_major})
          .then(async response => {
            if(response.ok){
              this.props.showSuccessSnackbar("Alert channel notification has been successfully modified!");
              this.setState({
                loading: false,
                success: true,
                open:false,
              }, () => {this.props.handler()} );
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false,
                }, () => {this.props.handler()} );
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  render() {
    const { classes, forwardedRef, ...props } = this.props;

    const { open, loading, success ,notification, severity, 
            notification_push_major, notification_sms_major, notification_email_major,
            notification_push_minor, notification_sms_minor, notification_email_minor,
            notification_push_informative, notification_sms_informative, notification_email_informative, active_phone_number} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    
    

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      > 
          <Dialog
              open={open}
              onClose={this.props.handler}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '5px'}} id="alert-dialog-slide-title">{"Manage Alert Channel Notifications"}</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '15px', paddingTop: '15px'}}>
                
                  <Container fluid>
                    <Row>
                      <Col> </Col>
                      <Col>Push</Col>
                      <Col>SMS</Col>
                      <Col>Email</Col>
                    </Row>
                    <Row>
                      <Col className={classes.columnSpacing}>Major</Col>
                      <Col className={classes.columnSpacing}>
                        <FormControlLabel control={<Switch disabled checked={notification_push_major} onChange={this.handleChangeMajorPush} name="notification_push_major" color="primary" />}  />
                      </Col>
                      <Col className={classes.columnSpacing}>
                        <FormControlLabel control={<Switch disabled={active_phone_number} checked={notification_sms_major} onChange={this.handleChangeMajorSMS} name="notification_sms_major" color="primary" />} />
                      </Col>
                      <Col className={classes.columnSpacing}>
                        <FormControlLabel control={<Switch checked={notification_email_major} onChange={this.handleChangeMajorEmail} name="notification_email_major" color="primary" />}  />
                      </Col>
                    </Row>
                    <Row>
                      <Col className={classes.columnSpacing}>Minor</Col>
                      <Col className={classes.columnSpacing}>
                        <FormControlLabel control={<Switch disabled checked={notification_push_minor} onChange={this.handleChangeMinorPush} name="notification_push_minor" color="primary" />}  />
                      </Col>
                      <Col className={classes.columnSpacing}>
                        <FormControlLabel control={<Switch disabled={active_phone_number} checked={notification_sms_minor} onChange={this.handleChangeMinorSMS} name="notification_sms_minor" color="primary" />} />
                      </Col>
                      <Col className={classes.columnSpacing}>
                        <FormControlLabel control={<Switch checked={notification_email_minor} onChange={this.handleChangeMinorEmail} name="notification_email_minor" color="primary" />}  />
                      </Col>
                    </Row>
                    <Row>
                      <Col className={classes.columnSpacing}>Informative</Col>
                      <Col className={classes.columnSpacing}>
                        <FormControlLabel control={<Switch disabled checked={notification_push_informative} onChange={this.handleChangeInformativePush} name="notification_push_informative" color="primary" />}  />
                      </Col>
                      <Col className={classes.columnSpacing}>
                        <FormControlLabel control={<Switch disabled={active_phone_number} checked={notification_sms_informative} onChange={this.handleChangeInformativeSMS} name="notification_sms_informative" color="primary" />} />
                      </Col>
                      <Col className={classes.columnSpacing}>
                        <FormControlLabel control={<Switch checked={notification_email_informative} onChange={this.handleChangeInformativeEmail} name="notification_email_informative" color="primary" />}  />
                      </Col>
                    </Row>
                  </Container>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.props.handler} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

ManageNotificationsModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})

const mapStateToProps = state => {
  return {
    
    selectedAlertChannel: state.alertChannels.selectedAlertChannel,
    currentUserInfo: state.users.currentUserInfo,
  }
}
// const mapStateToProps = state => state

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ManageNotificationsModal));

import React from 'react';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {getData, postData, putData, deleteData} from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import LeaveProjectModal from '../components/LeaveProjectModal';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { Grid} from '@material-ui/core';
import HighchartsNorm from 'highcharts'
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {apiLink} from '../ApiCalls/ApisVariables.js'
// import {globalStyles} from "../styles/globalStyles";



const styles = theme => ({
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '3px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
  },
  addBtnCSS: {
    color: '#2196f3',
    backgroundColor:'transparent',
    fontSize: 'large',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  addBtnCSS1: {
    color: '#2196f3',
    backgroundColor:'transparent',
    fontSize: '40px',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  cardRootTodays: {
    borderRadius: '8px',
    margin: '4px 4px 4px 0px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('sm')]: {
      margin: '4px 2px 4px 0px',
    },
  },
  cardRootMonths: {
    borderRadius: '8px',
    margin: '4px 4px 4px 4px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('sm')]: {
      margin: '4px 0px 4px 2px',
    },
  },
  cardHeader: {
    fontSize:'20px',
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    // fontFamily:'"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
    textAlign:'center',
    marginTop: '5px',
    marginBottom: '2px',
    paddingBottom: '0px',
  },
});

class ProjectListPage extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      leaveProjectModalOpen: false,
      selected_project: [],
      selected_project_uuid: '',
      project_added: false,
      project_edited: false,
      project_deleted: false,
      columns: [
        { title: 'Project Name', 
          field: 'project_name',         
        },
        { title: 'Privacy',
          field: 'project_is_private',
          lookup: { true: 'Private', false: 'Public' }, 
        },
        { title: 'Project Description', field: 'project_description' },
        { title: 'Project Role', field: 'project_member_role_name', editable:'never'},
      ],
      projects_list: [],

    };
    this.handleCloseLeaveProjectModal = this.handleCloseLeaveProjectModal.bind(this)
    this.refreshProjectList = this.refreshProjectList.bind(this)
  }

  componentDidMount() {
        
    getData(apiLink + '/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        // console.log('data', data)
        this.setState({
          projects_list: data.data ,
        })
      }
    });
  }

  refreshProjectList() {
    getData(apiLink + '/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        this.setState({
          projects_list: data.data ,
        })
      }
    });
  }

  componentDidUpdate(prevState, prevProps) {
    if(this.state.project_added){
      this.refreshProjectList();
      this.setState({project_added: false})
    }
    if(this.state.project_edited){
      this.refreshProjectList();
      this.setState({project_edited: false})
    }
    if(this.state.project_deleted){
      this.refreshProjectList();
      this.setState({project_deleted: false})
    }
  }

  handleLeaveProjectModalOpen = (project_uuid) => {
    this.setState({ leaveProjectModalOpen: true });
    this.setState({selected_project_uuid: project_uuid})
  };

  handleCloseLeaveProjectModal = () => {
    this.setState({ 
      leaveProjectModalOpen: false,
     });
  };


  render(){
    const { columns, 
            projects_list, 
            selectedOrganization, 
            leaveProjectModalOpen, 
            selected_project_uuid, 
            selected_project, 
            project_added, 
            project_edited,
            project_deleted,} = this.state
    const { classes, currentUserInfo} = this.props
    // if (this.state.redirect) {
    //   return <Redirect push to={this.state.toPath} />
    // }
    if (this.state.redirect) {
      return <Redirect push to={{pathname: this.state.toPath, state: {selected_project_name: this.state.selected_project.project_name} }} />
    }
    

    return (
      <div>
        <div className={classes.breadcrumbCSS}>
          <Breadcrumbs  
            // style={{color: globalStyles.LightDarkTheme.color}} 
            separator="›" aria-label="breadcrumb">
            {/* <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/" >
              {this.props.selectedOrganization.organization_name}
            </Link> */}
            {/* <Link color="inherit" href="/projects" >
              Projects
            </Link> */}
            <Typography className={classes.fontStyles} 
              // style={{color: globalStyles.LightDarkTheme.color}}
            >
                {this.props.selectedOrganization.organization_name}
            </Typography>
            <Typography className={classes.fontStyles} 
              // style={{color: globalStyles.DarkTheme.color}} 
            >
              Projects
            </Typography>
          </Breadcrumbs>
        </div>
      
        <MaterialTable
              title="Project List"
              columns={columns} 
              data={projects_list}
              // style={globalStyles.DarkTheme}
              icons={{
                Add: props => 
                      <div className={classes.addBtnCSS}>
                        <AddCircleOutlineOutlinedIcon {...props} className={classes.addBtnCSS1}  />
                      </div>
              }}
              // onRowClick={(event, rowData) => this.openApp(event, rowData)}
              // onRowClick={(event, rowData) => alert(`/projects/${rowData.id}`)}
              
              onRowClick={(event, rowData) => {
                this.setState({selected_project: rowData})
                this.setState({toPath: `/projects/${rowData.project_uuid}`})
                this.setState({redirect: true})
                
                }
              }
              
              // onRowClick={() => alert("hello")}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve) => {
                    // POST Function for creating a new project 
                    postData(apiLink + '/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects', 
                    {'project_name': newData.project_name,
                      'project_description': newData.project_description ,
                      'project_is_private': newData.project_is_private})
                      .then(async response => {
                        if(response.ok){
                          this.setState({
                            project_added: true,
                          });
                          this.props.showSuccessSnackbar("New project has been successfully added!");
                        }
                        else{
                          const data = await response.json();
                          this.setState({
                            project_added: false,
                          });
                          this.props.showErrorSnackbar(data.message);
                        }
                      })
                    setTimeout(() => {
                      resolve();
                      this.setState((prevState) => {
                        const data = [...prevState.projects_list];
                        data.push(newData);
                        
                        return { ...prevState, data };
                      });
                    }, 600);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    // PUT Function for creating a new project 
                    putData(apiLink + '/v1/projects/' + newData.project_uuid, 
                    {'project_name': newData.project_name,
                      'project_description': newData.project_description ,
                      'project_is_private': newData.project_is_private})
                      .then(async response => {
                        if(response.ok){
                          this.setState({
                            project_edited: true,
                          });
                          this.props.showSuccessSnackbar("Project has been successfully modified!");
                        }
                        else{
                          try {
                            const data = await response.json();
                            this.setState({
                              project_edited: false,
                            });
                            this.props.showErrorSnackbar(data.message);
                          }catch (e){
                            this.props.showErrorSnackbar('Something went wrong');
                          }
                        }
                      })
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        this.setState((prevState) => {
                          const data = [...prevState.projects_list];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    // Delete Function for creating a new project 
                    deleteData(apiLink + 'v1/projects/' + oldData.project_uuid)
                      .then(async response => {
                        
                        if(response.ok){
                          this.setState({
                            project_deleted: true,
                          })
                          this.props.showSuccessSnackbar("Project has been successfully deleted!");
                        }
                        else{
                          try {
                            const data = await response.json();
                            this.setState({
                              project_deleted: false,
                            })
                            this.props.showErrorSnackbar(data.message);
                          }catch (e){
                            this.props.showErrorSnackbar('Something went wrong');
                          }
                        }
                      })
                    setTimeout(() => {
                      resolve();
                      this.setState((prevState) => {
                        const data = [...prevState.projects_list];
                        data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data };
                      });
                    }, 600);
                  }),
              }}
              localization={{
                body: {
                    editRow: {
                      deleteText: 'Are you sure you want to delete project selected? All data, devices and members will be removed from project.'
                    }
                }
               }}
              actions={[
                {
                  icon: 'exit_to_app',
                  tooltip: 'Leave Project',
                  onClick: (event, rowData) => {
                    this.setState({leaveProjectModalOpen: false})
                    this.handleLeaveProjectModalOpen(rowData.project_uuid)
                  }
                },
                
                // {
                //   icon: 'add',
                //   // tooltip: 'Add User',
                //   isFreeAction: true,
                //   // onClick: (event) => alert("You want to add a new row")
                // }
                
              ]}
              options={{
                actionsColumnIndex: -1,
                headerStyle: {
                  // backgroundColor: '#bdbdbd',
                  // color: '#FFF',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                },
                pageSize:20,       // make initial page size
                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                pageSizeOptions:[10,20,30,50],    // rows selection options

                
              }}
            />
            
            <LeaveProjectModal open={leaveProjectModalOpen} project_uuid={selected_project_uuid} handleCloseLeaveProjectModal={this.handleCloseLeaveProjectModal} refreshProjectList={this.refreshProjectList} /> 

            </div>


    )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    currentUserInfo: state.users.currentUserInfo,

  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ProjectListPage))

// export default withStyles(styles)(ProjectListPage)

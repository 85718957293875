import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import  {getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    maxWidth: theme.spacing(200),
    minWidth: theme.spacing(90),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('md')]:{
      maxWidth: theme.spacing(110),
      minWidth: theme.spacing(80),
    },
    [theme.breakpoints.down('sm')]:{
      maxWidth: theme.spacing(70),
      minWidth: theme.spacing(60),
    },
    [theme.breakpoints.down('xs')]:{
      maxWidth: theme.spacing(43),
      minWidth: theme.spacing(43),
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'5px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    minWidth:'70px',
    maxHeight: '40px',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    borderRadius: 10, 
    maxWidth: theme.spacing(200),
  },
  inputContainers: {
    textAlign: 'left',
    // marginLeft: '12px',
    marginTop:'12px',
    marginBottom:'24px',
  },
  newMachineButton: {
    backgroundColor: '#3b82f6',
    color: '#fff',
    border: 'none',
    fontWeight: 500,
    padding: '4px 14px',
    fontSize: '16px',
    letterSpacing: '.025em',
    lineHeight: '28px',
    borderRadius: '4px',
    width: '100%',
    '&:hover': {
        backgroundColor: '#2563eb',
    },
    '&:disabled': {
        opacity: 0.8,
    },
  },
  tableRow: {
    cursor: 'pointer',
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(52, 58, 64,0.7)"
    }
  },
  tableCell: {
    "$selected &": {
      color: "rgb(232, 232, 232)"
    }
  },
  hover: {},
  selected: {},
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ChangeMachineDashboardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      loading: false,
      success: false,
      machineDashboardList: [],
      // machineDashboardList: this.props.machineDashboards,
      selectedDashboard:[],
    }
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
  // Get machine dashboards after I got asset information to know what machine uuid is
  getData('https://api.iotflows.com/v1/machines/' + this.props.assetInformation.machine_uuid + '/machine_dashboards' )
  .then(async response => {
    if(response.ok ){
        const data = await response.json();
        if(data.data){
          
        this.setState({
          machineDashboardList: data.data,
        })
        }
    }
  });

  }


  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
    });
  };

  handleClose = () => {
    this.props.handleCloseModal()
    this.setState({ 
      open: false,
      loading: false,
      success: false,
    });
  };



  submitButtonClick = () => {
    const { loading,selectedDashboard } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          
          this.props.handleFinishedCreatingDashboard(selectedDashboard)
        // putData('https://api.iotflows.com/v1/assets/' + this.props.asset_uuid + '/selected_machine_dashboard', 
        // {'machine_dashboard_uuid': selectedDashboard.machine_dashboard_uuid,
        // })
        //   .then(async response => {
        //     if(response.ok){
        //       const data = await response.json();
              
        //       this.props.showSuccessSnackbar("Machine Dashboard changed!");
        //       // this.props.refreshAssetList();
        //       this.handleClose();
        //       this.props.handleCloseModal()
        //       this.setState({
        //         loading: false,
        //         success: true,
        //         open:false,
        //       });
        //     }
        //     else{
        //       try{
        //         const data = await response.json();
        //         this.props.showErrorSnackbar(data.message);
        //         this.setState({
        //           loading: false,
        //           success: false,
        //           open:false});
        //       }catch (e){
        //         this.props.showErrorSnackbar('Something went wrong');
        //       }
        //     }
        //   })
        }
      )
    }
  };


  // handledCreateNewDashboard = () =>{

  // }

  handleRowSelected =(dashboard) => {
    this.setState({selectedDashboard: dashboard})
  }

  render() {
    const { classes, selectedOrganization } = this.props;
    const { open, 
            loading, 
            success, 
            machineDashboardList,
            selectedDashboard} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        container
        alignItems="flex-start"
        justify="flex-start"
        direction="column"
      >
        
        {/* <Button className={classes.button} variant="contained"   onClick={this.handleOpen}><AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Create Machine Flow</Button> */}

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >

                
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Change Machine Dashboard</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px'}}>
                  <TableContainer styled={{marginTop:'12px'}}>
                    <Table aria-label="data table">
                      <colgroup>
                        <col style={{width:'30%'}}/>
                        <col style={{width:'70%'}}/>
                      </colgroup>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell style={{paddingLeft: '12px', borderBottom: 'none', fontSize:'16px'}} colSpan={2}>Select Dashboard Template</StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell style={{paddingLeft: '12px', borderBottom: 'none'}} >Name</StyledTableCell>
                          <StyledTableCell style={{paddingLeft: '12px', borderBottom: 'none'}} >Description</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody >
                      {machineDashboardList.map(dashboard => (
                        <TableRow 
                            hover 
                            key={dashboard.machine_dashboard_id} 
                            selected={dashboard.machine_dashboard_id == selectedDashboard.machine_dashboard_id} 
                            classes={{ hover: classes.hover, selected: classes.selected }}
                            className={classes.tableRow} 
                            onClick={() => this.handleRowSelected(dashboard)}
                          >
                            <StyledTableCell className={classes.tableCell} align="left" style={{paddingLeft: '12px'}}>{dashboard.machine_dashboard_name}</StyledTableCell>
                            <StyledTableCell className={classes.tableCell} align="left" >{dashboard.machine_dashboard_description}</StyledTableCell>
                          </TableRow>
                      ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div style={{marginTop:'24px'}}>
                    <div className={classes.inputContainers} >
                        <button 
                            // type="submit"
                            onClick={() => this.props.handleCreateMachineDashboardMenu()} 
                            className={classes.newMachineButton} 
                        >
                            Create new dashboard
                        </button>
                    </div>
                    {/* <div style={{margin:'16px 0px', textAlign:'center'}}>
                      <span style={{fontSize:'17px', fontWeight:600, fontFamily:'Trebuchet MS', color:'#1f2937'}}>Or</span>
                    </div> */}
                  </div>


                      

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading || (Object.keys(selectedDashboard).length == 0)} onClick={this.submitButtonClick}>
                    {'Change'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ChangeMachineDashboardModal));




import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import "../styles/ButtonStyles.scss";
import "../styles/HeartbeatLED.scss";
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import PermScanWifiOutlinedIcon from '@material-ui/icons/PermScanWifiOutlined';
import { connect} from "react-redux";
import '../styles/ScrollBarStyles.scss';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';



const styles = theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    margin: 'auto',
    backgroundColor: '#fff',
  },
  paperDialog: {
    position: 'relative',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    margin:theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(44),
    }
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  dialogContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class ConnectingToRemoteTunnelModal extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      openConfigurationModal: this.props.openConfigurationModal,
      loadingServer: this.props.loadingServer,
      loadingDevice: this.props.loadingDevice,
      successServer: this.props.successServer,
      successDevice: this.props.successDevice,
      failed: this.props.failed,
      message: '',
      device_disabled: this.props.device_disabled,
    }
    
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      openConfigurationModal: nextProps.openConfigurationModal,
      device_disabled: nextProps.device_disabled,
      successServer: nextProps.successServer,
      loadingServer: nextProps.loadingServer,
      loadingDevice: nextProps.loadingDevice,
      failed: nextProps.failed,
      successDevice: nextProps.successDevice,
    }); 
    // if(nextProps.device_disabled){
    //   this.props.changeSwitchToFalse();
    //   this.setState( {
    //     switchChecked: false, 
    //     openConfigurationModal: false, 
    //     loadingServer: false, 
    //     loadingDevice: false, 
    //     successServer: false, 
    //     successDevice: false})
    // }
  };


  render(){
    const { classes, } = this.props
    const { openConfigurationModal, 
            loadingServer, 
            loadingDevice, 
            successDevice, 
            successServer, 
            failed } = this.state;
    
    const buttonClassname1 = classNames({
      [classes.buttonSuccess]: successServer,
    });
    const buttonClassname2 = classNames({
      [classes.buttonSuccess]: successDevice,
    });

    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
        style={{paddingRight: '4px'}}
      >
        <Dialog
            open={openConfigurationModal}
            onClose={this.props.handleCloseConfigurationModal}
            TransitionComponent={Transition}
            keepMounted
            classes={{ paper: classes.dialog }}
          >
          <Paper className={classes.paperDialog} >

              
              <DialogContent style={{paddingBottom: '10px', paddingTop: '10px'}}>
              
              <div className={classes.dialogContainer}>
                <div className={classes.wrapper}>
                  <Fab
                    aria-label="save"
                    // color="primary"
                    style={{color:'#fafafa'}}
                    className={buttonClassname1}
                  >
                    {successServer ? <CheckIcon /> : <PermScanWifiOutlinedIcon style={{color:'#9e9e9e'}} />}
                  </Fab>
                  { loadingServer && <CircularProgress size={68}  className={classes.fabProgress} />}
                </div>
                
                <DialogContentText id="alert-dialog-server">
                  Configuring the server.
                </DialogContentText>


                <div className={classes.wrapper}>
                  <Fab
                    aria-label="save"
                    // color="primary"
                    style={{color:'#fafafa'}}
                    className={buttonClassname2}
                  >
                    {successDevice ? <CheckIcon /> : <PermScanWifiOutlinedIcon style={{color:'#9e9e9e'}}/>}
                  </Fab>
                  { loadingDevice && <CircularProgress size={68} className={classes.fabProgress} />}  
                </div>

                <DialogContentText id="alert-dialog-device">
                  Configuring the device.
                </DialogContentText>
              </div>

              <Divider light/>

              {failed &&
                <DialogContentText id="alert-dialog-device">
                  We couldn't configure the device. The device might be off or disconnected from the Internet.
                  This configuration will be completed once the device is back online.
                </DialogContentText>
              }   
              </DialogContent>
              <DialogActions style={{justifyContent:'flex-end'}}>
                <Button onClick={this.props.handleCloseConfigurationModal} >
                  Close
                </Button>
              </DialogActions>
          </Paper>
        </Dialog>
      </Grid>

    )
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})

const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    currentUserInfo: state.users.currentUserInfo,

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConnectingToRemoteTunnelModal))

 
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import  {deleteData } from '../ApiCalls/DataApis';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';
import Row from 'react-bootstrap/Row'



const styles = theme => ({
  paper: {
    position: 'relative',
    maxWidth: theme.spacing(90),
    minWidth: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    // backgroundColor:'transparent',
    borderRadius:'25px',
    // width: '100%',
    // height: '100%',
    border:'1px solid #388e3c',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    color:'#388e3c',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    outline: 'none',
    // // font-weight:bold;
    padding:'0px 12px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
      outline: 'none',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class PasswordInfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      loading: false,
      success: false,
      generated_client_id: '',
      generated_password: '',
    };
  }

  downloadCsv = () => {
    var csv = 'API Key Username,Password\n';
    csv += this.props.generated_client_id + ',' + this.props.generated_password

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'API Key Info-'+ this.props.selectedOrganization.organization_name + '-'+ this.props.selected_device.project_name + '-' + this.props.selected_device.device_name + '-' + this.props.clientName + '.csv';
    hiddenElement.click();
  }


  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };


  // submitButtonClick = () => {
  //   const { loading } = this.state;
  //   if (!loading) {
  //     this.setState(
  //       {
  //         success: false,
  //         loading: true,
  //       },
  //       () => {
          
  //       deleteData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/members', 
  //       {'member_username': this.props.currentUserInfo[0].user_username})
  //         .then(async response => {
  //           if(response.ok){ 
  //             // const data = await response.json();
  //             this.props.showSuccessSnackbar("You have successfully left this Organization!");
  //             this.setState({
  //               loading: false,
  //               success: true,
  //               open:false,
  //             });
  //           }
  //           else{
  //             this.props.showErrorSnackbar("You could not be removed from Organization");

  //           }
  //         })
  //       }
  //     )
  //   }
  // };

  

  render() {
    const { classes } = this.props;
    const { open, loading, success } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });


    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
        {/* <Button className={classes.button} variant="outlined"  onClick={this.handleOpen}>Leave Org</Button> */}
       
        
        
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title"> API Key Password  </DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '20px'}}>
                
                  <div style={{margin: '0px'}} id="alert-dialog-description">
                    <Alert severity="success">
                      You have successfully created the API key below. You can view the API key credentials below.<i><b>This is the last time 
                      these credentials will be available to download.</b></i> However, you can create new API keys at any time.
                      <br/>
                      You could use these credentials in an application such as NodeRED or Python to publish and subscribe to data streams and alerts
                    </Alert> 
                    <Row style={{padding: '5px'}}>
                      <Typography style={{paddingLeft: '10px'}}>
                        API Key Username:&nbsp;
                      </Typography>
                      <Typography style={{color:'#212121', fontWeight: 500}}>
                        {this.props.generated_client_id}
                      </Typography>
                    </Row>
                    <Row style={{padding: '5px'}} >
                      <Typography style={{paddingLeft: '10px'}}>
                        Password:&nbsp;
                      </Typography>
                      <Typography style={{color:'#212121', fontWeight: 500}}> 
                        {this.props.generated_password}
                      </Typography>
                    </Row>
                  </div>
                  <Button className={classes.button} onClick={this.downloadCsv}>Download CSV</Button>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  {/* <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button> */}
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

PasswordInfoModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(PasswordInfoModal));




import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {CardElement} from '@stripe/react-stripe-js';
import '../styles/CardSectionStyles.css';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CardSetupFormAddingOrganization from '../MyBillingPage/CardSetupFormAddingOrganization';
import Divider from '@material-ui/core/Divider';
import DialogContentText from '@material-ui/core/DialogContentText';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';

const stripePromise = loadStripe('pk_live_cv1o2of6G7RjXFImxVlKnzSD00evGTXFFa');

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ paddingLeft: '0px', paddingRight: '0px', paddingBottom: '0px'}} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const SubscriptionTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
    borderBottom: '0px solid #e8e8e8',
    // backgroundColor: 'transparent',
    outline: 'none',
    borderRadius: '10px',

  },
  indicator: {
    backgroundColor: '#4f79ce',
  },
})(Tabs);

const SubscriptionTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    borderRadius: '10px',
    outline: 'none',
    fontFamily: [
      'Trebuchet MS',
    ].join(','),
    '&:hover': {
      color: '#4f86ce',
      opacity: 1,
      outline: 'none',
    },
    '&$selected': {
      color: '#4f79ce',
      fontWeight: theme.typography.fontWeightMedium,
      outline: 'none',
    },
    '&:focus': {
      color: '#4f86ce',
      outline: 'none',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);


const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(100),
    // width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('md')]:{
      width: theme.spacing(100),
    },
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(85),
    },
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  contentCSS: {
    padding: '8px', 
    marginRight: '30px', 
    marginLeft: '30px',
    [theme.breakpoints.down('xs')]:{
      marginRight: '10px', 
      marginLeft: '10px',
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'5px 14px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    borderRadius: 10, 
    maxWidth: theme.spacing(150),
  },
  cardCSS: {
    width: '100%',
    borderRadius: '10px',
    // height: '750px',
    border:'1px solid #f5f5f5',
    padding: '8px',
    backgroundColor: '#fafafa',
    boxShadow: '2px 4px #f5f5f5',
    cursor:'pointer',
    '&:hover': {
      // backgroundColor:'#eeeeee',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid #bdbdbd',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      // backgroundColor:'#eeeeee',
    },
    '&:focus': {
      outline: 'none',
      // backgroundColor:'#eeeeee',
      border:'1px solid #bdbdbd',
      boxShadow: '2px 4px #bdbdbd',
    },
  },
  cardCSSSelected: {
    width: '100%',
    borderRadius: '10px',
    // height: '750px',
    padding: '8px',
    outline: 'none',
    // backgroundColor:'#eeeeee',
    backgroundColor: '#fafafa',
    border:'1px solid rgb(51, 147, 242)',
    boxShadow: '1px 2px rgba(51, 147, 242,0.5)',
    position:'relative',
    cursor:'pointer',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  headerCSS: {
    textAlign: 'center',
    color: '#1976d2',
    fontFamily:'Trebuchet MS',
    // marginTop: '20px',
    // marginBottom: '10px',
  },
  bodyCSS: {
    height: '20px',
    fontSize: '14px',
    color: 'rgb(84, 97, 128)',
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
  },
  itemsCSS: {
    fontSize: '12px',
    color: '#525f7f',
    textAlign: 'left',
    fontFamily:'Trebuchet MS',
    listStyleType: 'none',
    marginTop: '15px',
    padding: '0px',
    height: '500px',
    // [theme.breakpoints.down('md')]:{
    //   height: '600px',
    // },
    // [theme.breakpoints.down('sm')]:{
    //   height: '750px',
    // },
    // [theme.breakpoints.down('xs')]:{
    //   width: theme.spacing(43),
    // },
  },
  itemsCSSRegular: {
    fontSize: '12px',
    color: '#525f7f',
    textAlign: 'left',
    fontFamily:'Trebuchet MS',
    listStyleType: 'none',
    marginTop: '15px',
    padding: '0px',
    height: '220px',
    // [theme.breakpoints.down('md')]:{
    //   height: '600px',
    // },
    [theme.breakpoints.down('sm')]:{
      height: '290px',
    },
    [theme.breakpoints.down('xs')]:{
      height: '220px',
    },
  },
  itemCSS: {
    padding: '5px',
  },
  subItemCSS: {
    fontSize: '11px',
    padding: '5px',
    paddingLeft: '25px',
  },
  checkmarkCSS: {
    fontSize: '14px',
    color:'rgb(0,209,0)',
  },
  circleCSS: {
    fontSize: '14px',
    color:'rgb(191, 191, 191)',
  },
  pricingCSS: {
    fontSize: '18px',
    color: 'rgb(0,0,0)',
    fontWeight: 700,
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
    marginTop: '16px',
    marginBottom: '16px',
  },
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class AddOrganizationModal extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      open: this.props.open,
      // value: 0,
      
    }
    this.handleClose = this.handleClose.bind(this);
   
  }
  
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      open: false ,
    });
    this.props.handleCloseModal();
  };

  componentWillReceiveProps({open}) {
    this.setState({...this.state,open})
  }

  // handleSubscriptionTypeChange = (event) => {
  //   this.setState({subscriptionType: event});
  // }

  // handleChange = (event, newValue) => {
  //   this.setState({value: newValue});
  // };

  // handleChangeIndex = (index) => {
  //   this.setState({value: index});
  // };



  render() {
    const { classes, theme } = this.props;
    const { open, 
            subscriptionType,
            value} = this.state;

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
        {/* <Button className={classes.button} onClick={this.handleOpen} >
            <AddCircleOutlineOutlinedIcon  /> &nbsp;Add Payment        
        </Button> */}

        <Dialog
            open={open}
            onClose={this.handleClose}
            TransitionComponent={Transition}
            keepMounted
            classes={{ paper: classes.dialog }}
          >
            <div className={classes.paper} >
              <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Create Organization</DialogTitle>
              <DialogContent className={classes.contentCSS}  >

              {/* <AppBar position="static" color="default" style={{borderRadius: '10px'}}>
                <SubscriptionTabs
                  value={value}
                  onChange={this.handleChange}
                  variant="fullWidth"
                >
                  <SubscriptionTab label="Enterprise Organization" {...a11yProps(0)}  />
                  <SubscriptionTab label="Organization" {...a11yProps(1)}  />
                </SubscriptionTabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={this.handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction} >
                  <Grid container spacing={1} style={{marginBottom: '16px', textAlign: 'center', paddingLeft: '0px', paddingRight: '0px'}}>
                    <Grid item xs={12} sm={6} md={3}>
                      <button className={subscriptionType == 'ORGANIZATION_ENTERPRISE_BASIC_MONTHLY' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('ORGANIZATION_ENTERPRISE_BASIC_MONTHLY')} >
                        <h4 className={classes.headerCSS}>Basic</h4>
                        <Divider/>
                        <div className={classes.pricingCSS}>
                          $49.95/user/month
                        </div>
                        <div className={classes.bodyCSS}>
                          Billed $999 per month per organizaton
                        </div>
                        <ul className={classes.itemsCSS}>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Users : 20</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alerts and Notifications</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> 3,000 Emails per user</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> 300 Text Messages per user</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Small Cloud Flow Base Programming</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Small Real-Time Web-App Hosting with OAuth</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alert Channels: 10</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Projects: 15</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Support</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Onboarding training & consulting Services</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Billing and subscription management support</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> 24/7 self-help resources, including <a target="_blank" href="https://docs.iotflows.com">docs.iotflows.com</a> and <a target="_blank" href="https://rest-api-docs.iotflows.com">rest-api-docs.iotflows.com</a></li>
                        </ul>
                      </button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                    <button className={subscriptionType == 'ORGANIZATION_ENTERPRISE_STANDARD_MONTHLY' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('ORGANIZATION_ENTERPRISE_STANDARD_MONTHLY')}>
                        <h4 className={classes.headerCSS}>Standard</h4>
                        <Divider/>
                        <div className={classes.pricingCSS}>
                          $69.98/user/month
                        </div>
                        <div className={classes.bodyCSS}>
                          Billed $3,499 per month per organization
                        </div>
                        <ul className={classes.itemsCSS}>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Users : 50</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alerts and Notifications</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} />4,000 Emails per user</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} />500 Text Messages per user</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Small Cloud Flow Base Programming</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Small Real-Time Web-App Hosting with OAuth</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alert Channels: 25</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Projects: 30</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  SAML-based single sign-on (SSO)</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Support</li>
                            <li className={classes.subItemCSS}> <ArrowBackIcon className={classes.checkmarkCSS}/>  Everything included in Basic</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> 24/7 access to technical support by email and phone</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> IoTFlows Advisor- your free, personalized guide to IoTFlows best practices</li>

                        </ul>
                      </button>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={3}>
                    <button className={subscriptionType == 'ORGANIZATION_ENTERPRISE_PRO_MONTHLY' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('ORGANIZATION_ENTERPRISE_PRO_MONTHLY')}>
                        <h4 className={classes.headerCSS}>Pro</h4>
                        <Divider/>
                        <div className={classes.pricingCSS}>
                          $79.99/user/month
                        </div>
                        <div className={classes.bodyCSS}>
                          Billed $7,999 per month per organization
                        </div>
                        <ul className={classes.itemsCSS}>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Users : 100</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alerts and Notifications</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> 5,000 Emails per user</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> 500 Text Messages per user</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Medium Cloud Flow Base Programming</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Medium Real-Time Web-App Hosting with OAuth</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alert Channels: 45</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Projects: 50</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  SAML-based single sign-on (SSO)</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Advanced data export tools</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Support</li>
                          <li className={classes.subItemCSS}> <ArrowBackIcon className={classes.checkmarkCSS}/>  Everything included in Standard</li>
                          <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Third-party software support with interoperability, configuration guidance and troubleshooting</li>
                        </ul>
                      </button>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={3}>
                    <button className={subscriptionType == 'ORGANIZATION_ENTERPRISE_EXTREME_MONTHLY' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('ORGANIZATION_ENTERPRISE_EXTREME_MONTHLY')}>
                        <h4 className={classes.headerCSS}>Extreme</h4>
                        <Divider/>
                        <div className={classes.pricingCSS}>
                          $99.99/user/month
                        </div>
                        <div className={classes.bodyCSS}>
                          Billed $99,990 per month per organization
                        </div>
                        <ul className={classes.itemsCSS}>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Users : 1,000</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alerts and Notifications</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} />  7,000 Emails per user</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} />  600 Text Messages per user</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Large Cloud Flow Base Programming</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Large Real-Time Web-App Hosting with OAuth</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alert Channels: Unlimited</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Projects: Unlimited</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  SAML-based single sign-on (SSO)</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Advanced data export tools</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Support</li>
                            <li className={classes.subItemCSS}> <ArrowBackIcon className={classes.checkmarkCSS}/>  Everything included in Pro</li>
                            <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Designated account and customer success teams</li>
                        </ul>
                      </button>
                    </Grid> 
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                <Grid container spacing={2} style={{marginBottom: '16px', textAlign: 'center', paddingLeft: '0px', paddingRight: '0px'}}>
                    <Grid item xs={12} sm={6} md={4}>
                      <button className={subscriptionType == 'ORGANIZATION_BASIC_MONTHLY' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('ORGANIZATION_BASIC_MONTHLY')} >
                        <h4 className={classes.headerCSS}>Basic</h4>
                        <Divider/>
                        <div className={classes.pricingCSS}>
                          $8.25/user/month
                        </div>
                        <div className={classes.bodyCSS}>
                          Billed $99 per month per organizaton
                        </div>
                        <ul className={classes.itemsCSSRegular}>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Users : 12</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alerts and Notifications</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> 1,000 Emails per user</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> 100 Text Messages per user</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Limited Cloud Flow Base Programming</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Limited Real-Time Web-App Hosting with OAuth</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alert Channels: 10</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Projects: 15</li>
                        </ul>
                      </button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                    <button className={subscriptionType == 'ORGANIZATION_STANDARD_MONTHLY' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('ORGANIZATION_STANDARD_MONTHLY')}>
                        <h4 className={classes.headerCSS}>Standard</h4>
                        <Divider/>
                        <div className={classes.pricingCSS}>
                          $13.96/user/month
                        </div>
                        <div className={classes.bodyCSS}>
                          Billed $349 per month per organization
                        </div>
                        <ul className={classes.itemsCSSRegular}>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Users : 25</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alerts and Notifications</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} />3,000 Emails per user</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} />200 Text Messages per user</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Small Cloud Flow Base Programming</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Limited Real-Time Web-App Hosting with OAuth</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alert Channels: 25</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Projects: 30</li>
                        </ul>
                      </button>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={4}>
                    <button className={subscriptionType == 'ORGANIZATION_PRO_MONTHLY' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('ORGANIZATION_PRO_MONTHLY')}>
                        <h4 className={classes.headerCSS}>Pro</h4>
                        <Divider/>
                        <div className={classes.pricingCSS}>
                          $19.98/user/month
                        </div>
                        <div className={classes.bodyCSS}>
                          Billed $799 per month per organization
                        </div>
                        <ul className={classes.itemsCSSRegular}>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Users : 40</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alerts and Notifications</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> 5,000 Emails per user</li>
                            <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> 300 Text Messages per user</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Small Cloud Flow Base Programming</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Small Real-Time Web-App Hosting with OAuth</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Alert Channels: 45</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} />  Projects: 50</li>
                        </ul>
                      </button>
                    </Grid> 
                  </Grid>
                </TabPanel>
              </SwipeableViews> */}

              
              {/* <Grid container spacing={2} style={{marginBottom: '16px', textAlign: 'center', paddingLeft: '0px', paddingRight: '0px'}}>
                <Grid item xs={12} sm={4} md={4}>
                  <button className={subscriptionType == 'ORGANIZATION_STANDARD' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleSubscriptionTypeChange('ORGANIZATION_STANDARD')} >
                    <h4 className={classes.headerCSS}>Standard</h4>
                    <Divider/>
                    <div className={classes.pricingCSS}>
                      $100/month
                    </div>
                    <div className={classes.bodyCSS}>
                      &nbsp; 
                    </div>
                    <ul className={classes.itemsCSSRegular}>
                      <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Devices : <b>10</b></li> 
                      <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Interactions: <b>5 Million</b></li>
                      <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Remote Access: <b>5</b> </li>
                      <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Dedicated Cloud Server - <b>Small</b></li>
                      <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Alerts and Notifications</li>
                        <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> <b>2,500</b> Emails per user</li>
                        <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /> <b>150</b> Text Messages per user</li>
                    </ul>
                  </button>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                <button className={subscriptionType == 'ORGANIZATION_PRO' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleSubscriptionTypeChange('ORGANIZATION_PRO')}>
                    <h4 className={classes.headerCSS}>Pro</h4>
                    <Divider/>
                    <div className={classes.pricingCSS}>
                      $500/month
                    </div>
                    <div className={classes.bodyCSS}>
                      &nbsp; 
                    </div>
                    <ul className={classes.itemsCSSRegular}>
                      <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Devices : <b>100</b></li> 
                      <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Interactions: <b>50 Million</b></li>
                      <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Remote Access: <b>50</b> </li>
                      <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Dedicated Cloud Server - <b>Medium</b></li>
                      <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Alerts and Notifications</li>
                        <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /><b>25,000</b> Emails per user</li>
                        <li className={classes.subItemCSS}> <FiberManualRecordOutlinedIcon className={classes.circleCSS} /><b>1,500</b> Text Messages per user</li>
                      <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Connect your domain</li>
                    </ul>
                  </button>
                </Grid> 
                <Grid item xs={12} sm={4} md={4}>
                  <button onClick={(e) => {e.preventDefault();window.open('https://www.iotflows.com/company/support/',"_blank") }} className={classes.cardCSS} style={{textDecoration:'none'}} >
                      <h4 className={classes.headerCSS}>Enterprise</h4>
                      <Divider/>
                      <div className={classes.pricingCSS}>
                        Contact Us
                      </div>
                      <div className={classes.bodyCSS}>
                        sales@iotflows.com
                      </div>
                      <ul className={classes.itemsCSSRegular}>
                        <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Peace of mind with enterprise-grade security and compliance</li>
                        <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Large scale implementations and collaboration with support for up to 1,000,000 devices</li>
                        <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Tailored support with a designated account and customer success team </li>
                        <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Advanced identity management through SAML-based SSO.</li>
                        <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 99.99% guaranteed uptime SLA</li>
                      </ul>
                  </button>
                </Grid> 
              </Grid> */}

                

              <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <CardSetupFormAddingOrganization handleClose={this.handleClose} handleCloseModal={this.props.handleCloseModal} disableCancel={this.props.disableCancel} />
              </Elements>

              </DialogContent>
            </div>
        
        </Dialog>
        
      </Grid>
    );
  }
}



export default withStyles(styles,{ withTheme: true })(AddOrganizationModal);


const initialState = {
  allOrganizations: [],
  currentOrganization: [{}],
  isFetchingOrganizationsComplete: false,
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'FETCH_ORGANIZATIONS':
      return {
        ...state,
        allOrganizations: action.payload
      }
    case 'IS_FETCHING_ORGANIZATIONS_COMPLETE':
      return {
        ...state,
        isFetchingOrganizationsComplete: action.isAllOrganizationsFetched
      }
    case 'SELECTED_ORGANIZATION':
      return {
        ...state,
        selectedOrganization: action.currentOrganization
      }
    // case 'SELECTED_USER':
    //   return {
    //     ...state,
    //     selectedUser: action.payload
    //   }
   
    default:
      return state;
  }
}

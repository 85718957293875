import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import { green } from '@material-ui/core/colors';
// import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import {fetchOrganizations} from '../store/actions/organizationActions';
import Typography from '@material-ui/core/Typography';
// import { CopyBlock, dracula,tomorrowNightBlue } from 'react-code-blocks'
// import CodeSnippet from '../CodeSnippet';
// import CodeSnippet from '@bit/carbon-design-system.carbon-components-react.code-snippet';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Alert from '@material-ui/lab/Alert';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  paper: {
    position: 'relative',
    // width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    // [theme.breakpoints.down('sm')]:{
    //   width: theme.spacing(43),
    // }
  },
  button: {
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      
    },
  },
  dialog: { borderRadius: 10 } ,
  textSizeCSS: {
    display:'flex',
    fontSize: '0.875rem',
    [theme.breakpoints.down('xs')]:{
      fontSize: '0.675rem',
    }
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

 



class SshServerConnectedInfoModal extends React.Component {
  constructor(props) {
    super(props)
 
    this.state = {
      open: this.props.open,
      openClicked1: false,
      openClicked2: false,
    }
  
    this.handleTooltipClose1 = this.handleTooltipClose1.bind(this)
    this.handleTooltipClose2 = this.handleTooltipClose2.bind(this)
  }


  handleOpen = () => {
    this.setState({ 
      open: true,
      first: true });
  };

  handleClose = () => {
    this.props.handlerCloseSshServerConnectedInfoModal();
    this.setState({ open: false });
  };

  copyCode = (copiedTextId) => {
    var copyText = document.getElementById(copiedTextId).textContent;
    navigator.clipboard.writeText(copyText);
    if(copiedTextId == 'chmodCommant') {
      this.setState({openClicked1: true})
    }else if (copiedTextId == 'publicDns'){
      this.setState({openClicked2: true})
    }

    
  }

  handleTooltipClose1(){
    this.setState({
      openClicked1: false, 
    })
  }
  handleTooltipClose2(){
    this.setState({
      openClicked2: false,})
  }
  

  render() {
    const { classes } = this.props;
    const { open, 
            openClicked1, 
            openClicked2} = this.state;

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
              fullWidth
              maxWidth="xl"
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} > Access cloud server remotely via SSH</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '20px'}}>



                    <Typography variant="subtitle1" gutterBottom>
                      <b>1. Open an SSH client or command line shell</b>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      You can use the regular terminal in MacOS or Linux. For Windows use PowerShell.
                    </Typography> 
                    <Typography variant="subtitle1" gutterBottom>
                      <b>2. Ensure your private key is not isn't publicly viewable</b>             
                    </Typography> 
                    <Typography variant="subtitle1" gutterBottom>
                      Using your command line shell or terminal navigate the directory where your private key is located. Use chmod to ensure your key is not publicly viewable         
                    </Typography> 
                    <Typography variant="subtitle1" gutterBottom>
                      <code id="chmodCommant" >chmod 400 <span style={{color: 'rgb(74, 140, 207)'}}>[private key name for that server]</span>.pem </code>&nbsp;
                      <ClickAwayListener onClickAway={this.handleTooltipClose1}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={this.handleTooltipClose1}
                            open={openClicked1}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Copied!"
                          >
                            <IconButton  className="js-copybtn"  size="small">
                              <FileCopyOutlinedIcon style={{fontSize: '16px'}} onClick={() => this.copyCode("chmodCommant")}/>
                            </IconButton> 
                          </Tooltip>
                      </ClickAwayListener>      
                    </Typography>  
                    <Typography variant="subtitle1" gutterBottom>
                      <b>3. Connect to your cloud server using its Public DNS:</b>             
                    </Typography> 
                    <Typography variant="subtitle1" gutterBottom>
                      <code id="publicDns">ssh -i <span style={{color: 'rgb(74, 140, 207)'}}>[private key name for that server]</span>.pem ubuntu@{this.props.selectedCloudServer.cloud_server_dns_record_name}</code>&nbsp;
                      <ClickAwayListener onClickAway={this.handleTooltipClose2}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={this.handleTooltipClose2}
                            open={openClicked2}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Copied!"
                          >
                            <IconButton  className="js-copybtn"  size="small">
                              <FileCopyOutlinedIcon style={{fontSize: '16px'}} onClick={() => this.copyCode("publicDns")}/>
                            </IconButton> 
                          </Tooltip>
                      </ClickAwayListener>      
                    </Typography>  
                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

SshServerConnectedInfoModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  fetchOrganizations: () => dispatch(fetchOrganizations()),

})

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(SshServerConnectedInfoModal));
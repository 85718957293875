import { combineReducers } from 'redux';
import uiReducer from './uiReducer';
import userReducer from './userReducer';
import searchbarUserReducer from './searchbarUserReducer';
import organizationReducer from './organizationReducer';
import alertChannelsReducer from './alertChannelsReducer';
import iotflowsMemberRoleReducer from './iotflowsMemberRoleReducer';




export default combineReducers({
  ui: uiReducer,
  users: userReducer,
  searchbarUser: searchbarUserReducer,
  organizations: organizationReducer,
  alertChannels: alertChannelsReducer,
  iotflowsMemberRoleId: iotflowsMemberRoleReducer,

})
import React from 'react';
import PropTypes from 'prop-types';
import { fade, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { blue } from '@material-ui/core/colors';
import SearchMemberWithDelete from './SearchMemberWithDelete';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import {getData} from '../ApiCalls/DataApis';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DeleteAlertChannelMemberModal from './DeleteAlertChannelMemberModal';
import IconButton from '@material-ui/core/IconButton';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ChangeAlertChannelMemberRoleModal from './ChangeAlertChannelMemberRoleModal';
import {searchbarUserHandler} from '../store/actions/searchbarUserActions';


const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(55),
    backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    // fontSize:'14px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    border:'1px solid #bdbdbd',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  dialog: {borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



class ListMemberModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      loading: false,
      success: false,
      alert_channel_members: [],
    };
    this.refreshMemberList = this.refreshMemberList.bind(this);
  }

  componentDidMount() {

    getData('https://api.iotflows.com/v1/alert-channels/' + this.props.selectedAlertChannel.alert_channel_uuid + '/members')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            alert_channel_members: data.data ,
            
          })
        }
        
      });
  }

  // componentDidUpdate(prevState, prevProps) {

  //   if(prevState.selectedAlertChannel.alert_channel_uuid != this.props.selectedAlertChannel.alert_channel_uuid){
  //     getData('https://api.iotflows.com/v1/alert-channels/' + this.props.selectedAlertChannel.alert_channel_uuid + '/members')
  //     .then(async response => {
  //       if(response.ok ){
  //         const data = await response.json();
  //         this.setState({
  //           alert_channel_members: data.data ,
            
  //         })
  //       }
        
  //     });
  //   }
    
  // }

  refreshMemberList() {
    getData('https://api.iotflows.com/v1/alert-channels/' + this.props.selectedAlertChannel.alert_channel_uuid + '/members')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            alert_channel_members: data.data ,
            
          })
        }
        
      });
  }

  

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  submitButtonClick = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          this.timer = setTimeout(() => {
            this.props.showSuccessSnackbar("member has been successfully added!");
            this.setState({
              loading: false,
              success: true,
              open:false,

            });
          }, 2000);
        },
      );
    }
  };


  componentWillReceiveProps({open}) {
    this.setState({...this.state,open})
    this.refreshMemberList();
  }

  render() {
    const { classes,searchbarUsername, selectedAlertChannel } = this.props;
    const { open, loading, success, alert_channel_members } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
        {/* <Button className={classes.button} variant="outlined"  onClick={this.handleOpen}>Members List</Button> */}
          
          <Dialog
              open={open}
              onClose={this.props.handler}
              TransitionComponent={Transition}
              keepMounted
              classes={{ paper: classes.dialog }}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">{"Members List"}</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '15px', paddingTop: '15px'}}>

                <List>
                    {alert_channel_members.map((member,index) => (
                      <Row key={index}>
                        <ListItem  key={index} selected={member.user_username == searchbarUsername} onClick={() => { this.props.searchbarUserHandler(member.user_username); }} >
                          <ListItemAvatar>
                            <Avatar className={classes.avatar} src={member.user_image_url} alt={member.user_first_name} />
                          </ListItemAvatar>
                          <Col>
                            <ListItemText primary={member.user_first_name + ' ' + member.user_last_name} />
                            <ListItemText secondary={member.alert_channel_member_role_name} />
                          </Col>
                          <div edge="end" style={{margin:'0px', padding: '0px'}}>
                            <ChangeAlertChannelMemberRoleModal />
                          </div>
                          <div edge="end" style={{margin:'0px', padding: '0px', }}>
                            <DeleteAlertChannelMemberModal user_username={member.user_username} refreshMemberList={this.refreshMemberList} />
                          </div>
                        </ListItem>
                      </Row>
                    ))}
                  </List>

                {/* <SearchMemberWithDelete items={this.state.alert_channel_members}  /> */}

                

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.props.handler}>
                    Close
                  </Button>
                  {/* <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Add member { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button> */}
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

ListMemberModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  searchbarUserHandler: username => dispatch(searchbarUserHandler(username)),


})
const mapStateToProps = state => {
  return {
    searchbarUsername: state.searchbarUser.searchbarUsername,
    selectedAlertChannel: state.alertChannels.selectedAlertChannel
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ListMemberModal));

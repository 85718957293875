import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AssignMachineFlows from './AssignMachineFlows'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import EditAssetModal from "../components/EditAssetModal"
import DeleteAssetModal from "../components/DeleteAssetModal"
import "../styles/HeartbeatLED.scss";


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function MenuAssetButton({asset, refreshAssetList}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openAssignMachineFlowsModal, setOpenAssignMachineFlowsModal] = React.useState(false);
  const [openEditAssetModal, setOpenEditAssetModal] = React.useState(false);
  const [openDeleteAssetModal, setOpenDeleteAssetModal] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    // event.stopPropagation(); 
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenAssignMachineFlowsModal = () => {
    setOpenAssignMachineFlowsModal(!openAssignMachineFlowsModal);
  };

  const handleOpenEditAssetModal = () => {
    setOpenEditAssetModal(!openEditAssetModal);
  };

  const handleOpenDeleteAssetModal = () => {
    setOpenDeleteAssetModal(!openDeleteAssetModal);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{position:'relative'}}
      >      
        <MoreVertIcon />
        {asset.missingDSConnections && asset.missingDSConnections > 0 ?
          <span style={{position:'absolute', top:0, right:0}} className="alertHeartbeat"> </span>
          : null
        } 
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenAssignMachineFlowsModal} disableRipple>
            <AssignmentTurnedInOutlinedIcon />
            Assign Machine Flows &nbsp;&nbsp;&nbsp;
            {asset.missingDSConnections && asset.missingDSConnections > 0 ?
              <span style={{backgroundColor:'#ea580c',borderRadius:'10px', padding:'4px 6px', fontWeight:500, fontSize:'0.75rem', minWidth:'20px', color:'#fff', height:'20px', lineHeight:1,fontFamily: "Roboto,Helvetica,Arial,sans-serif"}} >
                {asset.missingDSConnections} 
                </span>
              : null
            } 
          
          
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleOpenEditAssetModal} disableRipple>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={handleOpenDeleteAssetModal} disableRipple>
          <DeleteOutlineOutlinedIcon />
          Delete
        </MenuItem>
      </StyledMenu>
      {openAssignMachineFlowsModal &&
      <AssignMachineFlows asset={asset} openAssignMachineFlowsModal={openAssignMachineFlowsModal} handleOpenAssignMachineFlowsModal={handleOpenAssignMachineFlowsModal} />
      }
      {openEditAssetModal &&
      <EditAssetModal asset={asset} openEditAssetModal={openEditAssetModal} handleOpenEditAssetModal={handleOpenEditAssetModal} refreshAssetList={refreshAssetList} />
      }
      {openDeleteAssetModal &&
      <DeleteAssetModal asset={asset} openDeleteAssetModal={openDeleteAssetModal} handleOpenDeleteAssetModal={handleOpenDeleteAssetModal} refreshAssetList={refreshAssetList} />
      }
    </div>
  );
}
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Portal from '@material-ui/core/Portal';
import Form from 'react-bootstrap/Form';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import  {postData, getData } from '../ApiCalls/DataApis';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 500,
    fontFamily:'Trebuchet MS',
    fontSize: 12,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 14,
    fontWeight: 200,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);


const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(80),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    // [theme.breakpoints.down('sm')]:{
    //   width: theme.spacing(43),
    // },
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    borderRadius:'5px',
    border:'0px solid rgb(79, 134, 206)',
    backgroundColor:'transparent',
    color:'rgba(79, 135, 204, 1)',
    fontFamily:'Trebuchet MS',
    fontSize:'13px',
    outline: 'none',
    padding:'3px 12px',
    '&:hover': {
      color:'rgba(79, 135, 204, 0.8)',
      backgroundColor: 'rgba(79, 134, 206, 0)',
      outline: 'none',
      transform: 'translateY(-1px)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  iconButtonCSS: {
    borderRadius:'5px',
    border:'0px solid rgb(79, 134, 206)',
    backgroundColor:'transparent',
    color:'rgba(79, 135, 204, 0.8)',
    fontFamily:'Trebuchet MS',
    outline: 'none',
    padding:'6px',
    margin: '2px',
    '&:hover': {
      color:'rgba(79, 135, 204, 0.8)',
      backgroundColor: 'rgba(79, 134, 206, 0)',
      outline: 'none',
      transform: 'translateY(-1px)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { 
    borderRadius: 10,
    maxWidth: theme.spacing(80),
  },
  headersCSS: {
    fontSize: '24px',
    fontFamily:'Trebuchet MS',
  }, 
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DownloadDataStreamDataModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      data_streams_list: [],
      selected_data_stream: [],
      index: '',
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.downloadDataButtonClick = this.downloadDataButtonClick.bind(this);
  }

  componentDidMount() {
    getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/data_streams')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        this.setState({
          data_streams_list: data.data,
        })
      }
    });
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
    });
  };

  downloadDataButtonClick = () => {

    getData('https://api.iotflows.com/v1/data_streams/' + this.state.selected_data_stream.data_stream_uuid + '/historical_data')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();

        var modifyDate = {};
        var csv = 'Data stream,Data,Received At,Publisher (Device API Key)\n';
        for(var i=0; i<Object.keys(data.data).length; i++) {
          modifyDate[i] = new Date(data.data[i].received_at);
          modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
          data.data[i].received_at = modifyDate[i];
          csv += this.state.selected_data_stream.data_stream_name + ',' + data.data[i].data + ',' + data.data[i].received_at + ',' + data.data[i].client_id+'\n' 
        }

        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = this.state.selected_data_stream.data_stream_name+'DataStream.csv';
        hiddenElement.click();
        this.handleClose()
      }
    });
  };

  handleRowClick = (event, data_stream, id) => {
    this.setState({
      selected_data_stream: data_stream,
      index: id,
    })
    
  }


  render() {
    const { classes } = this.props;
    const { open, 
            loading, 
            success, 
            data_streams_list,
            selected_data_stream,
            index} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
        
      >
        {/* <Button className={classes.button}  onClick={this.handleOpen}>Download Data</Button> */}
        <LightTooltip title="Download data">
          <IconButton className={classes.iconButtonCSS}  onClick={this.handleOpen} component="span">
            <CloudDownloadOutlinedIcon style={{fontSize: '28px'}} />
          </IconButton>
        </LightTooltip>
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                {/* <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Download Data Stream Data</DialogTitle> */}
                <DialogContent style={{padding: '0px',paddingBottom: '0px', }}>

                <TableContainer component={Paper} className={classes.tableContainer}>
                  <div style={{textAlign: 'left', margin: '5px', marginLeft: '10px'}} >
                    <span className={classes.headersCSS} >
                      <span><b>Download data stream data</b> </span>
                    </span>
                  </div>
                  <Table className={classes.table} >
                    <colgroup>
                      <col style={{width:'30%'}}/>
                      <col style={{width:'20%'}}/>
                      <col style={{width:'50%'}}/>
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell style={{paddingLeft: '12px'}}>Name</StyledTableCell>
                        <StyledTableCell align="left">UUID </StyledTableCell>
                        <StyledTableCell align="left">DESCRIPTION </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data_streams_list.map((data_stream, id) => (
                        <TableRow hover selected={index === id} style={{cursor: 'pointer'}} key={id} onClick={(event) => this.handleRowClick(event, data_stream, id)}>
                          <StyledTableCell style={{paddingLeft: '12px'}}>{data_stream.data_stream_name}</StyledTableCell>
                          <StyledTableCell align="left" style={{fontSize: '12px', color:'rgb(105, 115, 134)'}} >{data_stream.data_stream_uuid}</StyledTableCell>
                          <StyledTableCell align="left" style={{fontSize: '12px', color:'rgb(105, 115, 134)'}} >{data_stream.data_stream_description}</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.downloadDataButtonClick}>
                    Download { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

DownloadDataStreamDataModal.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => state

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(DownloadDataStreamDataModal));

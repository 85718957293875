import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Typography from '@material-ui/core/Typography';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {getData} from '../ApiCalls/DataApis';
import MqttService from '../core/services/MqttService';
import {GoogleData, MicrosoftData, AppleData} from './DataTest.js'
import TuckerMachine from './TuckerMachine.jpeg'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Map from '../components/Map';
import SynchronisedCharts from "./SynchronisedCharts.js";
import {withRouter} from 'react-router-dom';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  },
  body: {
    fontSize: 13,
    fontFamily:'Trebuchet MS',
    height: '24px',
    padding: '8px',
  },
}))(TableCell);


const styles = theme => ({
  root: {
    flexGrow: 1,
    // delete later, added just to make it look dark
    // backgroundColor:'#0f172a',
    // margin:'-24px',
    // padding:'24px',
  },
  cardRoot: {
    borderRadius: '10px',
    margin: '5px',
  },
  chartContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: '14px',
    fontWeight: 450,
    fontFamily:'Trebuchet MS',
  },
  cardValue: {
    fontSize:'26px',
    fontWeight: 550,
    fontFamily:'Trebuchet MS',
  },
  switchText: {
    textAlign:'right',
    [theme.breakpoints.down('xs')]: {
      textAlign:'left',
    },
  },
  mapContainer: {
    width:'100%',
    height:'255px',
    // [theme.breakpoints.down('md')]: {
    //   height: '300px',
    //   width:'100%'
    // },
    // [theme.breakpoints.down('xs')]: {
    //   height: '300px',
    //   width:'100%'
    // },
  },
  cardHeader: {
    fontSize:'20px',
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    // fontFamily:'"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
    textAlign:'center',
    marginTop: '5px',
    marginBottom: '2px',
    paddingBottom: '0px',
  },
  interactionsContainer: {
    display: 'flex',
    flexDirection:'column',
    position: 'absolute',
    top: '49%',
    fontFamily:'Trebuchet MS',
    right: '28%',
    width: '100px',
    textAlign: 'center',
  },
  sizeSum: {
    fontSize: '22px',
    fontWeight: 700,
  },
  interactionsSumTop: {
    fontSize: '24px',
    fontWeight: 700,
  },
  interactionsSumBottom: {
    fontSize:'11px', 
    color:'#9CA3AF',
    fontWeight: 300,
  },
});

const alerts = [
  {
    error:'Current spikes',
    time:'49 min ago',
    description: 'Machine XYC exceeded 5000 amps',
    action: 'shutdown'
  },
  {
    error:'Voltage fluctuation',
    time:'Yesterday, 1:09am',
    description: 'Tucker SWS 12.2 volts fluctuate < 5%',
    action: 'restart'
  },
  {
    error:'Measurement data change',
    time:'Dec 5, 5:12pm',
    description: 'Measurement data changed to 23432',
    action: null
  },
]

const specialDeviceUUID = 'dev_c20fc4c5d60c91f412b271942aaa5c95'


class SelectedAssetPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      asset_info:[],
      asset_flows:[],
      asset_widgets:[],
      totalCost: 0.0,
      forecastCost: 0.0,
      currentDeviceNumber: 0,
      totalInteractions: 0,
      totalCurrentInteractions: 0,
      currentDailyInteractionCount: 0,
      yesterdaysDate: '',
      firstDayOfMonth: '',
      redirect: false,
      value: 0,
      devices_list: [],
      devicesDic: [],
      todaysTotalSumInteger: 0,
      monthsTotalSumInteger: 0,
      todaysTotalSumSummary: 0,
      monthsTotalSumSummary: 0,
      totalSizeSummary: '',
      pieChartSizeOptions: {
        chart: {
          // renderTo: 'container',
          backgroundColor: null,
          height: 160,
          // width: 125,
        },
        colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
        // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
        credits: {enabled: false},
        title: {
          text: "",
          verticalAlign: 'middle',
          style: { 
            color:'#000',
            fontSize: '22px',
          } 
        },
        exporting: {
          enabled: false
        },
        series: [{
          type: 'pie',
          name: 'Size',
          innerSize: '80%',
          data: [{
              name: '0 Bytes',
              y: 1,
              // sliced: true,
              // selected: true
          }]
        }],
        plotOptions: {
          // series: {
          //   animation: false
          // },
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              size: 135,
              dataLabels: {
                  enabled: false
              },
              borderWidth: 0,
              margin: 0,
          }
        },
        legend: {
          itemDistance:0,
        },
        tooltip: {
          pointFormat: '{series.name}: 0'
        }
      },
      pieChartTodaysOptions: {
        chart: {
          // renderTo: 'container',
          backgroundColor: null,
          height: 160,
          // width: 125,
        },
        colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
        // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
        credits: {enabled: false},
        title: {
          text: "",
          verticalAlign: 'middle',
          style: { 
            color:'#000',
            fontSize: '22px',
          } 
        },
        exporting: {
          enabled: false
        },
        series: [{
          type: 'pie',
          name: 'Messages',
          innerSize: '80%',
          data: [{
              name: 'No Messages Published',
              y: 1,
              // sliced: true,
              // selected: true
          }]
        }],
        plotOptions: {
          // series: {
          //   animation: false
          // },
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              size: 135,
              dataLabels: {
                  enabled: false
              },
              borderWidth: 0,
              margin: 0,
          }
      },
        legend: {
          itemDistance:0,
        }
      },
      pieChartMonthsOptions: {
        chart: {
          // renderTo: 'container',
          backgroundColor: null,
          height: 160,
          // width: 125,
        },
        colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
        // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
        credits: {enabled: false},
        title: {
          text: "",
          verticalAlign: 'middle',
          style: { 
            color:'#000',
            fontSize: '22px',
          } 
        },
        exporting: {
          enabled: false
        },
        series: [{
          type: 'pie',
          name: 'Messages',
          innerSize: '80%',
          data: [{
              name: 'No Messages Published',
              y: 1,
              // sliced: true,
              // selected: true
          }]
        }],
        plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              size: 135,
              dataLabels: {
                  enabled: false
              },
              borderWidth: 0,
              margin: 0,
          }
      },
        legend: {
          itemDistance:0,
        }
      },
      costOptions: {
        credits: {enabled: false},
        series: [{
          type: 'column',
          color: '#4caf50',
          name: 'Estimated cost',          
          data: [],
          dataGrouping: {
            units: [ [
                'month',
                [1, 2, 3, 4, 6]
            ]]
          }
        }]
      },
      multiAxisOptions: {
        credits: {enabled: false},
        chart: {
          zoomType: 'xy',
          height:'290px',
        },
        xAxis: [{
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            crosshair: true
        }],
        yAxis: [{ // Primary yAxis
            labels: {
                format: '{value}°C',
                style: {
                    color: Highcharts.getOptions().colors[3]
                }
            },
            title: {
                text: 'Temperature',
                style: {
                    color: Highcharts.getOptions().colors[3]
                }
            },
            opposite: false
    
        }, { // Secondary yAxis
            gridLineWidth: 0,
            title: {
                text: 'Rainfall',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            labels: {
                format: '{value} mm',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            opposite: true
    
        }, { // Tertiary yAxis
            gridLineWidth: 0,
            title: {
                text: 'Sea-Level Pressure',
                style: {
                    color: Highcharts.getOptions().colors[1]
                }
            },
            labels: {
                format: '{value} mb',
                style: {
                    color: Highcharts.getOptions().colors[1]
                }
            },
            opposite: true
        }],
        tooltip: {
            shared: true
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            x: 80,
            verticalAlign: 'top',
            y: 55,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                'rgba(255,255,255,0.25)'
        },
        series: [{
            name: 'Rainfall',
            type: 'column',
            yAxis: 1,
            data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
            tooltip: {
                valueSuffix: ' mm'
            }
    
        }, {
            name: 'Sea-Level Pressure',
            type: 'spline',
            yAxis: 2,
            data: [1016, 1016, 1015.9, 1015.5, 1012.3, 1009.5, 1009.6, 1010.2, 1013.1, 1016.9, 1018.2, 1016.7],
            marker: {
                enabled: false
            },
            dashStyle: 'shortdot',
            tooltip: {
                valueSuffix: ' mb'
            }
    
        }, {
            name: 'Temperature',
            type: 'spline',
            data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6],
            color: Highcharts.getOptions().colors[3],
            tooltip: {
                valueSuffix: ' °C'
            }
        }],
        navigator: {
          height: 15,
          outlineWidth: 0,
          outlineColor: '#ffffff',
          xAxis: {
            gridLineWidth: 0,
            gridLineColor: '#ffffff',
          },
          yAxis: {
            gridLineWidth: 0,
            gridLineColor: '#ffffff',
          },
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 200
                },
                chartOptions: {
                    legend: {
                        floating: false,
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',
                        x: 0,
                        y: 0
                    },
                    yAxis: [{
                        labels: {
                            align: 'right',
                            x: 0,
                            y: -6
                        },
                        showLastLabel: false
                    }, {
                        labels: {
                            align: 'left',
                            x: 0,
                            y: -6
                        },
                        showLastLabel: false
                    }, {
                        visible: false
                    }]
                }
            }]
        }
      },
      deviceOptions: {
        credits: {enabled: false},
        series: [{
          type: 'column',
          name: 'Devices',          
          data: [],
          // dataGrouping: {
          //   units: [[
          //       'week', // unit name
          //       [1] // allowed multiples
          //   ], [
          //       'month',
          //       [1, 2, 3, 4, 6]
          //   ]]
          // }
        }]
      },
      multiLineChartOptions: {
        credits: {enabled: false},
        series: [{
          name: 'GOOG',          
          data: GoogleData,
        },{
          name: 'MSFT',          
          data: MicrosoftData,
        },{
          name: 'AAPL',          
          data: AppleData,
        },
      ]
      },
      interactionOptions: {
        credits: {enabled: false},
        series: [{
          type: 'column',
          name: 'Interactions',
          data: [],
          // dataGrouping: {
          //   units: [[
          //       'week', // unit name
          //       [1] // allowed multiples
          //   ], [
          //       'month',
          //       [1, 2, 3, 4, 6]
          //   ]]
          // }
        }]
      },
      upTimeOptions: {
        credits: {enabled: false},
        title: {
          text: 'Uptime',
          margin: 0,
          style: {
            fontSize: '22px',
            fontWeight: '900',
            fontFamily:'Trebuchet MS',
          }
        },
        chart: {
          height: '240px',
          alignTicks: false,
        },
        scrollbar: {
          enabled: false
        },
        // rangeSelector:{},
        rangeSelector:{
          allButtonsEnabled: true,
          buttons: [
            {
            type: 'day',
            count: 1,
            text: '1d'
          }, {
            type: 'day',
            count: 7,
            text: '7d'
          },{
            type: 'day',
            count: 14,
            text: '14d'
          },{
            type: 'month',
            count: 1,
            text: '1m'
          },{
            type: 'month',
            count: 3,
            text: '3m'
          },{
            type: 'month',
            count: 6,
            text: '6m'
          }],
          selected: 5
        },
        xAxis: {       
          ordinal: false,
          gridLineWidth: 1
        },
        yAxis: {
          gridLineWidth: 1,
          allowDecimals: false,
          ceiling: 1,
          max: 1,
          tickPositioner: function (min, max) {
            var ticks = [],
                interval = (max - min) / (this.chart.yAxis[0].tickPositions.length - 1);
    
            while (min <= max) {
                ticks.push(min);
                min += interval;
            }
    
            return ticks;
          },
        },
        plotOptions: {
          series: {
            lineWidth: 0,
          },
        },
        time: {
          timezoneOffset: new Date().getTimezoneOffset()
        },
        series: [{
          type: 'area',
          color: '#4caf50',
          name: 'Uptime',  
          data: [Date().toLocaleString(),0],        
          // data: [[1615726420133.15,1],[1615726438339.27,0],[1616483432104.93,1],[1616486511376.51,0],[1616488341604.9,1],[1616488708046.84,0],[1616490118260.24,1],[1616490129180.98,0],[1616875607868.05,1],[1616875635271.49,0],[1616875770994.73,1],[1616875797883.67,0],[1616876236014.07,1],[1616876262935.6,0],[1616876420090.32,1],[1616876447659.11,0],[1616876491094.86,1],[1616876518296.01,0],[1617776466203.34,1],[1617777618948.78,0]],
          step: 'left',
          dataGrouping: {
            units: [[
              'millisecond', // unit name
              [1, 2, 5, 10, 20, 25, 50, 100, 200, 500] // allowed multiples
          ], [
              'second',
              [1, 2, 5, 10, 15, 30]
          ], [
              'minute',
              [1, 2, 5, 10, 15, 30]
          ], [
              'hour',
              [1, 2, 3, 4, 6, 8, 12]
          ], [
              'day',
              [1]
          ], [
              'week',
              [1]
          ], [
              'month',
              [1, 3, 6]
          ], [
              'year',
              null
          ]]
          }
        }],
        navigator: {
          height: 15,
          outlineWidth: 0,
          outlineColor: '#ffffff',
          xAxis: {
            gridLineWidth: 0,
            gridLineColor: '#ffffff',
          },
          yAxis: {
            gridLineWidth: 0,
            gridLineColor: '#ffffff',
          },
          chart: {
            type: 'area',
            color: '#4caf50',
          },
          series: {
              type: 'area',
              step: 'left',
              color: '#4caf50',
              lineWidth: 0.5,
          }
        },
      },
    }
  }

  componentDidMount() {

    // TEMPORARY UNTIL API IS BUILT**************************
    // Get list of assets for device
    // getData('https://api.iotflows.com/v1/devices/' + this.props.match.params.device_uuid + '/assets')
    // .then(async response => {
    //   if(response.ok ){
    //     const data = await response.json();
    //     if(data.data){
    //       console.log("first one", data.data[0])
    //       this.setState({
    //         asset_info: data.data[0],
    //       })
    //     }
    //   }
    // });
    // TEMPORARY UNTIL API IS BUILT**************************


    // Get flows of asset
    getData('https://api.iotflows.com/v1/assets/' + this.props.match.params.asset_uuid + '/flows')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          console.log("asset flows")
          console.log(data.data)
          this.setState({
            asset_flows: data.data,
          })
        }
      }
    });
    // Get widgets of asset
    getData('https://api.iotflows.com/v1/assets/' + this.props.match.params.asset_uuid + '/widgets')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          console.log("asset widgets")
          console.log(data.data)
          this.setState({
            asset_widgets: data.data,
          })
        }
      }
    });

    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1); 
    this.setState({yesterdaysDate: yesterday.toDateString()});

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    this.setState({firstDayOfMonth: firstDay.toDateString()});

    // ****Inputted a device to make it easier to display data*********************

    if(MqttService.isConnected) {
      MqttService.subscribe('v1/organizations/' + 'org_1570ab30abddcfec3d2bf2115dbe7ca2' + '/projects/' + 'prj_361dc9b4bc90bb947e86e06d9cc9a4db' + '/devices/' + specialDeviceUUID +'/data-streams/#', this.onDataStreamDevice)
    }


     // Device uptime chart
     getData('https://api.iotflows.com/v1/clients/' + specialDeviceUUID + '/historical_status/highcharts')
     .then(async response => {
       if(response.ok ){
         const data = await response.json();
         this.setState({
           upTimeOptions: {
             ...this.state.upTimeOptions,
             series: 
               [{...this.state.upTimeOptions.series[0],
                 data: JSON.parse(data.data)}],
           },
         }, ()=> {
           this.setState({

             upTimeOptions: {
               scrollbar: {
                 enabled: false
               },
               credits: {enabled: false},
               title: {
                 text: 'Uptime',
                 margin: 0,
                 style: {
                   fontSize: '24px'
                 }
               },
               chart: {
                 height: '240px',
                 alignTicks: false,
               },
               rangeSelector:{
                 allButtonsEnabled: true,
                 buttons: [
                   {
                   type: 'day',
                   count: 1,
                   text: '1d'
                 },{
                   type: 'day',
                   count: 7,
                   text: '7d'
                 },{
                   type: 'day',
                   count: 14,
                   text: '14d'
                 },{
                   type: 'month',
                   count: 1,
                   text: '1m'
                 },{
                   type: 'month',
                   count: 3,
                   text: '3m'
                 },{
                   type: 'month',
                   count: 6,
                   text: '6m'
                 }],
                 selected: 5
               },
               xAxis: {       
                 ordinal: false,
                 gridLineWidth: 1,
                 scrollbar: {enabled: false},
               },
               yAxis: {
                 gridLineWidth: 1,
                 allowDecimals: false,
                 ceiling: 1,
                 max: 1,
                 tickPositioner: function (min, max) {
                   var ticks = [],
                       interval = (max - min) / (this.chart.yAxis[0].tickPositions.length - 1);
   
                   while (min <= max) {
                       ticks.push(min);
                       min += interval;
                   }
   
                   return ticks;
                 },
               },
               plotOptions: {
                 series: {
                   lineWidth: 0,
                 },
               },
               series: [{
                 type: 'area',
                 color: '#4caf50',
                 name: 'Uptime',  
                 data: JSON.parse(data.data),        
                 step: 'left',
                 dataGrouping: {
                   units: [[
                     'millisecond', // unit name
                     [1, 2, 5, 10, 20, 25, 50, 100, 200, 500] // allowed multiples
                 ], [
                     'second',
                     [1, 2, 5, 10, 15, 30]
                 ], [
                     'minute',
                     [1, 2, 5, 10, 15, 30]
                 ], [
                     'hour',
                     [1, 2, 3, 4, 6, 8, 12]
                 ], [
                     'day',
                     [1]
                 ], [
                     'week',
                     [1]
                 ], [
                     'month',
                     [1, 3, 6]
                 ], [
                     'year',
                     null
                 ]]
                 }
               }],
               navigator: {
                 scrollbar: {enabled: false},
                 height: 15,
                 outlineWidth: 0,
                 outlineColor: '#ffffff',
                 xAxis: {
                   gridLineWidth: 0,
                   gridLineColor: '#ffffff',
                 },
                 yAxis: {
                   gridLineWidth: 0,
                   gridLineColor: '#ffffff',
                 },
                 chart: {
                   type: 'area',
                   color: '#4caf50',
                 },
                 series: {
                     type: 'area',
                     step: 'left',
                     color: '#4caf50',
                     lineWidth: 0.5,
                 }
               },
             },
           })
           }
         )    
       }
     });

     // Get data messages sizes
    getData('https://api.iotflows.com/v1/devices/' + specialDeviceUUID + '/data_streams_interaction_size')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(Object.keys(data.data).length > 0){

          var dataSizes = data.data.map(result =>({
              name: result.data_stream_name,
              y: parseInt(result.size),
              prettyY: this.formatBytes(result.size),
              device_uuid: result.data_stream_uuid
          }))

          let sizeSum = 0
          for(var i = 0; i < Object.keys(dataSizes).length; i++) {
            sizeSum = sizeSum + dataSizes[i].y
          }

          var formattedSize = this.formatBytes(sizeSum)

          this.setState({
            totalSizeSummary: formattedSize,
          })

          this.setState(
            {
              pieChartSizeOptions: {
                ...this.state.pieChartSizeOptions,
                series: 
                  [{...this.state.pieChartSizeOptions.series[0],
                    data: dataSizes}],
              }}, () => {
                this.setState({
                  pieChartSizeOptions: {
                    chart: {
                      // renderTo: 'container',
                      backgroundColor: null,
                      height: 160,
                      // width: 125,
                    },
                    colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
                    // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
                    credits: {enabled: false},
                    title: {
                      text: "",
                      verticalAlign: 'middle',
                      style: { 
                        color:'#000',
                        fontSize: '22px',
                      } 
                    },
                    exporting: {
                      enabled: false
                    },
                    series: [{
                      type: 'pie',
                      name: 'Message storage',
                      innerSize: '80%',
                      data: dataSizes
                    }],
                    plotOptions: {
                      // series: {
                      //   animation: false
                      // },
                      pie: {
                          allowPointSelect: true,
                          cursor: 'pointer',
                          size: 135,
                          dataLabels: {
                              enabled: false
                          },
                          borderWidth: 0,
                          margin: 0,
                      }
                    },
                    legend: {
                      itemDistance:0,
                    },
                    tooltip: {
                      pointFormat: '{series.name}: {point.prettyY}'
                    }
                  },
                })
          })
        }
      }
    });

    // Get todays device interactions
    getData('https://api.iotflows.com/v1/devices/' + specialDeviceUUID +'/data_streams_interaction_count/today')
    .then(async response => {
      if(response.ok ){
          const data = await response.json();
          if(Object.keys(data.data).length > 0){
          var intervalId = setInterval(this.timer, 60000);
          // store intervalId in the state so it can be accessed later:
          this.setState({intervalId: intervalId});


          var todaysData = data.data.map(result =>({
              name: result.data_stream_name,
              y: parseInt(result.count),
              data_stream_uuid: result.data_stream_uuid
          }))
          var todaysSum = 0
          for(var i = 0; i < Object.keys(todaysData).length; i++) {
            todaysSum = todaysSum + todaysData[i].y
          }
          var todaysSumInteger = todaysSum;
          if(todaysSumInteger >= 1000000){
            todaysSum = (Number.parseFloat(todaysSumInteger)/1000000.00).toFixed(1)
            todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
            todaysSum = todaysSum+"M"
          }
          else if( todaysSumInteger >= 100000  ){
            todaysSum = (Number.parseFloat(todaysSumInteger)/1000.00).toFixed(1)
            todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
            todaysSum = todaysSum+"k"
          }
          else{
            todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
          }

          this.setState({
            todaysTotalSumInteger: todaysSumInteger,
            todaysTotalSumSummary: todaysSum,
          })

          this.setState({
            pieChartTodaysOptions: {
              chart: {
                // renderTo: 'container',
                backgroundColor: null,
                height: 160,
                // width: 125,
              },
              colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
              // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
              credits: {enabled: false},
              title: {
                text: "",
                // text: todaysSum,
                verticalAlign: 'middle',
                style: { 
                  color:'#000',
                  fontSize: '22px',
                } 
              },
              exporting: {
                enabled: false
              },
              series: [{
                type: 'pie',
                name: 'Messages',
                innerSize: '80%',
                data: todaysData
              }],
              plotOptions: {
                // series: {
                //   animation: false
                // },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: 135,
                    dataLabels: {
                        enabled: false
                    },
                    borderWidth: 0,
                    margin: 0,
                }
            },
              legend: {
                itemDistance:0,
              }
            },
          })
        }
      }
    });

    // Get months device interactions
    getData('https://api.iotflows.com/v1/devices/' + specialDeviceUUID +'/data_streams_interaction_count/this_month')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(Object.keys(data.data).length > 0){
          
          var dic = {};
            for(var i=0; i<Object.keys(data.data).length; i++){
              dic[data.data[i].device_uuid]= data.data[i]
            }
            this.setState({
              months_dic: dic,
            })

          var monthsData = data.data.map(result =>({
            name: result.data_stream_name,
            y: parseInt(result.count),
            data_stream_uuid: result.data_stream_uuid
          }))


          var monthsSum = 0
          for(var i = 0; i < Object.keys(monthsData).length; i++) {
            monthsSum = monthsSum + monthsData[i].y
          }

          var monthsSumInteger = monthsSum;
          if(monthsSum > 1000000){
            monthsSum = (Number.parseFloat(monthsSum)/1000000.00).toFixed(2)
            monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
            monthsSum = monthsSum+"M"
          }
          else if( monthsSum > 100000 ){
            monthsSum = (Number.parseFloat(monthsSum)/1000.00).toFixed(1)
            monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
            monthsSum = monthsSum+"k"
          }
          else {
            monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
          }
          

          this.setState({
            monthsTotalSumInteger: monthsSumInteger,
            monthsTotalSumSummary: monthsSum,
            deviceMonthsData: monthsData,
          })



          this.setState({
            pieChartMonthsOptions: {
              chart: {
                // renderTo: 'container',
                backgroundColor: null,
                height: 160,
                // width: 125,
              },
              colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
              // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
              // colors: ['#0e2139', '#153255', '#1c4272', '#23538f', '#2a64ab', '#3175c8', '#4b87d2', '#679ad9', '#84ade0', '#a0c0e7'],
              // colors: ['#172330', '#223448', '#2e4660', '#395778', '#456990', '#517aa8', '#678cb6', '#7f9ec1', '#97b1cd', '#afc3d8'],
              credits: {enabled: false},
              title: {
                text: "",
                // text: monthsSum,
                verticalAlign: 'middle',
                style: { 
                  color:'#000',
                  fontSize: '22px',
                } 
              },
              exporting: {
                enabled: false
              },
              series: [{
                type: 'pie',
                name: 'Messages',
                innerSize: '80%',
                data: monthsData
              }],
              plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: 135,
                    dataLabels: {
                        enabled: false
                    },
                    borderWidth: 0,
                    margin: 0,
                }
            },
              legend: {
                itemDistance:0,
              }
            },
          })
        }
      }
    });

    // Historical interactions charts
    getData('https://api.iotflows.com/v1/devices/' + specialDeviceUUID + '/interaction_count/historical/highcharts')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(Object.keys(JSON.parse(data.data)).length > 0 ){
            this.setState({
                interactionOptions: {
                  ...this.state.interactionOptions,
                  series: 
                    [{...this.state.interactionOptions.series[0],
                      data: JSON.parse(data.data)}],
                }}, () => {
                  this.setState({
                    interactionOptions: {
                      credits: {enabled: false},
                      title: {
                        text: 'Published Messages',
                        margin: 0,
                        style: {
                          fontSize: '22px',
                          fontWeight: '900',
                          fontFamily:'Trebuchet MS',
                        }
                      },
                      chart: {
                        height: '225px',
                      },
                      scrollbar: {
                        enabled: false
                      },
                      time: {
                        timezoneOffset: new Date().getTimezoneOffset()
                      },
                      navigator: {
                        height: 15,
                        outlineWidth: 0,
                        outlineColor: '#ffffff',
                        xAxis: {
                          gridLineWidth: 0,
                          gridLineColor: '#ffffff',
                        },
                        yAxis: {
                          gridLineWidth: 0,
                          gridLineColor: '#ffffff',
                        },
                        series: {
                            lineWidth: 0.5,
                        }
                      },
                      rangeSelector:{
                        allButtonsEnabled: true,
                        buttons: [
                          {
                          type: 'day',
                          count: 1,
                          text: '1d'
                        }, {
                          type: 'day',
                          count: 7,
                          text: '7d'
                        }, {
                          type: 'day',
                          count: 14,
                          text: '14d'
                        },{
                          type: 'month',
                          count: 1,
                          text: '1m'
                        },{
                          type: 'all',
                          text: 'All'
                        }],
                        selected: 3
                      },
                      plotOptions: {
                        area: {
                            marker: {
                                radius: 2
                            },
                            lineWidth: 1,
                            states: {
                                hover: {
                                    lineWidth: 1
                                }
                            },
                            threshold: null
                        }
                      },
                      series: [{
                        type: 'area',
                        // color: '#4caf50',
                        name: 'Interactions',
                        data: JSON.parse(data.data), 
                        color: {
                          linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                          },
                          stops: [
                            [0, 'rgba(1,31,75,0.8)'],
                            [1, 'rgba(1,31,75,0.3)']
                            // [0, 'rgba(1,31,75,0.8)'],
                            // [1, 'rgba(3,57,108,0.4)']
                          ]
                        },
                        navigator: {
                          series: {
                              color: '#4caf50',
                          }
                        },
                      }]
                    }
                  })
                }
            );
          }
        }
      });


    // ****Inputted a device to make it easier to display data*********************
 

     // Get function to receive project device list
     getData('https://api.iotflows.com/v1/projects/prj_361dc9b4bc90bb947e86e06d9cc9a4db/devices')
     .then(async response => {
       if(response.ok ){
         const data = await response.json();
         if(data.data){
           var dic = {};
           for(var i=0; i<Object.keys(data.data).length; i++){
             if (data.data[i].device_last_heard){
               let localDateTime = new Date(data.data[i].device_last_heard);
               localDateTime = localDateTime.toLocaleTimeString()+' '+localDateTime.toLocaleDateString();
 
               data.data[i].device_last_heard = localDateTime;
               dic[data.data[i].device_uuid]= data.data[i];
             } else {
               data.data[i].device_last_heard = '';
               dic[data.data[i].device_uuid]= data.data[i];
             }
           }
           this.setState({
             devices_list: data.data,
             devicesDic: dic,
             },this.subscribedDevicesMQTT
           )
         }
       }
     });
    
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/daily_cost/highcharts')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            costOptions: {
              series: [
                {data: JSON.parse(data.data)}
              ]
            }
          });
        }
      });
   
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/daily_interaction_usage/highcharts')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
         
          
          this.setState({
            interactionOptions: {
              series: [
                {data: JSON.parse(data.data)}
              ]
            }
          });
        }
      });

      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/daily_device_usage/highcharts')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            deviceOptions: {
              series: [
                {data: JSON.parse(data.data)}
              ]
            }
          });
        }
      });

      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/billing/this_month_usage')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          var totalInteractions = data.data[0].current_month_interaction;
          if(totalInteractions > 1000000){
            totalInteractions = (Number.parseFloat(totalInteractions)/1000000.00).toFixed(1)+"M"
          }
          else if( totalInteractions > 1000 ){
            totalInteractions = (Number.parseFloat(totalInteractions)/1000.00).toFixed(1)+"k"
          }
          // MAKE ZERO if null
          if((totalInteractions == null && data.data[0].current_device_count == 0) || (data.data[0].current_month_cost == null)){
            this.setState({
              totalCost: 0.0,
              forecastCost: 0.0,
              currentDeviceNumber: data.data[0].current_device_count,
              totalInteractions: 0,
              totalCurrentInteractions: 0,
            });
          } else if( totalInteractions == null) {
            this.setState({
              totalCost: data.data[0].current_month_cost.toFixed(2),
              forecastCost: data.data[0].forecasted_cost.toFixed(2),
              currentDeviceNumber: data.data[0].current_device_count,
              totalInteractions: 0,
              totalCurrentInteractions: 0,
            });
          }else {
            this.setState({
              totalCost: data.data[0].current_month_cost.toFixed(2),
              forecastCost: data.data[0].forecasted_cost.toFixed(2),
              currentDeviceNumber: data.data[0].current_device_count,
              totalInteractions: totalInteractions,
              totalCurrentInteractions: data.data[0].current_month_interaction,
            });
          }
          
        }
      });

      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/current_interaction_count')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            currentDailyInteractionCount: parseInt(data.data),
          });
        }
      });

      // MQTT subscribing to everything
      if(MqttService.isConnected) {
        MqttService.subscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/#', this.onEverything)
        
      }

  }

  // callback function to ensure all device are listed
  subscribedDevicesMQTT(){
    if(MqttService.isConnected) {
      for (let i=0; i<Object.keys(this.state.devices_list).length; i++){
        MqttService.subscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/clients/' + this.state.devices_list[i].device_uuid + '/status/#', this.onDeviceStatus)
      }
    }
  }

  onDeviceStatus = message => {
    // updating state of device online and last heard
    try{
      const updatedDeviceState = JSON.parse(message.payloadString);
      var currentDeviceUuid = updatedDeviceState.username;
      var deviceOnlineStatus = updatedDeviceState.is_online;
  
      let localDateTime = new Date(parseInt(updatedDeviceState.timestamp));
      localDateTime = localDateTime.toLocaleTimeString()+' '+localDateTime.toLocaleDateString();

      var device_dic = this.state.devicesDic
      device_dic[currentDeviceUuid].device_is_online = deviceOnlineStatus;
      device_dic[currentDeviceUuid].device_last_heard = localDateTime;

      this.setState({
        devicesDic: device_dic,
      });
    }
    catch(e){
      console.log("Error in reading heartbeat from MQTT");
      console.log(e.message);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    MqttService.unsubscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/#')
    
  }

  onEverything = message => {

    try{
      var addingInteractions = this.state.currentDailyInteractionCount + 1;
      var currentTotal = this.state.totalCurrentInteractions + addingInteractions;
      var totalInteractions = '';

      if(currentTotal > 1000000){
        totalInteractions = (Number.parseFloat(currentTotal)/1000000.00).toFixed(1)+"M"
      }
      else if( currentTotal > 1000 ){
        var totalInteractions = (Number.parseFloat(currentTotal)/1000.00).toFixed(1)+"k"
      }
      
      this.setState({
        currentDailyInteractionCount: addingInteractions,
        totalInteractions: totalInteractions,
      })
    }
    catch(e){
      console.log("Error in reading On Everything notification from MQTT");
      console.log(e.message);
    }
  }

  onDataStreamDevice = message => {
    try{
      // adding to the todays messages in project list
      var currentTodaysSumInt = this.state.todaysTotalSumInteger + 1;
      var currentTodaysSumSummary = currentTodaysSumInt;
      if(currentTodaysSumInt >= 1000000){
        currentTodaysSumSummary = (Number.parseFloat(currentTodaysSumInt)/1000000.00).toFixed(1)
        currentTodaysSumSummary = currentTodaysSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
        currentTodaysSumSummary = currentTodaysSumSummary+"M"
      }
      else if( currentTodaysSumInt >= 100000  ){
        currentTodaysSumSummary = (Number.parseFloat(currentTodaysSumInt)/1000.00).toFixed(1)
        currentTodaysSumSummary = currentTodaysSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
        currentTodaysSumSummary = currentTodaysSumSummary+"k"
      }
      else{
        currentTodaysSumSummary = currentTodaysSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
      }
      this.setState({
        todaysTotalSumInteger: currentTodaysSumInt,
        todaysTotalSumSummary: currentTodaysSumSummary,
      })



      // adding to the months messages in project list
      var currentMonthsSumInt = this.state.monthsTotalSumInteger + 1;
      var currentMonthsSumSummary = currentMonthsSumInt;
      if(currentMonthsSumInt >= 1000000){
        currentMonthsSumSummary = (Number.parseFloat(currentMonthsSumInt)/1000000.00).toFixed(2)
        currentMonthsSumSummary = currentMonthsSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
        currentMonthsSumSummary = currentMonthsSumSummary+"M"
      }
      else if( currentMonthsSumInt >= 100000 ){
        currentMonthsSumSummary = (Number.parseFloat(currentMonthsSumInt)/1000.00).toFixed(1)
        currentMonthsSumSummary = currentMonthsSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
        currentMonthsSumSummary = currentMonthsSumSummary+"k"
      }
      else {
        currentMonthsSumSummary = currentMonthsSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
      }

      this.setState({
        monthsTotalSumInteger: currentMonthsSumInt,
        monthsTotalSumSummary: currentMonthsSumSummary 
      })

    }
    catch(e){
      console.log("Error in reading data stream in device from MQTT");
      console.log(e.message);
    }
  }

  formatBytes = (bytes, decimals = 1) => {
    try{
      if (bytes == 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k));
  
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
    }
    catch(e){
      return "-"
    }
    
  }

  formatDate = (date) => {
    if (date){
      let localDateTime = new Date(date);
      localDateTime = localDateTime.toLocaleTimeString()+' '+localDateTime.toLocaleDateString();
      return localDateTime;
    } else {
      return '';
    }
  }


  render(){
    const { classes, theme} = this.props
    const {costOptions,
          multiAxisOptions,
          interactionOptions,
          deviceOptions,
          multiLineChartOptions,
          totalCost,
          totalInteractions,
          currentDeviceNumber,
          forecastCost,
          currentDailyInteractionCount,
          firstDayOfMonth,
          yesterdaysDate,
          devices_list,
          devicesDic,
          upTimeOptions,
          pieChartSizeOptions,
          pieChartTodaysOptions,
          pieChartMonthsOptions,
          todaysTotalSumSummary,
          monthsTotalSumSummary,
          todaysTotalSumInteger,
          monthsTotalSumInteger,
          totalSizeSummary,
          asset_info,
          asset_flows,
          asset_widgets} = this.state

    // const asset_uuid = this.props.match.params.asset_uuid;

    console.log('this.props match')
    console.log(this.props.match)
    console.log('this.props.location')
    console.log(this.props.location)

    return (
      <div className={classes.root}>
          
        <Grid container spacing={0}>
          <Grid item xs={12} sm={2} xl={2}>
            <Card className={classes.cardRoot} style={{height:'710px', overflow:'scroll'}} variant="outlined">
              <CardContent style={{textAlign: 'left'}}>
                <div style={{textAlign:'center'}} >
                  <img src={TuckerMachine} alt="Machine image" style={{borderRadius:'8px'}}/>
                </div>
                <TableContainer styled={{marginTop:'12px'}}>
                  <Table aria-label="data table">
                    <colgroup>
                      <col style={{width:'30%'}}/>
                      <col style={{width:'70%'}}/>
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell style={{paddingLeft: '12px', borderBottom: 'none'}} colSpan={2}>Asset</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {asset_info.asset_custom_name &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Name</StyledTableCell>
                          <StyledTableCell align="left" >{asset_info.asset_custom_name}</StyledTableCell>
                        </TableRow>
                      }
                      {!asset_info.asset_custom_name && asset_info.machine_name &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Name</StyledTableCell>
                          <StyledTableCell align="left" >{asset_info.machine_name}</StyledTableCell>
                        </TableRow>
                      }
                      {asset_info.asset_custom_description &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Description</StyledTableCell>
                          <StyledTableCell align="left" >{asset_info.asset_custom_description}</StyledTableCell>
                        </TableRow>
                      }
                      {!asset_info.asset_custom_description && asset_info.machine_description &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Description</StyledTableCell>
                          <StyledTableCell align="left" >{asset_info.machine_description}</StyledTableCell>
                        </TableRow>
                      }
                      {asset_info.asset_custom_identifier &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>ID</StyledTableCell>
                          <StyledTableCell align="left" >{asset_info.asset_custom_identifier}</StyledTableCell>
                        </TableRow>
                      }
                      {!asset_info.asset_custom_identifier && asset_info.machine_identifier &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>ID</StyledTableCell>
                          <StyledTableCell align="left" >{asset_info.machine_identifier}</StyledTableCell>
                        </TableRow>
                      }
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Tags</StyledTableCell>
                          <StyledTableCell align="left" >CNC</StyledTableCell>
                        </TableRow>
                      {asset_info.asset_custom_homepage_url &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Homepage URL</StyledTableCell>
                          <StyledTableCell align="left" style={{cursor:'pointer'}} onClick={() => window.open(asset_info.asset_custom_identifier, "_blank")}>{asset_info.asset_custom_identifier}</StyledTableCell>
                        </TableRow>
                      }
                      {!asset_info.asset_custom_homepage_url && asset_info.machine_homepage_url &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Homepage URL</StyledTableCell>
                          <StyledTableCell align="left" style={{cursor:'pointer'}} onClick={() => window.open(asset_info.machine_homepage_url, "_blank")}>{asset_info.machine_homepage_url}</StyledTableCell>
                        </TableRow>
                      }
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell style={{paddingLeft: '12px', borderBottom: 'none'}} colSpan={2}>
                          {'Manufacturer'}&nbsp;&nbsp;&nbsp;
                          {asset_info.manufacturer_is_verified &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" fill="#1e40af" class="bi bi-check2-circle" viewBox="0 0 16 16">
                              <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                              <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                            </svg>
                          }
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {asset_info.manufacturer_name &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Name</StyledTableCell>
                          <StyledTableCell align="left" >{asset_info.manufacturer_name}</StyledTableCell>
                        </TableRow>
                      }
                      {asset_info.manufacturer_description &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Description</StyledTableCell>
                          <StyledTableCell align="left" >{asset_info.manufacturer_description}</StyledTableCell>
                        </TableRow>
                      }
                      {asset_info.manufacturer_homepage_url &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Homepage URL</StyledTableCell>
                          <StyledTableCell align="left" style={{cursor:'pointer'}} onClick={() => window.open(asset_info.manufacturer_homepage_url, "_blank")}>{asset_info.manufacturer_homepage_url}</StyledTableCell>
                        </TableRow>
                      }
                      {asset_info.manufacturer_support_email &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Support Email</StyledTableCell>
                          <StyledTableCell align="left" >{asset_info.manufacturer_support_email}</StyledTableCell>
                        </TableRow>
                      }
                      {asset_info.manufacturer_support_phone &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Support Phone</StyledTableCell>
                          <StyledTableCell align="left" >{asset_info.manufacturer_support_phone}</StyledTableCell>
                        </TableRow>
                      }
                      {asset_info.manufacturer_support_homepage_url &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Support URL</StyledTableCell>
                          <StyledTableCell align="left" style={{cursor:'pointer'}} onClick={() => window.open(asset_info.manufacturer_support_homepage_url, "_blank")}>{asset_info.manufacturer_support_homepage_url}</StyledTableCell>
                        </TableRow>
                      }
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell style={{paddingLeft: '12px', borderBottom: 'none'}} colSpan={2}>
                          Device
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {asset_info.device_name &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Name</StyledTableCell>
                          <StyledTableCell align="left" >{asset_info.device_name}</StyledTableCell>
                        </TableRow>
                      }
                      {asset_info.device_description &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Description</StyledTableCell>
                          <StyledTableCell align="left" >{asset_info.device_description}</StyledTableCell>
                        </TableRow>
                      }
                      {asset_info.device_last_heard &&
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Last Connection Established</StyledTableCell>
                          <StyledTableCell align="left" >{this.formatDate(asset_info.device_last_heard)}</StyledTableCell>
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item container xs={12} sm={10} xl={10}>
            <Grid item xs={12} sm={8} xl={8}>
              <Card className={classes.cardRoot} style={{padding:'8px', height:'111px'}} variant="outlined">
                <CardContent style={{display:'flex'}} >
                  <Grid item xs={6} sm={3} >
                    <div style={{display:'flex', flexDirection:'column'}}>
                      <div style={{fontSize:'14px', fontWeight:'400', color:'#6b7280'}}>System Welds</div>
                      <div style={{fontSize:'24px', fontWeight:'700', color:'#1f2937'}}>569k</div>
                      <div style={{fontSize:'12px', fontWeight:'300', color:'#6b7280'}}>569,342</div>
                    </div>
                  </Grid>    
                  <Grid item xs={6} sm={3} >
                    <div style={{display:'flex', flexDirection:'column'}}>
                      <div style={{fontSize:'14px', fontWeight:'400', color:'#6b7280'}}>Outlet Welds</div>
                      <div style={{fontSize:'24px', fontWeight:'700', color:'#1f2937'}}>253k</div>
                      <div style={{fontSize:'12px', fontWeight:'300', color:'#6b7280'}}>253,321</div>
                    </div>
                  </Grid>    
                  <Grid item xs={6} sm={3} >
                    <div style={{display:'flex', flexDirection:'column'}}>
                      <div style={{fontSize:'14px', fontWeight:'400', color:'#6b7280'}}>Tool Welds</div>
                      <div style={{fontSize:'24px', fontWeight:'700', color:'#1f2937'}}>1.3M</div>
                      <div style={{fontSize:'12px', fontWeight:'300', color:'#6b7280'}}>1,439,742</div>
                    </div>
                  </Grid>    
                  <Grid item xs={6} sm={3} >
                    <div style={{display:'flex', flexDirection:'column'}}>
                      <div style={{fontSize:'14px', fontWeight:'400', color:'#6b7280'}}>Prog Welds</div>
                      <div style={{fontSize:'24px', fontWeight:'700', color:'#1f2937'}}>34.8k</div>
                      <div style={{fontSize:'12px', fontWeight:'300', color:'#6b7280'}}>34,891</div>
                    </div>
                  </Grid>    
                </CardContent>
              </Card>
              
              <Card className={classes.cardRoot} style={{ padding:'8px', height:'270px'}} variant="outlined">
                <CardContent style={{padding:'0px'}} >
                  <div className={classes.mapContainer}>
                    <div style={{display: 'inline-flex', width:'100%', height:'100%', padding: '1px 0px 0px',borderRadius: '8px',overflow:'hidden'}}>
                      <Map devices_list={devices_list} devicesDic={devicesDic} />
                    </div>
                  </div>
                    
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
              <Card className={classes.cardRoot} style={{width:'100%', padding:'8px', height:'386px'}} variant="outlined">
                  <CardContent >
                    <div style={{textAlign:'left'}}>
                      <h4 style={{fontWeight:'700', fontSize:'14px'}}>Critical Alarms</h4>
                    </div>
                    {alerts.map((alert,id) => (
                      <Paper key={id} style={{width:'100%', padding:'4px', marginBottom:'16px', fontSize:'12px', fontFamily:'Trebuchet MS'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                          <div style={{textAlign:'left'}}>
                            {alert.error}
                          </div>
                          <div style={{marginLeft:'auto'}}>
                            {alert.time}
                          </div>
                        </div>
                        <div style={{display:'flex', marginTop:'4px', alignItems:'center'}}>
                          <div style={{textAlign:'left'}}>
                            {alert.description}
                          </div>
                          {alert.action &&
                            <div style={{marginLeft:'auto', padding: '2px 4px', border:'1px solid #94a3b8', borderRadius:'5px'}}>
                              {alert.action}
                            </div>
                          }
                        </div>
                      </Paper>
                    ))}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={8} xl={8}>
              <Card className={classes.cardRoot} style={{padding:'8px', height:'314px'}} variant="outlined">
                <CardContent style={{padding:'0px'}} >
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={multiAxisOptions}
                  /> 
                    
                </CardContent>
              </Card>
            </Grid> 
            <Grid item xs={12} sm={4} xl={4}>
              <Card className={classes.cardRoot} style={{width:'100%', padding:'8px', height:'314px'}} variant="outlined">
                <CardContent style={{padding:'0px'}} >
                <TableContainer styled={{marginTop:'12px'}}>
                  <Table aria-label="data table">
                    <colgroup>
                      <col style={{width:'40%'}}/>
                      <col style={{width:'60%'}}/>
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell style={{fontSize:"16px",paddingLeft: '12px', borderBottom: 'none'}} colSpan={2}>Asset Flows</StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell style={{paddingLeft: '12px', borderBottom: 'none'}} >Name</StyledTableCell>
                        <StyledTableCell style={{paddingLeft: '12px', borderBottom: 'none'}} >Parsing Expression</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {Object.keys(asset_flows).length > 0 && asset_flows.map(assetFlow => (
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>{assetFlow.flow_name}</StyledTableCell>
                          <StyledTableCell align="left" >{assetFlow.flow_mqtt_payload_parsing_expression}</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                    
                </CardContent>
              </Card>
            </Grid> 
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={3}>
            <Card className={classes.cardRoot} variant="outlined">
              <CardContent >
                <SynchronisedCharts/>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.cardRoot} variant="outlined">
              <CardContent >
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={upTimeOptions}
                />
                <TableContainer styled={{marginTop:'12px'}}>
                  <Table aria-label="data table">
                    <colgroup>
                      <col style={{width:'35%'}}/>
                      <col style={{width:'30%'}}/>
                      <col style={{width:'35%'}}/>
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell style={{paddingLeft: '12px', borderBottom: 'none'}} colSpan={2}>Connection Events</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Device connection lost</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >Tucker Machine 12.4v</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >7:11:47 PM 12/15/2021</StyledTableCell>
                        </TableRow>
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Device connected</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >Tucker Machine 12.4v</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >6:11:48 PM 12/14/2021</StyledTableCell>
                        </TableRow>
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Device connection lost</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >Tucker Machine 12.4v</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >6:11:47 PM 12/14/2021</StyledTableCell>
                        </TableRow>
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Device connected</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >Tucker Machine 12.4v</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >01:11:48 PM 12/10/2021</StyledTableCell>
                        </TableRow>
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Device connection lost</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >Tucker Machine 12.4v</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >01:11:47 PM 12/10/2021</StyledTableCell>
                        </TableRow>
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Device connected</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >Tucker Machine 12.4v</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >5:11:32 PM 11/03/2021</StyledTableCell>
                        </TableRow>
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Device connection lost</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >Tucker Machine 12.4v</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >5:11:31 PM 11/03/2021</StyledTableCell>
                        </TableRow>
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Device connected</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >Tucker Machine 12.4v</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >5:11:32 PM 11/03/2021</StyledTableCell>
                        </TableRow>
                        <TableRow >
                          <StyledTableCell align="left" style={{paddingLeft: '12px', color:'#6b7280'}}>Device connection lost</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >Tucker Machine 12.4v</StyledTableCell>
                          <StyledTableCell align="left" style={{color:'#6b7280'}} >5:11:31 PM 11/03/2021</StyledTableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid item xs={12}  >
              <Card className={classes.cardRoot}  variant="outlined">
                <CardContent style={{position:'relative',textAlign: 'center', padding: '0px', width: '225px', marginLeft: 'auto', marginRight: 'auto'}}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.cardHeader}  gutterBottom>
                      Storage
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.interactionsContainer}>
                      <span className={classes.sizeSum} >{totalSizeSummary} </span>
                    </div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'chart'}
                      options={pieChartSizeOptions}
                    />
                  </Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} >
              <Card className={classes.cardRoot}  variant="outlined">
                <CardContent style={{position:'relative',textAlign: 'center', padding: '0px', width: '225px', marginLeft: 'auto', marginRight: 'auto'}}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.cardHeader}  gutterBottom>
                      Today's Messages
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.interactionsContainer}>
                      <span className={classes.interactionsSumTop} >{todaysTotalSumSummary} </span>
                      <span className={classes.interactionsSumBottom} >{todaysTotalSumInteger.toLocaleString('en-US', {maximumFractionDigits:2})} </span>
                    </div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'chart'}
                      options={pieChartTodaysOptions}
                    />
                  </Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card className={classes.cardRoot} variant="outlined">
                <CardContent style={{position:'relative',textAlign: 'center', padding: '0px', width: '225px', marginLeft: 'auto', marginRight: 'auto'}}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.cardHeader}  gutterBottom>
                      Month's Messages
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.interactionsContainer}>
                      <span className={classes.interactionsSumTop} >{monthsTotalSumSummary} </span>
                      <span className={classes.interactionsSumBottom} >{monthsTotalSumInteger.toLocaleString('en-US', {maximumFractionDigits:2})} </span>
                    </div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'chart'}
                      options={pieChartMonthsOptions}
                    />
                  </Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.cardRoot} variant="outlined">
              <CardContent style={{textAlign: 'left'}}>
                <Grid container spacing={1}>
                  <Grid item sm={6}>
                    <Typography className={classes.cardValue} >
                      ${totalCost}
                    </Typography>
                    <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                      Current Estimated Month's Cost 
                    </Typography>
                    <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                      {/* ({firstDayOfMonth} - {yesterdaysDate}) */}
                    </Typography>
                  </Grid>
                  <Grid className={classes.switchText} item sm={6}>
                    <Typography className={classes.cardValue}  >
                      ${forecastCost}
                    </Typography>
                    <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                      Month's Estimated Forecast Cost
                    </Typography>
                  </Grid>
                </Grid>
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={costOptions}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.cardRoot} variant="outlined">
              <CardContent style={{textAlign: 'left'}}>
                <Typography className={classes.cardValue} >
                  {currentDeviceNumber}
                </Typography>
                <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                  Connected Machine(s)
                </Typography>
                
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={multiLineChartOptions}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>


        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Card className={classes.cardRoot} variant="outlined">
              <CardContent style={{textAlign: 'left'}}>
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={interactionOptions}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.cardRoot} variant="outlined">
              <CardContent style={{textAlign: 'left'}}>
                <Typography className={classes.cardValue} >
                  {currentDeviceNumber}
                </Typography>
                <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                  Current Linux Devices
                </Typography>
                
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={deviceOptions}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </div>


    )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
// export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{ withTheme: true })(SelectedAssetPage));
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{ withTheme: true })(withRouter(SelectedAssetPage)));


import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import  {getData, putData, deleteData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import Tooltip from '@material-ui/core/Tooltip';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    margin:theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(44),
    }
  },
  button: {
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
  hoverText: {    
    fontSize: 33,
    paddingLeft: 0,
    
  },
  radioLabel: {
    fontSize: 14,
  },
  radioCSS: {
    // border: "1px solid blue",
    fontSize: 'small'
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class LastMemberAdminModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    open: this.props.open,
    loading: false,
    success: false,
    openSnackbar: false,
    mode_options: '',
  }

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitButtonClick = this.submitButtonClick.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);

  }
  

  handleOpen = () => {
    this.setState({ 
      open: true,
      success: false,
      loading: false, });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      success: false,
      loading: false, });
      this.props.handleCloseModalLastMember();
  };

  submitButtonClick = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          
        deleteData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/members', 
        {'member_username': this.props.user_username,
        'delete_mode': this.state.mode_options})
          .then(async response => {
            if(response.ok){ 
              this.props.showSuccessSnackbar("Member has been successfully removed!"); 
              this.setState({
                loading: false,
                success: true,
                open:false,
              },()=> {
                this.props.refreshMemberList();
                this.props.handleCloseModalLastMember();
                this.setState({
                  success: false,
                })
              });
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false,
                }); 
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };


  handleRoleChange = (event) => {
    this.setState({mode_options: event.target.value});
  };

  componentWillReceiveProps({open}) {
    this.setState({...this.state,open})
  }

  render() {
    const { classes } = this.props;
    const { open, loading, success, mode_options } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    

    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
        
      >
        {/* <Button className={classes.button} onClick={this.handleOpen}>Change Role</Button> */}
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes={{ paper: classes.dialog }}
            >
            <Paper className={classes.paper} >

                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Last Administrator</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '0px', paddingTop: '10px'}}>
                
                <DialogContentText style={{margin: '0px', color: '#000000'}} id="alert-dialog-description">
                  {this.props.lastMember}the last administrator of at least one project or alert channel. 
                  <br/>
                  <br/>
                  Would you like to: 
                </DialogContentText>

                <FormControl  component="fieldset">
                  {/* <FormLabel component="legend">Deleting Options</FormLabel> */}
                  <RadioGroup aria-label="role" name="delete_mode_options"   value={mode_options} onChange={this.handleRoleChange}>
                        <FormControlLabel classes={{label: classes.radioLabel}} value="delete_all_resources" control={<Radio color="primary"  className={classes.radioCSS}/>} label={"Delete all resources and member"} />
                        <FormControlLabel classes={{label: classes.radioLabel}} value="delete_member_only" control={<Radio color="primary"  className={classes.radioCSS}/>} label={"Keep resources and remove member"} />
                  </RadioGroup>
                </FormControl>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Delete { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              
            </Paper>

          </Dialog>
        
      </Grid>
    );
  }
}

LastMemberAdminModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    // currentUserInfo: state.users.currentUserInfo,
    // allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(LastMemberAdminModal));

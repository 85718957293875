import React from 'react';
import PropTypes from 'prop-types';
import { fade, withStyles, rgbToHex } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { blue } from '@material-ui/core/colors';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import SearchMember from './SearchMember';
import  {postData, getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import Tooltip from '@material-ui/core/Tooltip';
import {searchbarUserHandler} from '../store/actions/searchbarUserActions';
import SendInviteModal from './SendInviteModal';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    width: '135px',
    // font-weight:bold;
    padding:'5px 14px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    border:'1px solid #bdbdbd',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  dialog: {borderRadius: 10 } ,
  hoverText: {    
    fontSize: 33,
    paddingLeft: 0,
  },
  radioLabel: {
    fontSize: 14,
  },
  radioCSS: {
    // border: "1px solid blue",
    fontSize: 'small'
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// const contacts = ['username@gmail.com', 'user028@gmail.com', 'username1@gmail.com', 'user027@gmail.com', 'username2@gmail.com', 'user026@gmail.com', 'username3@gmail.com', 'user025@gmail.com','username4@gmail.com', 'user024@gmail.com', 'username5@gmail.com', 'user023@gmail.com', 'username6@gmail.com', 'user022@gmail.com', 'username7@gmail.com', 'user021@gmail.com'];

// const items = [
//   { id: 1, name: 'Ulises', email: 'username@gmail.com'}, 
//   { id: 2, name: 'Amanda',email: 'user028@gmail.com'}, 
//   { id: 3, name: 'Mahmoud', email: 'username1@gmail.com'}, 
//   { id: 4, name: 'Paul', email: 'username2@gmail.com'},
// ]


class AddMemberModal extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      open: false,
      loading: false,
      success: false,
      roles: [],
      memberRole: '',
      // role_id: '',
      // role_name: '',
      // role_description: '',
      
    }
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitButtonClick = this.submitButtonClick.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);

  }

  componentDidMount() {
    

      getData(this.props.roleApi)
      .then(async response => {
        
        if(response.ok ){
          const data = await response.json();
          this.setState({
            roles: data.data 
          })
        }
        
      });
  } 



  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false, });
  };

  handleClose = () => {
    this.props.searchbarUserHandler();
    this.setState({ 
      open: false,
      loading: false,
      success: false, });
  };

  submitButtonClick = () => {

    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        postData(this.props.memberApi, 
        {'member_username': this.props.searchbarUsername,
          'role_name': this.state.memberRole})
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              // const data = await response.json();
              this.props.showSuccessSnackbar("Member has been successfully added!");
              this.setState({
                loading: false,
                success: true,
                open:false,
              }, ()=> {
                  this.props.refreshMemberList();
                  this.setState({
                    success: false,
                  })
                }
              );
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false,
                });
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };


  handleRoleChange = (event) => {
    this.setState({memberRole: event.target.value});
  };

  handleOpenSendInviteModal = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
     });
  };




  render() {
    const { classes, searchbarUsername } = this.props;
    const { open, loading, success , memberRole} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Grid>
        <Button className={classes.button} onClick={this.handleOpen} variant="contained" disabled = {this.props.disableBtn} >
            <AddCircleOutlineOutlinedIcon fontSize="small" /> &nbsp;Add Member          
        </Button>

        
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes={{ paper: classes.dialog }}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}}>{"Add Member"}</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '5px', paddingTop: '20px'}}>

                <SearchMember searchMemberApi={this.props.searchMemberApi} />

                <FormControl  component="fieldset">
                  <FormLabel component="legend">Role</FormLabel>
                  <RadioGroup aria-label="role" name="memberRole"   value={memberRole} onChange={this.handleRoleChange}>
                    {this.state.roles.map( (role,index) => (
                      <LightTooltip  key={index.toString()} className={classes.hoverText} title={role.role_description} placement="right-start" arrow  >
                        <FormControlLabel classes={{label: classes.radioLabel}} value={role.role_name} control={<Radio color="primary"  className={classes.radioCSS}/>} label={role.role_name} />
                      </LightTooltip>
                    ))}
                  </RadioGroup>
                </FormControl>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading || !memberRole || !searchbarUsername} onClick={this.submitButtonClick}>
                    Add member { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

AddMemberModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  searchbarUserHandler: username => dispatch(searchbarUserHandler(username)),

})

const mapStateToProps = state => {
  return {
    searchbarUsername: state.searchbarUser.searchbarUsername
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AddMemberModal));

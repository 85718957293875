import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Row from 'react-bootstrap/Row';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../../store/actions/snackbarActions';
import  { updateUserPhoneNumber, verifyUserPhoneNumber, putData  } from '../../ApiCalls/DataApis';
import EditProfileModal from '../EditProfileModal';
import {fetchCurrentUser} from '../../store/actions/userActions';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';



const styles = theme => ({
  
  paper: {
    position: 'relative',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    overflowY:'visible',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    // backgroundColor:'transparent',
    borderRadius:'5px',
    width: 'auto',
    height: 'auto',
    // border:'1px solid #4f86ce',
    justifyContent: 'center',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { 
    borderRadius: 10,
    overflowY:'visible',
    // overflowY: 'hidden',
   },
  
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});




class EnterNewNumberModal extends React.Component {
  state = {
    openPhoneNumberModal: false,
    loadingPhoneNumber: false,
    loadingVerificationCode: false,
    successPhoneNumber: false,
    successVerificationCode: false,
    phone_number: '',
    unedited_phone_number: '',
    verificationCodeModalOpen: false,
    verification_code: '',
    phone: '',

  };

  timer = undefined;

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handlePhoneNumberOpen = () => {
    this.setState({ openPhoneNumberModal: true,});
  };

  handleClose = () => {
    this.setState({
      loadingVerificationCode: false,
      successVerificationCode: false,
      verificationCodeModalOpen:false,
      openPhoneNumberModal : false,
      successPhoneNumber: false,
      loadingPhoneNumber: false,
      phone_number: '',
      verification_code: '',
      error: '',
      phone: '',
    });

  };

  // handlePhoneNumberChange = (event) => {
  //   this.setState({phone_number: event.target.value});
  // }

  handleVerificationCodeChange = (event) => {
    this.setState({verification_code: event.target.value});
  }
  

  // componentWillReceiveProps({open}) {
  //   this.setState({...this.state,open})
  // }

  submitPhoneNumberButtonClick = () => {
    const { loadingPhoneNumber } = this.state;
    // console.log('phone')
    // console.log(this.state.phone)
    // this.setState({unedited_phone_number: '+' + this.state.phone },()=> {console.log(this.state.unedited_phone_number)})
    
    if (!loadingPhoneNumber) {
      this.setState(
        {
          successPhoneNumber: false,
          loadingPhoneNumber: true,
          loadingVerificationCode: false,
          successVerificationCode: false,
          unedited_phone_number: '+' + this.state.phone,
        },
        () => {
        // Update the phone number in Cognito
        updateUserPhoneNumber(this.state.unedited_phone_number).then(async response => {
          if(response == "SUCCESS")
          {
            this.setState({
              loadingPhoneNumber: false,
              successPhoneNumber: true,
              verificationCodeModalOpen: true,
            })
            this.props.showSuccessSnackbar("Phone number update request sent successfully.");
          }        
          else
          {
            this.props.showErrorSnackbar("Phone number update request failed.");

          }
        })
        }
      )
    }
  };

  submitVerificationCodeButtonClick = () => {
    const { loadingVerificationCode } = this.state;

    if (!loadingVerificationCode) {
      this.setState(
        {
          successVerificationCode : false,
          successPhoneNumber: false,
          loadingVerificationCode : true,
        },
        () => {
        // Verify the code      
      verifyUserPhoneNumber(this.state.verification_code).then(async response => {       
           
        if(response == "SUCCESS")
        {
          
          // Update the phone number in IoTFlows DB if phone verified
          putData('https://api.iotflows.com/v1/users/phone_number', 
          {'phone_number': this.state.unedited_phone_number})
            .then(async response => {
              if(response.ok){                  
                this.props.showSuccessSnackbar("Phone number updated successfully.");
                this.props.fetchCurrentUser();
                this.props.refreshUserInfo();
                this.setState({
                  loadingVerificationCode: false,
                  successVerificationCode: true,
                  verificationCodeModalOpen:false,
                  openPhoneNumberModal : false,
                });
              }
              else{
                try{
                  const data = await response.json();
                  this.props.showErrorSnackbar(data.message);
                  this.setState({
                    loadingVerificationCode: false,
                    successVerificationCode: false,
                  });
                }catch (e){
                  this.props.showErrorSnackbar('Something went wrong');
                }
              }
            })
          }
          else
          {
            this.props.showErrorSnackbar("Phone number verification failed.");
            this.setState({
              loadingVerificationCode: false,
              successVerificationCode: false,
            });
          }          
        }
      ).catch( e => {
        this.props.showErrorSnackbar("Phone number verification failed.");
        this.setState({
          loadingVerificationCode: false,
          successVerificationCode: false,
        });
      })              
      // ---
        }
      )
    }
  };

  handleVerificationCodeModalClose = () => {
    this.setState({
      loadingVerificationCode: false,
      successVerificationCode: false,
      verificationCodeModalOpen:false,
      openPhoneNumberModal : false,
      successPhoneNumber: false,
      loadingPhoneNumber: false,
      // phone_number: '',
      // verification_code: '',
    });
    
  };



  // handlePhoneNumberChange = ({ target: { value } }) => {   
  //   this.setState(prevState=> ({ phone_number: this.normalizeInput(value, prevState.phone_number) }));
  // };
  


  // normalizeInput = (value, previousValue) => {
  //   if (!value) return value;
  //   const currentValue = value.replace(/[^+\d]/g, '');
  //   const cvLength = currentValue.length;
    
  //   if (!previousValue || value.length > previousValue.length) {
  //     // console.log('cvLength')
  //     // console.log(cvLength)
  //     // console.log('currentValue')
  //     // console.log(currentValue)
  //     this.setState({unedited_phone_number: currentValue})
  //     if (cvLength < 3) return currentValue;
  //     if (cvLength < 6) return `${currentValue.slice(0,2)} (${currentValue.slice(2, 5)})`;
  //     if (cvLength < 7) return `${currentValue.slice(0,2)} (${currentValue.slice(2, 5)}) ${currentValue.slice(5)}`;
  //     return `${currentValue.slice(0,2)} (${currentValue.slice(2, 5)}) ${currentValue.slice(5, 8)}-${currentValue.slice(8, 14)}`;
  //   }
  // };

  render() {
    const { classes } = this.props;
    const { openPhoneNumberModal, loadingPhoneNumber, successPhoneNumber,
            verificationCodeModalOpen, successVerificationCode, loadingVerificationCode, phone } = this.state;

    const buttonClassnamePhoneNumber = classNames({
      [classes.buttonSuccess]: successPhoneNumber ,
      [classes.buttonSuccess]: successVerificationCode ,
    });

    const buttonClassnameVerification = classNames({
      [classes.buttonSuccess]: successVerificationCode ,
    }); 

    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
        
      >
        <Button className={classes.button} onClick={this.handlePhoneNumberOpen}>Edit</Button>

        {/* {console.log('made it to edit profile')}
        {console.log(this.state.open)} */}
          
          <Dialog
              open={openPhoneNumberModal }
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Phone Number Change</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '15px', paddingTop: '15px', overflowY:'visible'}}>

                {/* <TextField
                  autoFocus
                  margin="normal"
                  id="phone_number"
                  label="Phone Number"
                  placeholder="+x(xxx) xxx-xxxx"
                  // helperText="+15551234567"
                  value={this.state.phone_number}
                  onChange={this.handlePhoneNumberChange}
                  type="tel"
                  fullWidth
                /> */}
                {/* {this.state.error && <p className="error">{this.state.error}</p>} */}

                <PhoneInput
                  country={'us'}
                  value={this.state.phone}
                  onChange={phone => this.setState({ phone })}
                />

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  {/* Disabling button until user enters phone number */}
                  {this.state.phone != '' ?
                    <Button  color="primary" type='submit' className={buttonClassnamePhoneNumber} disabled={loadingPhoneNumber } onClick={this.submitPhoneNumberButtonClick}>
                    Submit { loadingPhoneNumber && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                  :
                    <Button disabled color="primary" type='submit' className={buttonClassnamePhoneNumber} >
                      Submit 
                    </Button>
                  }
                </DialogActions>
              </Paper>
         
        </Dialog>


        {/* <VerificationCodeModal open={verificationCodeModalOpen} phone_number={this.state.phone_number} /> */}

        <Dialog
              open={verificationCodeModalOpen}
              onClose={this.handleVerificationCodeModalClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Verification Code</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '15px', paddingTop: '15px'}}>

                <TextField
                  autoFocus
                  margin="normal"
                  id="verification_code"
                  label="Verification Code"
                  onChange={this.handleVerificationCodeChange}
                  type="text"
                  fullWidth
                />


                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleVerificationCodeModalClose} >
                    Close
                  </Button>
                  
                  <Button  color="primary" type='submit' className={buttonClassnameVerification} disabled={loadingVerificationCode } onClick={this.submitVerificationCodeButtonClick}>
                    Submit { loadingVerificationCode && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
        </Dialog>
       

        
      </Grid>
    );
  }
}

EnterNewNumberModal.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),

})
const mapStateToProps = state => state

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(EnterNewNumberModal));

const initialState = {
  
  searchbarUsername: '',
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'SELECTED_SEARCHBAR_USER':
      return {
        ...state,
        searchbarUsername: action.username
      }
    // case 'FETCH_USERS':
    //   return {
    //     ...state,
    //     users: action.payload
    //   }
    // case 'SELECTED_USER':
    //   return {
    //     ...state,
    //     selectedUser: action.payload
    //   }
   
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import "../styles/ButtonStyles.scss";
import { connect} from "react-redux";
import {fetchCurrentUser} from '../store/actions/userActions'
import {fetchOrganizations, fetchSelectedOrganization} from '../store/actions/organizationActions';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import {getData, postData} from '../ApiCalls/DataApis';
import Popover from '@material-ui/core/Popover';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';



const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2196f3",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 16,
    border: '1px solid #fafafa',
    // arrow: {
    //   backgroundColor: '#fafafa',
    //   color: '#2196f3',
    // },
    
  },
  arrow: {
    // backgroundColor: '#fafafa',
    // border: '1px solid #fafafa',
    color: '#2196f3',
  },
}))(Tooltip);


const styles = theme => ({
  button: {
    backgroundColor:'transparent',
    textTransform: 'none',
    borderRadius:'10px',
    border:'0px solid #fff',
    display:'inline-block',
    cursor:'pointer',
    color:'#fff',
    fontFamily:'Trebuchet MS',
    fontSize:'14px',
    fontWeight:500,
    textDecoration:'none',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
      opacity: 0.7
    },
    '&:active': {
      backgroundColor:'transparent',
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dbFeedbackFormTextarea: {
      resize: 'none',
      border: 'none',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#3c4257',
      padding: '12px',
      width: '100%',
      minHeight: '80px',
      background: 'transparent',
      boxSizing: 'border-box',
      flexGrow: '1',
      '&:hover': {
        outline: 'none',
      },
      '&:active': {
        outline: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
  },
  feedbackModalContainer: {
    width: '320px',
    // height: '200px',
    boxShadow: '0 0 0 1px rgb(136 152 170 / 10%), 0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%)',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  actionSection: {
    padding: '12px',
    backgroundColor: '#f7fafc',
  },
  bottomText: {
    paddingTop: '8px',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    display: 'inline',
    color: '#3c4257',
  },
});

class FeedbackButton extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      textAreaValue:'',
      loading: false,
      success: false,

    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    

  }


  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget  });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    };

  submitButtonClick = () => {
    
    const { loading, textAreaValue } = this.state;
    const {currentUserInfo} = this.props;
    var userfeedback = "Console user feedback: " + textAreaValue;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        
        postData('https://api.iotflows.com/v1/supports/sales' , 
        {'user_first_name': currentUserInfo[0].user_first_name,
          'user_last_name': currentUserInfo[0].user_last_name,
          'work_email': currentUserInfo[0].user_email,
          'website': 'Feedback from Console',
          'country': 'Feedback from Console',
          'user_message': userfeedback,
        })
          .then(async response => {
            if(response.ok){
              const data = await response.json();
              this.props.showSuccessSnackbar("Thank you for your feedback!");
              this.setState({
                loading: false,
                success: true,
                open:false,
              }, ()=> {this.handleClose()});
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false});
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })



        }
      )
    }
  };

  handleChange = (event) => {
    this.setState({textAreaValue: event.target.value});
  }

  


  render() {
    const { classes, currentUserInfo} = this.props
    const { anchorEl,
            textAreaValue, 
            loading, 
            success} = this.state;

    const open = Boolean(anchorEl);
    const id = open ? 'feedback-popover' : undefined;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });


    return(
      <div>
        <Button aria-describedby={id} className={classes.button} variant="contained" color="primary" onClick={this.handleClick}>
          <svg height="16" width="16" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
            <path fill="#fff" d="M2.666 11.995a304.44 304.44 0 0 1-1.841-.776s-.41-.14-.558-.638c-.148-.498-.187-1.058 0-1.627.187-.57.558-.735.558-.735s9.626-4.07 13.64-5.43c.53-.179 1.18-.156 1.18-.156C17.607 2.702 19 6.034 19 9.9c0 3.866-1.62 6.808-3.354 6.84 0 0-.484.1-1.18-.135-2.189-.733-5.283-1.946-7.971-3.035-.114-.045-.31-.13-.338.177v.589c0 .56-.413.833-.923.627l-1.405-.566c-.51-.206-.923-.822-.923-1.378v-.63c.018-.29-.162-.362-.24-.394zM15.25 15.15c1.367 0 2.475-2.462 2.475-5.5s-1.108-5.5-2.475-5.5-2.475 2.462-2.475 5.5 1.108 5.5 2.475 5.5z" ></path>
          </svg>
          &nbsp; Feedback?
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className={classes.feedbackModalContainer}>
            {/* <textarea class="db-FeedbackForm-textarea" placeholder="Help us improve this page." spellcheck="false"></textarea> */}
            {/* <form onSubmit={this.submitButtonClick}> */}
              <textarea className={classes.dbFeedbackFormTextarea} value={textAreaValue} placeholder="Help us improve this page." onChange={this.handleChange} />
            {/* <input type="submit" value="Submit" />
          </form> */}
            <div className={classes.actionSection}>
              <div style={{display:'flex'}}>
                <Button variant="contained" style={{backgroundColor: '#fff'}} onClick={this.handleClose} >
                  Close
                </Button>
                <Button  variant="outlined" color="primary" style={{marginLeft: 'auto'}} type='submit' className={buttonClassname} disabled={loading || (textAreaValue == '') } onClick={this.submitButtonClick}>
                  {'Send Feedback'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
              </div>
              <span className={classes.bottomText} >
                <strong>Have a specific issue?</strong> Contact <a target="_blank" href="https://www.iotflows.com/company/support/" rel="noopener">IoTFlows Support</a> or <a target="_blank" href="https://docs.iotflows.com/" rel="noopener">browse our docs</a>.
              </span>
            </div>
          </div>
        </Popover>


      </div>
      
  )
  
}

}

const mapDispatchToProps = dispatch => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
})

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization,
    selectedIoTFlowsMemberRoleId: state.iotflowsMemberRoleId.selected_iotflows_member_role_id
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FeedbackButton))

import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import "../styles/ButtonStyles.scss";
import { connect} from "react-redux";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditAlertChannelModal from '../components/EditAlertChannelModal';
import DeleteAlertChannelModal from '../components/DeleteAlertChannelModal';
import AddAlertChannelMemberModal from '../components/AddAlertChannelMemberModal';
import ListMemberModal from '../components/ListMemberModal';
import LeaveAlertChannelModal from '../components/LeaveAlertChannelModal';
import ManageNotificationsModal from '../components/ManageNotificationsModal';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

const styles = theme => ({
  iconButtonAvatar: {
    padding: 1,
    outline: 'none',
  }, 
  settingsBtn:{
    color: '#4f87cc',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  }
});

class AlertSettings extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      userAnchorEl: null,
      userOpen: false,
      manageNotificationsModalOpen: false,
      editAlertChannelModalOpen: false,
      memberListModalOpen: false,
      addMemberModalOpen: false,
      leaveAlertChannelModalOpen: false,
      deleteAlertChannelModalOpen: false,
    };
    this.userHandleClick = this.userHandleClick.bind(this);
    this.userHandleClose = this.userHandleClose.bind(this);
    this.handler = this.handler.bind(this);

  }


  userHandleClick = event => {
    this.setState({ 
      manageNotificationsModalOpen: false,
      editAlertChannelModalOpen: false,
      memberListModalOpen: false,
      addMemberModalOpen: false,
      leaveAlertChannelModalOpen: false,
      deleteAlertChannelModalOpen: false,
      userAnchorEl: event.currentTarget,
      userOpen: true
     });

  };

  userHandleClose = () => {
    this.setState({ 
      manageNotificationsModalOpen: false,
      editAlertChannelModalOpen: false,
      memberListModalOpen: false,
      addMemberModalOpen: false,
      leaveAlertChannelModalOpen: false,
      deleteAlertChannelModalOpen: false,
      userAnchorEl: null,
      userOpen: false,
     });
  };

  handleManageNotificationsModalOpen = () => {
    this.setState({ 
      manageNotificationsModalOpen: true,
      editAlertChannelModalOpen: false,
      memberListModalOpen: false,
      addMemberModalOpen: false,
      leaveAlertChannelModalOpen: false,
      deleteAlertChannelModalOpen: false,
      userAnchorEl: null,
      userOpen: false,
     });
  };
  handleEditAlertChannelModalOpen = () => {
    this.setState({ 
      manageNotificationsModalOpen: false,
      editAlertChannelModalOpen: true,
      memberListModalOpen: false,
      addMemberModalOpen: false,
      leaveAlertChannelModalOpen: false,
      deleteAlertChannelModalOpen: false,
      userAnchorEl: null,
      userOpen: false,
     });
  };
  handleMemberListModalOpen = () => {
    this.setState({ 
      manageNotificationsModalOpen: false,
      editAlertChannelModalOpen: false,
      memberListModalOpen: true,
      addMemberModalOpen: false,
      leaveAlertChannelModalOpen: false,
      deleteAlertChannelModalOpen: false,
      userAnchorEl: null,
      userOpen: false,
     });
  };
  handleAddMemberModalOpen = () => {
    this.setState({ 
      manageNotificationsModalOpen: false,
      editAlertChannelModalOpen: false,
      memberListModalOpen: false,
      addMemberModalOpen: true,
      leaveAlertChannelModalOpen: false,
      deleteAlertChannelModalOpen: false,
      userAnchorEl: null,
      userOpen: false,
     });
  };
  handleLeaveAlertChannelModalOpen = () => {
    this.setState({ 
      manageNotificationsModalOpen: false,
      editAlertChannelModalOpen: false,
      memberListModalOpen: false,
      addMemberModalOpen: false,
      leaveAlertChannelModalOpen: true,
      deleteAlertChannelModalOpen: false,
      userAnchorEl: null,
      userOpen: false,
     });
  };
  handleDeleteAlertChannelModalOpen = () => {
    this.setState({ 
      manageNotificationsModalOpen: false,
      editAlertChannelModalOpen: false,
      memberListModalOpen: false,
      addMemberModalOpen: false,
      leaveAlertChannelModalOpen: false,
      deleteAlertChannelModalOpen: true,
      userAnchorEl: null,
      userOpen: false,
     });
  };


  // componentWillReceiveProps({open}) {
  //   this.setState({...this.state,open})
  // }

  handler = () => { 
    this.setState({ 
      manageNotificationsModalOpen: false,
      editAlertChannelModalOpen: false,
      memberListModalOpen: false,
      addMemberModalOpen: false,
      leaveAlertChannelModalOpen: false,
      deleteAlertChannelModalOpen: false,
      userAnchorEl: null,
      userOpen: false,
     });
  };


  render() {
    const { classes, currentUserInfo, selectedAlertChannel} = this.props
    const { userAnchorEl,editProfileModalOpen, userOpen, logoutModalOpen,
            manageNotificationsModalOpen, editAlertChannelModalOpen, memberListModalOpen, addMemberModalOpen,
            leaveAlertChannelModalOpen, deleteAlertChannelModalOpen } = this.state
    // const userOpen = Boolean(userAnchorEl);

    // console.log('selectedAlertChannel in setting page')
    // console.log(selectedAlertChannel)
    

    return(
      <div>
        
      <React.Fragment>
        <IconButton color="inherit"  className={classes.settingsBtn} onClick={this.userHandleClick}>
          <SettingsOutlinedIcon  style={{fontSize:"24px"}} />
        </IconButton>

        <Menu style={{outline: 'none'}}
        id="user-menu"
        anchorEl={userAnchorEl}
        open={userOpen}
        onClose={this.userHandleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'center', 
        // }}
        PaperProps={{
          style: {
            width: 300,
          },
        }}
        >
        <MenuItem onClick={this.handleManageNotificationsModalOpen} >
          Manage Alert Channel Notifications
        </MenuItem>
        <MenuItem  onClick={this.handleEditAlertChannelModalOpen} >
          Edit Alert Channel 
        </MenuItem>
        <MenuItem onClick={this.handleMemberListModalOpen} >
          Member List
        </MenuItem>
        <MenuItem  onClick={this.handleAddMemberModalOpen} >
          Add Member
        </MenuItem>
        <MenuItem  onClick={this.handleLeaveAlertChannelModalOpen} >
          Leave Alert Channel
        </MenuItem>
        <MenuItem  onClick={this.handleDeleteAlertChannelModalOpen} >
          Delete Alert Channel
        </MenuItem>
        </Menu>
      </React.Fragment> 
      <ManageNotificationsModal open={manageNotificationsModalOpen}  handler={this.handler}  />
      <EditAlertChannelModal open={editAlertChannelModalOpen} alert_channel_name = {this.props.selectedAlertChannel.alert_channel_name} alert_channel_description = {this.props.selectedAlertChannel.alert_channel_description} handler={this.handler} />
      <ListMemberModal open={memberListModalOpen} handler={this.handler}  />
      <AddAlertChannelMemberModal open={addMemberModalOpen} handler={this.handler}  />
      <LeaveAlertChannelModal open={leaveAlertChannelModalOpen} handler={this.handler}  />
      <DeleteAlertChannelModal open={deleteAlertChannelModalOpen} handler={this.handler} modalButton={'Delete Alert Channel'} dialogTitle={'Delete Alert Channel '} removingItem={'Alert Channel'} selected_alert_channel={this.props.selected_alert_channel}/>
      
      
      
      
    </div>
  )
}

}

AlertSettings.propTypes = {
/**
 * Injected by the documentation to work in an iframe.
 * You won't need it on your project.
 */
container: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    selectedAlertChannel: state.alertChannels.selectedAlertChannel
  }
}

const mapDispatchToProps = dispatch => ({
  // fetchCurrentUser: () => dispatch(fetchCurrentUser()),
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlertSettings))

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {CardElement} from '@stripe/react-stripe-js';
import '../styles/CardSectionStyles.css';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CardSetupForm from './CardSetupForm';


const stripePromise = loadStripe('pk_live_cv1o2of6G7RjXFImxVlKnzSD00evGTXFFa');

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};



const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(75),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  contentCSS: {
    padding: '8px', 
    marginRight: '30px', 
    marginLeft: '30px',
    [theme.breakpoints.down('xs')]:{
      marginRight: '10px', 
      marginLeft: '10px',
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'5px 14px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    borderRadius: 10, 
  },
  hoverText: {    
    fontSize: 33,
    paddingLeft: 0,
    
  },
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class PaymentModal extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      open: false,
      
    }
    this.handleClose = this.handleClose.bind(this);
   
  }
  
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      open: false ,
    });
  };
  render() {
    const { classes } = this.props;
    const { open} = this.state;

    


    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
        <Button className={classes.button} onClick={this.handleOpen} >
            <AddCircleOutlineOutlinedIcon  /> &nbsp;Add Payment        
        </Button>

        <Dialog
            open={open}
            onClose={this.handleClose}
            TransitionComponent={Transition}
            keepMounted
            classes={{ paper: classes.dialog }}
          >
            <Paper className={classes.paper} >
              <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">{"Add Payment"}</DialogTitle>
              <DialogContent className={classes.contentCSS}  >
              <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <CardSetupForm handleClose={this.handleClose} refreshCreditCardList={this.props.refreshCreditCardList} />
              </Elements>

              </DialogContent>
            </Paper>
        
        </Dialog>
        
      </Grid>
    );
  }
}



export default withStyles(styles)(PaymentModal);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import AvatarEditor from 'react-avatar-editor';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
// import UliProfilePic from '../UliPic.jpeg';
import Avatar from '@material-ui/core/Avatar';
import Row from 'react-bootstrap/Row';
import "../styles/fontStyles.scss";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import  {postDataProfilePic } from '../ApiCalls/DataApis';
import {fetchCurrentUser} from '../store/actions/userActions';


const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(37),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(37),
    }
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize:'14px',
    margin: '0px',
    // alignItems:'center',

  },
  button: {
    backgroundColor:'transparent',
    // borderRadius:'24px',
    border:'0px solid #4f86ce',
    // display:'inline-block',
    // cursor:'pointer',
    // color:'#4f87cc',
    // fontFamily:'Trebuchet MS',
    // fontSize:'6px',
    // font-weight:bold;
    padding:'0px 0px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',

    '&:hover': {
      backgroundColor:'transparent',
      opacity:0.5,
      outline: 'none',

    },
    '&:active': {
      position:'relative',
      // top:'1px',
      outline: 'none',
      
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
  largeAvatar: {
    width: theme.spacing(13),
    height: theme.spacing(13),
  },
  buttonLeftRight: {
    backgroundColor:'transparent',
    // borderRadius:'24px',
    border:'0px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'16px',
    // font-weight:bold;
    padding:'5px 14px',
    textDecoration:'none',
    outline: 'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
      outline: 'none',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ProfilePicEditor extends React.Component {
  state = {
    open: false,
    loading: false,
    success: false,
    image: this.props.profilePic,
    newImg: '',
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 50,
    preview: null,
    width: 200,
    height: 200,
  };

  timer = undefined;

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  submitButtonClick = () => {
    const canvas = this.editor.getImageScaledToCanvas()
    canvas.toBlob((blob) => {
      const new_image_file = new FormData()
      new_image_file.append("file", blob, "filename.jpg")
      const { loading } = this.state;
      if (!loading) {
        this.setState(
          {
            success: false,
            loading: true,
          },
          () => {
            postDataProfilePic('https://api.iotflows.com/v1/users/profile/picture', new_image_file)
            .then(async response => {
              if(response.ok){
                this.props.showSuccessSnackbar("Profile Picture has been successfully modified!");
                this.setState({
                  loading: false,
                  success: true,
                  open:false,
                }, ()=> {
                    this.props.fetchCurrentUser();
                });
              }
              else{
                try{
                  const data = await response.json();
                  this.props.showErrorSnackbar(data.message);
                  this.setState({
                    loading: false,
                    success: false,
                    open:false,
                  });
                }catch (e){
                  this.props.showErrorSnackbar('Something went wrong');
                }
              }
            })
          },
        );
      }
    }, "image/jpeg", 95)
  };



  

  // setEditorRef = (editor) => this.editor = editor

  setEditorRef = (editor) => {
    if (editor) {
      this.editor = editor;

    }
  }

  handleNewImage = e => {
    this.setState({ image: e.target.files[0] })
  }

  handleScale = e => {
    const scale = parseFloat(e.target.value)
    this.setState({ scale })
  }

  handlePositionChange = position => {
    this.setState({ position })
  }


  rotateright = () => {
    let newRotation = this.state.rotate + 90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.setState({
      rotate: newRotation,
    })
  }
  
  rotateleft = () => {
    let newRotation = this.state.rotate - 90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.setState({
      rotate: newRotation,
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      profilePic: nextProps.profilePic,
    }); 
  };

  render() {
    const { classes, currentUserInfo } = this.props;
    const { open, loading, success, image, borderRadius } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });


    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
        <Button className={classes.button} variant="outlined"  onClick={this.handleOpen}> <Avatar  alt={`${currentUserInfo[0].user_first_name}`} src={currentUserInfo[0].user_image_url} className={classes.largeAvatar} /></Button>
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title"> Editor</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '10px', paddingTop: '10px'}}>

                  <AvatarEditor
                    scale={parseFloat(this.state.scale)}
                    width={this.state.width}
                    height={this.state.height}
                    position={this.state.position}
                    onPositionChange={this.handlePositionChange}
                    rotate={parseFloat(this.state.rotate)}
                    borderRadius={this.state.width / (100 / borderRadius)}
                    // image={currentUserInfo[0].user_image_url}
                    image={image}
                    className="editor-canvas"
                    crossOrigin = 'anonymous'

                    ref={(ref) => this.setEditorRef(ref)}

                  />
                  <br />
                  <Row style={{justifyContent: 'center'}}>
                    <Button className={classes.buttonLeftRight} onClick={this.rotateleft} variant="outlined" >
                      <RotateLeftIcon />          
                    </Button>
                    &nbsp;
                    <Button className={classes.buttonLeftRight} onClick={this.rotateright} variant="outlined" >
                      <RotateRightIcon />          
                    </Button>
                  </Row>
                  
                  <br />
                  <Row  >
                    <h6 className={classes.fontStyles}> New File:&nbsp; </h6>
                    <input style={{fontSize:'11px'}} name="newImage" type="file" onChange={this.handleNewImage} />
                  </Row>
                  <br />
                  <Row>
                    <h6 className={classes.fontStyles}> Zoom:&nbsp; </h6>
                      <input 
                        name="scale"
                        type="range"
                        onChange={this.handleScale}
                        min={this.state.allowZoomOut ? '0.1' : '1'}
                        max="2"
                        step="0.01"
                        defaultValue="1"
                      />
                  </Row>
                  <br />
                
                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

ProfilePicEditor.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),

})
const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ProfilePicEditor));
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import {withRouter} from 'react-router-dom';
// import DashboardTestPage from '../MyDashboards/DashboardTestPage'
import CreateDashboardPage from './CreateDashboardPage'
import EditDashboardPage from './EditDashboardPage'
import  { getData, putData } from '../ApiCalls/DataApis';
// import CreateMachineFlowModal from "../components/CreateMachineFlowModal";
import ChangeMachineDashboardModal from "../components/ChangeMachineDashboardModal";
import { styled, alpha } from '@mui/material/styles';
// import Button from '@mui/material/Button';
import Button from '@material-ui/core/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import _ from "lodash";
import { WidthProvider, Responsive } from "react-grid-layout";
import SmartWidget from '@iotflows/iotflows-smart-widget/dist/components/SmartWidget'
import DeleteMachineDashboardModal from '../components/DeleteMachineDashboardModal'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";


const ResponsiveReactGridLayout = WidthProvider(Responsive);


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom:'60px',
  },
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '3px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'5px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    minWidth:'70px',
    maxHeight: '40px',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
});



class SelectedAssetPage extends React.Component{
  static defaultProps = {
    className: "layout",
    cols:{lg: 60, md: 50, sm: 30, xs: 20, xxs: 10},
    rowHeight: 10,
  };
  constructor(props){
    super(props);
    this.state = {
      assetInformation:[],
      anchorEl: null,
      selectedAssetDashboard: [],
      openChangeMachineDashboardModal: false,
      openCreateMachineDashboardPage: false,
      openEditMachineDashboardPage: false,
      openDeleteMachineDashboardModal: false,
      renderGrid: true,
    }
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleCreateMachineDashboardMenu = this.handleCreateMachineDashboardMenu.bind(this)
    this.handleFinishedCreatingDashboard = this.handleFinishedCreatingDashboard.bind(this)
    this.handleEditedDashboard = this.handleEditedDashboard.bind(this)
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.handleCloseCreateMachineDashboardPage = this.handleCloseCreateMachineDashboardPage.bind(this);
    this.handleEditMachineDashboardMenu = this.handleEditMachineDashboardMenu.bind(this);
    

  }
  


  componentDidMount(){

    localStorage.setItem('cache', null)


    // Get list of data streams
    getData('https://api.iotflows.com/v1/assets/' + this.props.match.params.asset_uuid )
    .then(async response => {
      if(response.ok ){
          const data = await response.json();
          if(data.data){
          this.setState({
              assetInformation: data.data[0],
          })

          // make sure asset information is set before calling this function
          // Does asset have a selected dashboard
          getData('https://api.iotflows.com/v1/assets/' + this.props.match.params.asset_uuid + '/selected_machine_dashboard' )
          .then(async response => {
            if(response.ok ){
                const data = await response.json();
                if(data.data){
                    if(!data.data[0]){
                      this.setState({openChangeMachineDashboardModal: true})
                    }
                this.setState({
                  selectedAssetDashboard: data.data[0],
                })
                }
            }
          });

          }
      }
    });

    // Does asset have a selected dashboard
    getData('https://api.iotflows.com/v1/assets/' + this.props.match.params.asset_uuid + '/selected_machine_dashboard' )
    .then(async response => {
      if(response.ok ){
          const data = await response.json();
          if(data.data){
              if(!data.data[0]){
                this.setState({openChangeMachineDashboardModal: true})
              }
          this.setState({
            selectedAssetDashboard: data.data[0],
          })
          }
      }
    });
  }


  createElement(el) {
    
    const i = el.add ? "+" : el.i;
    return (
      <div key={i} data-grid={el} 
        style={{
          backgroundColor:'#fff', 
          borderRadius:'20px', 
          overflow:'scroll',
          boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1), 0px -4px 15px -2px rgba(0,0,0,0.1)',
          padding:'8px'
        }}
      >
        <div style={{marginTop:'auto', marginBottom:'auto', height:'100%'}} >
          <SmartWidget
              username={localStorage.getItem('client_username')}
              password={localStorage.getItem('client_password')}
              widget_uuid={el.widget_uuid}
              asset_uuid={this.props.match.params.asset_uuid}
          />
        </div>
      </div>
    );
  }

  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    });
  }

  onLayoutChange = (layout) => {
    this.setState({ layout: layout });
  }

  handleEditedDashboard = (machine_dashboard_uuid) => {
    this.setState({
      openEditMachineDashboardPage: false,
      renderGrid: false
    })
    // Update machine dashboard
    getData('https://api.iotflows.com/v1/machine_dashboards/' + machine_dashboard_uuid )
    .then(async response => {
      if(response.ok ){
          const data = await response.json();
          if(data.data){
          this.onLayoutChange(data.data[0].machine_dashboard_settings)
          this.setState({
            selectedAssetDashboard: data.data[0],
          }, ()=> this.setState({renderGrid: true}))
          }
      }
    });
  
  }
  
  handleFinishedCreatingDashboard = (newMachineDashboardInfo) => {
    this.setState({
      openCreateMachineDashboardPage: false,
      openChangeMachineDashboardModal: false,
      selectedAssetDashboard: newMachineDashboardInfo
    })
    putData('https://api.iotflows.com/v1/assets/' + this.props.match.params.asset_uuid + '/selected_machine_dashboard', 
    { "machine_dashboard_uuid": newMachineDashboardInfo.machine_dashboard_uuid
    })
      .then(async response => {
        if(response.ok){
          const data = await response.json();
          this.props.showSuccessSnackbar("Machine Dashboard changed!");
          this.setState({
            loading: false,
            success: true,
            open:false,
          });
        }
        else{
          try{
            const data = await response.json();
            this.props.showErrorSnackbar(data.message);
            this.setState({
              loading: false,
              success: false,
              open:false});
          }catch (e){
            this.props.showErrorSnackbar('Something went wrong');
          }
        }
      })
  
  }

  refreshDashboardList = () => {

    // Does asset have a selected dashboard after one is deleted
    getData('https://api.iotflows.com/v1/assets/' + this.props.match.params.asset_uuid + '/selected_machine_dashboard' )
    .then(async response => {
      if(response.ok ){
          const data = await response.json();
          if(data.data){
              if(!data.data[0]){
                this.setState({openChangeMachineDashboardModal: true})
              }
          this.setState({
            selectedAssetDashboard: data.data[0],
          })
          }
      }
    });

  }


  handleCloseModal = () => {
    this.setState({
      openChangeMachineDashboardModal: false,
      openCreateMachineDashboardPage: false,
      openEditMachineDashboardPage: false,
      openDeleteMachineDashboardModal: false,
    })
  }

  handleMachineDashboardMenu = (e) => {
    this.setState({anchorEl: e.currentTarget})
  }

  handleCloseMachineDashboardMenu = () => {
    this.setState({anchorEl: null})
  }

  handleDeleteMachineDashboard = () => {
    this.setState({
      openDeleteMachineDashboardModal: true,
      anchorEl: null
    })
  }

  handleChangeMachineDashboardMenu = () => {
    this.setState({
      openChangeMachineDashboardModal: true,
      anchorEl: null
    })
  }

  handleCreateMachineDashboardMenu = () => {
    this.setState({
      openChangeMachineDashboardModal: false
    }, () => {
      this.setState({
        openCreateMachineDashboardPage: true,
        anchorEl: null
      })
    })
  }

  handleEditMachineDashboardMenu = () => {
    this.setState({
      openEditMachineDashboardPage: true,
      anchorEl: null
    })
  }

  handleCloseCreateMachineDashboardPage = () => {
    this.setState({
      openCreateMachineDashboardPage: false,
      openEditMachineDashboardPage: false,
    })
  }


  render(){
    const { classes, selectedOrganization} = this.props
    const {assetInformation,
            anchorEl,
            openChangeMachineDashboardModal,
            openCreateMachineDashboardPage,
            openEditMachineDashboardPage,
            selectedAssetDashboard,
            openDeleteMachineDashboardModal,
            renderGrid} = this.state

    const openMachineDashboardMenu = Boolean(anchorEl);

    return (
      <div className={classes.root}>
        {assetInformation && Object.keys(assetInformation).length > 0}
        <div className={classes.breadcrumbCSS}>
          <Breadcrumbs  separator="›" aria-label="breadcrumb">
            <Typography className={classes.fontStyles} style={{color:'#757575'}}>{selectedOrganization.organization_name}</Typography>
            <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/assets" >
              Assets
            </Link>
            <Typography className={classes.fontStyles} color="textPrimary">
              {assetInformation.asset_custom_name? assetInformation.asset_custom_name : assetInformation.machine_name}
              </Typography>
          </Breadcrumbs>
        </div>
        <div style={{padding:'12px', display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
            <h2 style={{fontWeight:700, fontSize:'24px', fontFamily: 'Trebuchet MS',marginRight:'auto' }}>
              {openCreateMachineDashboardPage && 'Create Dashboard Template'}
              {openEditMachineDashboardPage && selectedAssetDashboard && Object.keys(selectedAssetDashboard).length > 0 && 
                `Edit ${selectedAssetDashboard.machine_dashboard_name} Dashboard Template`
              }
              {!openCreateMachineDashboardPage && !openEditMachineDashboardPage && selectedAssetDashboard && Object.keys(selectedAssetDashboard).length > 0 && 
                `${selectedAssetDashboard.machine_dashboard_name} Asset Workspace`
              }
              {!openCreateMachineDashboardPage && !openEditMachineDashboardPage && !selectedAssetDashboard && 
                'Asset Workspace'
              }
            </h2>
            {/* <div style={{margin:'0px 8px'}} >
                <CreateMachineFlowModal assetInformation={assetInformation} />
            </div> */}
            <div style={{margin:'0px 8px'}} >
              {/* Machine Dashboard Dropdown */}
              <Button
                id="demo-customized-button"
                aria-controls={openMachineDashboardMenu ? 'machine-dashboard-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMachineDashboardMenu ? 'true' : undefined}
                variant="contained" 
                className={classes.button}
                onClick={this.handleMachineDashboardMenu}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Machine Dashboard
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={openMachineDashboardMenu}
                onClose={this.handleCloseMachineDashboardMenu}
              >
                <MenuItem onClick={this.handleCreateMachineDashboardMenu} >
                  <AddCircleOutlineOutlinedIcon />
                  Create
                </MenuItem>
                <MenuItem onClick={this.handleChangeMachineDashboardMenu} >
                <ChangeCircleOutlinedIcon />
                  Change
                </MenuItem>
                <MenuItem onClick={this.handleEditMachineDashboardMenu} disabled={!selectedAssetDashboard || Object.keys(selectedAssetDashboard).length == 0} disableRipple >
                <EditIcon />
                  Edit
                </MenuItem>
                <MenuItem onClick={this.handleDeleteMachineDashboard} disabled={!selectedAssetDashboard || Object.keys(selectedAssetDashboard).length == 0} disableRipple>
                  <DeleteOutlineOutlinedIcon />
                  Delete
                </MenuItem>
              </StyledMenu>
            </div>
            </div>
            
            

            {renderGrid && !openCreateMachineDashboardPage && !openEditMachineDashboardPage && selectedAssetDashboard && Object.keys(selectedAssetDashboard).length > 0 &&
              <ResponsiveReactGridLayout
                  onLayoutChange={this.onLayoutChange}
                  onBreakpointChange={this.onBreakpointChange}
                  useCSSTransforms={true}
                  // allowOverlap={true}
                  isDraggable={false}
                  isResizable={false}
                  {...this.props}
              >
                {_.map(selectedAssetDashboard.machine_dashboard_settings, el => this.createElement(el))}
              </ResponsiveReactGridLayout>
            }

        {/* {Object.keys(assetInformation).length > 0 &&
          <DashboardTestPage asset_uuid={this.props.match.params.asset_uuid} machine_uuid={assetInformation.machine_uuid} />
        } */}
        {openCreateMachineDashboardPage && 
          <CreateDashboardPage 
            asset_uuid={this.props.match.params.asset_uuid} 
            machine_uuid={assetInformation.machine_uuid}
            handleFinishedCreatingDashboard={this.handleFinishedCreatingDashboard}
            handleCloseCreateMachineDashboardPage={this.handleCloseCreateMachineDashboardPage}
            />
        }
        {openEditMachineDashboardPage && 
          <EditDashboardPage 
            asset_uuid={this.props.match.params.asset_uuid} 
            machine_uuid={assetInformation.machine_uuid}
            handleEditedDashboard={this.handleEditedDashboard}
            handleCloseCreateMachineDashboardPage={this.handleCloseCreateMachineDashboardPage}
            selectedAssetDashboard={selectedAssetDashboard}
            />
        }
        
        {assetInformation && Object.keys(assetInformation).length > 0 && openChangeMachineDashboardModal && 
          <ChangeMachineDashboardModal 
            open={openChangeMachineDashboardModal} 
            handleCloseModal={this.handleCloseModal} 
            asset_uuid={this.props.match.params.asset_uuid}
            assetInformation={assetInformation}
            handleCreateMachineDashboardMenu={this.handleCreateMachineDashboardMenu}
            handleFinishedCreatingDashboard={this.handleFinishedCreatingDashboard}
            />
        }
        {openDeleteMachineDashboardModal && 
          <DeleteMachineDashboardModal 
            open={openDeleteMachineDashboardModal} 
            handleCloseModal={this.handleCloseModal} 
            selectedAssetDashboard={selectedAssetDashboard}
            refreshDashboardList={this.refreshDashboardList}
            />
        }
        
      </div>


    )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{ withTheme: true })(withRouter(SelectedAssetPage)));


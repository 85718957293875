import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import  {getData } from '../ApiCalls/DataApis';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Amplify, { Auth, Hub } from 'aws-amplify';


Amplify.configure({
  Auth: {            
      identityPoolId: 'us-east-1:5684b65a-8bf4-4697-b9a4-87eadbf03ef5',
      region: 'us-east-1',
      identityPoolRegion: 'us-east-1',      
      userPoolId: 'us-east-1_3EP1MRzAy',  
      userPoolWebClientId: '18230ih88kfb3ouj67mrid5er3',
      oauth: {
          domain: 'auth.iotflows.com',
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          // redirectSignIn: 'https://console.iotflows.com/', 
          // redirectSignOut: 'https://console.iotflows.com/', 
          redirectSignIn: 'http://localhost:3000/', 
          redirectSignOut: 'http://localhost:3000/', 
          responseType: 'token', // DO NOT USE 'code'!!! it goes through a forever loop //or 'token', note that REFRESH token will only be generated when the responseType is code
          // responseType: 'code', 
          options: {
              // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
              AdvancedSecurityDataCollectionFlag : true
          },
      }
  }
});


const styles = theme => ({
  button: {
    backgroundColor:'transparent',
    borderRadius:'10px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    minWidth:'70px',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'10px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    "$selected &": {
      backgroundColor: "rgb(232, 232, 232)",
    },
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  selectedButton: {
    backgroundColor: "rgb(232, 232, 232)",
    borderRadius:'10px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    minWidth:'70px',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'10px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonProgress: {
    color: 'rgb(33, 117, 243)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});


class MenuComponentNodeRed extends React.Component {
  state = {
    loading: false,
    success: false,
    userAnchorEl: null,
    userOpen: false,
  };

  nodeRedHandleClick = event => {
    this.setState({ 
      userAnchorEl: event.currentTarget ,
      userOpen: true,
     });

  };
  nodeRedHandleClose = () => {
    this.setState({ 
      userAnchorEl: null,
      userOpen: false 
    });
  };
  handleConnectNodeRed = (instance) => {
    console.log('instance')
    console.log(instance)
    // accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
    
    Auth.currentSession()
    .then(data => {
        window.open(instance.cloud_server_url +'/?access_token=' + data.getAccessToken().getJwtToken()  , "_blank");
        this.setState({ 
          userAnchorEl: null,
          userOpen: false 
        });
      })
    .catch(err => {
      console.log('err', err)
      this.props.showErrorSnackbar(err)
      });

    
  }

  handleRestartNodeRed = (instance) => {
    // console.log('restart instnace')
    // console.log(instance)
    this.setState({ 
      userAnchorEl: null,
      userOpen: false 
    });
    getData('https://api.iotflows.com/v1/cloud-servers/'+ instance.cloud_server_uuid +'/nodered_restart')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        this.props.showSuccessSnackbar('Node-RED has been restarted');
      }else{
        try{
          const data = await response.json();
          this.props.showErrorSnackbar(data.message)
        }catch (e){
          this.props.showErrorSnackbar('Something went wrong')
        }
      }
    });

  };


  

  render() {
    const { classes } = this.props;
    const { userAnchorEl,
            userOpen} = this.state;

    return (
      <React.Fragment>
        <Button 
          className={this.props.selected? classes.selectedButton: classes.button} 
          disabled={!this.props.instance.cloud_server_is_ready}
          variant="contained"
          onClick={this.nodeRedHandleClick}
        >
          {'Node-RED'}  {!this.props.instance.cloud_server_is_ready && <CircularProgress size={24} className={classes.buttonProgress} /> }      
        </Button>

              <Menu 
              style={{outline: 'none'}}
              anchorEl={userAnchorEl}
              open={userOpen}
              onClose={this.nodeRedHandleClose}
              // onExit= {this.nodeRedHandleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              // transformOrigin={{
              //   vertical: 'top',
              //   horizontal: 'center',
              // }}
              PaperProps={{
                style: {
                  width: 150,
                },
              }}
              >
              <MenuItem 
                onClick={() => this.handleConnectNodeRed(this.props.instance) }
                style={{padding: '4px 4px 4px 4px'}} 
                >
                <div style={{display: 'flex'}}  >
                  <div style={{overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingLeft: '6px',lineHeight: '28px'}} >
                    <span style={{fontWeight:500, fontSize: '14px', letterSpacing: '0.2px' }}>Node-RED Connect </span>
                  </div>
                </div>
              </MenuItem>
              <MenuItem onClick={() => this.handleRestartNodeRed(this.props.instance) } style={{padding: '4px 4px 4px 4px'}}>
                <div style={{display: 'flex'}}>
                  <div style={{overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingLeft: '6px',lineHeight: '28px'}}>
                    <span style={{fontWeight:500, fontSize: '14px', letterSpacing: '0.2px' }}>Restart Node-RED</span>
                  </div>
                </div>
              </MenuItem>
              </Menu>
            </React.Fragment> 
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(MenuComponentNodeRed));

import * as React from 'react';
import Grid from '@mui/material/Grid';

const styles = {
  input: {
    width:'100%',
    backgroundColor:'transparent',
    display: 'block',
    border:'1px solid #d1d5db',
    fontSize:'14px',
    color:'#4b5563',
    padding:'4px 8px',
    borderRadius:'8px',
    "&:hover": {
      background: "#efefef"
    },
    "&:focus": {
      outline: "none",
      backgroundColor:'transparent',
      boxShadow: ' 0 0 5px ##93c5fd',
    },
    "::placeholder": {
      color:'#dc2626',
    }
  },
  svg: {
    width:'20px', 
    height:'20px', 
    color:'#4b5563',
    "&:hover": {
      background: "#1f2937"
    },
  }
};

function EnvironmentVariablesInputs({variables, index, RemoveEnvironmentVariablesFields, handleChangeEnvironmentVariablesValues}) {
    

  return (
    <div style={{backgroundColor: `${index % 2 === 0 && '#f1f5f9'}`,padding:'8px', borderRadius:'10px' }} >
        
        <Grid container spacing={1} style={{alignItems:'center'}}>
          <Grid item xs={12} md={6} style={{alignItems:'center'}}>
              <input 
                  // className={`block bg-transparent w-full border rounded-md py-1 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                  style={styles.input}
                  id={'environmentVarKey'} 
                  type={'text'}
                  name={'environmentVarKey'}
                  placeholder={'key'}
                  value={variables.environmentVarKey || ''}
                  onChange={(e) => handleChangeEnvironmentVariablesValues(index, e)}
              />
          </Grid>
          <Grid item xs={12} md={6} style={{alignItems:'center'}}>
            <div style={{display:'flex', alignItems:'center'}} >
                <input 
                    // className={`block bg-transparent w-full border rounded-md py-1 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                    style={styles.input}
                    id={'environmentVarValue'} 
                    type={'text'}
                    name={'environmentVarValue'}
                    placeholder={'value'}
                    value={variables.environmentVarValue || ''}
                    onChange={(e) => handleChangeEnvironmentVariablesValues(index, e)}
                />
                <div style={{margin:'0px 0px 4px 4px', cursor: 'pointer'}} onClick={() => RemoveEnvironmentVariablesFields(index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={styles.svg} >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>

                </div>
            </div>
          </Grid>
        </Grid>
            
            
        
    </div>
  )
}

export default EnvironmentVariablesInputs
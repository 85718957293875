import React from 'react';
import {
  ElementsConsumer, 
  CardElement, 
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import Button from '@material-ui/core/Button';
import CardSection from './CardSection';
import  {postData, getData } from '../ApiCalls/DataApis';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import {fetchOrganizations, fetchSelectedOrganization} from '../store/actions/organizationActions';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import {logEvent, Result, ErrorResult} from '../MyBillingPage/util';
import '../styles/CardSectionStyles.css';
import TextField from '@material-ui/core/TextField';
import PhoneInput from 'react-phone-input-2';
// import {CountryCodeJson} from '../MyNetworksPage/CountryCodeJson';
// import {StateCodeJson} from './StateCodeJson';
import Form from 'react-bootstrap/Form';

const btnStyle = {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
};

// const ELEMENT_OPTIONS = {
//   style: {
//     base: {
//       fontSize: '18px',
//       color: '#424770',
//       letterSpacing: '0.025em',
//       '::placeholder': {
//         color: '#aab7c4',
//       },
//     },
//     invalid: {
//       color: '#9e2146',
//     },
//   },
// };


class CardSetupFormAddingOrganization extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      loading: false,
      success: false,
      // name: '',
      // email: '',
      // city: '',
      // country: 'US',
      // line1: '',
      // line2: '',
      // address_state: '',
      // phone: '',
      organizationName: '',
      organizationDescription: '',
      errorMessage: null,
      paymentMethod: null,
    }
  }

  // handleCountryCodeChange = e => {
  //   this.setState({country: e.target.value })
  // }

  // handleStateCodeChange = e => {
  //   this.setState({address_state: e.target.value })
  // }
  
  handleSubmit = async (event) => {
    
    
    // // We don't want to let default form submission happen here,
    // // which would refresh the page.
    event.preventDefault();
    const { loading } = this.state;
    const {stripe, elements} = this.props
    var cardElement = elements.getElement('card');
    
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },() => {
          getData('https://api.iotflows.com/v1/payment_methods/setup_intent')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              const result = await stripe.confirmCardSetup(data.client_secret, {
                payment_method: {
                  card: elements.getElement(CardElement),
                }
              });
              if (result.error) {
                
                this.props.showErrorSnackbar(result.error.message);
                cardElement.clear();
                this.setState({
                  loading: false,
                  success: true,
                  open:false,
                  organizationDescription: '',
                  organizationName: '',
                }, () => { 
                  // this.props.handleClose();
                  this.cancelCreation();
                });
                // Display result.error.message in your UI.
              } else {
                this.props.showSuccessSnackbar('Card verified, sending to database');
                // The setup has succeeded. Display a success message and send
                // result.setupIntent.payment_method to your server to save the
                // card to a Customer
                
                postData('https://api.iotflows.com/v1/organizations', 
                {'organization_name': this.state.organizationName,
                  'organization_description': this.state.organizationDescription,
                  'organization_is_private': true,
                  'payment_method': result.setupIntent.payment_method}) 
                  .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                      // Clear the Element:
                      cardElement.clear();
                      this.props.showSuccessSnackbar("New Organization has been successfully added!");
                      this.setState({
                        loading: false,
                        success: true,
                        open:false,
                        organizationDescription: '',
                        organizationName: '',
                      }, () => { 
                        this.props.handleClose()
                        this.props.fetchOrganizations();
                        
                      });
                      
                    }
                    else{
                      try{
                        const data = await response.json();
                        this.props.showErrorSnackbar(data.message);
                        // Clear the Element:
                        cardElement.clear();
                        this.setState({
                          loading: false,
                          success: false,
                          open:false,
                          organizationDescription: '',
                          organizationName: '',
                        }, ()=> {
                          this.props.handleClose();
                          this.props.handleCloseModal()});
                      }catch (e){
                        // Clear the Element:
                        cardElement.clear();
                        this.props.showErrorSnackbar(e);
                        this.props.handleClose();
                      }
                    }
                  })
              }
              
            }
          });
        });
      }
  };
  
  cancelCreation = () => {
    this.props.handleClose();
    this.props.handleCloseModal();
  }

  render() {
    
    const {stripe} = this.props;
    const { loading, success, organizationDescription, organizationName,name, paymentMethod, city, errorMessage, country, line1, line2, address_state} = this.state;

  const buttonClassname = classNames({
    [btnStyle]: success,
  });

    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
        autoFocus
        size="small"
        margin="normal"
        id="organizationName"
        label= "Organization Name"
        type="text"
        variant="outlined"
        value={organizationName} 
        onChange={(event) => {
          this.setState({organizationName: event.target.value});
        }}
        fullWidth
        />

        <TextField
        autoFocus
        size="small"
        margin="normal"
        id="organizationDescription"
        label="Organization Description"
        type="text"
        variant="outlined"
        value={organizationDescription} 
        onChange={this.handleOrganizationDescriptionChange}
        fullWidth
        onChange={(event) => {
          this.setState({organizationDescription: event.target.value});
        }}
        />
        {/* <TextField 
          autoFocus
          size="small"
          margin="normal"
          id="email"
          label="Email"
          variant="outlined"
          // placeholder="Email"
          value={email}
          fullWidth
          onChange={(event) => {
            this.setState({email: event.target.value});
          }}
        />

        <TextField
          size="small"
          variant="outlined"
          // type="text"
          fullWidth
          required
          id="name"
          label="Name on card"
          value={name}
          onChange={(event) => {
            this.setState({name: event.target.value});
          }}
        />

         
        <Form.Group style={{margin:'0px', paddingTop: '15px'}} controlId="deviceForm.SelectNetwork">
          <Form.Label > Billing Address</Form.Label>
          <Form.Control as="select" onChange={this.handleCountryCodeChange} >
            {CountryCodeJson.map( (country,index) =>(
                    // <option key={network.network_id} value={network.network_id}>{country_code}</option>  
                    <option key={index} value={country.country_code}>{country.country_name}</option>  
                
            ))}
          </Form.Control>
        </Form.Group>

        <TextField 
          size="small"
          variant="outlined"
          margin="normal"
          id="line1"
          label="Address Line 1"
          value={line1}
          type="text"
          fullWidth
          onChange={(event) => {
            this.setState({line1: event.target.value});
          }}
        />

        <TextField 
          size="small"
          variant="outlined"
          margin="normal"
          id="line2"
          label="Address Line 2"
          value={line2}
          type="text"
          fullWidth
          onChange={(event) => {
            this.setState({line2: event.target.value});
          }}
        />

        <TextField 
          size="small"
          variant="outlined"
          margin="normal"
          id="city"
          label="City"
          value={city}
          type="text"
          fullWidth
          onChange={(event) => {
            this.setState({city: event.target.value});
          }}
        /> */}

        {/* <Form.Group style={{margin:'0px', paddingTop: '10px', paddingBottom: '10px'}} controlId="deviceForm.SelectState">
          <Form.Label style={{paddingTop:'10px'}}> State</Form.Label>
          <Form.Control as="select" onChange={this.handleStateCodeChange} >
            {StateCodeJson.map( (state,index) =>(
                    // <option key={network.network_id} value={network.network_id}>{country_code}</option>  
                    <option key={index} value={state.abbreviation}>{state.name}</option>  
                
            ))}
          </Form.Control>
        </Form.Group>

        <PhoneInput
          country={'us'}
          value={this.state.phone}
          onChange={phone => this.setState({ phone })}
        />
        <br/> */}

        <label >Payment Details <i style={{fontSize: '9px'}}>You will not be charged until you subscribe to a device or cloud server.</i></label>
        <CardSection />
        {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}

        <i style={{fontSize: '9px', padding: '5px'}}>
          By clicking "Create", I authorise IOTFLOWS INC to send instructions to the financial institution that issued 
          my card to take payments from my card account in accordance with the terms of my agreement with you.
        </i>
        <br/>
        <div style={{textAlign:'end'}}>
          <Button onClick={this.cancelCreation} disabled={this.props.disableCancel}>
            Cancel
          </Button>
          <Button  color="primary" type='submit' style={{marginTop: '5px', marginBottom: '5px'}} className={buttonClassname} disabled={loading} >
            Create { loading && <CircularProgress size={24} style={{color: green[500], position: 'absolute', top: '50%', left: '50%', marginTop: -12,marginLeft: -12}} />}
          </Button>
        </div>
        
        {/* <button disabled={!this.props.stripe}>Save Card</button> */}
      </form>
      
    );
  }
}


class InjectedCardSetupForm extends React.Component {

  render(){
    return (
      <ElementsConsumer>
        {({stripe, elements}) => (
          <CardSetupFormAddingOrganization  
            stripe={stripe} 
            elements={elements} 
            handleClose={this.props.handleClose} 
            handleCloseModal={this.props.handleCloseModal} 
            selectedOrganization={this.props.selectedOrganization} 
            showSuccessSnackbar={this.props.showSuccessSnackbar} 
            showErrorSnackbar={this.props.showErrorSnackbar}
            fetchOrganizations={this.props.fetchOrganizations}
            disableCancel={this.props.disableCancel}
          />
        )}
      </ElementsConsumer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),
  fetchSelectedOrganization: currentOrganization => dispatch(fetchSelectedOrganization(currentOrganization)),
  fetchOrganizations: () => dispatch(fetchOrganizations()),

})

const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(InjectedCardSetupForm);




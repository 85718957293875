import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import Grid from '@material-ui/core/Grid';
import {getData} from '../ApiCalls/DataApis';
import MqttService from '../core/services/MqttService';
import HeaderDevice from './HeaderDevice';
import LogsDeviceTable from './LogsDeviceTable';
import '../styles/emailAddressesList.css'
import LinearProgress from '@material-ui/core/LinearProgress';
import 'rsuite/dist/styles/rsuite-default.css';
import { DateRangePicker } from 'rsuite';



const {
  allowedMaxDays,
  allowedDays,
  allowedRange,
  beforeToday,
  afterToday,
  combine
} = DateRangePicker;


const styles = theme => ({
  setUpBtn: {
    borderRadius: '5px',
    color: 'rgb(42, 42, 47)',
    border: '1px solid rgba(42, 42, 47,0.7)',
    backgroundColor: 'transparent',
    marginRight: '10px',
    '&:hover': {
      color: 'rgba(42, 42, 47,0.8)',
      border: '1px solid rgba(42, 42, 47,1)',
      outline: 'none',
      transform: 'translateY(-1px)',
    },
  },
  tourSection: {
    textAlign: 'left',
    color:'rgb(102, 60, 0)', 
    border:'1px solid #df7800', 
    fontSize: '0.875rem', 
    fontFamily:'Trebuchet MS', 
    padding: '8px', 
    borderRadius: '5px', 
    margin: '0 0 16px 0',
  },
  hover: {},
  selected: {},
  inputContainer:{
    display: 'inline-flex',
    flexGrow: 1,
    width: '100%',
    border: '1px solid #E5E7EB',
    padding: '0px',
    margin: '4px',
    textAlign: 'left',
    backgroundColor: "#fff",
    borderRadius:'6px',
    height: '2.25rem',
    position: 'relative',
  },
  searchIconContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
    padding: '0.125rem 0.5rem 0.125rem 0.25rem'
  },
  searchIcon: {
    color: '#6B7280',
    height: '1rem'
  },
  inputCSS: {
    backgroundColor: 'transparent',
    border:'0px solid transparent',
    textAlign: 'left',
    outline: 'none',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    padding: '0.5rem 0.25rem',
    minWidth: '100%',
    color: '#4B5563'
  },
  searchResults: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 300,
    backgroundColor: '#fff',
    padding: '0.5rem',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    inset: '40px auto auto 0px',
    borderRadius: '5px',
    maxHeight: '500px',
    overflow: 'auto',
  },
  resultContainer: {
    width: '100%',
    display: 'flex',
    padding: '8px 8px 8px 12px',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#F3F4F6',
    }
  },
  searchText: {
    fontSize: '14px',
    color: '#3B82F6',
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
  },
  searchTopic: {
    fontSize: '12px',
    color: '#6B7280',
    fontStyle: 'italic',
    fontWeight: 500,
    fontFamily:'Trebuchet MS',
  },
  colorPrimary: {
    backgroundColor: '#4f86ce',
  },
  barColorPrimary: {
    backgroundColor: '#bfdaff',
  },
  buttonProgress: {
    height: '2px'
  },
  
});
const data_streams = [];




class LogsDevice extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      rows: [],
      searchedRows: [],
      organization_data_streams_dic: [],
      organization_clients_dic: [],
      inputText:'',
      data_streams_list:[],
      topics_list:[],
      dataStreamMatchList: [],
      topicMatchList: [],
      showInputDropdown: false,
      isSearched: false,
      filterItems: [],
      loading: false,
      startDate: "",
      endDate: "",
      isDataStreamSearch: false,
      isTopicSearch: false,
      isSubTopicSearch: false,
      isPayloadSearch: false,
      isTopicPayloadSearch: false,
      currentSearchedValue: '',
      searchMoreData: true,
      fetchingData: true,
    }
  }

  componentDidMount() {

    window.addEventListener('scroll', this.listenToScroll)


    getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/data_streams')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            data_streams_list: data.data,
          })
        }
      }
    });

    getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/historical_data/topics')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          this.setState({
            topics_list: data.data,
          })
        }
      }
    });

    getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/historical_data')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data){
          let modifyDate = {};
          let dataSize = {};
          for(let i=0; i<Object.keys(data.data).length; i++){
            // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
            modifyDate[i] = new Date(data.data[i].received_at);
            modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
            data.data[i].received_at = modifyDate[i];

            // get data size
            dataSize[i] = Object.keys(data.data[i].data).length;
            dataSize[i] = this.formatBytes(dataSize[i]);
            data.data[i].data_stream_size = dataSize[i];
          }

          // console.log("Finished pulling historical Data")
          this.setState({
            rows: data.data,
            fetchingData: false
          })
        }
      } else{
        try{
          const data = await response.json();
          this.props.showErrorSnackbar(data.message);
          this.setState({
            fetchingData: false
          })
          
        }catch (e){
          this.props.showErrorSnackbar('Something went wrong');
          // console.log("Failed pulling historical Data")
          this.setState({
            fetchingData: false
          })
        }
      }
    });

    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/data_streams')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          var dic = {};
          if(data.data){
            for(var i=0; i<Object.keys(data.data).length; i++){
              dic[data.data[i].data_stream_uuid]= data.data[i]
            }
            this.setState({
              organization_data_streams_dic: dic,
            })
          }
        }
      });

    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/devices/clients')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          var dic = {};
          if(data.data){
            for(var i=0; i<Object.keys(data.data).length; i++){
              dic[data.data[i].client_id]= data.data[i]
            }
            this.setState({
              organization_clients_dic: dic,
            })
          }
        }
      });


    if(MqttService.isConnected) {
      MqttService.subscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/' + this.props.selected_device.project_uuid + '/devices/' + this.props.device_uuid + '/data-streams/#', this.onDataStreamDevice)
    }
    for (let i = 0; i < Object.keys(data_streams).length; i++) {
      // data_streams[i].severity_level_icon = alerts_icon[data_streams[i].severity_level]
        this.state.rows.push(data_streams[i]);
      }

  }

  onDataStreamDevice = message => {
    try{
      const data_stream_message = JSON.parse(message.payloadString);
      var data_stream_uuid = data_stream_message.data_stream_id;
      var client_id = data_stream_message.client_id;
      var data = String(data_stream_message.data);
      var topic = message.topic;

      // var topicSliced = message.topic.split('/');
      // var dataStreamUuid = topicSliced[topicSliced.indexOf('data-streams')+1];
        
        let data_stream_name = this.state.organization_data_streams_dic[data_stream_uuid].data_stream_name;
        let data_stream_unit = this.state.organization_data_streams_dic[data_stream_uuid].data_stream_unit;
        let client_name = this.state.organization_clients_dic[client_id].client_name;
        // let device_name = this.state.organization_clients_dic[client_id].device_name;
        // let project_name = this.state.organization_clients_dic[client_id].project_name;
    
        // var rows = this.state.rows;
        data_stream_message.client_name = client_name;
        data_stream_message.data_stream_name = data_stream_name;
        data_stream_message.data = data;
        data_stream_message.data_stream_unit = data_stream_unit;
        data_stream_message.topic = topic;
        // data_stream_message.device_name = device_name;
        // data_stream_message.project_name = project_name;
        data_stream_message.received_at = (new Date()).toLocaleTimeString() + " " + (new Date()).toLocaleDateString();

        // get data stream size
        let dataSize = 0; 
        dataSize = Object.keys(data_stream_message.data).length;
        dataSize = this.formatBytes(dataSize);
        data_stream_message.data_stream_size = dataSize;
    
        // rows.unshift(data_stream_message);
        // this.setState({rows:rows.slice(0,100)})
        // this.setState({rows:rows})
        this.setState({rows: [data_stream_message, ...this.state.rows.slice(0,20)]})
    }
    catch(e)
    {
      console.log("Error in reading data streaming from MQTT");
      console.log(e.message);
    }
    
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
    if(MqttService.isConnected) {
      MqttService.unsubscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/' + this.props.project_uuid + '/devices/' + this.props.device_uuid +'/data-streams/#')
    }
  }

  

  listenToScroll = () => {
    if(this.state.searchMoreData && (this.state.currentSearchedValue || this.state.isDataStreamSearch ||  this.state.isTopicPayloadSearch ||  this.state.isTopicSearch ||  this.state.isSubTopicSearch ||  this.state.isPayloadSearch ||  this.state.startDate)){
      const { currentSearchedValue, isDataStreamSearch, isTopicPayloadSearch, isTopicSearch, isSubTopicSearch, isPayloadSearch, startDate } = this.state;
      const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
  

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = winScroll / height

    
    
    if(scrolled > 0.5 ){
      
      this.setState({
        searchMoreData: false
      }, () =>{
          // console.log("Adding data to the table")
          
          let lastRow = "";
          let lastTime = "";
          
            if(currentSearchedValue || isDataStreamSearch ||  isTopicPayloadSearch ||  isTopicSearch ||  isSubTopicSearch ||  isPayloadSearch ||  startDate){
              if(Object.keys(this.state.searchedRows).length > 0){
                lastRow = this.state.searchedRows[Object.keys(this.state.searchedRows).length -1];
                lastTime = lastRow.received_at
                lastTime = new Date(lastTime);
                lastTime = lastTime.getTime();
                lastTime = parseInt(lastTime);
              }
            }else {
              if(Object.keys(this.state.rows).length > 0){
                lastRow = this.state.rows[Object.keys(this.state.rows).length -1];
                lastTime = lastRow.received_at
                lastTime = new Date(lastTime);
                lastTime = lastTime.getTime();
                lastTime = parseInt(lastTime);
              }
            }
          

          var endLink = 'search_date_to=' + lastTime;
          if(isDataStreamSearch && currentSearchedValue){
            if(startDate){
              endLink = 'search_ds_uuid=' + currentSearchedValue + '&search_date_from=' + startDate + '&search_date_to=' + lastTime;
            } else {
              endLink = 'search_ds_uuid=' + currentSearchedValue + '&search_date_to=' + lastTime;
            }
          }
          if(isTopicPayloadSearch && currentSearchedValue){
            if(startDate){
              endLink = 'search_containing=' + currentSearchedValue + '&search_date_from=' + startDate + '&search_date_to=' + lastTime;
            } else {
              endLink = 'search_containing=' + currentSearchedValue + '&search_date_to=' + lastTime;
            }
          }
          if(isTopicSearch && currentSearchedValue){
            if(startDate){
              endLink = 'search_topic_containing=' + currentSearchedValue + '&search_date_from=' + startDate + '&search_date_to=' + lastTime;
            } else {
              endLink = 'search_topic_containing=' + currentSearchedValue + '&search_date_to=' + lastTime;
            }
          }
          if(isSubTopicSearch && currentSearchedValue){
            if(startDate){
              endLink = 'search_topic_containing=' + currentSearchedValue.subtopic + '&search_ds_uuid=' + currentSearchedValue.data_stream_uuid + '&search_date_from=' + startDate + '&search_date_to=' + lastTime;
            } else {
              endLink = 'search_topic_containing=' + currentSearchedValue.subtopic + '&search_ds_uuid=' + currentSearchedValue.data_stream_uuid + '&search_date_to=' + lastTime;
            }
          }
          if(isPayloadSearch && currentSearchedValue){
            if(startDate){
              endLink = 'search_payload_containing=' + currentSearchedValue + '&search_date_from=' + startDate + '&search_date_to=' + lastTime;
            } else {
              endLink = 'search_payload_containing=' + currentSearchedValue + '&search_date_to=' + lastTime;
            }
            
          }
          
          getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/historical_data?' + endLink)
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              if(data){
                let modifyDate = {};
                let dataSize = {};
                for(let i=0; i<Object.keys(data.data).length; i++){
                  // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                  modifyDate[i] = new Date(data.data[i].received_at);
                  modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                  data.data[i].received_at = modifyDate[i];

                  // get data size
                  dataSize[i] = Object.keys(data.data[i].data).length;
                  dataSize[i] = this.formatBytes(dataSize[i]);
                  data.data[i].data_stream_size = dataSize[i];
                }

                if(currentSearchedValue || isDataStreamSearch ||  isTopicPayloadSearch ||  isTopicSearch ||  isSubTopicSearch ||  isPayloadSearch ||  startDate){
                  // var searchedRows = this.state.searchedRows;
                  // searchedRows.push(...data.data);
                  this.setState({
                    searchedRows: [...this.state.searchedRows, ...data.data],
                    searchMoreData: true
                  })
                }else {
                  // var rows = this.state.rows;
                  // rows.push(...data.data);
                  this.setState({
                    rows: [...this.state.rows, ...data.data],
                    searchMoreData: true
                  })
                }
              }
            }else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  searchMoreData: true
                })
                
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
                this.setState({
                  searchMoreData: true
                })
              }
            }
          });
        
      })
      
    }
    }
  }


  formatBytes = (bytes, decimals = 1) => {
    try{
      if (bytes == 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k));
  
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    catch(e){
      return "-"
    }
    
  }

  cutTopic = (topic) => {
    try{
      if(topic){
        var topicSliced = topic.split('/');
        // var subTopicStart = topicSliced[topicSliced.indexOf('data-streams')+2];
        var subTopicStartIndex = topicSliced.indexOf('data-streams')+2;
        if(topicSliced[subTopicStartIndex]){
          var subTopic = topicSliced.slice(subTopicStartIndex).join('/') 
          subTopic = this.cutName(subTopic)
          return subTopic
        }else {
          return ""
        }
      }
    }
    catch(e){
      return ""
    }
  }

  cutData = (cellData) => {
    try {
      var strData = String(cellData);
      if(Object.keys(strData).length > 30 && window.innerWidth <= 600){
        strData = strData.slice(0,30) + '...';
        cellData = strData;
      }
      if(Object.keys(strData).length > 30 && window.innerWidth > 600 && window.innerWidth <= 768){
        strData = strData.slice(0,30) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 30 && window.innerWidth > 768 && window.innerWidth <= 900) {
        strData = strData.slice(0,30) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 40 && window.innerWidth > 900 && window.innerWidth <= 1024) {
        strData = strData.slice(0,40) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 55 && window.innerWidth > 1024 && window.innerWidth <= 1280) {
        strData = strData.slice(0,55) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 70 && window.innerWidth > 1280 && window.innerWidth <= 1440) {
        strData = strData.slice(0,70) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 80 && window.innerWidth > 1440) {
        strData = strData.slice(0,80) + '...';
        cellData = strData;
      }
  
      return cellData
    }
    catch {
      return ""
    }

  }

  cutName = (cellData) => {
    try {
      var strData = String(cellData);
      if(Object.keys(strData).length > 13 && window.innerWidth <= 600){
        strData = strData.slice(0,13) + '...';
        cellData = strData;
      }
      if(Object.keys(strData).length > 13 && window.innerWidth > 600 && window.innerWidth <= 768){
        strData = strData.slice(0,13) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 25 && window.innerWidth > 768 && window.innerWidth <= 900) {
        strData = strData.slice(0,25) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 30 && window.innerWidth > 900 && window.innerWidth <= 1024) {
        strData = strData.slice(0,30) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 30 && window.innerWidth > 1024 && window.innerWidth <= 1280) {
        strData = strData.slice(0,30) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 45 && window.innerWidth > 1280 && window.innerWidth <= 1440) {
        strData = strData.slice(0,45) + '...';
        cellData = strData;
      }
      if (Object.keys(strData).length > 70 && window.innerWidth > 1440) {
        strData = strData.slice(0,70) + '...';
        cellData = strData;
      }
  
      return cellData
    }
    catch {
      return ""
    }

  }

 

  handleChangeInputText = (e) => {
    var currentInputText = e.target.value;
    this.setState({
      inputText: currentInputText,
      showInputDropdown: true
    })

    let matchListDS = [];
      let matchListTopic = [];
      if(currentInputText){

        // Input text
        let lowerCaseInput = currentInputText.toLowerCase();

        // Looking for matches in Data stream names        
        this.state.data_streams_list.map((dataStream,index) => {
          let lowerCaseName = dataStream.data_stream_name.toLowerCase();          
          if(lowerCaseName.includes(lowerCaseInput)){
            matchListDS.push(dataStream)            
          }
        })
        this.setState({dataStreamMatchList: matchListDS})

        // Looking for matches in Topic names
        this.state.topics_list.map((topic,index) => {
          topic.subtopics.map((subTopic,index) => {
            let lowerCaseName = subTopic.toLowerCase();            
            if(lowerCaseName.includes(lowerCaseInput)){              
              matchListTopic.push({
                data_stream_name: topic.data_stream_name,
                data_stream_uuid: topic.data_stream_uuid,
                subtopic: subTopic
              })              
            }
          })
        })
        this.setState({topicMatchList: matchListTopic})

      }else {
        this.setState({
          dataStreamMatchList: [],
          topicMatchList: [],
        })
      }
  }

  handleDataStreamSearch = (dataStream) => {
    const { loading, filterItems, startDate, endDate } = this.state;
    if(!loading){
      var endLink = "";
      if(startDate && endDate){
        endLink = 'search_ds_uuid=' + dataStream.data_stream_uuid + '&search_date_from=' + startDate + '&search_date_to=' + endDate;
      } else {
        endLink = 'search_ds_uuid=' + dataStream.data_stream_uuid;
      }
      this.setState({
        filterItems: this.state.filterItems.filter(i => i !== filterItems[0]),
        currentSearchedValue: dataStream.data_stream_uuid,
        loading: true,
        isDataStreamSearch: false,
        isTopicPayloadSearch:false,
        isTopicSearch: false,
        isSubTopicSearch: false,
        isPayloadSearch: false,
      }, () => {
            this.setState({
              filterItems: [...this.state.filterItems, dataStream.data_stream_name + " Data Stream"],
              inputText: '',
              showInputDropdown: false,
              isSearched: true,
              isDataStreamSearch: true,
            })
            
            getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/historical_data?' + endLink)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data){
                  let modifyDate = {};
                  let dataSize = {};
                  for(let i=0; i<Object.keys(data.data).length; i++){
                    // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                    modifyDate[i] = new Date(data.data[i].received_at);
                    modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                    data.data[i].received_at = modifyDate[i];

                    // get data size
                    dataSize[i] = Object.keys(data.data[i].data).length;
                    dataSize[i] = this.formatBytes(dataSize[i]);
                    data.data[i].data_stream_size = dataSize[i];
                  }

                  this.setState({
                    searchedRows: data.data,
                    loading: false
                  })
                }
              } else{
                try{
                  const data = await response.json();
                  this.props.showErrorSnackbar(data.message);
                  this.setState({loading: false})
                  
                }catch (e){
                  this.props.showErrorSnackbar('Something went wrong');
                  this.setState({loading: false})
                }
              }
            });
      })
    }
  }

  handleTopicSearch = (inputValue) => {
    const { loading, filterItems, inputText, startDate, endDate } = this.state;
    if(!loading){
      var endLink = "";
      if(startDate && endDate){
        endLink = 'search_topic_containing=' + inputValue + '&search_date_from=' + startDate + '&search_date_to=' + endDate;
      } else {
        endLink = 'search_topic_containing=' + inputValue;
      }
      this.setState({
        filterItems: this.state.filterItems.filter(i => i !== filterItems[0]),
        loading: true,
        currentSearchedValue: inputValue,
        isDataStreamSearch: false,
        isTopicPayloadSearch:false,
        isTopicSearch: false,
        isSubTopicSearch: false,
        isPayloadSearch: false,
      }, () => {
            this.setState({
              filterItems: [...this.state.filterItems, inputValue + " in Topic"],
              inputText: '',
              showInputDropdown: false,
              isSearched: true,
              isTopicSearch: true,
            })
            getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/historical_data?' + endLink)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data){
                  let modifyDate = {};
                  let dataSize = {};
                  for(let i=0; i<Object.keys(data.data).length; i++){
                    // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                    modifyDate[i] = new Date(data.data[i].received_at);
                    modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                    data.data[i].received_at = modifyDate[i];

                    // get data size
                    dataSize[i] = Object.keys(data.data[i].data).length;
                    dataSize[i] = this.formatBytes(dataSize[i]);
                    data.data[i].data_stream_size = dataSize[i];
                  }


                  this.setState({
                    searchedRows: data.data,
                    loading: false
                  })
                }
              } else{
                try{
                  const data = await response.json();
                  this.props.showErrorSnackbar(data.message);
                  this.setState({loading: false})
                  
                }catch (e){
                  this.props.showErrorSnackbar('Something went wrong');
                  this.setState({loading: false})
                }
              }
            });
      })
    }
  }
  handleSubTopicSearch = (inputValue) => {
    const { loading, filterItems, inputText, startDate, endDate } = this.state;
    if(!loading){
      var endLink = "";
      if(startDate && endDate){
        endLink = 'search_topic_containing=' + inputValue.subtopic + '&search_ds_uuid=' + inputValue.data_stream_uuid + '&search_date_from=' + startDate + '&search_date_to=' + endDate;
      } else {
        endLink = 'search_topic_containing=' + inputValue.subtopic + '&search_ds_uuid=' + inputValue.data_stream_uuid;
      }
      this.setState({
        filterItems: this.state.filterItems.filter(i => i !== filterItems[0]),
        loading: true,
        currentSearchedValue: inputValue,
        isDataStreamSearch: false,
        isTopicPayloadSearch:false,
        isTopicSearch: false,
        isSubTopicSearch: false,
        isPayloadSearch: false,
      }, () => {
            this.setState({
              filterItems: [...this.state.filterItems, inputValue.subtopic + " Subtopic of Data Stream " + inputValue.data_stream_name],
              inputText: '',
              showInputDropdown: false,
              isSearched: true,
              isSubTopicSearch: true,
            })
            getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/historical_data?' + endLink)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data){
                  let modifyDate = {};
                  let dataSize = {};
                  for(let i=0; i<Object.keys(data.data).length; i++){
                    // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                    modifyDate[i] = new Date(data.data[i].received_at);
                    modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                    data.data[i].received_at = modifyDate[i];

                    // get data size
                    dataSize[i] = Object.keys(data.data[i].data).length;
                    dataSize[i] = this.formatBytes(dataSize[i]);
                    data.data[i].data_stream_size = dataSize[i];
                  }


                  this.setState({
                    searchedRows: data.data,
                    loading: false
                  })
                }
              } else{
                try{
                  const data = await response.json();
                  this.props.showErrorSnackbar(data.message);
                  this.setState({loading: false})
                  
                }catch (e){
                  this.props.showErrorSnackbar('Something went wrong');
                  this.setState({loading: false})
                }
              }
            });
      })
    }
  }

  handlePayloadSearch = (inputValue) => {
    const { loading, filterItems, inputText, startDate, endDate } = this.state;
    if(!loading){
      var endLink = "";
      if(startDate && endDate){
        endLink = 'search_payload_containing=' + inputValue + '&search_date_from=' + startDate + '&search_date_to=' + endDate;
      } else {
        endLink = 'search_payload_containing=' + inputValue;
      }
      this.setState({
        filterItems: this.state.filterItems.filter(i => i !== filterItems[0]),
        currentSearchedValue: inputValue,
        loading: true,
        isDataStreamSearch: false,
        isTopicPayloadSearch:false,
        isTopicSearch: false,
        isSubTopicSearch: false,
        isPayloadSearch: false,
      }, () => {
            this.setState({
              filterItems: [...this.state.filterItems, inputValue + " in Payload"],
              inputText: '',
              showInputDropdown: false,
              isSearched: true,
              isPayloadSearch: true,
            })
            getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/historical_data?' + endLink)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data){
                  let modifyDate = {};
                  let dataSize = {};
                  for(let i=0; i<Object.keys(data.data).length; i++){
                    // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                    modifyDate[i] = new Date(data.data[i].received_at);
                    modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                    data.data[i].received_at = modifyDate[i];

                    // get data size
                    dataSize[i] = Object.keys(data.data[i].data).length;
                    dataSize[i] = this.formatBytes(dataSize[i]);
                    data.data[i].data_stream_size = dataSize[i];
                  }


                  this.setState({
                    searchedRows: data.data,
                    loading: false
                  })
                }
              } else{
                try{
                  const data = await response.json();
                  this.props.showErrorSnackbar(data.message);
                  this.setState({loading: false})
                  
                }catch (e){
                  this.props.showErrorSnackbar('Something went wrong');
                  this.setState({loading: false})
                }
              }
            });
      })
    }
  }

  handleTopicPayloadSearch = (inputValue) => {
    const { loading, filterItems, inputText, startDate, endDate } = this.state;
    if(!loading){
      var endLink = "";
      if(startDate && endDate){
        endLink = 'search_containing=' + inputValue + '&search_date_from=' + startDate + '&search_date_to=' + endDate;
      } else {
        endLink = 'search_containing=' + inputValue;
      }
      this.setState({
        filterItems: this.state.filterItems.filter(i => i !== filterItems[0]),
        currentSearchedValue: inputValue,
        loading: true,
        isDataStreamSearch: false,
        isTopicPayloadSearch:false,
        isTopicSearch: false,
        isSubTopicSearch: false,
        isPayloadSearch: false,
      }, () => {
            this.setState({
              filterItems: [...this.state.filterItems, inputValue + " in Topic/Payload"],
              inputText: '',
              showInputDropdown: false,
              isSearched: true,
              isTopicPayloadSearch: true,
            })
            getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/historical_data?' + endLink)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data){
                  let modifyDate = {};
                  let dataSize = {};
                  for(let i=0; i<Object.keys(data.data).length; i++){
                    // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                    modifyDate[i] = new Date(data.data[i].received_at);
                    modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                    data.data[i].received_at = modifyDate[i];

                    // get data size
                    dataSize[i] = Object.keys(data.data[i].data).length;
                    dataSize[i] = this.formatBytes(dataSize[i]);
                    data.data[i].data_stream_size = dataSize[i];
                  }


                  this.setState({
                    searchedRows: data.data,
                    loading: false
                  })
                }
              } else{
                try{
                  const data = await response.json();
                  this.props.showErrorSnackbar(data.message);
                  this.setState({loading: false})
                  
                }catch (e){
                  this.props.showErrorSnackbar('Something went wrong');
                  this.setState({loading: false})
                }
              }
            });
      })
    }
  }


  handleSelectedDate = (value) => {
    var start = new Date(value[0]);
    start = start.getTime();
    start = parseInt(start);
    var end = new Date(value[1]);
    end = end.getTime();
    end = parseInt(end);
    //  convert it to the end of the day
    end = end + 86399000; 
    
    const { loading, currentSearchedValue, isDataStreamSearch, isTopicPayloadSearch, isTopicSearch, isSubTopicSearch, isPayloadSearch } = this.state;
    if(!loading){
      this.setState({
          loading: true,
          startDate: start,
          endDate: end,
          isSearched: true,
        }, () => {
          if(start && end){
            var endLink = 'search_date_from=' + start + '&search_date_to=' + end;
            if(isDataStreamSearch && currentSearchedValue){
              endLink = 'search_ds_uuid=' + currentSearchedValue + '&search_date_from=' + start + '&search_date_to=' + end;
            }
            if(isTopicPayloadSearch && currentSearchedValue){
              endLink = 'search_containing=' + currentSearchedValue + '&search_date_from=' + start + '&search_date_to=' + end;
            }
            if(isTopicSearch && currentSearchedValue){
              endLink = 'search_topic_containing=' + currentSearchedValue + '&search_date_from=' + start + '&search_date_to=' + end;
            }
            if(isSubTopicSearch && currentSearchedValue){
              endLink = 'search_topic_containing=' + currentSearchedValue.subtopic + '&search_ds_uuid=' + currentSearchedValue.data_stream_uuid + '&search_date_from=' + start + '&search_date_to=' + end;
            }
            if(isPayloadSearch && currentSearchedValue){
              endLink = 'search_payload_containing=' + currentSearchedValue + '&search_date_from=' + start + '&search_date_to=' + end;
            }
            getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/historical_data?' + endLink)
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                  if(data){
                    let modifyDate = {};
                    let dataSize = {};
                    for(let i=0; i<Object.keys(data.data).length; i++){
                      // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                      modifyDate[i] = new Date(data.data[i].received_at);
                      modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                      data.data[i].received_at = modifyDate[i];

                      // get data size
                      dataSize[i] = Object.keys(data.data[i].data).length;
                      dataSize[i] = this.formatBytes(dataSize[i]);
                      data.data[i].data_stream_size = dataSize[i];
                    }


                    this.setState({
                      searchedRows: data.data,
                      loading: false
                    })
                  }
                } else{
                  try{
                    const data = await response.json();
                    this.props.showErrorSnackbar(data.message);
                    this.setState({loading: false})
                    
                  }catch (e){
                    this.props.showErrorSnackbar('Something went wrong');
                    this.setState({loading: false})
                  }
                }
              });

          }
      })
    }
  }

  handleDelete = item => {
    this.setState({
      filterItems: this.state.filterItems.filter(i => i !== item),
      currentSearchedValue: '',
      isDataStreamSearch: false,
      isTopicPayloadSearch:false,
      isTopicSearch: false,
      isSubTopicSearch: false,
      isPayloadSearch: false,
    });
    if(this.state.startDate && this.state.endDate){
      var endLink = 'search_date_from=' + this.state.startDate + '&search_date_to=' + this.state.endDate;
      getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/historical_data?' + endLink)
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            if(data){
              let modifyDate = {};
              let dataSize = {};
              for(let i=0; i<Object.keys(data.data).length; i++){
                // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                modifyDate[i] = new Date(data.data[i].received_at);
                modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                data.data[i].received_at = modifyDate[i];

                // get data size
                dataSize[i] = Object.keys(data.data[i].data).length;
                dataSize[i] = this.formatBytes(dataSize[i]);
                data.data[i].data_stream_size = dataSize[i];
              }


              this.setState({
                searchedRows: data.data,
                loading: false
              })
            }
          } else{
            try{
              const data = await response.json();
              this.props.showErrorSnackbar(data.message);
              this.setState({loading: false})
              
            }catch (e){
              this.props.showErrorSnackbar('Something went wrong');
              this.setState({loading: false})
            }
          }
        });
      
    }else {
      this.setState({
        isSearched: false,
      });
    }
  };

  handleClearDate = (e) => {
    const { currentSearchedValue, isDataStreamSearch, isTopicPayloadSearch, isTopicSearch,isSubTopicSearch, isPayloadSearch } = this.state;
    this.setState({
      startDate: '',
      endDate: '',
    })
    if(currentSearchedValue){
      var endLink = '';
      if(isDataStreamSearch && currentSearchedValue){
        endLink = 'search_ds_uuid=' + currentSearchedValue;
      }
      if(isTopicPayloadSearch && currentSearchedValue){
        endLink = 'search_containing=' + currentSearchedValue;
      }
      if(isTopicSearch && currentSearchedValue){
        endLink = 'search_topic_containing=' + currentSearchedValue;
      }
      if(isSubTopicSearch && currentSearchedValue){
        endLink = 'search_topic_containing=' + currentSearchedValue.subtopic + '&search_ds_uuid=' + currentSearchedValue.data_stream_uuid;
      }
      if(isPayloadSearch && currentSearchedValue){
        endLink = 'search_payload_containing=' + currentSearchedValue;
      }
      getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/historical_data?' + endLink)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data){
                  let modifyDate = {};
                  let dataSize = {};
                  for(let i=0; i<Object.keys(data.data).length; i++){
                    // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                    modifyDate[i] = new Date(data.data[i].received_at);
                    modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                    data.data[i].received_at = modifyDate[i];

                    // get data size
                    dataSize[i] = Object.keys(data.data[i].data).length;
                    dataSize[i] = this.formatBytes(dataSize[i]);
                    data.data[i].data_stream_size = dataSize[i];
                  }


                  this.setState({
                    searchedRows: data.data,
                    loading: false
                  })
                }
              } else{
                try{
                  const data = await response.json();
                  this.props.showErrorSnackbar(data.message);
                  this.setState({loading: false})
                  
                }catch (e){
                  this.props.showErrorSnackbar('Something went wrong');
                  this.setState({loading: false})
                }
              }
            });
    }else {
      this.setState({
        isSearched: false,
      })
    }
  }



  render(){
    const { classes} = this.props
    const { rows,
            inputText,
            dataStreamMatchList,
            showInputDropdown,
            isSearched,
            searchedRows,
            filterItems,
            loading,
            topicMatchList,
            searchMoreData,
            fetchingData} = this.state


    return (
      <div className={classes.root} > 
          {!fetchingData && Object.keys(rows).length == 0 &&
              <Grid container spacing={0} className={classes.tourSection} >
                <Grid container item xs={9} sm={6} >
                  <Grid item xs={2} sm={1} style={{minWidth: '35px'}}>
                    <svg style={{width: '30px',padding: '0 0px 0 0'}} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                      <path d="M4.87 18.23h12.26a1.87 1.87 0 0 0 1.62-2.8L12.62 4.71a1.86 1.86 0 0 0-3.24 0L3.25 15.43a1.87 1.87 0 0 0 1.62 2.8zM11 12.64V7.73m0 7.48v-.16" fill="none" stroke="#df7800" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path>
                    </svg>
                  </Grid>
                  <Grid item xs={10} sm={11} style={{textAlign: 'left'}}>
                  <span style={{fontWeight: '900'}}>We don't have any data! </span>
                    <br/>
                    <span style={{paddingLeft: '0px'}}>You haven't sent us any data yet. Don't worry: it's easy to get set up.</span>
                  </Grid>
                </Grid>
                <Grid item xs={3} sm={6} style={{textAlign: 'right', marginTop: 'auto', marginBottom: 'auto'}}>
                  <button onClick={this.props.handleClickStartDemo} className={classes.setUpBtn}>Set up Data</button>
                </Grid>
              </Grid>
            }

            {this.props.selected_device ? 
              <HeaderDevice 
                handleClickStartDemo={this.props.handleClickStartDemo}
                selected_device={this.props.selected_device} 
                selectedDevice={this.props.selectedDevice} 
                isDeviceOnline={this.props.isDeviceOnline} 
                deviceGeolocation={this.props.deviceGeolocation} 
                device_uuid={this.props.device_uuid}
                lastHeard={this.props.lastHeard} 
                deviceInfo= {this.props.deviceInfo}
              />
              : null}

              {/* Input section */}
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <div className={classes.inputContainer} >
                    <div className={classes.searchIconContainer} >
                        <svg xmlns="http://www.w3.org/2000/svg" className={classes.searchIcon}   fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </div>
                    <input className={classes.inputCSS} value={inputText} onChange={this.handleChangeInputText}  placeholder="Search data..." />
                    
                    {inputText && showInputDropdown &&
                      <div className={classes.searchResults}  >
                        <div className={classes.resultContainer} onClick={() => this.handleTopicSearch(inputText)} >
                          <div>
                            <span className={classes.searchText}>{inputText}</span> &nbsp;<span className={classes.searchTopic}>in Topic</span>
                          </div>
                        </div>
                        <div className={classes.resultContainer} onClick={() => this.handlePayloadSearch(inputText)} >
                          <div>
                            <span className={classes.searchText}>{inputText}</span> &nbsp;<span className={classes.searchTopic}>in Payload</span>
                          </div>
                        </div>
                        <div className={classes.resultContainer} onClick={() => this.handleTopicPayloadSearch(inputText)} >
                          <div>
                            <span className={classes.searchText}>{inputText}</span> &nbsp;<span className={classes.searchTopic}>in Topic/Payload</span>
                          </div>
                        </div>
                        {dataStreamMatchList.map(dataStreamMatch => (
                          <div key={dataStreamMatch.data_stream_uuid} onClick={() => this.handleDataStreamSearch(dataStreamMatch)} className={classes.resultContainer}>
                            <div>
                              <span className={classes.searchText}>{dataStreamMatch.data_stream_name}</span> &nbsp;<span className={classes.searchTopic}>Data Stream</span>
                            </div>
                          </div>
                        ))}
                        {topicMatchList.map((topicMatch, index) => (
                          <div key={topicMatch.data_stream_uuid+topicMatch.subtopic} onClick={() => this.handleSubTopicSearch(topicMatch)} className={classes.resultContainer}>
                            <div>
                              <span className={classes.searchText}>{topicMatch.subtopic}</span> &nbsp;<span className={classes.searchTopic}> Subtopic of Data Stream {topicMatch.data_stream_name}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{margin:'4px'}}>
                    <DateRangePicker
                        style={{ width: '100%' }}
                        placeholder="Filter by Date"
                        showOneCalendar
                        preventOverflow
                        ranges={[]}
                        disabledDate={afterToday()}
                        // onExit={(value) => console.log('value onExit',value)}
                        // onSelect={(value) => console.log('value Select',value)}
                        // onChange={(value) => console.log('value change',value)}
                        onOk={this.handleSelectedDate}
                        onClean={this.handleClearDate}
                      />
                  </div>
                </Grid>
                {loading &&
                <Grid item xs={12}>
                  <LinearProgress className={classes.buttonProgress} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary,}} />
                </Grid>
                }
                {Object.keys(filterItems).length > 0 &&
                <Grid item xs={12} style={{textAlign: 'left', }}>
                  {filterItems.map(item => (
                      <div className="tag-item" key={item}>
                        {item}
                        <button
                          type="button"
                          className="button"
                          onClick={() => this.handleDelete(item)}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                </Grid>
                }
              </Grid>
              
                <LogsDeviceTable rows={rows} searchedRows={searchedRows} isSearched={isSearched} searchMoreData={searchMoreData} fetchingData={fetchingData} />
      </div>


    )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{ withTheme: true })(LogsDevice));

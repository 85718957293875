import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, rgbToHex } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import IconButton from '@material-ui/core/IconButton';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PasswordInfoModal from '../components/PasswordInfoModal';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import TextField from '@material-ui/core/TextField';
import EnvironmentVariablesInputs from './EnvironmentVariablesInputs';
import {apiLink} from '../ApiCalls/ApisVariables'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(70),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    minWidth: '180px',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { 
    borderRadius: 10 ,
  },
  listCard: {
    width:"100%",
    borderRadius: "5px",
    marginBottom: "12px",
    border:'1px solid rgb(87, 147, 222)',
    cursor:'pointer',
    // backgroundColor:'transparent',
    backgroundColor:'rgba(87, 147, 222, 0.7)',
    padding:'3px 10px',
    '&:hover': {
      // backgroundColor:'transparent',
      backgroundColor:'rgba(87, 147, 222, 0.9)',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'rgba(87, 147, 222, 1)',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor:'rgba(87, 147, 222, 1)',
    },
  },
  listCardSelected: {
    width:"100%",
    borderRadius: "5px",
    marginBottom: "12px",
    border:'1px solid rgb(87, 147, 222)',
    cursor:'pointer',
    position:'relative',
    backgroundColor:'rgba(87, 147, 222, 1)',
    padding:'3px 10px',
    outline:'none',
  },
  listCardText: {
    textAlign: 'left',
    fontSize: "14px",
    color: "rgb(255,255,255)",
  },
  cardCSS: {
    borderRadius: '10px',
    border:'1px solid #f5f5f5',
    padding: '8px',
    backgroundColor: '#fafafa',
    boxShadow: '2px 4px #f5f5f5',
    cursor:'pointer',
    '&:hover': {
      // backgroundColor:'#eeeeee',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid #bdbdbd',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'#eeeeee',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor:'#eeeeee',
      border:'1px solid #bdbdbd',
      boxShadow: '2px 4px #bdbdbd',
    },
  },
  cardCSSSelected: {
    borderRadius: '10px',
    padding: '8px',
    outline: 'none',
    backgroundColor:'#eeeeee',
    border:'1px solid #bdbdbd',
    boxShadow: '2px 4px #bdbdbd',
    position:'relative',
    cursor:'pointer',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  headerCSS: {
    textAlign: 'center',
    color: '#1976d2',
    fontFamily:'Trebuchet MS',
  },
  bodyCSS: {
    fontSize: '14px',
    color: '#525f7f',
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
  },
  pricingCSS: {
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
    marginTop: '16px',
    marginBottom: '16px',
  },
  customButton: {
    width: '100%',
    marginBottom: '10px', 
    marginTop: '10px',
    borderRadius: '5px',
    border:'1px solid #f5f5f5',
    backgroundColor:'rgba(87, 147, 222, 0.1)',
    '&:hover': {
      backgroundColor:'rgba(87, 147, 222, 0.3)',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid rgba(87, 147, 222, 0.5)',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'rgba(87, 147, 222, 0.4)',
      border:'1px solid rgba(87, 147, 222, 0.7)',
    },
    '&:focus': {
      outline: 'none',
      // backgroundColor:'#eeeeee',
      // border:'1px solid #bdbdbd',
      // boxShadow: '2px 4px #bdbdbd',
    },
  }

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class AddContainerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      imageWithTag: '',
      privatePort: '',
      publicPort: '',
      killBeforeRunState:'killTrue',
      environmentVariablesValues: [
        {
          environmentVarKey:'',
          environmentVarValue:'',
        }
      ]
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  


  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
      imageWithTag: '',
      privatePort: '',
      publicPort: '',
      killBeforeRunState:'killTrue'
    });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
      imageWithTag: '',
      privatePort: '',
      publicPort: '',
      killBeforeRunState:'killTrue'
    });
  };

  // handleRemoteConnectionFromIpChange = (event) => {
  //   this.setState({remoteConnectionFromIp: event.target.value.replace(/[^0-9.]/g, "")});
  // }

  handleImageWithTagChange = (event) => {
    this.setState({imageWithTag: event.target.value});
  }
  handlePrivatePortChange = (event) => {
    this.setState({privatePort: event.target.value});
  }
  handlePublicPortChange = (event) => {
    this.setState({publicPort: event.target.value});
  }

  handleKillBeforeRunChange = (event) => {
    this.setState({killBeforeRunState: event.target.value});
  }

 
 

  submitButtonClick = () => {
    
    const { loading, environmentVariablesValues } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {

        let portBindings = [{'private_port':this.state.privatePort,"public_port":this.state.publicPort}]
        let tempVar ={}
        // if()
        for(let i=0; i <= Object.keys(environmentVariablesValues).length - 1; i++ ){
          if(environmentVariablesValues[i].environmentVarKey && environmentVariablesValues[i].environmentVarValue){
            let currentkey = environmentVariablesValues[i].environmentVarKey
            let currentval = environmentVariablesValues[i].environmentVarValue
            tempVar[currentkey] = currentval
          }
        }

        let killRun = true
        if(this.state.killBeforeRunState === 'killFalse'){
          killRun = false
        }
        
        postData(apiLink + '/v1/devices/' + this.props.device_uuid +'/containers', 
        {'container_image': this.state.imageWithTag,
          'container_kill_before_run': killRun,
          'container_port_bindings': portBindings,
          'container_environment_variables': tempVar})
          .then(async response => {
            if(response.ok){
              const data = await response.json();
              this.props.showSuccessSnackbar("Container has been successfully added!");
              this.props.refreshContainerList();
              this.setState({
                loading: false,
                success: true,
                open:false,
              });
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false});
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  // ********** Functions for Environment variables Inputs **********
  handleChangeEnvironmentVariablesValues = (i, e, id) => {
    
    let newEnvironmentVariablesValues = [...this.state.environmentVariablesValues];
    
    newEnvironmentVariablesValues[i][e.target.name] = e.target.value

    this.setState({environmentVariablesValues: newEnvironmentVariablesValues})
    

  }
    
  addPartInputFormFields = () => {
    const {environmentVariablesValues} = this.state
    
    let tempVar = [...environmentVariablesValues, {environmentVarKey:'', environmentVarValue:''}]
    this.setState({environmentVariablesValues: tempVar})
      
  }

  RemoveEnvironmentVariablesFields = (i) => {
      // console.log('i',i)
      let newEnvironmentVariablesValues = [...this.state.environmentVariablesValues];
      newEnvironmentVariablesValues.splice(i, 1);
      this.setState({environmentVariablesValues: newEnvironmentVariablesValues})
  }

  // ********** Functions for Environment variables Inputs **********

  


  render() {
    const { classes } = this.props;
    const { open, 
            loading, 
            success,
            privatePort,
            publicPort,
            imageWithTag,
            killBeforeRunState,
            environmentVariablesValues} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    

    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
      >
        
        <Button className={classes.button} variant="contained"   onClick={this.handleOpen}><AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Add Container </Button>

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Create Container</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px'}}>

                  <TextField
                    style={{marginTop: '0px'}}
                    margin="normal"
                    id="imageWithTag"
                    label="Image with tag"
                    type="text"
                    value={imageWithTag} 
                    onChange={this.handleImageWithTagChange}
                    fullWidth
                    required
                    size="small"
                  />
                  
                  <div style={{fontSize:'14px',fontWeight:700, fontFamily:'Trebuchet MS',marginTop:'16px'}} >
                  Kill before run
                  </div>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={killBeforeRunState}
                      onChange={this.handleKillBeforeRunChange}
                    >
                      <FormControlLabel value='killTrue' control={<Radio color="primary" />} label="True" />
                      <FormControlLabel value='killFalse' control={<Radio color="primary" />} label="False" />
                    </RadioGroup>
                  </FormControl>

                  <div style={{fontSize:'14px',fontWeight:700, fontFamily:'Trebuchet MS',marginTop:'16px'}} >
                    Port Bindings
                  </div>
                  <TextField
                    style={{marginTop: '0px'}}
                    margin="normal"
                    id="privatePort"
                    label="Private Port"
                    type="text"
                    value={privatePort} 
                    onChange={this.handlePrivatePortChange}
                    fullWidth
                    required
                    size="small"
                  />
                  <TextField
                    style={{marginTop: '0px'}}
                    margin="normal"
                    id="publicPort"
                    label="Public Port"
                    type="text"
                    value={publicPort} 
                    onChange={this.handlePublicPortChange}
                    fullWidth
                    required
                    size="small"
                  />

                  <div style={{fontSize:'14px',fontWeight:700, fontFamily:'Trebuchet MS',marginTop:'16px'}} >
                   Environment Variables
                  </div>
                  <div style={{marginTop:'8px'}}>
                    {environmentVariablesValues.map((variables, index) => (
                      <div key={index}>
                        <EnvironmentVariablesInputs variables={variables} index={index} handleChangeEnvironmentVariablesValues={this.handleChangeEnvironmentVariablesValues} RemoveEnvironmentVariablesFields={this.RemoveEnvironmentVariablesFields} />
                      </div>
                    ))}
                  </div>
                  <div className='w-full flex pt-1'>
                    <div style={{fontSize:'12px',fontWeight:700, fontFamily:'Trebuchet MS',marginTop:'8px', cursor:'pointer', color:'#2563eb', width:' fit-content'}} onClick={this.addPartInputFormFields}>
                      Add another environment variable
                    </div>
                  </div>
                


                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading || (imageWithTag == '') } onClick={this.submitButtonClick}>
                    {'Create'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

AddContainerModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AddContainerModal));

import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import EditProfileModal from '../components/EditProfileModal';
import "../styles/ButtonStyles.scss";
import LogoutModal from '../components/LogoutModal';
import { connect} from "react-redux";
import {fetchCurrentUser} from '../store/actions/userActions'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {BrowserRouter as Router,Redirect, Link} from "react-router-dom";

const styles = theme => ({
  iconButtonAvatar: {
    padding: 1,
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  }, 
});

class ProfileButton extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      userAnchorEl: null,
      userOpen: false,
      editProfileModalOpen: false,
      logoutModalOpen: false,
      redirect: false,
    };
    this.userHandleClick = this.userHandleClick.bind(this);
    this.userHandleClose = this.userHandleClose.bind(this);
    this.handler = this.handler.bind(this);
    this.handleEditProfileModalOpen = this.handleEditProfileModalOpen.bind(this);
    this.handleUsageRouting = this.handleUsageRouting.bind(this);

  }

  // componentDidMount(){
    
  //   // if(this.props.currentUserInfo[0].user_first_name == null || this.props.currentUserInfo[0].user_first_name == '' || this.props.currentUserInfo[0].user_first_name == undefined ||
  //   // this.props.currentUserInfo[0].user_last_name == null || this.props.currentUserInfo[0].user_last_name == '' || this.props.currentUserInfo[0].user_last_name == undefined){
  //   //   setTimeout(() => {
  //   //     this.setState({editProfileModalOpen: true})
  //   //   }, 1000);
  //   // }
  //   if(this.props.currentUserInfo[0].user_first_name == null || this.props.currentUserInfo[0].user_first_name == '' || this.props.currentUserInfo[0].user_first_name == undefined){
  //     console.log("name is true")
  //     setTimeout(() => {
  //       this.setState({editProfileModalOpen: true})
  //     }, 1000);
  //   }
  // }


  // Commented out so it doesn't continue to pop up when creating a profile
  // componentDidUpdate(prevState, prevProps){
  //   if(!prevProps.editProfileModalOpen){
  //     if(this.props.currentUserInfo[0].user_first_name == null || this.props.currentUserInfo[0].user_first_name == '' || this.props.currentUserInfo[0].user_first_name == undefined ||
  //     this.props.currentUserInfo[0].user_last_name == null || this.props.currentUserInfo[0].user_last_name == '' || this.props.currentUserInfo[0].user_last_name == undefined){
  //         this.setState({editProfileModalOpen: true})
  //     }
  //   }
  // }

  userHandleClick = event => {
    this.setState({ logoutModalOpen: false });
    this.setState({ editProfileModalOpen: false });
    this.setState({ userAnchorEl: event.currentTarget  });
    this.setState({ 
      userOpen: true,
      redirect: false,
     });

  };

  userHandleClose = () => {
    this.setState({ userAnchorEl: null });
    this.setState({ userOpen: false });
    this.setState({ logoutModalOpen: false });
    this.setState({ editProfileModalOpen: false,});

  };

  // userMenuClose = () => {
  //   this.setState({ userOpen: null });
  // };

  handleEditProfileModalOpen = () => {
    this.setState({ userAnchorEl: null });
    this.setState({ userOpen: false });
    this.setState({ logoutModalOpen: false });
    this.setState({ editProfileModalOpen: true });
  };

  handleLogoutModalOpen = () => {
    this.setState({ userAnchorEl: null });
    this.setState({ userOpen: false });
    this.setState({ editProfileModalOpen: false });
    this.setState({ logoutModalOpen: true });
  };

  handleUsageRouting = () => {
    this.props.usageNavigation();
    this.setState({ userAnchorEl: null });
    this.setState({ userOpen: false });
    this.setState({ logoutModalOpen: false });
    this.setState({ editProfileModalOpen: false});
    
  };


  handler = () => {
    this.setState({
      logoutModalOpen: false,
      editProfileModalOpen: false
    })
  };



  render() {
    const { classes, currentUserInfo} = this.props
    const { userAnchorEl,editProfileModalOpen, userOpen, logoutModalOpen} = this.state
    // const userOpen = Boolean(userAnchorEl);


    return(
      <div>
        
      <React.Fragment>
        <IconButton color="inherit" className={classes.iconButtonAvatar} onClick={this.userHandleClick}>
          <Avatar  src={currentUserInfo[0].user_image_url} alt={currentUserInfo[0].user_first_name} />
        </IconButton>

        <Menu style={{outline: 'none'}}
        id="user-menu"
        anchorEl={userAnchorEl}
        open={userOpen}
        onClose={this.userHandleClose}
        // onExit= {this.userHandleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'center',
        // }}
        PaperProps={{
          style: {
            width: 150,
          },
        }}
        >
        {/* <MenuItem onClick={this.handleUsageRouting} >
          Test Dashboard 
        </MenuItem> */}

        <MenuItem onClick={this.handleEditProfileModalOpen} >
          Edit Profile 
        </MenuItem>
         
        <MenuItem onClick={this.handleLogoutModalOpen} >
          Logout
        </MenuItem>
        </Menu>
        {/* <Dropdown >
          <Dropdown.Toggle  id="dropdown-custom-components" >
            <Avatar className={classes.avatar} src={currentUserInfo[0].user_image_url} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as="button" style={{padding:'0px'}}>
              <EditProfileModal 
                email = {this.state.userInfo[0].user_email} 
                user_first_name ={this.state.userInfo[0].user_first_name}
                user_id= {this.state.userInfo[0].user_id}
                profilePic = {this.state.userInfo[0].user_image_url}
                lastName = {this.state.userInfo[0].user_last_name}
                user_phone_number = {this.state.userInfo[0].user_phone_number}
                user_username = {this.state.userInfo[0].user_username}   
              />
                <EditProfileModal 
                email = {currentUserInfo[0].user_email} 
                user_first_name ={currentUserInfo[0].user_first_name}
                // user_id= {currentUserInfo[0].user_id}
                profilePic = {currentUserInfo[0].user_image_url}
                lastName = {currentUserInfo[0].user_last_name}
                user_phone_number = {currentUserInfo[0].user_phone_number}
                user_username = {currentUserInfo[0].user_username}   
              />
            </Dropdown.Item>
            
            <Dropdown.Item as="button" style={{padding:'0px'}}><LogoutModal/></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      </React.Fragment> 
      
      {editProfileModalOpen ? 
        <EditProfileModal 
          open={editProfileModalOpen}
          handler = {this.handler}
          user_first_name = {this.props.currentUserInfo[0].user_first_name}
          user_last_name = {this.props.currentUserInfo[0].user_last_name}
          user_username_public = {this.props.currentUserInfo[0].user_username_public}
        /> 
      : null}
      <LogoutModal open={logoutModalOpen}  handler = {this.handler} /> 


     
      
      
    </div>
  )
}

}

ProfileButton.propTypes = {
/**
 * Injected by the documentation to work in an iframe.
 * You won't need it on your project.
 */
container: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo
  }
}

const mapDispatchToProps = dispatch => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileButton))

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { AutoSizer, Column, Table } from 'react-virtualized';
import DataStreamsTable from '../MyDataStreamsPage/DataStreamsTable';
import {Container} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MqttService from '../core/services/MqttService';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import { connect} from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';   
import Portal from '@material-ui/core/Portal';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getData} from '../ApiCalls/DataApis';
import "../styles/HeartbeatLED.scss";
import "../styles/WaitingDataPulse.scss";
import DeviceDataModal from '../MyDevicesPage/DeviceDataModal';


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    // maxWidth: 752,
  },
  title: {
    margin: theme.spacing(0, 1, 1),
    fontFamily:'Trebuchet MS',
  },
  header: {
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: '10px',
  },
  breadcrumbCSS: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '0px',
  },
  fontStyles: {
    fontFamily:'Trebuchet MS',
    fontSize: '14px',
  },
  waitingCSS:{
    // padding: '5px',
    position: 'absolute',
    // borderRadius: '10px',
    // backgroundColor: '#fafafa',
    // border:'1px solid #4f86ce',
    zIndex: '2',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    left: 'calc(50% - 148px)',
    top: '15%',
    // textShadow:'1px 1px 1px #9eb9ff',
  }
});

const data_streams = [
  // {
  //   "client_id": "client_nodered_1",
  //   "subject": "Humidity",
  //   "name":  "Humidity 34", 
  //   "latest_value":  "50",
  //   "published_at" :  "04:23 pm 05-21-2010"
  // },
  // {
  //   "client_id": "client_testClient_2",
  //   "subject": "Analytics",
  //   "name":  "Analytics sensor 4", 
  //   "latest_value":  "290",
  //   "published_at" :  "05:12 pm 03-31-2010"
  // },
  
];




class MainDataStreamPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isConnected: false,
      message: '',
      organization_data_streams_dic: [],
      organization_clients_dic: [],
      deviceDataRow: [],
      selectedDataRow: [],
      openDeviceDataModal: false,
      rows: [],
    }
    this.handleCloseDeviceDataModal = this.handleCloseDeviceDataModal.bind(this);

  }
  


  // MQTT Functions Below
  componentDidMount() {
    
    // console.log("component did mount");
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/historical_data')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data.data){
          let modifyDate = {};
          let dataSize = {};
          for(var i=0; i<Object.keys(data.data).length; i++){
            // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
            modifyDate[i] = new Date(data.data[i].received_at);
            modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
            data.data[i].received_at = modifyDate[i];

            // get data size
            dataSize[i] = Object.keys(data.data[i].data).length;
            dataSize[i] = this.formatBytes(dataSize[i]);
            data.data[i].data_stream_size = dataSize[i];
          }
          this.setState({
            rows: data.data,
          })
        }
      } else{
        try{
          const data = await response.json();
          this.props.showErrorSnackbar(data.message);
          
        }catch (e){
          this.props.showErrorSnackbar('Something went wrong');
        }
      }
    });
    
    for (let i = 0; i < Object.keys(data_streams).length; i++) {
      // data_streams[i].severity_level_icon = alerts_icon[data_streams[i].severity_level]
        this.state.rows.push(data_streams[i]);
      }

    if(MqttService.isConnected) {
      // MqttService.subscribe('ulises/data-streams/device', this.onDataStream)
      MqttService.subscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/+/devices/+/data-streams/#', this.onDataStream)
    }
    
    
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/data_streams')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          var dic = {};
          for(var i=0; i<Object.keys(data.data).length; i++){
            dic[data.data[i].data_stream_uuid]= data.data[i]
          }
          this.setState({
            organization_data_streams_dic: dic,
          })
        }
      });

      getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/devices/clients')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          var dic = {};
          for(var i=0; i<Object.keys(data.data).length; i++){
            dic[data.data[i].client_id]= data.data[i]
          }
          this.setState({
            organization_clients_dic: dic,
          })
        }
      });
  }

  componentWillUnmount() {
    if(MqttService.isConnected) {
      // MqttService.unsubscribe('ulises/data-streams/device')
      MqttService.unsubscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/+/devices/+/data-streams/#')

      // MqttService.unsubscribe('v1/organizations/'+this.props.selectedOrganization.organization_uuid+'/+')

      // console.log('MQTT unsubscribed')
    }
  }



  onDataStream = message => {
    // console.log('onDataStreamDevice message.topic')
    // console.log(message.topic)
    try{
      const data_stream_message = JSON.parse(message.payloadString);
      var data_stream_uuid = data_stream_message.data_stream_id;
      var client_id = data_stream_message.client_id;
      var data = String(data_stream_message.data);
      // var topicSliced = message.topic.split('/');
      // var dataStreamUuid = topicSliced[topicSliced.indexOf('data-streams')+1];
  
      let data_stream_name = this.state.organization_data_streams_dic[data_stream_uuid].data_stream_name;
      let data_stream_unit = this.state.organization_data_streams_dic[data_stream_uuid].data_stream_unit;
      let client_name = this.state.organization_clients_dic[client_id].client_name;
      let device_name = this.state.organization_clients_dic[client_id].device_name;
      let project_name = this.state.organization_clients_dic[client_id].project_name;
  
      var rows = this.state.rows;
      data_stream_message.client_name = client_name;
      data_stream_message.data_stream_name = data_stream_name;
      data_stream_message.data = data;
      data_stream_message.data_stream_unit = data_stream_unit;
      data_stream_message.device_name = device_name;
      data_stream_message.project_name = project_name;
      data_stream_message.received_at = (new Date()).toLocaleTimeString() + " " + (new Date()).toLocaleDateString();

      // get data stream size
      let dataSize = 0; 
      dataSize = Object.keys(data_stream_message.data).length;
      dataSize = this.formatBytes(dataSize);
      data_stream_message.data_stream_size = dataSize;
  
      rows.unshift(data_stream_message);
      this.setState({rows:rows})
    }
    catch(e){
      console.log("Error in reading data stream in Org from MQTT");
      console.log(e.message);
    }
    
  }

  handleCloseDeviceDataModal = () => {
    this.setState({
      openDeviceDataModal: false,
      deviceDataRow: [],
      selectedDataRow: [],
      })
  }

  formatBytes = (bytes, decimals = 1) => {
    try{
      if (bytes == 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k));
  
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    catch(e){
      return "-"
    }
    
  }

  render(){
    const { classes, selectedOrganization} = this.props
    const { 
      rows,
      openDeviceDataModal,
      deviceDataRow,
      selectedDataRow,} = this.state


    return (
      <div className={classes.root}>
        <div className={classes.breadcrumbCSS}>
          <Breadcrumbs  separator="›" aria-label="breadcrumb">
            {/* <Link color="inherit" className={classes.fontStyles} style={{color:'#757575'}} to="/" >
              {this.props.selectedOrganization.organization_name}
            </Link> */}
            <Typography className={classes.fontStyles} style={{color:'#757575'}}>{selectedOrganization.organization_name}</Typography>
            <Typography className={classes.fontStyles} color="textPrimary">Data Streams</Typography>
          </Breadcrumbs>
        </div>

        <Container  >
          <Row className={classes.header}>
            <Typography variant="h5" className={classes.title}>
              Data Streams
            </Typography>
          </Row>

        
          <Paper  style={{ height:900}}>   
          {rows == '' ? 
             <Paper elevation={3}  >
               <div className={classes.waitingCSS}  >
                  <span className="waitingOnData"> 
                  <Col   >
                    <Row style={{ justifyContent:'center'}}>
                      <Typography  variant="subtitle2" style={{ fontFamily:'Trebuchet MS'}}  >
                        WAITING ON DATA
                      </Typography>
                    </Row>
                    <Row style={{ justifyContent:'center'}}>
                      <Typography  variant="body2"  style={{ fontSize: '12px', fontFamily:'Trebuchet MS' }}>
                          Device messages will be published here in real-time
                      </Typography>
                    </Row>
                  </Col>
                  </span>
                </div>
             </Paper> 
            : null}

            <DataStreamsTable 
              rowCount={rows.length}
              rowGetter={({ index }) => rows[index]}
              onRowClick={(event) => { 
                this.setState({
                  deviceDataRow: event.rowData.data,
                  selectedDataRow: event.rowData
                }, ()=> this.setState({openDeviceDataModal: true }))
              }}
              columns={[
                {
                  width: 300,
                  label: 'Project',
                  dataKey: 'project_name',
                },{
                  width: 300,
                  label: 'Device',
                  dataKey: 'device_name',
                },
                {
                  width: 300,
                  label: 'Client',
                  dataKey: 'client_name',
                },
                {
                  width: 300,
                  label: 'Data Stream',
                  dataKey: 'data_stream_name',
                },
                {
                  width: 250,
                  label: 'Data',
                  dataKey: 'data',
                  numeric: true,
                },
                {
                  width: 50,
                  label: 'Unit',
                  dataKey: 'data_stream_unit',
                  
                },
                {
                  width: 150,
                  label: 'Size',
                  dataKey: 'data_stream_size',
                  
                },
                {
                  width: 300,
                  label: 'Received At',
                  dataKey: 'received_at',
                  date: true,
                },
              ]}
            />
            {openDeviceDataModal?
                <DeviceDataModal openDeviceDataModal={openDeviceDataModal} title={'Device Data'} deviceDataRow={deviceDataRow} selectedDataRow={selectedDataRow} handleCloseDeviceDataModal={this.handleCloseDeviceDataModal} />
                  : null}
          </Paper>
        </Container>
      </div>

    );
    
  } 
}

const mapStateToProps = state => {
  return {
    // currentUserInfo: state.users.currentUserInfo,
    // allOrganizations: state.organizations.allOrganizations,
    selectedOrganization: state.organizations.selectedOrganization
  }
}

export default connect(mapStateToProps)(withStyles(styles)(MainDataStreamPage))
// export default MainDataStreamPage






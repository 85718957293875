import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, rgbToHex } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData, putData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import IconButton from '@material-ui/core/IconButton';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PasswordInfoModal from '../components/PasswordInfoModal';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import QRCode from 'qrcode.react';
import Amplify, { Auth, Hub } from 'aws-amplify';

Amplify.configure({
  Auth: {            
      identityPoolId: 'us-east-1:5684b65a-8bf4-4697-b9a4-87eadbf03ef5',
      region: 'us-east-1',
      identityPoolRegion: 'us-east-1',      
      userPoolId: 'us-east-1_3EP1MRzAy',  
      userPoolWebClientId: '18230ih88kfb3ouj67mrid5er3',
      oauth: {
          domain: 'auth.iotflows.com',
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          // redirectSignIn: 'https://www.console.iotflows.com/', 
          // redirectSignOut: 'https://www.console.iotflows.com/', 
          redirectSignIn: 'http://localhost:3000/', 
          redirectSignOut: 'http://localhost:3000/', 
          responseType: 'token', // DO NOT USE 'code'!!! it goes through a forever loop //or 'token', note that REFRESH token will only be generated when the responseType is code
          // responseType: 'code', 
          options: {
              // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
              AdvancedSecurityDataCollectionFlag : true
          },
      }
  }
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(70),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    // [theme.breakpoints.down('md')]:{
    //   width: theme.spacing(110),
    // },
    // [theme.breakpoints.down('sm')]:{
    //   width: theme.spacing(70),
    // },
    [theme.breakpoints.down('xs')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    width: 'auto',
    height: 'auto',
    padding: '4px',
    margin: '0px',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-end',
    alignItems:'flex-end',
    alignItems: 'flex-end',
    fontFamily:'Trebuchet MS',
    outline: 'none',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    borderRadius: 10, 
    maxWidth: theme.spacing(150),
  },
  header: {
    fontSize: '14px',
    lineHeight: '32px',
    fontFamily:'Trebuchet MS',
    fontWeight: 400,
    textAlign: 'center',
    padding: '12px',
  },
  loginTitleContainer: {
    marginLeft: '12px',
    textAlign: 'center',
  },
  // MFA CSS
  boxDisplay: {
    borderRight: '1px solid rgba(0, 0, 0, 0.2)',
    width: '40px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '32px',
    position: 'relative',
    appearance: 'none',
    margin: 0,
    '&:last-child': {
        borderRight: 'none',
    },
  },
  wrap: {
    border: '1px solid rgba(0, 0, 0, 0.2)',
    display: 'inline-block',
    position: 'relative',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '24px',
    marginBottom: '12px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  input: {
    position: 'absolute',
    border: 'none',
    fontSize: '32px',
    textAlign: 'center',
    backgroundColor: 'transparent',
    outline: 'none',
    appearance: 'none',
    margin: 0,
    // -webkitAppearance: 'none',
    // -mozAppearance: 'textfield',
  },
  shadows: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    boxShadow: '0 0 0 4px rgba(58, 151, 212, 0.28)',
    borderRadius: '0px',
  },
  shadowsLeft: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    boxShadow: '0 0 0 4px rgba(58, 151, 212, 0.28)',
    borderRadius: '0px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '0px',
  },
  shadowsRight: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    boxShadow: '0 0 0 4px rgba(58, 151, 212, 0.28)',
    borderRadius: '0px',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '8px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '8px',
  },

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CODE_LENGTH = new Array(6).fill(0);

class QRcodeMFA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      loading: false,
      success: false,
      strQR: '',
      displayQRcode: true,
      value: "",
      focused: false,
      user: this.props.user,
      mfaVerified: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    
  }

  input = React.createRef();

  componentDidMount(){

    Auth.setupTOTP(this.state.user)
    .then((code) => {
        
      let issuer = 'IoTFlows';
      // let str = "otpauth://totp/AWSCognito:"+ this.props.currentUserInfo[0].user_username + "?secret=" + code + "&issuer=" + issuer;
      let str = "otpauth://totp/AWSCognito:"+ issuer + "?secret=" + code + "&issuer=" + issuer;
      this.setState({
        strQR: str,
      })
    });

  }

  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
    });
  };

  handleClose = () => {
    this.props.handleCloseQRcodeMFA(this.state.mfaVerified);
    this.setState({ 
      open: false,
      loading: false,
      success: false,
      displayQRcode: true,
    });
  };
  

  submitButtonClick = () => {
    this.setState({displayQRcode: false})
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          Auth.verifyTotpToken(this.state.user, this.state.value)
          .then(() => {

              // don't forget to set TOTP as the preferred MFA method
              Auth.setPreferredMFA(this.state.user, 'TOTP');
              this.props.showSuccessSnackbar('Two-factor authentication has been enabled')
              this.setState({mfaVerified: true}, () => this.handleClose())
              
              // ...
          }).catch( e => {
              // console.log(e)
              this.props.showErrorSnackbar(e.message)
              this.setState({ 
                loading: false,
                success: false,
                mfaVerified: false
              });
          });
        }
      )
    }
  };

  // MFA functions
  handleClick = () => {
    this.input.current.focus();
  };

  handleFocus = () => {
      this.setState({ focused: true });
  };

  handleBlur = () => {
      this.setState({focused: false});
  };

  handleChange = (e) => {
      const value = e.target.value.replace(/[^0-9]/g, "");
    
      this.setState((state) => {
        if (state.value.length >= CODE_LENGTH.length) return null;
        return {
          value: (state.value + value).slice(0, CODE_LENGTH.length),
        };
      });
    };

  handleKeyUp = (e) => {
  if (e.key === "Backspace") {
      this.setState((state) => {
      return {
          value: state.value.slice(0, state.value.length - 1),
      };
      });
  }
  };

  


  render() {
    const { classes, selectedOrganization } = this.props;
    const { open, 
            loading, 
            success,
            strQR,
            value,
            focused,
            displayQRcode } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });


    const values = value.split("");
    const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;
    const hideInput = !(values.length < CODE_LENGTH.length);

    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
      >
        
        {/* <IconButton className={classes.button} variant="outlined" onClick={this.handleOpen}><EditOutlinedIcon style={{color: '#757575'}}/></IconButton> */}

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">
                  {displayQRcode && 
                    'Set Up Authentication App'
                  }
                  {!displayQRcode && 
                    'Confirm Authentication App'
                  }
                </DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px'}}>
                  
                  {displayQRcode &&
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <div className={classes.loginTitleContainer} >
                        <span className={classes.header}>Scan the QR code below into a third party authentication app.</span>
                      </div>
                      
                      <div style={{textAlign: 'center'}}>
                        <QRCode value={strQR}/>
                      </div>
                    </div>
                  }
                  {!displayQRcode && 
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <div className={classes.loginTitleContainer} >
                          <span className={classes.header} >Enter the 6-digit code you see in the app.</span>
                      </div>

                      {/* 6 code digits  */}
                      <div className={classes.wrap} onClick={this.handleClick}>
                          <input
                                  value=""
                                  ref={this.input}
                                  onChange={this.handleChange}
                                  onFocus={this.handleFocus}
                                  onBlur={this.handleBlur}
                                  onKeyUp={this.handleKeyUp}
                                  className={classes.input}
                                  type="text"
                                  style={{
                                      width: "40px",
                                      top: "0px",
                                      bottom: "0px",
                                      left: "0px",
                                      left: `${selectedIndex * 40}px`,
                                      opacity: hideInput ? 0 : 1,
                                  }}
                              />
                          {
                              CODE_LENGTH.map((v, index) => {
                                  const selected = values.length === index;
                                  const filled = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

                                  return (
                                  <div key={index} className={classes.boxDisplay}>
                                      {values[index]}
                                      {(selected || filled) && focused && (index == 0) && <div className={classes.shadowsLeft} />}
                                      {(selected || filled) && focused && (index > 0 && index < 5) && <div className={classes.shadows} />}
                                      {(selected || filled) && focused && (index == 5) && <div className={classes.shadowsRight} />}
                                  </div>
                                  );
                              })
                          }
                      </div>

                    </div>
                  }

                    
                    
                  

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  {displayQRcode &&
                    <>
                      <Button  color="primary" type='submit' className={buttonClassname} onClick={() => this.setState({displayQRcode: false})}>
                        {'Next'} 
                      </Button>
                    </>
                  }
                  {!displayQRcode &&
                    <>
                      <Button  color="primary" type='submit' className={buttonClassname} onClick={() => this.setState({displayQRcode: true})}>
                        {'Back'}
                      </Button>
                      <Button  color="primary" type='submit' className={buttonClassname} onClick={this.submitButtonClick}>
                        {'Submit'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </Button>
                    </>
                  }
                  
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    currentUserInfo: state.users.currentUserInfo,
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(QRcodeMFA));

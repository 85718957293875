import {getData} from '../../ApiCalls/DataApis';

// export const fetchUsers = () => dispatch => {
//   fetch(BASE_URL + 'users')
//     .then(res => res.json())
//     .then(users => 
//       dispatch({
//         type: FETCH_USERS,
//         payload: users
//       })
//     );
// }

export const fetchOrganizations = () => dispatch => { 
  getData('https://api.iotflows.com/v1/organizations')
      .then(async response => {
        
        if(response.ok ){
          const data = await response.json();
          if(data){
            dispatch({
              type: 'FETCH_ORGANIZATIONS',
              payload: data.data 
            })
            dispatch({
              type: 'IS_FETCHING_ORGANIZATIONS_COMPLETE',
              isAllOrganizationsFetched: true 
            })
          }
        }
        
      });
}

export const fetchingOrganizationsComplete = isAllOrganizationsFetched => {
  return dispatch => {
    dispatch({ type: "IS_FETCHING_ORGANIZATIONS_COMPLETE", isAllOrganizationsFetched });
  };
};

export const fetchSelectedOrganization = currentOrganization => {
  return dispatch => {
    dispatch({ type: "SELECTED_ORGANIZATION", currentOrganization });
  };
};

// export const fetchSelectedOrganization = (id) => dispatch => {
//   // TODO: Remove and uncomment when API is ready.
//   dispatch({
//     type: SELECTED_ORGANIZATION,
//     payload: dummyUser
//   })
//   // fetch(BASE_URL + 'user/' + id)
//   //   .then(res => res.json())
//   //   .then(user => 
//   //     dispatch({
//   //       type: SELECTED_USER,
//   //       payload: user
//   //     })
//   //   );
// }


import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Portal from '@material-ui/core/Portal';
import Form from 'react-bootstrap/Form';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import  {postData, getData } from '../ApiCalls/DataApis';


const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'rgb(79, 135, 204)',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 14px',
    height: '34px',
    width: '195px',    
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
      outline: 'none',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: { borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class CreateDataStreamModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      dataStreamName: '',
      dataStreamDescription: '',
      dataStreamUnit: '',
      device_disabled: this.props.device_disabled,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitButtonClick = this.submitButtonClick.bind(this);
  }

  timer = undefined;

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
      dataStreamName: '',
      dataStreamDescription: '',
      dataStreamUnit: ''
    });
  };

  handleDataStreamNameChange = (event) => {
    this.setState({dataStreamName: event.target.value});
  }

  handleDataStreamDescriptionChange = (event) => {
    this.setState({dataStreamDescription: event.target.value});
  }

  handleDataStreamUnitChange = (event) => {
    this.setState({dataStreamUnit: event.target.value});
  }

  submitButtonClick = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        postData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/data_streams', 
        {'data_stream_name': this.state.dataStreamName,
          'data_stream_description': this.state.dataStreamDescription,
          'data_stream_unit': this.state.dataStreamUnit})
          .then(async response => {
            if(response.ok){
              this.props.showSuccessSnackbar("Data Stream has been successfully added!");
              this.props.refreshDataStreamList();
              this.setState({
                loading: false,
                success: true,
                open:false,
              }, ()=> {this.handleClose()});
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false,
                }, ()=> {this.handleClose()});
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      device_disabled: nextProps.device_disabled,
    }); 
  };


  render() {
    const { classes } = this.props;
    const { open, 
            loading, 
            success, 
            dataStreamName,
            dataStreamDescription,
            dataStreamUnit,
            device_disabled} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    

    return (
      <Grid
        container
        alignItems="flex-start"
        justify="flex-start"
        direction="column"
        
      >
        <Button className={classes.button} variant="contained"  disabled={device_disabled} onClick={this.handleOpen}><AddCircleOutlineOutlinedIcon /> &nbsp;Create Data Stream</Button>
          
          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">Create Data Stream</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '0px'}}>

                <TextField
                  autoFocus
                  margin="normal"
                  id="dataStreamName"
                  label="Data Stream Name"
                  type="text"
                  value={dataStreamName} 
                  onChange={this.handleDataStreamNameChange}
                  fullWidth
                />

                <TextField
                  autoFocus
                  margin="normal"
                  id="dataStreamDescription"
                  label="Data Stream Description"
                  type="text"
                  value={dataStreamDescription} 
                  onChange={this.handleDataStreamDescriptionChange}
                  fullWidth
                />

                <TextField
                  autoFocus
                  margin="normal"
                  id="dataStreamUnit"
                  label="Data Stream Unit"
                  type="text"
                  value={dataStreamUnit} 
                  onChange={this.handleDataStreamUnitChange}
                  fullWidth
                />

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading} onClick={this.submitButtonClick}>
                    Submit { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

CreateDataStreamModal.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => state

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(CreateDataStreamModal));

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import  {postData, getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';

// const LightTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: "#757575",
//     color: theme.palette.common.white,
//     boxShadow: theme.shadows[1],
//     fontSize: 12,
//     border: '1px solid #fafafa',
//   },
//   arrow: {
//     color: '#757575',
//   },
// }))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    maxWidth: theme.spacing(150),
    minWidth: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('md')]:{
      maxWidth: theme.spacing(110),
      minWidth: theme.spacing(60),
    },
    [theme.breakpoints.down('sm')]:{
      maxWidth: theme.spacing(70),
      minWidth: theme.spacing(60),
    },
    [theme.breakpoints.down('xs')]:{
      maxWidth: theme.spacing(43),
      minWidth: theme.spacing(43),
    }
  },
  button: {
    backgroundColor:'transparent',
    borderRadius:'24px',
    border:'1px solid #4f86ce',
    display:'inline-block',
    cursor:'pointer',
    color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    fontSize:'12px',
    // font-weight:bold;
    padding:'3px 10px',
    textDecoration:'none',
    textShadow:'0px 0px 0px #9eb9ff',
    outline: 'none',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    borderRadius: 10, 
    maxWidth: theme.spacing(150),
  },
  cardCSS: {
    width: '100%',
    borderRadius: '10px',
    border:'1px solid #f5f5f5',
    padding: '8px',
    backgroundColor: '#fafafa',
    boxShadow: '2px 4px #f5f5f5',
    cursor:'pointer',
    '&:hover': {
      // backgroundColor:'#eeeeee',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid #bdbdbd',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'#eeeeee',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor:'#eeeeee',
      border:'1px solid #bdbdbd',
      boxShadow: '2px 4px #bdbdbd',
    },
  },
  cardCSSSelected: {
    width: '100%',
    borderRadius: '10px',
    padding: '8px',
    outline: 'none',
    backgroundColor:'#eeeeee',
    border:'1px solid #bdbdbd',
    boxShadow: '2px 4px #bdbdbd',
    position:'relative',
    cursor:'pointer',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  headerCSS: {
    textAlign: 'center',
    color: '#1976d2',
    fontFamily:'Trebuchet MS',
  },
  bodyCSS: {
    fontSize: '14px',
    color: '#525f7f',
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
  },
  pricingCSS: {
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'center',
    fontFamily:'Trebuchet MS',
    marginTop: '16px',
    marginBottom: '16px',
  },
  customButton: {
    width: '100%',
    marginBottom: '10px', 
    marginTop: '10px',
    borderRadius: '5px',
    border:'1px solid #f5f5f5',
    backgroundColor:'rgba(87, 147, 222, 0.1)',
    '&:hover': {
      backgroundColor:'rgba(87, 147, 222, 0.3)',
      // boxShadow: '2px 4px #eeeeee',
      border:'1px solid rgba(87, 147, 222, 0.5)',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      outline: 'none',
      backgroundColor:'rgba(87, 147, 222, 0.4)',
      border:'1px solid rgba(87, 147, 222, 0.7)',
    },
    '&:focus': {
      outline: 'none',
      // backgroundColor:'#eeeeee',
      // border:'1px solid #bdbdbd',
      // boxShadow: '2px 4px #bdbdbd',
    },
  },
  itemsCSS: {
    fontSize: '12px',
    color: '#525f7f',
    textAlign: 'left',
    fontFamily:'Trebuchet MS',
    listStyleType: 'none',
    marginTop: '15px',
    padding: '0px',
    // height: '250px',
    // [theme.breakpoints.down('md')]:{
    //   height: '250px',
    // },
    // [theme.breakpoints.down('sm')]:{
    //   height: '350px',
    // },
    // [theme.breakpoints.down('xs')]:{
    //   height: '100%',
    // }
  },
  itemCSS: {
    padding: '5px',
  },
  subItemCSS: {
    fontSize: '11px',
    padding: '5px',
    paddingLeft: '25px',
  },
  checkmarkCSS: {
    fontSize: '14px',
    color:'rgb(0,209,0)',
  },
  circleCSS: {
    fontSize: '14px',
    color:'rgb(191, 191, 191)',
  },
  selectedServerCSS: {
    marginTop: '12px', 
    fontSize: '20px', 
    color:'#4f87cc', 
    cursor:'pointer',
    '&:hover': {
      color:'rgb(79, 135, 204, 0.8)',
      transform: 'translateY(-1px)',
    },
  },
  subscriptionCard: {
    width: '240px',
    margin: '10px',
    borderRadius: '10px',
    // border:'1px solid #f5f5f5',
    border:'1px solid rgba(207, 207, 207, 0.3)',
    padding: '16px 8px',
    backgroundColor: '#fff',
    // backgroundColor: '#fafafa',
    // boxShadow: '2px 4px #f5f5f5',
    cursor:'pointer',
    webkitBoxShadow: '0 30px 60px -12px rgb(50 50 93 / 25%), 0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%)',
    boxShadow: '0 30px 60px -12px rgb(50 50 93 / 25%), 0 18px 36px -18px rgb(0 0 0 / 30%), 0 -12px 36px -8px rgb(0 0 0 / 3%)',
    '&:hover': {
      border:'1px solid rgba(207, 207, 207, 0.5)',
      backgroundColor:'rgba(219, 219, 219, 0.3)',
      transform: 'translateY(-5px)',
    },
  },
  dropdownButton: {
    padding: 2,
    marginBottom: '8px',
    border: '1px solid rgba(0,0,0,0.5)',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    outline: 'none',
    minWidth: '180px',
    width: "100%",
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  }, 

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class CreateInstanceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      loading2: false,
      success2: false,
      instanceName: '',
      instanceDescription: '',
      cloudServerType:'',
      cloudServerSubdomain: '',
      createNewOrgKey: false,
      organizationsKeys: [],
      selectedOrganizationKey: [],
      newKeyName: '',
      newKeyDescription: '',
      pemFileAcknowledged: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOrgKeyChange = this.handleOrgKeyChange.bind(this);
  }

  componentDidMount() {

    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/keys')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        this.setState({
          organizationsKeys: data.data,
        })
      }
    });
  }

  handleOpen = () => {
    this.setState({ 
      open: true,
      loading: false,
      success: false,
      loading2: false,
      success2: false,
      instanceName: '',
      instanceDescription: '',
      cloudServerType:'',
      cloudServerSubdomain: '',
      createNewOrgKey: false,
      newKeyName: '',
      newKeyDescription: '',
      pemFileAcknowledged: false,
    });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      loading: false,
      success: false,
      loading2: false,
      success2: false,
      instanceName: '',
      instanceDescription: '',
      cloudServerType:'',
      cloudServerSubdomain: '',
      createNewOrgKey: false,
      selectedOrganizationKey: [],
      newKeyName: '',
      newKeyDescription: '',
      pemFileAcknowledged: false,
    });
  };

  handleInstanceNameChange = (event) => {
    this.setState({instanceName: event.target.value});
  }

  handleInstanceDescriptionChange = (event) => {
    this.setState({instanceDescription: event.target.value});
  }

  handleNewKeyNameChange = (event) => {
    this.setState({newKeyName: event.target.value.replace(/[^A-Za-z0-9_]/g, "")});
  }

  handleNewKeyDescriptionChange = (event) => {
    this.setState({newKeyDescription: event.target.value});
  }

  handleCloudServerTypeChange = (event) => {
    this.setState({cloudServerType: event});
  }

  handleCloudServerSubdomainChange = (event) => {
    
    this.setState({cloudServerSubdomain: event.target.value.replace(/[^a-z0-9-]/g, "")});
  }

  // downloadCsv = () => {
  //   var csv = 'API Key Username,Password\n';
  //   csv += this.props.generated_client_id + ',' + this.props.generated_password

  //   var hiddenElement = document.createElement('a');
  //   hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  //   hiddenElement.target = '_blank';
  //   hiddenElement.download = 'API Key Info.pem';
  //   hiddenElement.click();
  // }

  refreshOrgKeys= () => {
    getData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/keys')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        this.setState({
          organizationsKeys: data.data,
        })
      }
    });
  }

  downloadKey = () => {
    const { loading2 } = this.state;
    if (!loading2) {
      this.setState(
        {
          success2: false,
          loading2: true,
        },
        () => {
        postData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/keys' , 
        {'key_name': this.state.newKeyName,
          'key_description': this.state.newKeyDescription,
        })
          .then(async response => {
            if(response.ok){
              const data = await response.json();

              var keyPrivate = data.data.key_private;
              var hiddenElement = document.createElement('a');
              hiddenElement.href = 'data:text;charset=utf-8,' + encodeURI(keyPrivate);
              hiddenElement.target = '_blank';
              hiddenElement.download = data.data.key_name +'.pem';
              hiddenElement.click();
              this.refreshOrgKeys();
              
              let newOrganizationKey = 
              [{'key_name': data.data.key_name,
              'key_description': data.data.key_description,
              'key_uuid': data.data.key_uuid
              }]

              this.props.showSuccessSnackbar("SSH Key Downloaded!");
              this.setState({
                loading2: false,
                success2: true,
                pemFileAcknowledged: true,
                selectedOrganizationKey: newOrganizationKey[0],
              });
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading2: false,
                  success2: false});
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  submitButtonClick = () => {
    
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        postData('https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/cloud-servers', 
        {'cloud_server_name': this.state.instanceName,
          'cloud_server_description': this.state.instanceDescription,
          'cloud_server_subscription_identifier': this.state.cloudServerType,
          'cloud_server_subdomain': this.state.cloudServerSubdomain,
          'key_uuid': this.state.selectedOrganizationKey.key_uuid,
        })
          .then(async response => {
            if(response.ok){
              const data = await response.json();
              this.props.showSuccessSnackbar("Cloud Node-RED Server created!");
              this.props.refreshCloudNodeRedInstanceList();
              this.setState({
                loading: false,
                success: true,
                open:false,
              }, ()=> {
                setTimeout(
                  () => this.props.showSuccessSnackbar("It may take up to 5 minutes to spin up and configure server"), 
                  10000
                );
                
              });
            }
            else{
              try{
                const data = await response.json();
                this.props.showErrorSnackbar(data.message);
                this.setState({
                  loading: false,
                  success: false,
                  open:false});
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
    }
  };

  userHandleClick = event => {
    this.setState({ 
      userAnchorEl: event.currentTarget ,
      userOpen: true,
     });

  };

  userHandleClose = () => {
    this.setState({ 
      userAnchorEl: null,
      userOpen: false });

  };

  handleOrgKeyChange = (organizationKey) => {
    this.setState({ 
      selectedOrganizationKey: organizationKey,
      newKeyName: '',
      newKeyDescription: '',
      createNewOrgKey: false,
      userAnchorEl: null,
      userOpen: false 
    })
  }
  handleCreateNewKey = () => {

    this.setState({ 
      createNewOrgKey: true,
      selectedOrganizationKey: [],
      userAnchorEl: null,
      userOpen: false 
    })
  }

  handleRadioChange = (event) => {
    this.setState({pemFileAcknowledged: event.target.checked})
  }
  


  render() {
    const { classes, selectedOrganization } = this.props;
    const { userAnchorEl, 
            userOpen, 
            open, 
            loading, 
            success, 
            loading2, 
            success2, 
            instanceName,
            instanceDescription,
            cloudServerType,
            cloudServerSubdomain,
            selectedOrganizationKey,
            createNewOrgKey,
            newKeyName,
            newKeyDescription,
            pemFileAcknowledged} = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    const buttonClassname2 = classNames({
      [classes.buttonSuccess]: success2,
    });


    return (
      <Grid
        container
        alignItems="flex-end"
        justify="flex-end"
        direction="column"
      >
        
        <Button className={classes.button} variant="contained" onClick={this.handleOpen}><AddCircleOutlineOutlinedIcon style={{fontSize: '16px'}} />&nbsp;Create Server</Button>

          <Dialog
              open={open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              classes = {{paper: classes.dialog}}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} >
                  {selectedOrganization.organization_is_enterprise?
                      'Create Enterprise Cloud Node-RED Server'
                      :
                      'Create Cloud Node-RED Server'
                    }
                </DialogTitle>
                <DialogContent dividers style={{paddingBottom: '20px', paddingTop: '12px'}}>

                {selectedOrganization.organization_is_enterprise?
                        <div  style={{ display: (!cloudServerType ) ? "block" : "none" }} >
                          <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '20px'}}>
                            <b>Select Enterprise Cloud Server Type</b>
                          </DialogContentText>
                          <div style={{display:'flex'}} >
                              <div className={classes.subscriptionCard} onClick={()=> this.setState({cloudServerType: 'ent-cns-medium'})} >
                                <h4 className={classes.headerCSS} style={{marginTop: '10px'}} >Enterprise Medium</h4>
                              </div>
                              <div className={classes.subscriptionCard}  onClick={()=> this.setState({cloudServerType: 'ent-cns-large'})}>
                                <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Enterprise Large</h4>
                              </div>
                          </div>
                          
                        </div>
                      :
                        <div  style={{ display: (!cloudServerType ) ? "block" : "none" }} >
                          <DialogContentText style={{textAlign: 'center', marginTop: '12px', fontSize: '20px'}}>
                            <b>Select Cloud Server Type</b>
                          </DialogContentText>
                          <div style={{display:'flex'}} >
                              <div className={classes.subscriptionCard} onClick={()=> this.setState({cloudServerType: 'cns-small'})} >
                                <h4 className={classes.headerCSS} style={{marginTop: '10px'}} >Small</h4>
                              </div>
                              <div className={classes.subscriptionCard}  onClick={()=> this.setState({cloudServerType: 'cns-medium'})}>
                                <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Medium</h4>
                              </div>
                              <div className={classes.subscriptionCard}   onClick={()=> this.setState({cloudServerType: 'cns-large'})}>
                                <h4 className={classes.headerCSS} style={{marginTop: '10px'}}>Large</h4>
                              </div>
                          </div>
                          
                        </div>
                    }

                

                {/* Organizaton SSH key selection/creation */}
                <div  style={{ display: cloudServerType && (Object.keys(selectedOrganizationKey).length == 0 || !pemFileAcknowledged) ? "block" : "none" }} >
                  <div className={classes.selectedServerCSS} onClick={()=> this.setState({cloudServerType: '', selectedOrganizationKey: [], pemFileAcknowledged: false})} >
                      <h5 style={{textTransform: 'uppercase', display: 'flex'}}>
                        <b>Cloud Node-RED Server:&nbsp;</b>
                        {cloudServerType === "cns-small" ? 'Small' : null}
                        {cloudServerType === "cns-medium" ? 'Medium':null}
                        {cloudServerType === "cns-large" ? 'Large' : null}
                        {cloudServerType === "ent-cns-medium" ? 'Enterprise Medium':null}
                        {cloudServerType === "ent-cns-large" ? 'Enterprise Large' : null}
                      </h5>
                    </div>
                  <DialogContentText style={{textAlign: 'center', margin: '12px 0px', fontSize: '20px'}}>
                    <b>Select an existing key or create a new key </b>
                  </DialogContentText>
                  <div style={{textAlign: 'left', color: 'rgba(0, 0, 0, 0.67)'}}>
                    <p> A pair key consists of a public key that will be stored in the server and a private key that you store on your computer. This private key allows you to connect to your instance securely. Please keep it in a safe location</p>
                  </div>
                  <div style={{textAlign: 'left'}}>
                    <React.Fragment>
                      <button  className={classes.dropdownButton} onClick={this.userHandleClick}>
                        <div >
                          { Object.keys(selectedOrganizationKey).length == 0 && !createNewOrgKey? 
                            <div style={{textAlign:'left', overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingLeft: '6px'}}>
                              <span style={{fontSize: '14px', lineHeight: '20px', letterSpacing: '0.2px' }}>Choose/Create Key </span>
                            </div>
                            :
                            null
                          }
                          {Object.keys(selectedOrganizationKey).length > 0 && !createNewOrgKey ? 
                            <div style={{maxWidth: '110px',textAlign:'left', overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingLeft: '6px'}}>
                              <span style={{fontSize: '14px', lineHeight: '20px', letterSpacing: '0.2px' }}>{selectedOrganizationKey.key_name}</span>
                            </div>
                            :
                            null
                          }
                          {createNewOrgKey && Object.keys(selectedOrganizationKey).length == 0 ? 
                            <div style={{textAlign:'left', overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingLeft: '6px'}}>
                              <span style={{fontSize: '14px', lineHeight: '20px', letterSpacing: '0.2px' }}>Create new key </span>
                            </div>
                            :
                            null
                          }
                        </div>
                      </button>

                      {!createNewOrgKey || Object.keys(selectedOrganizationKey).length > 0 ? 
                            <FormGroup row>
                              <FormControlLabel 
                                control={
                                  <Checkbox
                                    checked={pemFileAcknowledged}
                                    onChange={this.handleRadioChange}
                                    name="acknowledged"
                                    color="primary"
                                  />
                                }
                                label="I acknowledge that I have access to the selected private key file (*.pem), and that without this file, I won't be able to log into my server " 
                                />
                          </FormGroup>
                            :
                            null
                        }
                      

                      <Menu 
                        style={{outline: 'none'}}
                        id="user-menu"
                        anchorEl={userAnchorEl}
                        open={userOpen}
                        onClose={this.userHandleClose}
                        // onExit= {this.userHandleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        // transformOrigin={{
                        //   vertical: 'top',
                        //   horizontal: 'center',
                        // }}
                        PaperProps={{
                          style: {
                            width: 300,
                          },
                        }}
                      >
                      {this.state.organizationsKeys?.map( (organizationKey, index) => (
                        <MenuItem key={index} style={{padding: '4px 4px 4px 4px'}} onClick={() => this.handleOrgKeyChange(organizationKey)}>
                          <div style={{display: 'flex'}}>
                            <div style={{width: '180px', overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingLeft: '6px',lineHeight: '20px'}} >
                              <span style={{fontWeight:500, fontSize: '14px', letterSpacing: '0.2px' }}>{organizationKey.key_name}</span>
                            </div>
                          </div>
                        </MenuItem>
                      ))}

                      
                      
                      <MenuItem onClick={this.handleCreateNewKey} style={{padding: '4px 4px 4px 4px'}}>
                        <div style={{display: 'flex'}}>
                            <div style={{ height: '24px', width: '20px'}} >
                              <AddCircleOutlineOutlinedIcon style={{ fontSize: 18, margin: '3px' }}/>
                            </div>
                            <div  style={{width: '205px', overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingLeft: '6px',lineHeight: '24px'}}>
                              <span style={{fontSize: '14px', letterSpacing: '0.2px' }}>Create a new key</span>
                            </div>
                          </div>
                      </MenuItem>
                      </Menu>
                    </React.Fragment> 

                    {/* Create new organization key */}
                    <div style={{ display: (createNewOrgKey ) ? "block" : "none" }}>
                      <TextField
                        style={{marginTop: '0px'}}
                        autoFocus
                        margin="normal"
                        id="newKeyName"
                        label="Key Name"
                        type="text"
                        value={newKeyName} 
                        onChange={this.handleNewKeyNameChange}
                        fullWidth
                        required
                      />

                      <TextField
                        autoFocus
                        margin="normal"
                        id="newKeyDescription"
                        label="Key Description"
                        type="text"
                        value={newKeyDescription} 
                        onChange={this.handleNewKeyDescriptionChange}
                        fullWidth
                      />
                      <div style={{textAlign: 'right'}}>
                        <Button style={{margin: '8px 0px 16px 0px'}} color="primary" type='submit' className={buttonClassname2} disabled={loading2 || (newKeyName == '') } onClick={this.downloadKey}>
                          {'Download Key'} { loading2 && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                      </div>
                      

                      <Alert severity="info" >
                        <div style={{display: 'block'}}>
                          You have to download the <strong>private key file</strong> (*.pem file) before you can continue.<strong> Store it in a secure and accessible location.</strong> You will not be able to download the file again after it's created.
                        </div>
                      </Alert> 

                    </div>
                  
                </div>
                </div>

                {/* Cloud server naming */}
                <div style={{ display: cloudServerType && Object.keys(selectedOrganizationKey).length > 0 && pemFileAcknowledged  ? "block" : "none" }}>
                    <div className={classes.selectedServerCSS} onClick={() => this.setState({cloudServerType: '', selectedOrganizationKey: [], pemFileAcknowledged: false, instanceName: '', instanceDescription: '', cloudServerSubdomain: ''})} >
                      <h5 style={{textTransform: 'uppercase', display: 'flex'}}>
                        <b>Cloud Node-RED Server:&nbsp;</b>
                        {cloudServerType === "cns-small" ? 'Small' : null}
                        {cloudServerType === "cns-medium" ? 'Medium':null}
                        {cloudServerType === "cns-large" ? 'Large' : null}
                        {cloudServerType === "ent-cns-medium" ? 'Enterprise Medium':null}
                        {cloudServerType === "ent-cns-large" ? 'Enterprise Large' : null}
                      </h5>
                    </div>
                    <div className={classes.selectedServerCSS} onClick={() => this.setState({selectedOrganizationKey: [], instanceName: '', instanceDescription: '', cloudServerSubdomain: '', pemFileAcknowledged: false})} >
                      <h5 style={{textTransform: 'uppercase', display: 'flex'}}>
                        <b>Organization Key:&nbsp;</b>{selectedOrganizationKey.key_name}
                      </h5>
                    </div>
                  <TextField
                    style={{marginTop: '0px'}}
                    autoFocus
                    margin="normal"
                    id="instanceName"
                    label="Name"
                    type="text"
                    value={instanceName} 
                    onChange={this.handleInstanceNameChange}
                    fullWidth
                    required
                  />

                  <TextField
                    autoFocus
                    margin="normal"
                    id="instanceDescription"
                    label="Description"
                    type="text"
                    value={instanceDescription} 
                    onChange={this.handleInstanceDescriptionChange}
                    fullWidth
                  />

                  <TextField
                    autoFocus
                    margin="normal"
                    id="cloudServerSubdomain"
                    label="Server Sudomain"
                    type="text"
                    value={cloudServerSubdomain} 
                    onChange={this.handleCloudServerSubdomainChange}
                    fullWidth
                    required
                  />
                </div>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.handleClose} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading || (instanceName == '') || (cloudServerSubdomain == '') || selectedOrganizationKey.key_uuid == '' || !pemFileAcknowledged } onClick={this.submitButtonClick}>
                    {'Launch Server'} { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(CreateInstanceModal));



{/* <Grid container spacing={1} style={{marginBottom: '16px', textAlign: 'center'}}>
                    <Grid item xs={12} sm={6} md={3}>
                      <button className={cloudServerType == 'CS1' ? classes.cardCSSSelected : classes.cardCSS}  onClick={() => this.handleCloudServerTypeChange('CS1')} >
                        <h4 className={classes.headerCSS}>CS1</h4>
                        <Divider/>
                        <div className={classes.pricingCSS}>
                          $25
                        </div>
                        <div className={classes.bodyCSS}>
                          per cloud server/month
                        </div>
                        <ul className={classes.itemsCSS}>
                          <li className={classes.itemCSS}><b>Performance: up to 3GHz</b> </li>
                          <li className={classes.itemCSS}><b>Memory: Up to 1GB</b> </li>
                             <li className={classes.subItemCSS}><FiberManualRecordOutlinedIcon className={classes.circleCSS} /> Node-RED or SHH connections</li> 
                            <br/>
                          <li className={classes.itemCSS}><i>Top Features:</i> </li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> SHA-256 with RSA Encryption</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> OAuth / SAML single sign-on</li>
                        </ul>
                      </button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                    <button className={cloudServerType == 'CS2' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleCloudServerTypeChange('CS2')}>
                        <h4 className={classes.headerCSS}>CS2</h4>
                        <Divider/>
                        <div className={classes.pricingCSS}>
                          $50
                        </div>
                        <div className={classes.bodyCSS}>
                          per cloud server/month
                        </div>
                        <ul className={classes.itemsCSS}>
                          <li className={classes.itemCSS}><b>Performance: Up to 12GHz</b> </li>
                          <li className={classes.itemCSS}><b>Memory: Up to 16GB</b> </li>
                            <br/>
                          <li className={classes.itemCSS}><i>Top Features:</i> </li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> SHA-256 with RSA Encryption</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> OAuth / SAML single sign-on</li>
                        </ul>
                      </button>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={3}>
                    <button className={cloudServerType == 'CS3' ? classes.cardCSSSelected : classes.cardCSS} onClick={() => this.handleCloudServerTypeChange('CS3')}>
                        <h4 className={classes.headerCSS}>CS3</h4>
                        <Divider/>
                        <div className={classes.pricingCSS}>
                          $200
                        </div>
                        <div className={classes.bodyCSS}>
                          per cloud server/month
                        </div>
                        <ul className={classes.itemsCSS}>
                          <li className={classes.itemCSS}><b>Performance: Up to 48GHz</b> </li>
                          <li className={classes.itemCSS}><b>Memory: Up to 164GB</b> </li>
                            <br/>
                          <li className={classes.itemCSS}><i>Top Features:</i> </li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> SHA-256 with RSA Encryption</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> OAuth / SAML single sign-on</li>
                        </ul>
                      </button>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={3}>
                    <button className={cloudServerType == 'CSE' ? classes.cardCSSSelected : classes.cardCSS} disabled={true}>
                        <h4 className={classes.headerCSS}>Enterprise</h4>
                        <Divider/>
                        <div className={classes.pricingCSS}>
                          Contact Us
                        </div>
                        <div className={classes.bodyCSS}>
                          sales@iotflows.com
                        </div>
                        <ul className={classes.itemsCSS}>
                          <li className={classes.itemCSS}><b>Multi-Cluster servers</b> </li>
                          <li className={classes.itemCSS}><b>Load Balancers</b> </li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> Enterprise-grade security and compliance</li>
                          <li className={classes.itemCSS}><CheckOutlinedIcon className={classes.checkmarkCSS} /> 99.9% guaranteed uptime SLA</li>
                            <br/>
                        </ul>
                      </button>
                    </Grid> 
                  </Grid> */}

import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import DeleteAssetModal from '../components/DeleteAssetModal';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@mui/material/CircularProgress';





export default function TableSATWidget({loading, testDataTable}) {
  const [slicedRows, setSlicedRows] = useState([])
  const [updateRows, setUpdateRows] = useState(false);
  const [sliceAmount, setSliceAmount] = useState(100);
  const inputEl = useRef();

  //  If new rows are received, reset table and states
  useEffect(() => {
    setSlicedRows(testDataTable.slice(0,100))
    setSliceAmount(100)
  },[testDataTable]);

  // Every time rows updated, update the scroll position
  useEffect(() => {
    // console.log("Finished updates Rows state")
    setUpdateRows(false)
  },[slicedRows]);

  const onScroll = () => {
    // console.log('sliceAmount', sliceAmount)
    // console.log('Object.keys(testDataTable).length', Object.keys(testDataTable).length)
    // If all the rows are already displayed, then stop calculating scroll
    if(Object.keys(testDataTable).length > sliceAmount){
      let scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
      let scrollTop = inputEl.current.scrollTop
      let scrollHeight = inputEl.current.scrollHeight
      let currentPos = (scrollTop + 1000) / scrollHeight
      // console.log('currentPos', currentPos)
      if(currentPos > 0.9 && !updateRows){
        setUpdateRows(true)
        let addRows = sliceAmount + 100
        setSliceAmount(addRows)
        setSlicedRows(testDataTable.slice(0,addRows))
      }
    }
  }


  function downloadDataButtonClick(){
    // console.log("Inside download CSV file")
    var headers = ['Spindle','Controller', 'Data ID','Date', 'Time', 'Status', 'Job Count','Task Count', 'Temperature', 'Torque', 'Torque Status','Angle','Angle Status','Current','Current Status','Received At'];
    

    var data = [];
    
    testDataTable.map( dataPoint => {
      let row = [dataPoint.spindle, dataPoint.controller, dataPoint.data_id, dataPoint.data['Date'], dataPoint.data['Time'],dataPoint.data['Status'], dataPoint.data['Job Count'],dataPoint.data['Task Count'], dataPoint.data['Temperature'], dataPoint.data['Torque'], dataPoint.data['Torque Status'], dataPoint.data['Angle'], dataPoint.data['Angle Status'], dataPoint.data['Current'], dataPoint.data['Current Status'], formatDate(dataPoint.received_at)]
      data.push(row)
    })
    
    var csv = headers+'\n';
    data.map(row => (
      csv += row+'\n'
    ))

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'SAT_data.csv';
    hiddenElement.click();
  };

  function formatDate(date){
    // console.log('date', date)
    // return date
    if(date){
      let modifyDate = new Date(date);
      modifyDate = modifyDate.toLocaleTimeString()+' '+modifyDate.toLocaleDateString();
      return modifyDate
    }
  }
  
  return (
    <div >
      <Card ref={inputEl} onScroll={onScroll}
        style={{ padding:'8px',maxHeight:'1000px', overflow:'scroll', margin: '8px', borderRadius:'20px', backgroundColor:'transparent',border:'0px solid transparent'}} 
        variant="outlined">
        <CardContent style={{padding:'0px'}} >
            {loading &&
              <Grid item xs={12}>
                <LinearProgress sx={{height:'4px'}} color='primary' />
              </Grid>
            }
            <TableContainer component={Paper}  >
                <Table style={{minWidth: 450}} aria-label="spanning table">
                    {/* <colgroup>
                        
                        <col style={{width:'25%'}}/>
                        <col style={{width:'50%'}}/>
                        <col style={{width:'25%'}}/>
                    </colgroup> */}
                    <TableHead>
                        <TableRow>
                        <StyledTableCellHeader style={{fontSize: '24px', paddingLeft: '12px', paddingRight: '12px'}} align="left" colSpan={11}>
                            <b>SAT Data</b>
                        </StyledTableCellHeader>
                        <StyledTableCellHeader align="right" colSpan={6}>
                          <DownloadButton disabled={Object.keys(testDataTable).length == 0} onClick={downloadDataButtonClick}>
                            <ArrowDownwardIcon fontSize='small' /> Download Table
                          </DownloadButton>
                        </StyledTableCellHeader>
                        </TableRow>
                        <TableRow>
                          <StyledTableCellHeader >Spindle</StyledTableCellHeader>
                          <StyledTableCellHeader >Controller</StyledTableCellHeader>
                          <StyledTableCellHeader >Data ID</StyledTableCellHeader>
                          <StyledTableCellHeader >Date</StyledTableCellHeader>
                          <StyledTableCellHeader >Time</StyledTableCellHeader>
                          <StyledTableCellHeader >Status</StyledTableCellHeader>
                          <StyledTableCellHeader >Job Count</StyledTableCellHeader>
                          <StyledTableCellHeader >Task Count</StyledTableCellHeader>
                          <StyledTableCellHeader >Temperature</StyledTableCellHeader>
                          <StyledTableCellHeader >Torque</StyledTableCellHeader>
                          <StyledTableCellHeader >Torque Status</StyledTableCellHeader>
                          <StyledTableCellHeader >Angle</StyledTableCellHeader>
                          <StyledTableCellHeader >Angle Status</StyledTableCellHeader>
                          <StyledTableCellHeader >Current</StyledTableCellHeader>
                          <StyledTableCellHeader >Current Status</StyledTableCellHeader>
                          <StyledTableCellHeader >Part ID</StyledTableCellHeader>
                          <StyledTableCellHeader >Received At</StyledTableCellHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{flex: '1 1 auto'}}>
                      {slicedRows.map( dataPoint => (
                        <TableRow key={`${dataPoint.controller}-${dataPoint.spindle}-${dataPoint.id}`}>
                          <StyledTableCellBody >{dataPoint.spindle}</StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.controller}</StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.data_id}</StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.data['Date']}</StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.data['Time']}</StyledTableCellBody>
                          <StyledTableCellBody >
                            {dataPoint.data['Status'] == "NOK" && <span style={{color:'#f87171'}}>{dataPoint.data['Status']}</span>}
                            {dataPoint.data['Status'] == "OK" && <span style={{color:'#34d399'}}>{dataPoint.data['Status']}</span>}
                          </StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.data['Job Count']}</StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.data['Task Count']}</StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.data['Temperature']}</StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.data['Torque']}</StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.data['Torque Status']}</StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.data['Angle']}</StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.data['Angle Status']}</StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.data['Current']}</StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.data['Current Status']}</StyledTableCellBody>
                          <StyledTableCellBody >{dataPoint.data['Part ID'] ? dataPoint.data['Part ID'] : 'N/A'}</StyledTableCellBody>
                          <StyledTableCellBody >{formatDate(dataPoint.received_at)}</StyledTableCellBody>
                        </TableRow>
                      ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {updateRows &&
              <Box sx={{ display: 'flex', justifyContent:'center', width:'100%', m: 1}}>
                <CircularProgress />
              </Box>
             }
        </CardContent>
      </Card>
    </div>
  );
}


const StyledTableCellHeader = styled(TableCell)({
    backgroundColor: '#fff',
    color: '#4b5563',
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    fontSize: 11,
    height: '20px',
    padding: '8px',
  
});

const StyledTableCellBody = styled(TableCell)({
  fontSize: 13,
  fontFamily:'Trebuchet MS',
  height: '24px',
  padding: '8px',
});


const DownloadButton = styled(Button)({
  backgroundColor:'transparent',
  borderRadius:'5px',
  border:'1px solid #757575',
  display:'inline-block',
  cursor:'pointer',
  color:'#757575',
  fontFamily:'Trebuchet MS',
  fontSize:'12px',
  // font-weight:bold;
  padding:'2px 14px',
  textDecoration:'none',
  textShadow:'0px 0px 0px #9eb9ff',
  outline: 'none',
  '&:hover': {
    backgroundColor:'rgba(117, 117, 117,0.1)',
  },
  '&:active': {
    position:'relative',
    top:'1px',
    outline: 'none',
  },
  '&:focus': {
    outline: 'none',
  },

});

const AssetInfo = styled('div')({
  fontSize: '14px',
  fontWeight: 400,
  fontFamily:'Trebuchet MS',
  color: '#111827',
  marginTop:'8px',
  marginLeft:'4px'
});

const AssetTags = styled('div')({
  fontSize: '14px',
  fontWeight: 600,
  fontFamily:'Trebuchet MS',
  color: '#2563eb',
  marginTop:'8px',
  marginLeft:'7px'
});


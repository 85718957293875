import {network_data_api, network_names} from '../MyNetworksPage/NetworkDataApi'
import React from 'react';

export const OrgDeviceListColumns = [
  // { title: "", render: rowData => <span className="heartbeatOnline"> </span> , width: 5 },
  { title: 'Device Name', 
    field: 'device_name' 
  },
  { title: 'Assigned Project', 
    field: 'project_name',
    editable: 'never', 
  },
  // { title: 'Privacy',
  //   field: 'device_is_private',
  //   lookup: { true: 'Private', false: 'Public' }, 
  // },
  { title: 'Device Description', 
    field: 'device_description' 
  },
  { title: 'Device Type', 
    field: 'device_type',
    lookup: {},
    editable: 'onAdd', 
  },
  { title: 'Last Heard', 
    field: 'device_last_heard',
    type: 'datetime',
    editable: 'never',
   },
  // { title: 'Network', 
  //   field: 'network',
  //   lookup: network_names,
    
  //  },
];

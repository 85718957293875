import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Typography from '@material-ui/core/Typography';
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import { connect} from "react-redux";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {getData} from '../ApiCalls/DataApis';
import MqttService from '../core/services/MqttService';
import HeaderDevice from './HeaderDevice';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DeviceInfoModal from './DeviceInfoModal';
import { tomorrowNightEighties } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  chartBoxCSS: {
    border:'0px solid #d1d1d1',
    padding: '8px',
    borderRadius: '8px',
    marginRight: '4px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
    },
  },
  interactionsChartBox: {
    border:'0px solid #d1d1d1',
    padding: '8px',
    borderRadius: '8px',
    marginRight: '0px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    // [theme.breakpoints.down('sm')]: {
    //   marginRight: '0px',
    // },
  },
  cardRootDeviceInfo: {
    borderRadius: '8px',
    margin: '0px 0px 8px 4px',
    height: '100%',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('sm')]: {
      margin: '4px 0px 4px 0px',
    },
  },
  cardRootMessagesSize: {
    borderRadius: '8px',
    margin: '8px 4px 8px 0px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('xs')]: {
      margin: '4px 0px 4px 0px',
    },
  },
  cardRootTodays: {
    borderRadius: '8px',
    margin: '8px 4px 8px 4px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('xs')]: {
      margin: '4px 0px 4px 0px',
    },
  },
  cardRootMonths: {
    borderRadius: '8px',
    margin: '8px 0px 8px 4px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('xs')]: {
      margin: '4px 0px 4px 0px',
    },
  },
  cardHeader: {
    fontSize:'20px',
    fontWeight: 700,
    fontFamily:'Trebuchet MS',
    // fontFamily:'"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
    textAlign:'center',
    marginTop: '5px',
    marginBottom: '2px',
    paddingBottom: '0px',
  },
  expandModalContainer: {
    position: 'absolute',
    top: '0%',
    right: '0%',
  },
  interactionsContainer: {
    display: 'flex',
    flexDirection:'column',
    position: 'absolute',
    top: '49%',
    fontFamily:'Trebuchet MS',
    right: '28%',
    width: '100px',
    textAlign: 'center',
  },
  sizeSum: {
    fontSize: '22px',
    fontWeight: 700,
  },
  interactionsSumTop: {
    fontSize: '24px',
    fontWeight: 700,
  },
  interactionsSumBottom: {
    fontSize:'11px', 
    color:'#9CA3AF',
    fontWeight: 300,
  },
  deviceInfoContainer: {
    padding: '4px',
    textAlign: 'center',
    display: 'flex',
    fontFamily:'Trebuchet MS',
  },
  deviceInfoHeader: {
    fontSize: '14px',
    color: '#757575',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    }
  },
  deviceInfoValue:{
    fontSize: '16px',
    color: '#212121',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    }
  },
  deviceInfoTextContainer: {
    padding: '0px',
    display: 'flex',
    fontFamily:'Trebuchet MS',
  },
  deviceInfoTextContainerWithMargin: {
    padding: '0px',
    display: 'flex',
    fontFamily:'Trebuchet MS',
    [theme.breakpoints.down('sm')]: {
      marginTop: '4px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
    },
  },
  deviceFontSize: {
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    }
  },
  expandButton: {
    outline:'none',
    '&:focus': {
      outline:'none',
    },
    '&:hover': {
      outline:'none',
    },
    '&:active': {
      outline:'none',
    },
  }
});

function ExpandModalIcon(props) {
  return (
    <SvgIcon  style={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props}>
        <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
        <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
    </SvgIcon>
  );
}


class OverviewDevice extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      value: 0,
      todaysTotalSumInteger: 0,
      monthsTotalSumInteger: 0,
      todaysTotalSumSummary: 0,
      monthsTotalSumSummary: 0,
      intervalId: '',
      totalSizeSummary: '',
      openDeviceInfoModal: false,
      pieChartSizeOptions: {
        chart: {
          // renderTo: 'container',
          backgroundColor: null,
          height: 160,
          // width: 125,
        },
        colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
        // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
        credits: {enabled: false},
        title: {
          text: "",
          verticalAlign: 'middle',
          style: { 
            color:'#000',
            fontSize: '22px',
          } 
        },
        exporting: {
          enabled: false
        },
        series: [{
          type: 'pie',
          name: 'Size',
          innerSize: '80%',
          data: [{
              name: '0 Bytes',
              y: 1,
              // sliced: true,
              // selected: true
          }]
        }],
        plotOptions: {
          // series: {
          //   animation: false
          // },
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              size: 135,
              dataLabels: {
                  enabled: false
              },
              borderWidth: 0,
              margin: 0,
          }
        },
        legend: {
          itemDistance:0,
        },
        tooltip: {
          pointFormat: '{series.name}: 0'
        }
      },
      pieChartTodaysOptions: {
        chart: {
          // renderTo: 'container',
          backgroundColor: null,
          height: 160,
          // width: 125,
        },
        colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
        // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
        credits: {enabled: false},
        title: {
          text: "",
          verticalAlign: 'middle',
          style: { 
            color:'#000',
            fontSize: '22px',
          } 
        },
        exporting: {
          enabled: false
        },
        series: [{
          type: 'pie',
          name: 'Messages',
          innerSize: '80%',
          data: [{
              name: 'No Messages Published',
              y: 1,
              // sliced: true,
              // selected: true
          }]
        }],
        plotOptions: {
          // series: {
          //   animation: false
          // },
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              size: 135,
              dataLabels: {
                  enabled: false
              },
              borderWidth: 0,
              margin: 0,
          }
      },
        legend: {
          itemDistance:0,
        }
      },
      pieChartMonthsOptions: {
        chart: {
          // renderTo: 'container',
          backgroundColor: null,
          height: 160,
          // width: 125,
        },
        colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
        // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
        credits: {enabled: false},
        title: {
          text: "",
          verticalAlign: 'middle',
          style: { 
            color:'#000',
            fontSize: '22px',
          } 
        },
        exporting: {
          enabled: false
        },
        series: [{
          type: 'pie',
          name: 'Messages',
          innerSize: '80%',
          data: [{
              name: 'No Messages Published',
              y: 1,
              // sliced: true,
              // selected: true
          }]
        }],
        plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              size: 135,
              dataLabels: {
                  enabled: false
              },
              borderWidth: 0,
              margin: 0,
          }
      },
        legend: {
          itemDistance:0,
        }
      },
      interactionOptions: {
        credits: {enabled: false},
        title: {
          text: 'Published Messages',
          margin: 0,
          style: {
            fontSize: '22px',
            fontWeight: '900',
            fontFamily:'Trebuchet MS',
          }
        },
        chart: {
          height: '225px',
        },
        scrollbar: {
          enabled: false
        },
        time: {
          timezoneOffset: new Date().getTimezoneOffset()
        },
        navigator: {
          height: 15,
          outlineWidth: 0,
          outlineColor: '#ffffff',
          xAxis: {
            gridLineWidth: 0,
            gridLineColor: '#ffffff',
          },
          yAxis: {
            gridLineWidth: 0,
            gridLineColor: '#ffffff',
          },
          series: {
              lineWidth: 0.5,
          }
        },
        rangeSelector:{
          allButtonsEnabled: true,
          buttons: [
            {
            type: 'day',
            count: 1,
            text: '1d'
          }, {
            type: 'day',
            count: 7,
            text: '7d'
          }, {
            type: 'day',
            count: 14,
            text: '14d'
          },{
            type: 'month',
            count: 1,
            text: '1m'
          },{
            type: 'all',
            text: 'All'
          }],
          selected: 3
        },
        plotOptions: {
          area: {
              marker: {
                  radius: 2
              },
              lineWidth: 1,
              states: {
                  hover: {
                      lineWidth: 1
                  }
              },
              threshold: null
          }
        },
        series: [{
          type: 'area',
          // color: '#4caf50',
          name: 'Interactions',
          data: [[Date().toLocaleString(),1],[Date().toLocaleString(),1],[Date().toLocaleString(),1],[Date().toLocaleString(),1]], 
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1
            },
            stops: [
              [0, 'rgba(1,31,75,0.8)'],
              [1, 'rgba(1,31,75,0.3)']
              // [0, 'rgba(1,31,75,0.8)'],
              // [1, 'rgba(3,57,108,0.4)']
            ]
          },
          navigator: {
            series: {
                color: '#4caf50',
            }
          },
        }]
      },
      upTimeOptions: {
        credits: {enabled: false},
        title: {
          text: 'Uptime',
          margin: 0,
          style: {
            fontSize: '22px',
            fontWeight: '900',
            fontFamily:'Trebuchet MS',
          }
        },
        chart: {
          height: '240px',
          alignTicks: false,
        },
        scrollbar: {
          enabled: false
        },
        // rangeSelector:{},
        rangeSelector:{
          allButtonsEnabled: true,
          buttons: [
            {
            type: 'day',
            count: 1,
            text: '1d'
          }, {
            type: 'day',
            count: 7,
            text: '7d'
          },{
            type: 'day',
            count: 14,
            text: '14d'
          },{
            type: 'month',
            count: 1,
            text: '1m'
          },{
            type: 'month',
            count: 3,
            text: '3m'
          },{
            type: 'month',
            count: 6,
            text: '6m'
          }],
          selected: 5
        },
        xAxis: {       
          ordinal: false,
          gridLineWidth: 1
        },
        yAxis: {
          gridLineWidth: 1,
          allowDecimals: false,
          ceiling: 1,
          max: 1,
          tickPositioner: function (min, max) {
            var ticks = [],
                interval = (max - min) / (this.chart.yAxis[0].tickPositions.length - 1);
    
            while (min <= max) {
                ticks.push(min);
                min += interval;
            }
    
            return ticks;
          },
        },
        plotOptions: {
          series: {
            lineWidth: 0,
          },
        },
        time: {
          timezoneOffset: new Date().getTimezoneOffset()
        },
        series: [{
          type: 'area',
          color: '#4caf50',
          name: 'Uptime',  
          data: [Date().toLocaleString(),0],        
          // data: [[1615726420133.15,1],[1615726438339.27,0],[1616483432104.93,1],[1616486511376.51,0],[1616488341604.9,1],[1616488708046.84,0],[1616490118260.24,1],[1616490129180.98,0],[1616875607868.05,1],[1616875635271.49,0],[1616875770994.73,1],[1616875797883.67,0],[1616876236014.07,1],[1616876262935.6,0],[1616876420090.32,1],[1616876447659.11,0],[1616876491094.86,1],[1616876518296.01,0],[1617776466203.34,1],[1617777618948.78,0]],
          step: 'left',
          dataGrouping: {
            units: [[
              'millisecond', // unit name
              [1, 2, 5, 10, 20, 25, 50, 100, 200, 500] // allowed multiples
          ], [
              'second',
              [1, 2, 5, 10, 15, 30]
          ], [
              'minute',
              [1, 2, 5, 10, 15, 30]
          ], [
              'hour',
              [1, 2, 3, 4, 6, 8, 12]
          ], [
              'day',
              [1]
          ], [
              'week',
              [1]
          ], [
              'month',
              [1, 3, 6]
          ], [
              'year',
              null
          ]]
          }
        }],
        navigator: {
          height: 15,
          outlineWidth: 0,
          outlineColor: '#ffffff',
          xAxis: {
            gridLineWidth: 0,
            gridLineColor: '#ffffff',
          },
          yAxis: {
            gridLineWidth: 0,
            gridLineColor: '#ffffff',
          },
          chart: {
            type: 'area',
            color: '#4caf50',
          },
          series: {
              type: 'area',
              step: 'left',
              color: '#4caf50',
              lineWidth: 0.5,
          }
        },
      },
    }
    this.handlerExpandDeviceInfoModal= this.handlerExpandDeviceInfoModal.bind(this);
    this.handlerCloseExpandDeviceInfoModal= this.handlerCloseExpandDeviceInfoModal.bind(this);
  }

  componentDidMount() {

    if(MqttService.isConnected) {
      MqttService.subscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/' + this.props.project_uuid + '/devices/' + this.props.device_uuid +'/data-streams/#', this.onDataStreamDevice)
    }

    // Get todays device interactions
    getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid +'/data_streams_interaction_count/today')
    .then(async response => {
      if(response.ok ){
          const data = await response.json();
          if(Object.keys(data.data).length > 0){
          var intervalId = setInterval(this.timer, 60000);
          // store intervalId in the state so it can be accessed later:
          this.setState({intervalId: intervalId});


          var todaysData = data.data.map(result =>({
              name: result.data_stream_name,
              y: parseInt(result.count),
              data_stream_uuid: result.data_stream_uuid
          }))
          var todaysSum = 0
          for(var i = 0; i < Object.keys(todaysData).length; i++) {
            todaysSum = todaysSum + todaysData[i].y
          }
          var todaysSumInteger = todaysSum;
          if(todaysSumInteger >= 1000000){
            todaysSum = (Number.parseFloat(todaysSumInteger)/1000000.00).toFixed(1)
            todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
            todaysSum = todaysSum+"M"
          }
          else if( todaysSumInteger >= 100000  ){
            todaysSum = (Number.parseFloat(todaysSumInteger)/1000.00).toFixed(1)
            todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
            todaysSum = todaysSum+"k"
          }
          else{
            todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
          }

          this.setState({
            todaysTotalSumInteger: todaysSumInteger,
            todaysTotalSumSummary: todaysSum,
          })

          this.setState({
            pieChartTodaysOptions: {
              chart: {
                // renderTo: 'container',
                backgroundColor: null,
                height: 160,
                // width: 125,
              },
              colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
              // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
              credits: {enabled: false},
              title: {
                text: "",
                // text: todaysSum,
                verticalAlign: 'middle',
                style: { 
                  color:'#000',
                  fontSize: '22px',
                } 
              },
              exporting: {
                enabled: false
              },
              series: [{
                type: 'pie',
                name: 'Messages',
                innerSize: '80%',
                data: todaysData
              }],
              plotOptions: {
                // series: {
                //   animation: false
                // },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: 135,
                    dataLabels: {
                        enabled: false
                    },
                    borderWidth: 0,
                    margin: 0,
                }
            },
              legend: {
                itemDistance:0,
              }
            },
          })
        }
      }
    });

    // Get months device interactions
    getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid +'/data_streams_interaction_count/this_month')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(Object.keys(data.data).length > 0){
          
          var dic = {};
            for(var i=0; i<Object.keys(data.data).length; i++){
              dic[data.data[i].device_uuid]= data.data[i]
            }
            this.setState({
              months_dic: dic,
            })

          var monthsData = data.data.map(result =>({
            name: result.data_stream_name,
            y: parseInt(result.count),
            data_stream_uuid: result.data_stream_uuid
          }))


          var monthsSum = 0
          for(var i = 0; i < Object.keys(monthsData).length; i++) {
            monthsSum = monthsSum + monthsData[i].y
          }

          var monthsSumInteger = monthsSum;
          if(monthsSum > 1000000){
            monthsSum = (Number.parseFloat(monthsSum)/1000000.00).toFixed(2)
            monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
            monthsSum = monthsSum+"M"
          }
          else if( monthsSum > 100000 ){
            monthsSum = (Number.parseFloat(monthsSum)/1000.00).toFixed(1)
            monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
            monthsSum = monthsSum+"k"
          }
          else {
            monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
          }
          

          this.setState({
            monthsTotalSumInteger: monthsSumInteger,
            monthsTotalSumSummary: monthsSum,
            deviceMonthsData: monthsData,
          })



          this.setState({
            pieChartMonthsOptions: {
              chart: {
                // renderTo: 'container',
                backgroundColor: null,
                height: 160,
                // width: 125,
              },
              colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
              // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
              // colors: ['#0e2139', '#153255', '#1c4272', '#23538f', '#2a64ab', '#3175c8', '#4b87d2', '#679ad9', '#84ade0', '#a0c0e7'],
              // colors: ['#172330', '#223448', '#2e4660', '#395778', '#456990', '#517aa8', '#678cb6', '#7f9ec1', '#97b1cd', '#afc3d8'],
              credits: {enabled: false},
              title: {
                text: "",
                // text: monthsSum,
                verticalAlign: 'middle',
                style: { 
                  color:'#000',
                  fontSize: '22px',
                } 
              },
              exporting: {
                enabled: false
              },
              series: [{
                type: 'pie',
                name: 'Messages',
                innerSize: '80%',
                data: monthsData
              }],
              plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: 135,
                    dataLabels: {
                        enabled: false
                    },
                    borderWidth: 0,
                    margin: 0,
                }
            },
              legend: {
                itemDistance:0,
              }
            },
          })
        }
      }
    });

    // Device uptime chart
    getData('https://api.iotflows.com/v1/clients/' + this.props.device_uuid + '/historical_status/highcharts')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          this.setState({
            upTimeOptions: {
              ...this.state.upTimeOptions,
              series: 
                [{...this.state.upTimeOptions.series[0],
                  data: JSON.parse(data.data)}],
            },
          }, ()=> {
            this.setState({

              upTimeOptions: {
                scrollbar: {
                  enabled: false
                },
                credits: {enabled: false},
                title: {
                  text: 'Uptime',
                  margin: 0,
                  style: {
                    fontSize: '24px'
                  }
                },
                chart: {
                  height: '240px',
                  alignTicks: false,
                },
                rangeSelector:{
                  allButtonsEnabled: true,
                  buttons: [
                    {
                    type: 'day',
                    count: 1,
                    text: '1d'
                  },{
                    type: 'day',
                    count: 7,
                    text: '7d'
                  },{
                    type: 'day',
                    count: 14,
                    text: '14d'
                  },{
                    type: 'month',
                    count: 1,
                    text: '1m'
                  },{
                    type: 'month',
                    count: 3,
                    text: '3m'
                  },{
                    type: 'month',
                    count: 6,
                    text: '6m'
                  }],
                  selected: 5
                },
                xAxis: {       
                  ordinal: false,
                  gridLineWidth: 1,
                  scrollbar: {enabled: false},
                },
                yAxis: {
                  gridLineWidth: 1,
                  allowDecimals: false,
                  ceiling: 1,
                  max: 1,
                  tickPositioner: function (min, max) {
                    var ticks = [],
                        interval = (max - min) / (this.chart.yAxis[0].tickPositions.length - 1);
    
                    while (min <= max) {
                        ticks.push(min);
                        min += interval;
                    }
    
                    return ticks;
                  },
                },
                plotOptions: {
                  series: {
                    lineWidth: 0,
                  },
                },
                series: [{
                  type: 'area',
                  color: '#4caf50',
                  name: 'Uptime',  
                  data: JSON.parse(data.data),        
                  step: 'left',
                  dataGrouping: {
                    units: [[
                      'millisecond', // unit name
                      [1, 2, 5, 10, 20, 25, 50, 100, 200, 500] // allowed multiples
                  ], [
                      'second',
                      [1, 2, 5, 10, 15, 30]
                  ], [
                      'minute',
                      [1, 2, 5, 10, 15, 30]
                  ], [
                      'hour',
                      [1, 2, 3, 4, 6, 8, 12]
                  ], [
                      'day',
                      [1]
                  ], [
                      'week',
                      [1]
                  ], [
                      'month',
                      [1, 3, 6]
                  ], [
                      'year',
                      null
                  ]]
                  }
                }],
                navigator: {
                  scrollbar: {enabled: false},
                  height: 15,
                  outlineWidth: 0,
                  outlineColor: '#ffffff',
                  xAxis: {
                    gridLineWidth: 0,
                    gridLineColor: '#ffffff',
                  },
                  yAxis: {
                    gridLineWidth: 0,
                    gridLineColor: '#ffffff',
                  },
                  chart: {
                    type: 'area',
                    color: '#4caf50',
                  },
                  series: {
                      type: 'area',
                      step: 'left',
                      color: '#4caf50',
                      lineWidth: 0.5,
                  }
                },
              },
            })
            }
          )    
        }
      });


    // Historical interactions charts
    getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/interaction_count/historical/highcharts')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(Object.keys(JSON.parse(data.data)).length > 0 ){
            this.setState({
                interactionOptions: {
                  ...this.state.interactionOptions,
                  series: 
                    [{...this.state.interactionOptions.series[0],
                      data: JSON.parse(data.data)}],
                }}, () => {
                  this.setState({
                    interactionOptions: {
                      credits: {enabled: false},
                      title: {
                        text: 'Published Messages',
                        margin: 0,
                        style: {
                          fontSize: '22px',
                          fontWeight: '900',
                          fontFamily:'Trebuchet MS',
                        }
                      },
                      chart: {
                        height: '225px',
                      },
                      scrollbar: {
                        enabled: false
                      },
                      time: {
                        timezoneOffset: new Date().getTimezoneOffset()
                      },
                      navigator: {
                        height: 15,
                        outlineWidth: 0,
                        outlineColor: '#ffffff',
                        xAxis: {
                          gridLineWidth: 0,
                          gridLineColor: '#ffffff',
                        },
                        yAxis: {
                          gridLineWidth: 0,
                          gridLineColor: '#ffffff',
                        },
                        series: {
                            lineWidth: 0.5,
                        }
                      },
                      rangeSelector:{
                        allButtonsEnabled: true,
                        buttons: [
                          {
                          type: 'day',
                          count: 1,
                          text: '1d'
                        }, {
                          type: 'day',
                          count: 7,
                          text: '7d'
                        }, {
                          type: 'day',
                          count: 14,
                          text: '14d'
                        },{
                          type: 'month',
                          count: 1,
                          text: '1m'
                        },{
                          type: 'all',
                          text: 'All'
                        }],
                        selected: 3
                      },
                      plotOptions: {
                        area: {
                            marker: {
                                radius: 2
                            },
                            lineWidth: 1,
                            states: {
                                hover: {
                                    lineWidth: 1
                                }
                            },
                            threshold: null
                        }
                      },
                      series: [{
                        type: 'area',
                        // color: '#4caf50',
                        name: 'Interactions',
                        data: JSON.parse(data.data), 
                        color: {
                          linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                          },
                          stops: [
                            [0, 'rgba(1,31,75,0.8)'],
                            [1, 'rgba(1,31,75,0.3)']
                            // [0, 'rgba(1,31,75,0.8)'],
                            // [1, 'rgba(3,57,108,0.4)']
                          ]
                        },
                        navigator: {
                          series: {
                              color: '#4caf50',
                          }
                        },
                      }]
                    }
                  })
                }
            );
          }
        }
      });

    // Get data messages sizes
    getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid + '/data_streams_interaction_size')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(Object.keys(data.data).length > 0){

          var dataSizes = data.data.map(result =>({
              name: result.data_stream_name,
              y: parseInt(result.size),
              prettyY: this.formatBytes(result.size),
              device_uuid: result.data_stream_uuid
          }))

          let sizeSum = 0
          for(var i = 0; i < Object.keys(dataSizes).length; i++) {
            sizeSum = sizeSum + dataSizes[i].y
          }

          var formattedSize = this.formatBytes(sizeSum)

          this.setState({
            totalSizeSummary: formattedSize,
          })

          this.setState(
            {
              pieChartSizeOptions: {
                ...this.state.pieChartSizeOptions,
                series: 
                  [{...this.state.pieChartSizeOptions.series[0],
                    data: dataSizes}],
              }}, () => {
                this.setState({
                  pieChartSizeOptions: {
                    chart: {
                      // renderTo: 'container',
                      backgroundColor: null,
                      height: 160,
                      // width: 125,
                    },
                    colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
                    // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
                    credits: {enabled: false},
                    title: {
                      text: "",
                      verticalAlign: 'middle',
                      style: { 
                        color:'#000',
                        fontSize: '22px',
                      } 
                    },
                    exporting: {
                      enabled: false
                    },
                    series: [{
                      type: 'pie',
                      name: 'Message storage',
                      innerSize: '80%',
                      data: dataSizes
                    }],
                    plotOptions: {
                      // series: {
                      //   animation: false
                      // },
                      pie: {
                          allowPointSelect: true,
                          cursor: 'pointer',
                          size: 135,
                          dataLabels: {
                              enabled: false
                          },
                          borderWidth: 0,
                          margin: 0,
                      }
                    },
                    legend: {
                      itemDistance:0,
                    },
                    tooltip: {
                      pointFormat: '{series.name}: {point.prettyY}'
                    }
                  },
                })
          })
        }
      }
    });

  }

  formatBytes = (bytes, decimals = 1) => {
    try{
      if (bytes == 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k));
  
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
    }
    catch(e){
      return "-"
    }
    
  }


  onDataStreamDevice = message => {
    try{
      // adding to the todays messages in project list
      var currentTodaysSumInt = this.state.todaysTotalSumInteger + 1;
      var currentTodaysSumSummary = currentTodaysSumInt;
      if(currentTodaysSumInt >= 1000000){
        currentTodaysSumSummary = (Number.parseFloat(currentTodaysSumInt)/1000000.00).toFixed(1)
        currentTodaysSumSummary = currentTodaysSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
        currentTodaysSumSummary = currentTodaysSumSummary+"M"
      }
      else if( currentTodaysSumInt >= 100000  ){
        currentTodaysSumSummary = (Number.parseFloat(currentTodaysSumInt)/1000.00).toFixed(1)
        currentTodaysSumSummary = currentTodaysSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
        currentTodaysSumSummary = currentTodaysSumSummary+"k"
      }
      else{
        currentTodaysSumSummary = currentTodaysSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
      }
      this.setState({
        todaysTotalSumInteger: currentTodaysSumInt,
        todaysTotalSumSummary: currentTodaysSumSummary,
      })



      // adding to the months messages in project list
      var currentMonthsSumInt = this.state.monthsTotalSumInteger + 1;
      var currentMonthsSumSummary = currentMonthsSumInt;
      if(currentMonthsSumInt >= 1000000){
        currentMonthsSumSummary = (Number.parseFloat(currentMonthsSumInt)/1000000.00).toFixed(2)
        currentMonthsSumSummary = currentMonthsSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
        currentMonthsSumSummary = currentMonthsSumSummary+"M"
      }
      else if( currentMonthsSumInt >= 100000 ){
        currentMonthsSumSummary = (Number.parseFloat(currentMonthsSumInt)/1000.00).toFixed(1)
        currentMonthsSumSummary = currentMonthsSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
        currentMonthsSumSummary = currentMonthsSumSummary+"k"
      }
      else {
        currentMonthsSumSummary = currentMonthsSumSummary.toLocaleString('en-US', {maximumFractionDigits:2})
      }

      this.setState({
        monthsTotalSumInteger: currentMonthsSumInt,
        monthsTotalSumSummary: currentMonthsSumSummary 
      })

    }
    catch(e){
      console.log("Error in reading data stream in device from MQTT");
      console.log(e.message);
    }
  }

  timer = () => {
    // Get todays device interactions
    getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid +'/data_streams_interaction_count/today')
    .then(async response => {
      if(response.ok ){
          const data = await response.json();
          if(Object.keys(data.data).length > 0){

            var todaysData = data.data.map(result =>({
              name: result.data_stream_name,
              y: parseInt(result.count),
              data_stream_uuid: result.data_stream_uuid
          }))

          this.setState({
            pieChartTodaysOptions: {
              chart: {
                backgroundColor: null,
                height: 160,
              },
              colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
              credits: {enabled: false},
              title: {
                text: "",
                verticalAlign: 'middle',
                style: { 
                  color:'#000',
                  fontSize: '22px',
                } 
              },
              exporting: {
                enabled: false
              },
              series: [{
                type: 'pie',
                name: 'Messages',
                innerSize: '80%',
                data: todaysData
              }],
              plotOptions: {
                // series: {
                //   animation: false
                // },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: 135,
                    dataLabels: {
                        enabled: false
                    },
                    borderWidth: 0,
                    margin: 0,
                }
            },
              legend: {
                itemDistance:0,
              }
            },
          })
        }
      }
    });

    // Get months device interactions
    getData('https://api.iotflows.com/v1/devices/' + this.props.device_uuid +'/data_streams_interaction_count/this_month')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(Object.keys(data.data).length > 0){

          var monthsData = data.data.map(result =>({
            name: result.data_stream_name,
            y: parseInt(result.count),
            data_stream_uuid: result.data_stream_uuid
          }))


          this.setState({
            pieChartMonthsOptions: {
              chart: {
                backgroundColor: null,
                height: 160,
              },
              colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
              credits: {enabled: false},
              title: {
                text: "",
                verticalAlign: 'middle',
                style: { 
                  color:'#000',
                  fontSize: '22px',
                } 
              },
              exporting: {
                enabled: false
              },
              series: [{
                type: 'pie',
                name: 'Messages',
                innerSize: '80%',
                data: monthsData
              }],
              plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: 135,
                    dataLabels: {
                        enabled: false
                    },
                    borderWidth: 0,
                    margin: 0,
                }
            },
              legend: {
                itemDistance:0,
              }
            },
          })
        }
      }
    });

  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    if(MqttService.isConnected) {
      MqttService.unsubscribe('v1/organizations/' + this.props.selectedOrganization.organization_uuid + '/projects/' + this.props.project_uuid + '/devices/' + this.props.device_uuid +'/data-streams/#')
    }
    
  }

  handlerExpandDeviceInfoModal = () => {
    this.setState({openDeviceInfoModal: true})
  }

  handlerCloseExpandDeviceInfoModal = () => {
    this.setState({openDeviceInfoModal: false})
  }


  render(){
    const { classes, theme, deviceInfo} = this.props
    const {interactionOptions,
          upTimeOptions,
          pieChartTodaysOptions,
          pieChartMonthsOptions,
          todaysTotalSumSummary,
          monthsTotalSumSummary,
          todaysTotalSumInteger,
          monthsTotalSumInteger,
          pieChartSizeOptions,
          totalSizeSummary,
          openDeviceInfoModal} = this.state

  

    return (
      <div className={classes.root} >
        <HeaderDevice
            handleClickStartDemo={this.props.handleClickStartDemo}
            selected_device={this.props.selected_device} 
            selectedDevice={this.props.selectedDevice} 
            isDeviceOnline={this.props.isDeviceOnline} 
            deviceGeolocation={this.props.deviceGeolocation} 
            device_uuid={this.props.device_uuid}
            lastHeard={this.props.lastHeard} 
            deviceInfo= {this.props.deviceInfo}
          />

        {this.props.deviceInfo && Object.keys(this.props.deviceInfo).length > 0 ?
        <Grid container item xs={12}>
            <Grid item xs sm  >
              <div className={classes.chartBoxCSS} >
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={upTimeOptions}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}  >
              <Card className={classes.cardRootDeviceInfo}  variant="outlined">
                <CardContent style={{position:'relative'}}>
                  <div className={classes.expandModalContainer}>
                    <LightTooltip  title="Expand Device Information" placement="bottom" arrow  >
                      <IconButton className={classes.expandButton} aria-label="Expand Device Information" onClick={this.handlerExpandDeviceInfoModal} >
                        <ExpandModalIcon  style={{ fontSize: 16, color: "#6B7280" }}/>
                      </IconButton>
                    </LightTooltip>
                  </div>
                  <Grid container spacing={1} >
                    <Grid item xs={4} >
                      <div className={classes.deviceInfoContainer}>
                        <span className={classes.deviceInfoHeader}>% CPU</span>
                      </div>
                    </Grid>
                    <Grid item xs={4} >
                      <div className={classes.deviceInfoContainer}>
                        <span className={classes.deviceInfoHeader}>Memory used</span>
                      </div>
                    </Grid>
                    <Grid item xs={4} >
                      <div className={classes.deviceInfoContainer}>
                        <span className={classes.deviceInfoHeader}>Memory free</span>
                      </div>
                    </Grid>
                    <Grid item xs={4} >
                      <div className={classes.deviceInfoContainer}>
                        <span className={classes.deviceInfoValue}>{deviceInfo.cpu_load_percent ? deviceInfo.cpu_load_percent : "-" }</span>
                      </div>
                    </Grid>
                    <Grid item xs={4} >
                      <div className={classes.deviceInfoContainer}>
                        <span className={classes.deviceInfoValue}>{this.formatBytes(deviceInfo.memory_used_bytes)}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4} >
                      <div className={classes.deviceInfoContainer}>
                        <span className={classes.deviceInfoValue}>{this.formatBytes(deviceInfo.memory_free_bytes)}</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} >
                      <div className={classes.deviceInfoTextContainer} style={{marginTop:'4px'}}>
                        <Typography  className={classes.deviceFontSize} variant="body1" style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                            MAC Address:&nbsp; 
                        </Typography>
                        <Typography className={classes.deviceFontSize} variant="body1" style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                          {deviceInfo.default_network_mac}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} >
                      <div className={classes.deviceInfoTextContainerWithMargin} >
                        <Typography  className={classes.deviceFontSize} variant="body1" style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                          IPv4 Address:&nbsp; 
                        </Typography>
                        <Typography className={classes.deviceFontSize} variant="body1" style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                          {deviceInfo.default_network_ip4}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} >
                      <div className={classes.deviceInfoTextContainer}>
                      <Typography  className={classes.deviceFontSize} variant="body1" style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                          IPv4 Subnet Mask:&nbsp; 
                      </Typography>
                      <Typography className={classes.deviceFontSize} variant="body1" style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                        {deviceInfo.default_network_ip4subnet}
                      </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} >
                      <div className={classes.deviceInfoTextContainer}>
                        <Typography className={classes.deviceFontSize} variant="body1" style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                          OS Architecture:&nbsp; 
                        </Typography>
                        <Typography className={classes.deviceFontSize} variant="body1" style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                          {deviceInfo.os_architecture}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} >
                      <div className={classes.deviceInfoTextContainer}>
                        <Typography className={classes.deviceFontSize} variant="body1" style={ {color: '#757575', fontFamily:'Trebuchet MS'}}>
                          OS Distribution:&nbsp; 
                        </Typography>
                        <Typography className={classes.deviceFontSize} variant="body1" style={ {color:'#212121', fontFamily:'Trebuchet MS'}}>
                          {deviceInfo.os_distribution}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
        </Grid>
        :
          <div className={classes.chartBoxCSS} style={{marginRight: '0px'}}>
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={upTimeOptions}
            />
          </div>
        }
        
        <Grid container spacing={1} >
          <Grid container item xs={12}>
            <Grid item xs={12} sm={4} md={4} >
              <Card className={classes.cardRootMessagesSize}  variant="outlined">
                <CardContent style={{position:'relative',textAlign: 'center', padding: '0px', width: '225px', marginLeft: 'auto', marginRight: 'auto'}}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.cardHeader}  gutterBottom>
                      Storage
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.interactionsContainer}>
                      <span className={classes.sizeSum} >{totalSizeSummary} </span>
                    </div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'chart'}
                      options={pieChartSizeOptions}
                    />
                  </Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4}  >
              <Card className={classes.cardRootTodays}  variant="outlined">
                <CardContent style={{position:'relative',textAlign: 'center', padding: '0px', width: '225px', marginLeft: 'auto', marginRight: 'auto'}}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.cardHeader}  gutterBottom>
                      Today's Messages
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.interactionsContainer}>
                      <span className={classes.interactionsSumTop} >{todaysTotalSumSummary} </span>
                      <span className={classes.interactionsSumBottom} >{todaysTotalSumInteger.toLocaleString('en-US', {maximumFractionDigits:2})} </span>
                    </div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'chart'}
                      options={pieChartTodaysOptions}
                    />
                  </Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Card className={classes.cardRootMonths} variant="outlined">
                <CardContent style={{position:'relative',textAlign: 'center', padding: '0px', width: '225px', marginLeft: 'auto', marginRight: 'auto'}}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.cardHeader}  gutterBottom>
                      Month's Messages
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.interactionsContainer}>
                      <span className={classes.interactionsSumTop} >{monthsTotalSumSummary} </span>
                      <span className={classes.interactionsSumBottom} >{monthsTotalSumInteger.toLocaleString('en-US', {maximumFractionDigits:2})} </span>
                    </div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'chart'}
                      options={pieChartMonthsOptions}
                    />
                  </Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.interactionsChartBox}>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={'stockChart'}
            options={interactionOptions}
          />
        </div>
        {openDeviceInfoModal && Object.keys(this.props.selectedDevice).length > 0 ? 
          <DeviceInfoModal open={openDeviceInfoModal} handlerCloseExpandDeviceInfoModal={this.handlerCloseExpandDeviceInfoModal} selectedDevice={this.props.selectedDevice}  />
        : null}
        </div>


    )

  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{ withTheme: true })(OverviewDevice));

export const CountryCodeJson = 
[  {"country_code" :"US", "country_name": "United States of America"},
   {"country_code":"IE" , "country_name": "Ireland "},
   {"country_code":"IL" , "country_name": "Israel"},
   {"country_code":"IM" , "country_name": "Isle of Man"},
   {"country_code":"IN" , "country_name": "India"},
   {"country_code":"IO" , "country_name": "British Indian Ocean Territory"},
   {"country_code":"IQ" , "country_name": "Iraq"},
   {"country_code":"IR" , "country_name": "Iran (Islamic Republic of)"},
   {"country_code":"IS" , "country_name": "Iceland"},
   {"country_code":"IT" , "country_name": "Italy"},
   {"country_code":"JE" , "country_name": "Jersey"},
   {"country_code":"JM" , "country_name": "Jamaica"},
   {"country_code":"JO" , "country_name": "Jordan"},
   {"country_code":"JP" , "country_name": "Japan"},
   {"country_code":"KE" , "country_name": "Kenya"},
   {"country_code":"KG" , "country_name": "Kyrgyzstan"},
   {"country_code":"KH" , "country_name": "Cambodia"},
   {"country_code":"KI" , "country_name": "Kiribati"},
   {"country_code":"KM" , "country_name": "Comoros"},
   {"country_code":"KN" , "country_name": "Saint Kitts and Nevis"},
   {"country_code":"KP" , "country_name": "Korea (Democratic People's Republic of)"},
   {"country_code":"KR" , "country_name": "Korea, Republic of"},
   {"country_code":"KW" , "country_name": "Kuwait"},
   {"country_code":"KY" , "country_name": "Cayman Islands"},
   {"country_code":"KZ" , "country_name": "Kazakhstan"},
   {"country_code":"LA" , "country_name": "Lao People's Democratic Republic"},
   {"country_code":"LB" , "country_name": "Lebanon"},
   {"country_code":"LC" , "country_name": "Saint Lucia"},
   {"country_code":"LI" , "country_name": "Liechtenstein"},
   {"country_code":"LK" , "country_name": "Sri Lanka"},
   {"country_code":"LR" , "country_name": "Liberia"},
   {"country_code":"LS" , "country_name": "Lesotho"},
   {"country_code":"LT" , "country_name": "Lithuania"},
   {"country_code":"LU" , "country_name": "Luxembourg"},
   {"country_code":"LV" , "country_name": "Latvia"},
   {"country_code":"LY" , "country_name": "Libya"},
   {"country_code":"MA" , "country_name": "Morocco"},
   {"country_code":"MC" , "country_name": "Monaco"},
   {"country_code":"MD" , "country_name": "Moldova, Republic of"},
   {"country_code":"ME" , "country_name": "Montenegro"},
   {"country_code":"MF" , "country_name": "Saint Martin (French part)"},
   {"country_code":"MG" , "country_name": "Madagascar"},
   {"country_code":"MH" , "country_name": "Marshall Islands"},
   {"country_code":"MK" , "country_name": "North Macedonia"},
   {"country_code":"ML" , "country_name": "Mali"},
   {"country_code":"MM" , "country_name": "Myanmar"},
   {"country_code":"MN" , "country_name": "Mongolia"},
   {"country_code":"MO" , "country_name": "Macao"},
   {"country_code":"MP" , "country_name": "Northern Mariana Islands"},
   {"country_code":"MQ" , "country_name": "Martinique"},
   {"country_code":"MR" , "country_name": "Mauritania"},
   {"country_code":"MS" , "country_name": "Montserrat"},
   {"country_code":"MT" , "country_name": "Malta"},
   {"country_code":"MU" , "country_name": "Mauritius"},
   {"country_code":"MV" , "country_name": "Maldives"},
   {"country_code":"MW" , "country_name": "Malawi"},
   {"country_code":"MX" , "country_name": "Mexico"},
   {"country_code":"MY" , "country_name": "Malaysia"},
   {"country_code":"MZ" , "country_name": "Mozambique"},
   {"country_code":"NA" , "country_name": "Namibia"},
   {"country_code":"NC" , "country_name": "New Caledonia"},
   {"country_code":"NE" , "country_name": "Niger"},
   {"country_code":"NF" , "country_name": "Norfolk Island"},
   {"country_code":"NG" , "country_name": "Nigeria"},
   {"country_code":"NI" , "country_name": "Nicaragua"},
   {"country_code":"NL" , "country_name": "Netherlands"},
   {"country_code":"NO" , "country_name": "Norway"},
   {"country_code":"NP" , "country_name": "Nepal"},
   {"country_code":"NR" , "country_name": "Nauru"},
   {"country_code":"NU" , "country_name": "Niue"},
   {"country_code":"NZ" , "country_name": "New Zealand"},
   {"country_code":"OM" , "country_name": "Oman"},
   {"country_code":"PA" , "country_name": "Panama"},
   {"country_code":"PE" , "country_name": "Peru"},
   {"country_code":"PF" , "country_name": "French Polynesia"},
   {"country_code":"PG" , "country_name": "Papua New Guinea"},
   {"country_code":"PH" , "country_name": "Philippines"},
   {"country_code":"PK" , "country_name": "Pakistan"},
   {"country_code":"PL" , "country_name": "Poland"},
   {"country_code":"PM" , "country_name": "Saint Pierre and Miquelon"},
   {"country_code":"PN" , "country_name": "Pitcairn"},
   {"country_code":"PR" , "country_name": "Puerto Rico"},
   {"country_code":"PS" , "country_name": "Palestine, State of"},
   {"country_code":"PT" , "country_name": "Portugal"},
   {"country_code":"PW" , "country_name": "Palau"},
   {"country_code":"PY" , "country_name": "Paraguay"},
   {"country_code":"QA" , "country_name": "Qatar"},
   {"country_code":"RE" , "country_name": "Réunion"},
   {"country_code":"RO" , "country_name": "Romania"},
   {"country_code":"RS" , "country_name": "Serbia"},
   {"country_code":"RU" , "country_name": "Russian Federation"},
   {"country_code":"RW" , "country_name": "Rwanda"},
   {"country_code":"SA" , "country_name": "Saudi Arabia"},
   {"country_code":"SB" , "country_name": "Solomon Islands"},
   {"country_code":"SC" , "country_name": "Seychelles"},
   {"country_code":"SD" , "country_name": "Sudan"},
   {"country_code":"SE" , "country_name": "Sweden"},
   {"country_code":"SG" , "country_name": "Singapore"},
   {"country_code":"SH" , "country_name": "Saint Helena, Ascension and Tristan da Cunha"},
   {"country_code":"SI" , "country_name": "Slovenia"},
   {"country_code":"SJ" , "country_name": "Svalbard and Jan Mayen"},
   {"country_code":"SK" , "country_name": "Slovakia"},
   {"country_code":"SL" , "country_name": "Sierra Leone"},
   {"country_code":"SM" , "country_name": "San Marino"},
   {"country_code":"SN" , "country_name": "Senegal"},
   {"country_code":"SO" , "country_name": "Somalia"},
   {"country_code":"SR" , "country_name": "Suriname"},
   {"country_code":"SS" , "country_name": "South Sudan"},
   {"country_code":"ST" , "country_name": "Sao Tome and Principe"},
   {"country_code":"SV" , "country_name": "El Salvador"},
   {"country_code":"SX" , "country_name": "Sint Maarten (Dutch part)"},
   {"country_code":"SY" , "country_name": "Syrian Arab Republic"},
   {"country_code":"SZ" , "country_name": "Eswatini"},
   {"country_code":"TC" , "country_name": "Turks and Caicos Islands"},
   {"country_code":"TD" , "country_name": "Chad"},
   {"country_code":"TF" , "country_name": "French Southern Territories"},
   {"country_code":"TG" , "country_name": "Togo"},
   {"country_code":"TH" , "country_name": "Thailand"},
   {"country_code":"TJ" , "country_name": "Tajikistan"},
   {"country_code":"TK" , "country_name": "Tokelau"},
   {"country_code":"TL" , "country_name": "Timor-Leste"},
   {"country_code":"TM" , "country_name": "Turkmenistan"},
   {"country_code":"TN" , "country_name": "Tunisia"},
   {"country_code":"TO" , "country_name": "Tonga"},
   {"country_code":"TR" , "country_name": "Turkey"},
   {"country_code":"TT" , "country_name": "Trinidad and Tobago"},
   {"country_code":"TV" , "country_name": "Tuvalu"},
   {"country_code":"TW" , "country_name": "Taiwan, Province of China"},
   {"country_code":"TZ" , "country_name": "Tanzania, United Republic of"},
   {"country_code":"UA" , "country_name": "Ukraine"},
   {"country_code":"UG" , "country_name": "Uganda"},
   {"country_code":"UM" , "country_name": "United States Minor Outlying Islands"},
   {"country_code":"US" , "country_name": "United States of America"},
   {"country_code":"UY" , "country_name": "Uruguay"},
   {"country_code":"UZ" , "country_name": "Uzbekistan"},
   {"country_code":"VA" , "country_name": "Holy See"},
   {"country_code":"VC" , "country_name": "Saint Vincent and the Grenadines"},
   {"country_code":"VE" , "country_name": "Venezuela (Bolivarian Republic of)"},
   {"country_code":"VG" , "country_name": "Virgin Islands (British)"},
   {"country_code":"VI" , "country_name": "Virgin Islands (U.S.)"},
   {"country_code":"VN" , "country_name": "Viet Nam"},
   {"country_code":"VU" , "country_name": "Vanuatu"},
   {"country_code":"WF" , "country_name": "Wallis and Futuna"},
   {"country_code":"WS" , "country_name": "Samoa"},
   {"country_code":"YE" , "country_name": "Yemen"},
   {"country_code":"YT" , "country_name": "Mayotte"},
   {"country_code":"ZA" , "country_name": "South Africa"},
   {"country_code":"ZM" , "country_name": "Zambia"},
   {"country_code":"ZW" , "country_name": "Zimbabwe"},
]
 
import React from 'react';
import PropTypes from 'prop-types';
import { fade, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { blue } from '@material-ui/core/colors';
import SearchMember from './SearchMember';
import  {postData, getData } from '../ApiCalls/DataApis';
import { connect} from "react-redux";
import {showSuccessSnackbar, showErrorSnackbar} from '../store/actions/snackbarActions';
import Tooltip from '@material-ui/core/Tooltip';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#757575",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #fafafa',
  },
  arrow: {
    color: '#757575',
  },
}))(Tooltip);

const styles = theme => ({
  paper: {
    position: 'relative',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(43),
    }
  },
  button: {
    // backgroundColor:'transparent',
    // borderRadius:'0px',
    width: '100%',
    height: '100%',
    border:'0px solid #4f86ce',
    justifyContent: 'flex-start',
    // display:'inline-block',
    // cursor:'pointer',
    // // color:'#4f87cc',
    fontFamily:'Trebuchet MS',
    // fontSize:'14px',
    // // font-weight:bold;
    // padding:'5px 14px',
    // textDecoration:'none',
    // textShadow:'0px 0px 0px #9eb9ff',
    '&:hover': {
      backgroundColor:'transparent',
    },
    '&:active': {
      position:'relative',
      top:'1px',
      color:'#fafafa',
      
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: '#fafafa',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    border:'1px solid #bdbdbd',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  dialog: {borderRadius: 10 } ,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const emails = ['username@gmail.com', 'user02@gmail.com', 'username@gmail.com', 'user02@gmail.com', 'username@gmail.com', 'user02@gmail.com', 'username@gmail.com', 'user02@gmail.com','username@gmail.com', 'user02@gmail.com', 'username@gmail.com', 'user02@gmail.com', 'username@gmail.com', 'user02@gmail.com', 'username@gmail.com', 'user02@gmail.com'];


class AddAlertChannelMemberModal extends React.Component {

  constructor(props){
    super(props);
    this.state ={
      open: false,
      loading: false,
      success: false,
      member_role: '',
      roles:[],
      
    }
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitButtonClick = this.submitButtonClick.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);

  } 
  

  timer = undefined;

  componentDidMount() {
    
    // console.log("component did mount")

      getData('https://api.iotflows.com/v1/alert-channels/roles')
      .then(async response => {
        
        if(response.ok ){
          const data = await response.json();
          this.setState({
            roles: data.data 
          })
        }
        
      });
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleOpen = () => {
    this.setState({ open: true });

  };

  handleClose = () => {
    // console.log('reached closed')
    this.setState({ 
      open: false,
      success: false,
      loading: false,
      member_role: '',
     });
  };

  submitButtonClick = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
        postData('https://api.iotflows.com/v1/alert-channels/'+ this.props.selectedAlertChannel.alert_channel_uuid + '/members', 
        {'member_username': this.props.searchbarUsername,
          'role_name': this.state.member_role})
          .then(async response => {
            if(response.ok){
              // const data = await response.json();
              this.props.showSuccessSnackbar("Member has been successfully added!");
              this.setState({
                loading: false,
                success: true,
                open:false,
              }, () => {this.handleClose()});
            }
            else{
              try{
                const data = await response.json();
                this.setState({
                  loading: false,
                  success: false,
                  open:false,
                }, () => {this.handleClose()});
                this.props.showErrorSnackbar(data.message);
              }catch (e){
                this.props.showErrorSnackbar('Something went wrong');
              }
            }
          })
        }
      )
      // calling function in parent to permanently close modal
      this.props.handler()
    }
  };

  componentWillReceiveProps({open}) {
    this.setState({...this.state,open})
  }


  handleRoleChange = (event) => {
    this.setState({member_role: event.target.value});
  };


  render() {
    const { classes, selectedOrganization } = this.props;
    const { open, loading, success,  member_role } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        
      >
        {/* <Button className={classes.button} variant="outlined"  onClick={this.handleOpen}>Add Member</Button> */}
          
          <Dialog
              open={open}
              onClose={this.props.handler}
              TransitionComponent={Transition}
              keepMounted
              classes={{ paper: classes.dialog }}
            >
              <Paper className={classes.paper} >
                <DialogTitle style={{paddingBottom: '0px'}} id="alert-dialog-slide-title">{"Add Alert Channel Member"}</DialogTitle>
                <DialogContent dividers style={{paddingBottom: '5px', paddingTop: '15px'}}>


                <SearchMember searchMemberApi = {'https://api.iotflows.com/v1/organizations/' + this.props.selectedOrganization.organization_uuid +'/members/search/containing?q='} />

                <FormControl component="fieldset">
                  <FormLabel component="legend">Role</FormLabel>
                  <RadioGroup aria-label="role" name="member_role"   value={member_role} onChange={this.handleRoleChange}>
                    {this.state.roles.map( (role,index) => (
                      <LightTooltip  key={index.toString()} className={classes.hoverText} title={role.role_description} placement="right-start" arrow  >
                        <FormControlLabel classes={{label: classes.radioLabel}} value={role.role_name} control={<Radio color="primary"  className={classes.radioCSS}/>} label={role.role_name} />
                      </LightTooltip>
                    ))}
                  </RadioGroup>
                </FormControl>

                </DialogContent>
                <DialogActions style={{justifyContent:'flex-end'}}>
                  <Button onClick={this.props.handler} >
                    Close
                  </Button>
                  <Button  color="primary" type='submit' className={buttonClassname} disabled={loading || this.props.searchbarUsername == "" || member_role =='' } onClick={this.submitButtonClick}>
                    Add member { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </DialogActions>
              </Paper>
         
        </Dialog>
        
      </Grid>
    );
  }
}

AddAlertChannelMemberModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message)),
  showErrorSnackbar: message => dispatch(showErrorSnackbar(message)),

})
const mapStateToProps = state => {
  return {
    selectedOrganization: state.organizations.selectedOrganization,
    searchbarUsername: state.searchbarUser.searchbarUsername,
    selectedAlertChannel: state.alertChannels.selectedAlertChannel


  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AddAlertChannelMemberModal));
